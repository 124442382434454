<template>
  <div :class="$style.spacer" :style="style" />
</template>

<script>
export default {
  name: 'Spacer',
  props: {
    y: {
      type: Number,
      default: 1,
      validator: (value) => Number.isInteger((value * 10) % 5), // 0.5刻みまで許容
    },
    x: {
      type: Number,
      default: 1,
      validator: (value) => Number.isInteger((value * 10) % 5), // 0.5刻みまで許容
    },
    mdX: {
      type: Number,
      validator: (value) => Number.isInteger((value * 10) % 5), // 0.5刻みまで許容
    },
    mdY: {
      type: Number,
      validator: (value) => Number.isInteger((value * 10) % 5), // 0.5刻みまで許容
    },
    smX: {
      type: Number,
      validator: (value) => Number.isInteger((value * 10) % 5), // 0.5刻みまで許容
    },
    smY: {
      type: Number,
      validator: (value) => Number.isInteger((value * 10) % 5), // 0.5刻みまで許容
    },
  },
  computed: {
    style() {
      return {
        '--width': `calc(var(--spacer-base) * ${this.x})`,
        '--height': `calc(var(--spacer-base) * ${this.y})`,
        '--md-width': `calc(var(--spacer-base) * ${this.mdX || this.x})`,
        '--md-height': `calc(var(--spacer-base) * ${this.mdY || this.y})`,
        '--sm-width': `calc(var(--spacer-base) * ${this.smX || this.mdX || this.x})`,
        '--sm-height': `calc(var(--spacer-base) * ${this.smY || this.mdY || this.y})`,
      };
    },
  },
};
</script>

<style lang="scss" module>
.spacer {
  flex-grow: 0;
  flex-shrink: 0;
  display: block;
  width: var(--width);
  height: var(--height);

  @include md-view {
    width: var(--md-width);
    height: var(--md-height);
  }

  @include sm-view {
    width: var(--sm-width);
    height: var(--sm-height);
  }
}
</style>

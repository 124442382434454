<template>
  <div class="s3test">
    <div :class="$style.types">
      <div :class="$style.type">
        <h2>S3へ画像をアップロード</h2>
        <form
          class="form vertical"
          v-on:submit.prevent="submit">
          <div class="form-contents">
            <dl class="form-group">
              <dt class="label form-label">画像</dt>
              <dd
                class="detail file"
                v-if="flag.showFileInput">
                <div class="form-parts-file">
                  <input
                    type="file"
                    name="file"
                    accept=".jpg, .jpeg, .png, .gif"
                    v-if="validate.view"
                    v-bind:disabled="flag.confirm"
                    v-on:change="updateFile">
                </div>
              </dd>

              <dd
                class="detail d-flex align-items-center"
                v-if="!flag.showFileInput">
                <div class="image">
                  <img v-bind:src="`${iconUrl}`" alt="">
                </div>
                <button
                  type="button"
                  class="btn btn_border wht sm"
                  v-on:click="deleteURL">画像削除・変更</button>
              </dd>

            </dl>
          </div>

          <div>
            <ul class="btn-lists horizontal justify-content-start">
              <li>
                <button
                  type="submit"
                  class="btn btn_border blk"
                  id="btn_check"
                  >送信・保存</button>
                <div
                  class=""
                  id="btn_submit"
                  v-on:click="submitUpdate">updaterのテストはこちらから(feature_idは1固定)</div>
              </li>
              <li v-if="flag.isSaved">
                <div>
                  <p>S3に画像を保存しました。</p>
                  <a v-bind:href="s3path" target="_blank">{{ s3path }}</a>
                </div>
              </li>
            </ul>
          </div>
        </form>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'FeatureTest',
  mixins: [cf],
  data() {
    return {
      file: null,
      iconUrl: null,
      validate: {
        size: false,
        view: true,
      },
      flag: {
        showFileInput: true,
        isSaved: false,
      },
      s3path: null,
      feature_id: 1,
    };
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
  },
  methods: {
    /** 画像の更新 */
    updateFile(e) {
      const files = e.target.files || e.dataTransfer.files;

      // files.lengthが0の場合はキャンセル時
      if (!files.length) {
        this.file = null;
        return;
      }

      // ファイルのサイズチェック（上限2mb）
      const limit = 1024 * 1024 * 2;
      if (files[0].size > limit) {
        this.validate.size = true;
        // ファイルのリセットは非表示→表示で生成しなおす
        this.validate.view = false;
        this.$nextTick(() => {
          this.validate.view = true;
        });
        return;
      }

      // ファイルを格納
      this.file = files[0];
      this.validate.size = false;

      this.previewicon();
    },

    // プレビュー表示
    previewicon() {
      const path = window.URL.createObjectURL(this.file);
      this.iconUrl = path;
      this.flag.showFileInput = false;
    },

    /**
     * サブミット
     */
    submit() {
      if (this.file) {
        // 画像更新・登録
        this.uploadFile();
      }
    },

    submitUpdate() {
      if (this.feature_id) {
        // 画像更新
        this.updaterFile();
      }
    },

    /** ファイルのアップロード */
    uploadFile() {
      // 保存時formDataに落とし込む
      const form = new FormData();
      form.append('file', this.file);

      this.axios({
        method: 'POST',
        url: '/v1/feature/set/register',
        data: form,
        params: {
          environment: this.helper.env.name,
          url: 'https://www.google.com',
          title: 'TEST',
          target: 0,
        },
      })
        .then((response) => {
          // URLの紐付けを保存
          const url = response.data.s3path;
          this.s3path = url;
          this.flag.isSaved = true;
        })
        .catch((error) => {
          alert('プロフィール画像のアップロードに失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    /** ファイルのアップロード */
    updaterFile() {
      const header = {
        method: 'POST',
        url: '/v1/feature/set/updater',
        params: {
          environment: this.helper.env.name,
          id: 1,
          url: 'https://www.google.com',
          title: 'TEST-Update',
          target: 1,
        },
      };

      if (this.file) {
        // 保存時formDataに落とし込む
        const form = new FormData();
        form.append('file', this.file);
        const data = { data: form };
        Object.assign(header, data);
      }

      this.axios(header)
        .then((response) => {
          // URLの紐付けを保存
          const url = response.data.s3path;
          this.s3path = url;
          this.flag.isSaved = true;
        })
        .catch((error) => {
          alert('プロフィール画像のアップロードに失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    // userurls登録
    // submitUrls() {
    //   // テストなので新規登録固定
    //   const id = null;

    //   const data = {
    //     id,
    //     user_id: Number(this.user_id),
    //     url: this.s3path,
    //     type: 1,
    //   };

    //   this.axios({
    //     method: 'POST',
    //     url: '/v1//set/updateUrl',
    //     data,
    //   })
    //     .then(() => {
    //       // 初期化
    //       this.file = null;
    //       this.iconUrl = null;
    //       this.s3path = null;
    //       this.user_id = null;
    //       this.flag.showFileInput = true;
    //       this.flag.isSaved = false;
    //       this.validate.size = false;
    //     })
    //     .catch((error) => {
    //       alert('プロフィール画像の更新に失敗しました。');
    //       if (error.response) console.log(error.response.data);
    //       else console.log(error);
    //     })
    //     .finally(() => {
    //       alert('user_id:9999としてs3pathを保存しました。\nテストを終了します。');
    //     });
    // },

    /** 画像URL削除 */
    deleteURL() {
      this.iconUrl = null;
      this.file = null;
      // input[type=file]の表示
      this.flag.showFileInput = true;
    },
  },
};
</script>

<style module lang="scss">
.types {
}

.type {
  &:not(:first-child) {
    margin-top: 50px;
  }
}

.table {
  margin-top: 10px;
  th {
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  td, th {
    padding: 10px;
  }
}
</style>

<template>
  <div class="editPassword edit">
    <spacer :y="5"/>
    <contents-box title="パスワード変更">
      <form
        class="form vertical"
        v-on:submit="submit"
        autocomplete="off">
        <div class="form-contents">
          <div :class="$style.input">
            <input
              id="oldPassword"
              type="password"
              name="oldPassword"
              placeholder="現在のパスワード"
              v-model.trim="$v.oldPassword.$model"
              v-bind:class="{ input_error: validationFlags.oldPassword }"
              v-on:input="invalidsCheck('oldPassword', $v.oldPassword.$invalid)"
              >
          </div>

          <p
            :class="$style.comment"
            v-if="$v.oldPassword.$dirty
              && $v.oldPassword.required.$invalid">パスワードを入力してください</p>

          <spacer :y="2"/>

          <div :class="$style.input">
            <input
              id="newPassword"
              type="password"
              name="newPassword"
              placeholder="新しいパスワード"
              v-model.trim="$v.newPassword.$model"
              v-bind:class="{ input_error: validationFlags.newPassword }"
              v-on:input="invalidsCheck('newPassword', $v.newPassword.$invalid)"
              >
          </div>

          <p :class="[$style.comment, $style.assistant]">半角英数字8桁以上15桁以下</p>

          <p
            :class="$style.comment"
            v-if="$v.newPassword.$dirty
              && $v.newPassword.required.$invalid">パスワードを入力してください</p>

          <p
            :class="$style.comment"
            v-if="$v.newPassword.$dirty
              && $v.newPassword.minLength.$invalid">パスワードは8文字以上で設定してください</p>

          <p
            :class="$style.comment"
            v-if="$v.newPassword.$dirty
              && $v.newPassword.maxLength.$invalid">パスワードは15文字以下で設定してください</p>

          <spacer :y="2"/>
          <div :class="$style.input">
            <input
              id="repeatPassword"
              type="password"
              name="repeatPassword"
              placeholder="新しいパスワードの確認"
              v-model.trim="$v.repeatPassword.$model"
              v-bind:class="{ input_error: validationFlags.repeatPassword }"
              v-on:input="invalidsCheck('repeatPassword', $v.repeatPassword.$invalid)"
              >
          </div>

          <p
            :class="$style.comment"
            v-if="$v.repeatPassword.$dirty
              && $v.repeatPassword.sameAsPassword.$invalid">新しいパスワードと同一のパスワードを入力してください。</p>
        </div>

        <spacer :y="3"/>
        <div v-if="!flag.updated" :class="$style.flex">
          <button
            type="submit"
            :class="$style.btn"
            v-bind:disabled="!submitFlag">送信</button>
          <spacer :x="2"/>
          <router-link
            :to="toSettings"
            :class="`${$style.btn} ${$style.bdr}`">戻る</router-link>
        </div>

        <spacer v-if="flag.updated || flag.mismatch" :y="2"/>
        <div>
          <p
            :class="$style.message"
            v-if="flag.updated">パスワードを更新しました。次回から新しいログインパスワードをご使用ください。</p>
          <spacer v-if="flag.updated" :y="2"/>
          <div
            :class="$style.flex"
            v-if="flag.updated">
            <router-link
              v-if="user.login.isLogin"
              :class="$style.btn"
              to="/account/">マイページ</router-link>
              <spacer :x="2"/>
            <button
              v-if="user.login.isLogin"
              :class="`${$style.btn} ${$style.bdr}`"
              v-on:click="logout">ログアウト</button>
          </div>

          <p
            :class="$style.message"
            v-if="flag.mismatch">現在のパスワードが一致しないため更新できません。<br>パスワードをお忘れに場合は<router-link to="/forgot-password/">こちら</router-link>からパスワードリセットを行ってください。</p>
        </div>
      </form>
    </contents-box>
    <spacer :y="5"/>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import {
  required,
  minLength,
  maxLength,
  sameAs,
} from '@vuelidate/validators';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'edit-password',
  mixins: [cf],
  components: {
    ContentsBox,
    Spacer,
  },
  data() {
    return {
      validationFlags: {
        oldPassword: false,
        newPassword: false,
        repeatPassword: false,
      },
      // 無効フラグ
      invalids: {
        oldPassword: true,
        newPassword: true,
        repeatPassword: true,
      },
      flag: {
        updated: false,
        mismatch: false,
      },
      toSettings: '',
    };
  },
  setup() {
    const oldPassword = ref('');
    const newPassword = ref('');
    const repeatPassword = ref('');

    const rules = {
      // パスワードは必須で8文字以上
      oldPassword: {
        required,
      },
      newPassword: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(15),
      },
      repeatPassword: {
        sameAsPassword: sameAs(newPassword),
      },
    };

    const $v = useVuelidate(rules, {
      oldPassword,
      newPassword,
      repeatPassword,
    });

    return {
      oldPassword,
      newPassword,
      repeatPassword,
      $v,
    };
  },

  created() {
    if (this.user.account_type < 10) {
      this.toSettings = '/settings/';
    } else if (this.user.account_type < 20) {
      this.toSettings = '/teacher/school/';
    } else if (this.user.account_type < 30) {
      this.toSettings = '/counselor/account/';
    } else {
      this.tosettings = '';
    }
    
  },
  computed: {
    ...mapState(['user', 'page']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.oldPassword
        && !this.invalids.newPassword
        && !this.invalids.repeatPassword
        && !this.flag.updated;
    },
  },
  methods: {
    ...mapActions('user/login', [
      'logout',
    ]),

    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    invalidsCheck(name, bool) {
      this.flag.mismatch = false;
      this.invalids[name] = bool;
    },

    submit(e) {
      e.preventDefault();
      this.showLoading();
      // validateチェックは入力時に行われてる
      const data = {
        id: this.user.id,
        email: this.user.email,
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/updatePassword',
        data,
      })
        .then((response) => {
          if (response.data.token) {
            // tokenをlocalStorageに保存
            cf.saveLocalStorage({
              jwt: response.data.token,
            }, 'wtt');
            this.$store.dispatch('user/login/authCheck', {
              type: 'check',
              jwt: response.data.token,
            });
          }
          if (response.data.updated) this.flag.updated = true;
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.status === 401
              && error.response.data.detail.includes('MISMATCH')) {
              this.flag.mismatch = true;
            }
          } else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module>
.flex {
  display: flex;
}
.input {
  margin-left: 0;
  input {
    width: 100%;
    padding: 13px;
    border-radius: 8px;
    border: none;
    background-color: var(--gray-sub);
    outline: none;
  }
}
.comment {
  font-size: 12px;
  color: var(--pink);
  margin-top: .5em;
  &.assistant {
    color: var(--gray);
    &:before {
      content: "※";
    }
  }
}
.message {
  font-size: 12px;
  a {
    color: var(--black);
    text-decoration: underline;
  }
}
.btn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
  &.bdr {
    background-color: transparent;
    color: var(--black);
    border: 1px solid var(--black);
  }
}
</style>

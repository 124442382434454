<template>
  <div :class="$style.wrap">
    <div v-if="user">
      <label-box v-if="showId"
        color="gray"
        :label="[1, 2, 3, 11, 12].includes(user.account_type) ? user.customer_id : `ID: ${user.id}`"
        :size="type === 'inner' ? 'xs' : 'sm'"
        :class="labelClass" />
      <div
        :class="`${ownUser.account_type >= 30
          ? 'unregistered_operator'
          : 'unregistered_teacher'}`"
        v-if="user.temporary && ownUser">仮登録</div>
      <div
        :class="`${ownUser.account_type >= 30
          ? 'unregistered_operator'
          : 'unregistered_teacher'}`"
        v-if="user.risk && user.risk.length && user.risk[user.risk.length - 1].type !== 11 && ownUser">{{ helper.master.labels.riskApplication.riskType[user.risk[user.risk.length - 1].type].label }}</div>
    </div>
    <basic-box
      :type="type" v-if="user"
      :bg="bg"
      :class="[isCancel ? $style.isCancel : '']">
      <div :class="className">
        <div :class="$style.user">
          <div :class="$style.thumb">
            <round-img width="60px" :src="getMyIconUrl(user)" />
          </div>
          <div :class="$style.user_text">
            <component
              :is="link ? 'router-link' : 'div'"
              :to="link ? link : ''"
              :class="$style.link"
              :target="targetWindow"
              @click="sendClick">
              <div :class="$style.info">
                <div :class="$style.kana" v-html="user.kana"></div>
                <div :class="$style.name">
                  <div v-html="user.username"></div>
                  <span v-if="user.age && showAge">（{{ user.age }}）</span>
                  <i v-if="user.gender" :class="user.gender === 1 ? $style.male : user.gender === 2 ? $style.female : $style.other" class="fa-solid fa-user" style="margin-left: 5px;" />
                  <div v-if="showMessageBtn && ownUser && ownUser.id" @click.prevent :class="$style.message" class="fa-solid fa-messages" @click="messageDetail">
                  </div>
                  <div v-if="showCocomoniBtn && ownUser && cocomoniData.img" @click.prevent :class="$style.cocomoni" @click="cocomoniDetail">
                    <img :class="$style.cocomoni_icon" :src="`/img/default/${cocomoniData.img}`" :alt="`${cocomoniData.alt}`">
                  </div>
                </div>
                <div :class="$style.belongs"
                  v-if="helper.master && helper.master.labels">
                  <span v-if="user.school && user.school.length" :class="$style.school">{{ user.school[0].name }}</span>
                  {{ isCompany ? helper.master.labels.users.company_account_type[user.account_type] : helper.master.labels.users.account_type[user.account_type] }}
                  <span v-if="user.school && !isCompany && user.account_type === 1 && user.school_year" :class="$style.school_year">{{ user.school_year }}年生</span>
                </div>
                <div v-if="user.count" :class="$style.count">
                  <div :class="$style.count_remaining">心理士{{ user.count.psychologist }}回/医師{{ user.count.doctor }}回</div>
                </div>
              </div>
            </component>
            <p v-if="showCancel" :class="$style.cancel">キャンセル</p>
            <p v-if="showCancelOnTheDay" :class="$style.cancel">当日キャンセル</p>
          </div>
        </div>
        <div>
          <slot name="btn"/>
        </div>
      </div>
      <slot name="main" />
    </basic-box>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LabelBox from './LabelBox.vue';
import BasicBox from './BasicBox';
import RoundImg from './RoundImg';
// import Spacer from './Spacer';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'UserBox',
  mixins: [cf],
  components: {
    BasicBox,
    RoundImg,
    // Spacer,
    LabelBox,
  },
  props: {
    user: {
      type: Object,
    },
    ownUser: {
      type: Number,
    },
    targetBlank: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    time: {
      type: String,
    },
    type: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'inner'].includes(value),
    },
    showId: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    showCancelOnTheDay: {
      type: Boolean,
      default: false,
    },
    showCocomoniBtn: {
      type: Boolean,
      default: false,
    },
    showMessageBtn: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: null,
    },
    bg: {
      type: String,
      default: 'white',
      validator: (value) => ['white', 'gray', 'silver'].includes(value),
    },
    isCancel: {
      type: Boolean,
      default: false,
    },
    showAge: {
      type: Boolean,
      default: true,
    },
    saveTab: {
      type: String,
      default: null,
    },
    // isCompany: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      showFlag: false,
      reserves: null,
    };
  },
  computed: {
    ...mapState(['helper']),
    className() {
      const id = this.showId ? 'showId' : '';
      return `${this.$style.header} ${this.$style[this.type]} ${this.$style[id]}`;
    },
    labelClass() {
      const size = this.type === 'inner' ? 'xs' : 'sm';
      return `${this.$style.id} ${this.$style[size]}`;
    },
    targetWindow() {
      return this.targetBlank === true ? '_blank' : '';
    },
    cocomoniData() {
      let result = {};
      const userId = isNaN(this.user.id) ? this.user.rawId : this.user.id;
      if (this.user && this.ownUser && this.ownUser.cocomoni && this.ownUser.cocomoni[userId]) {
        if (this.ownUser.cocomoni[userId] >= 7) {
          result = {
            stenScore: this.ownUser.cocomoni[userId],
            text: 'High',
            img: 'arrow_up.png',
            alt: '上向き矢印',
          };
        } else if (this.ownUser.cocomoni[userId] < 7 && this.ownUser.cocomoni[userId] >= 3) {
          result = {
            stenScore: this.ownUser.cocomoni[userId],
            text: 'Middle',
            img: 'arrow_flat.png',
            alt: '横ばい矢印',
          };
        } else {
          result = {
            stenScore: this.ownUser.cocomoni[userId],
            text: 'Low',
            img: 'arrow_down.png',
            alt: '下向き矢印',
          };
        }
      }
      return result;
    },
    isCompany() {
      return Boolean(this.user && this.user.school && this.user.school.length && this.user.school[0].is_company);
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    sendClick() {
      this.$emit('clickedUser', {});

      if (this.saveTab) cf.saveTabState({ tab: this.saveTab });

    },

    cocomoniDetail() {
      const accountType = this.ownUser.account_type;
      let routeName = '';
      switch (accountType) {
        case 11:
        case 12:
          routeName = 'TeacherCocomoniDetail';
          break;
        case 21:
        case 22:
          routeName = 'CounselorCocomoniDetail';
          break;
        case 31:
          routeName = 'OperatorCocomoniDetail';
          break;
        case 3:
          routeName = 'CocomoniDetail';
          break;
        default:
          routeName = 'NotFound'; // ここへは基本割り振られないはず
          break;
      }
      const userId = String(this.user.id).includes('highlight')
        ? this.user.id.match(/<span class="highlight">(.*?)<\/span>/)[1]
        : this.user.id;
      const resolvedRoute = this.$router.resolve({
        name: routeName,
        params: { id: userId },
      });
      window.open(`${resolvedRoute.href}/`, '_blank');
    },

    async messageDetail() {
      this.showLoading();
      let targetUserId = this.user.id;

      // IDで検索した際にメッセージを送ろうとするとハイライトの影響でuser.idが正しく取れないので変換
      // 利用者IDが文字列かつspanタグがIDに含まれていれば本来のIDのみを抽出
      if (typeof this.user.id === 'string' && this.user.id.includes('</span>')) {
        targetUserId = Number(targetUserId.replace('<span class="highlight">', '').replace('</span>', ''));
      }

      // storeから取得をやめ、吹き出しクリック時に存在するか確認
      const opeMessages = await this.axios({
        method: 'GET',
        url: '/v1/messagegroup/get/list',
        params: {
          target_user_id: targetUserId,
          target_type: 2,
          user_id: this.ownUser.id,
        },
      });

      const chatRoom = opeMessages.data.groups.data;

      if (!chatRoom.length) {
        // 事務局メッセージ未作成の場合は作成

        let id = this.user.id;

        // ハイライトされたIDの場合span タグが混ざるので削除
        if (typeof this.user.id === 'string') {
          const idStr = String(this.user.id);
          id = Number(idStr.replace(/[^0-9]/g, ''));
        }

        // メッセージ新規作成
        const data = {
          flag: 11, // 事務局メッセージは作成時点で11(実施済み)
          user_id: this.ownUser.id,
          target_type: 2, // 事務局個別メッセージ
          target_user_id: id,
        };

        this.axios({
          method: 'POST',
          url: '/v1/messageGroup/set/register',
          data,
        })
          .then((response) => {
            const res = response.data;
            const resolvedRoute = this.$router.resolve({
              name: 'OperatorMessageDetail',
              params: { slug: res.group_id },
            });
            window.open(resolvedRoute.href, '_blank');
          })
          .catch((error) => {
            if (error.message) console.log(error.message);
            else console.log(error);
            alert('メッセージの初期化に失敗しました。');
          })
          .finally(() => {
            this.hideLoading();
          });
      } else if (this.user && this.ownUser.id && chatRoom.length) {
        const targetGroup = chatRoom.find((room) => room.user_id === this.ownUser.id);
        if (!targetGroup) {
          // 事務局とのやりとりはあるが、
          // ログインユーザがチャットルーム作成ユーザと異なる場合
          alert('対象のメッセージを読み込めませんでした。\n再度お試しください。');
          this.hideLoading();
          return;
        }
        const resolvedRoute = this.$router.resolve({
          name: 'OperatorMessageDetail',
          params: { slug: targetGroup.id },
        });
        this.hideLoading();
        window.open(resolvedRoute.href, '_blank');
      }
    },
  },
};
</script>

<style lang="scss" module>
.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @include sm-view {
    display: block;
  }
  &.showId {
    padding-top: 40px;
    &.inner {
      padding-top: 30px;
    }
  }
}
.wrap {
  position: relative;
}
.id {
  position: absolute;
  z-index: 1;

  &.sm {
    margin: 16px 0 0 -6px;
    width: 120px;
  }
  &.xs {
    margin: 14px 0 0 -4px;
    width: 100px;
  }
}

.user {
  display: flex;
  align-items: center;
  @include sm-view {
    display: block;
  }
  &_text {
    display: flex;
    align-items: center;
  }
}

.thumb {
  display: block;
  margin-right: calc(var(--spacer-base) * 2);
  width: 50px;
  @include sm-view {
    margin-bottom: 5px;
  }
}

.info {
  display: block;
}

.count {
  &_remaining {
    font-size: 11px;
    @include md-view {
      font-size: 10px;
    }
  }
}

.history {
  position: relative;
  top: 3px;
  left: 65px;
  &_flex {
    display: flex;
  }
}
.icon {
  position: relative;
  z-index: 1;
  margin: 2px 0 0 2px;
}

.table_flex {
  width: 70%;
  table-layout: fixed;
}

.kana {
  font-size: 10px;
}

.name {
  font-size: 20px;
  line-height: 1.2;
  display: flex;

  @include md-view {
    font-size: 17px;
  }
}
.male {
  color: var(--blue-main);
}
.female {
  color: var(--pink);
}
.other {
  color: var(--gray-main);
}

.time {
  font-size: 12px;

  @include md-view {
    font-size: 11px;
  }
}

.belongs {
  font-size: 12px;
  @include md-view {
    font-size: 11px;
  }
}
.icon {
  display: block;
}

.inner {
  .name {
    font-size: 16px;
  }
  .belongs, .time {
    font-size: 10px;
  }
}

.school {
  margin-right: 8px;
  &_year {
    margin-left: 8px;
  }
}

.cancel {
  font-size: 12px;
  color: var(--pink);
  margin-left: 12px;
}

.link {
  display: block;
  color: var(--black);
}
.message {
  margin-left: 5px;
  color: var(--gray-side);
  cursor: pointer;
}
.cocomoni {
  margin-left: 5px;
  color: var(--gray-side);
  cursor: pointer;

  &_icon {
    width: 24px;
  }
}
.isCancel {
  box-shadow: none;
}
</style>
<style lang="scss" scoped>
:deep(.highlight) {
  background: linear-gradient(transparent 0%, #FFFF00 0%);
}

.unregistered {
  &_operator {
    position: absolute;
    top: 9px;
    left: 100px;
    padding: 3px 15px;
    z-index: 1;
    border: red solid;
    color: red;
    background-color: white;
    border-radius: 20px;
    font-size: 14px;
  }
  &_teacher {
    position: absolute;
    top: 15px;
    left: 130px;
    padding: 3px 15px;
    z-index: 1;
    border: 1px red solid;
    color: red;
    background-color: white;
    border-radius: 20px;
    font-size: 14px;
  }
}

</style>

const actions = {
  toggleIsShownMenu({ commit, state }) {
    commit('setIsShownMenu', { isShownMenu: !state.isShownMenu });
  },
};


const mutations = {
  setIsShownMenu(state, payload) {
    state.isShownMenu = payload.isShownMenu;
  },
};

const state = {
  isShownMenu: false,
};


export default {
  namespaced: true,
  actions,
  mutations,
  state,
};

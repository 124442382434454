<template>
  <span :class="$style.parent">
    <span
    :class="$style.icon"
    v-html="icon"
    v-on:mouseover="mousehover()"
    v-on:mouseleave="mouseleave()"
    v-on:click="clickelem()"></span>
    <div
      v-if="showflag || hover"
      v-on:click="closetab()"
      v-html="close"
      :class="$style.closebtn"></div>
    <div v-if="showflag || hover" :class="$style.main_text" v-html="text"></div>
  </span>
</template>
<script>
export default {
  name: 'Baloon',
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    close: {
      type: String,
      default: '<i class="fa-solid fa-square-x"></i>',
    },
  },
  data() {
    return {
      showflag: false,
      hover: false,
    };
  },
  created() {
  },
  methods: {
    mousehover() {
      this.hover = true;
    },
    mouseleave() {
      this.hover = false;
    },
    clickelem() {
      if (window.innerWidth >= 750) {
        this.showflag = true;
      }
    },
    closetab() {
      this.showflag = false;
      this.hover = false;
    },
  },
};
</script>
<style lang="scss" module>
.parent {
  position: relative;
}
.closebtn {
  display: block;
  position: absolute;
  top: -900%;
  right: 8px;
  font-size: 1.3em;
  z-index: 2;
  color: white;
}
.main_text {
  width: 300px;
  position: absolute;
  top: -930%;
  right: 0;
  padding: 16px;
  padding-top: 21px;
  border-radius: 5px;
  background: var(--primary-color);
  color: #fff;
  text-align: left;
}
.main_text::after {
  position: absolute;
    width: 0;
    height: 0;
    right: 20%;
    bottom: -19px;
    margin-left: 10px;
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: var(--primary-color);
    border-width: 10px;
    pointer-events: none;
    content: " ";
}
</style>


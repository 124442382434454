<template>
  <div :class="className">
    <slot name="title" />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BasicBox',
  props: {
    type: {
      type: String,
      default: 'default',
    },
    bg: {
      type: String,
      default: 'white',
      validator: (value) => ['white', 'gray', 'silver', 'red'].includes(value),
    },
    attention: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    className() {
      return `${this.$style.box} ${this.$style[this.type]} ${this.$style[this.bg]} ${this.attention ? this.$style.attention : ''}`;
    },
  },
};
</script>

<style module lang="scss">
.box {
  border-radius: 10px;
  box-shadow: 0px 1px 4.45px 0.55px rgba(208, 208, 208, 0.48);
  position: relative;
  background-color: #fff;
  &::after {
    content: '';
    border-radius: 10px;
    mix-blend-mode: multiply;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  // サイズ
  &.default {
    padding: 24px 32px 32px;
    @include sm-view {
      padding: 15px 20px 20px;
    }
  }
  &.inner {
    padding: 18px 24px;
    @include sm-view {
      padding: 12px 16px;
    }
  }

  // カラー
  &.white {
    &::after {
      background-color: rgba(#fff, .15);
    }
  }
  &.gray {
    box-shadow: none;
    &::after {
      background-color: rgba(#aaa, .25);
    }
  }
  &.silver {
    &::after {
      background-color: var(--gray-sub);
    }
  }
  &.red {
    &::after {
      background-color: rgba(#DD0000, .1);
    }
  }

  &.attention {
    border: 3px solid #ff6347;
  }
}
</style>

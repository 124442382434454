<template>
  <contents-box
    title="グラフ表示"
    icon="<i class='fa-solid fa-calendar-days'></i>">
    <div :class="$style.periods">
      <ul :class="$style.btns">
        <li
          v-for="val in periods"
          :key="val.name">
          <input
            type="radio"
            name="period"
            :id="val.name"
            :value="val.name"
            :checked="!flag.liftAll && val.name === 'weeks-2'">
          <label
            :for="val.name"
            :class="$style.btn"
            @click="selectPeriod">{{ val.label }}</label>
        </li>
      </ul>

      <spacer :y="2" />

      <form :class="$style.calendars">
        <div :class="$style.max_items">
          <date-small-picker
            :name="'start'"
            v-model="start"
            v-on:sendDate="receiveDate" />
        </div>
        <div :class="$style.max_items">
          <date-small-picker
            :name="'end'"
            v-model="end"
            v-on:sendDate="receiveDate" />
        </div>
        <div :class="$style.max_items">
          <button
            :class="$style.btn"
            :disabled="!flag.manualPeriod"
            @click="manualSubmit">実行</button>
        </div>
      </form>

      <div>
        <p :class="$style.message">{{ manualMessage }}</p>
      </div>
    </div>
  </contents-box>
</template>

<script>
import { mapState } from 'vuex';
import Spacer from '@/views/components/Spacer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import DateSmallPicker from '@/views/components/DateSmallPicker.vue';
import moment from 'moment';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'GraphPeriod',
  mixins: [cf],
  components: {
    Spacer,
    ContentsBox,
    DateSmallPicker,
  },
  data() {
    return {
      start: null,
      end: null,
      flag: {
        manualPeriod: false,
        liftAll: false,
      },
      periods: [
        { name: 'weeks-2', label: '2週間' },
        { name: 'months-1', label: '1か月' },
        { name: 'months-3', label: '3か月' },
        { name: 'months-6', label: '6か月' },
        { name: 'year-1', label: '1年' },
        { name: 'dummy', label: 'dum' },
      ],
      manualMessage: '表示開始日と終了日を選択してください',
    };
  },
  created() {
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  methods: {
    /** ボタン期間の取得 */
    selectPeriod(e) {
      const target = e.currentTarget;
      const id = target.getAttribute('for');
      const split = id.split('-');
      const unit = split[0];
      const value = Number(split[1]);

      const fmt = 'YYYY-MM-DD';
      const today = moment(new Date()).format(fmt);
      this.end = today;
      this.start = moment(today).subtract(value, unit).format(fmt);
      this.$emit('sendPeriod', {
        start: this.start,
        end: this.end,
      });
    },

    /** 手動期間の始点と終点取得 */
    receiveDate(args) {
      if (args.date === 'Invalid date') {
        this[args.name] = null;
        this.flag.manualPeriod = false;
        this.manualMessage = '表示開始日と終了日を選択してください';
      } else {
        this[args.name] = args.date;
        // startとendが存在すればサブミット可
        if (this.start && this.end) {
          if (this.start < this.end) {
            this.flag.manualPeriod = true;
            this.manualMessage = '実行をクリックしてください';
          } else {
            this.flag.manualPeriod = false;
            this.manualMessage = '期間の指定が正しくありません';
          }
        }
      }
    },

    /** 手動で期間を指定して実行 */
    manualSubmit(e) {
      e.preventDefault();
      this.manualMessage = '';
      this.flag.manualPeriod = false;
      this.flag.liftAll = true;
      this.$emit('sendPeriod', {
        start: this.start,
        end: this.end,
      });
    },
  },
};
</script>

<style lang="scss" module>
.periods {
  .btns {
    display: flex;
    width: 102%;
    li {
      margin-right: 1em;
    }
    li:last-child {
      position: relative;
      z-index: -1;
    }
    input[type=radio] {
      display: none;
      &:checked {
        + label {
          background-color: var(--orange-main);
        }
      }
    }
    @include sm-view {
      flex-wrap: wrap;
      margin-top: -.5em;
      li {
        flex-grow: 1;
        margin-right: .25em;
        margin-top: .5em;
      }
    }
    @media screen and (max-width: 472px) {
      justify-content: space-between;
    }
  }
  .calendars {
    display: flex;
    align-items: center;
    > div {
      &:not(:first-child) {
        margin-left: 1em;
      }
      &:nth-child(2) {
        display: flex;
        align-items: center;
        &:before {
          content: "-";
          display: inline-block;
          margin-left: -.5em;
          margin-right: .5em;
        }
      }
    }
    @include sm-view {
      justify-content: space-between;
      flex-wrap: wrap;
      .max_items {
        flex-grow: 1;
      }
      > div {
        &:not(:last-child) {
          width: calc(50% - 1em);
          > div {
            width: 100%;
          }
        }
        &:last-child {
          width: calc(100% - 1em);
          margin-left: 0;
          margin-top: 1em;
          .btn {
            width: 100%;
            display: block;
            border-radius: 10px;
            padding: .8em 1em;
          }
        }
      }
    }
  }
  .btn {
    padding: 6px 20px;
    width: 82px;
    font-size: 14px;
    text-align: center;
    border: none;
    background-color: #ababab;
    font-weight: bold;
    color: #fff;
    transition: 0.4s all;
    display: inline-block;
    border-radius: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    &:not(:disabled) {
      &:hover {
        background-color: var(--orange-main);
      }
    }
    &:disabled {
      opacity: .5;
      cursor: default;
    }
    @include sm-view {
      font-size: .8rem;
      padding: 4px 14px;
    }
    @media screen and (max-width: 450px) {
      width: 100%;
    }
    @media screen and (max-width: 382px) {
      width: 6.5em;
    }
  }
  .message {
    margin-top: 8px;
    font-size: 12px;
  }
}
</style>

<template>
  <div>
    <spacer :y="5"/>
      <ul>
        <stack-item>
          <contents-box title="シフト登録">
            <p>毎月14日AM11:00より翌月のシフト登録が可能です。17日までにご登録ください。<br>翌月シフトは毎月20日に公開されます。</p>
            <spacer :y="3"/>
            <div :class="$style.calendar">
              <Calendar
                :registSchedule="true"
                :schedule="calendarSchedule"
                v-on:sendDate="receiveDate"/>
            </div>
            <spacer :y="3"/>
            <p>日付を選択してください。</p>
          </contents-box>
        </stack-item>
        <stack-item>
          <contents-box title="登録済みシフト一覧">
            <spacer :y="1"/>
            <ul>
              <stack-item v-for="(v,k) in schedules()" :key="k">
                <contents-box :title="k">
                  <ul>
                    <stack-item v-for="row in v" :key="row.id" :space="2">
                      <contents-box type="inner">
                        <div :class="$style.hour">
                          <div>
                            {{formatTimestamp(row.date, 'HH:00')}}
                            <span :class="$style.counselingicons">
                              <i
                                class="fa-regular fa-laptop"
                                :class="$style.colorIcon_online"
                              >
                                <i :class="$style.colorIcon_online_inner" class="fa-regular fa-user-headset" />
                              </i>
                              <i
                                v-if="canReserveVirtual(row)"
                                class="fa-solid fa-vr-cardboard"
                                :class="$style.colorIcon_virtual"
                              />
                            </span>
                          </div>
                          <div v-if="row.reserve && row.reserve.flag === 1">
                            <router-link :to="toURL(row.reserve)">
                              <div :class="$style.fill">
                                <i
                                  v-if="isVirtualReserve(row.reserve)"
                                  class="fa-solid fa-vr-cardboard"
                                  :class="$style.colorIcon_virtual"
                                />
                                <i
                                  v-else
                                  class="fa-regular fa-laptop"
                                  :class="$style.colorIcon_online"
                                >
                                  <i :class="$style.colorIcon_online_inner" class="fa-regular fa-user-headset" />
                                </i>
                                予約あり
                              </div>
                            </router-link>
                          </div>
                          <div :class="$style.icon"
                            v-else
                            v-on:click="receiveDate(k)"><i class="fa-solid fa-pen-to-square"/></div>
                        </div>
                      </contents-box>
                    </stack-item>
                  </ul>
                </contents-box>
              </stack-item>
            </ul>
          </contents-box>
        </stack-item>
      </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions.js';
import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Calendar from '@/views/components/DatePicker.vue';
import Spacer from '@/views/components/Spacer.vue';

export default {
  name: 'counselor-top',
  mixins: [cf],
  components: {
    StackItem,
    ContentsBox,
    Calendar,
    Spacer,
  },
  data() {
    return {
      flag: {
        invalidRange: false,
      },
      date: null,
      rawSchedule: [],
      calendarSchedule: [],
    };
  },
  created() {},
  computed: {
    ...mapState(['user', 'schedule', 'holiday']),
    minDate() {
      return cf.calcSalesMinDate(this.holiday);
    },
  },
  methods: {
    receiveDate(d) {
      this.date = d;
      if (!d) return;
      const data = {
        isOperator: false,
        id: this.user.id,
        date: d,
      };
      const args = {
        modalName: 'edit-schedule',
        data,
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
    schedules() {
      const object = {};
      this.schedule.list.forEach((row) => {
        const date = this.formatTimestamp(row.date, 'YYYY-MM-DD');
        const min = this.formatTimestamp(this.minDate, 'YYYY-MM-DD');
        if (row.target_user_id === this.user.id) {
          if (row.flag === 1 && min <= date) {
            if (object[date]) {
              object[date].push(row);
            } else {
              object[date] = [];
              object[date].push(row);
            }
          }
        }
      });
      return object;
    },
    canReserveVirtual(schedule) {
      return schedule.counseling_reception_type === 2;
    },
    isVirtualReserve(reserve) {
      return reserve.type && reserve.type.counseling_type === 3;
    },
    toURL(reserve) {
      if (this.isVirtualReserve(reserve)) {
        return `/counselor/reserve/virtual/${reserve.id}`;
      }
      return `/counselor/reserve/online/${reserve.id}`;
    },
  },
};
</script>

<style lang="scss" module>
.calendar {
  position: relative;
  z-index: 0;
}
.hour {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fill {
  font-size: 14px;
  color: var(--pink);
  border: 1px solid var(--pink);
  padding: 2px 12px;
  border-radius: 4px;
}
.icon {
  color: var(--gray-side);
  cursor: pointer;
  font-size: 18px;
}
.counselingicons {
  > i {
    &:not(:first-child) {
      margin-left: .3em;
    }
  }
}
.colorIcon {
  &_online {
    color: var(--orange-main);
    position: relative;
    &_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
      font-size: .4em;
    }
  }
  &_virtual {
    color: var(--green-main);
  }
}
</style>

<template>
  <div>
    <p>この利用者を非アクティブにしてよろしいですか？</p>
    <p :class="$style.red">※この操作は取り消せません。</p>
    <!-- <spacer :y="2"/>
    <p>【{{ data.customer_id }}】{{ data.username }}</p> -->
    <spacer :y="4"/>
    <user-box :showId="false" type="inner" :user="data"></user-box>
    <spacer :y="4"/>
    <btn-container>
      <basic-btn tag="button" v-on:click="changeStatus">確定</basic-btn>
      <spacer :x="2"/>
      <basic-btn tag="button" type="bdr" v-on:click="hideModal">キャンセル</basic-btn>
    </btn-container>
  </div>
</template>

<script>
import UserBox from '@/views/components/UserBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';

export default {
  name: 'confirm-change-status',
  props: ['data'],
  components: {
    UserBox,
    Spacer,
    BasicBtn,
    BtnContainer,
  },
  created() {
  },
  methods: {
    hideModal() {
      this.$store.dispatch('modal/messages/hideModal');
    },
    changeStatus() {
      console.log(this.data.id);
      const data = {
        id: this.data.id,
        flag: 998,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then(() => {
          alert('利用者を非アクティブ化が完了しました。\nリロードすると最新の状態が反映されます。');
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          alert('利用者の非アクティブ化に失敗しました。');
        })
        .finally(() => {
          this.hideModal();
        });
    },
  },
};
</script>

<style lang="scss" module>
.red {
  color: var(--pink);
  font-size: 12px;
}
</style>

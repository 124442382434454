<template>
  <div>
    <Bar
      height="200"
      :chartData="ChartData"
      :chart-options="chartOptions" />
  </div>
</template>

<script>
import {
  Chart,
  registerables,
} from 'chart.js';
import { Bar } from 'vue-chartjs';

Chart.register(...registerables);

export default {
  name: 'MixedChart',
  components: {
    Bar,
  },
  props: {
    labelsData: { // 表に付属する色と対応しているデータの情報を表すラベル
      type: Array,
      default: null,
    },
    datasetsData: { // グラフ描画用データ
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      ChartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        plugins: {
          legend: {
            position: 'bottom',
          },
        },
        datasets: { // 棒グラフ、線グラフをそれぞれ指定して個別にオプションを設定できる
          line: {
            borderWidth: 5,
          },
          bar: {
            barPercentage: 0.6, // バーの太さを余白と相対的に指定
            maxBarThickness: 40, // バーの最大太さ40px
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
          'right-y-axis': { // 月毎who5のあ値は右側のy軸にスケールを表示
            type: 'linear',
            position: 'right',
            max: 30,
            grid: { // 男女の利用件数のグリッドと微妙にズレてるため非表示
              display: false,
            },
          },
        },
      },
    };
  },
  created() {
  },
  mounted() {
    this.drawGraph();
  },
  watch: {
    labelsData() { // 期日や絞り込み条件の変動があったら再描画
      this.updateGraph();
    },
  },
  methods: {
    updateGraph() {
      this.ChartData.labels = [];
      this.ChartData.datasets = [];
      this.drawGraph();
    },
    drawGraph() {
      Object.keys(this.labelsData).forEach((index) => {
        this.ChartData.labels.push(this.labelsData[index]);
      });
      Object.keys(this.datasetsData).forEach((index) => {
        this.ChartData.datasets.push(this.datasetsData[index]);
      });
    },
  },
};
</script>

<template>
  <div>
    <canvas :class="$style.particleCanvas" ref="canvas" id="canvasOne" width="500" height="500" ></canvas>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    size: {
      type: Number,
    },
  },
  data() {
    return {
    };
  },
  created() {
  },
  mounted() {
    window.addEventListener('load', this.canvasApp(), false);
  },
  methods: {
    canvasApp() {
      const theCanvas = document.getElementById('canvasOne');
      const context = theCanvas.getContext('2d');

      const sphereRad = 200;
      const that = this;

      let displayWidth;
      let displayHeight;
      let wait;
      let count;
      let numToAddEachFrame;
      let particleList;
      let recycleBin;
      let particleAlpha;
      let fLen;
      let m;
      let projCenterX;
      let projCenterY;
      let zMax;
      let turnAngle;
      let turnSpeed;
      let sphereCenterY;
      let sphereCenterZ;
      let particleRad;
      let zeroAlphaDepth;
      let randAccelX;
      let randAccelY;
      let randAccelZ;
      let gravity;
      // 画面更新ように使う変数を関数内の上位スコープで定義
      let p;
      let outsideTest;
      let nextParticle;
      let sinAngle;
      let cosAngle;
      let rotX;
      let rotZ;
      let depthAlphaFactor;
      let i;
      let theta;
      let phi;
      let x0;
      let y0;
      let z0;

      function addParticle(X0, Y0, Z0, vx0, vy0, vz0) {
        // onTimerの中で粒子を追加している関数
        let newParticle;

        // 利用可能なdropがないかゴミ箱内をチェックする
        if (recycleBin.first != null) {
          newParticle = recycleBin.first;
          // ゴミ箱を空にする
          if (newParticle.next != null) {
            recycleBin.first = newParticle.next;
            newParticle.next.prev = null;
          } else {
            recycleBin.first = null;
          }
        } else {
          // ゴミ箱がからの場合空のオブジェクトを生成する。
          newParticle = {};
        }

        if (particleList.first == null) {
          particleList.first = newParticle;
          newParticle.prev = null;
          newParticle.next = null;
        } else {
          newParticle.next = particleList.first;
          particleList.first.prev = newParticle;
          particleList.first = newParticle;
          newParticle.prev = null;
        }

        // 初期化
        newParticle.x = X0;
        newParticle.y = Y0;
        newParticle.z = Z0;
        newParticle.velX = vx0;
        newParticle.velY = vy0;
        newParticle.velZ = vz0;
        newParticle.age = 0;
        newParticle.dead = false;
        if (Math.random() < 0.5) {
          newParticle.right = true;
        } else {
          newParticle.right = false;
        }
        return newParticle;
      }

      function recycle(P) {
        // particleListから粒子を削除
        if (particleList.first === P) {
          if (P.next != null) {
            P.next.prev = null;
            particleList.first = P.next;
          } else {
            particleList.first = null;
          }
        } else if (P.next == null) {
          P.prev.next = null;
        } else {
          P.prev.next = P.next;
          P.next.prev = P.prev;
        }

        // ゴミ箱に追加
        if (recycleBin.first == null) {
          recycleBin.first = P;
          P.prev = null;
          P.next = null;
        } else {
          P.next = recycleBin.first;
          recycleBin.first.prev = P;
          recycleBin.first = P;
          P.prev = null;
        }
      }

      function onTimer() {
        // 粒追加用関数
        count += 1;

        if (count >= wait) {

          count = 0;
          for (i = 0; i < numToAddEachFrame; i += 1) {
            theta = Math.random() * 2 * Math.PI;
            phi = Math.acos(Math.random() * 2 - 1);
            x0 = sphereRad * Math.sin(phi) * Math.cos(theta);
            y0 = sphereRad * Math.sin(phi) * Math.sin(theta);
            z0 = sphereRad * Math.cos(phi);

            // addParticle 関数は粒子を追加する関数引数は位置と速度になる
            // 速度パラメータによって粒子は球の中心から外側に飛ぶ

            const P = addParticle(x0, sphereCenterY + y0, sphereCenterZ + z0, 0.002 * x0, 0.002 * y0, 0.002 * z0);

            P.attack = 50;
            P.hold = 50;
            P.decay = 100;
            P.initValue = 0;
            P.holdValue = particleAlpha;
            P.lastValue = 0;

            // 以下の時間が経過するまで粒子は生成時の場所にとどまる
            P.stuckTime = 90 + Math.random() * 20;
            P.accelX = 0;
            P.accelY = gravity;
            P.accelZ = 0;
          }
        }

        // 視野角の更新
        turnAngle = (turnAngle + turnSpeed) % (2 * Math.PI);
        sinAngle = Math.sin(turnAngle);
        cosAngle = Math.cos(turnAngle);

        // 背景の塗りつぶし
        context.fillStyle = 'black';
        context.fillRect(0, 0, displayWidth, displayHeight);

        // 粒子の更新と描画
        p = particleList.first;
        while (p != null) {
          nextParticle = p.next;
          p.age += 1;

          // 粒子がstuckTimeを過ぎたら動き出す
          if (p.age > p.stuckTime) {
            p.velX += p.accelX + randAccelX * (Math.random() * 2 - 1);
            p.velY += p.accelY + randAccelY * (Math.random() * 2 - 1);
            p.velZ += p.accelZ + randAccelZ * (Math.random() * 2 - 1);

            p.x += p.velX;
            p.y += p.velY;
            p.z += p.velZ;
          }

          // 表示座標を計算するためにｙ軸を中心に回転座標を計算（ｘとｚのもの）回転座標から新しいｘ、ｚの座標を取得して表示

          rotX = cosAngle * p.x + sinAngle * (p.z - sphereCenterZ);
          rotZ = -sinAngle * p.x + cosAngle * (p.z - sphereCenterZ) + sphereCenterZ;
          m = (that.size * fLen) / (fLen - rotZ);
          p.projX = rotX * m + projCenterX;
          p.projY = p.y * m + projCenterY;


          if (p.age < p.attack + p.hold + p.decay) {
            if (p.age < p.attack) {
              p.alpha = (p.holdValue - p.initValue) / p.attack * p.age + p.initValue; // eslint-disable-line
            } else if (p.age < p.attack + p.hold) {
              p.alpha = p.holdValue;
            } else if (p.age < p.attack + p.hold + p.decay) {
              p.alpha = (p.lastValue - p.holdValue) / p.decay * (p.age - p.attack - p.hold) + p.holdValue; // eslint-disable-line
            }
          } else {
            p.dead = true;
          }

          if ((p.projX > displayWidth) || (p.projX < 0) || (p.projY < 0) || (p.projY > displayHeight) || (rotZ > zMax)) {
            outsideTest = true;
          } else {
            outsideTest = false;
          }

          if (outsideTest || p.dead) {
            recycle(p);
          } else {
            // depth-dependent darkening
            depthAlphaFactor = (1 - rotZ / zeroAlphaDepth);
            depthAlphaFactor = (depthAlphaFactor > 1) ? 1 : ((depthAlphaFactor < 0) ? 0 : depthAlphaFactor);  // eslint-disable-line
            context.fillStyle = `rgba(250, 200, 120, ${depthAlphaFactor * p.alpha})`; // 色と離れたものの透明になっていくときの度合い

            // 描画
            context.beginPath();
            context.arc(p.projX, p.projY, m * particleRad, 0, 2 * Math.PI, false);
            context.closePath();
            context.fill();
          }

          p = nextParticle;
        }
      }

      function init() {
        wait = 1;
        count = wait - 1;
        numToAddEachFrame = 6;
        particleAlpha = 1;
        displayWidth = theCanvas.width;
        displayHeight = theCanvas.width;

        fLen = 320;

        projCenterX = displayWidth / 2;
        projCenterY = displayHeight / 2;

        zMax = fLen - 2;

        particleList = {};
        recycleBin = {};

        randAccelX = 0.1;
        randAccelY = 0.1;
        randAccelZ = 0.1;

        gravity = -0;

        particleRad = 2.5;
        sphereCenterY = 0;
        sphereCenterZ = -3 - sphereRad;

        zeroAlphaDepth = -750;
        turnSpeed = (2 * Math.PI) / 2000; // 回転スピード
        turnAngle = 0;
        setInterval(onTimer, 10 / 24);
      }

      init();
    },
  },
};
</script>

<style lang="scss" module>
.particleCanvas {
  background-color: black;
  max-height: 450px;
  @media screen and (max-width: 800px) {
    scale: .9;
  }
  @media screen and (max-width: 600px) {
    scale: .7;
  }
  @media screen and (max-width: 450px) {
    scale: .6;
    max-width: 350px;
  }
  @media screen and (max-width: 350px) {
    scale: .5;
  }
}
</style>

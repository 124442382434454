<template>
  <div>
    <contents-box title="グループ" icon="<i class='fa-regular fa-user-group'></i>">
      <contents-box type="inner">
        <div :class="$style.add_group">
          <basic-btn tag="button" @click="appendGroup(0)">新規追加</basic-btn>
        </div>
        <div v-if="flag.append">
          <spacer :y="5" />
          <unit-editor
            :user="user"
            :school="user.school"
            :users="users"
            :registFlag="0"
            @pushCancel="appendGroup(0)"
            @registGroup="getGroupList()" />
        </div>
      </contents-box>

      <spacer :y="2" />
      <loader-simple v-if="!flag.noGroup" :flag="flag.loader">
        <spacer :y="3" />
        <ul v-if="!flag.loader">
          <li v-for="group in groups" :key="group">
            <group-list
              :flag="flag"
              :groups="groups"
              :group="group"
              :users="users"
              :user="user"
              @getGroupList="getGroupList" />
          </li>
        </ul>
      </loader-simple>
    </contents-box>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BasicBtn from '@/views/components/BasicBtn.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import UnitEditor from '@/views/components/UnitEditor';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import GroupList from '@/views/components/GroupList.vue';

export default {
  name: 'units',
  components: {
    BasicBtn,
    ContentsBox,
    Spacer,
    UnitEditor,
    LoaderSimple,
    GroupList,
  },
  data() {
    return {
      users: [],
      groups: [],
      groupData: {
        label: null,
        name: null,
      },
      flag: {
        noGroup: false,
        append: false,
        loader: true,
        groupLoader: true,
      },
    };
  },
  created() {
    this.getGroupList();
    this.getUserList(); // 学校に属するグループにユーザーを紐づけるために先に学校に所属するユーザー一覧を取得
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  methods: {
    // キャンセルボタン
    appendGroup(cancel = 0) {
      if (cancel) {
        this.clearGroupDatas();
      } else {
        this.flag.append = !this.flag.append;
      }
    },

    // グループ登録モーダル閉じ
    clearGroupDatas() {
      this.groupData.label = null;
      this.groupData.name = null;
      this.flag.append = false;
    },

    // 編集フラグ更新
    toggleGroupFlag(id) {
      const target = this.groups.find((group) => group.id === id);
      if (!target.detailOpen) {
        target.detailOpen = true;
        this.getGroupDetail(target.id);
      } else if (target.editFlag) {
        target.detailOpen = !target.detailOpen;
        target.editFlag = false;
      } else {
        target.detailOpen = !target.detailOpen;
      }
    },

    /** dbから該当するグループを取得 */
    getGroupList(updateGroupId = 0) {
      if (updateGroupId) this.flag.groupLoader = true;
      else this.flag.loader = true;

      const params = {
        school_id: this.user.school[0].id,
        create_user_id: this.user.id,
      };
      this.axios({
        method: 'GET',
        url: '/v1/unit/get/list',
        params,
      })
        .then((response) => {
          if (response.data.units.data.length) {
            this.groups = response.data.units.data;
            this.flag.loader = false;
            this.flag.groupLoader = false;
            this.flag.noGroup = false;
          } else {
            this.flag.loader = false;
            this.flag.noGroup = true;
          }
          // 更新の時は毎回グループのアコーディオンが閉じるため、サイドアコーディオンを開いて更新後の内容を表示
          if (updateGroupId) {
            this.toggleGroupFlag(updateGroupId);
            this.flag.groupLoader = false;
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /** グループ単体取得ではグループに属するユーザー詳細も取得 */
    getGroupDetail(id) {
      const target = this.groups.find((group) => group.id === id);
      const params = { id };
      this.axios({
        method: 'GET',
        url: '/v1/unit/get/detail',
        params,
      })
        .then((response) => {
          target.userList = response.data.data.userList;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /** ユーザーを紐づけるためにユーザーのデータも取得 */
    // 仮登録ユーザーも取得する？
    getUserList() {
      const params = { school_id: this.user.school[0].id };
      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params,
      })
        .then((response) => {
          if (response.data.users.data.length) {
            const users = response.data.users.data;
            users.forEach((user) => { // 空白を除去
              user.username = user.username.replace(/"/g, ''); // csvからの登録では文字列リテラルがついていることがあるので
              if (user.username.indexOf(' ')) {
                user.username = user.username.replace(' ', '');
              } else if (user.username.indexOf('　')) {
                user.username = user.username.replace('　', '');
              }
              if (user.account_type === 1) { // 教師（利用者アカウント）が検索、グループに追加できるのは所属校に紐づく生徒
                this.users.push(user);
              }
            });
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /** 登録 */
    registGroup() {
      if (!this.groupData.label) {
        alert('グループ名を入力してください');
      }
      const confirmation = confirm(`グループ名${this.groupData.label}を登録します。`);
      if (!confirmation) return true;
      const data = {
        school_id: this.user.school[0].id,
        label: this.groupData.label,
        name: this.groupData.name,
        create_user_id: this.user.id,
        update_user_id: this.user.id,
      };
      this.axios({
        method: 'POST',
        url: '/v1/unit/set/register',
        data,
      })
        .then((response) => {
          if (response.data.id) {
            alert('グループの登録が完了しました');
            this.clearGroupDatas();
            this.getGroupList(); // 更新後すぐに反映してほしいためグループ取得
          }
          if (response.data.exists) {
            alert('グループは既に登録済みです');
            this.groupData.label = null;
            this.groupData.name = null;
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module>
.add_group {
  display: flex;
  justify-content: flex-end;
}
</style>

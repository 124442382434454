<template>
  <basic-box :attention="true">
    <div :class="$style.header">
      <span :class="$style.icon_parent">
        <span
          :class="$style.icon"><i class="fa-regular fa-message-exclamation"></i></span>
      </span>
      <component :class="$style.title" :is="'h3'" v-html="title"></component>
    </div>
    <spacer :y="2" />
    <div :class="$style.notice">
      <p :class="$style.message" v-html="convertText2Html(message)"></p>
    </div>
  </basic-box>
</template>

<script>
import BasicBox from './BasicBox';
import Spacer from './Spacer';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'EmphasisNotice',
  components: { BasicBox, Spacer },
  mixins: [cf],
  props: {
    title: {
      type: String,
      default: '重要なお知らせ',
    },
    message: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconClassName() {
      return `${this.$style.icon} ${this.$style[this.type]}`;
    },
  },
};
</script>

<style module lang="scss">
.subtitle {
  position: relative;
  left: 35px;
  bottom: -5px;
  @include sm-view {
    left: 27px;
    font-size: .9em;
  }
}
.header {
  position: relative;
  display: flex;
  align-items: center;
  color: #ff6347;
}
.icon_parent {
  position: relative;
  color: #ff6347;
}
.icon {
  display: block;
  margin-right: 10px;
  font-size: 25px;
  line-height: 1;

  @include sm-view {
    font-size: 20px;
    margin-right: 7px;
  }
}

.title {
  display: block;
  font-weight: bold;
  font-size: 20px;

  @include sm-view {
    font-size: 16px;
  }

  small {
    font-size: 12px;
    color: red;
    margin-left: 1em;
    @include sm-view {
      display: block;
    }
  }
}
.message {
  font-weight: bold;
  font-size: 18px;
  color: #ff6347;
}
.notice {
  text-align: center;
}
</style>

<template>
  <loader-simple :flag="flag.loader">
    <div v-if="!flag.loader">
      <div :class="$style.count">
        <p :class="$style.label">心理士</p>
        <spacer :x="1"/>
        <input
          :class="$style.text_count"
          name="count"
          v-model="count.psychologist"
          placeholder="回数を入力"
          type="number"
          min="1">
        <spacer :y="2"/>
        <p :class="$style.label">回</p>
        <spacer :y="4"/>
      </div>

      <spacer :y="1"/>

      <div :class="$style.count">
        <p :class="$style.label">医師</p>
        <spacer :x="3"/>
        <input
          :class="$style.text_count"
          name="count"
          v-model="count.doctor"
          placeholder="回数を入力"
          type="number"
          min="1">
        <spacer :y="2"/>
        <p :class="$style.label">回</p>
        <spacer :y="4"/>
        <div :class="$style.flex_elem">
          <basic-btn
            tag="button"
            id="add-doctor"
            :disabled="flag.disabled"
            @click="limitCounter">追加</basic-btn>
          <spacer :y="4"/>
          <basic-btn
            tag="button"
            addClass="danger"
            id="subtract-doctor"
            :disabled="flag.disabled"
            v-if="!isSchoolAdmin"
            @click="limitCounter">削除</basic-btn>
        </div>
      </div>
    </div>
  </loader-simple>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import Spacer from '@/views/components/Spacer.vue';


export default {
  name: 'limit-counter-for-billing-account',
  props: {
    flag: {
      type: Object,
    },
    school: {
      type: Object,
      default: null,
    },
    customer: {
      type: Object,
      default: null,
    },
    creditTarget: {
      type: Object,
      default: null,
    },
    isSchoolAdmin: {
      type: Boolean,
      default: false,
    },
    isOperator: {
      type: Boolean,
      default: false,
    },
    isBulkEdit: {
      type: Boolean,
      default: false,
    },
    targetFile: {
      type: Object,
      default: null,
    },
    sustainableAddCreditHistory: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoaderSimple,
    BasicBtn,
    Spacer,
  },
  data() {
    return {
      label: {
        doctor: '医師',
        psychologist: '心理士',
        add: '追加',
        subtract: '削除',
      },
      count: {
        doctor: null,
        psychologist: null,
      },
    };
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    ...mapActions('user', ['update']),
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },


    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    async limitCounter(e) { // ボタンは共通にして
      e.preventDefault();
      const btn = e.currentTarget;
      const id = btn.id;
      const split = id.split('-');
      const type = split[0]; // typeには追加、削除の情報が入ってくる
      // const target = split[1];

      // バリデーション
      if ((!this.count.psychologist && !this.count.doctor)) {
        return alert('追加または削除の回数は1以上の値を設定してください');
      }
      if (this.count.psychologist < 0) {
        this.count.psychologist = 1;
        return alert('追加または削除の回数は1以上の値を設定してください');
      }
      if (this.count.doctor < 0) {
        this.count.doctor = 1;
        return alert('追加または削除の回数は1以上の値を設定してください');
      }
      if (this.isBulkEdit && this.targetFile === null && Boolean(this.creditTarget)) { // インポートcsv利用回数を調整する際に範囲が選択されていなければ返却
        return alert('範囲を選択してください');
      }
      if (type === 'subtract') { // 削除時、保有クレジットよりも削除するクレジットの方が多い場合は返却
        let subtractTarget;
        if (this.creditTarget) subtractTarget = this.creditTarget;
        else subtractTarget = this.school.users.filter((user) => user.account_type !== 11 && user.account_type !== 12); // 学校管理者は含まない
        const noEnoughCredit = subtractTarget.filter((user) => { // 削除クレジットが保有クレジットを超えるかを検証
          let enougFlag = false;
          if (this.count.doctor && (user.count.doctor < this.count.doctor)) enougFlag = true;
          if (this.count.psychologist && (user.count.psychologist < this.count.psychologist)) enougFlag = true;
          return enougFlag;
        });
        if (noEnoughCredit.length) {
          return alert('選択した利用者の保有クレジットが足りないため削除できません。');
        }
      }

      // if (!this.count[target] || this.count[target] < 0) {
      //   this.count[target] = 1;
      //   return alert('追加または削除の回数は1以上の値を設定してください');
      // }

      // 利用者単体、学校複数のフォーマットを合わせる
      let customers;
      let credit = 0;
      if (this.creditTarget) { // 学校管理者から特定利用者
        // this.creditTargetが配列かどうかを判断
        if (Array.isArray(this.creditTarget)) {
          customers = this.creditTarget;
        } else {
          customers = [this.creditTarget];
        }
        // 消費クレジット
        // const nes = target === 'doctor' ? 2 : 1;
        // credit = this.count[target] * nes * customers.length; // 複数の利用者に対するクレジット計算
        credit = ((this.count.doctor * 2) + (this.count.psychologist * 1)) * customers.length; // (一括時の医師、心理士の合計クレジット) * 対象利用者数
      } else if (this.customer) { // 事務局から特定利用者
        customers = [this.customer];
      } else if (this.school) { // 事務局から特定の学校に所属する利用者全員
        customers = this.school.users.filter((user) => {
          const accountType = user.account_type;
          return accountType < 11;
        });
      }
      const remainingCount = this.school.mainUser[0].count.credit;

      // 保有クレジットよりも割り振ろうとしたクレジットの方が大きかったらアラートで戻る
      if ((remainingCount - credit) < 0 && !this.isOperator) {
        alert('保有クレジットが足りません');
        return true;
      }

      let base;
      if (this.targetFile) {
        base = `${this.targetFile.name}に紐づく利用者さんの`;
      } else {
        base = (this.creditTarget || this.customer)
          ? `${customers.length}名に`
          : `「${this.school.name}」に所属する利用者さん全員の`;
      }

      const message = {
        confirm: `${base}利用可能回数を${this.count.psychologist ? `心理士${this.count.psychologist}回` : ''} ${this.count.doctor ? `医師${this.count.doctor}回` : ''} 分${this.label[type]}しますか？\n${!this.isOperator ? `（${credit}クレジットを消費します）` : ''}`,
        done: `${base}利用可能回数を${this.count.psychologist ? `心理士${this.count.psychologist}回` : ''} ${this.count.doctor ? `医師${this.count.doctor}回` : ''} 分${this.label[type]}しました`,
      };

      const confirm = window.confirm(message.confirm);
      if (!confirm) return;

      this.showLoading();

      /**
       * 学校管理者がユーザーへクレジットを
       * 割り振る際の消費（学校管理者ユーザ管理）
       */
      if (!this.isOperator) { // クレジットが消費されるのは学校管理者から操作の時、事務局から集時にはクレジット消費はナシ
        await this.axios({
          method: 'POST',
          url: '/v1/count/history/set/register',
          data: {
            billing_type: 2,
            foreign_id: this.school.mainUser[0].id, // 学校管理者ID
            count: credit, // クレジット
            is_add: 0,
            credit_user_id: customers[0].id, // 付与するユーザーID
          },
        });
      }

      // 利用者をまわして処理
      // 心理士のカウントなければスキップ
      if (this.count.psychologist) {
        await Promise.all(
          customers.map(async (customer) => {
            let data;
            if (type === 'subtract' && customer.count.psychologist - this.count.psychologist < 0) { // 減算かつ利用者の保有クレジットよりも削除するクレジットの方が大きい場合は0になるようにする
              data = {
                billing_type: 2, // アカウント課金のみ対象のため2固定
                foreign_id: customer.id, // 利用者ID
                count: customer.count.psychologist,
                is_add: 0,
                consultation_type: 1,
                credit_user_id: null,
              };
            } else {
              data = {
                billing_type: 2, // アカウント課金のみ対象のため2固定
                foreign_id: customer.id, // 利用者ID
                count: this.count.psychologist,
                is_add: type === 'add' ? 1 : 0, // 加算: 1, 減算: 0
                consultation_type: 1,
                credit_user_id: type === 'add' ? this.user.id : null, // 付与実行ユーザーID
              };
            }

            this.axios({
              method: 'POST',
              url: '/v1/count/history/set/register',
              data,
            })
              .then(() => {
                if (this.sustainableAddCreditHistory) {
                  const args = {
                    regist_user_id: this.user.id,
                    target_id: customer.id,
                    is_add: type === 'add' ? 1 : 0,
                    count: data.count,
                    counselor_type: 22,
                  };
                  this.registSustainableAddCredit(args);
                }
              })
              .catch((error) => {
                if (error.message) console.log(error.message);
                else console.log(error);
              });
          }),
        );

        if (this.isBulkEdit) { // 事務局から操作があった際には調整履歴に記録(個別に編集された時は記録しない)
          const params = {
            regist_user_id: this.user.id,
            school_id: this.school.id,
            is_add: type === 'add' ? 1 : 0,
            count: this.count.psychologist,
            counselor_type: 22,
            import_id: this.targetFile.id,
          };
          this.axios({
            method: 'POST',
            url: '/v1/bulkCount/set/register',
            params,
          })
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              if (error.message) console.log(error.message);
              else console.log(error);
            });
        }
      }

      // 医師のカウントなければスキップ
      if (this.count.doctor) {
        await Promise.all(
          customers.map(async (customer) => {
            let data;
            if (type === 'subtract' && customer.count.doctor - this.count.doctor < 0) { // 減算かつ利用者の保有クレジットよりも削除するクレジットの方が大きい場合は0になるようにする
              data = {
                billing_type: 2, // アカウント課金のみ対象のため2固定
                foreign_id: customer.id, // 利用者ID
                count: customer.count.doctor,
                is_add: 0,
                consultation_type: 2,
                credit_user_id: null,
              };
            } else {
              data = {
                billing_type: 2, // アカウント課金のみ対象のため2固定
                foreign_id: customer.id, // 利用者ID
                count: this.count.doctor,
                is_add: type === 'add' ? 1 : 0, // 加算: 1, 減算: 0
                consultation_type: 2,
                credit_user_id: type === 'add' ? this.user.id : null, // 付与実行ユーザーID
              };
            }

            this.axios({
              method: 'POST',
              url: '/v1/count/history/set/register',
              data,
            })
              .then(() => {
                if (this.sustainableAddCreditHistory) {
                  const args = {
                    regist_user_id: this.user.id,
                    target_id: customer.id,
                    is_add: type === 'add' ? 1 : 0,
                    count: data.count * 2,
                    counselor_type: 21,
                  };
                  this.registSustainableAddCredit(args);
                }
              })
              .catch((error) => {
                if (error.message) console.log(error.message);
                else console.log(error);
              });
          }),
        );

        if (this.isBulkEdit) { // 事務局から操作があった際には調整履歴に記録(個別にチェックつけて操作した際は記録しない)
          const params = {
            regist_user_id: this.user.id,
            school_id: this.school.id,
            is_add: type === 'add' ? 1 : 0,
            count: this.count.doctor,
            counselor_type: 21,
            import_id: this.targetFile.id,
          };
          this.axios({
            method: 'POST',
            url: '/v1/bulkCount/set/register',
            params,
          })
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              if (error.message) console.log(error.message);
              else console.log(error);
            });
        }
      }

      alert(message.done);
      this.count = { doctor: null, psychologist: null };
      if (this.creditTarget) this.$emit('updateTarget', { fromChild: true });
      this.hideLoading();
      // 学校管理者の場合は学校state更新
      this.update();
    },

    /** サステナブルクレジット編集を保存 */
    async registSustainableAddCredit(datas) {
      const params = {
        regist_user_id: datas.regist_user_id,
        target_user_id: datas.target_id,
        school_id: this.school.id,
        is_add: datas.is_add,
        credit: datas.count,
        counselor_type: datas.counselor_type,
      };

      await this.axios({
        method: 'post',
        url: '/v1/sustainableAddCredit/set/register',
        params,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module>
.label {
  border-radius: 4px;
  margin-right: 4px;
  font-weight: bold;
  display: inline-block;
  &_wrap {
    display: flex;
    align-items: baseline;
  }
}

.count {
  display: flex;
  align-items: baseline;
}

.text {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
  &_count {
    width: 20%;
    padding: 13px;
    border-radius: 8px;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
    $background: var(--gray-sub);
    text-align: right;
  }
}

.flex_elem {
  display: flex;
  position: relative;
  left: 30px;
  top: -30px;
}

</style>

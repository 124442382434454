<template>
  <div>
    <spacer :y="5"/>
    <contents-box title="医師・心理士情報入力" id="confirm">
      <div :class="$style.wrapper">
        <div :class="$style.row" v-for="row in contents" :key="row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">{{ row.label }}</p>
            <p v-if="row.required" :class="$style.required">※必須項目</p>
          </div>

          <!-- テキスト -->
          <div :class="$style.detail" v-if="row.type === 'text'">
            <div>
              <input
                v-if="row.name === 'kana'"
                :class="$style.text"
                :name="row.name"
                :disabled="flag.isConfirm"
                :placeholder="row.placeholder"
                v-model.trim="$v.kana.$model"
                v-on:input="invalidsCheck('kana', $v.kana.$invalid)"
                type="text">
              <input
                v-else-if="row.name === 'virtual_cast_room_key'"
                v-show="isVirtualSpecialist"
                :class="$style.text"
                :name="row.name"
                :disabled="flag.isConfirm"
                :placeholder="row.placeholder"
                type="text"
                v-model.trim="row.value">
              <input
                v-else
                :class="$style.text"
                :name="row.name"
                :disabled="flag.isConfirm"
                :placeholder="row.placeholder"
                type="text" v-model="row.value">
            </div>
            <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
            <p v-if="row.name === 'kana'&& $v.kana.$dirty && $v.kana.custom.$invalid" :class="$style.required">カナ文字のみ入力してください</p>
          </div>

          <!-- テキスト(複数行) -->
          <div :class="$style.detail" v-if="row.type === 'textarea'">
            <div>
              <textarea
                :class="$style.text"
                :name="row.name"
                :disabled="flag.isConfirm"
                :placeholder="row.placeholder"
                v-model="row.value"></textarea>
            </div>
          </div>

          <!-- ラジオボタン -->
          <div :class="$style.detail" v-if="row.type === 'radio'">
            <ul :class="$style.radio">
              <li v-for="opt in row.options" :key="opt">
                <input type="radio" :name="row.name" :id="opt.name" :disabled="flag.isConfirm" :value="opt.value" v-model="row.value">
                <label :for="opt.name">{{ opt.label }}</label>
              </li>
            </ul>
          </div>

          <!-- チェックボックス -->
          <div :class="$style.detail" v-if="row.type === 'check'">
            <div>
              <input
                type="checkbox"
                :name="row.name"
                :disabled="flag.isConfirm"
                v-model="row.value"
                true-value="1"
                false-value="0"
                v-on:change="changeCheckboxValue($event, row.name)"
              >
            </div>
          </div>

          <!-- 生年月日 -->
          <div :class="$style.detail" v-if="row.type === 'birthday'">
            <ul :class="$style.select_wrap">
              <li>
                <select :class="$style.select" name="birth_year" id="birth_year" :disabled="flag.isConfirm" v-model="row.value.year">
                  <option
                    v-for="n of 70"
                    :key="n"
                    :value="optionYear(n)">{{ optionYear(n) }}</option>
                </select>年
              </li>
              <li>
                <select :class="$style.select" name="birth_month" id="birth_month" :disabled="flag.isConfirm" v-model="row.value.month">
                  <option
                    v-for="n of 12"
                    :key="n"
                    :value="n">{{ n }}</option>
                </select>月
              </li>
              <li>
                <select :class="$style.select" name="birth_day" id="birth_day" :disabled="flag.isConfirm" v-model="row.value.day">
                  <option
                    v-for="n of 31"
                    :key="n"
                    :value="n">{{ n }}</option>
                </select>日
              </li>
            </ul>
          </div>

          <!--住所-->
          <div :class="$style.detail" v-if="row.type === 'address'">
            <spacer :y="2"/>
            <Address
              @sendValue="receiveValue"
              :items="items"
              :isConfirm="flag.isConfirm" />
          </div>

          <!--VCID-->
          <div :class="$style.detail" v-if="row.type === 'vcid'">
            <template v-if="isVirtualSpecialist">
              <div
                :class="[
                  $style.vcid,
                  flag.isConfirm ? null : $style.force_link
                ]"
                v-on:click="clickVcidInputButton"
              >
                <input-codes
                  :key="`regist-counselor-vcid-${row.value}`"
                  :digit="6"
                  :value="row.value"
                  :smallMode="true"
                  :readonly="true"
                />
              </div>
              <p
                v-if="row.value"
                :class="$style.assistant"
              ><a :href="getVirtualCastUserURL(row.value)" target="_blank">{{ getVirtualCastUserURL(row.value) }}</a></p>
            </template>
            <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
          </div>
        </div>

        <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">メールアドレス</p>
            <p :class="$style.required">※必須項目</p>
          </div>
          <div :class="$style.detail">
            <div>
              <input
                :class="$style.text"
                :disabled="flag.isConfirm"
                placeholder="メールアドレス"
                v-model.trim="$v.mail.$model"
                id="mail"
                type="email"
                name="mail"
                v-on:input="invalidsCheck('mail', $v.mail.$invalid)">
              <p
                :class="$style.required"
                v-if="$v.mail.$dirty
                  && $v.mail.required.$invalid">メールアドレスを入力してください</p>
              <p
                :class="$style.required"
                v-if="$v.mail.$dirty
                  && $v.mail.email.$invalid">正しいメールアドレスの形式で入力してください</p>
            </div>
          </div>
        </div>

        <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">パスワード</p>
            <p :class="$style.required">※必須項目</p>
          </div>
          <div :class="$style.detail">
            <div>
              <input
                :class="$style.text"
                placeholder="パスワード"
                :disabled="flag.isConfirm"
                type="text"
                id="password"
                name="password"
                v-model.trim="$v.password.$model"
                v-on:input="invalidsCheck('password', $v.password.$invalid)">
            </div>
            <p
              :class="$style.required"
              v-if="$v.password.$dirty
                && $v.password.minLength.$invalid">パスワードは8文字以上で設定してください</p>
            <p :class="$style.assistant" v-if="!flag.isConfirm">半角英数字8桁以上15桁以下</p>
          </div>
        </div>

      </div>
      <spacer :y="5"/>
      <btn-container>
        <basic-btn
          tag="button"
          v-if="!flag.isConfirm"
          v-on:click="changeFlag">入力内容を確認する</basic-btn>
        <basic-btn
          tag="button"
          v-if="flag.isConfirm"
          v-on:click="changeFlag">編集画面に戻る</basic-btn>
        <spacer :x="2"/>
        <basic-btn
          tag="button"
          v-if="flag.isConfirm"
          v-on:click="regist">入力内容を確定する</basic-btn>
      </btn-container>
    </contents-box>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import {
  email,
  required,
  minLength,
} from '@vuelidate/validators';
import { mapState } from 'vuex';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import InputCodes from '@/views/components/parts/Form/InputCodes.vue';
import Spacer from '@/views/components/Spacer.vue';
import { Address } from '@/views/components/parts/Form';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'Operator-Register-RegistCounselor.vue',
  mixins: [cf],
  components: {
    BasicBtn,
    BtnContainer,
    ContentsBox,
    InputCodes,
    Spacer,
    Address,
  },
  data() {
    return {
      flag: {
        isConfirm: false,
        readySubmit: false,
      },
      validationFlags: {
        mail: false,
        password: false,
        kana: false,
      },
      invalids: {
        mail: true,
        password: true,
        minLength: true,
        kana: true,
      },
      contents: [
        {
          type: 'radio',
          name: 'account_type',
          label: '利用者種別',
          required: true,
          value: 21,
          datatype: 1,
          options: [
            {
              name: 'doctor',
              label: '医師',
              value: 21,
            },
            {
              name: 'psychologist',
              label: '心理士',
              value: 22,
            },
          ],
        },
        {
          type: 'text',
          name: 'username',
          label: '名前',
          required: true,
          placeholder: '例：三上　春斗',
          value: '',
          datatype: 1,
        },
        {
          type: 'text',
          name: 'kana',
          label: 'フリガナ',
          required: true,
          placeholder: '例：ミカミ　ハルト',
          datatype: 1,
        },
        {
          type: 'radio',
          name: 'gender',
          label: '性別',
          required: true,
          value: null,
          datatype: 1,
          options: [
            {
              name: 'male',
              label: '男性',
              value: 1,
            },
            {
              name: 'female',
              label: '女性',
              value: 2,
            },
          ],
        },
        {
          type: 'birthday',
          name: 'birthday',
          label: '生年月日',
          required: true,
          datatype: 1,
          value: {
            year: '',
            month: '',
            day: '',
          },
        },
        {
          type: 'address',
          name: 'address',
          label: '住所',
          required: true,
          value: '',
          datatype: 2,
        },
        {
          type: 'text',
          name: 'tel',
          label: '電話番号',
          required: true,
          value: '',
          datatype: 1,
        },
        // {
        //   type: 'text',
        //   name: 'fax',
        //   label: 'FAX',
        //   value: '',
        //   datatype: 1,
        // },
        {
          type: 'text',
          name: 'specialty',
          label: '専門領域',
          value: '',
          datatype: 3,
        },
        {
          type: 'textarea',
          name: 'qualifications',
          label: '資格',
          value: '',
          datatype: 3,
        },
        {
          type: 'textarea',
          name: 'carrer',
          label: '経歴',
          placeholder: 'xxxx年　〜　xxxx年　経歴 の形で入力してください',
          value: '',
          datatype: 3,
        },
        {
          type: 'text',
          name: 'birthplace',
          label: '出身',
          value: '',
          datatype: 3,
        },
        {
          type: 'text',
          name: 'hobby',
          label: '趣味',
          value: '',
          datatype: 3,
        },
        {
          type: 'text',
          name: 'blog',
          label: 'ブログURL',
          value: '',
          datatype: 3,
        },
        {
          type: 'text',
          name: 'note',
          label: '特記',
          value: '',
          datatype: 3,
        },
        {
          type: 'check',
          name: 'is_virtual_specialist',
          label: 'バーチャル対応専門家',
          value: '0',
          datatype: 3,
        },
        {
          type: 'vcid',
          name: 'virtual_cast_id',
          label: 'バーチャルキャストID',
          value: null,
          datatype: 5,
          assistant: 'バーチャル対応専門家の場合は入力必須です',
        },
        {
          type: 'text',
          name: 'virtual_cast_room_key',
          label: 'バーチャルキャストルームキー',
          value: null,
          datatype: 5,
          assistant: 'バーチャル対応専門家の場合は入力必須です',
        },
        {
          type: 'check',
          name: 'is_risk_application_approver',
          label: 'リスク評価申請承認者',
          value: '0',
          datatype: 3,
        },
        {
          name: 'account_name',
          type: 'text',
          label: '口座名義',
          required: true,
          value: null,
          datatype: 4,
        },
        {
          name: 'bank_name',
          type: 'text',
          label: '銀行名',
          required: true,
          value: null,
          datatype: 4,
        },
        {
          name: 'branch_name',
          type: 'text',
          label: '支店名',
          required: true,
          value: null,
          datatype: 4,
        },
        {
          type: 'radio',
          name: 'deposit_type',
          label: '預金種目',
          required: true,
          value: null,
          datatype: 4,
          options: [
            {
              name: '普通',
              label: '普通',
              value: 1,
            },
            {
              name: '当座',
              label: '当座',
              value: 2,
            },
            {
              name: '貯蓄',
              label: '貯蓄',
              value: 3,
            },
            {
              name: 'その他',
              label: 'その他',
              value: 4,
            },
          ],
        },
        {
          name: 'account_number',
          type: 'text',
          label: '口座番号',
          required: true,
          value: null,
          datatype: 4,
        },
      ],
      items: [
        {
          name: 'zip',
          type: 'text',
          label: '郵便番号',
          value: null,
          required: true,
          placeholder: '1020074',
          comment: '半角・ハイフンなしで入力してください',
        },
        {
          name: 'pref',
          type: 'select',
          label: '都道府県',
          value: null,
          options: [],
          required: true,
          comment: '',
        },
        {
          name: 'city',
          type: 'text',
          label: '市区町村',
          value: null,
          required: true,
          placeholder: '千代田区九段南',
        },
        {
          name: 'address',
          type: 'text',
          label: '番地',
          value: null,
          required: false,
          placeholder: '1-5-6',
        },
        {
          name: 'building',
          type: 'text',
          label: '建物名・部屋番号',
          placeholder: 'りそな九段ビル5階 KSフロア',
          value: null,
        },
      ],
      address: null,
      counselorID: null,
    };
  },
  setup() {
    const mail = ref('');
    const password = ref('');
    const kana = ref('');

    // カタカナ・半角スペース・全角スペースのみ許す
    const kanaTextRule = (value) => /^[ァ-ヶー\s　]+$/u.test(value);

    const rules = {
      // メールは必須・email形式
      mail: { required, email },
      // パスワードは必須
      password: {
        required,
        minLength: minLength(8),
      },
      kana: {
        required,
        custom: kanaTextRule,
      },
    };

    const $v = useVuelidate(rules, {
      mail,
      password,
      kana,
    });

    return {
      mail,
      password,
      kana,
      $v,
    };
  },
  created() {
    if (this.helper.master.prefs) {
      this.items[1].options = this.helper.master.prefs;
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/putMaster') {
          this.items[1].options = mutation.payload.prefs;
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
    isVirtualSpecialist() {
      const target = this.contents.find((row) => row.name === 'is_virtual_specialist');
      return target.value === '1';
    },
    virtualCastIdRow() {
      return this.contents.find((row) => row.name === 'virtual_cast_id');
    },
    virtualCastRoomKeyRow() {
      return this.contents.find((row) => row.name === 'virtual_cast_room_key');
    },
  },
  methods: {
    optionYear(n) {
      const year = Number(this.formatTimestamp(new Date(), 'YYYY')) + 1 - n;
      return year;
    },
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },
    /** 子から値を受け取る */
    receiveValue(values) {
      this.flag.readySubmit = values.readySubmit;
      this.address = values.address;
    },
    changeFlag() {
      let filledRequired = true;
      this.contents.forEach((row) => {
        if (row.required) {
          if (row.name === 'address') {
            filledRequired = true;
          } else if (row.name === 'birthday') {
            Object.keys(row.value).forEach((key) => {
              if (!row.value[key]) filledRequired = false;
            });
          } else if (row.value === null || row.value === '') {
            filledRequired = false;
          }
        }
      });

      if (!filledRequired) {
        alert('必須項目が未入力です');
        return;
      }
      if (this.invalids.mail || this.invalids.password) {
        alert('メールアドレスまたはパスワードを正しく入力してください。');
        return;
      }
      if (!this.flag.readySubmit) {
        alert('住所の項目に不備があります。');
        return;
      }
      if (this.invalids.kana) {
        alert('フリガナが正しく入力されていません。');
        return;
      }
      if (this.isVirtualSpecialist) {
        if (!this.virtualCastIdRow.value) {
          alert('バーチャルキャストIDを入力してください。');
          return;
        }
        if (!this.virtualCastRoomKeyRow.value) {
          alert('バーチャルキャストルームキーを正しく入力してください。');
          return;
        }
      }

      this.flag.isConfirm = !this.flag.isConfirm;

      if (this.flag.isConfirm) {
        const elem = document.getElementById('confirm');
        const position = elem.getBoundingClientRect().top;
        scrollBy(0, position - 77);
      }
    },

    /** checkbox項目のON/OFF切り替え時に作動するメソッド */
    changeCheckboxValue(event, name) {
      // "バーチャル対応専門家"のチェックをOFFにしたタイミングでバーチャルキャスト関連の値を空にする
      if (name === 'is_virtual_specialist' && !event.target.checked) {
        this.contents.forEach((row) => {
          if (row.datatype === 5) {
            row.value = null;
          }
        });
      }
    },

    /** 登録 */
    async regist() {
      this.showLoading();
      const data = {
        email: this.mail,
        password: this.password,
        kana: this.kana,
        additionalData: {},
        bankData: {},
        virtualCastProp: this.isVirtualSpecialist ? {} : null,
      };
      this.contents.forEach((row) => {
        if (row.datatype === 1) {
          if (row.name === 'birthday') {
            const m = row.value.month < 10 ? `0${row.value.month}` : row.value.month;
            const d = row.value.day < 10 ? `0${row.value.day}` : row.value.day;
            data[row.name] = `${row.value.year}-${m}-${d}`;
          } else if (row.name !== 'kana') {
            data[row.name] = row.value;
          }
        } else if (row.datatype === 2) {
          data.addressData = this.address;
          data.addressData.model_type = 1;
        } else if (row.datatype === 3) {
          data.additionalData[row.name] = row.value;
        } else if (row.datatype === 4) {
          data.bankData[row.name] = row.value;
        } else if (row.datatype === 5) { // virtualCastProps テーブルへの情報
          if (!this.isVirtualSpecialist) {
            // バーチャル対応専門家の場合のみセットする
            return;
          }
          data.virtualCastProp[row.name] = row.value;
        }
      });

      await this.axios({ // 医師・心理士の情報を登録
        method: 'POST',
        url: '/v1/user/set/register',
        data,
      })
        .then(() => {
          const type = data.account_type === 21 ? '医師' : '心理士';
          alert(`${type}の登録が完了しました。`);
          this.hideLoading();
          this.$router.push('/operator/?tab=counselor');
        })
        .catch((error) => {
          console.log(error);
          alert('医師・心理士の登録に失敗しました。もう一度やり直してください。');
          this.hideLoading();
        });
    },

    /** VCID入力モーダルを開く */
    clickVcidInputButton() {
      if (this.flag.isConfirm) {
        return;
      }
      const args = {
        modalName: 'input-vcid',
        data: {
          callback: (vcid) => {
            this.virtualCastIdRow.value = vcid;
          },
        },
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
  },
};
</script>

<style lang="scss" module>
.row {
  padding: 24px 0;
  border-top: 1px solid var(--gray-main);
}
.label {
  border-radius: 4px;
  margin-right: 4px;
  font-weight: bold;
  display: inline-block;
  &_wrap {
    display: flex;
    align-items: baseline;
  }
}
.text, .select {
  &:disabled {
    color: black;
    background-color: transparent;
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }
  }
}
.required {
  font-size: 12px;
  color: red;
}
.assistant {
  font-size: 12px;
}
.space {
  padding-top: 6px;
}
.text {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
}
.radio {
  padding-top: 8px;
  display: flex;

  li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
.select {
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;
  appearance: none;

  &_wrap {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.tel {
  width: 70px;
  padding: 13px;
}
.thumbnail {
  width: 100px;
  padding-top: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin-right: 20px;
  border: 1px solid rgba(#aaa, 0.3);
  &_wrap {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  &_btn {
    display: inline-block;
    border-radius: 22px;
    padding: 6px 15px;
    font-size: 14px;
    border: 1px solid var(--black);
    &:not(:first-child) {
      margin-left: 1em;
    }
  }
}
.vcid {
  width: 200px;
}
.force_link {
  cursor: pointer;
  * {
    cursor: pointer;
  }
}
</style>

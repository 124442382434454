<template>
  <div class="n2br" :class="$style.counselorlist">
    <spacer :y="5"/>
    <ul>
      <stack-item>
        <contents-box type="inner">
          <div :class="$style.regist">
            <p :class="$style.regist_title">医師・心理士登録</p>
            <basic-btn to="/operator/regist-counselor/">登録する</basic-btn>
          </div>
        </contents-box>
      </stack-item>
      <stack-item>
        <contents-box title="医師・心理士一覧">
          <loader-simple :flag="flag.loader">
            <ul>
              <stack-item space="2"
                v-for="row of counselors"
                :key="row.id">
                <user-box v-if="row.flag !== 999" type="inner" :showId="true" :user="row" :ownUser="user" :showMessageBtn="true" :showAge="false" :bg="row.flag !== 1 ? 'gray' : 'white'">
                  <template v-slot:btn >
                    <div :class="$style.icons">
                      <basic-btn
                        v-on:click="changeStatusFlag(row)"
                        tag="button"
                        type="bdr"
                        size="sm">{{ row.flag === 1 ? '非アクティブにする' : 'アクティブにする'}}</basic-btn>
                      <spacer :x="2"
                        v-if="!openIds.includes(row.id)"
                        :class="$style.only_pc"/>
                      <basic-btn
                        tag="button"
                        v-if="!openIds.includes(row.id) && helper.env.name !== 'production'"
                        size="sm"
                        addClass="danger"
                        v-on:click="deleteUser($event, {
                          id: row.id,
                          username: row.username,
                          email: row.email,
                        })">削除</basic-btn>
                      <spacer :y="1" :class="$style.only_sp"/>
                      <router-link :to="`/operator/update-counselor/${row.id}`">
                        <i
                          v-if="openIds.includes(row.id)"
                          :class="$style.open"
                          class="fa-solid fa-pen-to-square"></i>
                      </router-link>
                      <i
                        v-if="openIds.includes(row.id)"
                        :class="$style.open"
                        class="fa-regular fa-minus"
                        v-on:click="changeOpenIds(row.id)"></i>
                      <i
                        v-if="!openIds.includes(row.id)"
                        :class="$style.open"
                        class="fa-regular fa-plus"
                        v-on:click="changeOpenIds(row.id)"></i>
                    </div>
                  </template>

                  <template v-slot:main v-if="openIds.includes(row.id)">
                    <spacer :y="3"/>
                    <p :class="$style.counselorComment">{{ row.comment }}</p>
                    <spacer :y="3"/>
                    <ul :class="$style.detail" class="n2br">
                      <li>
                        <i :class="$style.icon" class="fa-regular fa-envelope"></i>
                        <p :class="$style.content">{{ row.email }}</p>
                      </li>
                      <li v-if="row.tel">
                        <i :class="$style.icon" class="fa-solid fa-phone"></i>
                        <p :class="$style.content">{{ row.tel }}</p>
                      </li>
                      <li v-if="row.address">
                        <i :class="$style.icon" class="fa-regular fa-house"></i>
                        <p :class="$style.content">〒{{ row.address.zip }}　{{ row.address.pref }}{{ row.address.city }}{{ row.address.address }}{{ row.address.building }}</p>
                      </li>
                      <li :class="$style.flexstart">
                        <label-box
                          :class="$style.label"
                          label="趣味"
                          size="xs" color="orange" />
                        <p :class="$style.content" v-html="row.medicalWorker.hobby"/>
                        <!-- <p :class="$style.content" v-html="row.medicalWorker.hobby"></p> -->
                      </li>
                      <li :class="$style.flexstart">
                        <label-box
                          :class="$style.label"
                          label="専門領域"
                          size="xs" color="orange" />
                        <p :class="$style.content" v-html="row.medicalWorker.specialty"/>
                      </li>
                      <li :class="$style.flexstart">
                        <label-box
                          :class="$style.label"
                          label="資格"
                          size="xs" color="orange" />
                        <p :class="$style.content" v-html="row.medicalWorker.qualifications"/>
                      </li>
                      <li :class="$style.flexstart">
                        <label-box
                          :class="$style.label"
                          label="出身地"
                          size="xs" color="orange" />
                        <p :class="$style.content" v-html="row.medicalWorker.birthplace"/>
                      </li>
                      <li :class="$style.flexstart">
                        <label-box
                          :class="$style.label"
                          label="経歴"
                          size="xs" color="orange" />
                        <p :class="$style.content" v-html="row.medicalWorker.carrer"/>
                      </li>
                      <li :class="$style.flexstart">
                        <label-box
                          :class="$style.label"
                          label="備考"
                          size="xs" color="orange" />
                        <p :class="$style.content" v-html="row.medicalWorker.note"/>
                      </li>
                      <li :class="$style.flexstart">
                        <label-box
                          :class="$style.label"
                          label="ブログ"
                          size="xs" color="orange" />
                        <a :href="row.medicalWorker.blog" target="_blank" :class="$style.blog">
                          <p :class="$style.content" v-html="row.medicalWorker.blog"/>
                        </a>
                      </li>
                    </ul>
                  </template>
                </user-box>
              </stack-item>
            </ul>
          </loader-simple>
        </contents-box>
      </stack-item>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import TwoColumn from '@/views/layouts/TwoColumn.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import UserBox from '@/views/components/UserBox.vue';
// import BtnContainer from '@/views/components/BtnContainer.vue';
// import TabNav from '@/views/components/TabNav';
// import RoundImg from '@/views/components/RoundImg.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';

export default {
  name: 'operator-user-list',
  components: {
    BasicBtn,
    // BtnContainer,
    LabelBox,
    // TwoColumn,
    UserBox,
    // TabNav,
    // RoundImg,
    ContentsBox,
    StackItem,
    LoaderSimple,
    Spacer,
  },
  data() {
    return {
      flag: {
        loader: true,
      },
      counselors: [],
      openIds: [],
    };
  },
  created() {
    this.getCounselor();
  },
  computed: {
    ...mapState(['helper', 'user']),
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    getCounselor() {
      const params = {
        accountTypes: [21, 22],
        order: 'kana',
        includeDeleted: 1,
      };
      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.counselors = res.users.data;
          this.flag.loader = false;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    changeOpenIds(id) {
      if (this.openIds.includes(id)) {
        const idx = this.openIds.indexOf(id);
        this.openIds.splice(idx, 1);
      } else {
        this.openIds.push(id);
      }
    },

    deleteUser(e, args) {
      e.preventDefault();
      const deleteConfirm = confirm(`${args.username}さんの登録を削除します。よろしいですか？`);
      if (!deleteConfirm) return;

      this.showLoading();
      const data = {
        id: args.id,
        flag: 999,
      };

      // 目的はメールアドレスの再利用なので、updateに統一
      // メールアドレスに乱数を付与して以後の重複を回避
      if (this.helper.env.name !== 'production') {
        const rdm = Math.floor(Math.random() * 101);
        data.email = `${args.email}${rdm}`;
      }

      this.axios({
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then(() => {
          alert(`${args.username}さんの登録を削除しました。`);
          // 再読み込み
          this.counselors = [];
          this.getCounselor();
        })
        .catch((error) => {
          alert(`${args.username}さんの削除に失敗しました。`);
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    changeStatusFlag(user) {
      const label = user.flag === 1 ? '非アクティブ' : 'アクティブ';
      if (!confirm(`このアカウントを${label}してよろしいですか？\n${user.username}`)) return;
      const data = {
        id: user.id,
        flag: user.flag === 1 ? 998 : 1,
        account_type: user.account_type,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then(() => {
          this.getCounselor();
          alert(`${label}化が完了しました。`);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          alert('更新に失敗しました。');
          this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module>
// .counselorlist {
//   font-size: 14px;
// }
.regist {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_title {
    font-size: 20px;
    font-weight: bold;
  }
  @include sm-view {
    display: block;
  }
}
.icons {
  display: flex;
  align-items: center;
  @include sm-view {
    display: block;
    text-align: right;
  }
}
.open {
  font-size: 25px;
  font-weight: bold;
  color: var(--gray-side);
  cursor: pointer;
  margin-left: 20px;
  @include sm-view {
    margin-top: 10px;
  }
}
.detail {
  li {
    display: flex;
    align-items: center;
    &.flexstart {
      align-items: flex-start;
    }
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    .icon {
      font-size: 20px;
      color: var(--orange-main);
      margin-right: 10px;
    }
    .label {
      width: 60px;
      white-space: nowrap;
      margin-right: 10px;
    }
    .content {
      flex: 1;
      @include sm-view {
        font-size: 14px;
      }
    }
  }
}
.counselorComment {
  @include sm-view {
    font-size: 14px;
  }
}
.blog {
  color: var(--black);
  &:hover {
    text-decoration: underline;
  }
}
.inactive {
  font-size: 12px;
  padding: 2px 12px;
  border-radius: 12px;
  border: 1px solid var(--black);
  cursor: pointer;
}
.only_pc {
  @include sm-view {
    display: none;
  }
}
.only_sp {
  display: none;
  @include sm-view {
    display: block;
  }
}
</style>

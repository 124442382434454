<template>
  <div :class="$style.wrapper">
    <loader-simple :flag="flag.loader">
      <div
        v-if="flag.invalid"
        :class="$style.invalid">
        <p :class="$style.image"><img src="@/assets/images/logo_vertical.png" alt="welcome to talk"></p>
        <p :class="$style.text">Welcome to talkサービスとの接続が確立できませんでした。<br>恐れ入りますが、時間をおいてから再度お試しください。</p>
      </div>

      <div
        v-if="flag.invalidUser"
        :class="$style.invalid">
        <p :class="$style.image"><img src="@/assets/images/logo_vertical.png" alt="welcome to talk"></p>
        <p :class="$style.text">恐れ入りますが、Welcome to talkのサービスへはブラウザからログインしてください。<br><a href="https://welcometotalk.com/login/" target="_blank">https://welcometotalk.com/login/</a></p>
      </div>

      <div
        v-if="flag.notFoundTemporaryUser"
        :class="$style.invalid">
        <p :class="$style.image"><img src="@/assets/images/logo_vertical.png" alt="welcome to talk"></p>
        <p :class="$style.text">利用者情報の連携に失敗しています。<br>恐れ入りますが学校管理者様へご連絡の上、<br>Welcome to talk事務局までご連絡くださいますようお願いいたします。</p>
      </div>

      <div
        v-if="!flag.invalid && flag.isRegister">
        <p>Welcome to talkをご利用いただきありがとうございます。<br>ご利用にあたり、必要な下記情報を入力してください。<br>（初回のみ必要です。）</p>
        <Signup
          :lti="lti"
          :wtt="wtt"
          @registered="receiveRegistData" />
      </div>
    </loader-simple>
  </div>
</template>

<script>
// import { mapState } from 'vuex';
import axios from 'axios';
import Signup from '@/views/pages/User/Signup-LTI.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import localData from '@/data/develop/lti_user.json';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'LTI-Login',
  mixins: [cf],
  components: {
    Signup,
    LoaderSimple,
  },
  data() {
    return {
      flag: {
        loader: true,
        invalid: false,
        invalidUser: false,
        notFoundTemporaryUser: false,
        isRegister: false,
      },
      lti: {
        key: null,
        info: null,
      },
      wtt: {
        clientId: null,
        user: null,
      },
    };
  },
  created() {
    // LMSのTOKEN取得
    this.getLtik();
  },
  computed: {
  },
  methods: {
    /**
     * LMSで認証されたリクエストはltikパラメータ（TOKEN）が
     * 付与されているため抽出する
     */
    getLtik() {
      const query = this.$route.query;
      if (process.env.NODE_ENV === 'local') {
        // ローカル開発用（実環境では/LTI/infoから情報取得）
        this.lti.info = localData;
        this.userVerification();
      } else if (query.ltik) {
        // ローカル以外
        this.lti.key = query.ltik;
        // LMSのユーザ情報取得
        this.getLTIInfo();
      } else {
        // ltikパラメータが存在しない場合は不正
        this.flag.invalid = true;
        // throw new Error('Missing lti key.')
      }
    },

    /**
     * LMSから取得・格納されたユーザー情報をAUTHサーバから取得
     */
    async getLTIInfo() {
      try {
        const response = await axios({
          method: 'GET',
          url: '/lti/info',
          headers: {
            Authorization: `Bearer ${this.lti.key}`,
          },
        });
        this.lti.info = response.data;
        // lti.infoを取得したら、LTIログインユーザとWTTユーザを照合
        this.userVerification();
      } catch (error) {
        if (error.response) console.log(error.response);
        else console.log(error);
        console.log(`Failed trying to retrieve custom parameters! ${error}`);
        this.flag.invalid = true;
      }
    },

    /**
     * ユーザの照合
     */
    async userVerification() {
      // LTIログインユーザのemail
      const email = this.lti.info.user.email;
      try {
        const response = await this.axios({
          method: 'GET',
          url: '/v1/user/get/detail',
          params: { email, isOperatorMessage: 0 },
        });
        console.log(response.data.data.account_type);
        this.wtt.user = response.data.data;
        if (this.wtt.user && this.wtt.user.account_type < 10) {
          // wttユーザーの自動ログイン処理
          this.userLogin();
        } else {
          // 利用者でない場合は許可しない
          this.flag.invalidUser = true;
          this.flag.loader = false;
        }
      } catch (error) {
        const response = error.response;
        if (response?.status === 404) {
          // 404の場合は新規登録
          const ls = cf.getLocalStorage('wtt');
          if (ls) cf.deleteLocalStorage('wtt');
          await this.getSchoolData();
        } else {
          console.log(error.message);
          this.flag.invalid = true;
        }
      }
    },

    /**
     * lti.infoからclient_idをキーにして該当校情報取得
     */
    async getSchoolData() {
      try {
        // lti.infoからclient_idをキーにして学校取得
        const ltiClientId = await this.axios({
          method: 'GET',
          url: '/v1/lti/client_id/detail',
          params: {
            client_id: this.lti.info.client_id,
            withSchool: 1,
          },
        });
        const data = ltiClientId.data.data;
        this.wtt.clientId = data;
        this.registUser();
      } catch (error) {
        if (error.response) console.log(error.response);
        else console.log(error);
        this.flag.invalid = true;
      }
    },

    /**
     * ユーザー登録（更新）
     * LIT対応校はアカウント課金前提のため
     * 事前にユーザー名とカナが登録済みである
     * ユーザー名と学校IDからwtt.userを特定しupdateする
     */
    async registUser() {
      try {
        // usernameと所属学校ID（と、あれば学年）が一致するユーザーを取得
        const temporaryUser = await this.axios({
          method: 'GET',
          url: '/v1/user/get/detail',
          params: {
            username: this.lti.info.user.name,
            schoolId: this.wtt.clientId.school_id,
            // 学年情報がLTIから渡るかはまだ不明
            schoolYear: this.lti.info.user.schoolYear || null,
            emptyEmail: 1, // メールアドレスが空のみ（本登録済みユーザは除外）
            isOperatorMessage: 0,
          },
        });
        this.wtt.user = temporaryUser.data.data;
        // flagを切り替える事で入力フォームを表示
        this.flag.isRegister = true;
        this.flag.loader = false;
      } catch (error) {
        this.flag.loader = false;
        if (error.response) {
          const data = error.response.data;
          if (data.status === 404) { // 仮登録ユーザが見つからない
            this.flag.notFoundTemporaryUser = true;
          } else {
            this.flag.invalid = true;
          }
        } else {
          console.log(error);
          this.flag.invalid = true;
        }
      }
    },

    /**
     * フォーム入力・ユーザー新規登録後に戻ってくる
     */
    async receiveRegistData() {
      try {
        this.flag.loader = true;
        // 新規ユーザー登録入力フォーム非表示
        this.flag.isRegister = false;
        // ユーザーIDとclientIdの連関作成
        const data = {
          client_id: this.wtt.clientId.id,
          user_id: this.wtt.user.id,
        };
        await this.axios({
          method: 'POST',
          url: '/v1/lti/user/register',
          data,
        });
        // ユーザ取得へ再帰
        this.userVerification();
      } catch (error) {
        if (error.response) console.log(error.response);
        else console.log(error);
        this.flag.invalid = true;
      }
    },

    async userLogin() {
      this.flag.loader = false;
      const data = {
        mail: this.wtt.user.email,
        password: `${this.wtt.user.email}*2`,
      };

      this.$store.dispatch('user/login/submit', data);
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  padding-top: 50px;
}
.invalid {
  text-align: center;
  .image {
    max-width: 380px;
    margin: 0 auto;
  }
  .text {
    margin-top: 20px;
  }
}
</style>

<template>
  <div :class="classname">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DataRow',
  props: {
    noBorder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classname() {
      return `${this.$style.dataRow} ${this.noBorder ? this.$style.noBorder : ''}`;
    },
  },
};
</script>

<style lang="scss" module>
.dataRow {
  display: table-row;
  width: 100%;
  border-top: 1px solid #ddd;

  &.noBorder {
    border-top: none;
  }

  @include sm-view {
    display: block;
  }
}
</style>

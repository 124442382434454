<template>
  <div :class="$style.container">
    <div :class="$style.mainColumn">
      <slot name="main" />
    </div>
    <div :class="$style.sideColumn">
      <slot name="side" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwoColumn',
};
</script>

<style module lang="scss">
.container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  @include md-view {
    display: block;
  }
}

.mainColumn {
  display: block;
  width: 100% * 560 / 958;

  @include md-view {
    width: 100%;
  }
}

.sideColumn {
  display: block;
  width: 100% * 360 / 958;

  @include md-view {
    width: 100%;
    margin-top: calc(var(--spacer-base) * 3);
  }
}
</style>

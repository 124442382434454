<template>
  <div>
    <h2 :class="$style.title">Virtual Cast ID</h2>
    <spacer :y="2"/>
    <div>
      <div :class="$style.code-container">
        <p :class="$style.assistance">6桁の Virtual Cast ID を入力してください。</p>
        <input-codes
          :digit="6"
          @inputComplete="inputComplete"
          @inputIncomplete="inputIncomplete"
        />
      </div>
    </div>
    <template v-if="virtualCastId">
      <spacer :y="3"/>
      <div :class="$style.policy">
        <p :class="$style.text">リンクをクリックして Virtual Cast ID が正しいか確認してください。</p>
        <p>
          <a
            v-on:click="clickLink"
            :href="getVirtualCastUserURL(virtualCastId)"
            target="_blank"
          >{{ getVirtualCastUserURL(virtualCastId) }}</a>
        </p>
        <template v-if="flg.clickedLink">
          <spacer :y="3"/>
          <div>
            <input
              type="checkbox"
              id="vcid-checked"
              v-on:change="changePolicy"
              v-bind:disabled="!flg.clickedLink"
            >
            <label for="vcid-checked">確認しました</label>
          </div>
        </template>
      </div>
      <template v-if="flg.checkedPolicy">
        <spacer :y="3"/>
        <div :class="$style.bottom">
          <button
            type="button"
            :class="$style.btn"
            :disabled="!flg.checkedPolicy"
            v-on:click="clickSet">IDを決定する</button>
        </div>
      </template>
    </template>
    <div :class="$style.close" v-on:click="hideModal"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions.js';
import Spacer from '@/views/components/Spacer.vue';
import InputCodes from '@/views/components/parts/Form/InputCodes.vue';

export default {
  name: 'input-vcid',
  props: ['data'], // callback: function
  mixins: [cf],
  components: {
    Spacer,
    InputCodes,
  },
  data() {
    return {
      virtualCastId: null,
      flg: {
        clickedLink: false,
        checkedPolicy: false,
      },
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    changePolicy(e) {
      this.flg.checkedPolicy = e.target.checked;
    },

    clickLink() {
      this.flg.clickedLink = true;
    },

    clickSet() {
      this.data.callback(this.virtualCastId);
      this.hideModal();
    },

    hideModal(args = null) {
      this.$store.dispatch('modal/contents/hideModal', args, { root: true });
    },

    // コード入力され、VCID6桁がすべて入力された
    inputComplete(value) {
      // 確認フラグを初期化する
      this.flg.clickedLink = false;
      this.flg.checkedPolicy = false;
      // 入力されたVCIDを反映する
      this.virtualCastId = value;
    },
    // コード入力され、VCID6桁のうちいずれかが入力されていない
    inputIncomplete() {
      this.virtualCastId = null;
    },
  },
};
</script>

<style lang="scss" module>
.title {
  @include sm-view {
    font-size: 16px;
  }
}
.policy {
  padding: 13px;
  border: 1px solid var(--pink);
  border-radius: 8px;
}

.input {
  margin-left: 0;
  input {
    width: 100%;
    padding: 13px;
    border-radius: 8px;
    border: none;
    background-color: var(--gray-sub);
    outline: none;
  }
}
.text {
  font-size: 12px;
}
.bottom {
  display: flex;
  justify-content: center;
}
.btn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
}
.close {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background-color: var(--gray-main);
  border-radius: 50%;
  cursor: pointer;
  &::before, &::after {
    content: '';
    width: 3px;
    height: 30px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  @include sm-view {
    width: 20px;
    height: 20px;
    &::before, &::after {
      content: '';
      width: 2px;
      height: 13px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
}
.required {
  font-size: 12px;
  color: red;
}
.assistance {
  font-size: 12px;
}
</style>

<template>
  <div :class="className">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DataTerm',
  props: {
    width: {
      type: String,
      default: 'half',
      validation: (value) => ['half', 'quater'].includes(value),
    },
  },
  computed: {
    className() {
      return `${this.$style.dataTerm} ${this.$style[this.width]}`;
    },
  },
};
</script>

<style lang="scss" module>
.dataTerm {
  display: table-cell;
  padding: calc(var(--spacer-base) * 3) calc(var(--spacer-base) * 3) calc(var(--spacer-base) * 3) 0;
  vertical-align: top;
  width: 50%;
  &.half {
    width: 50%;
  }
  &.quater {
    width: 25%;
  }

  @include sm-view {
    display: block;
    padding: calc(var(--spacer-base) * 2) 0 var(--spacer-base);
    &.half, &.quater {
      width: auto;
    }
  }
}
</style>

<template>
  <div>
    <spacer :y="8.5"/>
    <div :class="$style.slider">
      <img src="/img/dummy/slider.png" alt="" />
    </div>
    <spacer :y="8.5"/>
    <tab-nav />
    <spacer :y="6"/>
    <two-column>
      <template v-slot:main >
        <ul>
          <stack-item>
            <user-box>
              <p>
                子どもたちのこころのケアに携わるなかで、教育現場での仕事にも興味があったことから、医療と教育を結ぶWelcome to talkに参画しました。
              </p>
              <spacer :y="4" />
              <btn-container>
                <basic-btn to="/">オンライン相談する</basic-btn>
              </btn-container>
            </user-box>
          </stack-item>
          <stack-item>
            <user-box>
              <p>
                子どもたちのこころのケアに携わるなかで、教育現場での仕事にも興味があったことから、医療と教育を結ぶWelcome to talkに参画しました。
              </p>
              <spacer :y="2" />
              <hr color="#aaa" size="1"/>
              <spacer :y="2"/>
              <ul :class="`${$style.action}`">
                <li>いいね</li>
                <li>コメント</li>
                <li>シェア</li>
              </ul>
            </user-box>
          </stack-item>
        </ul>
      </template>
      <template v-slot:side >
        <ul>
          <stack-item>
            <contents-box title="相談する" >
              <p>4月2日17時～オンライン健康相談の予約が入っています。</p>
              <spacer :y="2"/>
              <btn-container>
                <basic-btn size="sm" to="/">オンライン健康相談</basic-btn>
                <spacer :x="1"/>
                <!-- <basic-btn size="sm" to="/">テキスト相談</basic-btn> -->
              </btn-container>
            </contents-box>
          </stack-item>
          <stack-item>
            <contents-box title="学校だより" >
              <p>明日は雪のため休校です。</p>
            </contents-box>
          </stack-item>
          <stack-item>
            <contents-box title="ココモニ" >
              <img src="/img/dummy/mykarte.jpg" alt="ココモニ" style="width: 100%;">
              <spacer :y="2" />
            </contents-box>
          </stack-item>
          <stack-item>
            <contents-box title="事務局からのお知らせ" >
              <spacer :y="2" />
            </contents-box>
          </stack-item>
          <stack-item :space="5">
            <p>広告</p>
            <spacer :y="1"/>
            <stack-item tag="div">
              <img src="/img/dummy/ad1.jpg" alt="ad">
            </stack-item>
            <stack-item tag="div">
              <img src="/img/dummy/ad2.jpg" alt="ad">
            </stack-item>
          </stack-item>
        </ul>
      </template>
    </two-column>
  </div>
</template>

<script>
import TwoColumn from '@/views/layouts/TwoColumn.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import UserBox from '@/views/components/UserBox.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import TabNav from '@/views/components/TabNav';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import Spacer from '@/views/components/Spacer.vue';

export default {
  name: 'Home',
  components: {
    BasicBtn,
    BtnContainer,
    TwoColumn,
    UserBox,
    TabNav,
    ContentsBox,
    StackItem,
    Spacer,
  },
  created() {
    this.axios({
      method: 'GET',
      url: '/v1/connection/test',
    })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      });
  },
};
</script>

<style lang="scss" module>
.action {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  li {
    color: #aaa;
  }
}

.slider {
  width: 100%;
  height: 510px;
  border-radius: 10px;
  background-color: var(--primary-color);
}
</style>

<template>
  <div>
    <two-column>
      <template v-slot:main>
        <spacer :y="4"/>
        <select-counselor
          :tab="type"
        />
      </template>

      <template v-slot:side>
        <spacer :y="4"/>
        <limit-count />

        <stack-item>
          <contents-box title="LINE登録でより簡単に" icon="<i class='fa-solid fa-megaphone'></i>">
            <p>オンライン健康相談の予約やテキスト健康相談の回答をLINEでチェックできます。</p>
            <spacer :y="2"/>
            <contents-box type="inner">
              <div :class="$style.flex_center">
                <a :href="`https://lin.ee/${helper.env.LINEBOT}`" target="_blank"><img :class="$style.qr" :src="`/img/default/line_${helper.env.name}.png`"></a>
              </div>
              <spacer :y="2"/>
              <div :class="$style.flex_center">
                <p :class="$style.img_title">LINE 公式アカウント</p>
              </div>
              <div :class="$style.flex_center">
                <p :class="$style.position">連携するとリマインドやお知らせが届きます！</p>
              </div>
            </contents-box>
          </contents-box>
        </stack-item>

        <stack-item :space="5" :class="$style.banners">
          <spacer :y="1"/>
          <stack-item tag="div">
            <a href="https://www.youtube.com/watch?v=De7L0L5rhL4" target="_blank"><img src="/img/banner/banner01.jpg"></a>
          </stack-item>
          <stack-item tag="div">
            <a href="https://welcometotalk.co.jp/blog" target="_blank"><img src="/img/banner/banner02.jpg"></a>
          </stack-item>
        </stack-item>
      </template>
    </two-column>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TwoColumn from '@/views/layouts/TwoColumn.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import SelectCounselor from '@/views/components/SelectCounselor.vue';
import Spacer from '@/views/components/Spacer.vue';
import LimitCount from '@/views/components/LimitCount4BillingAccount.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'reserve-select',
  mixins: [cf],
  components: {
    Spacer,
    StackItem,
    TwoColumn,
    ContentsBox,
    SelectCounselor,
    LimitCount,
  },
  data() {
    return {
      type: 'online', // SelectCounselorの呼び出しモード（初期値はオンライン健康相談）
    };
  },
  created() {
    // typeが明示指定されば場合はそれを採用
    if (this.$route.params.type) {
      this.type = this.$route.params.type; // virtualを渡された場合はバーチャル健康相談
    }
  },
  computed: {
    ...mapState(['helper', 'user']),
  },
  methods: {
  },
};
</script>

<style lang="scss" module>
.flex_center {
  display: flex;
  justify-content: center;
}
.qr {
  width: 100px;
}
.img_title {
  font-weight: bold;
}
.count {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
  span {
    font-size: 14px;
    &:first-of-type {
      margin-right: .5em;
    }
    &:last-of-type {
      margin-left: .5em;
    }
  }

  &_text {
    font-size: 12px;
    font-weight: bold;
  }
}
.search {
  &_items {
    padding: 35px 0;
    border-width: 1px;
    border-color: #eee transparent;
    border-style: solid;
  }
}
.position {
  font-size: 12px;
}
</style>

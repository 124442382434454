<template>
  <div>
    <spacer :y="5"/>
    <loader-simple :flag="flag.loader">
      <div v-if="!flag.invalid && information">
        <contents-box :title="information.title" icon="<i class='fa-regular fa-circle-info'></i>">
          <div :class="$style.flex">
            <label-box
              :label="filterMoment(information.updated_at, 'YYYY-MM-DD')"
              size="sm"
              color="orange"
              :class="`${$style.label} ${$style.date}`" />
            <spacer :x="2"/>
            <label-box
              label="重要"
              size="sm"
              color="pink"
              v-if="information.flag === 11"
              :class="$style.label" />
          </div>

          <spacer :y="2"/>
          <div
            class="n2br"
            :class="$style.content">
            <p v-html="information.content"></p>
          </div>

          <spacer :y="5" />
          <btn-container>
            <basic-btn
              :to="`${prefix}/information/`"
              v-if="user.account_type !== 31">一覧</basic-btn>
            <basic-btn
              to="/operator/"
              v-if="user.account_type === 31">一覧</basic-btn>
          </btn-container>
        </contents-box>
      </div>

      <div v-else-if="flag.invalid">
        <contents-box title="お知らせを閲覧できません">
          <div>URLが無効であるか、お知らせを閲覧する権限を持ちません。</div>
        </contents-box>
      </div>
    </loader-simple>
    <spacer :y="5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'information-list',
  mixins: [cf],
  components: {
    BasicBtn,
    BtnContainer,
    ContentsBox,
    LabelBox,
    Spacer,
    LoaderSimple,
  },
  data() {
    return {
      flag: {
        loader: true,
        invalid: false,
      },
      id: null,
      information: null,
      prefix: '',
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  created() {
    if (this.$route.params
      && this.$route.params.id
      && cf.convert2Num(this.$route.params.id)) {
      this.id = cf.convert2Num(this.$route.params.id);

      if (this.user.email) {
        this.getInformation();
      } else {
        this.$store.subscribe((mutation) => {
          if (mutation.type === 'user/setUserData') {
            this.getInformation();
          }
        });
      }
    } else {
      this.flag.loader = false;
      this.flag.invalid = true;
    }
  },
  methods: {
    /** お知らせ取得 */
    getInformation() {
      const user = this.user;
      const params = {
        id: this.id,
      };
      const types = {
        customer: [1, 2, 3],
        teacher: [11, 12],
        counselor: [21, 22],
      };
      if (types.customer.includes(user.account_type)) {
        params.targetNumber = 2;
      } else if (types.teacher.includes(user.account_type)) {
        params.targetNumber = 3;
        this.prefix = '/teacher';
      } else if (types.counselor.includes(user.account_type)) {
        params.targetNumber = 4;
        this.prefix = '/counselor';
      }

      this.axios({
        method: 'GET',
        url: '/v1/information/get/detail',
        params,
      })
        .then((response) => {
          this.information = response.data.detail;
          // readflagの登録がなければ登録
          if (!this.user.readflags.informations.includes(this.information.id)) this.registReadFlag();
          // パンくず
          const breadcrumb = {
            label: this.information.title,
            link: this.$route.fullPath,
          };
          this.$store.dispatch('page/breadcrumbs/addSingleName', breadcrumb, { root: true });
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.flag.invalid = true;
        })
        .finally(() => {
          this.flag.loader = false;
        });
    },
    registReadFlag() {
      const data = {
        model_type: 1, // 事務局からのお知らせ
        user_id: this.user.id,
        foreign_id: this.information.id,
      };
      this.axios({
        method: 'POST',
        url: '/v1/readflag/set/register',
        data,
      })
        .then(() => {
          this.$store.dispatch('user/update');
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module>
.date {
  font-size: 12px;
}
.link {
  color: var(--black);
}
.label {
  display: inline-block;
  width: 120px;
  @include sm-view {
    width: 100%;
  }
}
.flex {
  display: flex;
  align-items: flex-start;

  @include sm-view {
    display: block;
    .label {
      width: 100%;
    }
  }
}
.content {
  line-height: 2;
  * {
    word-wrap: break-word;
    word-break: break-all;
  }
  a {
    color: var(--black);
    text-decoration: underline;
    transition: all .4s;
    &:hover {
      opacity: .7;
    }
  }
  ol {
    counter-reset: num;
    > li {
      position: relative;
      padding-left: 1.4em;
      &:before {
        counter-increment: num;
        content: counter(num)".";
        position: absolute;
        top: 0; left: 0;
      }
    }
  }
  ul {
    > li {
      position: relative;
      padding-left: 1.4em;
      &:before {
        content: "・";
        position: absolute;
        top: 0; left: 0;
      }
    }
  }
}

</style>

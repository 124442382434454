<template>
  <div>
    <spacer :y="6"/>
    <ul>
      <stack-item id="scroll-top">
        <contents-box :title="helper.master && user.school ? helper.master.labels.organizationNews[user.school[0].is_company] : ''" icon="<i class='fa-regular fa-bullhorn'></i>">
          <ul>
            <stack-item>
              <contents-box type="inner">
                <ul :class="$style.newslist">
                  <li
                    v-for="row in schoolInformations"
                    :key="row.id">
                    <div :class="$style.school_info_wrap">
                      <div :class="$style.info_labels">
                        <label-box :class="$style.label_sm" :label="filterMoment(row.updated_at,'YYYY-MM-DD')" size="sm" color="gray"/>
                        <div :class="$style.title">
                          <span>{{ row.title }}</span>
                          <span
                            v-if="helper.master"
                            :class="$style.assistant">（{{ helper.master.labels.informations.flag[row.flag] }}）</span>
                        </div>
                      </div>
                      <i
                        :class="$style.pencil"
                        class="fa-solid fa-pen-to-square"
                        v-if="editId !== row.id"
                        v-on:click="editStart(row)"/>
                      <i
                        :class="$style.pencil"
                        class="fa-regular fa-circle-xmark"
                        v-if="editId === row.id"
                        v-on:click="editStart({ id: null })"/>
                    </div>
                    <spacer :y="1"/>
                    <div :class="$style.infoContent">
                      <p v-html="row.content"></p>
                    </div>
                    <div v-if="editId === row.id">
                      <spacer :y="2"/>
                      <div :class="$style.publishdate">
                        <p>公開日設定</p>
                        <spacer :y="1"/>
                        <date-small-picker
                          :class="$style.calendar"
                          :myDate="updated_at"
                          name="updated_at"
                          v-on:sendDate="receiveDate" />
                      </div>

                      <spacer :y="2"/>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        placeholder="タイトルを入力してください。"
                        v-model="title"
                        :disabled="flag.isConfirm"
                        :class="$style.textinput">

                      <spacer :y="2"/>
                      <Editor
                        name="textarea"
                        id="textarea"
                        rows="10"
                        v-model="content"
                        placeholder="投稿内容を入力してください。"
                        :class="$style.textarea"
                        v-bind:api-key="TINYMCE_API_KEY"
                        v-bind:init="editorConf"
                      />

                      <spacer :y="3"/>
                      <btn-container
                        v-if="!flag.isConfirm">
                        <basic-btn
                          tag="button"
                          v-on:click="regist($event, 1)">投稿する</basic-btn>
                        <spacer :x="2"/>
                        <basic-btn
                          tag="button"
                          type="bdr"
                          v-on:click="regist($event, 2)">下書き保存</basic-btn>
                        <spacer :x="2"/>
                        <basic-btn
                          tag="button"
                          type="bg"
                          :addClass="'danger'"
                          v-on:click="regist($event, 999)">削除</basic-btn>
                      </btn-container>
                    </div>
                  </li>
                </ul>
                <spacer :y="3"/>
                <pagination
                  :page="page"
                  :pageMax="pageMax"
                  :path="'/teacher/schoolInformation'"
                  v-if="schoolInformations.length && pageMax !== 1" />
              </contents-box>
            </stack-item>
          </ul>
        </contents-box>
      </stack-item>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Editor from '@tinymce/tinymce-vue';
import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import DateSmallPicker from '@/views/components/DateSmallPicker.vue';
import cf from '@/mixins/commonFunctions.js';
import Pagination from '@/views/components/Pagination.vue';

const TINYMCE_API_KEY = process.env.VUE_APP_TINYMCE_API_KEY;

export default {
  name: 'schoolinformation-list',
  mixins: [cf],
  components: {
    StackItem,
    ContentsBox,
    Spacer,
    LabelBox,
    BtnContainer,
    BasicBtn,
    DateSmallPicker,
    Editor,
    Pagination,
  },
  data() {
    return {
      TINYMCE_API_KEY,
      flag: {
        isConfirm: false,
        pageQuery: false,
      },
      editId: null,
      schoolInformations: [],
      title: null,
      content: null,
      updated_at: null,
      editorConf: {
        selector: 'textarea',
        content_css: '/editor/css/editor.css',
        language: 'ja',
        language_url: '/editor/langs/ja.js',
        menubar: false,
        plugins: 'lists link image code textcolor autolink',
        toolbar: 'styleselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | link | undo redo | code',
      },
      page: 1,
      pageMax: 1,
      limit: 20,
      total: 0,
    };
  },
  created() {
    const path = this.$route;
    if (path.query.page) {
      this.page = Number(path.query.page);
    } else if (path.path === '/teacher/schoolInformation/') {
      // queryにpageがなければ追加してreplace
      this.$router.replace('/teacher/schoolInformation/?page=1');
    }
    if (this.user.email) {
      this.getSchoolInformations();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') this.getSchoolInformations();
      });
    }
  },
  watch: {
    $route() {
      const path = this.$route;
      if (path.query.page) {
        this.page = Number(path.query.page);
      } else if (path.path === '/teacher/schoolInformation/') {
        // queryにpageがなければ追加してreplace
        this.$router.replace('/teacher/schoolInformation&page=1');
      }
      this.getSchoolInformations();
      this.flag.pageQuery = true;
    },
  },
  computed: {
    ...mapState(['user', 'helper']),
    loaderFlag() {
      let flag = true;
      if (this.user.counseling && this.user.counseling.reserves) {
        flag = false;
      }
      return flag;
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    editStart(item) {
      this.isConfirm = false;
      this.editId = item.id;
      if (!item.id) {
        this.title = null;
        this.content = null;
        this.updated_at = null;
      } else {
        this.title = item.title;
        this.content = item.content;
        this.updated_at = item.updated_at;
      }
    },

    changeFlag() {
      this.flag.isConfirm = !this.flag.isConfirm;
    },

    /** 学校だより・企業レター取得 */
    getSchoolInformations() {
      this.axios({
        method: 'GET',
        url: '/v1/schoolinformation/get/list',
        params: {
          school_id: this.user.school[0].id,
          includedDraft: 1,
          order: 'updated_at',
          orderBy: 'desc',
          page: this.page,
          limit: this.limit,
        },
      })
        .then((response) => {
          // this.schoolInformations = response.data.schoolinformations.data;
          const data = response.data.schoolinformations.data;
          const pageInfo = response.data.schoolinformations;
          this.schoolInformations = data;
          this.pageMax = pageInfo.lastPage;
          this.total = pageInfo.total;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          if (this.flag.pageQuery) this.scroll2Top();
          this.flag.pageQuery = false;
        });
    },

    scroll2Top() {
      // 上までスクロール
      const elem = document.getElementById('scroll-top');
      const position = elem.getBoundingClientRect().top;
      scrollBy(0, position - 77);
    },

    /** 学校だより・企業レター登録 */
    regist(e, flag) {
      e.preventDefault();
      this.showLoading();
      if (!this.content) {
        this.hideLoading();
        return alert('投稿内容を入力してください。');
      }

      const data = {
        id: this.editId === 0 ? null : this.editId,
        flag,
        user_id: this.user.id,
        school_id: this.user.school.id,
        title: this.title,
        content: this.content,
        updated_at: this.updated_at,
      };

      const endpoint = this.editId ? 'updater' : 'register';
      const label = this.editId ? '更新' : '登録';
      const type = this.helper.master && this.user.school ? this.helper.master.labels.organizationNews[this.user.school[0].is_company] : '学校だより・企業レター';

      this.axios({
        method: 'POST',
        url: `/v1/schoolinformation/set/${endpoint}`,
        data,
      })
        .then(() => {
          // 初期化
          this.flag.isConfirm = false;
          this.content = null;
          this.editId = null;

          this.schoolInformations = [];
          this.getSchoolInformations();
          if (flag === 999) {
            alert(`${type}を削除しました。`);
          } else if (flag !== 999) {
            alert(`${type}を${label}しました。`);
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    /** datepickerからの値受け取り */
    receiveDate(args) {
      if (!args.date) return;
      this[args.name] = args.date;
    },
  },
};
</script>

<style lang="scss" module>
.register {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pencil {
  font-size: 20px;
  color: var(--gray-side);
  cursor: pointer;
}
.school_info_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hideSp {
  @include sm-view {
    display: none;
  }
}
.info_labels {
  display: flex;
  align-items: flex-start;
  @include sm-view {
    display: block;
  }
}
.newsOperator {
  li {
    padding: 20px;
    border-bottom: 1px solid var(--gray-main);
    .title {
      font-weight: bold;
    }
  }
  a {
    color: var(--black);
    &:hover {
      .title {
        text-decoration: underline;
      }
    }
  }
}
.infoContent {
  height: 1.8em;
  font-size: 14px;
  overflow: hidden;
}
.newslist {
  li {
    align-items: flex-start;
    padding: 20px;
    border-bottom: 1px solid var(--gray-main);
  }
}
.label {
  &_sm {
    width: 120px;
    margin-right: 20px;
  }
}
.content {
  flex: 1;
  font-size: 12px;
  margin-top: 3px;
  overflow: hidden;
  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  a {
    pointer-events: none;
  }
}
.textarea {
  width: 100%;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;
  padding: 20px;
}
.textinput {
  padding: 20px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  width: 100%;
  border-radius: 8px;
}
.assistant {
  font-size: 12px;
}
.publishdate {
  max-width: 200px;
}
</style>

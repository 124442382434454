<template>
  <div>
    <h2>{{ formatTimestamp(data.date, 'YYYY/MM/DD') }}</h2>
    <spacer :y="3"/>
    <div v-if="data.isOperator">
      <p :class="$style.title">医師・心理士</p>
      <select
        name="counselor"
        id="counselor"
        :class="$style.counselor"
        v-on:change="updateCounselor">
        <option
          v-for="row in counselors"
          :key="row.id"
          :value="row.id"
          :selected="row.id === selectCounselor">{{ row.id }}：{{ row.username }}</option>
      </select>
      <spacer :y="3"/>
    </div>
    <loader-simple :flag="flag.loader">
      <div v-show="!flag.loader">
        <div>
          <contents-box type="inner">
            <p :class="$style.title">選択中の時間</p>
            <spacer :y="0.5"/>
            <p :class="$style.assistant">※クリックすると選択・解除できます。</p>
            <p
              v-if="isVirtualSpecialist"
              :class="$style.assistant"
            >
              ※凡例：
              <i
                class="fa-regular fa-laptop"
                :class="$style.icon_online"
              >
                <i :class="$style.icon_online_inner" class="fa-regular fa-user-headset" />
              </i>オンライン健康相談のみ受付可能
              &nbsp;
              <label :class="$style.counselingicons">
                <i
                  class="fa-regular fa-laptop"
                  :class="$style.icon_online"
                >
                  <i :class="$style.icon_online_inner" class="fa-regular fa-user-headset" />
                </i>
                <i
                  class="fa-solid fa-vr-cardboard"
                  :class="$style.icon_virtual"
                />
              </label>オンライン健康相談/バーチャル健康相談を受付可能
            </p>
            <spacer :y="1"/>
            <ul :class="$style.hours">
              <li v-for="h of 9" :key="h">
                <div>
                  <input
                    type="checkbox"
                    :id="h"
                    :class="$style.checkbox"
                    v-if="!reservedHours.includes(h)"
                    :checked="selectHours.includes(h)"
                    v-on:change="changeHours(h)">
                  <label
                    v-if="!reservedHours.includes(h)"
                    :class="selectHours.includes(h) ? `${$style.label} ${$style.checked}` : $style.label"
                    :for="h">{{ h + 12 }}:00 〜</label>
                  <label
                    v-if="reservedHours.includes(h)"
                    :class="[$style.label, $style.reserved]">予約あり</label>
                </div>
                <template v-if="isVirtualSpecialist">
                  <div v-show="selectHours.includes(h)">
                    <ul :class="$style.radio">
                      <li
                        v-for="(typeLabel, typeKey) of helper.master.labels.schedules.counseling_reception_type"
                        :key="typeKey"
                        :class="$style.receptiontype"
                      >
                        <input
                          type="radio"
                          :name="`counseling_reception_type-${h}`"
                          :id="`counseling_reception_type-${h}-${typeKey}`"
                          v-model="counselingReceptions[h]"
                          :value="typeKey"
                          :disabled="reservedHours.includes(h)"
                        >
                        <label
                          :for="`counseling_reception_type-${h}-${typeKey}`"
                          :class="$style.counselingicons"
                          :title="typeLabel"
                        >
                          <i
                            class="fa-regular fa-laptop"
                            :class="$style.icon_online"
                          >
                            <i :class="$style.icon_online_inner" class="fa-regular fa-user-headset" />
                          </i>
                          <i
                            v-if="typeKey === '2'"
                            class="fa-solid fa-vr-cardboard"
                            :class="$style.icon_virtual"
                          />
                        </label>
                      </li>
                    </ul>
                  </div>
                </template>
              </li>
            </ul>
            <spacer :y="3"/>
            <btn-container>
              <basic-btn
                tag="button"
                v-on:click="regist">登録</basic-btn>
                <spacer :x="2"/>
              <basic-btn
                tag="button"
                type="bdr"
                v-if="data.isOperator"
                v-on:click="back2dailySchedule">戻る</basic-btn>
              <basic-btn
                tag="button"
                type="bdr"
                v-if="!data.isOperator"
                v-on:click="hideModal">戻る</basic-btn>
            </btn-container>
          </contents-box>
        </div>
      </div>
    </loader-simple>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions.js';
import Spacer from '@/views/components/Spacer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';

export default {
  name: 'edit-schedule',
  mixins: [cf],
  props: ['data'],
  components: {
    Spacer,
    BasicBtn,
    BtnContainer,
    LoaderSimple,
  },
  data() {
    return {
      flag: {
        loader: true,
      },
      counselors: [],
      selectCounselor: null,
      reservedHours: [], // 予約済のシフト
      rawHours: [], // flagに関わらずgetしたデータそのまま、objectの配列
      adjustRawHours: [], // flagに関わらずgetしたデータを数字に変換して格納
      validHours: [], // flag=1で登録済の時間
      invalidHours: [], // flag=999で登録済の時間
      selectHours: [], // 最終的にflagが１で登録したい時間
      counselingReceptions: {}, // 画面上で選択状態の時間におけるカウンセリング受付種別 (key: selectHour, value: 1 or 2(オンライン / オンライン/バーチャル))
      rawCounselingReceptions: {}, // 登録済みの時間における counseling_reception の値（データ構造はcounselingReceptionsと同じ）（スケジュールの更新判定に利用する）
    };
  },
  created() {
    this.getCounselor();
  },
  watch: {
    selectCounselor() {
      this.getSchedule();
    },
  },
  computed: {
    ...mapState(['user', 'helper']),
    /** 選択中のカウンセラーがバーチャル対応専門家か */
    isVirtualSpecialist() {
      if (!this.selectCounselor) {
        return false;
      }
      const counselor = this.counselors.find((row) => Number(row.id) === Number(this.selectCounselor));
      if (!counselor) {
        return false;
      }
      return counselor.medicalWorker.is_virtual_specialist === true || counselor.medicalWorker.is_virtual_specialist === 1;
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
    getCounselor() {
      const params = {
        accountTypes: [21, 22],
      };
      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.counselors = res.users.data;
          if (this.data.id) this.selectCounselor = this.data.id;
          else this.selectCounselor = this.counselors[0].id;
          this.getSchedule();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    updateCounselor(e) {
      this.selectCounselor = e.target.value;
    },
    getSchedule() {
      const params = {
        target_user_id: this.selectCounselor,
        excludeDelete: 0, 
      };
      this.axios({
        method: 'GET',
        url: '/v1/schedule/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.rawHours = res.schedules.data;
          this.adjustSchedule();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    adjustSchedule() {
      this.selectHours = [];
      this.validHours = [];
      this.invalidHours = [];
      this.reservedHours = [];
      const array = [];
      this.adjustRawHours = [];
      this.counselingReceptions = {};
      this.rawCounselingReceptions = {};
      this.rawHours.forEach((time) => {
        let date = this.formatTimestamp(time.date, 'YYYY-MM-DD/HH');
        date = date.split('/');
        if (date[0] === this.data.date) {
          const hour = Number(date[1] - 12);
          if (time.reserve && time.reserve.flag === 1) this.reservedHours.push(hour);
          // 有効なものはselectHoursに
          if (time.flag === 1) {
            this.selectHours.push(hour);
            this.validHours.push(hour);
            this.counselingReceptions[hour] = time.counseling_reception_type;
          } else {
            // 無効フラグのものはinvalidHoursに入れる
            this.invalidHours.push(hour);
          }
          // flagに関わらずすでに登録のある時間は全てここに入れる
          array.push(time);
          this.adjustRawHours.push(hour);
          // flagに関わらずすでに登録のある counseling_reception_type は全てここに入れる（スケジュールの更新判定に利用する）
          this.rawCounselingReceptions[hour] = time.counseling_reception_type;
        }
      });
      this.rawHours = array;
      this.flag.loader = false;
    },
    changeHours(h) {
      if (this.selectHours.includes(h)) {
        const idx = this.selectHours.indexOf(h);
        this.selectHours.splice(idx, 1);
        if (this.isVirtualSpecialist && this.counselingReceptions[h]) { // バーチャル対応専門家の場合の対応
          delete this.counselingReceptions[h]; // 選択値をクリアする
        }
      } else {
        this.selectHours.push(h);
        if (this.isVirtualSpecialist) { // バーチャル対応専門家の場合の対応
          this.counselingReceptions[h] = 1; // 初期表示時の選択値="オンライン"
        }
      }
    },
    back2dailySchedule() {
      const data = {
        date: this.data.date,
      };
      const args = {
        modalName: 'daily-schedule',
        data,
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
    regist() {
      this.showLoading();
      const registSchedules = [];
      // 取得したscheduleには含まれない場合登録の配列に入れる
      this.selectHours.forEach((time) => {
        if (this.needRegist(time)) {
          registSchedules.push({
            date: `${this.data.date} ${time + 12}:00:00`,
            counseling_reception_type: this.isVirtualSpecialist
              ? this.counselingReceptions[time]
              : null,
          });
        }
      });

      // updateするscheduleを割り出す
      const updateSchedule = [];
      this.adjustRawHours.forEach((row, idx) => {
        if (this.needUpdate(row)) {
          updateSchedule.push({
            id: this.rawHours[idx].id,
            date: this.formatTimestamp(this.rawHours[idx].date, 'YYYY-MM-DD HH:mm:ss'),
            flag: this.selectHours.includes(row) ? 1 : 999,
            counseling_reception_type: this.isVirtualSpecialist
              ? this.counselingReceptions[row]
              : null,
          });
        }
      });

      let registed = false;
      let updated = false;
      // 登録
      if (registSchedules.length) {
        const data = {
          target_user_id: this.selectCounselor,
          regist_user_id: this.user.id,
          schedules: registSchedules,
        };
        this.axios({
          method: 'POST',
          url: '/v1/schedule/set/register',
          data,
        })
          .then(() => {
            registed = true;
            if (registed && updated) {
              this.complete();
            }
          })
          .catch((error) => {
            if (error.message) console.log(error.message);
            else console.log(error);
          });
      } else {
        registed = true;
        if (registed && updated) {
          this.complete();
        }
      }

      // 更新
      if (updateSchedule.length) {
        const data = {
          schedules: updateSchedule,
        };
        this.axios({
          method: 'POST',
          url: '/v1/schedule/set/updater',
          data,
        })
          .then(() => {
            updated = true;
            if (registed && updated) {
              this.complete();
            }
          })
          .catch((error) => {
            if (error.message) console.log(error.message);
            else console.log(error);
          });
      } else {
        updated = true;
        if (registed && updated) {
          this.complete();
        }
      }
    },
    complete() {
      alert('シフトの登録が完了しました。');
      this.$store.dispatch('schedule/setSchedule');
      if (this.data.isOperator) {
        this.getSchedule();
        this.hideLoading();
        this.back2dailySchedule();
      } else {
        this.hideLoading();
        this.hideModal();
      }
    },
    hideModal() {
      this.$store.dispatch('modal/contents/hideModal', null, { root: true });
    },

    /** 対象時間に対して登録が必要か */
    needRegist(hour) {
      // backendから取得した登録済みスケジュールと重ならない時間が画面上で選択されている場合は登録が必要
      return this.selectHours.includes(hour) && !this.adjustRawHours.includes(hour);
    },
    /** 対象時間に対して更新が必要か */
    needUpdate(hour) {
      // 登録対象の時間は除外
      if (this.needRegist(hour)) {
        return false;
      }
      // 無効から無効にする場合は更新が不要
      if (!this.selectHours.includes(hour) && !this.adjustRawHours.includes(hour)) {
        return false;
      }
      // 有効から無効する場合は更新が必要
      if (!this.selectHours.includes(hour) && this.validHours.includes(hour)) {
        return true;
      }
      // 無効から有効にする場合は更新が必要
      if (this.selectHours.includes(hour) && this.invalidHours.includes(hour)) {
        return true;
      }
      // 有効から有効の場合、カウンセリング受付種別の変更有無で判断する
      if (!this.isVirtualSpecialist) {
        return false; // バーチャル対応専門家ではない場合はそもそもカウンセリング受付種別の概念がないため更新不要
      }
      return this.rawCounselingReceptions[hour] !== this.counselingReceptions[hour]; // 登録済みの種別と画面上で選択中の種別が異なる場合は更新が必要
    },
  },
};
</script>

<style lang="scss" module>
.title {
  font-size: 14px;
}
.counselor {
  outline: none;
  border: none;
  padding: 13px;
  background-color: var(--gray-sub);
  border-radius: 8px;
}
.date {
  font-size: 20px;
  font-weight: bold;
}
.title {
  font-weight: bold;
}
.assistant {
  font-size: 12px;
}
.message {
  font-size: 12px;
  margin-top: 5px;
  color: var(--pink);
}
.hours {
  display: flex;
  flex-wrap: wrap;

  li {
    width: calc(100% / 9);
    margin-top: 4px;
    min-width: 80px;
    text-align: center;
    padding: 0 2px;
    @include xs-view {
      min-width: 60px;
    }
  }
}
.checkbox {
  display: none;
}
.label {
  font-size: 12px;
  display: block;
  border: 1px solid var(--gray-main);
  color: var(--gray-main);
  width: 100%;
  padding: 5px 0;
  border-radius: 2px;
  &.checked {
    background-color: var(--orange-main);
    color: #fff;
    border-color: var(--orange-main);
  }
  &.reserved {
    background-color: var(--gray-main);
    color: #fff;
    cursor: auto;
  }

  @include xs-view {
    font-size: 10px;
  }
}

.receptiontype {
  margin-left: 0.25em;
  text-align: left !important;
}
.counselingicons {
  > i {
    &:not(:first-child) {
      margin-left: .3em;
    }
  }
}
.icon {
  &_online {
    color: var(--orange-main);
    position: relative;
    &_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
      font-size: .4em;
    }
  }
  &_virtual {
    color: var(--green-main);
  }
}
</style>

<template>
  <div>
    <spacer :y="5"/>
    <contents-box title="医師・心理士情報更新" id="confirm">
      <loader-simple :flag="flag.loader">
        <div v-show="!flag.loader">
          <div :class="$style.wrapper">
            <div :class="$style.row" v-for="row in contents" :key="row">
              <div :class="$style.label_wrap">
                <p :class="$style.label">{{ row.label }}</p>
                <p v-if="row.required" :class="$style.required">※必須項目</p>
              </div>

              <!-- テキスト -->
              <div :class="$style.detail" v-if="row.type === 'text'">
                <div>
                  <input
                    v-if="row.name === 'virtual_cast_room_key'"
                    v-show="isVirtualSpecialist"
                    :class="$style.text"
                    :name="row.name"
                    :disabled="flag.isConfirm"
                    :placeholder="row.placeholder"
                    v-model.trim="row.value"
                    type="text">
                  <input
                    v-else
                    :class="$style.text"
                    :name="row.name"
                    :disabled="flag.isConfirm"
                    :placeholder="row.placeholder"
                    type="text" v-model="row.value">
                </div>
                <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
              </div>

              <!-- テキスト(複数行) -->
              <div :class="$style.detail" v-if="row.type === 'textarea'">
                <div>
                  <textarea
                    :class="$style.text"
                    :name="row.name"
                    :disabled="flag.isConfirm"
                    :placeholder="row.placeholder"
                    v-model="row.value"></textarea>
                </div>
              </div>

              <!-- ラジオボタン -->
              <div :class="$style.detail" v-if="row.type === 'radio'">
                <ul :class="$style.radio">
                  <li v-for="opt in row.options" :key="opt">
                    <input type="radio" :name="row.name" :id="opt.name" :disabled="flag.isConfirm" :value="opt.value" v-model="row.value">
                    <label :for="opt.name">{{ opt.label }}</label>
                  </li>
                </ul>
              </div>

              <!-- チェックボックス -->
              <div :class="$style.detail" v-if="row.type === 'check'">
                <div>
                  <input
                    type="checkbox"
                    :name="row.name"
                    :disabled="flag.isConfirm"
                    v-model="row.value"
                    true-value="1"
                    false-value="0"
                    v-on:change="changeCheckboxValue($event, row.name)"
                  >
                </div>
              </div>

              <!-- 生年月日 -->
              <div :class="$style.detail" v-if="row.type === 'birthday'">
                <ul :class="$style.select_wrap">
                  <li>
                    <select :class="$style.select" name="birth_year" id="birth_year" :disabled="flag.isConfirm" v-model="row.value.year">
                      <option
                        v-for="n of 70"
                        :key="n"
                        :selected="row.value.year === optionYear(n)"
                        :value="optionYear(n)">{{ optionYear(n) }}</option>
                    </select>年
                  </li>
                  <li>
                    <select :class="$style.select" name="birth_month" id="birth_month" :disabled="flag.isConfirm" v-model="row.value.month">
                      <option
                        v-for="n of 12"
                        :key="n"
                        :value="n">{{ n }}</option>
                    </select>月
                  </li>
                  <li>
                    <select :class="$style.select" name="birth_day" id="birth_day" :disabled="flag.isConfirm" v-model="row.value.day">
                      <option
                        v-for="n of 31"
                        :key="n"
                        :value="n">{{ n }}</option>
                    </select>日
                  </li>
                </ul>
              </div>

              <!--VCID-->
              <div :class="$style.detail" v-if="row.type === 'vcid'">
                <template v-if="isVirtualSpecialist">
                  <div
                    :class="[
                      $style.vcid,
                      flag.isConfirm ? null : $style.force_link
                    ]"
                    v-on:click="clickVcidInputButton"
                  >
                    <input-codes
                      :key="`update-counselor-vcid-${row.value}`"
                      :digit="6"
                      :value="row.value"
                      :smallMode="true"
                      :readonly="true"
                    />
                  </div>
                  <p
                    v-if="row.value"
                    :class="$style.assistant"
                  ><a :href="getVirtualCastUserURL(row.value)" target="_blank">{{ getVirtualCastUserURL(row.value) }}</a></p>
                </template>
                <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
              </div>
            </div>

            <div :class="$style.row">
              <div :class="$style.label_wrap">
                <p :class="$style.label">住所</p>
                <p :class="$style.required">※必須項目</p>
              </div>
              <!--住所-->
              <div :class="$style.detail">
                <Address
                  @sendValue="receiveValue"
                  :items="items"
                  :isConfirm="flag.isConfirm"
                  ref="address" />
              </div>
            </div>
          </div>
          <spacer :y="5"/>
          <btn-container>
            <basic-btn
              tag="button"
              v-if="!flag.isConfirm"
              v-on:click="changeFlag">入力内容を確認する</basic-btn>
            <basic-btn
              tag="button"
              v-if="flag.isConfirm"
              v-on:click="changeFlag">編集画面に戻る</basic-btn>
            <spacer :x="2"/>
            <basic-btn
              tag="button"
              v-if="flag.isConfirm"
              v-on:click="update">入力内容を確定する</basic-btn>
          </btn-container>
        </div>
      </loader-simple>
    </contents-box>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import InputCodes from '@/views/components/parts/Form/InputCodes.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';
import { Address } from '@/views/components/parts/Form';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'operator-update-counselor',
  mixins: [cf],
  components: {
    BasicBtn,
    BtnContainer,
    ContentsBox,
    InputCodes,
    LoaderSimple,
    Spacer,
    Address,
  },
  data() {
    return {
      counselorId: null,
      counselor: null,
      flag: {
        loader: true,
        isConfirm: false,
        readySubmit: false,
      },
      contents: [
        {
          type: 'radio',
          name: 'account_type',
          label: '利用者種別',
          required: true,
          value: 21,
          datatype: 1,
          options: [
            {
              name: 'doctor',
              label: '医師',
              value: 21,
            },
            {
              name: 'psychologist',
              label: '心理士',
              value: 22,
            },
          ],
        },
        {
          type: 'text',
          name: 'username',
          label: '名前',
          required: true,
          placeholder: '例：三上　春斗',
          value: '',
          datatype: 1,
        },
        {
          type: 'text',
          name: 'kana',
          label: 'フリガナ',
          required: true,
          placeholder: '例：ミカミ　ハルト',
          value: '',
          datatype: 1,
        },
        {
          type: 'radio',
          name: 'gender',
          label: '性別',
          required: true,
          value: null,
          datatype: 1,
          options: [
            {
              name: 'male',
              label: '男性',
              value: 1,
            },
            {
              name: 'female',
              label: '女性',
              value: 2,
            },
          ],
        },
        {
          type: 'birthday',
          name: 'birthday',
          label: '生年月日',
          datatype: 1,
          value: {
            year: '',
            month: '',
            day: '',
          },
        },
        {
          type: 'text',
          name: 'tel',
          label: '電話番号',
          required: true,
          value: '',
          datatype: 1,
        },
        {
          type: 'textarea',
          name: 'specialty',
          label: '専門領域',
          value: '',
          datatype: 3,
        },
        {
          type: 'textarea',
          name: 'qualifications',
          label: '資格',
          value: '',
          datatype: 3,
        },
        {
          type: 'textarea',
          name: 'carrer',
          label: '経歴',
          placeholder: 'xxxx年　〜　xxxx年　経歴 の形で入力してください',
          value: '',
          datatype: 3,
        },
        {
          type: 'text',
          name: 'birthplace',
          label: '出身',
          value: '',
          datatype: 3,
        },
        {
          type: 'textarea',
          name: 'hobby',
          label: '趣味',
          value: '',
          datatype: 3,
        },
        {
          type: 'text',
          name: 'blog',
          label: 'ブログURL',
          value: '',
          datatype: 3,
        },
        {
          type: 'textarea',
          name: 'note',
          label: '特記',
          value: '',
          datatype: 3,
        },
        {
          type: 'textarea',
          name: 'comment',
          label: 'ひとこと',
          value: '',
          datatype: 1,
        },
        {
          type: 'check',
          name: 'is_virtual_specialist',
          label: 'バーチャル対応専門家',
          value: '0',
          datatype: 3,
        },
        {
          type: 'vcid',
          name: 'virtual_cast_id',
          label: 'バーチャルキャストID',
          value: null,
          datatype: 5,
          assistant: 'バーチャル対応専門家の場合は入力必須です',
        },
        {
          type: 'text',
          name: 'virtual_cast_room_key',
          label: 'バーチャルキャストルームキー',
          value: null,
          datatype: 5,
          assistant: 'バーチャル対応専門家の場合は入力必須です',
        },
        {
          type: 'text',
          name: 'virtual_cast_room_name',
          label: 'バーチャルキャストルーム名',
          value: null,
          datatype: 5,
          assistant: 'バーチャル対応専門家の場合は入力必須です',
        },
        {
          type: 'check',
          name: 'is_risk_application_approver',
          label: 'リスク評価申請承認者',
          value: '0',
          datatype: 3,
        },
        {
          name: 'account_name',
          type: 'text',
          label: '口座名義',
          required: true,
          value: null,
          datatype: 4,
        },
        {
          name: 'bank_name',
          type: 'text',
          label: '銀行名',
          required: true,
          value: null,
          datatype: 4,
        },
        {
          name: 'branch_name',
          type: 'text',
          label: '支店名',
          required: true,
          value: null,
          datatype: 4,
        },
        {
          type: 'radio',
          name: 'deposit_type',
          label: '預金種目',
          required: true,
          value: null,
          datatype: 4,
          options: [
            {
              name: '普通',
              label: '普通',
              value: 1,
            },
            {
              name: '当座',
              label: '当座',
              value: 2,
            },
            {
              name: '貯蓄',
              label: '貯蓄',
              value: 3,
            },
            {
              name: 'その他',
              label: 'その他',
              value: 4,
            },
          ],
        },
        {
          name: 'account_number',
          type: 'text',
          label: '口座番号',
          required: true,
          value: null,
          datatype: 4,
        },
      ],
      items: [
        {
          name: 'zip',
          type: 'text',
          label: '郵便番号',
          value: null,
          required: true,
          placeholder: '1020074',
          comment: '半角・ハイフンなしで入力してください',
        },
        {
          name: 'pref',
          type: 'select',
          label: '都道府県',
          value: null,
          options: [],
          required: true,
          comment: '',
        },
        {
          name: 'city',
          type: 'text',
          label: '市区町村',
          value: null,
          required: true,
          placeholder: '千代田区九段南',
        },
        {
          name: 'address',
          type: 'text',
          label: '番地',
          value: null,
          required: false,
          placeholder: '1-5-6',
        },
        {
          name: 'building',
          type: 'text',
          label: '建物名・部屋番号',
          placeholder: 'りそな九段ビル5階 KSフロア',
          value: null,
        },
      ],
      address: null,
      counselorID: null,
      isVirtualSpecialistBeforeUpdate: false, // 画面表示時点でのバーチャル対応専門家フラグ
    };
  },
  created() {
    if (this.helper.master.prefs) {
      this.items[1].options = this.helper.master.prefs;
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/putMaster') {
          this.items[1].options = mutation.payload.prefs;
        }
      });
    }
    this.counselorId = cf.convert2Num(this.$route.params.slug);
    this.getCounselor();
  },
  computed: {
    ...mapState(['user', 'helper']),
    isVirtualSpecialist() {
      const target = this.contents.find((row) => row.name === 'is_virtual_specialist');
      return target.value === '1';
    },
    virtualCastIdRow() {
      return this.contents.find((row) => row.name === 'virtual_cast_id');
    },
    virtualCastRoomKeyRow() {
      return this.contents.find((row) => row.name === 'virtual_cast_room_key');
    },
  },
  methods: {
    optionYear(n) {
      const year = Number(this.formatTimestamp(new Date(), 'YYYY')) + 1 - n;
      return year;
    },
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
    /** 子から値を受け取る */
    receiveValue(values) {
      this.flag.readySubmit = values.readySubmit;
      this.address = values.address;
    },
    getCounselor() {
      const params = {
        id: this.counselorId,
      };
      this.axios({
        method: 'GET',
        url: '/v1/user/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.counselor = res.data;
          this.setCounselorData();
        })
        .catch((error) => {
          if (error.message) {
            if (error.message === 'Request failed with status code 404') this.getInactiveCounselor();
            else console.log(error.message);
          } else console.log(error);
        });
    },

    getInactiveCounselor() {
      const params = { id: this.counselorId };
      this.axios({
        method: 'GET',
        url: '/v1/user/get/AllConditions',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.counselor = res.data;
          this.setCounselorData();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    setCounselorData() {
      this.contents.forEach((row) => {
        if (row.name === 'birthday') {
          let day = this.filterDate(this.counselor[row.name]);
          day = day.split('.');
          row.value.year = cf.convert2Num(day[0]);
          row.value.month = cf.convert2Num(day[1]);
          row.value.day = cf.convert2Num(day[2]);
        } else if (row.datatype === 3) {
          const booleanColumns = [
            'is_virtual_specialist',
            'is_risk_application_approver',
          ];
          if (booleanColumns.includes(row.name)) {
            // フラグ値は数値（0/1）でレスポンスが返ってくるが、当vue上では文字列の'0'/'1'で扱うため、文字列変換を行う
            row.value = `${this.counselor.medicalWorker[row.name]}`;
          } else {
            row.value = this.counselor.medicalWorker[row.name];
          }
        } else if (row.datatype === 4) {
          row.value = this.counselor.bankAccount[row.name];
        } else if (row.datatype === 5) {
          row.value = this.counselor.virtualCastProp ? this.counselor.virtualCastProp[row.name] : null;
        } else {
          row.value = this.counselor[row.name];
        }
      });
      if (this.counselor.address) this.$refs.address.setCustomerData(this.counselor.address);

      // バーチャルキャスト専門家カラム: データ取得直後の状態を控えておく（∵更新時の関連情報削除要否を判断するため）
      this.isVirtualSpecialistBeforeUpdate = this.counselor.medicalWorker.is_virtual_specialist === 1;

      this.flag.loader = false;
    },
    changeFlag() {
      let filledRequired = true;
      this.contents.forEach((row) => {
        if (row.required) {
          if (row.value === null || row.value === '') {
            filledRequired = false;
          }
        }
      });
      if (!filledRequired) {
        alert('必須項目が未入力です');
        return;
      }
      if (!this.flag.readySubmit) {
        alert('住所の項目に不備があります。');
        return;
      }
      if (this.isVirtualSpecialist) {
        if (!this.virtualCastIdRow.value) {
          alert('バーチャルキャストIDを入力してください。');
          return;
        }
        if (!this.virtualCastRoomKeyRow.value) {
          alert('バーチャルキャストルームキーを正しく入力してください。');
          return;
        }
      }

      this.flag.isConfirm = !this.flag.isConfirm;
      if (this.flag.isConfirm) {
        const elem = document.getElementById('confirm');
        const position = elem.getBoundingClientRect().top;
        scrollBy(0, position - 77);
      }
    },

    /** checkbox項目のON/OFF切り替え時に作動するメソッド */
    changeCheckboxValue(event, name) {
      // "バーチャル対応専門家"のチェックをOFFにしたタイミングでバーチャルキャスト関連の値を空にする
      if (name === 'is_virtual_specialist' && !event.target.checked) {
        this.contents.forEach((row) => {
          if (row.datatype === 5) {
            row.value = null;
          }
        });
      }
    },

    /** 登録 */
    update() {
      this.showLoading();
      const data = {
        additionalData: {},
        virtualCastProp: this.isVirtualSpecialist ? {} : null,
      };
      this.contents.forEach((row) => {
        if (row.datatype === 1) {
          if (row.name === 'birthday') {
            const m = row.value.month < 10 ? `0${row.value.month}` : row.value.month;
            const d = row.value.day < 10 ? `0${row.value.day}` : row.value.day;
            data[row.name] = `${row.value.year}-${m}-${d}`;
            Object.keys(row.value).forEach((key) => {
              if (row.value[key] === null || 0) data[row.name] = null;
            });
          } else {
            data[row.name] = row.value;
          }
        } else if (row.datatype === 3) {
          data.additionalData[row.name] = row.value;
        } else if (row.datatype === 5) { // virtualCastProps テーブルへの情報
          if (!this.isVirtualSpecialist) {
            // バーチャル対応専門家の場合のみセットする
            return;
          }
          data.virtualCastProp[row.name] = row.value;
        }
      });

      // vc関連のデータを削除する
      if (this.isVirtualSpecialistBeforeUpdate && !this.isVirtualSpecialist) {
        data.virtualCastProp = {
          needDelete: true,
        };
      }

      data.id = this.counselor.id;
      data.additionalData.id = this.counselor.medicalWorker.id;

      this.axios({ // 医師・心理士の情報を登録
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then(() => {
          this.updateAddress();
          this.updataBankData();
        })
        .catch((error) => {
          console.log(error);
          alert('医師・心理士の登録に失敗しました。もう一度やり直してください。');
          this.hideLoading();
        });
    },
    updateAddress() {
      const data = this.address;
      data.id = this.counselor.address ? this.counselor.address.id : null;
      data.foreign_id = this.counselor.id.id;
      data.model_type = 1;

      const endpoint = data.id ? 'updater' : 'register';
      this.axios({
        method: 'POST',
        url: `/v1/address/set/${endpoint}`,
        data,
      })
        .then(() => {
          alert('更新が完了しました。');
          this.hideLoading();
          this.$router.push('/operator/?tab=counselors');
        })
        .catch((error) => {
          console.log(error);
          alert('住所情報の更新に失敗しました。');
          this.hideLoading();
        });
    },
    updataBankData() {
      const data = {};
      data.id = this.counselor.bankAccount ? this.counselor.bankAccount.id : null;
      this.contents.forEach((row) => {
        if (row.datatype === 4) {
          data[row.name] = row.value;
        }
      });
      const endpoint = data.id ? 'updater' : 'register';
      this.axios({
        method: 'POST',
        url: `/v1/bankaccount/set/${endpoint}`,
        data,
      })
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
          alert('住所情報の更新に失敗しました。');
          this.hideLoading();
        });
    },

    /** VCID入力モーダルを開く */
    clickVcidInputButton() {
      if (this.flag.isConfirm) {
        return;
      }
      const args = {
        modalName: 'input-vcid',
        data: {
          callback: (vcid) => {
            this.virtualCastIdRow.value = vcid;
          },
        },
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
  },
};
</script>

<style lang="scss" module>
.row {
  padding: 24px 0;
  border-top: 1px solid var(--gray-main);
}
.label {
  border-radius: 4px;
  margin-right: 4px;
  font-weight: bold;
  display: inline-block;
  &_wrap {
    display: flex;
    align-items: baseline;
  }
}
.text, .select {
  &:disabled {
    color: black;
    background-color: transparent;
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }
  }
}
.required {
  font-size: 12px;
  color: red;
}
.assistant {
  font-size: 12px;
}
.space {
  padding-top: 6px;
}
.text {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
}
.radio {
  padding-top: 8px;
  display: flex;

  li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
.select {
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;
  appearance: none;

  &_wrap {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.tel {
  width: 70px;
  padding: 13px;
}
.thumbnail {
  width: 100px;
  padding-top: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin-right: 20px;
  border: 1px solid rgba(#aaa, 0.3);
  &_wrap {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  &_btn {
    display: inline-block;
    border-radius: 22px;
    padding: 6px 15px;
    font-size: 14px;
    border: 1px solid var(--black);
    &:not(:first-child) {
      margin-left: 1em;
    }
  }
}
.vcid {
  width: 200px;
}
.force_link {
  cursor: pointer;
  * {
    cursor: pointer;
  }
}
</style>

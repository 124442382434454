<template>
  <!-- 項目を追加・削除等改修する際はsignup2.vueも同様に行う -->
  <spacer :y="5"/>
  <div>
    <contents-box title="利用者情報入力" id="confirm">
      <div :class="$style.wrapper">
        <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">{{ helper.master && school ? helper.master.labels.organizationTypes[school.is_company] : '' }}名</p>
          </div>
          <div :class="$style.detail">
            <p :class="$style.school" v-if="school">{{ school.name }}</p>
          </div>
        </div>
        <!-- 利用者タイプを選択（選択する必要がない(生徒限定)ためコメントアウト） -->
        <!-- <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">利用者種別</p>
            <p :class="$style.required">※必須項目</p>
          </div>
          <div :class="$style.detail">
            <ul :class="$style.radio">
              <li v-for="(opt, i) in accountType" :key="opt">
                <div v-if="!(school && school.is_company && opt.name === 'parent')">
                  <input
                    type="radio"
                    name="account_type"
                    :id="opt.name"
                    :disabled="flag.isConfirm || flag.alreadySet"
                    :checked=" i === 0"
                    v-on:change="updateType">
                  <label :for="opt.name">{{ school && school.is_company ? opt.companyLabel : opt.label }}</label>

                </div>
              </li>
            </ul>
          </div>
        </div> -->

        <div v-if="school && school.is_company === 0 && userType === 1" :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">学年</p>
            <!-- <p :class="$style.required">※必須項目</p> -->
          </div>
          <div :class="detail">
            <!-- LTIはアカウント課金のため初期値で学年登録済み -->
            <input
              disabled
              :class="$style.text"
              name="schoolYear"
              v-model="schoolYear"
              type="text">
          </div>
        </div>

        <!-- 全タイプ必須の情報 -->
        <div :class="$style.row" v-for="row in contents" :key="row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">{{ row.label }}</p>
            <p v-if="row.required" :class="$style.required">※必須項目</p>
          </div>


          <!-- テキスト -->
          <div :class="$style.detail" v-if="row.type === 'text'">
            <div>
              <!-- 名前・フリガナも登録済み -->
              <input
                v-if="row.name === 'username' || row.name === 'kana'"
                :class="$style.text"
                :name="row.name"
                disabled
                :placeholder="row.placeholder"
                v-model="row.value"
                type="text">
              <input
                v-else
                :class="$style.text"
                :name="row.name"
                :disabled="flag.isConfirm || flag.alreadySet"
                :placeholder="`${row.placeholder}`"
                v-model="row.value"
                type="text">
            </div>
            <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
            <p v-if="row.name === 'kana'&& $v.kana.$dirty && $v.kana.custom.$invalid" :class="$style.error">カナ文字のみ入力してください</p>
          </div>

          <!-- ラジオボタン -->
          <div :class="$style.detail" v-if="row.type === 'radio'">
            <ul :class="$style.radio">
              <li v-for="opt in row.options" :key="opt">
                <input
                  type="radio"
                  :name="row.name"
                  :id="opt.name"
                  :disabled="flag.isConfirm"
                  v-on:change="updateValue">
                <label :for="opt.name">{{ opt.label }}</label>
              </li>
            </ul>
          </div>

          <!-- 生年月日 -->
          <div :class="$style.detail" v-if="row.type === 'birthday'">
            <ul :class="$style.select_wrap">
              <li>
                <select
                  :class="$style.select"
                  name="birthday"
                  id="year"
                  :disabled="flag.isConfirm"
                  v-on:change="updateValue">
                  <option
                    v-if="userBirthDay.year"
                    :value="userBirthDay.year">{{userBirthDay.year}}</option>
                  <option
                    v-if="!userBirthDay.year"
                    :value="0"></option>
                  <option
                    v-for="n of 70"
                    :key="n"
                    :value="optionYear(n)">{{ optionYear(n) }}</option>
                </select>年
              </li>
              <li>
                <select
                  :class="$style.select"
                  name="birthday"
                  id="month"
                  :disabled="flag.isConfirm"
                  v-on:change="updateValue">
                  <option
                    v-if="userBirthDay.month">{{userBirthDay.month}}</option>
                  <option
                    v-if="!userBirthDay.month"
                    :value="0"></option>
                  <option
                    v-for="n of 12"
                    :key="n"
                    :value="n">{{ n }}</option>
                </select>月
              </li>
              <li>
                <select
                  :class="$style.select"
                  name="birthday"
                  id="day"
                  :disabled="flag.isConfirm"
                  v-on:change="updateValue">
                  <option
                    v-if="userBirthDay.day"
                    :value="userBirthDay.day">{{userBirthDay.day}}</option>
                  <option
                    v-if="!userBirthDay.day"
                    :value="0"></option>
                  <option
                    v-for="n of 31"
                    :key="n"
                    :value="n">{{ n }}</option>
                </select>日
              </li>
            </ul>
          </div>
        </div>

        <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">住所</p>
            <p :class="$style.required">※必須項目</p>
          </div>
          <div :class="$style.detail">
            <spacer :y="2"/>
            <Address
              :items="items"
              :isConfirm="flag.isConfirm"
              v-on:sendValue="receiveValue"/>
          </div>
        </div>

        <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">メールアドレス</p>
            <!-- <p :class="$style.required">※必須項目</p> -->
          </div>
          <div :class="$style.detail">
            <div>
              <input
                :class="$style.text"
                placeholder="メールアドレス"
                id="mail"
                type="email"
                name="mail"
                v-model="mail"
                disabled>
            </div>
          </div>
        </div>

        <!-- パスワードの登録は不要のためコメントアウト（自動でLMSのemail） -->
        <!-- <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">パスワード</p>
            <p :class="$style.required">※必須項目</p>
          </div>
          <div :class="$style.detail">
            <div>
              <input
                :class="$style.text"
                placeholder="パスワード"
                :disabled="flag.isConfirm"
                type="password"
                id="password"
                name="password"
                v-model.trim="$v.password.$model"
                v-on:input="invalidsCheck('password', $v.password.$invalid)">
              <p
                :class="$style.error"
                v-if="$v.password.$dirty
                  && $v.password.minLength.$invalid">パスワードは8文字以上で設定してください</p>
            </div>
            <p :class="$style.assistant" v-if="!flag.isConfirm">半角英数字8桁以上15桁以下</p>
          </div>
        </div> -->

        <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">携帯番号</p>
          </div>
          <div :class="$style.detail">
            <div>
              <input
                :class="$style.text"
                type="text"
                v-model="tel"
                :disabled="flag.isConfirm">
            </div>
            <p :class="$style.assistant" v-if="!flag.isConfirm">半角・ハイフンなしで入力してください</p>
          </div>
        </div>
      </div>

      <div :class="$style.wrapper"
        v-if="userType === 1">
        <div :class="$style.row"
          v-for="row in studentItem"
          :key="row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">{{ school && school.is_company && row.companyLabel ? row.companyLabel : row.label }}</p>
            <p v-if="row.required" :class="$style.required">※必須項目</p>
          </div>
          <!-- セレクト -->
          <div :class="$style.detail" v-if="row.type === 'select'">
            <select
              :class="$style.select"
              :name="row.name"
              :id="row.name"
              :disabled="flag.isConfirm"
              v-on:change="updateSchool">
              <option :value="0" :selected="row.value === 0">選択してください</option>
              <option
                v-for="n of row.options"
                :key="n"
                :value="n.id"
                :selected="n.id === row.value">{{ n.name }}</option>
            </select>
          </div>
          <!-- テキスト -->
          <div :class="$style.detail" v-if="row.type === 'text'">
            <div>
              <input
                :class="$style.text"
                :name="row.name"
                :disabled="flag.isConfirm"
                :placeholder="school && school.is_company && row.companyPlaceholder ? row.companyPlaceholder : row.placeholder"
                v-model="row.value"
                type="text">
            </div>
            <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
          </div>
        </div>
      </div>

      <div :class="$style.wrapper"
        v-if="userType === 2">
        <div :class="$style.row"
          v-for="row in parentItem"
          :key="row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">{{ row.label }}</p>
            <p v-if="row.required" :class="$style.required">※必須項目</p>
          </div>
          <!-- テキスト -->
          <div :class="$style.detail" v-if="row.type === 'text'">
            <div>
              <input
                :class="$style.text"
                :name="row.name"
                :disabled="flag.isConfirm"
                :placeholder="row.placeholder"
                v-model="row.value"
                type="text">
            </div>
            <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
          </div>
        </div>
      </div>

      <div :class="$style.wrapper"
        v-if="userType === 3">
        <div :class="$style.row"
          v-for="row in teacherItem"
          :key="row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">{{ (school && school.is_company) && row.companyLabel ? row.companyLabel : row.label }}</p>
            <p v-if="row.required" :class="$style.required">※必須項目</p>
          </div>
          <!-- セレクト -->
          <div :class="$style.detail" v-if="row.type === 'select'">
            <select
              :class="$style.select"
              :name="row.name"
              :id="row.name"
              :disabled="flag.isConfirm"
              v-on:change="updateSchool">
              <option
                v-for="n of row.options"
                :key="n"
                :value="n.value">{{ n.label }}</option>
            </select>
          </div>
          <!-- テキスト -->
          <div :class="$style.detail" v-if="row.type === 'text'">
            <div>
              <input
                :class="$style.text"
                :name="row.name"
                :disabled="flag.isConfirm"
                :placeholder="row.placeholder"
                v-model="row.value"
                type="text">
            </div>
            <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
          </div>
        </div>
      </div>

      <div :class="$style.wrapper" v-if="flag.isConfirm">
        <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">利用規約</p>
          </div>
          <div :class="$style.detail">
            <div :class="$style.terms">
              <terms
                isSignup="true"
                :schoolId="schoolId"
                :isCompany="school && school.is_company ? 1 : 0" />
            </div>

            <spacer :y="2" />
            <ul :class="$style.checkbox_wrap">
              <li :class="$style.parentAgree" v-if="calcAge < 18">
                <input
                  type="checkbox"
                  id="parent_agree"
                  name="parent"
                  v-on:change="changeAgrees"
                  :checked="agrees.parent">
                <label for="parent_agree">未成年（18歳未満）の方は、本サービスの利用にあたり保護者の同意を得ています。</label>
              </li>
              <li :class="$style.agree">
                <input
                  type="checkbox"
                  id="agree"
                  name="agree"
                  v-on:change="changeAgrees"
                  :checked="agrees.agree">
                <label for="agree">利用規約に同意して登録する</label>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <spacer :y="5"/>
      <btn-container>
        <basic-btn
          tag="button"
          v-if="!flag.isConfirm"
          v-on:click="changeFlag">入力内容を確認する</basic-btn>
        <basic-btn
          tag="button"
          v-if="flag.isConfirm"
          v-on:click="changeFlag">編集画面に戻る</basic-btn>
        <spacer :x="2"/>
        <basic-btn
          tag="button"
          v-if="flag.isConfirm"
          :disabled="!agrees.parent || !agrees.agree"
          v-on:click="regist">入力内容を確定する</basic-btn>
      </btn-container>
    </contents-box>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import { Address } from '@/views/components/parts/Form';
import Terms from '@/views/pages/Common/Terms.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'signup-LTI',
  mixins: [cf],
  components: {
    BasicBtn,
    BtnContainer,
    ContentsBox,
    Spacer,
    Address,
    Terms,
  },
  props: {
    lti: { type: Object },
    wtt: { type: Object },
  },
  setup() {
    const password = ref('');
    const kana = ref('');

    // カタカナ・半角スペース・全角スペースのみ許す
    const kanaTextRule = (value) => /^[ァ-ヶー\s　]+$/u.test(value);

    const rules = {
      // パスワードは必須
      password: {
        required,
        minLength: minLength(8),
      },
      kana: {
        required,
        custom: kanaTextRule,
      },
    };

    const $v = useVuelidate(rules, { password, kana });

    return { password, kana, $v };
  },
  data() {
    return {
      mail: this.lti.info.user.email,
      flag: {
        readySubmit: false,
        isConfirm: false,
        alreadySet: false,
      },
      validationFlags: {
        password: false,
        kana: false,
      },
      invalids: {
        kana: false, // フリガナの登録は不要のため初期false
        password: false, // パスワードも同様
        minLength: true,
      },
      agrees: {
        parent: false,
        agree: false,
      },
      school: null,
      userDetail: null,
      tel: null,
      address: {},
      additional: ['school', 'school_year', 'student_number'],
      userType: 1,
      schoolYear: this.wtt.user.school_year,
      accountType: [
        {
          name: 'student',
          label: '生徒',
          companyLabel: 'プログラム参加者',
          value: 1,
        },
        {
          name: 'parent',
          label: '保護者',
          companyLabel: '',
          value: 2,
        },
        {
          name: 'teacher',
          label: '教職員',
          companyLabel: '企業担当者',
          value: 3,
        },
      ],
      contents: [
        {
          type: 'text',
          name: 'username',
          label: '名前',
          // required: true,
          placeholder: '例：三上　春斗',
          value: this.wtt.user.username,
        },
        {
          type: 'text',
          name: 'kana',
          label: 'フリガナ',
          // required: true,
          placeholder: '例：ミカミ　ハルト',
          value: this.wtt.user.kana,
        },
        {
          type: 'radio',
          name: 'gender',
          label: '性別',
          required: true,
          value: null,
          options: [
            {
              name: 'male',
              label: '男性',
              value: 1,
            },
            {
              name: 'female',
              label: '女性',
              value: 2,
            },
          ],
        },
        {
          type: 'birthday',
          name: 'birthday',
          label: '生年月日',
          required: true,
          values: {
            year: null,
            month: null,
            day: null,
          },
        },
      ],
      studentItem: [
        {
          type: 'text',
          name: 'student_id',
          label: '学籍番号',
          companyLabel: '特記事項',
          companyPlaceholder: 'インターン企業名',
          value: '',
        },
      ],
      parentItem: [
        {
          type: 'text',
          name: 'student_id',
          label: '学籍番号',
          companyLabel: '特記事項',
          value: null,
        },
      ],
      teacherItem: [
        {
          name: 'route',
          type: 'text',
          label: '経路',
          companyLabel: '特記事項',
          value: null,
        },
      ],
      items: [
        {
          name: 'zip',
          type: 'text',
          label: '郵便番号',
          value: null,
          required: true,
          placeholder: '1020074',
          comment: '半角・ハイフンなしで入力してください',
        },
        {
          name: 'pref',
          type: 'select',
          label: '都道府県',
          value: null,
          options: [],
          required: true,
          comment: '',
        },
        {
          name: 'city',
          type: 'text',
          label: '市区町村',
          value: null,
          required: true,
          placeholder: '千代田区九段南',
        },
        {
          name: 'address',
          type: 'text',
          label: '番地',
          value: null,
          required: false,
          placeholder: '1-5-6',
        },
        {
          name: 'building',
          type: 'text',
          label: '建物名・部屋番号',
          placeholder: 'りそな九段ビル5階 KSフロア',
          value: null,
        },
      ],
    };
  },
  created() {
    this.school = this.wtt.clientId.school;
    this.schoolId = this.wtt.clientId.school_id;
    this.password = `${this.lti.info.user.email}*2`;

    if (this.helper.master.prefs) {
      this.items[1].options = this.helper.master.prefs;
    } else {
      this.$store.subscribe((mutations) => {
        if (mutations.type === 'helper/putMaster') {
          this.items[1].options = mutations.payload.prefs;
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
    calcAge() {
      let birthday = null;
      this.contents.some((r) => {
        if (r.name === 'birthday') {
          const year = r.values.year;
          const month = r.values.month;
          const day = r.values.day;
          if (year && month && day) {
            birthday = `${year}-${month}-${day}`;
          }
        }
        return false;
      });
      const birthDate = moment(birthday);
      const nowDate = moment(new Date());
      const age = nowDate.year() - birthDate.year();
      if (!age) return null;
      const thisYearsBirthDay = `${nowDate.year()}-${birthDate.month() + 1}-${birthDate.date()}`;
      const thisYearsBirthDate = moment(thisYearsBirthDay);
      const calcAge = age + ((thisYearsBirthDate.isAfter(nowDate)) ? -1 : 0);
      return calcAge;
    },
    userBirthDay() {
      return this.contents[3].values;
    },
  },
  methods: {
    // masterのデータから該当する学校の学年を取得
    setSchoolYear() {
      if (this.school) {
        const school = this.school;
        const yearIndex = Object.keys(this.helper.master.schoolYear).findIndex((elem) => this.helper.master.schoolYear[elem].label === school.school_type);
        const schoolYear = this.helper.master.schoolYear[yearIndex].value;
        return schoolYear;
      }
    },
    optionYear(n) {
      const year = Number(this.formatTimestamp(new Date(), 'YYYY')) + 1 - n;
      return year;
    },
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    // 名前に特殊文字が入っていた際は変換して表示
    judgChar(name) {
      let result;
      if (name.includes('&#x') && name.includes(';')) { // 特殊文字のコードを変換
        result = name.replace('&#x', '*'); // インデックス取得用マーカー
        result = result.replace(';', '');
        const targetIndex = result.indexOf('*'); // マーカーのインデックスを取得
        result = result.replace('*', ''); // 開始位置を特定したら不要なので削除
        const hexString = result.slice(targetIndex, targetIndex + 4); // 16進数の文字コードを切り出し
        const hexIndex = result.indexOf(hexString);
        result = result.replace(hexString, '');
        const decrypt = String.fromCharCode(parseInt(hexString, 16));
        result = result.slice(0, hexIndex) + decrypt + result.slice(hexIndex, result.length); // 元の位置に戻す
      } else {
        result = name;
      }
      return result;
    },

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },
    /** 子から値を受け取る */
    receiveValue(values) {
      this.flag.readySubmit = values.readySubmit;
      this.address = values.address;
    },
    updateType(e) {
      this.accountType.forEach((row) => {
        if (e.target.id === row.name) {
          this.userType = row.value;
        }
      });
      if (this.userType !== 1) this.schoolYear = null;
    },
    updateValue(e) {
      // ラジオボタン
      if (e.target.type === 'radio') {
        this.contents.forEach((row) => {
          if (row.name === e.target.name) {
            row.options.forEach((opt) => {
              if (e.target.id === opt.name) {
                row.value = opt.value;
              }
            });
          }
        });
      }
      if (e.target.type === 'select-one') {
        this.contents.forEach((row) => {
          if (row.name === e.target.name) {
            row.values[e.target.id] = e.target.value < 9 ? `0${e.target.value}` : `${e.target.value}`;
          }
        });
      }
    },
    updateSchool(e) {
      if (this.userType === 1) this.studentItem[0].value = Number(e.target.value);
      else if (this.userType === 3) this.teacherItem[0].value = Number(e.target.value);
    },
    changeFlag() {
      // 18歳以上の場合は保護者同意不要
      if (this.calcAge > 17) this.agrees.parent = true;
      if (!this.flag.isConfirm) {
        // 必須項目が入力されているか確認
        // 共通項目
        let filledRequired = true;
        this.contents.forEach((row) => {
          if (row.required) {
            // if (row.name === 'kana') {
            //   // kanaはthis.kanaに格納されているため代入する
            //   // → 初期値で指定されてるためバリデーション不要
            //   row.value = this.kana;
            // }
            if (row.name === 'birthday') {
              Object.keys(row.values).forEach((key) => {
                if (!row.values[key]) filledRequired = false;
              });
            } else if (row.value === null || row.value === '') {
              filledRequired = false;
            }
          }
        });

        // 各タイプごとの確認
        let checkArray = [];
        if (this.userType === 1) checkArray = this.studentItem;
        else if (this.userType === 2) checkArray = this.parentItem;
        else if (this.userType === 3) checkArray = this.teacherItem;
        checkArray.forEach((row) => {
          if (row.required) {
            if (row.value === null || row.value === '' || !row.value) {
              filledRequired = false;
            }
          }
        });

        // 学年確認
        if (this.school.is_company === 0) {
          if (this.userType === 1) {
            if (!this.schoolYear) {
              filledRequired = false;
            }
          }
        }

        if (!filledRequired) {
          alert('必須項目が未入力です。');
          return;
        }
        if (!this.flag.readySubmit) {
          alert('住所を正しく入力してください');
          return;
        }
        if (this.invalids.mail || this.invalids.password) {
          alert('メールアドレスまたはパスワードが正しく入力されていません。');
          return;
        }

        // 必須項目が入力されていたら進む
        this.flag.isConfirm = !this.flag.isConfirm;
        const elem = document.getElementById('confirm');
        const position = elem.getBoundingClientRect().top;
        scrollBy(0, position - 77);
      } else {
        // 確認画面から編集に戻る際はagreesをリセット
        this.agrees.parent = false;
        this.agrees.agree = false;
        this.flag.isConfirm = !this.flag.isConfirm;
      }
    },

    /** 登録処理 */
    regist() {
      this.showLoading();

      const data = {
        additionalData: {},
      };

      // 共通の項目
      this.contents.forEach((row) => {
        if (row.name === 'birthday') {
          data.birthday = `${row.values.year}-${row.values.month}-${row.values.day}`;
        } else {
          data[row.name] = row.value;
        }
      });
      data.account_type = this.userType;
      data.school_year = this.schoolYear;
      data.email = this.mail;
      data.password = this.password;
      data.tel = this.tel;

      // 住所
      data.addressData = this.address;
      data.addressData.model_type = 1;

      // 各タイプごとの項目(additionalData)
      let setArray = [];
      if (this.userType === 1) setArray = this.studentItem;
      if (this.userType === 2) setArray = this.parentItem;
      if (this.userType === 3) setArray = this.teacherItem;
      setArray.forEach((row) => {
        data.additionalData[row.name] = row.value;
      });

      // 連関テーブル(organizationData)
      const userOrganization = {
        organization_type: 1, // 学校との連関は1
        foreign_id: this.schoolId,
      };
      data.organizationData = userOrganization;

      // updateのためuser.idを仕込む
      data.id = this.wtt.user.id;
      // 本登録なのでtemporaryを解除
      data.temporary = 0;
      this.axios({
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then(() => {
          // LTILogin.vueへ処理を戻す
          this.$emit('registered');

          // 登録済みEmailへ連絡
          // this.contact(response.data.userEmail);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    // contact(userEmail) {
    //   this.axios({
    //     method: 'POST',
    //     url: '/v1/contact/registed/user',
    //     data: { email: userEmail },
    //   })
    //     .then((response) => {
    //       console.log(response.data);
    //       alert('利用者登録が完了しました。\nログイン画面へリダイレクトします。');
    //       this.hideLoading();
    //       this.$router.push('/login/');
    //     })
    //     .catch((error) => {
    //       if (error.message) console.log(error.message);
    //       else console.log(error);
    //     });
    // },

    changeAgrees(e) {
      const tgt = e.currentTarget;
      const name = tgt.name;
      const checked = tgt.checked;
      this.agrees[name] = checked;
    },
  },
};
</script>

<style lang="scss" module>
.row {
  padding: 24px 0;
  border-top: 1px solid var(--gray-main);
}
.label {
  border-radius: 4px;
  margin-right: 4px;
  font-weight: bold;
  display: inline-block;
  &_wrap {
    display: flex;
    align-items: baseline;
  }
}

.school {
  margin-top: 7px;
}
.text, .select {
  &:disabled {
    color: black;
    background-color: transparent;
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }
  }
}
.select_box {
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;
  appearance: none;

  &_wrap {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.required {
  font-size: 12px;
  color: var(--pink);
}
.assistant {
  font-size: 12px;
}
.error {
  font-size: 12px;
  color: var(--pink);
}
.space {
  padding-top: 6px;
}
.text {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
}
.radio {
  padding-top: 8px;
  display: flex;

  li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
.select {
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;

  &_wrap {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.tel {
  width: 70px;
  padding: 13px;
}
.terms {
  height: 295px;
  padding: .5em 1em;
  border: 1px solid #ccc;
  overflow-y: scroll;
  border-radius: 10px;
}
.checkbox_wrap {
  font-size: 13px;
  > li {
    padding: .5em;
    &:not(:first-child) {
      margin-top: 5px;
    }
    &.parentAgree {
      background-color: rgba(#60b2c1, .3);
    }
    &.agree {
      background-color: rgba(#e7a223, .3);
    }
  }
}

.message {
  .logo {
    margin: 2em 0 4em;
  }
  img {
    display: block;
    max-width: 400px;
    width: 80%;
    margin: 0 auto;
  }
  p {
    line-height: 1.8;
    text-align: center;
  }
  a {
    text-decoration: underline;
  }
}
</style>

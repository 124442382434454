<template>
  <div>
    <spacer :y="5"/>
    <ul>
      <stack-item>
        <contents-box title="お知らせ">
        </contents-box>
      </stack-item>
      <spacer :y="2"/>
      <hr/>
      <spacer :y="2"/>
      <word-search-form placeholder="検索"/>
      <spacer :y="2"/>
      <hr/>
      <spacer :y="2"/>
      <stack-item>
        <contents-box>
        </contents-box>
      </stack-item>
      <stack-item>
        <contents-box>
        </contents-box>
      </stack-item>
    </ul>
  </div>
</template>

<script>
// import TwoColumn from '@/views/layouts/TwoColumn.vue';
// import BasicBtn from '@/views/components/BasicBtn.vue';
// import UserBox from '@/views/components/UserBox.vue';
// import BtnContainer from '@/views/components/BtnContainer.vue';
// import TabNav from '@/views/components/TabNav';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import Spacer from '@/views/components/Spacer.vue';
import WordSearchForm from '@/views/components/WordSearchForm.vue';

export default {
  name: 'Home',
  components: {
    // BasicBtn,
    // BtnContainer,
    // TwoColumn,
    // UserBox,
    // TabNav,
    ContentsBox,
    StackItem,
    Spacer,
    WordSearchForm,
  },
  created() {
    this.axios({
      method: 'GET',
      url: '/v1/connection/test',
    })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      });
  },
};
</script>

<style lang="scss" module>
.slider {
  width: 100%;
  height: 510px;
  border-radius: 10px;
  background-color: var(--primary-color);
}
</style>

<!-- 
  "オンライン健康相談の予約詳細情報"のキーを実装実体に受け渡すためのwrapped実装
-->
<template>
  <ReserveDetailImpl
    counseling_type="1"
  />
</template>

<script>
import ReserveDetailImpl from '@/views/pages/User/impl/ReserveDetailImpl.vue';

export default {
  name: 'reserve-detail-online',
  components: {
    ReserveDetailImpl,
  },
  data() {
  },
  methods: {
  },
};
</script>

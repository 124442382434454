<template>
  <main :class="page.name === 'Operator' && wideContents ? $style.container_wide : $style.container">
    <slot />
  </main>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MainContents',
  data() {
    return {
      wides: [
        'analyticsselect',
      ],
    };
  },
  computed: {
    ...mapState(['page']),
    // コンテンツ幅を画面いっぱいにする際に使用、pageのtab名がwidesの中にあるものは画面幅いっぱいまで拡大して表示する
    wideContents() {
      let result = false;
      if (this.page.breadcrumbs.list.length >= 2) {
        this.wides.forEach((wide) => {
          const index = this.page.breadcrumbs.list[1].tab.indexOf(wide);
          if (index >= 0) {
            result = true;
          }
        });
      }
      return result;
    },
  },
};
</script>

<style module lang="scss">
.container {
  display: block;
  width: 100%;
  margin: auto;
  padding: 0 var(--main-contents-gutter);
  min-height: 90vh;
  max-width: calc(var(--main-contents-width) + var(--main-contents-gutter) * 2);
  &_wide {
    display: block;
    width: 100%;
    margin:  auto;
    padding: 0 var(--main-contents-gutter);
    min-height: 90vh;
  }
}
</style>

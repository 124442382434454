<template>
  <div :class="$style.search_word_wrap">
    <i :class="$style.search_word_icon" class="fa-solid fa-magnifying-glass"></i>
    <input
      :class="$style.input"
      v-on:input="sendValue($event)"
      type="text"
      :placeholder="placeholder">
  </div>
</template>

<script>
export default {
  name: 'word-search-form',
  props: ['placeholder'],
  created() {

  },
  methods: {
    // 値を親へ渡す
    sendValue(e) {
      const data = {};
      data.name = e.currentTarget.name;
      data.value = e.currentTarget.value;
      this.$emit('sendValue', data);
    },
  },
};
</script>

<style lang="scss" module>
.input {
  outline: none;
  border: none;
  background-color: var(--gray-sub);
  width: 100%;
  padding: 15px 15px 15px 55px;
  border-radius: 45px;
}
.search {
  &_word {
    &_wrap {
      position: relative;
    }
    &_icon {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      opacity: .6;
    }
  }
}
</style>

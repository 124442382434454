<template>
  <div>
    <spacer :y="15"/>
    <div>
      <img :class="$style.logo" src="@/assets/images/logo_vertical.png" alt="logo">
    </div>
    <spacer :y="5"/>
    <h2 :class="$style.title">パスワード再発行</h2>

    <spacer :y="3"/>
    <p
      :class="$style.message"
      v-if="!flag.sended">ご登録のメールアドレスを入力・送信してください。</p>
    <p
      :class="$style.message"
      v-if="flag.sended
        && !flag.updated">送信いただいたメールアドレスのご登録は存在しませんでした。ご登録のメールアドレスをご確認ください。</p>
    <p
      :class="$style.message"
      v-if="flag.sended
        && flag.updated">ご登録のメールアドレスへパスワードリセット用のメールを送信しました。<br>メールに記載されているURLからパスワードのリセットを行ってください。</p>
    <spacer :y="3"/>

    <form
      v-on:submit="submit"
      autocomplete="off">
      <div :class="$style.form">
        <div :class="$style.input">
          <input
            id="mail"
            type="email"
            name="mail"
            placeholder="メールアドレス"
            v-bind:disabled="flag.sended && flag.updated"
            v-model.trim="$v.mail.$model"
            v-bind:class="{ input_error: validationFlags.mail }"
            v-on:input="invalidsCheck('mail', $v.mail.$invalid)"
            >
          <p
            :class="$style.comment"
            v-if="$v.mail.$dirty
              && $v.mail.required.$invalid">メールアドレスを入力してください</p>
          <p
            :class="$style.comment"
            v-if="$v.mail.$dirty
              && $v.mail.email.$invalid">正しいメールアドレスの形式で入力してください</p>
        </div>
      </div>
      <spacer :y="3"/>
      <p :class="$style.message">
        登録アドレスに、パスワード再設定メールをお送りします。<br>
        受信設定されている場合は、Welcome to talk ＜donotreply@welcometotalk.com＞からの<br>
        メールを受け取れるように設定をお願いします。<br>
        24時間以上手続きがない場合、再設定用URLは無効となりますのでご注意ください。
      </p>
      <spacer :y="3"/>
      <div :class="$style.wrap">
        <button
          type="submit"
          :class="$style.btn"
          v-bind:disabled="!submitFlag">送信</button>
      </div>
    </form>
    <spacer :y="15"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'forgot-password',
  mixins: [cf],
  components: {
    Spacer,
  },
  data() {
    return {
      validationFlags: {
        mail: false,
      },
      invalids: {
        mail: true,
      },
      flag: {
        sended: false,
        updated: false,
      },
    };
  },
  created() {
  },
  setup() {
    const mail = ref('');

    const rules = {
      // メールは必須・email形式
      mail: { required, email },
    };

    const $v = useVuelidate(rules, { mail });

    return { mail, $v };
  },
  computed: {
    ...mapState(['user', 'page']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.mail && !(this.flag.sended && this.flag.updated);
    },
  },
  methods: {
    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();
      const data = { mail: this.mail };

      this.axios({
        method: 'POST',
        url: '/v1/user/forgotPassword',
        data,
      })
        .then((response) => {
          const resData = response.data;
          if (resData.updated) this.flag.updated = true;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.sended = true;
        });
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  display: flex;
  justify-content: center;
}
.logo {
  margin: 0 auto;
  width: 300px;
  max-width: 100%;
}
.title {
  text-align: center;
  color: var(--orange-main);
}
.form {
  text-align: center;
}
.input {
  margin: 0 auto;
  width: 400px;
  max-width: 100%;
  input {
    width: 100%;
    padding: 13px;
    border-radius: 8px;
    border: none;
    background-color: var(--gray-sub);
    outline: none;
  }
}
.comment {
  font-size: 12px;
  color: var(--pink);
}
.message {
  font-size: 12px;
  text-align: center;
}
.btn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
}
</style>

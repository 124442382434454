<template>
  <div :class="wrapperClass">
    <global-header v-if="flag.showHeader" />
    <breadcrumbs v-if="flag.showHeader && flag.showBreadcrumbs" />
    <main-contents>
      <router-view />
    </main-contents>
    <global-footer v-if="flag.showHeader" />
    <fixed-bottom-nav />
    <drawer-menu />
    <ContentsModal />
    <MessagesModal />
    <LoadingsModal />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GlobalHeader from '@/views/layouts/GlobalHeader.vue';
import MainContents from '@/views/layouts/MainContents.vue';
import GlobalFooter from '@/views/layouts/GlobalFooter.vue';
import DrawerMenu from '@/views/layouts/DrawerMenu.vue';
import FixedBottomNav from '@/views/layouts/FixedBottomNav.vue';
import Breadcrumbs from '@/views/layouts/Breadcrumbs.vue';
import { ContentsModal, MessagesModal, LoadingsModal } from '@/views/components/modal';
import 'vue3-carousel/dist/carousel.css';

export default {
  name: 'App',
  components: {
    GlobalHeader,
    MainContents,
    GlobalFooter,
    DrawerMenu,
    FixedBottomNav,
    Breadcrumbs,
    ContentsModal,
    MessagesModal,
    LoadingsModal,
  },
  data() {
    return {
      flag: {
        showHeader: true,
        showBreadcrumbs: true,
      },
      hideHeader: [
        'login',
        'forgot-password',
        'reset-password',
      ],
      hideBreadcrumbs: [
        '/',
        '/teacher/', '/teacher',
        '/counselor/', '/counselor',
        '/operator/', '/operator',
        '/signup/', '/signup',
        '/signup2/', '/signup2',
        '/training/h3ddWNr9U7eD/', '/training/h3ddWNr9U7eD',
        '/lti/',
      ],
    };
  },
  computed: {
    ...mapState(['page', 'user']),
    wrapperClass() {
      if (!this.flag.showHeader) {
        return '';
      }
      if (!this.flag.showBreadcrumbs) {
        return `${this.$style.header}`;
      }
      return `${this.$style.withBreadcrumbs}`;
    },
    isModal() {
      const contents = this.$store.state.modal.contents.modalName !== '';
      const loadings = this.$store.state.modal.loadings.modalName !== '';
      const messages = this.$store.state.modal.messages.modalName !== '';
      return contents || loadings || messages;
    },
  },
  created() {
    // ログインチェック
    this.$store.dispatch('user/login/check');
    // backend共通マスターデータ取得
    this.$store.dispatch('helper/getMaster');
    this.$store.dispatch('helper/getTextDisableStatus');
  },
  watch: {
    $route(to) {
      // メタ情報の仕込み
      if (this.user.email) {
        this.setMetas(to, this.$store);
      } else {
        this.$store.subscribe((mutation) => {
          if (mutation.type === 'user/setUserData') {
            this.setMetas(to, this.$store);
          }
        });
      }
      this.checkFlags();
    },
  },
  methods: {
    setMetas: (route, store) => {
      const meta = {};
      // 個別設定がない場合のデフォルト設定
      const defaults = {
        title: 'Welcome to talk',
        description: '',
      };

      const user = store.state.user;
      let metaTitle = route && route.meta ? route.meta.title : '';
      if (user && user.school.length && user.school[0].is_company && metaTitle) {
        if (metaTitle.includes('学校だより')) {
          metaTitle = metaTitle.replace(/学校だより/g, '企業レター');
        } else if (metaTitle.includes('学校')) {
          metaTitle = metaTitle.replace(/学校/g, '企業');
        } else if (metaTitle.includes('教育機関')) {
          metaTitle = metaTitle.replace(/教育機関/g, '導入企業');
        }
      }

      meta.name = route.name;
      meta.title = metaTitle ? `${metaTitle} | ${defaults.title}` : defaults.title;
      meta.description = route.meta.description || defaults.description;

      // meta sets
      document.title = meta.title;
      document.querySelector("meta[name='description']").setAttribute('content', meta.description);

      // store.pageに保持させる
      store.dispatch('page/setPageInfo', meta);
      store.dispatch('page/breadcrumbs/setBreadCrumbs', { meta, route, isCompany: user.school.length && user.school[0].is_company ? 1 : 0 });
    },
    checkFlags() {
      this.flag.showHeader = true;
      this.flag.showBreadcrumbs = true;
      this.hideHeader.forEach((name) => {
        if (this.$route.path.includes(name)) {
          this.flag.showHeader = false;
        }
        if (this.hideBreadcrumbs.includes(this.$route.path)) {
          this.flag.showBreadcrumbs = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
// サイト全体のreset style
@import '@/assets/styles/reset.scss';

// CSS変数の定義ファイル
@import '@/assets/styles/variables.scss';

// fontawesome
@import '@/assets/styles/fonts.scss';

// notosansフォント指定
body {
  font-family: $notosansFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<style lang="scss" module>
.header {
  padding-top: var(--header-height);
}
.withBreadcrumbs {
  padding-top: calc(var(--header-height) + var(--breadcrumbs-height));
}
</style>


<style lang="scss">
.n2br {
  > * {
    white-space: pre-wrap;
    word-break: break-all;
  }
}
@-moz-document url-prefix() {
  // firefox向け
  .n2br {
    > * {
      word-wrap: break-word;
    }
  }
}

input, textarea {
  font-size: 16px;
  resize: vertical;
}

.disabled {
  opacity: .5;
  pointer-events: none;
  transition: .4s opacity;
}

html {
  scroll-behavior: smooth;
}

a {
  word-break: break-all;
}

::placeholder{
  color:#bbb;
  text-align: left;
}

.carousel__prev, .carousel__next {
  $size: 3em;
  width: $size;
  color: gray;
  background-color: transparent;
  top: 45%;
  .carousel__icon {
    width: $size;
    height: $size;
    transition: all .4s;
    &:hover {
      opacity: .6;
    }
  }
  @include sm-view {
    $size: 2em;
    width: $size;
    .carousel__icon {
      width: $size;
      height: $size;
    }
  }
}
.carousel__pagination-button {
  margin: var(--vc-pgn-margin);
  padding: 3px;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  background-color: silver;
}
.carousel__pagination-button {
  background-color: silver;
}
.carousel__pagination-button--active {
  background-color: #333333;
}
.carousel__slide {
  img {
    cursor: pointer;
    transition: all .4s;
    &:hover {
      opacity: .8;
    }
  }
}
</style>

<template>
  <div
    :class="[$style.nav, $style[menuType]]"
    v-if="this.user.login.isLogin && menuType">
    <div
      :class="[$style.wrap, nav.active ? $style.active : '']"
      v-for="nav in nav[menuType]"
      :key="nav.icon">
      <router-link
        :to="nav.to">
        <i
          class="fa-regular"
          :class="[$style.icon, nav.icon]">
          <i
            class="fa-regular"
            :class="[$style.icon_inner, nav.innerIcon]"
            v-if="nav.innerIcon"></i>
        </i>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'fixed-bottom-nav',
  data() {
    return {
      nav: {
        customer: [
          {
            icon: 'fa-house-user',
            to: { path: '/', query: {} },
          },
          {
            icon: 'fa-monitor-waveform',
            to: { path: '/', query: { tab: 'cocomoni' } },
          },
          {
            icon: 'fa-comment-lines',
            to: { path: '/', query: { tab: 'text' } },
          },
          {
            icon: 'fa-laptop',
            innerIcon: 'fa-user-headset',
            to: { path: '/', query: { tab: 'online' } },
          },
          {
            icon: 'fa-vr-cardboard',
            to: { path: '/', query: { tab: 'virtual' } },
          },
          {
            icon: 'fa-files-medical',
            to: { path: '/', query: { tab: 'history' } },
          },
          {
            icon: 'fa-user-doctor',
            to: { path: '/', query: { tab: 'counselors' } },
          },
          {
            icon: 'fa-user-group',
            to: { path: '/', query: { tab: 'units' } },
          },
        ],
        counselor: [
          {
            icon: 'fa-house-user',
            to: { path: '/counselor/', query: {} },
          },
          {
            icon: 'fa-laptop',
            innerIcon: 'fa-user-headset',
            to: { path: '/counselor/', query: { tab: 'online' } },
          },
          {
            icon: 'fa-vr-cardboard',
            to: { path: '/counselor/', query: { tab: 'virtual' } },
          },
          {
            icon: 'fa-comment-lines',
            to: { path: '/counselor/', query: { tab: 'text' } },
          },
          {
            icon: 'fa-calendar-days',
            to: { path: '/counselor/', query: { tab: 'schedule' } },
          },
        ],
        teacher: [
          {
            icon: 'fa-house-user',
            to: { path: '/teacher/', query: {} },
          },
          {
            icon: 'fa-user',
            to: { path: '/teacher/', query: { tab: 'students' } },
          },
          {
            icon: 'fa-calendar-check',
            to: { path: '/teacher/', query: { tab: 'reserve' } },
          },
          {
            icon: 'fa-files-medical',
            to: { path: '/teacher/', query: { tab: 'history' } },
          },
          {
            icon: 'fa-user-doctor',
            to: { path: '/teacher/', query: { tab: 'counselors' } },
          },
          {
            icon: 'fa-user-group',
            to: { path: '/teacher/', query: { tab: 'units' } },
          },
          {
            icon: 'fa-qrcode',
            to: { path: '/teacher/', query: { tab: 'qr' } },
          },
        ],
        operator: [
          {
            icon: 'fa-house-user',
            to: { path: '/operator/', query: {} },
          },
          //  {
          //   label: 'お問い合わせ',
          //   name: 'inquiries',
          //   new: false,
          // },
          {
            icon: 'fa-calendar-check',
            to: { path: '/operator/', query: { tab: 'online' } },
          },
          {
            icon: 'fa-calendar-check',
            to: { path: '/operator/', query: { tab: 'virtual' } },
          },
          {
            icon: 'fa-files-medical',
            to: { path: '/operator/', query: { tab: 'history' } },
          },
          {
            icon: 'fa-comment-lines',
            to: { path: '/operator/', query: { tab: 'text' } },
          },
          {
            icon: 'fa-user',
            to: { path: '/operator/', query: { tab: 'users' } },
          },
          {
            icon: 'fa-user-group',
            to: { path: '/operator/', query: { tab: 'units' } },
          },
          {
            icon: 'fa-school',
            to: { path: '/operator/', query: { tab: 'schools' } },
          },
          {
            icon: 'fa-user-doctor',
            to: { path: '/operator/', query: { tab: 'counselors' } },
          },
          {
            icon: 'fa-calendar-days',
            to: { path: '/operator/', query: { tab: 'counseling' } },
          },
          {
            icon: 'fa-circle-info',
            to: { path: '/operator/', query: { tab: 'information' } },
          },
          {
            icon: 'fa-bells',
            to: { path: '/operator/', query: { tab: 'holidays' } },
          },
        ],
      },
    };
  },
  created() {
    if (this.user.account_type) {
      this.getActive(this.$route);
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.getActive(this.$route);
        }
      });
    }
  },
  computed: {
    ...mapState(['user']),
    /** account_typeでメニュー出しわけ */
    menuType() {
      let type = null;
      const customerTypes = [1, 2, 3];
      const counselorTypes = [21, 22];
      const teacherTypes = [11, 12];
      const operatorTypes = [31];
      const accountType = this.user.account_type;
      if (this.user.account_type) {
        if (customerTypes.includes(accountType)) {
          type = 'customer';
          if (this.user.account_type !== 3) {
            this.spliceItem();
          }
        } else if (counselorTypes.includes(accountType)) {
          type = 'counselor';
        } else if (teacherTypes.includes(accountType)) {
          type = 'teacher';
        } else if (operatorTypes.includes(accountType)) {
          type = 'operator';
        }
      }
      return type;
    },
  },
  watch: {
    /** routeが切り替わる度に検出 */
    $route(to) {
      this.getActive(to);
    },
  },
  methods: {
    /** active状態の変更 */
    getActive(to) {
      if (!this.menuType) return;
      const path = to.path;
      const query = to.query;
      const nav = this.nav[this.menuType];
      const toData = { path, query };
      nav.forEach((row) => {
        if (JSON.stringify(toData) === JSON.stringify(row.to)) {
          row.active = true;
        } else {
          row.active = false;
        }
      });
    },

    spliceItem() {
      const target = this.nav.customer.findIndex((item) => item.icon === 'fa-user-group');
      if (target > 0) this.nav.customer.splice(target, 1);
    },
  },
};
</script>

<style lang="scss" module>
.nav {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  width: 100vw;
  box-shadow: 0 10px 30px 0 rgba(#333, .2);
  display: none;
  justify-content: center;
  padding: 12px;
  z-index: 10;

  @include sm-view {
    display: flex;
  }
  &.operator {
    .wrap {
      $size: 2em;
      width: $size;
      height: $size;
      border-radius: $size;
      &:not(:first-child) {
        margin-left: 5px;
      }
      .icon {
        font-size: 18px;
      }
    }
  }
}
.wrap {
  background-color: var(--gray-main);
  height: 45px;
  width: 45px;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(:first-child) {
    margin-left: 10px;
  }
  &.active {
    background-color: var(--blue-main);
  }
}
.icon {
  color: #fff;
  font-size: 24px;
  position: relative;

  &_inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    font-size: 9px;
  }
}
</style>

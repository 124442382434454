<template>
  <div>
    <p v-if="this.labelsData.length <= 1">表示できるデータがありません</p>
    <div v-else :class="$style.sizeAdjust">
      <p :class="$style.graph_y_label">週</p>
      <Bar
        :chartData="ChartData"
        :chart-options="chartOptions"
        css-classes="graphStyle"
        width="380"
        height="200"
      />
      <p :class="$style.graph_x_label">回</p>
    </div>
  </div>
</template>

<script>
import {
  Chart,
  registerables,
} from 'chart.js';
import { Bar } from 'vue-chartjs';

Chart.register(...registerables);

export default {
  name: 'BarChart',
  components: {
    Bar,
  },
  props: {
    Axis: {
      type: String,
      default: 'x',
    },
    labelsData: {
      type: Array,
      default: null,
    },
    datasetsData: {
      type: Object,
      default: null,
    },
    sort: { // 入力により昇順、降順でソートする
      type: String,
      default: null,
    },
    options: { // 個別にグラフスタイルをいじりたい時は呼び出し元でオプションを設定
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      temprary: null,
      totals: null,
      ChartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        indexAxis: null,
        plugins: {
          legend: {
            display: false, // 凡例の非表示
          },
          tooltip: {
            yAlign: 'center',
            xAlign: 'center',
            callbacks: {
              title: () => null,
              label: (item) => {
                const onHoverText = [
                  `WHO-5(ave): ${item.raw.meta.aveWHO5}点`,
                  `件数: ${item.raw.meta.total}件`,
                ];
                return onHoverText;
              },
            },
            filter: (item) => {
              let display = false;
              if (item.raw.meta.aveWHO5 !== null) display = true;
              return display;
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              stepSize: 1, // メモリのステップサイズを1.0に設定（標準は自動なので少ないと0.25になったりする）
            },
          },
          y: {
            stacked: true,
            max: null,
            grid: {
              drawBorder: false,
              display: true,
              tickColor: 'rgba(255, 255, 255, 0)',
            },
            ticks: {
              stepSize: 2,
            },
          },
        },
        barPercentage: 1.25, // バーの太さを余白と相対的に指定
        responsive: true,
      },
    };
  },
  created() {
    this.chartOptions.indexAxis = this.Axis;
  },
  mounted() {
    this.drawGraph();
  },
  watch: {
    labelsData() { // 期日や絞り込み条件の変動があったら再描画
      this.updateGraph();
    },
  },
  methods: {
    updateGraph() {
      this.ChartData.labels = [];
      this.ChartData.datasets = [];
      this.drawGraph();
    },
    drawGraph() {
      if (this.labelsData.length <= 1) return true;
      this.labelsData.forEach((elem) => {
        this.ChartData.labels.push(`${elem}回目`);
      });
      this.ChartData.labels.shift();
      // 参照渡しだと親コンポーネント側でもpropsに渡している値が変更されたためdeepcopy
      this.temprary = JSON.parse(JSON.stringify(this.datasetsData.data));
      this.totals = JSON.parse(JSON.stringify(this.datasetsData.totalData));
      this.totals.shift();
      this.temprary.splice(0, 2);

      this.temprary.forEach((count) => {
        count.pop(); // 最終的に配列の最後にn回目の表示をバックエンドでつけないようにするので不要になる?
      });

      this.temprary[0].forEach((data, index) => {
        this.ChartData.datasets.push({
          labels: `${(index + 1) * 2}w`,
          data: [],
          backgroundColor: [],
        });
      });

      this.temprary.forEach((data, j) => {
        data.reverse();
        data.forEach((elem, i) => {
          this.ChartData.datasets[i].data.push({
            x: j + 2,
            y: 2,
            meta: {
              aveWHO5: data[i],
              total: this.totals[j][i],
            },
          });
          this.ChartData.datasets[i].backgroundColor.push(data[i] === 0 ? '#EEEEEE' : `rgba(26, 115, 232, ${data[i] / 25})`);
        });
      });
      this.chartOptions.scales.y.max = this.temprary[0].length * 2;
    },
  },
};
</script>

<style lang="scss" module>
.sizeAdjust {
  position: relative;
  padding-right: 1%;
}
.graph_y_label {
  font-size: .8em;
  color: #666666;
  padding-left: 1em;
}
.graph_x_label {
  font-size: .8em;
  color: #666666;
  margin: -1.6em;
  margin-right: -2em;
  text-align: right;
}
</style>

<style lang="scss" scoped>
.bar-consul {
  @media screen and (min-width: 1080px) {
    max-width: 500px;
  }
}
</style>

<template>
  <div>
    <spacer :y="5"/>
    <contents-box :title="helper.master && user.school ? helper.master.labels.organizationNews[user.school[0].is_company] : ''" icon="<i class='fa-regular fa-bullhorn'></i>" id="scroll-top">
      <loader-simple :flag="flag.loader">
        <div v-if="!flag.invalid && informations.length">
          <stack-item
            v-for="row in informations"
            :key="row.title">
            <router-link
              :to="`/schoolInformation/${row.id}/`" :class="$style.link">
              <contents-box type="inner">
                <label-box
                  label="NEW"
                  size="sm"
                  color="orange"
                  v-if="user.readflags && !user.readflags.schoolInformations.includes(row.id)"
                  :class="$style.label" />
                <spacer
                  :x="2"
                  v-if="user.readflags && !user.readflags.schoolInformations.includes(row.id)"/>
                <div :class="$style.flex">
                  <div :class="$style.labels">

                    <label-box
                      :label="filterMoment(row.updated_at, 'YYYY-MM-DD')"
                      size="sm"
                      color="gray"
                      :class="`${$style.label} ${$style.date}`" />

                    <spacer :x="2"/>

                    <label-box
                      label="重要"
                      size="sm"
                      color="pink"
                      v-if="row.flag === 11"
                      :class="$style.label" />
                  </div>
                </div>
                <spacer :y="1"/>
                <div :class="$style.title">{{ row.title }}</div>
              </contents-box>
            </router-link>
          </stack-item>
        </div>

        <div v-else-if="flag.invalid">
          <contents-box title="閲覧できません">
            <div>URLが無効であるか、閲覧する権限を持ちません。</div>
          </contents-box>
        </div>
        <spacer :y="3"/>
          <pagination
            :page="page"
            :pageMax="pageMax"
            :path="'/schoolInformation'"
            v-if="informations.length && pageMax !== 1" />

        <spacer :y="5" />
        <btn-container>
          <basic-btn to="/">トップへ戻る</basic-btn>
        </btn-container>
      </loader-simple>
    </contents-box>
    <spacer :y="5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import cf from '@/mixins/commonFunctions';
import Pagination from '@/views/components/Pagination.vue';

export default {
  name: 'information-list',
  mixins: [cf],
  components: {
    ContentsBox,
    StackItem,
    LabelBox,
    Spacer,
    BtnContainer,
    BasicBtn,
    LoaderSimple,
    Pagination,
  },
  data() {
    return {
      flag: {
        loader: true,
        pageQuery: false,
      },
      informations: [],
      page: 1,
      pageMax: 1,
      limit: 20,
      total: 0,
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  created() {
    const path = this.$route;
    if (path.query.page) {
      this.page = Number(path.query.page);
    } else if (path.path === '/schoolInformation/') {
      // queryにpageがなければ追加してreplace
      this.$router.replace('/schoolInformation/?page=1');
    }
    if (this.user.email) {
      this.getSchoolInformations();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.getSchoolInformations();
        }
      });
    }
  },
  watch: {
    $route() {
      const path = this.$route;
      if (path.query.page) {
        this.page = Number(path.query.page);
      } else if (path.path === '/schoolInformation/') {
        // queryにpageがなければ追加してreplace
        this.$router.replace('/schoolInformation&page=1');
      }
      this.getSchoolInformations();
      this.flag.pageQuery = true;
    },
  },
  methods: {
    /** お知らせ取得 */
    getSchoolInformations() {
      this.axios({
        method: 'GET',
        url: '/v1/schoolinformation/get/list',
        params: {
          school_id: this.user.school[0].id,
          order: 'updated_at',
          orderBy: 'desc',
          page: this.page,
          limit: this.limit,
        },
      })
        .then((response) => {
          if (![1, 11, 12, 31].includes(this.user.account_type)) {
            // 生徒・学校管理者・事務局管理者以外は閲覧不可
            this.flag.invalid = true;
            this.flag.loader = false;
            return false;
          }
          // this.informations = response.data.schoolinformations.data;
          const data = response.data.schoolinformations.data;
          const pageInfo = response.data.schoolinformations;
          this.informations = data;
          this.pageMax = pageInfo.lastPage;
          this.total = pageInfo.total;
          this.flag.loader = false;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          if (this.flag.pageQuery) this.scroll2Top();
          this.flag.pageQuery = false;
        });
    },
    scroll2Top() {
      // 上までスクロール
      const elem = document.getElementById('scroll-top');
      const position = elem.getBoundingClientRect().top;
      scrollBy(0, position - 77);
    },
  },
};
</script>

<style lang="scss" module>
.link {
  color: var(--black);
  &:hover {
    .title {
      text-decoration: underline;
    }
  }
}
.labels {
  display: flex;
  flex-wrap: wrap;
}
.label {
  width: 80px;
  display: inline-block;
  &.date {
    width: 120px;
    @include sm-view {
      display: block;
      width: 100%;
    }
  }
  @include sm-view {
    display: block;
    width: 100%;
  }
}
.flex {
  display: flex;
  align-items: flex-start;

  @include sm-view {
    display: block;
  }
}
.hideSp {
  @include sm-view {
    display: none;
  }
}
.title {
  flex: 1;
}
</style>

<!-- 
  "バーチャル健康相談を表すタブ情報"を実装実体に受け渡すためのwrapped実装
-->
<template>
  <CounselingImpl
    tab="virtual"
    type="virtualReserve"
    counseling_type="3"
  />
</template>

<script>
import CounselingImpl from '@/views/pages/Counselor/tabs/impl/CounselingImpl.vue';

export default {
  name: 'counselor-virtual-counseling',
  components: {
    CounselingImpl,
  },
  data() {
  },
  methods: {
  },
};
</script>

<template>
  <div id="scroll-top">
    <spacer :y="5" />
    <ul>

      <stack-item>
        <contents-box title="検索">
          <div :class="$style.history_search">
            <spacer :y="2" />
            <div :class="$style.date">
              <dl>
                <dt><i class="fa-solid fa-calendar-days"></i>受付期間</dt>
                <dd>
                  <div>
                    <date-small-picker
                      :name="'searchCondition_receiveStartDate'"
                      v-model="searchCondition.receiveStartDate"
                      v-on:sendDate="setReceiveDate" />
                  </div>
                    <date-small-picker
                      :name="'searchCondition_receiveEndDate'"
                      v-model="searchCondition.receiveEndDate"
                      v-on:sendDate="setReceiveDate" />
                </dd>
              </dl>
              <div v-if="!searchCondition.receiveStartDate && searchCondition.receiveEndDate">
                <spacer :y="1"/>
                <p :class="$style.alert_text">受付期間の開始日を選択してください</p>
              </div>
              <spacer :y="1"/>
              <dl>
                <div v-if="searchCondition.receiveStartDate && searchCondition.receiveEndDate && !(fitlyReceivePeriod)" :class="$style.alert_text">正しい期間を選択してください</div>
              </dl>
                <spacer :y="1"/>
              <dl>
                <dt><i class="fa-solid fa-calendar-days"></i>回答期間</dt>
                <dd>
                  <div>
                    <date-small-picker
                      :name="'searchCondition_answerStartDate'"
                      v-model="searchCondition.answerStartDate"
                      v-on:sendDate="setAnswerDate" />
                  </div>
                    <date-small-picker
                      :name="'searchCondition_answerEndDate'"
                      v-model="searchCondition.answerEndDate"
                      v-on:sendDate="setAnswerDate" />
                </dd>
              </dl>
              <div v-if="!searchCondition.answerStartDate && searchCondition.answerEndDate">
                <spacer :y="1"/>
                <p :class="$style.alert_text">受付期間の開始日を選択してください</p>
              </div>
              <spacer :y="1"/>
              <dl>
                <div v-if="searchCondition.answerStartDate && searchCondition.answerEndDate && !(fitlyAnswerPeriod)" :class="$style.alert_text">正しい期間を選択してください</div>
              </dl>
              <spacer :y="1"/>
              <dl :class="$style.select">
                <dt>除外</dt>
                <div :class="$style.checkBox">
                  <input
                    type="checkbox"
                    id="excludeTest"
                    v-model="searchCondition.flag.excludeTest"
                  >
                  <label for="excludeTest">テストアカウント</label>
                </div>
              </dl>
              <spacer :y="2" />
              <dl :class="$style.select">
                <dt>学校・企業ソート</dt>
                <dd>
                  <select
                    :class="$style.select_box"
                    name="school"
                    id="school"
                    v-on:change="changeSchool">
                    <option :value="0" :selected="searchCondition.targetSchool === 0">すべて</option>
                    <option
                      v-for="n of searchCondition.schools"
                      :key="n"
                      :value="n.id"
                      :selected="n.id === searchCondition.targetSchool">{{ n.name }}</option>
                  </select>
                </dd>
              </dl>
              <spacer :y="2" />
              <dl :class="$style.select">
                <dt>心理士ソート</dt>
                <dd>
                  <select
                    :class="$style.select_box"
                    name="counselor"
                    id="counselor"
                    v-on:change="changeCounselor">
                    <option :value="0" :selected="searchCondition.targetCounselor === 0">すべて</option>
                    <option
                      v-for="counselor of searchCondition.counselors"
                      :key="counselor"
                      :value="counselor.id"
                      :selected="counselor.id === searchCondition.targetCounselor ">{{ counselor.username }}</option>
                  </select>
                </dd>
              </dl>
            </div>
            <spacer :y="4" />
            <div :class="$style.button_area">
              <basic-btn
                tag="button"
                :disabled="isLoading || !validSearchCondition"
                @click="clickSearch">
                <sapn>検索</sapn>
              </basic-btn>
              <spacer :x="2"/>
              <basic-btn
                tag="button"
                :disabled="isLoading"
                @click="clickClear">
                <sapn>クリア</sapn>
              </basic-btn>
            </div>
          </div>
        </contents-box>
      </stack-item>

      <stack-item v-if="showProgressArea">
        <spacer :y="2"/>
        <contents-box title="回答待ち(下書きも含む)">
          <loader-simple :flag="flag.loader.progress">
            <div v-if="!flag.loader.progress && user.counseling.op.text.progress.length">
              <p>回答待ち(下書きも含む)の相談 <span :class="$style.bold">{{ user.counseling.op.text.progress.length }}件</span></p>
              <spacer :y="3"/>
              <histories
                :histories="user.counseling.op.text.progress"
                :showFromUser="true"
                :showNew="false"
                :showCustomerId="true"
                :hideCounseling="true"
                :isAbleToClickUserBox="true"
                :showReplyDeadline="true"
              />
            </div>
            <p v-if="!flag.loader.progress && !user.counseling.op.text.progress.length">回答待ちのテキスト健康相談はありません。</p>
          </loader-simple>
        </contents-box>
      </stack-item>

      <stack-item>
        <spacer :y="2"/>
        <contents-box title="回答済み">
          <loader-simple :flag="flag.loader.history">
            <div v-if="!flag.loader.history">
              <p v-if="answeredTotal">回答済みの相談 <span :class="$style.bold">{{ answeredTotal }}件</span></p>
              <pagination
                v-if="user.counseling.op.text.answeredOptions.pageMax
                  && user.counseling.op.text.answeredOptions.pageMax !== 1"
                type="textHistoryPage"
                :page="page"
                :pageMax="user.counseling.op.text.answeredOptions.pageMax"
                :path="'/operator/?tab=text'" />

              <spacer v-if="user.counseling.op.text.answered[page]
                && user.counseling.op.text.answered[page].length" :y="3" />
              <histories
                v-if="user.counseling.op.text.answered[page]
                  && user.counseling.op.text.answered[page].length"
                :histories="user.counseling.op.text.answered[page]"
                :showFromUser="true"
                :showCustomerId="true"
                :hideCounseling="true"
                :isAbleToClickUserBox="true" />

              <p v-if="user.counseling.op.text.answered[page]
                && !user.counseling.op.text.answered[page].length">回答済みのテキスト健康相談はありません。</p>

              <spacer :y="3"/>
              <pagination
                v-if="user.counseling.op.text.answeredOptions.pageMax
                  && user.counseling.op.text.answeredOptions.pageMax !== 1"
                type="textHistoryPage"
                :page="page"
                :pageMax="user.counseling.op.text.answeredOptions.pageMax"
                :path="'/operator/?tab=text'" />
            </div>
          </loader-simple>
        </contents-box>
      </stack-item>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Histories from '@/views/components/Histories.vue';
import Spacer from '@/views/components/Spacer.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Pagination from '@/views/components/Pagination.vue';
import DateSmallPicker from '@/views/components/DateSmallPicker.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'text-counseling',
  mixins: [cf],
  components: {
    StackItem,
    ContentsBox,
    Histories,
    Spacer,
    LoaderSimple,
    Pagination,
    DateSmallPicker,
    BasicBtn,
  },
  data() {
    return {
      flag: {
        loader: {
          progress: false,
          history: false,
        },
        search: {
          isSearching: false, // 現在が検索条件による絞り込み状態か
          onChangeCondition: false, // trueのときは検索条件が変わったときなので、取得済み情報は破棄して情報再取得が必要
        },
      },
      progress: [],
      page: 1,
      pageMax: 1,
      limit: 10,
      searchMode: false, // trueの場合は回答済み検索モード状態で、「回答待ち(下書きも含む)」エリアは表示しない
      searchCondition: { // 回答済み健康相談に対する検索条件
        receiveStartDate: null,
        receiveEndDate: null,
        answerStartDate: null,
        answerEndDate: null,
        counselors: [],
        targetCounselor: null,
        flag: {
          excludeTest: false,
        },
      },
      answeredTotal: 0, // 表示対象の回答済み相談総件数
    };
  },
  created() {
    // タブ移動時にもcreatedが走ってる
    // 検索条件はクエリパラメータで管理していないため、タブ移動前に表示していた情報（検索下での取得結果）をそのまま再利用することができないケースがある
    // よってここでは全取得を基本として実行する

    // 検索条件エリアの整備
    this.getSchools();
    this.getCounselor();

    const query = this.$route.query;
    if (query.page) {
      this.page = Number(query.page);
    } else if (query.tab === 'text') { // queryにpageがなければ追加してreplace
      this.$router.replace('/counselor/?tab=text&page=1'); // クエリを変更することで検索を実行する
      return;
    }

    this.getTypeTexts();
  },
  computed: {
    ...mapState(['user']),
    fitlyReceivePeriod() {
      return moment(this.searchCondition.receiveEndDate).isAfter(moment(this.searchCondition.receiveStartDate));
    },
    fitlyAnswerPeriod() {
      return moment(this.searchCondition.answerEndDate).isAfter(moment(this.searchCondition.answerStartDate));
    },
    showProgressArea() {
      return this.page === 1 && this.progress.length > 0;
    },
    isLoading() {
      return this.flag.loader.progress || this.flag.loader.history;
    },
    validSearchCondition() {
      // 受付期間
      // 片一方だけの入力は不可
      if ((this.searchCondition.receiveStartDate && !this.searchCondition.receiveEndDate) || (!this.searchCondition.receiveStartDate && this.searchCondition.receiveEndDate)) {
        return false;
      }
      // 不正期間は不可
      if (this.searchCondition.receiveStartDate && this.searchCondition.receiveEndDate && !(this.fitlyReceivePeriod)) {
        return false;
      }
      
      // 受付期間
      // 片一方だけの入力は不可
      if ((this.searchCondition.answerStartDate && !this.searchCondition.answerEndDate) || (!this.searchCondition.answerStartDate && this.searchCondition.answerEndDate)) {
        return false;
      }
      // 不正期間は不可
      if (this.searchCondition.answerStartDate && this.searchCondition.answerEndDate && !(this.fitlyAnswerPeriod)) {
        return false;
      }

      return true;
    },
  },
  watch: {
    // クエリでidが渡ってきたとき（ホームのステータスから飛んだとき）
    // 該当テキスト健康相談までスクロール
    $route(to) {
      const query = this.$route.query;
      if (query.page) {
        this.page = Number(query.page);
      } else if (query.tab === 'text') { // queryにpageがなければ追加してreplace
        this.$router.replace('/operator/?tab=text&page=1');
        return;
      }

      // ケースに応じて検索を実行する
      if (this.flag.search.onChangeCondition) {
        // 検索条件が変わっている場合はすべて検索し直し
        this.getTypeTexts();

      } else if (this.user.counseling.op.text.answered[this.page] && this.user.counseling.op.text.answered[this.page].length > 0) {
        // 取得済みページの再表示
        this.scroll2Top();

      } else if (this.page > 1
        && (
          !this.user.counseling.op.text.answered[this.page]
          || (this.user.counseling.op.text.answered[this.page]
            && !this.user.counseling.op.text.answered[this.page].length)
        )) {
        // 実施済みの指定ページにデータがなければ取得
        this.getTypeTexts('answered'); // 2ページ目以降では「回答済み」のみを取得する

      } else {
        // ここまでのケースに該当しない場合は全取得
        this.getTypeTexts();
      }

      if (to.query.target) {
        const id = Number(to.query.target);
        this.$nextTick(() => {
          const elem = document.getElementById(`text_${id}`);
          const position = elem.getBoundingClientRect().top;
          scrollBy(0, position - 77);
        });
      }
    },
  },
  methods: {
    /**
     * テキスト健康相談データをタイプごとに取得
     */
    async getTypeTexts(checkType = null) {
      // 多重実行防止
      if (this.flag.loader.history || this.flag.loader.progress) {
        return;
      }

      // 検索対象の決定
      const ALL_TYPES = ['answered', 'draft', 'progress'];
      const types = checkType ? [checkType] : ALL_TYPES; // 指定がなければ全種類を対象として取得する

      // ローディング開始
      if (types.includes('answered')) {
        this.flag.loader.history = true;
      }
      if (types.includes('draft') || types.includes('progress')) {
        this.flag.loader.progress = true;
        this.progress = []; // 「回答待ち(下書きも含む)」を取得する場合、取得済みの対象情報をクリアする
      }

      // 全取得の場合は蓄積情報をクリアする（検索結果をあらためて蓄積する）
      if (ALL_TYPES.length === types.length) {
        this.$store.commit('user/counseling/clearOperatorTextDatas');
      }

      // 検索対象ごとに取得処理を実行する
      types.forEach((type) => {
        const limit = type === 'answered' ? this.limit : 999999;
        const params = {
          orderBy: type === 'answered' ? 'desc' : 'asc',
          includeDraft: type === 'draft' ? 1 : 0,
          target_type: 1,
          isRisk: 1,
          page: this.page,
          limit,
          type,
        };

        // 各種検索条件の適用
        const isSearchAnswered = type === 'answered'; // 回答済み検索か
        let isNoData = false; // 検索結果を0件として判断して良いか
        // 期間指定（受付期間） - 共通検索項目
        if (this.searchCondition.receiveStartDate && this.searchCondition.receiveEndDate) {
          params.between = [this.searchCondition.receiveStartDate, this.searchCondition.receiveEndDate];
        }
        // 期間指定（回答期間）
        if (this.searchCondition.answerStartDate && this.searchCondition.answerEndDate) {
          if (isSearchAnswered) {
            params.reply_between = [this.searchCondition.answerStartDate, this.searchCondition.answerEndDate];
          } else {
            isNoData = true;            
          }
        }
        // テストアカウントを除く - 共通検索項目
        if (this.searchCondition.flag.excludeTest) {
          params.excludeTest = true;
        }
        // 学校指定 - 共通検索項目
        if (this.searchCondition.targetSchool) {
          params.school_id = this.searchCondition.targetSchool;
        }
        // 心理士指定
        if (this.searchCondition.targetCounselor) {
          if (isSearchAnswered) {
            params.reply_counselor_id = this.searchCondition.targetCounselor;
          } else {
            isNoData = true;            
          }
        }

        // 「回答待ち(下書きも含む)」の結果を0件に設定する
        if (isNoData) {
          this.progress = [];
          this.sortProgress();
          this.flag.loader.progress = false;
          return;
        }

        this.axios({
          method: 'GET',
          url: '/v2/messageGroup/get/typeList',
          params,
        })
          .then(async (response) => {
            if (type === 'draft' || type === 'progress') {
              // 受付中と下書きをまとめる
              await response.data.groups.data.forEach((row) => {
                this.progress.push({
                  date: row.messages[0].updated_at,
                  type: 'text',
                  content: row,
                });
              });
              this.sortProgress();
            } else if (type === 'answered') {
              this.answeredTotal = response.data.groups.total; // 回答済み総件数
              this.pageMax = response.data.groups.lastPage;
              const histories = [];
              response.data.groups.data.forEach((row) => {
                if (row.messages.length >= 2) { // 回答されているので2以上にはなるはずだが
                  histories.push({
                    date: row.messages[1].updated_at,
                    type: 'text',
                    content: row,
                  });
                }
              });
              const commitData = {
                type: 'text',
                division: 'answered',
                page: this.page,
                values: histories,
                options: {
                  name: 'answeredOptions',
                  items: [
                    { key: 'pageMax', value: this.pageMax },
                  ],
                },
              };
              this.$store.commit('user/counseling/putOperatorDatas', commitData);
            }
          })
          .catch((error) => {
            if (error.message) console.log(error.message);
            else console.log(error);
          })
          .finally(() => {
            // ローディング終了
            if (type === 'answered') this.flag.loader.history = false;
            else if (type === 'progress') this.flag.loader.progress = false;
          });
      });

      // 次の検索条件変更まではフラグを寝かせる
      this.flag.search.onChangeCondition = false;
    },

    // 未回答、下書きを回答期限が近いものでソート
    sortProgress() {
      this.progress.sort((a, b) => moment(a.date).diff(moment(b.date)));
      // storeへ登録
      const commitData = {
        type: 'text',
        division: 'progress',
        values: this.progress,
      };
      this.$store.commit('user/counseling/putOperatorDatas', commitData);
    },

    scroll2Top() {
      // 上までスクロール
      const elem = document.getElementById('scroll-top');
      const position = elem.getBoundingClientRect().top;
      scrollBy(0, position - 77);
    },

    /** 受付期間の始点と終点取得 */
    setReceiveDate(args) {
      const propKeys = args.name.split('_');
      if (args.date === 'Invalid date') this[propKeys[0]][propKeys[1]] = null;
      else this[propKeys[0]][propKeys[1]] = args.date;
      if (this.searchCondition.receiveStartDate && !this.searchCondition.receiveEndDate) this.searchCondition.receiveEndDate = moment(new Date()).format('YYYY-MM-DD');
    },
    /** 回答期間の始点と終点取得 */
    setAnswerDate(args) {
      const propKeys = args.name.split('_');
      if (args.date === 'Invalid date') this[propKeys[0]][propKeys[1]] = null;
      else this[propKeys[0]][propKeys[1]] = args.date;
      if (this.searchCondition.answerStartDate && !this.searchCondition.answerEndDate) this.searchCondition.answerEndDate = moment(new Date()).format('YYYY-MM-DD');
    },
    /** 学校の選択 */
    changeSchool(e) {
      this.searchCondition.targetSchool = Number(e.target.value);
      if (!Number(e.target.value)) this.searchCondition.targetSchool = null;
      // 有意な学校を指定した時、テストカウント除外チェックは意味をなさないのでチェックを外す
      if (this.searchCondition.targetSchool) {
        this.searchCondition.flag.excludeTest = false;
      }
    },
    /** 心理士の選択 */
    changeCounselor(e) {
      this.searchCondition.targetCounselor = Number(e.target.value);
      if (!Number(e.target.value)) this.searchCondition.targetCounselor = null;
    },

    /** 学校情報を取得 */
    getSchools() {
      // 取得済みの場合は再取得しない
      if (this.searchCondition.schools && this.searchCondition.schools.length > 0) {
        return;
      }
      this.axios({
        method: 'GET',
        url: '/v1/school/get/list',
        params: {
          isUsers: 0,
          isAddress: 0,
        },
      })
        .then((response) => {
          const res = response.data.data;
          this.searchCondition.schools = res.data;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    /** 心理士の情報を取得（医師は対象外） */
    getCounselor() {
      // 取得済みの場合は再取得しない
      if (this.searchCondition.counselors && this.searchCondition.counselors.length > 0) {
        return;
      }
      const params = {
        accountTypes: [22], // 心理士のみ
        order: 'kana',
        isAddress: 0,
        isSchool: 0,
        isReadflags: 0,
      };
      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.searchCondition.counselors = res.users.data;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /** 検索 */
    clickSearch() {
      this.flag.search.isSearching = true;
      this.flag.search.onChangeCondition = true; // 画面表示情報の条件が変わった
      if (this.page === 1) {
        this.getTypeTexts();
      } else {
        // クエリを変更することで検索を実行する
        const query = { ...this.$route.query };
        query.page = 1;
        this.$router.push({ query });
      }
    },
    /** 検索解除 */
    clickClear() {
      // 検索条件全クリア
      this.searchCondition.receiveStartDate = null;
      this.searchCondition.receiveEndDate = null;
      this.searchCondition.answerStartDate = null;
      this.searchCondition.answerEndDate = null;
      this.searchCondition.flag.excludeTest = false;
      this.searchCondition.targetSchool = 0;
      this.searchCondition.targetCounselor = 0;
      // 現在が絞り込み状態の場合は、検索を解除する
      if (this.flag.search.isSearching) {
        // 全データ再表示
        this.flag.search.isSearching = false;
        this.flag.search.onChangeCondition = true; // 画面表示情報の条件が変わった
        if (this.page === 1) {
          this.getTypeTexts();
        } else {
          // クエリを変更することで検索を実行する
          const query = { ...this.$route.query };
          query.page = 1;
          this.$router.push({ query });
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
.history_search {
  &_title {
    font-size: 1.1em;
    font-weight: bold;
  }
}

.date {
  flex: 1;
  dl {
    display: flex;
    align-items: center;
    margin: 0;
  }
  dt {
    width: 9em;
    i {
      margin-right: .5em;
    }
  }
  dd {
    flex: 1;
    display: flex;
    margin-left: 2em;
    > div {
      display: flex;
      align-items: center;
      &:nth-child(2) {
        margin-left: 1em;
        &:before {
          content: "~";
          margin-right: 1em;
        }
      }
    }
  }
  @include sm-view {
    dl {
      display: block;
      margin: 1em 0 .4em;
    }
    dt {
      font-size: 14px;
    }
    dd {
      margin: 0;
    }
  }
}

.alert_text {
  font-size: .8em;
  color: red;
}

.select {
  display: flex;
  > dt {
    width: 9em;
  }
  > dd {
    margin-inline-start: 0;
    align-items: center;
  }
  &_box {
    width: 300px;
    padding: 13px;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
    border-radius: 8px;
    appearance: none;
    margin-left: 2em;

    &_wrap {
      display: flex;

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}

.checkBox {
  margin-left: 2em;
}

.button_area {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bold {
  font-weight: bold;
  font-size: 1.5em;
}
</style>

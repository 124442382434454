<template>
  <div>
    <spacer :y="5"/>
      <contents-box
        title="検索">
        <ul :class="$style.select">
          <li v-for="utype in userTypeItems" :key="utype">
            <input
              type="checkbox"
              :id="utype.name"
              :value="utype.value"
              v-on:change="updateType">
            <label :for="utype.name">{{ utype.label }}</label>
            <GuideIcon
              icon="fa-regular fa-circle-question"
              boxSize="730"
              hoverText="チェックを入れると、最新のパワーメータがココロラインを下回るアカウントを抽出することができます。" />
          </li>
        </ul>
      </contents-box>
    <spacer :y="5"/>

    <hr id="scroll-top">
    <spacer :y="5"/>
    <ul>
      <loader-simple :flag="flag.loader">
        <div v-show="!flag.loader">
          <stack-item v-for="row in users" :key="row.id">
            <user-box
              :showId="true"
              :showCocomoniBtn="true"
              :ownUser="user"
              v-if="users.length"
              :user="row"
              :bg="userBoxColor(row)"
              :link="`/teacher/history/${row.rawId || row.id}`"
              :targetBlank="true">
              <template v-slot:btn>
                <basic-btn
                  type="bdr"
                  tag="button"
                  size="sm"
                  v-on:click="changeUserStatus(row)">非アクティブ</basic-btn>
              </template>
              <template v-slot:main>
                <spacer :y="2"/>
                <div>
                  <ul :class="$style.items">
                    <li v-for="item in items" :key="item">
                      <div :class="$style.item" v-if="item.constant || openIds.includes(row.id)">
                        <i :class="[item.icon, $style.icon]" v-if="item.constant || openIds.includes(row.id)"/>
                        <div>
                          <p
                            v-if="item.name === 'birthday' && row[item.name]"
                            :class="$style.text">{{ formatTimestamp(row[item.name], 'YYYY/MM/DD') }}</p>
                          <p
                            v-else-if="item.name === 'student_id' && row.student && row.student.student_id"
                            :class="$style.text">{{ row.student.student_id }}</p>
                          <p
                            v-else-if="row[item.name]"
                            :class="$style.text"
                            v-html="row[item.name]"></p>
                          <p
                            v-else
                            :class="$style.text">{{ isCompany && item.clabel ? item.clabel : item.label }}登録なし</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div v-if="openIds.includes(row.id)">
                    <spacer :y="4"/>
                    <router-link
                      :class="$style.history"
                      :to="`/teacher/history/${row.id}`">健康相談履歴</router-link>
                  </div>
                </div>
                <spacer :y="2"/>
                <div :class="$style.open" v-on:click="changeOpenIds(row.id)">
                  <p>
                    {{ !openIds.includes(row.id) ? '詳細情報表示' : '詳細情報非表示' }}
                    <i :class="`fa-regular fa-angles-${!openIds.includes(row.id) ? 'down' : 'up'}`"/>
                  </p>
                </div>
              </template>
            </user-box>
          </stack-item>
          <spacer :y="4"/>
          <!-- <pagination
            :page="page"
            :pageMax="pageMax"
            :path="'/teacher/'"
            v-if="users.length && pageMax !== 1" /> -->
          <p v-if="!users.length && (keyword || (startBirthday && endBirthday) || targetUserType.length)">お探しの検索条件に該当するアカウントは見つかりませんでした。</p>
        </div>
      </loader-simple>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StackItem from '@/views/components/StackItem.vue';
import UserBox from '@/views/components/UserBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
// import Pagination from '@/views/components/Pagination.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import GuideIcon from '@/views/components/GuideIcon.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'students',
  mixins: [cf],
  components: {
    StackItem,
    UserBox,
    Spacer,
    BasicBtn,
    // Pagination,
    LoaderSimple,
    ContentsBox,
    GuideIcon,
  },
  data() {
    return {
      flag: {
        loader: true,
      },
      openIds: [],
      onlineOpenIds: [],
      users: [],
      keyword: null,
      targetColumn: [],
      startBirthday: null, // 文字列で渡す
      endBirthday: null, // 文字列で渡す
      page: 1,
      pageMax: 1,
      limit: 99999,
      cocomoniUsers: [],
      items: [
        // {
        //   label: 'ステータス',
        //   name: 'status',
        // },
        {
          label: 'メール',
          name: 'email',
          icon: 'fa-regular fa-envelope',
          constant: true,
        },
        {
          label: '誕生日',
          name: 'birthday',
          icon: 'fa-regular fa-cake-candles',
          constant: true,
        },
        {
          label: '携帯番号',
          name: 'tel',
          icon: 'fa-solid fa-phone',
          constant: false,
        },
        {
          label: '学籍番号',
          clabel: '特記事項',
          name: 'student_id',
          icon: 'fa-regular fa-id-badge',
          constant: false,
        },
        {
          label: 'ひとこと',
          name: 'comment',
          icon: 'fa-regular fa-comment-dots',
          constant: false,
        },
        // {
        //   label: '予約',
        //   name: 'reserve',
        //   icon: 'fa-regular fa-user-doctor',
        //   constant: false,
        // },
      ],
      userTypeItems: [
        {
          name: 'alert',
          label: 'ココロアラート',
          value: 1,
          checked: false,
        },
      ],
      cocoroLine: null,
    };
  },
  created() {
    this.cocoroLine = this.helper.master.datas.cocoroLine[this.helper.env.name];
    if (this.user.email) {
      this.getUsers(0);
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.getUsers(0);
        }
      });
    }
  },
  watch: {
    $route() {
      const query = this.$route.query;
      if (query.page) {
        this.page = Number(query.page);
      }
      this.getUsers();
      this.scroll2Top();
    },
  },
  computed: {
    ...mapState(['user', 'helper']),
    targetUserType() {
      let array = [];
      let all = true;
      this.userTypeItems.forEach((row) => {
        if (row.checked) {
          all = false;
          array.push(row.value);
        }
      });
      if (all) array = [0, 1];
      return array;
    },
    isCompany() {
      return Boolean(this.user.school[0].is_company);
    },
  },
  methods: {
    // 子コンポーネントから受け取ったデータを格納
    receiveValue(data) {
      const userIdRegex = new RegExp(/^[0-9０-９]{4}(-|ー|−|―|‐|-|ｰ)[0-9０-９]{1,}(-|ー|−|―|‐|-|ｰ)[0-9０-９]{4}$/);
      if (userIdRegex.test(data.value)) {
        const userId = data.value.split(/[-|ー|−|―|‐|-|ｰ]/);
        this.keyword = String(Number(this.toHalfNumber(userId[2]))); // user.id指定
        this.targetColumn = ['id', 'old_id', 'tel']; // 0120-12-3456のtelも引っかかる
      } else {
        this.keyword = data.value;
        this.targetColumn = ['username', 'kana', 'email', 'tel', 'id', 'old_id'];
      }
      this.getUsers();
    },

    toHalfNumber(str) {
      return str.replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
    },

    /** チェックボックスで絞り込み */
    updateType(e) {
      this.userTypeItems.forEach((row) => {
        if (e.target.id === row.name) row.checked = e.target.checked;
      });
      this.getUsers();
    },

    getUsers() {
      this.cocomoniUsers = this.user.cocomoni; // 過去に音声感情センシングを利用したことのあるユーザー一覧
      const cocomoniUserIds = Object.keys(this.cocomoniUsers);
      const params = {
        school_id: this.user.school[0].id, // 学校の絞り込みは必須
        targetColumn: this.keyword && this.keyword !== '' ? this.targetColumn : null,
        keyword: this.keyword && this.keyword !== '' ? this.keyword : null,
        accountTypes: this.targetUserType,
        betweenBirthday: [this.startBirthday, this.endBirthday],
        page: this.page,
        limit: this.limit,
        ids: cocomoniUserIds,
        isAddress: 0,
        isSchool: 0,
        isRisk: 1,
      };

      this.axios({
        method: 'GET',
        url: '/v1/user/get/search',
        params,
      })
        .then((response) => {
          const res = response.data;
          const users = [];
          res.users.data.forEach((elem) => {
            // 音声感情センシングを過去に利用しているユーザーのみを配列に追加
            if (elem.id in this.cocomoniUsers) {
              if (this.cocoroLine > this.cocomoniUsers[elem.id]) elem.alert = 1; // ココロラインを下回っていたらalertを1にする
              else elem.alert = 0;

              if (this.targetUserType.includes(elem.alert)) {
                users.push(elem);
              }
            }
          });

          this.users = users;
          this.pageMax = res.users.lastPage;
          this.flag.loader = false;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    changeOpenIds(n) {
      if (this.openIds.includes(n)) {
        const idx = this.openIds.indexOf(n);
        this.openIds.splice(idx, 1);
      } else {
        this.openIds.push(n);
      }
    },
    changeUserStatus(user) {
      const data = user;
      const args = {
        modalName: 'confirm-change-status',
        data,
      };
      this.$store.dispatch('modal/messages/showModal', args, { root: true });
    },
    scroll2Top() {
      // 上までスクロール
      const elem = document.getElementById('scroll-top');
      const position = elem.getBoundingClientRect().top;
      scrollBy(0, position - 77);
    },

    /** userBox背景色指定用 */
    userBoxColor(user) {
      let color;
      if (user.temporary) {
        color = 'silver';
      } else if (user.risk.length && user.risk[user.risk.length - 1].type !== 11) {
        color = 'red';
      } else {
        color = 'white';
      }
      return color;
    },

    /** 誕生日の始点と終点取得 */
    receiveDate(args) {
      if (args.date === 'Invalid date') this[args.name] = null;
      else this[args.name] = args.date;
      this.getUsers();
    },
  },
};
</script>

<style lang="scss" module>
.search {
  &_wrap {
    display: flex;
    width: 50%;
  }
  &_word {
    flex: 1;
    margin-right: 20px;
  }
  &_items {
    display: flex;
    align-items: center;
    select {
      border: none;
      outline: none;
      padding: 13px;
      border-radius: 8px;
      background-color: var(--gray-sub);
      margin-right: 10px;
    }
    .checklabel {
      // font-size: 14px;
      margin-left: 5px;
    }
  }
  &_box {
    padding: 20px;
  }
}

.icon {
  color: var(--orange-main);
  margin-right: 8px;
  font-size: 20px;
  display: inline-block;
  width: 20px;
}
.checkbox_label {
  position: relative;
}
.annotation {
  position: absolute;
  top: -.1em;
  right: -1.5em;
  font-size: 1.15em;
  cursor: pointer;
}
.hover_text { // 幅はテンプレートで文字列バインドで動的に決定
  position: absolute;
  top: -2.5em;
  padding: 5px 1.5em;
  text-align: center;
  background-color: white;
  border: 1.5px #DDDDDD solid;
  border-radius: 5px;
  box-shadow: 2px 2px 5px silver;
}
.items {
  // font-size: 14px;
  margin-top: -16px;
  .item {
    display: flex;
    align-items: flex-start;
    margin-top: 16px;
    line-height: 20px;
  }
}
.open {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray-side);
  font-size: 14px;
  cursor: pointer;

  &:hover {
    opacity: .7;
  }
}
.history {
  font-size: 14px;
  // background-color: var(--orange-main);
  // color: #fff;
  font-weight: bold;
  color: var(--orange-main);
  border: 1px solid var(--orange-main);
  padding: 8px 16px;
  border-radius: 24px;
}
.pagenation {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray-side);
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    margin: 0 10px;
    border-radius: 50%;
    border: 1px solid var(--orange-main);
    color: var(--orange-main);
    cursor: pointer;
    &.selected {
      background-color: var(--orange-main);
      color: #fff;
    }
    span {
      cursor: pointer;
    }
  }
  i {
    margin: 0 15px;
    font-size: 30px;
    color: var(--orange-main);
    cursor: pointer;
    &.invalid {
      color: var(--gray-main);
      cursor: initial;
    }
  }
}
.birth {
  flex: 1;
  dl {
    display: flex;
    align-items: center;
    margin: 0;
  }
  dt {
    width: 6em;
    i {
      margin-right: .5em;
    }
  }
  dd {
    flex: 1;
    display: flex;
    margin-left: 1em;
    > div {
      display: flex;
      align-items: center;
      &:nth-child(2) {
        margin-left: 1em;
        &:before {
          content: "~";
          margin-right: 1em;
        }
      }
    }
  }
  @include sm-view {
    dl {
      display: block;
      margin: 1em 0 .4em;
    }
    dt {
      font-size: 14px;
    }
    dd {
      margin: 0;
    }
  }
}
.select {
  display: flex;
  > li {
    &:not(:first-child) {
      margin-left: 1em;
    }
  }
}
label {
  margin-right: .5em;
}
</style>

<template>
  <div>
    <v-date-picker
      ref="calendar"
      :attributes="attrs"
      :select-attribute="selectAttribute"
      :model-config="modelConfig"
      :min-date="minDate"
      :max-date="maxDate"
      :disabled-dates="disabledDates"
      :initial-date="initialDate"
      :masks="{
        title: 'YYYY年 MM月',
        dayPopover: 'YYYY年MM月DD日',
      }"
      is-expanded
      v-model="date"
      />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import cf from '@/mixins/commonFunctions.js';

export default {
  mixins: [cf],
  props: {
    schedule: {
      type: Object,
    },
    information: {
      type: Boolean,
      default: false,
    },
    selectableSunSat: { // 土日を平日と同様に扱うか
      type: Boolean,
      default: false,
    },
    selectableHoliday: { // 祝日を選択可能にするか
      type: Boolean,
      default: false,
    },
    initialDate: { // カレンダーの初期選択日
      type: String,
      default: null,
    },
    selectOnlineDate: { // ３営業日後から選択可能、19日までは当月まで20日以降は翌月末まで有効
      type: Boolean,
      default: false,
    },
    registSchedule: { // ３営業日から選択可能
      type: Boolean,
      default: false,
    },
    selectableDates: { // 選択可にしたい日付（'YYYY-MM-DD'形式の日付文字列配列）
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      date: null,
      attrs: [],
      selectAttribute: {
        highlight: {
          style: {
            'background-color': '#e7a223',
          },
        },
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      // endDateOfNextMonth: moment().add(1, 'months').endOf('month').toDate(), // 翌月末日
    };
  },
  created() {
    if (this.schedule) {
      this.schedule.forEach((row) => {
        const data = {
          key: row.id,
          highlight: {
            style: {
              'background-color': row.color || 'var(--orange-main)',
            },
          },
          popover: row.label ? {
            label: row.label,
            visibility: 'hover',
            hideIndicator: true,
          } : null,
          dates: new Date(row.date),
        };
        this.attrs.push(data);
      });
    }
    // 祝日表示対応
    if (!this.selectableHoliday) {
      this.holiday.list.forEach((row) => {
        const data = {
          key: row.date,
          popover: {
            label: row.label,
            visibility: 'hover',
            hideIndicator: true,
          },
          dates: new Date(row.date),
        };
        this.attrs.push(data);
      });
    }
    if (this.initialDate) {
      this.date = this.initialDate;
    }
  },
  mounted() {
    if (this.initialDate) {
      this.$refs.calendar.move(this.initialDate);
    }
  },
  computed: {
    ...mapState(['holiday']),
    minDate() {
      let result = null;
      if (!this.information) {
        result = new Date();
      }
      if (this.selectOnlineDate || this.registSchedule) {
        result = cf.calcSalesMinDate(this.holiday);
      }

      return result;
    },
    maxDate() {
      let result = null;
      if (!this.information) {
        result = moment().add(1, 'months').endOf('month').toDate(); // 翌月末日
      }
      // 19日までは当月末まで、20日以降は翌月末まで
      if (this.selectOnlineDate) {
        const today = moment(new Date());
        if (today.date() < 20) {
          result = today.endOf('month').toDate();
        } else {
          result = today.add(1, 'month').endOf('month').toDate();
        }
      }
      // 14日から翌月のシフト登録可能に
      if (this.registSchedule) {
        const today = moment(new Date());
        if (today.date() < 14) {
          result = today.endOf('month').toDate();
        } else {
          result = today.add(1, 'month').endOf('month').toDate();
        }
      }
      return result;
    },
    disabledDates() {
      const dates = [];

      // selectableDates が指定された場合は、その日付以外はすべて選択不可にする
      // ただし、この制御を有効にするのはカレンダーの開始/終了日が定義される場合のみ（カレンダーの年月移動に制限がない場合は selectableDates による制御はしない∵選択不可日付情報が大量に必要になってしまうから）
      if (this.minDate !== null && this.maxDate !== null && this.selectableDates && this.selectableDates.length > 0) {
        const currentDate = new Date(this.minDate); // 選択可能開始日
        while (currentDate <= this.maxDate) { // 表示対象の日付すべてでループ
          const currentDateStr = this.filterMoment(currentDate, 'YYYY-MM-DD');
          // 選択可能日ではない場合は不可日に追加する
          if (!this.selectableDates.find((selectableDate) => selectableDate === currentDateStr)) {
            dates.push({
              start: currentDateStr,
              end: currentDateStr,
            });
          }
          currentDate.setDate(currentDate.getDate() + 1); // 次の日にする
        }
        return dates;
      }

      // 祝日対応
      if (!this.selectableHoliday) {
        this.holiday.list.forEach((row) => {
          dates.push({
            start: row.date,
            end: row.date,
          });
        });
      }
      // 土日対応
      if (!this.selectableSunSat) {
        dates.push({ weekdays: [1, 7] });
      }
      return dates;
    },
  },
  watch: {
    date() {
      this.$emit('sendDate', this.date);
    },
  },
};
</script>

<style lang="scss" module>
</style>

<template>
  <loader-simple
    :flag="showLoading"
  >
    <not-found
      v-if="flag.notFound"
    />
    <risk-application-form
      v-if="flag.allowed"
      mode="refer"
      :base="riskApplication"
      :clientId="riskApplication.target_user_id"
      :counselingType="riskApplication.counseling_type"
      :foreignId="riskApplication.foreign_id"
      @risk-application-message-posted="initialize"
    />
  </loader-simple>
</template>

<script>
import { mapState } from 'vuex';
import RiskApplicationForm from '@/views/pages/Common/RiskApplicationForm.vue';
import NotFound from '@/views/404.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';

import raf from '@/mixins/riskApplicationFunctions';

/**
 * コンポーネントの責務
 * * 画面表示可否チェック
 * * 必要情報の取得と引き渡し
 *
 * Path Variable から次を解決する
 * * risk_application_id
 */
export default {
  name: 'risk-application-refer',
  mixins: [
    raf,
  ],
  components: {
    RiskApplicationForm,
    NotFound,
    LoaderSimple,
  },
  data() {
    return {
      flag: {
        notFound: false,
        allowed: false,
      },
      riskApplication: null,
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
    showLoading() {
      return !this.flag.notFound && !this.flag.allowed;
    },
  },
  async beforeCreate() {
    if (this.user && this.user.account_type) {
      this.initialize();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.initialize();
        }
      });
    }
  },
  methods: {
    async initialize() {
      // パラメータチェック
      const id = this.$route.params.risk_application_id;

      // バリデーションチェック

      // アクターチェック
      const allowedAccountTypes = [
        11, // 学校管理者
        12, // 学校副管理者
        21, // 医師
        22, // 心理士
        31, // 事務局
      ];
      if (!allowedAccountTypes.includes(this.user.account_type)) {
        // 上記アカウント以外には表示しない
        console.log('Not allowed to view this page.');
        this.flag.notFound = true;
        return false;
      }

      // 申請書の存在チェック
      const schoolAccountTypes = [
        11, // 学校管理者
        12, // 学校副管理者
      ];
      let response;
      try {
        response = await this.axios({
          method: 'GET',
          url: '/v1/riskApplications/get/detail', // TODO 404エラーの場合の対応
          params: {
            risk_application_id: id,
            school_id: schoolAccountTypes.includes(this.user.account_type)
              ? this.user.school[0].id // 学校管理者の場合は自校所属ユーザの報告書のみが対象
              : null,
            with_messages: [11, 12, 31].includes(this.user.account_type) ? 1 : 0, // 学校管理者または事務局
          },
        });
      } catch (e) {
        console.error(e);
        alert('対象の報告書は表示できません。');
        throw e;
      }
      if (!response.data.data) {
        console.log(`There is no risk application. id: ${id}`);
        this.flag.notFound = true;
        return false;
      }
      const data = response.data.data;

      // 申請書のステータスチェック
      const STATUS = this.helper.master.labels.riskApplication.status;
      const notAllowedStatuses = [ // 参照画面の表示を禁じるステータス
        STATUS.APPLYER_DRAFTED, // 申請者下書き
      ];
      if (notAllowedStatuses.includes(data.status)) {
        console.log(`The risk application is not allowed to review. id: ${id}, status: ${data.status}`);
        this.flag.notFound = true;
        return false;
      }

      // フォームを表示する
      this.riskApplication = data;
      this.flag.allowed = true;
    },
  },
};
</script>

<style lang="scss" module>
</style>

<template>
  <div>
    <p>個人の詳細アナリティクスデータ</p>
    <div :class="$style.graph_size">
      <pie-graph
        :labelsData="labels"
        :datasetsData="datasets"
      />
  </div>
  </div>
</template>
<!-- 値を渡すと円グラフを描画するようなコンポーネントを作る -->
<script>
import PieGraph from '@/views/components/PieGraph.vue';

export default {
  name: 'OperatorPersonalAnalyticsSertch',
  components: {
    PieGraph,
  },
  data() {
    return {
      userDetail: null,
      labels: ['data1', 'data2', 'data3'],
      datasets: {
        label: 'Pie Chart',
        data: [100, 200, 300],
        backgroundColor: ['red', 'blue', 'green'],
      },
    };
  },
  created() {
    const path = this.$route.path;
    console.log(path.slice(34));
    this.getUserData();
  },
  methods: {
    getUserData() {
      const userid = this.$route.path.slice(34);
      const params = {
        id: userid,
      };
      this.axios({
        method: 'get',
        url: '/v1/user/get/detail',
        params,
      })
        .then((response) => {
          this.userDetail = response.data.data;
          console.log(this.userDetail);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module>
.graph_size {
  width: 50%;
}
</style>

<template>
  <div>
    <div :class="$style.top">
      <i
        :class="$style.top_icon"
        class="fa-regular fa-comment-lines"/>
      <div :class="$style.top_text"><span :class="$style.nowrap">テキスト健康相談詳細</span></div>
    </div>

    <spacer :y="5"/>
    <contents-box :class="$style.info" v-if="data">
      <data-grid>
        <data-row no-border>
          <data-term width="quater">
            <label-box label="お悩み" size="sm" color="gray" />
          </data-term>
          <data-detail>
            <div :class="$style.flex">
              <div
                :class="$style.item"
                v-for="typeNum in data.type"
                :key="typeNum">{{ helper.master.labels.texts.type[typeNum] }}<span v-if="data.other_text && typeNum === 6">（{{ data.other_text }}）</span></div>
            </div>
          </data-detail>
        </data-row>

        <data-row no-border>
          <data-term width="quater">
            <label-box label="ココモニ" size="sm" color="gray"></label-box>
          </data-term>
          <data-detail :space="1">
            <stack-item
              v-for="(item, i) in helper.master.who5.items"
              :key="item.label"
              :class="$style.who5item"
              :space="1">
              <div :class="$style.who5itemQ">Q{{ i + 1 }}. {{ item.label }}</div>
              <div :class="$style.who5itemA">{{ helper.master.who5.labels[data.who5Answer.answers[i]] }}</div>
            </stack-item>
          </data-detail>
        </data-row>
      </data-grid>
    </contents-box>

    <spacer :y="6"/>
    <user-box
      :showId="false"
      v-if="data && data.done"
      :user="data.messages[1].user">

      <template v-slot:main>
        <spacer :y="1" />
        <div>
          <p>{{ data.messages[1].user.comment }}</p>
        </div>
      </template>
    </user-box>

    <spacer :y="6"/>
    <contents-box v-if="data" :title="data.done ? '回答済み' : '回答待ち'">
      <ul>
        <stack-item
          :class="`${$style.comment} ${$style.q}`"
          v-if="data.messages.length">
          <div :class="$style.question">
            <div :class="$style.name">{{ data.messages[0].user.username }} [{{ filterMoment(data.messages[0].updated_at, 'YYYY.MM.DD HH:mm') }}]</div>
            <spacer :y="1"/>
            <div class="n2br">
              <p v-html="data.messages[0].message"></p>
            </div>
          </div>
        </stack-item>

        <stack-item
          :class="`${$style.comment} ${$style.a}`"
          v-if="data.done">
          <div :class="$style.answer">
            <div :class="$style.name">{{ data.messages[1].user.username }} [{{ filterMoment(data.messages[1].updated_at, 'YYYY.MM.DD HH:mm') }}]</div>
            <spacer :y="1"/>
            <div class="n2br">
              <p v-html="data.messages[1].message"></p>
            </div>
          </div>
        </stack-item>
      </ul>
    </contents-box>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ContentsBox from '@/views/components/ContentsBox.vue';
import UserBox from '@/views/components/UserBox.vue';
import LabelBox from '@/views/components/LabelBox';
import StackItem from '@/views/components/StackItem.vue';
import Spacer from '@/views/components/Spacer.vue';
import {
  DataGrid, DataRow, DataTerm, DataDetail,
} from '@/views/components/DataGrid';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'reserve-detail-online',
  mixins: [cf],
  components: {
    ContentsBox,
    UserBox,
    LabelBox,
    StackItem,
    Spacer,
    DataGrid,
    DataRow,
    DataTerm,
    DataDetail,
  },
  data() {
    return {
      id: null,
      data: null,
    };
  },
  created() {
    this.id = cf.convert2Num(this.$route.params.slug);
    this.getDetail();
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  methods: {
    getDetail() {
      this.axios({
        method: 'GET',
        url: '/v1/messagegroup/get/detail',
        params: {
          id: this.id,
        },
      })
        .then((response) => {
          this.data = response.data.detail;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module>
.top {
  background-color: var(--blue-main);
  display: flex;
  justify-content: center;
  padding: 90px;
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  margin: 0 calc((100vw - 100%) / (-2));
  position: relative;

  &_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .2;
    font-size: 130px;

    &_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -70%);
      font-size: 50px;
    }
  }

  &_text {
    text-align: center;
    .nowrap {
      white-space: nowrap;
    }
    @include sm-view {
      font-size: 30px;
    }
    @include xs-view {
      font-size: 20px;
    }
  }
}
.info {
  font-size: 14px;
}
.smlabel {
  width: 150px;
}
.flex {
  display: flex;
  align-items: flex-start;

  &.center {
    align-items: center;
  }

  &.check {
    justify-content: space-between;
  }
}
.content {
  flex: 1;
}

.bold {
  font-weight: bold;
}
.label {
  @include sm-view {
    width: 180px;
  }
}
.item {
  &:not(:first-child) {
    margin-left: 10px;
  }
}
.space {
  margin-top: 8px;
  @include sm-view {
    margin-top: 0;
  }
}

.who5item {
  display: flex;
  justify-content: space-between;
  font-size: 12px;

  @include sm-view {
    flex-direction: column;
  }
}

.who5itemA {
  padding-left: var(--spacer-base);
  font-weight: bold;
  color: #444;
}

.comment {
  &:not(:first-child) {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -24px;
      left: 30px;
      width: 3px;
      height: 24px;
    }

    &.q {
      &::before {
        background-color: var(--blue-main);
      }
    }
    &.a {
      &::before {
        background-color: var(--orange-main);
      }
    }
  }
  .name {
    font-size: 12px;
  }
}

.question, .answer {
  padding: 15px;
  border-radius: 8px;
}

.question {
  background-color: rgba(var(--blue-main-rgb), 0.2);
}
.answer {
  background-color: rgba(var(--orange-main-rgb), .2);
}
</style>

<template>
  <div>
    <spacer :y="5"/>
    <contents-box title="メールアドレス更新">
     <form
        class="form vertical"
        v-on:submit="submit"
        autocomplete="off">
          <div :class="$style.input">
            <div class="messages mt-4">
              <p
                :class="$style.message"
                v-if="!flg.success
                  && !flg.userNotFound
                  && !flg.duplicate
                  && !flg.error">更新したいメールアドレスを入力して送信してください。</p>
              <p
                v-if="flg.success"
                :class="$style.message">更新するメールアドレス宛に確認メールを送信しました。<br>現在は更新完了していません。<br>メールに記載されたURLからアクセスし、メールアドレスの更新を完了してください。</p>
              <p
                v-if="flg.userNotFound"
                :class="$style.message">ユーザが見つかりませんでした。</p>
              <p
                v-if="flg.duplicate"
                :class="$style.message">送信いただいた更新メールアドレスはすでに別アカウントで登録済みです。<br>メールアドレスの重複登録はできません。<br>別のメールアドレスでお試しください。</p>
              <p
                v-if="flg.error"
                :class="$style.message">メールアドレスの更新に失敗しました。お手数ですがリロードして再度お試しください。</p>
            </div>
            <spacer :y="2"/>
            <input
              id="mail"
              type="email"
              name="mail"
              v-model.trim="$v.mail.$model"
              v-bind:class="{ input_error: validationFlags.mail }"
              v-on:input="invalidsCheck('mail', $v.mail.$invalid)"
              >
          </div>
          <p
            :class="$style.comment"
            v-if="$v.mail.$dirty
              && $v.mail.required.$invalid">メールアドレスを入力してください</p>
          <p
            :class="$style.comment"
            v-if="$v.mail.$dirty
              && $v.mail.email.$invalid">正しいメールアドレスの形式で入力してください</p>

          <spacer :y="4"/>
          <div :class="$style.flex">
            <button
              :class="$style.btn"
              type="submit"
              v-bind:disabled="!submitFlag">メールアドレス更新</button>
            <spacer :x="2"/>
            <!-- <router-link
              :class="`${$style.btn} ${$style.bdr}`"
              to="/settings/"
              class="btn btn__tertiary sm">戻る</router-link> -->
            <router-link
              :class="`${$style.btn} ${$style.bdr}`"
              :to="user.account_type === 1?`/settings/`:`/counselor/account/`"
              class="btn btn__tertiary sm">戻る</router-link>
          </div>
      </form>
    </contents-box>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'edit-email',
  mixins: [cf],
  components: {
    ContentsBox,
    Spacer,
  },
  data() {
    return {
      flg: {
        success: false,
        userNotFound: false,
        duplicate: false,
        error: false,
      },
      validationFlags: {
        mail: false,
      },
      invalids: {
        mail: true,
      },
    };
  },
  created() {
  },
  setup() {
    const mail = ref('');

    const rules = {
      // 必須・email形式
      mail: { required, email },
    };

    const $v = useVuelidate(rules, { mail });
    return { mail, $v };
  },
  computed: {
    ...mapState(['user', 'page']),
    submitFlag() {
      return !this.invalids.mail && !this.flg.success;
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();
      this.showLoading();
      // 初期化
      this.flg.success = false;
      this.flg.userNotFound = false;
      this.flg.duplicate = false;
      this.flg.error = false;

      const data = {
        id: this.user.id,
        email: this.mail,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/updateEmail',
        data,
      })
        .then((response) => {
          const res = response.data;
          if (res.duplicate) this.flg.duplicate = true;
          if (res.notFound) this.flg.notFound = true;
          if (!res.duplicate && !res.notFound) this.flg.success = true;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.flg.error = true;
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module>
.flex {
  display: flex;
}
.input {
  margin-left: 0;
  input {
    width: 100%;
    padding: 13px;
    border-radius: 8px;
    border: none;
    background-color: var(--gray-sub);
    outline: none;
  }
}
.comment {
  font-size: 12px;
  color: var(--pink);
}
.message {
  font-size: 12px;
}
.btn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
  &.bdr {
    background-color: transparent;
    color: var(--black);
    border: 1px solid var(--black);
  }
}
</style>

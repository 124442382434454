<template>
  <div>
    <spacer :y="3"/>
    <p :class="$style.assistant">基本情報の変更ございましたら事務局までお知らせください。</p>
    <spacer :y="3"/>
    <div v-if="user.email">
      <div
        v-for="(type, i) in types"
        :key="type.title">
        <spacer :y="5" v-if="i !== 0" />
        <contents-box :title="type.title">
          <div :class="$style.wrapper">
            <div
              :class="$style.row"
              v-for="row in type.items"
              :key="row.label">
              <div :class="$style.label_wrap">
                <p :class="$style.label">{{ row.label }}</p>
              </div>
              <spacer :y="1" />

              <div
                class="n2br"
                :class="[$style.detail, row.type === 'textarea' ? $style.textarea : '' ]">
                <div v-if="row.name !== 'address'">
                  <p
                    :class="$style.plane"
                    v-if="row.type === 'accountType'
                      && helper.master
                      && helper.master.labels">{{ helper.master.labels.users.account_type[row.value] }}</p>

                  <input
                    v-if="row.type === 'input' && row.edit"
                    v-model="row.value"
                    type="text"
                    :class="$style.text">

                  <textarea
                    v-if="row.type === 'textarea' && row.edit"
                    v-model="row.value"
                    :class="$style.textarea"
                    rows="8"></textarea>

                  <p
                    class="n2br"
                    :class="$style.plane"
                    v-if="!row.edit && row.type !== 'accountType' && row.name !== 'password'"
                    v-html="row.value"/>
                  <p :class="$style.plane" v-if="row.name === 'password'">********</p>
                </div>

                <div v-else>
                <Address
                  :items="items"
                  :isConfirm="false"
                  v-on:sendValue="receiveValue"
                  ref="address"/>
                </div>


                <router-link
                  :class="$style.link"
                  :to="row.link"
                  v-if="row.link">変更する</router-link>
              </div>
            </div>

            <btn-container v-if="type.name === 'base'">
              <basic-btn
                size="sm"
                tag="button"
                @click="update(type.name)">更新</basic-btn>
            </btn-container>
          </div>
        </contents-box>
      </div>

      <spacer :y="8"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import { Address } from '@/views/components/parts/Form';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'Account',
  mixins: [cf],
  components: {
    BasicBtn,
    BtnContainer,
    ContentsBox,
    Spacer,
    Address,
  },
  data() {
    return {
      flag: {
        readySubmit: false,
      },
      types: [
        {
          name: 'base',
          title: '基本情報',
          items: [
            {
              label: '所属タイプ',
              name: 'account_type',
              edit: false,
              type: 'accountType',
              value: null,
            },
            {
              label: 'メールアドレス',
              name: 'email',
              edit: false,
              type: 'input',
              value: null,
              link: '/edit/email/',
            },
            {
              label: 'パスワード',
              name: 'password',
              edit: false,
              type: 'input',
              value: null,
              link: '/edit/password/',
            },
            {
              label: 'お名前',
              name: 'username',
              edit: false,
              type: 'input',
              value: null,
            },
            {
              label: '住所',
              name: 'address',
              edit: true,
            },
          ],
        },
        {
          name: 'additional',
          title: '詳細情報',
          items: [
            {
              label: '専門領域',
              name: 'specialty',
              edit: false,
              type: 'input',
              value: null,
            },
            {
              label: '資格',
              name: 'qualifications',
              edit: false,
              type: 'input',
              value: null,
            },
            {
              label: '経歴',
              name: 'carrer',
              edit: false,
              type: 'textarea',
              value: null,
            },
            {
              label: '出身',
              name: 'birthplace',
              edit: false,
              type: 'input',
              value: null,
            },
            {
              label: '趣味',
              name: 'hobby',
              edit: false,
              type: 'input',
              value: null,
            },
            {
              label: 'ブログURL',
              name: 'blog',
              edit: false,
              type: 'input',
              value: null,
            },
            {
              label: '特記',
              name: 'note',
              edit: false,
              type: 'textarea',
              value: null,
            },
            {
              label: 'ひとこと',
              type: 'textarea',
              name: 'comment',
              edit: false,
              value: null,
            },
          ],
        },
      ],
      items: [
        {
          name: 'zip',
          type: 'text',
          label: '郵便番号',
          value: null,
          required: true,
          placeholder: '1020074',
          comment: '半角・ハイフンなしで入力してください',
        },
        {
          name: 'pref',
          type: 'select',
          label: '都道府県',
          value: null,
          options: [],
          required: true,
          comment: '',
        },
        {
          name: 'city',
          type: 'text',
          label: '市区町村',
          value: null,
          required: true,
          placeholder: '千代田区九段南',
        },
        {
          name: 'address',
          type: 'text',
          label: '番地',
          value: null,
          required: false,
          placeholder: '1-5-6',
        },
        {
          name: 'building',
          type: 'text',
          label: '建物名・部屋番号',
          placeholder: 'りそな九段ビル5階 KSフロア',
          value: null,
        },
      ],
      address: null,
    };
  },
  created() {
    if (this.user.email) {
      this.setUserData();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.setUserData();
        }
      });
    }
    if (this.helper.master.prefs) {
      this.items[1].options = this.helper.master.prefs;
    } else {
      this.$store.subscribe((mutations) => {
        if (mutations.type === 'helper/putMaster') {
          this.items[1].options = mutations.payload.prefs;
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
    /** dataにセット */
    setUserData() {
      const keys = Object.keys(this.user);
      keys.forEach((key) => {
        const value = this.user[key];
        this.types.some((type) => {
          if (type.name === 'base') {
            // baseはuser
            type.items.some((row) => {
              if (key === row.name) {
                if (row.name !== 'address') {
                  row.value = value;
                }
                return true;
              }
              return false;
            });
          } else {
            // additionalDataはmedicalWorker
            type.items.some((row) => {
              if (key === 'medicalWorker') {
                const additionalKeys = Object.keys(this.user.medicalWorker);
                additionalKeys.some((akey) => {
                  if (akey === row.name) {
                    row.value = this.user.medicalWorker[akey];
                    return true;
                  }
                  return false;
                });
              } else if (row.name === 'comment' && this.user.comment) {
                row.value = this.user.comment;
              }
              return false;
            });
          }
          return false;
        });
      });
    },

    /** 子から値を受け取る */
    receiveValue(values) {
      this.flag.readySubmit = values.readySubmit;
      this.address = values.address;
    },

    /** 情報更新 */
    update(name) {
      this.showLoading();
      let target = [];
      this.types.forEach((row) => {
        if (row.name === name) {
          target = row.items;
        }
      });
      if (name === 'additional') this.updateAdditionalData(target);
      else if (name === 'comment') this.updateComment();
      else this.updateAddress();
    },

    updateAdditionalData(array) {
      const data = {
        id: this.user.id,
        account_type: this.user.account_type,
        isOnlyAdditional: true,
      };
      data.additionalData = { id: this.user.medicalWorker.id };

      array.forEach((row) => {
        data.additionalData[row.name] = row.value;
      });
      this.axios({
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then(() => {
          alert('詳細情報の更新が完了しました。');
          this.$store.dispatch('user/update');
          this.hideLoading();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          alert('更新に失敗しました。');
          this.hideLoading();
        });
    },

    /** コメント更新 */
    updateComment() {
      const data = { id: this.user.id };
      this.types.forEach((row) => {
        if (row.name === 'comment') {
          data.comment = row.items[0].value;
        }
      });

      this.axios({
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then(() => {
          alert('コメントを更新しました。');
          this.$store.dispatch('user/update', null, { root: true });
        })
        .catch((error) => {
          alert('コメントの更新に失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    updateAddress() {
      if (!this.flag.readySubmit) {
        alert('住所を正しく入力してください。');
        this.hideLoading();
        return;
      }
      // 住所
      const data = this.address;
      data.id = this.user.address ? this.user.address.id : null;
      data.model_type = 1;

      const endpoint = data.id ? 'updater' : 'register';

      if (!data.id) {
        data.model_type = 1;
        data.foreign_id = this.user.id;
      }

      this.axios({
        method: 'POST',
        url: `/v1/address/set/${endpoint}`,
        data,
      })
        .then(() => {
          alert('更新が完了しました。');
          this.$store.dispatch('user/update');
          this.hideLoading();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module>

.row {
  padding: 24px 0;
  border-top: 1px solid var(--gray-main);
  &.child {
    border-top: none;
    &:not(:first-child) {
      padding-top: 0;
    }
  }
}
.detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @include sm-view {
    display: block;
  }
  .link {
    margin-left: 13px;
    display: inline-block;
    font-size: 12px;
    color: var(--orange-main);
    border: 1px solid var(--orange-main);
    padding: 2px 12px;
    border-radius: 12px;
  }
}
.label {
  border-radius: 4px;
  margin-right: 4px;
  font-weight: bold;
  display: inline-block;
  &_wrap {
    display: flex;
    align-items: baseline;
  }
}
.text,
.select {
  &:disabled {
    color: black;
    background-color: transparent;
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }
  }
}
.required {
  font-size: 12px;
  color: var(--pink);
}
.assistant {
  font-size: 12px;
  margin-top: 5px;
  color: red;
}
.error {
  font-size: 12px;
  color: var(--pink);
}
.space {
  padding-top: 6px;
}
.text,
.plane {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
  &.plane {
    font-size: 14px;
    background-color: transparent;
  }
}
.textarea {
  display: block;
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  resize: none;
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
}
.radio {
  padding-top: 8px;
  display: flex;

  li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
.select {
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;

  &_wrap {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.tel {
  width: 70px;
  padding: 13px;
}
.terms {
  height: 295px;
  padding: .5em 1em;
  border: 1px solid #ccc;
  overflow-y: scroll;
  border-radius: 10px;
}
.checkbox_wrap {
  font-size: 13px;
  > li {
    padding: .5em;
    &:not(:first-child) {
      margin-top: 5px;
    }
    &.parentAgree {
      background-color: rgba(#60b2c1, .3);
    }
    &.agree {
      background-color: rgba(#e7a223, .3);
    }
  }
}
</style>

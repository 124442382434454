<template>
  <div
    :class="[$style.iconImage, $style[size]]">
    <img
      v-if="helper.env.name !== 'local'"
      :src="src"
      :class="$style.imageBody"
      v-bind="$attrs"
      crossorigin />
    <img
      v-else
      :src="src"
      :class="$style.imageBody"
      v-bind="$attrs" />
  </div>
</template>

<script>
/**
 * LTI利用者（LMS内のツールとしてアクセス）の場合、
 * CORSによりS3を含む外部リソースにbg-imageがアクセスできない
 * そのためimgタグにcrossoriginをあてて対応する
 */

import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'RoundImg-LTI',
  mixins: [cf],
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapState(['helper', 'user']),
  },
};

</script>

<style lang="scss" module>
.iconImage {
  $size: 120px;
  width: $size;
  height: $size;
  margin-right: 16px;
  border-radius: 100%;
  border: 1px solid rgba(#aaa, 0.3);
  overflow: hidden;
  .imageBody {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.xs {
    $size: 40px;
    width: $size;
    height: $size;
  }

  @include sm-view {
    $size: 20vw;
    width: $size;
    height: $size;
    &.xs {
      $size: 40px;
      width: $size;
      height: $size;
    }
  }
}
</style>

<template>
  <div>
    <spacer :y="15"/>
    <div>
      <img :class="$style.logo" src="@/assets/images/logo_vertical.png" alt="logo">
    </div>
    <spacer :y="5"/>
    <h2 :class="$style.title">パスワードリセット</h2>
    <spacer :y="3"/>
    <div v-if="invalid">
      <p :class="$style.message">アクティベートキーが無効です。<br>再度パスワード再設定リクエストを送信してください。</p>
      <spacer :y="2"/>
      <div :class="$style.wrap">
        <router-link
        to="/forgot-password/"
        :class="$style.btn">再設定リクエスト</router-link>
      </div>
    </div>
    <div v-if="!invalid">
      <p
        :class="$style.message"
        v-if="!flag.sended">新しいパスワードを入力・送信してください。</p>
      <p
        :class="$style.message"
        v-if="flag.sended
          && flag.expired
          && !flag.updated">パスワード再設定の有効期限が切れています。再度<router-link to="/forgot-password/" class="link">こちら</router-link>からパスワード再設定リクエストをお送りください。</p>
      <p
        :class="$style.message"
        v-if="flag.sended
          && !flag.expired
          && !flag.updated">パスワードアップデートのためのアクティベートキーが無効です。<br>再度<router-link to="/forgot-password/" class="link">こちら</router-link>からパスワード再設定リクエストをお送りください。</p>
      <p
        :class="$style.message"
        v-if="flag.sended
          && flag.updated">パスワードを更新しました。<br><router-link to="/login/?isResetPass=true" class="link">こちら</router-link>からログインしてください。</p>
    </div>
    <spacer :y="3"/>
    <form
      v-if="!invalid"
      v-on:submit="submit"
      autocomplete="off">
      <div :class="$style.form">
        <div :class="$style.input">
          <input
            id="password"
            type="password"
            name="password"
            placeholder="パスワード"
            v-model.trim="$v.password.$model"
            v-bind:class="{ input_error: validationFlags.password }"
            v-on:input="invalidsCheck('password', $v.password.$invalid)"
            >
          <p
            :class="$style.comment"
            v-if="$v.password.$dirty
              && $v.password.required.$invalid">パスワードを入力してください</p>
          <p
            :class="$style.comment"
            v-if="$v.password.$dirty
              && $v.password.minLength.$invalid">パスワードは8文字以上で設定してください</p>
        </div>
        <spacer :y="3"/>
        <div :class="$style.wrap">
          <button
            type="submit"
            class="btn btn__primary sm"
            :class="$style.btn"
            v-bind:disabled="!submitFlag">送信</button>
        </div>
      </div>
    </form>
    <spacer :y="15"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'reset-password',
  mixins: [cf],
  components: {
    Spacer,
  },
  data() {
    return {
      validationFlags: {
        password: false,
      },
      // 無効フラグ
      invalids: {
        password: true,
        minLength: true,
      },
      // パラメータなし等で無効
      invalid: true,
      activatekey: null,
      flag: {
        sended: false,
        updated: false,
        expired: false,
      },
    };
  },
  created() {
    // パラメータが含まれているか確認
    const query = this.$route.query;
    if (query.secret) {
      this.invalid = false;
      this.activatekey = query.secret;
    }
  },
  setup() {
    const password = ref('');

    const rules = {
      // パスワードは必須で8文字以上
      password: {
        required,
        minLength: minLength(8),
      },
    };

    const $v = useVuelidate(rules, { password });
    return { password, $v };
  },
  computed: {
    ...mapState(['user', 'page']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.password && !this.flag.updated && !this.flag.expired;
    },
  },
  methods: {
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },
    submit(e) {
      e.preventDefault();
      // validateチェックは入力時に行われてる
      const data = {
        password: this.password,
        activatekey: this.activatekey,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/resetPassword',
        data,
      })
        .then((response) => {
          const resData = response.data;
          if (resData.updated) this.flag.updated = true;
          cf.deleteLocalStorage('wtt', 'loginRef');
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.expired) this.flag.expired = true;
          } else console.log(error);
        })
        .finally(() => {
          this.flag.sended = true;
        });
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  display: flex;
  justify-content: center;
}
.logo {
  margin: 0 auto;
  width: 300px;
  max-width: 100%;
}
.title {
  text-align: center;
  color: var(--orange-main);
}
.form {
  text-align: center;
}
.input {
  margin: 0 auto;
  width: 400px;
  max-width: 100%;
  input {
    width: 100%;
    padding: 13px;
    border-radius: 8px;
    border: none;
    background-color: var(--gray-sub);
    outline: none;
  }
}
.comment {
  font-size: 12px;
  color: var(--pink);
}
.message {
  font-size: 12px;
  text-align: center;
  a {
    color: var(--black);
    text-decoration: underline;
  }
}
.btn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
}
</style>

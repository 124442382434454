<template>
  <loader-simple :flag="loaderFlag">
    <transition appear v-if="!loaderFlag">
      <div v-show="!loaderFlag">
        <div
          :class="$style.sign">
          <spacer :y="3" />
          <div :class="$style.sign_image"><img :src="`/img/seiza/seiza_${helper.seiza.number}.jpeg`" alt="星座イメージ"></div>
          <spacer :y="2" />
          <div :class="$style.sign_title">
            <h3>{{ helper.seiza.name }}</h3>
            <p>{{ helper.seiza.periodStart }} ~ {{ helper.seiza.periodEnd }}</p>
          </div>

          <!-- 女性用テキスト -->
          <p
            :class="$style.sign_content"
            v-if="user.gender === 2">{{ helper.fortune.text1 }}</p>

          <!-- 男性用テキスト -->
          <p
            :class="$style.sign_content"
            v-if="user.gender === 1">{{ helper.fortune.text2 }}</p>

          <div
            :class="$style.shadowBox"
            v-for="lucky in names"
            :key="lucky.value">
            <div :class="[$style.boxTitle, `seiza-${helper.seiza.number}`]">{{ lucky.label }}</div>
            <div :class="$style.boxContent">{{ helper.fortune[lucky.value] }}</div>
          </div>

          <div :class="$style.font_small_center">
            <p :class="$style.font_small">「占いボイス」は、占い日の星の配置から読み 解く「西洋占星術」がもとになっています。</p>
          </div>
        </div>
      </div>
    </transition>
  </loader-simple>
</template>

<script>
import { mapState } from 'vuex';
import Spacer from '@/views/components/Spacer.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'Fortune',
  mixins: [cf],
  components: {
    Spacer,
    LoaderSimple,
  },
  data() {
    return {
      names: [
        { label: 'ラッキーナンバー', value: 'name3' },
        { label: 'ラッキーカラー', value: 'name2' },
        { label: 'ラッキーパーソン', value: 'name4' },
      ],
    };
  },
  created() {
  },
  computed: {
    ...mapState(['user', 'helper']),
    loaderFlag() {
      return !this.helper.fortune;
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" module>
.sign {
  width: 80%;
  margin: 0 auto;
  &_image {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    img {
      max-width: 100px;
      margin: 0 auto;
    }
    @include sm-view {
      max-width: 50%;
      img {
        width: 50%;
      }
    }
  }
  h3 {
    font-size: 23px;
    + p {
      font-size: 20px;
    }
    @include sm-view {
      font-size: 1.4em;
      + p {
        font-size: 1em;
      }
    }
  }
  &_title {
    text-align: center;
  }
  &_content {
    padding: 30px 0 30px 0;
    line-height: 2;
    @include sm-view {
      padding: 1.4em 0;
      font-size: .9em;
    }
  }
  @include sm-view {
    width: 100%;
  }
}
.font_small {
  font-size: .9em;
  @include sm-view {
    font-size: .8em;
    text-align: left;
  }
  &_center {
    text-align: center;
  }
}
.shadowBox {
  box-shadow:rgba(122, 122, 122, 0.0588235) 0px 0px 6px 3px;
  -webkit-box-shadow:rgba(122, 122, 122, 0.0588235) 0px 0px 6px 3px;
  -moz-box-shadow:rgba(122, 122, 122, 0.0588235) 0px 0px 6px 3px;
  border-radius: 10px;
  margin: 20px 0 50px;
  padding: 30px;
  position: relative;
  @media screen and (max-width: 490px) {
    padding: 25px 0 15px;
    margin: 25px 0 30px;
  }
  .boxTitle {
    top: -.8em;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    position: absolute;
    padding: 5px 1em;
    display: inline-block;
    line-height: 1;
    color: white;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    border-radius: 20px;
    white-space: nowrap;
    @media screen and (max-width: 490px)  {
      font-size: 1em;
    }
  }
  .boxContent {
    font-size: 23px;
    text-align: center;
    line-height: 1;
    @media screen and (max-width: 490px) {
      font-size: 1em;
    }
  }
}
</style>

<style lang="scss" scoped>
$seizaColors:
  #7bc2dc,
  #88cabb,
  #b0bc75,
  #b28989,
  #a99898,
  #bb90ba,
  #9aadcb,
  #be8298,
  #b8ae76,
  #cbb27e,
  #739ba5,
  #8088b9;
@for $i from 1 through 12 {
  .seiza-#{$i} {
    background-color: nth($seizaColors, $i);
  }
}

.v {
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
  &-enter-active {
    transition: opacity 2s;
  }
  &-enter-to,
  &-leave {
    opacity: 1;
  }
  &-leave-active {
    transition: opacity .5s;
  }
}

</style>

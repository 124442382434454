<template>
  <div>
    <spacer :y="6"/>
    <contents-box>
      <loader-simple :flag="flag.loader">
        <ul v-if="!flag.loader">
          <stack-item
            v-for="row of counselors"
            :key="row.id">
            <counselor-box :counselor="row" :showDetail="openIds.includes(row.id)">
              <template v-slot:btn>
                <spacer :y="2"/>
                <btn-container>
                  <basic-btn
                    tag="button"
                    size="sm"
                    v-if="!openIds.includes(row.id)"
                    v-on:click="changeOpenIds(row.id)">詳細を表示</basic-btn>
                  <basic-btn
                    tag="button"
                    type="bdr"
                    size="sm"
                    v-if="openIds.includes(row.id)"
                    v-on:click="changeOpenIds(row.id)">詳細を非表示</basic-btn>
                </btn-container>
              </template>
              <template v-slot:other></template>
            </counselor-box>
          </stack-item>
        </ul>
      </loader-simple>
    </contents-box>
  </div>
</template>

<script>
import BasicBtn from '@/views/components/BasicBtn.vue';
import CounselorBox from '@/views/components/CounselorBox.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';

export default {
  name: 'counselors',
  components: {
    BasicBtn,
    BtnContainer,
    CounselorBox,
    StackItem,
    ContentsBox,
    LoaderSimple,
    Spacer,
  },
  data() {
    return {
      flag: {
        loader: true,
      },
      counselors: [],
      tempCounselors: [],
      openIds: [],
    };
  },
  created() {
    this.getCounselors(22);
  },
  methods: {
    /** 心理士・医師の順でkana/ascのorder取得 */
    getCounselors(accountType) {
      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params: {
          accountTypes: [accountType],
          order: 'kana',
          orderBy: 'asc',
        },
      })
        .then((response) => {
          if (response.data.users.data.length) {
            const length = response.data.users.data.length - 1;
            response.data.users.data.forEach((user, i) => {
              // テスト心理士を最後に表示に変更
              if (user.id === 107) this.tempCounselors.push(user);
              else this.counselors.push(user);

              if (length === i && accountType === 22) {
                this.getCounselors(21);
              } else if (length === i && accountType === 21) {
                if (this.tempCounselors.length) this.tempCounselors.forEach((u) => { this.counselors.push(u); });
                this.flag.loader = false;
              }
            });
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    changeOpenIds(id) {
      if (this.openIds.includes(id)) {
        const idx = this.openIds.indexOf(id);
        this.openIds.splice(idx, 1);
      } else {
        this.openIds.push(id);
      }
    },
  },
};
</script>

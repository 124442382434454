<template>
  <div>
    <spacer :y="5"/>
    <contents-box title="事務局管理者登録" id="confirm">
      <div :class="$style.wrapper">
        <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">名前</p>
            <p :class="$style.required">※必須項目</p>
          </div>
          <div :class="$style.detail">
            <div>
              <input
                :class="$style.text"
                name="username"
                :disabled="flag.isConfirm"
                placeholder="例　三上　春斗"
                v-on:input="updateValue"
                type="text">
            </div>
          </div>
        </div>

        <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">メールアドレス</p>
            <p :class="$style.required">※必須項目</p>
          </div>
          <div :class="$style.detail">
            <div>
              <input
                :class="$style.text"
                :disabled="flag.isConfirm"
                placeholder="メールアドレス"
                v-model.trim="$v.mail.$model"
                id="mail"
                type="email"
                name="mail"
                v-on:input="invalidsCheck('mail', $v.mail.$invalid)">
              <p
                :class="$style.error"
                v-if="$v.mail.$dirty
                  && $v.mail.required.$invalid">メールアドレスを入力してください</p>
              <p
                :class="$style.error"
                v-if="$v.mail.$dirty
                  && $v.mail.email.$invalid">正しいメールアドレスの形式で入力してください</p>
            </div>
          </div>
        </div>

        <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">パスワード</p>
            <p :class="$style.required">※必須項目</p>
          </div>
          <div :class="$style.detail">
            <div>
              <input
                :class="$style.text"
                placeholder="パスワード"
                :disabled="flag.isConfirm"
                type="password"
                id="password"
                name="password"
                v-model.trim="$v.password.$model"
                v-on:input="invalidsCheck('password', $v.password.$invalid)">
              <p
                :class="$style.error"
                v-if="$v.password.$dirty
                  && $v.password.minLength.$invalid">パスワードは8文字以上で設定してください</p>
            </div>
            <p :class="$style.assistant" v-if="!flag.isConfirm">半角英数字8桁以上15桁以下</p>
          </div>
        </div>
      </div>
      <spacer :y="5"/>
      <btn-container>
        <basic-btn
          tag="button"
          v-if="!flag.isConfirm"
          v-on:click="changeFlag">入力内容を確認する</basic-btn>
        <basic-btn
          tag="button"
          v-if="flag.isConfirm"
          v-on:click="changeFlag">編集画面に戻る</basic-btn>
        <spacer :x="2"/>
        <basic-btn
          tag="button"
          v-if="flag.isConfirm"
          v-on:click="regist">入力内容を確定する</basic-btn>
      </btn-container>
    </contents-box>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required, minLength } from '@vuelidate/validators';
import cf from '@/mixins/commonFunctions';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Spacer from '@/views/components/Spacer.vue';

export default {
  name: 'signup',
  mixins: [cf],
  components: {
    BasicBtn,
    BtnContainer,
    ContentsBox,
    Spacer,
  },
  setup() {
    const mail = ref('');
    const password = ref('');

    const rules = {
      // メールは必須・email形式
      mail: { required, email },
      // パスワードは必須
      password: {
        required,
        minLength: minLength(8),
      },
    };

    const $v = useVuelidate(rules, { mail, password });

    return { mail, password, $v };
  },
  data() {
    return {
      validationFlags: {
        mail: false,
        password: false,
      },
      invalids: {
        mail: true,
        password: true,
        minLength: true,
      },
      flag: {
        isConfirm: false,
      },
      username: null,
    };
  },
  methods: {
    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    updateValue(e) {
      this.username = e.target.value;
    },

    changeFlag() {
      if (!this.flag.isConfirm) {
        if (this.username === '' || this.username === null) {
          alert('名前を入力してください。');
          return;
        }
        if (this.invalids.mail || this.invalids.password) {
          alert('メールアドレスまたはパスワードが正しく入力されていません。');
          return;
        }
      }
      this.flag.isConfirm = !this.flag.isConfirm;
      // if (this.flag.isConfirm) {
      //   const elem = document.getElementById('confirm');
      //   const position = elem.getBoundingClientRect().top;
      //   scrollBy(0, position - 77);
      // }
    },

    regist() {
      const data = {
        email: this.mail,
        password: this.password,
        username: this.username,
        account_type: 31,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/register',
        data,
      })
        .then((response) => {
          console.log(response);
          if (response.data.exists) {
            alert('このメールアドレスはすでに登録されています。別のメールアドレスで再度お試しください。');
          } else {
            this.$router.push('/operator/');
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(this.error);
        });
    },
  },
};
</script>

<style lang="scss" module>
.row {
  padding: 24px 0;
  border-top: 1px solid var(--gray-main);
}
.label {
  border-radius: 4px;
  margin-right: 4px;
  font-weight: bold;
  display: inline-block;
  &_wrap {
    display: flex;
    align-items: baseline;
  }
}
.text, .select {
  &:disabled {
    color: black;
    background-color: transparent;
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }
  }
}
.required {
  font-size: 12px;
  color: red;
}
.assistant {
  font-size: 12px;
}
.error {
  font-size: 12px;
  color: var(--pink);
}
.space {
  padding-top: 6px;
}
.text {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
}
.radio {
  padding-top: 8px;
  display: flex;

  li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
.select {
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;
  appearance: none;

  &_wrap {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
</style>

<template>
  <div :class="$style.dataDetail">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DataDetail',
};
</script>

<style lang="scss" module>
.dataDetail {
  display: table-cell;
  padding: calc(var(--spacer-base) * 3) 0;

  @include sm-view {
    display: block;
    border-top: none;
    padding: var(--spacer-base);
  }
}
</style>

/**
* 共通ヘルパー
*/

import axios from '@/plugins/axios';
import moment from 'moment';

const actions = {
  /** ロケーションからenvを判別 */
  checkEnv({ commit, state }) {
    if (state.env.flag) return;

    const hostname = location.hostname;
    let env;
    let bot;
    switch (hostname) {
      case 'localhost':
        env = 'local';
        bot = 'kBmwCzm';
        break;
      case 'dev.welcometotalk.com':
        env = 'develop';
        bot = 'V3Pb9sS';
        break;
      case 'welcometotalk.com':
        env = 'production';
        bot = 'vy6FVos';
        break;
      default:
        env = 'production';
        bot = 'vy6FVos';
        break;
    }

    const data = {
      flag: true,
      name: env,
      LINEBOT: bot,
    };

    commit('putEnv', data);
  },

  checkSystemMaster({ commit, rootState }) {
    const systemMasters = [
      'imauji@cicac.jp',
      'sonoda@cicac.jp',
      'fujikawa@cicac.jp',
      'yamaguchi@cicac.jp',
      'amagawa@cicac.jp',
      'iguchi@cicac.jp',
    ];
    const flag = systemMasters.includes(rootState.user.email);
    commit('isSystemMaster', flag);
  },

  checkAccountingAccount({ commit, rootState }) {
    const accountingAccounts = [
      'takagi-atsushi@welcometotalk.co.jp',
    ];
    const flag = accountingAccounts.includes(rootState.user.email);
    commit('isAccountingAccount', flag);
  },

  reset({ commit }) {
    commit('reset');
  },

  /** backend共通マスターデータ */
  getMaster({ commit }) {
    axios({
      method: 'GET',
      url: '/v1/helper/get/master',
    })
      .then((response) => {
        commit('putMaster', response.data.master);
      })
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      });
  },

  checkUserSeiza({ commit, dispatch, rootState }) {
    const birthday = rootState.user.birthday;
    const month = moment(birthday).format('MM');
    const day = moment(birthday).format('DD');

    // 処理の便宜上、一旦１月はじまりで考える
    const monthBorder = [20, 19, 21, 20, 21, 22, 23, 23, 23, 24, 23, 22];

    let index = month - 1;
    if (day >= monthBorder[index]) index += 1;
    if (index === 12) index = 0;

    // cocomoniのコード表に合わせる
    switch (index) {
      case 1: index = 11; break;
      case 2: index = 12; break;
      default: index -= 2; break;
    }

    commit('putUserSeiza', index);
    dispatch('getTodaysFortune');
  },

  getTodaysFortune({ commit, state }) {
    axios({
      method: 'GET',
      url: '/v1/fortune/get/list',
      params: {
        targetDate: moment(new Date()).startOf('day').format('YYYY-MM-DD'),
        seiza_no: state.seiza.number,
      },
    })
      .then((response) => {
        const res = response.data;
        const fortune = res.data && res.data.data.length ? res.data.data[0] : null;
        commit('putTodaysFortune', fortune);
      })
      .catch((error) => {
        if (error.message) console.log(error.message);
        else console.log(error);
      });
  },

  getTextDisableStatus({ commit }) {
    axios({
      method: 'GET',
      url: '/v1/option/get/status',
    })
      .then((response) => {
        commit('putTextDisableStatus', response.data.data);
      })
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      });
  },
};


const mutations = {
  putEnv(state, data) {
    state.env.flag = data.flag;
    state.env.name = data.name;
  },

  isSystemMaster(state, bool) {
    state.isSystemMaster = bool;
  },

  systemMasters(state, array) {
    state.systemMasters = array;
  },

  isAccountingAccount(state, bool) {
    state.isAccountingAccount = bool;
  },

  reset(state) {
    state.systemMasters = [];
    state.isSystemMaster = false;
    state.env = {
      name: 'production',
      flag: false,
    };
  },

  putMaster(state, data) {
    state.master = data;
  },

  putTextDisableStatus(state, data) {
    state.textDisableStatus = data;
  },

  putUserSeiza(state, number) {
    const seizaList = ['牡羊座', '牡牛座', '双子座', '蟹座', '獅子座', '乙女座', '天秤座', '蠍座', '射手座', '山羊座', '水瓶座', '魚座'];
    const periodStartList = ['3/21', '4/20', '5/21', '6/22', '7/23', '8/23', '9/23', '10/24', '11/23', '12/22', '1/20', '2/19'];
    const periodEndList = ['4/19', '5/20', '6/21', '7/22', '8/22', '9/22', '10/23', '11/22', '12/21', '1/19', '2/18', '3/20'];

    state.seiza.number = number;
    state.seiza.name = seizaList[number - 1];
    state.seiza.periodStart = periodStartList[number - 1];
    state.seiza.periodEnd = periodEndList[number - 1];
  },

  putTodaysFortune(state, data) {
    state.fortune = data;
  },
};


const state = {
  isSystemMaster: false,
  isAccountingAccount: false,
  env: {
    name: 'production',
    flag: false,
    LINEBOT: 'vy6FVos',
  },
  master: {},
  textDisableStatus: {},
  seiza: {
    number: null,
    name: '',
    periodStart: '',
    periodEnd: '',
  },
  fortune: null,
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
};

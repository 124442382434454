/* eslint-disable */
import WavEncoder from './wav-encoder';

export default class {
  constructor(options = {}) {
    this.format = options.format;
    this.error_message;

    this.encoderOptions = {
      bitRate: options.bitRate,
      sampleRate: options.sampleRate,
    };

    this.bufferSize = 4096;
    this.records = [];

    this.duration = 0;
    this.startTime = 0;

    this.wavSamples = [];
    this.gainValue = 0;

    this.UA = options.UA;
  }

  async start() {
    const constraints = {
      video: false,
      audio: {
        channelCount: 1,
        echoCancellation: false,
      },
    };

    const capture = navigator.mediaDevices
      .getUserMedia(constraints)
      .then(this._micCaptured.bind(this))
      .catch((err) => {
        alert('マイクの検出に失敗しました。\nマイク接続を確認してください。');
        console.log(err);
        return err;
      });

    return capture;
  }

  stop() {
    this.stream.getTracks().forEach((track) => track.stop());
    this.input.disconnect();
    this.processor.disconnect();
    this.context.close();

    let record = null;

    const wavEncoder = new WavEncoder({
      bufferSize: this.bufferSize,
      sampleRate: this.encoderOptions.sampleRate,
      samples: this.wavSamples,
    });
    record = wavEncoder.finish();
    this.wavSamples = [];

    record.duration = this.convertTimeMMSS(this.duration);
    this.records.push(record);

    this.duration = 0;
  }


  recordList() {
    return this.records;
  }

  lastRecord() {
    return {
      record: this.records.slice(-1).pop(),
      startTime: this.startTime,
    }
  }

  applyGainValue(value) {
    this.gainValue = value;
  }

  _micCaptured(stream) {
    // const isAndroid = this.UA.toLowerCase().includes('android');
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    this.context = new AudioContext();
    this.input = this.context.createMediaStreamSource(stream);
    this.processor = this.context.createScriptProcessor(this.bufferSize, 1, 1);
    this.stream = stream;
    this.gainNode = this.context.createGain();
    // if (!isAndroid) { // Androidを除外
    //   this.compressorNode = this.context.createDynamicsCompressor();
    // }
    this.compressorNode = this.context.createDynamicsCompressor();

    // 音声開始部分の時間を取得
    // const analyser = this.context.createAnalyser();
    // this.input.connect(analyser);
    // analyser.minDecibels = -80;

    // const data = new Uint8Array(analyser.frequencyBinCount);
    // const silence_start = performance.now();
    // let triggered = false;
    // let timeBetween = 0;

    // function loop() {
    //   let silence_end
    //   while (!triggered) {
    //     analyser.getByteFrequencyData(data);
    //     if (data.some(v => v)) { // 最低dbより高いデータが検出された時
    //       if (!triggered) {
    //         silence_end = performance.now();
    //         triggered = true;
    //       }
    //     }
    //     if (triggered) timeBetween =  silence_end - silence_start;
    //   }
    // }
    // loop();
    // this.startTime = timeBetween;
    // 音声開始時間取得処理ここまで

    // TODO: AudioWorkletに置換
    this.processor.onaudioprocess = (ev) => {
      this.gainNode.gain.value = this.gainValue;
      const sample = ev.inputBuffer.getChannelData(0);

      const oldSampleRate = this.context.sampleRate;
      const resampleData = this.interpolateArray(sample, sample.length * (this.encoderOptions.sampleRate / oldSampleRate));
      this.bufferSize = sample.length * (this.encoderOptions.sampleRate / oldSampleRate);

      const bufferData = new Float32Array(this.bufferSize);
      for (let i = 0; i < this.bufferSize; ++i) {
        bufferData[i] = resampleData[i];
      }
      this.wavSamples.push(bufferData);

      this.duration = parseFloat(this.context.currentTime.toFixed(2));
    };

    this.input.connect(this.gainNode);
    // if (!isAndroid) { // Androidを除外
    //   this.gainNode.connect(this.compressorNode);
    //   this.compressorNode.connect(this.processor);
    // } else { // Android
    //   this.gainNode.connect(this.processor);
    // }
    this.gainNode.connect(this.compressorNode);
    this.compressorNode.connect(this.processor);
    this.processor.connect(this.context.destination);
  }

  linearInterpolate(before, after, atPoint) {
    return before + (after - before) * atPoint;
  }

  //サンプリングレート変換
  interpolateArray(data, fitCount) {
    const newData = [];
    const springFactor = new Number((data.length - 1) / (fitCount - 1));
    newData[0] = data[0];
    for (let i = 1; i < fitCount - 1; i += 1) {
      const tmp = i * springFactor;
      const before = new Number(Math.floor(tmp)).toFixed();
      const after = new Number(Math.ceil(tmp)).toFixed();
      const atPoint = tmp - before;
      newData[i] = this.linearInterpolate(data[before], data[after], atPoint);
    }
    newData[fitCount - 1] = data[data.length - 1];
    return newData;
  }

  convertTimeMMSS(seconds) {
    return new Date(seconds * 1000).toISOString().substr(14, 5);
  }

}

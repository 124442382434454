<template>
  <div>
    <spacer :y="5"/>
    <contents-box title="メールアドレス更新">

      <div v-if="flag.updated">
        <p :class="$style.message">メールアドレスを更新しました。次回ログイン時から更新したメールアドレスをご利用ください。</p>

        <spacer :y="4"/>
        <div :class="$style.flex">
          <router-link
            :class="$style.btn"
            to="/account/">マイページ</router-link>
          <spacer :x="2"/>
          <button
            :class="`${$style.btn} ${$style.bdr}`"
            v-on:click="logout">ログアウト</button>
        </div>
      </div>

      <div
        :class="$style.comment"
        v-if="flag.invalid">
        <p>アクティベートキーが無効です。<br>メールアドレス更新リクエスト時のメールを確認し、メール記載のURLからアクセスしてください。</p>
      </div>
    </contents-box>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'updated-email',
  mixins: [cf],
  components: {
    ContentsBox,
    Spacer,
  },
  data() {
    return {
      activatekey: null,
      flag: {
        invalid: true,
        updated: false,
        mismatch: false,
      },
    };
  },
  created() {
    // パラメータが含まれているか確認
    const query = this.$route.query;
    if (query.activate) {
      // アクティベートキーがあればメールアドレスを変更
      this.flag.invalid = false;
      this.activatekey = query.activate;

      if (this.user.email) {
        this.updateLoginEmailAddress();
      } else {
        this.$store.subscribe((mutation) => {
          if (mutation.type === 'user/setUserData') {
            this.updateLoginEmailAddress();
          }
        });
      }
    }
  },
  computed: {
    ...mapState(['user', 'page']),
  },
  methods: {
    ...mapActions('user/login', [
      'logout',
    ]),

    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** ログイン用メールアドレスを更新 */
    updateLoginEmailAddress() {
      // this.showLoading();

      const data = {
        id: this.user.id,
        email: this.user.updateEmail,
        updateEmail: null,
        activatekey: this.activatekey,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/updateLoginEmail',
        data,
      })
        .then(() => {
          this.flag.updated = true;
          this.$store.dispatch('user/update', null, { root: true });
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            this.flag.invalid = true;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          // this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module>
.flex {
  display: flex;
}
.comment {
  color: var(--pink);
}
.message {
  font-size: 12px;
}
.btn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
  &.bdr {
    background-color: transparent;
    color: var(--black);
    border: 1px solid var(--black);
  }
}
</style>

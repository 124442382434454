<template>
  <div>
    <spacer :y="5"/>
      <ul>
        <stack-item>
          <contents-box title="ステータス" icon="<i class='fa-solid fa-user-doctor'></i>">

            <stack-item>
              <contents-box
                type="inner"
                title="バーチャル健康相談"
                icon="<i class='fa-solid fa-vr-cardboard'></i>"
                iconColor="green"
              >
                <spacer :y="2"/>
                <loader-simple :flag="flag.loaders.virtual">
                  <div v-if="!flag.loaders.virtual && hasVirtualReserve()">
                    <ul :class="$style.reserve" v-if="virtualReserves.length">
                      <li v-for="row in virtualReserves" :key="row.id">
                        <router-link v-if="row.flag === 1" :to="`/counselor/reserve/virtual/${row.id}/`">
                          <p>{{ filterMoment(row.schedule.date, 'M月D日（ddd）H時') }}～バーチャル健康相談の予約が入っています。</p>
                        </router-link>
                      </li>
                    </ul>
                    <spacer :y="2"/>
                    <btn-container>
                      <basic-btn to="/counselor/?tab=virtual" size="sm">予約一覧へ</basic-btn>
                    </btn-container>
                  </div>
                  <div v-else-if="!flag.loaders.virtual">
                    <p>現在、バーチャル健康相談の予約はありません。</p>
                  </div>
                </loader-simple>
              </contents-box>
            </stack-item>

            <stack-item>
              <contents-box
                type="inner"
                title="オンライン健康相談"
                icon="<i class='fa-regular fa-laptop'></i>"
                inner-icon="<i class='fa-regular fa-user-headset'></i>"
                iconColor="orange"
              >
                <spacer :y="2"/>
                <loader-simple :flag="flag.loaders.online">
                  <div v-if="!flag.loaders.online && hasReserve()">
                    <ul :class="$style.reserve" v-if="reserves.length">
                      <li v-for="row in reserves" :key="row.id">
                        <router-link v-if="row.flag === 1" :to="`/counselor/reserve/online/${row.id}/`">
                          <p>{{ filterMoment(row.schedule.date, 'M月D日（ddd）H時') }}～オンライン健康相談の予約が入っています。</p>
                        </router-link>
                      </li>
                    </ul>
                    <spacer :y="2"/>
                    <btn-container>
                      <basic-btn to="/counselor/?tab=online" size="sm">予約一覧へ</basic-btn>
                    </btn-container>
                  </div>
                  <div v-else-if="!flag.loaders.online">
                    <p>現在、オンライン健康相談の予約はありません。</p>
                  </div>
                </loader-simple>
              </contents-box>
            </stack-item>

            <stack-item>
              <contents-box
                type="inner"
                title="テキスト健康相談"
                icon="<i class='fa-regular fa-comment-lines'></i>"
                iconColor="blue"
              >
                <spacer :y="2"/>
                <div v-if="user.account_type === 22 && user.manageWeekdays.length">
                  <loader-simple :flag="flag.loaders.progress">
                    <div v-if="!flag.loaders.progress && progressTexts.length">
                      <p>未回答のテキスト健康相談が{{ progressTexts.length }}件あります。</p>
                      <spacer :y="2"/>
                      <btn-container>
                        <basic-btn to="/counselor/?tab=text" size="sm">テキスト健康相談一覧へ</basic-btn>
                      </btn-container>
                    </div>
                    <div v-else-if="!flag.loaders.progress && !progressTexts.length">
                      <p>回答待ちのテキスト健康相談はありません。</p>
                    </div>
                  </loader-simple>
                </div>
                <div v-else>
                  <p>ー</p>
                </div>
              </contents-box>
            </stack-item>
            <stack-item v-if="operatorMessages.length">
              <contents-box type="inner" title="事務局メッセージ" icon="<i class='fa-regular fa-circle-info'></i>">
                <spacer :y="2"/>
                <ul :class="$style.operatorMessageList">
                  <li v-for="operatorMessage in operatorMessages" :key="operatorMessage.id">
                    <router-link :to="`/counselor/text/${operatorMessage.pivot.group_id}/`" :class="$style.link"
                      @click="registerReadFlag(operatorMessage.id)">
                      <div :class="$style.operator_wrap">
                        <div :class="$style.title_wrap">
                          <label-box
                            v-if="user.readflags && !user.readflags.operatorMessages.includes(operatorMessage.id)"
                            :class="$style.label"
                            label="NEW"
                            size="sm"
                            color="orange"/>
                          <spacer
                            :x="1"
                            v-if="user.readflags && !user.readflags.operatorMessages.includes(operatorMessage.id)"/>
                          <p :class="$style.operator_title">{{ operatorMessage.message }}</p>
                        </div>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </contents-box>
            </stack-item>
          </contents-box>
        </stack-item>

        <stack-item :class="$style.only_pc">
          <contents-box type="inner">
            <div :class="$style.regist">
              <div :class="$style.parallel">
                <i class="fa-solid fa-calendar-days" :class="$style.icon"></i>
                <p :class="$style.regist_title">シフト登録</p>
              </div>
              <basic-btn to="/counselor/?tab=schedule">登録する</basic-btn>
            </div>
          </contents-box>
        </stack-item>

        <stack-item :class="$style.only_sp">
          <contents-box title="シフト登録" icon="<i class='fa-solid fa-calendar-days'/>">
            <basic-btn to="/counselor/?tab=schedule">登録する</basic-btn>
          </contents-box>
        </stack-item>

        <stack-item>
          <contents-box title="事務局からのお知らせ" icon="<i class='fa-regular fa-circle-info'></i>">
            <div v-if="informations.length">
              <ul>
                <stack-item
                  v-for="row in informations"
                  :key="row.title">
                  <contents-box type="inner">
                    <router-link
                      :to="`/counselor/information/${row.id}/`">
                      <label-box
                        label="NEW"
                        size="sm"
                        color="orange"
                        v-if="user.readflags && !user.readflags.informations.includes(row.id)"
                        :class="$style.label" />
                      <spacer :y="1"/>

                      <div :class="$style.info_title">
                        <div :class="$style.labels">
                          <label-box
                            :label="filterMoment(row.updated_at, 'YYYY-MM-DD')"
                            size="sm"
                            color="orange"
                            :class="[$style.label, $style.date]" />
                          <label-box
                            label="重要"
                            size="sm"
                            color="pink"
                            v-if="row.flag === 11"
                            :class="$style.label" />
                        </div>
                      </div>

                      <spacer :class="$style.hideSp" :x="1"/>
                      <div :class="$style.texts">
                        <div :class="$style.title"><span>{{ row.title }}</span></div>
                        <div :class="$style.content"><p v-html="replaceImgTag(row.content)"></p></div>
                      </div>
                    </router-link>
                  </contents-box>
                </stack-item>
              </ul>

              <spacer :y="3" v-if="informations.length" />
              <btn-container v-if="informations.length">
                <basic-btn
                  to="/counselor/information/">お知らせ一覧</basic-btn>
              </btn-container>
            </div>
            <div v-else>
              <p>事務局からのお知らせはありません。</p>
            </div>
          </contents-box>
        </stack-item>

        <stack-item>
          <contents-box type="inner">
            <div :class="$style.regist">
              <div :class="$style.parallel">
                <i class="fa-solid fa-megaphone" :class="$style.icon"></i>
                <p :class="$style.regist_title">LINE登録</p>
              </div>
            </div>
            <p :class="$style.content_detail">オンライン健康相談のリマインダーやテキスト健康相談の受付LINEでチェックできます。</p>
            <div :class="$style.flex_center">
              <img :class="$style.qr" :src="`/img/default/line_${helper.env.name}.png`">
              <p>LINE 公式アカウント</p>
            </div>
          </contents-box>
        </stack-item>
      </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'counselor-top',
  mixins: [cf],
  components: {
    StackItem,
    ContentsBox,
    BasicBtn,
    BtnContainer,
    LabelBox,
    Spacer,
    LoaderSimple,
  },
  data() {
    return {
      flag: {
        loaders: {
          online: true,
          progress: true,
          virtual: true,
        },
      },
      informations: [],
      reserves: [],
      progressTexts: [],
      virtualReserves: [],
      operatorMessages: [],
    };
  },
  async created() {
    if (Object.keys(this.$route.query).length
      && this.$route.query.tab !== 'home') return;
    this.checkTabState();

    this.getReservesLite();
    this.getVirtualReservesLite();

    // 同時に実行よりも一つずつ実行を待ち、極力他環境への負荷を減らす

    try {
      const progressText = await this.getLiteMessageGroups();
      if (progressText) console.log(progressText);
    } catch (error) {
      console.log(error);
    }

    try {
      const opeMsg = await this.getOperatorMessage();
      if (opeMsg) console.log(opeMsg);
    } catch (error) {
      console.log(error);
    }

    try {
      const information = await this.getInformations();
      if (information) console.log(information);
    } catch (error) {
      console.log(error);
    }


    // this.getOperatorMessage();
    // this.getInformations();
    // this.getProgressTexts();

    if (this.user.counseling.reserves.length) {
      this.upSortReserves();
    } else {
      this.getReservesLite();
    }

    if (this.user.counseling.virtualReserves.length) {
      this.upSortVirtualReserves();
    } else {
      this.getVirtualReservesLite();
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  watch: {
    $route() {
      if (Object.keys(this.$route.query).length
        && this.$route.query.tab !== 'home') return;

      if (!this.informations.length) this.getInformations();
      if (!this.progressTexts.length) this.getProgressTexts();
      if (!this.operatorMessage) this.getOperatorMessage();
      if (this.user.counseling.reserves.length) this.upSortReserves();
      else this.getReservesLite();
      if (this.user.counseling.virtualReserves.length) this.upSortVirtualReserves();
      else this.getVirtualReservesLite();
    },
  },
  methods: {
    getReservesLite() {
      const params = {
        flags: [1],
        toUser: this.user.id,
      };

      this.axios({
        method: 'GET',
        url: '/v2/reserve/get/liteList',
        params,
      })
        .then((response) => {
          const res = response.data.result.reserve;
          this.$store.commit('user/counseling/putReserves', res);
          this.upSortReserves();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    getVirtualReservesLite() {
      const params = {
        flags: [1],
        toUser: this.user.id,
        counseling_type: 3, // バーチャル健康相談
      };

      this.axios({
        method: 'GET',
        url: '/v2/reserve/get/liteList',
        params,
      })
        .then((response) => {
          const res = response.data.result.reserve;
          this.$store.commit('user/counseling/putVirtualReserves', res);
          this.upSortVirtualReserves();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    hasReserve() {
      let flag = false;
      if (this.user.counseling && this.user.counseling.reserves && !this.user.counseling.reserves.future) {
        this.user.counseling.reserves.forEach((row) => {
          if (row.flag === 1) flag = true;
        });
      }
      return flag;
    },
    hasVirtualReserve() {
      let flag = false;
      if (this.user.counseling && this.user.counseling.virtualReserves && !this.user.counseling.virtualReserves.future) {
        this.user.counseling.virtualReserves.forEach((row) => {
          if (row.flag === 1) flag = true;
        });
      }
      return flag;
    },

    /**
     * 自身のオンライン健康相談予約を取得してstoreへ登録
     */
    storeReserves() {
      this.axios({
        method: 'get',
        url: '/v1/reserve/get/list',
        params: { to_user_id: this.user.id },
      })
        .then((response) => {
          this.$store.commit('user/counseling/putReserves', response.data.reserves.data);
          this.upSortReserves();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    /**
     * 自身のバーチャル健康相談予約を取得してstoreへ登録
     */
    storeVirtualReserves() {
      this.axios({
        method: 'get',
        url: '/v1/reserve/get/list',
        params: {
          to_user_id: this.user.id,
          counseling_type: 3, // バーチャル健康相談
        },
      })
        .then((response) => {
          this.$store.commit('user/counseling/putVirtualReserves', response.data.reserves.data);
          this.upSortReserves();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    /** 受付中テキスト健康相談取得 */
    getProgressTexts() {
      const end = moment(new Date()).format('YYYY-MM-DD 23:59:59');
      const start = moment(end).subtract(2, 'w').format('YYYY-MM-DD 00:00:00');
      const params = {
        orderBy: 'asc',
        between: [start, end],
        weekdays: this.user.manageWeekdays,
        target_type: 1,
        type: 'progress',
      };
      this.axios({
        method: 'GET',
        url: '/v2/messageGroup/get/typeList',
        params,
      })
        .then((response) => {
          const groups = response.data.groups;
          if (groups.data.length) this.progressTexts = groups.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.loaders.progress = false;
        });
    },

    /** テキスト健康相談件数、軽量情報取得 */
    getLiteMessageGroups() {
      const params = {
        weekdays: this.user.manageWeekdays,
        target_type: 1,
        flags: [1],
      };

      this.axios({
        method: 'GET',
        url: '/v2/messagegroup/get/liteList',
        params,
      })
        .then((response) => {
          const groups = response.data.groups;
          if (groups.data.length) this.progressTexts = groups.data;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.loaders.progress = false;
        });
    },

    /** 事務局お知らせ取得 */
    getInformations() {
      this.axios({
        method: 'GET',
        url: '/v1/information/get/list',
        params: {
          targetNumber: 4,
          limit: 3,
          onwards: this.user.created_at,
        },
      })
        .then((response) => {
          this.informations = response.data.data.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    /** LSにtab情報が保存されているか確認する */
    checkTabState() {
      const ls = cf.getLocalStorage('wtt');
      if (ls.saveState && ls.saveState.tab) {
        this.$router.replace({
          query: {
            tab: ls.saveState.tab,
          },
        });
        // localStorageのsaveStateを削除
        cf.deleteLocalStorage('wtt', 'saveState');
      }
    },

    /** 予約をschedule.dateの昇順でソート */
    upSortReserves() {
      const clone = cloneDeep(this.user.counseling.reserves);
      this.reserves = cf.upSortReserves(clone);
      this.flag.loaders.online = false;
    },

    /** 予約をschedule.dateの昇順でソート */
    upSortVirtualReserves() {
      const clone = cloneDeep(this.user.counseling.virtualReserves);
      this.virtualReserves = cf.upSortReserves(clone);
      this.flag.loaders.virtual = false;
    },

    /** 事務局個別メッセージ取得 */
    getOperatorMessage() {
      this.axios({
        method: 'GET',
        url: '/v1/messagegroup/get/list',
        params: {
          target_user_id: this.user.id,
          target_type: 2,
          isOperator: 1,
        },
      })
        .then((response) => {
          this.operatorMessages = response.data.groups.data.length
            ? response.data.groups.data.filter((group) => group.messages.length) // チャット作成後の初メッセージは事務局から
              .map((group) => group.messages.find((message) => message.user_id !== this.user.id))
            : [];
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    registerReadFlag(id) {
      if (this.user.readflags && !this.user.readflags.operatorMessages.includes(id)) {
        const data = {
          model_type: 6, // 事務局個別メッセージ
          user_id: this.user.id,
          foreign_id: id,
        };
        this.axios({
          method: 'POST',
          url: '/v1/readflag/set/register',
          data,
        })
          .then(async () => {
            await this.$store.dispatch('user/update');
          })
          .catch((error) => {
            if (error.message) console.log(error.message);
            else console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" module>
.reserve {
  margin-top: -8px;
  li {
    margin-top: 8px;
    a {
      color: var(--black);
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.regist {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_title {
    font-size: 20px;
    font-weight: bold;
  }
  .parallel {
    display: flex;

    .icon {
      margin-right: 10px;
      font-size: 25px;
      text-align: center;
    }
  }
  @include sm-view {
    display: block;
  }
}
.content_detail {
  padding-top: 10px;
  padding-left: 20px;
}

.flex_center {
  margin-top: 30px;
  padding-bottom: 20px;
  text-align: center;
  .qr {
    margin: 0 auto;
    width: 120px;
  }
  p {
    padding-top: 20px;
  }
}
.info_title {
  display: flex;
  align-items: center;
  color: var(--black);
  &:hover {
    .title {
      text-decoration: underline;
    }
  }
  @include sm-view {
    display: block;
  }
}
.labels {
  display: flex;
  @include sm-view {
    width: 100%;
    display: block;
    .label {
      width: 100%;
    }
  }
}
.label {
  width: 80px;
  @include sm-view {
    margin-top: 10px;
    width: 100%;
  }
  &.date {
    width: 120px;
    @include sm-view {
      width: 100%;
    }
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
}
.operatorMessageList {
  li:not(:last-child) {
    border-bottom: 1px solid var(--gray-main);
  }
  li {
    padding: 9px 0;
  }
}
.texts {
  color: var(--black);
}
.content {
  font-size: 12px;
  overflow: hidden;
  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  a {
    pointer-events: none;
  }
}
.only_pc {
  @include sm-view {
    display: none;
  }
}
.only_sp {
  display: none;
  @include sm-view {
    display: block;
  }
}
.link {
  color: var(--black);
  .title_wrap {
    overflow: hidden;
  }
  .operator_title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    &:hover {
      text-decoration: underline;
    }
  }
  &:hover {
    .title {
      text-decoration: underline;
    }
  }
  .infoDate {
    width: 120px;
    @include sm-view {
      width: 100%;
    }
  }
}
.operator_wrap {
  display: flex;
  .title_wrap {
    display: flex;
    align-items: center;
    .operator_label {
      width: fit-content;
      min-width: 100px;
    }
  }
}
</style>

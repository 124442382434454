<template>
  <div>
    <div
      :class="[
        $style.top,
        isVirtual ? $style.virtual : null,
      ]"
    >
      <i
        :class="[
          $style.top_icon,
          topicon,
        ]"
      >
        <i
          v-if="!isVirtual"
          :class="$style.top_icon_inner"
          class="fa-regular fa-user-headset"
        />
      </i>
      <div :class="$style.top_text">予約内容</div>
    </div>
    <loader-simple :flag="flag.loader">
      <div v-if="!flag.loader">
        <p :class="$style.invalid" v-if="!data">URL無効です。URLを確認してください。</p>
        <div>
          <spacer :y="5"/>
          <data-grid v-if="data">
            <data-row v-if="user && showFromUser.includes(user.account_type)">
              <data-term width="quater">
                <label-box
                  :class="$style.label"
                  label="予約者"
                  :color="labelBoxColor"
                />
              </data-term>
              <data-detail>
                <div :class="$style.flex">
                  <round-img width="50px" :src="getMyIconUrl(data.fromUser)" />
                  <spacer :x="1.5"/>
                  <div>
                    <p><span :class="$style.bold">{{ data.fromUser.username }}</span>（<span v-if="helper.master">{{ helper.master.labels.users.account_type[data.fromUser.account_type] }}</span>）</p>
                    <p :class="$style.status" v-if="data.fromUser.school && data.fromUser.school.length">{{ data.fromUser.school[0].name }}</p>
                  </div>
                </div>
              </data-detail>
            </data-row>
            <data-row>
              <data-term width="quater">
                <label-box
                  :class="$style.label"
                  label="先生"
                  :color="labelBoxColor"
                />
              </data-term>
              <data-detail>
                <div :class="$style.flex">
                  <round-img width="50px" :src="getMyIconUrl(data.toUser)" />
                  <spacer :x="1.5"/>
                  <div>
                    <p><span :class="$style.bold">{{ data.toUser.username }}</span>先生</p>
                    <p :class="$style.status" v-if="helper.master">{{ helper.master.labels.users.account_type[data.toUser.account_type] }}</p>
                  </div>
                </div>
              </data-detail>
            </data-row>

            <data-row v-if="isVirtual">
              <data-term width="quater">
                <label-box
                  :class="$style.label"
                  label="Virtual Cast ID"
                  :color="labelBoxColor"
                />
              </data-term>
              <data-detail>
                <p>{{ data.fromUser.virtualCastProp.virtual_cast_id }}</p>
                <p :class="$style.status"><a :href="getVirtualCastUserURL(data.fromUser.virtualCastProp.virtual_cast_id)" target="_branc">{{ getVirtualCastUserURL(data.fromUser.virtualCastProp.virtual_cast_id) }}</a></p>
              </data-detail>
            </data-row>

            <data-row>
              <data-term width="quater">
                <label-box
                  :class="$style.label"
                  label="日時"
                  :color="labelBoxColor"
                />
              </data-term>
              <data-detail>
                <div :class="`${$style.flex} ${$style.space}`">
                  <p>{{ filterMoment(data.schedule.date, 'YYYY年MM月DD日') }}</p>
                  <spacer :x="2"/>
                  <p>{{ filterMoment(data.schedule.date, 'HH:mm') }} - {{ filterMoment(data.schedule.date, 'HH:45') }}</p>
                  <spacer :x="2" v-if="isCancel" />
                  <p v-if="isCancel" :class="$style.assistant">{{ helper.master.labels.reserve.flag[data.flag] }}</p>
                </div>
              </data-detail>
            </data-row>

            <data-row v-if="isCancel">
              <data-term width="quater">
                <label-box
                  :class="$style.label"
                  label="キャンセル理由"
                  :color="labelBoxColor"
                />
              </data-term>
              <data-detail>
                <p>{{ helper.master.labels.reserve.cancel_reason[data.cancel_reason] }}</p>
                <spacer :y="2" v-if="data.memos.cancel.length" />
                <div class="n2br" :class="$style.space" v-if="data.memos.cancel.length">{{ data.memos.cancel[0].memo }}</div>
              </data-detail>
            </data-row>

            <data-row v-if="data.flag === 1">
              <data-term width="quater">
                <label-box
                  :class="$style.label"
                  label="ご利用URL"
                  :color="labelBoxColor"
                />
              </data-term>
              <!-- バーチャル -->
              <data-detail v-if="isVirtual">
                <div>
                  <p :class="$style.url"><a :href="getVirtualCastMedicalRooomURL(data.toUser.virtualCastProp.virtual_cast_room_key)" target="_blank">{{ getVirtualCastMedicalRooomURL(data.toUser.virtualCastProp.virtual_cast_room_key) }}</a></p>
                  <p :class="$style.assistant">※相談前に上記URLをクリックし「お気に入り登録」してください</p>
                  <p :class="$style.assistant">※相談当日はヘッドセットを装着し「お気に入り」ルームに入室してください</p>
                </div>
              </data-detail>
              <!-- オンライン -->
              <data-detail v-else>
                <div>
                  <p :class="$style.url"><a :href="data.meeting.join_url" target="_blank">{{ data.meeting.join_url }}</a></p>
                  <p :class="$style.assistant">※予約日時の5分前にアクセスください</p>
                </div>
              </data-detail>
            </data-row>

            <data-row>
              <data-term width="quater">
                <label-box
                  :class="$style.label"
                  label="お悩み"
                  :color="labelBoxColor"
                />
              </data-term>
              <data-detail>
                <div :class="`${$style.flex} ${$style.space}`">
                  <div
                    :class="$style.item"
                    v-for="type in data.type"
                    :key="type"
                    v-show="Array.isArray(data.type)">{{ helper.master.labels.reserve.type[type] }}</div>
                  <div
                    :class="$style.item"
                    v-show="!Array.isArray(data.type)">{{ helper.master.labels.reserve.type[data.type] }}</div>
                  <div :class="[$style.item, $style.other]" v-if="data.other_text">（{{ data.other_text }}）</div>
                </div>
              </data-detail>
            </data-row>

            <data-row v-if="data.memos.customer.length">
              <data-term width="quater">
                <label-box
                  :class="$style.label"
                  label="相談内容"
                  :color="labelBoxColor"
                />
              </data-term>
              <data-detail>
                <div class="n2br" :class="$style.space">{{ data.memos.customer[0].memo }}</div>
              </data-detail>
            </data-row>

            <data-row v-if="user.account_type === 11">
              <data-term width="quater">
                <label-box
                  :class="$style.label"
                  label="コメント"
                  :color="labelBoxColor"
                />
              </data-term>
              <data-detail>
                <textarea
                  :class="$style.textarea"
                  name="memo"
                  id="memo"
                  v-model="memo"
                  rows="10"></textarea>
                <btn-container>
                  <basic-btn tag="button"
                    v-on:click="saveMemo">保存</basic-btn>
                </btn-container>
              </data-detail>
            </data-row>

            <data-row v-if="helper.master">
              <data-term width="quater">
                <label-box
                  :class="$style.label"
                  label="ココモニ"
                  :color="labelBoxColor"
                />
              </data-term>
              <data-detail>
                <div
                  v-for="(item, i) in helper.master.who5.items"
                  :key="i"
                  :class="$style.who5item">
                  <div>Q{{ i + 1 }}. {{ item.label }}</div>
                  <div :class="$style.who5_answer">{{ helper.master.who5.labels[data.who5Answer.answers[i]] }}</div>
                </div>
              </data-detail>
            </data-row>
          </data-grid>
        </div>
      </div>
    </loader-simple>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import RoundImg from '@/views/components/RoundImg';
import LabelBox from '@/views/components/LabelBox';
import Spacer from '@/views/components/Spacer.vue';
import {
  DataGrid, DataRow, DataTerm, DataDetail,
} from '@/views/components/DataGrid';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'reserve-detail-impl',
  props: [
    'counseling_type', // 1 || 3
  ],
  mixins: [cf],
  components: {
    BasicBtn,
    BtnContainer,
    LoaderSimple,
    RoundImg,
    LabelBox,
    Spacer,
    DataGrid,
    DataRow,
    DataTerm,
    DataDetail,
  },
  data() {
    return {
      flag: {
        loader: true,
      },
      id: null,
      data: null,
      memo: null,
      showFromUser: [21, 22, 31],
    };
  },
  created() {
    this.id = cf.convert2Num(this.$route.params.slug);
    if (this.id && this.user.email) {
      this.getReserveDetail();
    } else if (this.id) {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.getReserveDetail();
        }
      });
    }
  },
  computed: {
    ...mapState(['helper', 'user']),
    isVirtual() {
      return this.counseling_type === '3';
    },
    topicon() {
      return this.isVirtual ? 'fa-solid fa-vr-cardboard' : 'fa-regular fa-laptop';
    },
    isCancel() {
      return this.data.flag === 901 || this.data.flag === 902;
    },
    labelBoxColor() {
      return this.isVirtual ? 'green' : 'orange';
    },
  },
  methods: {
    getReserveDetail() {
      this.axios({
        method: 'GET',
        url: '/v1/reserve/get/detail',
        params: {
          id: this.id,
          counseling_type: this.counseling_type,
        },
      })
        .then((response) => {
          let invalid = true;
          if (this.user.account_type > 10 || response.data.reserve.fromUser.id === this.user.id) invalid = false;
          if (!invalid) {
            this.data = response.data.reserve;
            if (this.data.memos.teacher.length) this.memo = this.data.memos.teacher[0].memo;
          }
          this.flag.loader = false;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    saveMemo() {
      const data = {};
      data.id = this.data.memos.teacher.length ? this.data.memos.teacher[0].id : null;
      const endpoint = data.id ? 'updater' : 'register';

      data.memo = this.memo;
      data.user_id = this.user.id;
      data.reserve_id = this.data.id;

      this.axios({
        method: 'POST',
        url: `/v1/reserve/memo/set/${endpoint}`,
        data,
      })
        .then(() => {
          alert('コメントを更新しました。');
          this.getReserveDetail();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module>
.top {
  background-color: var(--orange-main);
  display: flex;
  justify-content: center;
  padding: 90px 30px;
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  margin: 0 calc((100vw - 100%) / (-2));
  position: relative;

  &.virtual {
    background-color: var(--green-main);
  }

  &_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .2;
    font-size: 130px;

    &_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -70%);
      font-size: 50px;
    }
  }

  &_text {
    text-align: center;
    @include sm-view {
      font-size: 30px;
    }
    @include xs-view {
      font-size: 20px;
    }
  }
}
.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.bold {
  font-weight: bold;
}
.label {
  @include sm-view {
    width: 180px;
  }
}
.status {
  font-size: 12px;
}
.item {
  white-space: nowrap;
  &.other {
    white-space: pre-wrap;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
}
.space {
  margin-top: 8px;
  @include sm-view {
    margin-top: 0;
  }
}
.url {
  a {
    color: var(--black);
    text-decoration: underline;
  }
}
.assistant {
  font-size: 12px;
  color: var(--pink);
}
.who5item {
  &:not(:first-child) {
    margin-top: 15px;
  }
}
.who5_answer {
  font-weight: bold;
}
.textarea {
  width: 100%;
  resize: vertical;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
}
.invalid {
  margin-top: 30px;
}
</style>

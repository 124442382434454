<template>
  <div>
    <spacer :y="8.5" :class="$style.topSpace" />
    <loader-simple :flag="flag.loader">
      <div v-if="!flag.loader">
        <div :class="$style.counselor">
          <round-img width="100px" :src="getMyIconUrl(user)" />
          <div :class="$style.text">
            <p :class="$style.name">{{ user.username }}</p>
            <p :class="$style.position">{{ user.account_type === 21 ? '医師' : '心理士' }}</p>
          </div>
        </div>
        <spacer :y="7" :class="$style.bottomSpace" />
        <tab-nav
          :items="myTabItems"
          :isActive="isActive"
          v-on:sendIsActiveValue="receiveIsActiveValue"/>
        <div>
          <div
            v-for="(tab, key) in tabItems"
            :key="tab.name"
            :id="`panel-${tab.name}`"
            :aria-labelledby="`tab-${tab.name}`"
            :aria-hidden="key !== isActive"
          >
            <component
              v-if="key === isActive"
              :is="`${tab.name}`"
              :project="project"
            />
          </div>
        </div>
      </div>
    </loader-simple>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import RoundImg from '@/views/components/RoundImg.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import TabNav from '@/views/components/TabNav';
import Spacer from '@/views/components/Spacer.vue';
import Home from '@/views/pages/Counselor/tabs/Home.vue';
import Schedule from '@/views/pages/Counselor/tabs/Schedule.vue';
import OnlineCounseling from '@/views/pages/Counselor/tabs/OnlineCounseling.vue';
import TextCounseling from '@/views/pages/Counselor/tabs/TextCounseling.vue';
import VirtualCounseling from '@/views/pages/Counselor/tabs/VirtualCounseling.vue';
import RiskManagement from '@/views/pages/Counselor/tabs/RiskManagement.vue';
import RiskApplicationApproval from '@/views/pages/Counselor/tabs/RiskApplicationApproval.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'counselor',
  mixins: [cf],
  components: {
    RoundImg,
    LoaderSimple,
    TabNav,
    Spacer,
    Home,
    Schedule,
    OnlineCounseling,
    TextCounseling,
    VirtualCounseling,
    RiskManagement,
    RiskApplicationApproval,
  },
  data() {
    return {
      flag: {
        loader: true,
      },
      textManager: false,
      tabItems: {
        home: {
          label: 'ホーム',
          name: 'home',
          new: false,
        },
        text: {
          label: 'テキスト',
          name: 'text-counseling',
          new: false,
        },
        online: {
          label: 'オンライン',
          name: 'online-counseling',
          new: false,
        },
        virtual: {
          label: 'バーチャル',
          name: 'virtual-counseling',
          new: false,
        },
        riskManagement: {
          label: 'リスク管理',
          name: 'risk-management',
          new: false,
        },
        approval: {
          label: 'リスク承認',
          name: 'risk-application-approval',
          new: false,
        },
        schedule: {
          label: 'シフト登録',
          name: 'schedule',
          new: false,
        },
      },
      isActive: 'home',
    };
  },
  created() {
    if (this.user.email) {
      if (this.user.account_type < 20 || this.user.account_type > 30) this.redirect2top();
      else {
        this.getWeekdayManagers();
        this.changeTab();
      }
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          if (this.user.account_type < 20 || this.user.account_type > 30) this.redirect2top();
          else {
            this.getWeekdayManagers();
            this.changeTab();
          }
        }
      });
    }
  },
  computed: {
    ...mapState(['user']),
    isVirtualSpecialist() {
      return this.user.medicalWorker.is_virtual_specialist === 1;
    },
    myTabItems() {
      const items = cloneDeep(this.tabItems);
      if (!this.textManager) delete items.text;
      if (!this.isVirtualSpecialist) delete items.virtual;
      if (!this.user.medicalWorker.is_risk_application_approver) delete items.approval; // 「評価」タブは承認者にのみ表示
      return items;
    },
  },
  watch: {
    $route() {
      this.changeTab();
    },
  },
  methods: {
    changeTab() {
      // クエリでどのタブを表示させるか判定
      const query = this.$route.query;
      const tabNames = Object.keys(this.tabItems);
      if (query.tab && tabNames.includes(query.tab)) this.isActive = query.tab;
      else this.isActive = 'home';
      this.flag.loader = false;
    },
    receiveIsActiveValue(name) {
      this.isActive = name;
      this.$router.replace(`/counselor/?tab=${name}`);
    },
    /**
     * 自身のuser.idがweekdayManager.user_idに含まれれば
     * テキスト健康相談タブを表示
     */
    getWeekdayManagers() {
      this.axios({
        method: 'GET',
        url: '/v1/weekdaymanager/get/all',
      })
        .then((response) => {
          response.data.data.data.some((manager) => {
            if (manager.user_id === this.user.id) {
              this.textManager = true;
              return true;
            }
            return false;
          });
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    /** ログイン済みユーザはトップリダイレクト */
    redirect2top() {
      const ls = cf.getLocalStorage('wtt');
      // ログアウト時にログインへリダイレクトされた場合の対応
      if (!ls.jwt) return;

      const accountType = this.user.account_type;
      const customerTypes = [1, 2, 3];
      const counselorTypes = [21, 22];
      const teacherTypes = [11, 12];
      const operatorTypes = [31];
      let path = '';
      if (this.user.account_type) {
        if (customerTypes.includes(accountType)) {
          path = { path: '/' };
        } else if (counselorTypes.includes(accountType)) {
          path = { path: '/counselor/' };
        } else if (teacherTypes.includes(accountType)) {
          path = { path: '/teacher/' };
        } else if (operatorTypes.includes(accountType)) {
          path = { path: '/operator/' };
        }
      }
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" module>
@include sm-view {
  .topSpace {
    height: 2em;
  }
  .bottomSpace {
    height: 0em;
  }
}

.counselor {
  display: flex;
  align-items: center;

  .text {
    margin-left: 20px;
  }

  .name {
    font-size: 20px;
  }
  .position {
    font-size: 14px;
  }
  @include sm-view {
    .name {
      font-size: 16px;
    }
    .position {
      font-size: 12px;
    }
  }
}
.date {
  font-size: 12px;
}
.link {
  color: var(--black);
}
.labels {
  display: flex;
  flex-wrap: wrap;
}
.label {
  width: 80px;
}
.slider {
  width: 100%;
  border-radius: 10px;
  background-color: var(--primary-color);
}
</style>

<template>
  <div>
    <spacer :y="5"/>
    <h2>{{ policy.title }}</h2>
    <spacer :y="2"/>
    <p>{{ policy.subtitle }}</p>
    <spacer :y="2"/>
    <p v-for="d in policy.description" :key="d">{{ d }}</p>
    <spacer :y="4"/>
    <ul :class="$style.contents">
      <li v-for="(row, i) in policy.contents" :key="row" :class="$style.content">
        <div :class="$style.title">{{ i + 1 }}. {{ row.title }}</div>
        <spacer :y="1"/>
        <div>
          <p v-for="d in row.description" :key="d">{{ d }}</p>
        </div>
        <ul :class="$style.list">
          <li v-for="list in row.list" :key="list">
            <p>{{ list }}</p>
          </li>
        </ul>
      </li>
    </ul>
    <spacer :y="5"/>
    <div :class="$style.contact">
      <div :class="$style.contanct_title">【個人情報についてのお問い合わせ先】</div>
      <spacer :y="1"/>
      <p :class="$style.contact_text">
        株式会社 Welcome to talk<br>
        担当部署：運営サポート<br>
        E-mail：<a href="mailto:jimukyoku@welcometotalk.com">jimukyoku@welcometotalk.com</a>
      </p>
    </div>
    <spacer :y="5"/>
    <div>2018年6月1日 制定・施行</div>
    <div>2019年7月1日 改訂</div>
    <div>2019年9月1日 改訂</div>
    <div>2019年11月4日 改訂</div>
    <spacer :y="5"/>
  </div>
</template>

<script>
import policy from '@/data/policy.yaml';
import Spacer from '@/views/components/Spacer.vue';

export default {
  name: 'policy',
  components: {
    Spacer,
  },
  data() {
    return {
      policy: {},
    };
  },
  created() {
    this.policy = policy;
  },
};
</script>

<style lang="scss" module>
.title {
  font-weight: bold;
}
.contents {
  .content {
    &:not(:first-child) {
      margin-top: 40px;
    }
  }
}
.list {
  li {
    padding-left: 30px;
    position: relative;
    counter-increment: bracketscnt;

    &::before {
        position: absolute;
        left: 0;
        content: counter(bracketscnt) " ) ";
    }
  }
}
.contact {
  background-color: var(--gray-sub);
  padding: 40px 40px 40px 30px;
  border-radius: 8px;
  display: inline-block;
  &_text {
    padding-left: 10px;
    a {
      color: black;
      text-decoration: underline;
    }
  }
}
</style>

<!--
  "オンライン健康相談を表すタブ情報"を実装実体に受け渡すためのwrapped実装
-->
<template>
  <CounselingImpl
    tab="online"
    counseling_type="1"
  />
</template>

<script>
import CounselingImpl from '@/views/pages/User/impl/CounselingImpl.vue';

export default {
  name: 'user-online-counseling',
  components: {
    CounselingImpl,
  },
  data() {
  },
  methods: {
  },
};
</script>

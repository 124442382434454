<!--
  オンライン健康相談とバーチャル健康相談の両方をこの実装で担当する
-->
<template>
  <div :key="counseling_type">
    <spacer :y="5"/>
    <ul id="scroll-top">
      <stack-item>
        <contents-box title="予約" v-if="page === 1">
          <loader-simple :flag="flag.loaders.progress">
            <ul
              :class="$style.reserves"
              v-if="targetCounselorReserve.progress.length">
              <li
                v-for="row in targetCounselorReserve.progress"
                :key="row">
                <div :class="$style.reserve">
                  <contents-box
                    type="inner"
                    :title="formatTimestamp(row.schedule.date, `YYYY年MM月DD日（${row.weekday.label}）HH:00`)"
                    :icon="icon"
                    :innerIcon="innerIcon"
                    :iconColor="iconColor">

                    <spacer :y="1"/>
                    <user-box
                      :user="row.fromUser"
                      :showId="true"
                      :showCocomoniBtn="true"
                      :ownUser="user"
                      :bg="userBoxColor(row.fromUser)"
                      type="inner"
                      :link="`/counselor/history/${row.id}/?type=${tab}`"
                      :targetBlank="true"
                      :saveTab="tab">
                      <template v-slot:btn>
                        <div :class="$style.list_wrap">
                          <div :class="$style.top_btn">
                            <i
                              v-on:click="changeOpenIds(row.id, 'reserve')"
                              v-if="openIds.reserve.includes(row.id)"
                              :class="$style.icon"
                              class="fa-regular fa-minus"></i>
                            <i
                              v-on:click="changeOpenIds(row.id, 'reserve'), getMostRect(row)"
                              v-if="!openIds.reserve.includes(row.id)"
                              :class="$style.icon"
                              class="fa-regular fa-plus"></i>
                          </div>
                        </div>
                      </template>
                      <template v-slot:main>
                        <div
                          :class="$style.drawer"
                          v-if="openIds.reserve.includes(row.id)">
                          <spacer :y="2"/>
                          <ul :class="$style.detail">
                            <li v-if="row.meeting">
                              <label-box label="URL" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <p :class="$style.content"><a :href="row.meeting.join_url" target="_blank">{{ row.meeting.join_url }}</a></p>
                            </li>
                            <li>
                              <label-box label="お悩み" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <div :class="[$style.value, $style.type]">
                                <p
                                  v-for="typeNum in row.type"
                                  :key="typeNum">{{ helper.master.labels.reserve.type[typeNum] }}<span v-if="row.other_text && typeNum === 6">（{{ row.other_text }}）</span></p>
                              </div>
                            </li>
                            <li class="n2br">
                              <label-box label="相談内容" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <p :class="$style.content" v-html="getCustomerMemo(row.memos.customer)"></p>
                            </li>
                            <li>
                              <label-box label="ココモニ" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <div :class="$style.content">
                                <p :class="$style.who5score"><span :class="$style.who5label">WHO-5</span>{{ row.scoreWho5 }}点</p>
                                <spacer :y="2"/>
                                <ul :class="$style.who5">
                                  <li v-for="(item, i) in row.who5Answer.answers" :key="item">
                                    <p>{{ helper.master.who5.items[i].label }}</p>
                                    <p :class="$style.who5_answer">{{ helper.master.who5.labels[item] }}</p>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="n2br">
                              <label-box :label="isCompany(row) ? '企業コメント' : '学校コメント'" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <p
                                :class="$style.content"
                                v-if="row.memos.teacher.length && row.memos.teacher[0].memo && row.memos.teacher[0].memo !== ''"
                                v-html="row.memos.teacher[0].memo"></p>
                              <p
                                :class="$style.content"
                                v-else>記入なし</p>
                            </li>
                            <li class="n2br" :class="$style.fReserve_center">
                              <label-box label="オンライン<br>健康相談予約" color="gray" size="sm" :class="[$style.label, $style.fReserve_content]" smAlign="left"></label-box>
                              <ul v-if="row.nearest && row.nearest.length" :class="$style.nearest_box">
                                <li v-for="fReserve in row.nearest" :key="fReserve">
                                  <table>
                                    <tr>
                                      <td :class="$style.fReserve_label">実施情報</td>
                                      <td :class="$style.fReserve_value">{{ formatTimestamp(fReserve.created_at, 'YYYY/MM/DD') }}　{{ formatTimestamp(fReserve.schedule.date, `YYYY/MM/DD  HH:00〜HH:45`) }}　{{ fReserve.toUser.username }}</td>
                                    </tr>
                                    <tr>
                                      <td :class="$style.fReserve_label">お悩み</td>
                                      <td :class="$style.fReserve_value"><span v-for="wType in fReserve.type" :key="wType">{{ helper.master.labels.reserve.type[wType] }}　</span></td>
                                    </tr>
                                    <tr>
                                      <td :class="$style.fReserve_label">相談内容</td>
                                      <td :class="$style.fReserve_value">{{ getCustomerMemo(fReserve.memos.customer) }}</td>
                                    </tr>
                                  </table>
                                </li>
                              </ul>
                              <p v-else :class="$style.fReserve_none">以降の予約無し</p>
                            </li>
                          </ul>
                          <spacer :y="3"/>
                          <btn-container>
                            <basic-btn target="_blank" size="sm" :to="`/counselor/history/${row.id}/?type=${tab}`">利用者相談履歴・カルテ記入</basic-btn>
                          </btn-container>
                        </div>
                      </template>
                    </user-box>
                  </contents-box>
                </div>
              </li>
            </ul>

            <p v-else-if="!flag.loaders.progress">現在{{ counselingName }}の予約はありません。</p>
          </loader-simple>
        </contents-box>
      </stack-item>


      <stack-item>
        <contents-box title="報告書未記入" v-if="page === 1">
          <loader-simple :flag="flag.loaders.report">
            <ul
              :class="$style.reserves"
              v-if="targetCounselorReserve.report.length">
              <li
                v-for="row in targetCounselorReserve.report"
                :key="row">
                <div :class="$style.reserve">
                  <contents-box
                    type="inner"
                    :title="formatTimestamp(row.schedule.date, `YYYY年MM月DD日（${row.weekday.label}）HH:00`)"
                    :icon="icon"
                    :innerIcon="innerIcon"
                    :iconColor="iconColor">

                    <spacer :y="1"/>
                    <user-box
                      type="inner"
                      :link="`/counselor/history/${row.id}/?type=${tab}`"
                      :targetBlank="true"
                      :bg="userBoxColor(row.fromUser)"
                      :showCocomoniBtn="true" :ownUser="user"
                      :user="row.fromUser" :showId="true">
                      <template v-slot:btn>
                        <div :class="$style.list_wrap">
                          <div :class="$style.top_btn">
                            <i
                              v-on:click="changeOpenIds(row.id, 'reserve')"
                              v-if="openIds.reserve.includes(row.id)"
                              :class="$style.icon"
                              class="fa-regular fa-minus"></i>
                            <i
                              v-on:click="changeOpenIds(row.id, 'reserve'), getMostRect(row)"
                              v-if="!openIds.reserve.includes(row.id)"
                              :class="$style.icon"
                              class="fa-regular fa-plus"></i>
                          </div>
                        </div>
                      </template>
                      <template v-slot:main>
                        <div
                          :class="$style.drawer"
                          v-if="openIds.reserve.includes(row.id)">
                          <spacer :y="2"/>
                          <ul :class="$style.detail">
                            <li v-if="row.meeting">
                              <label-box label="URL" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <p :class="$style.content"><a :href="row.meeting.join_url" target="_blank">{{ row.meeting.join_url }}</a></p>
                            </li>
                            <li>
                              <label-box label="お悩み" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <div :class="[$style.value, $style.type]">
                                <p
                                  v-for="typeNum in row.type"
                                  :key="typeNum">{{ helper.master.labels.reserve.type[typeNum] }}<span v-if="row.other_text && typeNum === 6">（{{ row.other_text }}）</span></p>
                              </div>
                            </li>
                            <li class="n2br">
                              <label-box label="相談内容" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <p :class="$style.content" v-html="getCustomerMemo(row.memos.customer)"></p>
                            </li>
                            <li>
                              <label-box label="ココモニ" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <div :class="$style.content">
                                <p :class="$style.who5score"><span :class="$style.who5label">WHO-5</span>{{ row.scoreWho5 }}点</p>
                                <spacer :y="2"/>
                                <ul :class="$style.who5">
                                  <li v-for="(item, i) in row.who5Answer.answers" :key="item">
                                    <p>{{ helper.master.who5.items[i].label }}</p>
                                    <p>{{ helper.master.who5.labels[item] }}</p>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="n2br">
                              <label-box :label="isCompany(row) ? '企業コメント' : '学校コメント'" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <p
                                :class="$style.content"
                                v-if="row.memos.teacher.length && row.memos.teacher[0].memo && row.memos.teacher[0].memo !== ''"
                                v-html="row.memos.teacher[0].memo"></p>
                              <p
                                :class="$style.content"
                                v-else>記入なし</p>
                            </li>
                            <li class="n2br" :class="$style.fReserve_center">
                              <label-box label="オンライン<br>健康相談予約" color="gray" size="sm" :class="[$style.label, $style.fReserve_content]" smAlign="left"></label-box>
                              <ul v-if="row.nearest && row.nearest.length" :class="$style.nearest_box">
                                <li v-for="fReserve in row.nearest" :key="fReserve">
                                  <table>
                                    <tr>
                                      <td :class="$style.fReserve_label">実施情報</td>
                                      <td :class="$style.fReserve_value">{{ formatTimestamp(fReserve.created_at, 'YYYY/MM/DD') }}　{{ formatTimestamp(fReserve.schedule.date, `YYYY/MM/DD  HH:00〜HH:45`) }}　{{ fReserve.toUser.username }}</td>
                                    </tr>
                                    <tr>
                                      <td :class="$style.fReserve_label">お悩み</td>
                                      <td :class="$style.fReserve_value"><span v-for="wType in fReserve.type" :key="wType">{{ helper.master.labels.reserve.type[wType] }}　</span></td>
                                    </tr>
                                    <tr>
                                      <td :class="$style.fReserve_label">相談内容</td>
                                      <td :class="$style.fReserve_value">{{ getCustomerMemo(fReserve.memos.customer) }}</td>
                                    </tr>
                                  </table>
                                </li>
                              </ul>
                              <p v-else :class="$style.fReserve_none">以降の予約無し</p>
                            </li>
                          </ul>
                          <spacer :y="3"/>
                          <btn-container>
                            <basic-btn size="sm" :to="`/counselor/report/${row.id}/`">報告書を記入する</basic-btn>
                            <spacer :x="2"/>
                            <basic-btn
                              size="sm"
                              tag="button"
                              type="bg"
                              addClass="danger"
                              @click="cancel(row)">当日キャンセル</basic-btn>
                          </btn-container>
                        </div>
                      </template>
                    </user-box>
                  </contents-box>
                </div>
              </li>
            </ul>

            <p v-else-if="!flag.loaders.report">現在報告書記入待ちの{{ counselingName }}はありません。</p>
          </loader-simple>
        </contents-box>
      </stack-item>


      <stack-item>
        <contents-box title="実施済み">
          <loader-simple :flag="flag.loaders.finished">
            <pagination
              v-if="!flag.loaders.finished && pageMax && pageMax !== 1"
              :page="page"
              :pageMax="pageMax"
              :path="`/counselor/?tab=${tab}`" />

            <spacer
              v-if="!flag.loaders.finished
                && targetCounselorReserve.finished[page]
                && targetCounselorReserve.finished[page].length"
              :y="4" />
            <ul
              :class="$style.reserves"
              v-if="targetCounselorReserve.finished[page]
                && targetCounselorReserve.finished[page].length
                && !flag.loaders.finished">
              <li v-for="row in targetCounselorReserve.finished[page]" :key="row">
                <div :class="$style.reserve">
                  <contents-box
                    type="inner"
                    :title="`${formatTimestamp(row.schedule.date, `YYYY年MM月DD日（${row.weekday.label}）HH:00`)}${row.flag === 901 ? '<small>キャンセル</small>' : row.flag === 902 ? '<small>当日キャンセル</small>' : ''}`"
                    :icon="icon"
                    :innerIcon="innerIcon"
                    :iconColor="iconColor">

                    <spacer :y="1"/>
                    <user-box
                      type="inner"
                      :link="`/counselor/history/${row.id}/?type=${tab}&isHistory=true`"
                      :targetBlank="true"
                      :showCocomoniBtn="true" :ownUser="user"
                      :bg="userBoxColor(row.fromUser)"
                      :user="row.fromUser" :showId="true">
                      <template v-slot:btn>
                        <div :class="$style.list_wrap">
                          <div :class="$style.top_btn">
                            <i
                              v-on:click="changeOpenIds(row.id, 'reserve')"
                              v-if="openIds.reserve.includes(row.id)"
                              :class="$style.icon"
                              class="fa-regular fa-minus"></i>
                            <i
                              v-on:click="changeOpenIds(row.id, 'reserve'), getMostRect(row)"
                              v-if="!openIds.reserve.includes(row.id)"
                              :class="$style.icon"
                              class="fa-regular fa-plus"></i>
                          </div>
                        </div>
                      </template>
                      <template v-slot:main>
                        <div
                          :class="$style.drawer"
                          v-if="openIds.reserve.includes(row.id)">
                          <spacer :y="2"/>
                          <ul :class="$style.detail">
                            <li v-if="row.meeting">
                              <label-box label="URL" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <p :class="$style.content"><a :href="row.meeting.join_url" target="_blank">{{ row.meeting.join_url }}</a></p>
                            </li>
                            <li>
                              <label-box label="お悩み" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <div :class="[$style.value, $style.type]">
                                <p
                                  v-for="typeNum in row.type"
                                  :key="typeNum">{{ helper.master.labels.reserve.type[typeNum] }}<span v-if="row.other_text && typeNum === 6">（{{ row.other_text }}）</span></p>
                              </div>
                            </li>
                            <li class="n2br">
                              <label-box label="相談内容" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <p :class="$style.content" v-html="getCustomerMemo(row.memos.customer)"></p>
                            </li>
                            <li>
                              <label-box label="ココモニ" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <div :class="$style.content">
                                <p :class="$style.who5score"><span :class="$style.who5label">WHO-5</span>{{ row.scoreWho5 }}点</p>
                                <spacer :y="2"/>
                                <ul :class="$style.who5">
                                  <li v-for="(item, i) in row.who5Answer.answers" :key="item">
                                    <p>{{ helper.master.who5.items[i].label }}</p>
                                    <p>{{ helper.master.who5.labels[item] }}</p>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="n2br">
                              <label-box :label="isCompany(row) ? '企業コメント' : '学校コメント'" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <p
                                :class="$style.content"
                                v-if="row.memos.teacher.length && row.memos.teacher[0].memo && row.memos.teacher[0].memo !== ''"
                                v-html="row.memos.teacher[0].memo"></p>
                              <p
                                :class="$style.content"
                                v-else>記入なし</p>
                            </li>
                            <li
                              class="n2br"
                              v-if="row.cancel_reason">
                              <label-box label="キャンセル理由" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <div :class="$style.cancel_detail">
                                <p :class="$style.content">{{ helper.master.labels.reserve.cancel_reason[row.cancel_reason] }}{{ getAccountTypeLabel(row) }}</p>
                                <dl :class="$style.comment" v-if="row.memos.cancel.length">
                                  <dt>コメント</dt>
                                  <dd>{{ row.memos.cancel[0].memo }}</dd>
                                </dl>
                              </div>
                            </li>
                            <li class="n2br" :class="$style.fReserve_center">
                              <label-box label="オンライン<br>健康相談予約" color="gray" size="sm" :class="[$style.label, $style.fReserve_content]" smAlign="left"></label-box>
                              <ul v-if="row.nearest && row.nearest.length" :class="$style.nearest_box">
                                <li v-for="fReserve in row.nearest" :key="fReserve">
                                  <table>
                                    <tr>
                                      <td :class="$style.fReserve_label">実施情報</td>
                                      <td :class="$style.fReserve_value">{{ formatTimestamp(fReserve.created_at, 'YYYY/MM/DD') }}　{{ formatTimestamp(fReserve.schedule.date, `YYYY/MM/DD  HH:00〜HH:45`) }}　{{ fReserve.toUser.username }}</td>
                                    </tr>
                                    <tr>
                                      <td :class="$style.fReserve_label">お悩み</td>
                                      <td :class="$style.fReserve_value"><span v-for="wType in fReserve.type" :key="wType">{{ helper.master.labels.reserve.type[wType] }}　</span></td>
                                    </tr>
                                    <tr>
                                      <td :class="$style.fReserve_label">相談内容</td>
                                      <td :class="$style.fReserve_value">{{ getCustomerMemo(fReserve.memos.customer) }}</td>
                                    </tr>
                                  </table>
                                </li>
                              </ul>
                              <p v-else :class="$style.fReserve_none">以降の予約無し</p>
                            </li>
                          </ul>
                          <spacer :y="3"/>
                          <btn-container>
                            <basic-btn size="sm" target="_blank" :to="`/counselor/registed-report/${row.id}/`">報告書を確認する</basic-btn>
                          </btn-container>

                          <risk-application-btn
                            v-if="!isVirtual"
                            :counseling_type="counseling_type"
                            :foreign_id="row.id"
                            :target_user_id="row.fromUser.id"
                          />

                        </div>
                      </template>
                    </user-box>
                  </contents-box>
                </div>
              </li>
            </ul>

            <spacer :y="4" />
            <spacer v-if="flag.loaders.finished" :y="6" />
            <pagination
              v-if="!flag.loaders.finished && pageMax && pageMax !== 1"
              :page="page"
              :pageMax="pageMax"
              :path="`/counselor/?tab=${tab}`" />
            <p
              v-else-if="!flag.loaders.finished
                && !targetCounselorReserve.finished[page].length">実施済みの{{ counselingName }}はありません。</p>
          </loader-simple>
        </contents-box>
      </stack-item>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import UserBox from '@/views/components/UserBox.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import cf from '@/mixins/commonFunctions.js';
import Pagination from '@/views/components/Pagination.vue';
import RiskApplicationBtn from '@/views/pages/Counselor/parts/RiskApplicationBtn.vue';

export default {
  name: 'counselor-counseling-impl',
  props: [
    'tab', // 'online' || 'virtual'
    'type', // 'reserve' || 'virtualReserve'
    'counseling_type', // 1 || 3
  ],
  mixins: [cf],
  components: {
    StackItem,
    ContentsBox,
    UserBox,
    LabelBox,
    Spacer,
    BasicBtn,
    BtnContainer,
    LoaderSimple,
    Pagination,
    RiskApplicationBtn,
  },
  data() {
    return {
      flag: {
        loaders: {
          progress: true,
          report: true,
          finished: true,
        },
        pageQuery: false,
      },
      openIds: {
        reserve: [],
        report: [],
      },
      page: 1,
      pageMax: 1,
      limit: 5,
    };
  },
  created() {
    const query = this.$route.query;
    if (query.page) {
      this.page = Number(query.page);
    } else if (query.tab === this.tab) {
      this.$router.replace(`/counselor/?tab=${this.tab}&page=1`);
    }
    // 実施済みを取得してから予約・報告書未記入を取得
    this.getFinished();
  },
  watch: {
    $route() {
      if (this.$route.query.tab !== this.tab) return;

      const query = this.$route.query;
      if (query.page) {
        this.page = Number(query.page);
      } else if (query.tab === this.tab) {
        // queryにpageがなければ追加してreplace
        this.$router.replace(`/counselor/?tab=${this.tab}&page=1`);
        return;
      }

      // 実施済みの指定ページにデータがなければ取得
      if (!this.targetCounselorReserve.finished[this.page]
        || (this.targetCounselorReserve.finished[this.page]
          && !this.targetCounselorReserve.finished[this.page].length)) {
        this.flag.loaders.finished = true;
        this.getFinished();
      } else {
        this.scroll2Top();
      }
      this.flag.pageQuery = true;
    },
  },
  computed: {
    ...mapState(['user', 'helper']),
    isVirtual() {
      return this.tab === 'virtual';
    },
    targetReserves() {
      return this.isVirtual ? this.user.counseling.virtualReserves : this.user.counseling.reserves;
    },
    targetCounselorReserve() {
      return this.isVirtual ? this.user.counseling.counselor.virtualReserve : this.user.counseling.counselor.reserve;
    },
    counselingName() {
      return this.isVirtual ? 'バーチャル健康相談' : 'オンライン健康相談';
    },
    icon() {
      return !this.isVirtual
        ? '<i class="fa-regular fa-laptop" />'
        : '<i class="fa-solid fa-vr-cardboard" />';
    },
    innerIcon() {
      return !this.isVirtual
        ? '<i class="fa-regular fa-user-headset" />'
        : '';
    },
    iconColor() {
      return !this.isVirtual ? 'orange' : 'green';
    },
  },
  methods: {
    /**
     * 実施済みの予約取得
     */
    getFinished() {
      const params = {
        finished: 1,
        to_user_id: this.user.id,
        withWho5: true,
        withType: true,
        withMeeting: true,
        isRisk: 1,
        orderBy: 'desc',
        page: this.page,
        limit: this.limit,
        flags: [11, 901, 902],
        counseling_type: this.counseling_type,
      };

      this.axios({
        method: 'GET',
        url: '/v2/reserve/get/list',
        params,
      })
        .then((response) => {
          const res = response.data.result;
          this.pageMax = res.lastPage;
          const commitData = {
            type: this.type,
            division: 'finished',
            page: this.page,
            values: cf.downSortReserves(res.data),
          };
          this.$store.commit('user/counseling/putCounselorDatas', commitData);

          // 予約（未実施）は1ページ目でしか表示しない。
          // すでに読み込み済みの場合は再取得しない
          if (this.page === 1
            && !this.targetCounselorReserve.progress.length) {
            this.getProgress();
          } else {
            this.flag.loaders.progress = false;
            // 報告書の下書き、登録を行ったとき用
            if (this.targetCounselorReserve.report.length) this.flag.loaders.report = false;
            else this.getReport();
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          if (this.flag.pageQuery) this.scroll2Top();
          this.flag.pageQuery = false;
          this.flag.loaders.finished = false;
        });
    },

    /**
     * 未実施の予約を取得
     */
    getProgress() {
      const params = {
        to_user_id: this.user.id,
        orderBy: 'desc',
        progress: 1,
        withWho5: true,
        withType: true,
        withMeeting: true,
        isRisk: 1,
        counseling_type: this.counseling_type,
      };

      this.axios({
        method: 'GET',
        url: '/v2/reserve/get/list',
        params,
      })
        .then((response) => {
          const data = response.data.result.data;
          if (data.length) {
            const commitData = {
              userId: this.user.id,
              type: this.type,
              division: 'progress',
              values: cf.upSortReserves(data),
            };
            this.$store.commit('user/counseling/putCounselorDatas', commitData);
          }

          // 報告書未記入は1ページ目でしか表示しない。
          // すでに読み込み済みの場合は再取得しない
          if (this.page === 1
            && !this.targetCounselorReserve.report.length) {
            this.getReport();
          } else {
            this.flag.loaders.report = false;
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.loaders.progress = false;
        });
    },

    /**
     * 報告書未記入を取得
     */
    getReport() {
      const params = {
        to_user_id: this.user.id,
        orderBy: 'desc',
        report: 1,
        isRisk: 1,
        withWho5: true,
        withType: true,
        withMeeting: true,
        counseling_type: this.counseling_type,
      };

      this.axios({
        method: 'GET',
        url: '/v2/reserve/get/list',
        params,
      })
        .then((response) => {
          const data = response.data.result.data;

          if (data.length) {
            const commitData = {
              userId: this.user.id,
              type: this.type,
              division: 'report',
              values: cf.upSortReserves(data),
            };
            this.$store.commit('user/counseling/putCounselorDatas', commitData);
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.loaders.report = false;
        });
    },

    // 該当利用者の直近の未実施オンライン健康相談を取得
    async getMostRect(reserve) {
      const params = {
        from_user_id: reserve.from_user_id,
        orderBy: 'asc',
        progress: 1,
        withWho5: true,
        withType: true,
        withMeeting: true,
        isRisk: 1,
        counseling_type: this.counseling_type,
      };

      await this.axios({
        method: 'GET',
        url: '/v2/reserve/get/list',
        params,
      })
        .then((response) => {
          const res = response.data.result.data;
          const futureReserve = [];
          const now = moment(new Date());
          res.forEach((data) => { // 該当予約以外かつ今日以降の予約
            if (data.from_user_id === reserve.from_user_id
              && data.id !== reserve.id
              && moment(data.schedule.date).isAfter(now)) {
              futureReserve.push(data);
            }
          });
          reserve.nearest = futureReserve;
        })
        .catch((error) => {
          if (error.message) console.log(error);
          else console.log(error);
        });
    },

    // 上までスクロール
    scroll2Top() {
      const elem = document.getElementById('scroll-top');
      const position = elem.getBoundingClientRect().top;
      scrollBy(0, position - 77);
    },

    isCompany(row) {
      return Boolean(row && row.fromUser && row.fromUser.school.length && row.fromUser.school[0].is_company);
    },

    changeOpenIds(n, name) {
      if (this.openIds[name].includes(n)) {
        const idx = this.openIds[name].indexOf(n);
        this.openIds[name].splice(idx, 1);
      } else {
        this.openIds[name].push(n);
      }
    },

    /** 予約をschedule.dateの昇順でソート */
    upSortReserves() {
      if (!this.targetReserves.length) {
        this.flag.loaders = { progress: false, report: false, finished: false };
        return;
      }

      const progress = [];
      const report = [];
      const finished = [];
      const clone = cloneDeep(this.targetReserves);
      clone.forEach((row) => {
        if (row.flag === 1) progress.push(row);
        else if (row.flag === 11 && (!row.report || (row.report && row.report.flag === 2))) report.push(row);
        // キャンセル（901,902）も実施済みに含める
        else if ((row.flag === 11 && row.report && row.report.flag === 1) || row.flag === 901 || row.flag === 902) finished.push(row);
      });

      this.progress = cf.upSortReserves(progress);
      this.report = cf.upSortReserves(report);

      // loaderフラグ更新
      this.flag.loaders = { progress: false, report: false };
    },

    async getMyReserves() {
      const params = {
        to_user_id: this.user.id,
        isRisk: 1,
      };
      await this.axios({
        method: 'GET',
        url: '/v1/reserve/get/list',
        params,
      })
        .then((response) => {
          const progress = [];
          const report = [];
          const finished = [];
          response.data.reserves.data.forEach((row) => {
            if (row.flag === 1) progress.push(row);
            else if (row.flag === 11 && (!row.report || (row.report && row.report.flag === 2))) report.push(row);
            // キャンセル（901,902）も実施済みに含める
            else if ((row.flag === 11 && row.report && row.report.flag === 1) || row.flag === 901 || row.flag === 902) finished.push(row);
          });

          this.progress = cf.upSortReserves(progress);
          this.report = cf.upSortReserves(report);
          this.finished = cf.downSortReserves(finished);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /** 当日現れず医師がキャンセル */
    cancel(reserve) {
      const args = {
        modalName: 'reserveCancel',
        data: {
          reserve_id: reserve.id,
          counseling_type: this.counseling_type,
          schedule: reserve.schedule,
        },
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: null });
    },

    /** userBox背景色指定用 */
    userBoxColor(user) {
      let color;
      if (user && user.risk && user.risk.length && user.risk[user.risk.length - 1].type !== 11) {
        color = 'red';
      } else {
        color = 'white';
      }
      return color;
    },

    getDisplayMemo(fReserve) {
      if (fReserve && fReserve.memos && fReserve.memos.customer && fReserve.memos.customer[0]) {
        return fReserve.memos.customer[0].memo;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" module>
.reserves {
  li {
    &:not(:first-child) {
      .reserve {
        margin-top: 16px;
      }
    }
  }
}
.list {
  display: flex;
  align-items: center;
  color: var(--black);

  &_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include sm-view {
      margin-top: 10px;
      justify-content: right;
    }
  }
  @include sm-view {
    flex-wrap: wrap;
    .label {
      width: 100%;
      margin-bottom: 1em;
    }
    .text_wrap {
      flex: 1;
      padding-right: 1em;
    }
  }
}
.label {
  width: 140px;
  margin-right: 20px;
}
.text_wrap {
  margin-left: 15px;
}
.id {
  font-size: 12px;
}
.top_btn {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.icon {
  cursor: pointer;
  font-size: 20px;
  // color: var(--gray-main);
  color: --gray-side;
}
.detail {
  li {
    display: flex;
    align-items: flex-start;
    .content {
      // font-size: 14px;
      margin-top: 3px;
      flex: 1;
    }
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  @include sm-view {
    li {
      display: block;
    }
    .label {
      width: 100%;
    }
    .content {
      flex: 1;
    }
    font-size: 14px;
  }
}
.who5 {
  li {
    justify-content: space-between;
  }
  @include sm-view {
    li {
      display: block;
      p {
        &:first-child {
          font-size: 13px;
        }
        &:not(:first-child) {
          font-weight: bold;
        }
      }
    }
  }
  .who5_answer {
    font-weight: bold;
  }
}
.who5score {
  // font-size: 14px;
  color: var(--black);
  .who5label {
    margin-right: 12px;
  }
}
.done {
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: 1px solid var(--black);
  padding: 4px 12px;
  border-radius: 24px;
  margin-right: 10px;
}
.value {
  flex: 1;
  margin-top: 3px;
  &.type {
    display: flex;
    flex-wrap: wrap;
    p {
      white-space: nowrap;
      &:not(:last-child) {
        &::after {
          margin: 0 5px;
          content: '/';
        }
      }
    }
  }
}
.cancel_detail {
  .comment {
    font-size: 14px;
    margin-top: .5em;
    dt {
      font-weight: bold;
    }
    dd {
      margin: .3em 0 0 0;
    }
  }
}

.nearest_box {
  width: 78%;
}

.fReserve {
  &_content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &_label {
    background-color: var(--gray-sub);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    padding: .3em 1em;
    border-radius: 5px;
    width: 140px;
  }
  &_value {
    padding-left: 1em;
  }
  &_center {
    align-items: stretch !important;
  }
  &_none {
    display: flex;
    align-items: center;
  }
}
</style>

<template>
  <component :is="tag" v-bind="$attrs" :class="className">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'BasicBtn',
  props: {
    tag: {
      type: String,
      default: 'router-link',
      validator: (value) => ['router-link', 'button', 'a'].includes(value),
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => ['xs', 'sm', 'md', 'lg', 'xl'].includes(value),
    },
    type: {
      type: String,
      default: 'bg',
      validator: (value) => ['bg', 'bdr'].includes(value),
    },
    addClass: {
      type: String,
      default: null,
    },
  },
  computed: {
    className() {
      return `${this.$style.button} ${this.$style[this.size]} ${this.$style[this.type]} ${this.addClass ? this.$style[this.addClass] : ''}`;
    },
  },
};
</script>

<style lang="scss" module>
.button {
  display: inline-block;
  border-radius: 22px;
  appearance: none;
  cursor: pointer;
  &:disabled {
    opacity: .6;
    cursor: default;
    pointer-events: none;
  }

  &.bg {
    border: none;
    background-color: #666;
    font-weight: bold;
    color: #fff;
    transition: .4s all;
    &:hover {
      background-color: #555;
    }
    &.danger {
      color: white;
      background-color: #dc3545;
      &:hover {
        background-color: #bb2d3b;
      }
    }
    &.alert {
      color: white;
      background-color: #999999;
      &:hover {
        background-color: #999999;
      }
    }
    &.risk {
      color: rgba(#000033, .7);
      background-color: rgba(#DD0000, .1);
      &:hover {
        color: rgba(#000033, .8);
        background-color: rgba(#DD0000, .2);
      }
    }
    &.wide {
      width: 100%;
    }
  }

  &.bdr {
    background-color: transparent;
    border: 1px solid var(--black);
    color: var(--black);
  }

  &.sm {
    padding: 6px 15px;
    font-size: 14px;

    @include sm-view {
      font-size: 13px;
      padding: 4px 12px;
    }
  }

  &.md {
    padding: 8px 25px;
    font-size: 16px;

    @include sm-view {
      font-size: 14px;
      padding: 8px 25px;
    }
  }

  @include sm-view {
    margin: 0 auto;
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }

  &.isCenter {
    margin-left: auto;
    margin-right: auto;
  }
}

</style>

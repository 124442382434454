<template>
  <div>
    <spacer :y="4"/>
    <loader-simple :flag="flag.loader">
      <ul v-if="!flag.loader">
        <stack-item>
          <contents-box
            title="相談履歴"
            :bg="userBoxColor(targetUser)">
            <user-box
              v-if="targetUser"
              :user="targetUser"
              :ownUser="targetUser"
              :showId="true" />


            <spacer
              v-if="!flag.isHistory"
              :y="4" />
            <contents-box
              v-if="!flag.isHistory"
              title="予約">
              <p :class="$style.date">{{ formatTimestamp(reserve.schedule.date, `YYYY年MM月DD日（${reserve.weekday.label}） HH:00 - HH:45`) }}</p>
              <spacer :y="2"/>
              <user-box :showId="false" :user="reserve.fromUser">
                <template v-slot:main>
                  <spacer :y="2"/>
                  <ul :class="$style.detail">
                    <li>
                      <label-box label="お悩み" color="gray" size="sm" :class="$style.label"/>
                      <p :class="$style.content">
                        <span
                          v-for="rtype in reserve.type"
                          :key="rtype"
                          :class="$style.types">{{ helper.master.labels.reserve.type[rtype] }}</span>
                      </p>
                    </li>
                    <li>
                      <label-box label="ココモニ" color="gray" size="sm" :class="$style.label"/>
                      <ul :class="$style.who5">
                        <li v-for="(item, i) in helper.master.who5.items" :key="item">
                          <p>{{ item.label }}</p>
                          <p>{{ helper.master.who5.labels[reserve.who5Answer.answers[i]] }}</p>
                        </li>
                      </ul>
                    </li>
                    <li class="n2br">
                      <label-box :label="isCompany ? '企業コメント' : '学校コメント'" color="gray" size="sm" :class="$style.label"/>
                      <p
                        v-if="reserve.memos.teacher.length"
                        v-html="reserve.memos.teacher[0].memo"></p>
                      <p v-if="!reserve.memos.teacher.length">記入なし</p>
                    </li>
                  </ul>
                  <spacer :y="4"/>
                  <div
                  :class="$style.textareaDiv"
                  :flag="flag.memo">
                    <textarea
                      name="memo"
                      id="memo"
                      rows="10"
                      :value="memo"
                      v-on:input="updateMemo"
                      :class="$style.textarea">
                    </textarea>
                    <div
                    id="placeholderDiv"
                    v-if="!flag.memo"
                    :class="$style.placeholderDiv">
                      カルテ記入欄<br>カルテ冒頭に「本人確認の方法」をご入力ください。<br>書き方例<br>・生徒手帳にて本人確認済み<br>・生徒手帳忘れにより学校名・氏名にて本人確認済<br>・保護者/教職員のため学校名・氏名にて本人確認済
                    </div>
                  </div>
                  <p :class="$style.assistant">※保存せずにページを離れると入力した内容は失われます。忘れずに保存してください。</p>
                  <spacer :y="2"/>
                  <btn-container>
                    <basic-btn tag="button" v-on:click="registMemo">保存</basic-btn>
                  </btn-container>
                </template>
              </user-box>
            </contents-box>


            <spacer :y="4"/>
            <histories
              :histories="histories"
              :showNew="false"
              :hideCounseling="true"/>
          </contents-box>
        </stack-item>
      </ul>
    </loader-simple>
    <spacer :y="4"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import Histories from '@/views/components/Histories.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import UserBox from '@/views/components/UserBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'history',
  mixins: [cf],
  components: {
    BasicBtn,
    BtnContainer,
    LabelBox,
    Histories,
    ContentsBox,
    UserBox,
    StackItem,
    LoaderSimple,
    Spacer,
  },
  data() {
    return {
      flag: {
        loader: true,
        memo: true,
        isHistory: false,
      },
      slug: null,
      userId: null,
      targetUser: null,
      reserve: null,
      reserves: [],
      texts: [],
      histories: [],
      memo: null,
    };
  },
  created() {
    const slug = cf.convert2Num(this.$route.params.slug);
    this.slug = slug;
    this.getReserve();

    if (this.$route.query && this.$route.query.isHistory) {
      this.flag.isHistory = true;
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
    isCompany() {
      return Boolean(this.reserve && this.reserve.fromUser && this.reserve.fromUser.school.length && this.reserve.fromUser.school[0].is_company);
    },
  },
  methods: {
    // 対象の予約取得
    getReserve() {
      const params = {
        id: this.slug,
        counseling_type: this.$route.query.type === 'virtual' ? 3 : 1,
      };
      this.axios({
        method: 'get',
        url: '/v1/reserve/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.userId = res.reserve.from_user_id;
          this.reserve = res.reserve;
          if (this.reserve.memos.counselor[0]) this.memo = this.reserve.memos.counselor[0].memo;
          if (!this.memo) {
            this.flag.memo = false;
          } else if (this.memo) {
            this.flag.memo = true;
          }
          this.getUser();
          this.getHistory();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    // 利用者取得
    getUser() {
      const params = {
        id: this.userId,
        isRisk: 1,
        includeDeleted: 1,
      };
      this.axios({
        method: 'GET',
        url: '/v1/user/get/detail',
        params,
      })
        .then((response) => {
          this.targetUser = response.data.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    // 複合履歴取得
    getHistory() {
      const params = { userId: this.userId };

      this.axios({
        method: 'GET',
        url: '/v1/history/get/list',
        params,
      })
        .then((response) => {
          const res = response.data.data.data;
          this.histories = res.filter((elem) => elem.content.flag !== 1);
          this.reserves = res.filter((elem) => (elem.type === 'reserve' && elem.content.flag === 1)); // 予約の欄に追加するのは未実施のオンラインのみ
          // TODO ここに手入れが必要か要判断
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.loader = false;
        });
    },

    sortHistories() {
      this.histories = [];
      const now = this.formatTimestamp(new Date(), 'YYYY-MM-DD HH:mm:ss');
      this.reserves.history.forEach((row) => {
        const schedule = this.formatTimestamp(row.schedule.date, 'YYYY-MM-DD HH:mm:ss');
        if (schedule < now) {
          this.histories.push({
            date: schedule,
            type: 'reserve',
            content: row,
          });
        }
      });
      this.texts.answered.forEach((row) => {
        this.histories.push({
          date: row.created_at,
          type: 'text',
          content: row,
        });
      });
      this.texts.progress.forEach((row) => {
        this.histories.push({
          date: row.created_at,
          type: 'text',
          content: row,
        });
      });
      this.histories.sort((a, b) => {
        if (a.date > b.date) return -1;
        return 1;
      });
      this.flag.loader = false;
    },
    updateMemo(e) {
      this.memo = e.target.value;
      if (this.memo) {
        this.flag.memo = true;
      } else if (!this.memo) {
        this.flag.memo = false;
      }
    },
    registMemo() {
      const data = {
        reserve_id: this.reserve.id,
        memo: this.memo,
        user_id: this.user.id,
      };

      if (this.reserve.memos.counselor[0]) data.id = this.reserve.memos.counselor[0].id;
      else data.id = null;

      const endpoint = data.id ? 'updater' : 'register';

      this.axios({
        method: 'POST',
        url: `/v1/reserve/memo/set/${endpoint}`,
        data,
      })
        .then(() => {
          alert('カルテを保存しました。');
          this.getReserve();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module>
.date {
  font-weight: bold;
}
.detail {
  li {
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    @include sm-view {
      display: block;
    }
    .content {
      font-size: 14px;
      margin-top: 3px;
      flex: 1;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    > p {
      flex: 1;
    }
  }
}
.label {
  width: 100px;
  margin-right: 20px;
}
.who5 {
  flex: 1;
  li {
    justify-content: space-between;
  }
}
.types {
  margin-right: 8px;
  &:not(:first-child) {
    &:before {
      content: "/";
    }
  }
}
.textareaDiv {
  position: relative;

  .textarea {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: none;
    appearance: none;
    padding: 20px;
    outline: none;
    z-index:0;
    position: relative;
    resize: vertical;
    min-height: 170px;
    background-color: var(--gray-sub);
  }
  .placeholderDiv{
    border: none;
    color: #999;
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 20px;
    font-size: 14px;
    z-index: 1;
    pointer-events:none;
  }
}
.assistant {
  font-size: 12px;
  color: var(--pink);
}
</style>

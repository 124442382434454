<template>
  <div :class="[$style.code-container, smallMode ? $style.small : null]">
    <input
      v-for="(code, i) in codes"
      :key="i"
      :id="getCodeId(i)"
      type="number"
      :class="[
        $style.code,
        smallMode ? $style.small : null,
      ]" 
      placeholder="0"
      min="0"
      max="9"
      maxlength="1"
      :v-model="code"
      required
      v-on:input="inputCode($event, i)"
      v-on:keyup.delete="deleteCode($event, i)"
      :readonly="readonly"
    >
  </div>
</template>

<script>
import { reactive } from 'vue';

export default {
  name: 'parts-input-codes',
  props: {
    digit: { // 何桁のコードを入力するか
      type: Number,
      default: 6,
    },
    value: { // コード初期値
      type: Number,
      default: null,
    },
    smallMode: { // 小さく表示するか
      type: Boolean,
      default: false,
    },
    readonly: { // 読み取り専用か
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      randomKey: Math.random(), // 乱数. 当コンポーネントが複数利用された際にidが被らないようにするための対策.
    };
  },
  setup(props) {
    const codes = reactive(Array(props.digit));
    return {
      codes,
    };
  },
  computed: {
    inputCompletedValue() {
      const vcid = this.codes.join('');
      const regex = new RegExp(`^\\d{${this.digit}}$`); // 指定桁数の数字チェック
      if (!regex.test(vcid)) { // 指定桁数がすべて埋まっていない場合
        return null;
      }
      return vcid;
    },
  },
  mounted() {
    if (this.value) { // 初期値があれば反映する
      const strValue = `${this.value}`;
      for (let i = 0; i < strValue.length; i += 1) {
        if (this.digit < i) {
          break;
        }
        this.setCode(document.getElementById(this.getCodeId(i)), i, strValue[i]);
      }
    }
  },
  methods: {
    getCodeId(index) {
      return `wtt-input-code-${this.randomKey}-${index}`;
    },
    inputCode(event, index) {
      const pattern = /\d/;
      if (!pattern.test(event.data) || event.data === 'e') {
        this.setCode(event.target, index, null);
        return;
      }
      this.setCode(event.target, index, event.data); // 入力された値のみをinput要素に適用する
      const codeElementNext = document.getElementById(this.getCodeId(index + 1));
      // 次のコードにフォーカスを当てる
      if (codeElementNext) {
        codeElementNext.focus();
      } else {
        event.target.blur(); // 次のコードがない = 最後のコードなのでフォーカスを外す
      }
    },
    deleteCode(event, index) {
      this.setCode(event.target, index, null);
      const codeElementBefore = document.getElementById(this.getCodeId(index - 1));
      // 一つ前のコードにフォーカスを当てる
      if (codeElementBefore) {
        codeElementBefore.focus();
      }
    },
    setCode(target, index, value) {
      target.value = value;
      this.codes[index] = value;
      // 呼び出し元コンポーネントに通知
      if (this.inputCompletedValue) {        
        this.$emit('input-complete', this.inputCompletedValue); // 入力完了
      } else {
        this.$emit('input-incomplete'); // 入力が完了していない
      }
    },
  },
};
</script>

<style lang="scss" module>
.code-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
  &.small {
    margin: 10px 0;
  }
}
.code {
  border-radius: 5px;
  font-size: 60px;
  height: 100px;
  width: 80px;
  border: 1px solid #eee;
  margin: 1%;
  text-align: center;
  font-weight: 300;
  -moz-appearance: textfield;
  &::placeholder {
    text-align: center;
  }
  &.small {
    font-size: 20px;
    height: 30px;
    width: 25px;
  }
}
.code::-webkit-outer-spin-button,
.code::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.code:valid {
  border-color: var(--green-main);
  // box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.25);
}
</style>

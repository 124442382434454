const actions = {
  setBreadCrumbs({ commit }, args) {
    const list = [
      {
        label: 'TOP',
        link: '/',
      },
    ];

    const pageType = args.route.fullPath.split('/')[1];

    if (pageType === 'operator') list[0].link = '/operator/';
    else if (pageType === 'counselor') list[0].link = '/counselor/';
    else if (pageType === 'teacher') list[0].link = '/teacher/';
    else list[0].link = '/';

    if (args.route.fullPath.includes('reserve/online')) {
      const path = args.route.fullPath.split('/');
      let link = '';
      // 医師・心理士
      if (path[1] === 'counselor') link = '/counselor/?tab=online';
      // 事務局
      else if (path[1] === 'operator') link = '/operator/?tab=online';
      // 利用者
      else if (path[1] === 'teacher') link = '/teacher/';
      // 学校管理者
      else link = '/?tab=online';
      if (path[3] && path[3] !== '') {
        list.push({ label: 'オンライン健康相談', link });
      }
    }

    if (args.route.fullPath.includes('reserve/text')) {
      const path = args.route.fullPath.split('/');
      let link = '';
      // 医師・心理士
      if (path[1] === 'counselor') link = '/counselor/?tab=text';
      // 事務局
      else if (path[1] === 'operator') link = '/operator/?tab=text';
      // 利用者
      else if (path[1] === 'teacher') link = '/teacher/';
      // 学校管理者
      else link = '/?tab=text';
      if (path[3] && path[3] !== '') {
        list.push({ label: 'テキスト健康相談', link });
      }
    }

    if (args.route.fullPath.includes('reserve/virtual')) {
      const path = args.route.fullPath.split('/');
      let link = '';
      // 医師・心理士
      if (path[1] === 'counselor') link = '/counselor/?tab=virtual';
      // 事務局
      else if (path[1] === 'operator') link = '/operator/?tab=virtual';
      // 利用者
      else if (path[1] === 'teacher') link = '/teacher/';
      // 学校管理者
      else link = '/?tab=virtual';
      if (path[3] && path[3] !== '') {
        list.push({ label: 'バーチャル健康相談', link });
      }
    }

    if (args.route.fullPath.includes('tab=')) {
      const path = args.route.fullPath.replace('/', '').split('?');
      list.push({ tab: path[1].replace('tab=', '') });
    }

    let metaTitle = args.route.meta.title;
    if (args.isCompany && metaTitle) {
      if (metaTitle.includes('学校だより')) {
        metaTitle = metaTitle.replace(/学校だより/g, '企業レター');
      } else if (metaTitle.includes('学校')) {
        metaTitle = metaTitle.replace(/学校/g, '企業');
      } else if (metaTitle.includes('教育機関')) {
        metaTitle = metaTitle.replace(/教育機関/g, '導入企業');
      }
    }

    const data = {
      label: metaTitle,
      link: args.route.fullPath,
    };
    if (data.label) list.push(data);

    commit('setBreadCrumbs', list);
  },

  addSingleName({ commit }, args) {
    const data = {
      label: args.label,
      link: args.link,
    };

    commit('addBreadCrumb', data);
  },
};


const mutations = {
  setBreadCrumbs(state, list) {
    state.list = list;
  },
  addBreadCrumb(state, args) {
    state.list.push(args);
  },
};


const state = {
  list: [],
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
};

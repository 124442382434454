<template>
  <div class="form-contents" :class="$style.wrap">
    <dl
      :class="$style.label_wrap"
      class="form-group"
      v-for="(item, i) in items"
      v-bind:key="i">
      <dt class="label" :class="{ required: item.required }">
        <label
          :class="$style.label"
          class="form-label"
          v-bind:for="item.name">{{ item.label }}</label>
      </dt>
      <dd
        class="detail"
        :class="$style.content">
        <div class="form-parts">
          <input
            :class="$style.input"
            :id="item.name"
            :type="item.type"
            :name="item.name"
            :disabled="isConfirm"
            :placeholder="item.placeholder"
            @input="invalidsCheck(item.name, $v[item.name].$invalid)"
            v-model.trim="$v[item.name].$model"
            @blur="addPref(item.name)"
            v-if="(item.type === 'text' || item.type === 'email')
              && $v[item.name]">

          <input
            :class="$style.input"
            :id="item.name"
            :type="item.type"
            :name="item.name"
            :disabled="isConfirm"
            :placeholder="item.placeholder"
            @input="sendValue"
            v-model="this[item.name]"
            v-if="item.type === 'text' && !$v[item.name]">

          <select
            :class="$style.select"
            :id="item.name"
            :name="item.name"
            :disabled="isConfirm"
            @change="invalidsCheck(item.name, $v[item.name].$invalid)"
            v-model.trim="$v[item.name].$model"
            v-if="item.type === 'select'">
            <option
              v-for="option in item.options"
              :key="option.value"
              :value="option.value"
              :selected="option.value === user[item.name]">{{ option.label }}</option>
          </select>
        </div>
        <p
          :class="$style.assistant"
          class="form-assistance"
          v-if="item.comment && !isConfirm"
          v-html="item.comment"></p>
        <p
          class="form-text text-danger"
          v-if="$v[item.name]
            && $v[item.name].$dirty
            && $v[item.name].email
            && $v[item.name].email.$invalid">正しいメールアドレスの形式で入力してください</p>
        <p
          class="form-text text-danger"
          v-if="$v[item.name]
            && $v[item.name].$dirty
            && $v[item.name].required
            && $v[item.name].required.$invalid">「{{ item.label }}」は必須項目です</p>
        <p
          class="form-text text-danger"
          v-if="$v[item.name]
            && $v[item.name].$dirty
            && $v[item.name].integer
            && $v[item.name].integer.$invalid">「{{ item.label }}」はハイフンなしで半角数値を入力してください</p>
      </dd>
    </dl>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, integer } from '@vuelidate/validators';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'form-address',
  mixins: [cf],
  props: ['items', 'isConfirm'],
  data() {
    return {
      invalids: {
        zip: true,
        pref: true,
        city: true,
      },
      // vuelidateを使用しない項目
      building: null,
      // 住所の初期値をユーザー情報からセットしないページ
      notSetPage: ['regist-user', 'regist-school', 'teacher/school'],
    };
  },
  created() {
    if (this.user.email) {
      this.setAddressData();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') this.setAddressData();
      });
    }
  },
  setup() {
    const zip = ref('');
    const pref = ref('');
    const city = ref('');

    const rules = {
      zip: { required, integer },
      pref: { required },
      city: { required },
    };

    const $v = useVuelidate(rules, {
      zip,
      pref,
      city,
    });
    return {
      zip,
      pref,
      city,
      $v,
    };
  },
  computed: {
    ...mapState(['user', 'page']),
  },
  methods: {
    async addPref(fieldName) {
      if (fieldName !== 'zip') return;

      this.axios({
        method: 'GET',
        url: '/v1/address/get/fromZip',
        params: { zip: this.zip },
      })
        .then((response) => {
          const data = response.data.data;
          if (data.pref && data.address) {
            this.pref = data.pref;
            this.city = data.address;
          } else {
            this.pref = null;
            this.city = null;
          }
          // 郵便番号は合併などで正しいが反映がされていない場合を考慮
          this.checkInvalids();
          this.sendValue();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          alert('郵便番号を正しく入力してください\n※半角・ハイフンなし(7桁)');
          this.pref = null;
          this.city = null;
        });
    },
    /** this.user.addressに値があればセット */
    setAddressData() {
      let setAddress = true;

      this.notSetPage.forEach((row) => {
        if (this.$route.path.includes(row)) {
          setAddress = false;
        }
      });

      if (this.user.address && setAddress) {
        const updateKeys = [];
        this.items.forEach((item) => { updateKeys.push(item.name); });

        const address = this.user.address;
        const keys = Object.keys(address);
        keys.forEach((key) => {
          if (updateKeys.includes(key)) {
            this[key] = address[key];
            if (address[key]) this.invalids[key] = false;
          }
        });

        this.sendValue();
      } else {
        this.sendValue();
      }
    },

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
      this.sendValue();
    },

    /** 現状の入力状態でinvalidsをチェック */
    checkInvalids() {
      const addressColumn = Object.keys(this.invalids);
      addressColumn.forEach((key) => {
        if (this[key]) this.invalids[key] = false;
        else this.invalids[key] = true;
      });
    },

    /** 親へデータを渡す */
    sendValue() {
      const keys = Object.keys(this.invalids);
      const bools = [];
      keys.some((key) => { bools.push(this.invalids[key]); return false; });

      // サブミット可否
      const readySubmit = !bools.includes(true);

      // データの受け渡し
      const data = {
        readySubmit,
        address: {
          zip: this.zip,
          pref: this.pref,
          city: this.city,
          address: this.address,
          building: this.building,
        },
      };
      this.$emit('sendValue', data);
    },
    setCustomerData(data) {
      const updateKeys = [];
      this.items.forEach((item) => { updateKeys.push(item.name); });

      const address = data;
      const keys = Object.keys(address);
      keys.forEach((key) => {
        if (updateKeys.includes(key)) {
          this[key] = address[key];
          if (address[key]) {
            this.invalids[key] = false;
          }
        }
      });
      this.sendValue();
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  dl {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}
.label {
  font-size: 14px;
  &_wrap {
    margin: 0 0;
  }
}
.content {
  margin-left: 0;
}
.input {
  width: 100%;
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }

  &:disabled {
    background-color: transparent;
    color: black;
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }
  }
}
.select {
  padding: 13px;
  border: none;
  border-radius: 8px;
  background-color: var(--gray-sub);
  appearance: none;
  &:disabled {
    background-color: transparent;
    color: black;
  }
}
.assistant {
  font-size: 12px;
}
.auto_input_btn {
  margin-top: 5px;
}
</style>

<template>
  <div :key="counseling_type">
    <loader-simple :flag="loaderFlag">
      <div v-show="!loaderFlag">
        <ul>

          <stack-item v-if="isVirtual">
            <contents-box
              title="事前準備"
              icon="<i class='fa-regular fa-circle-info'></i>"
            >
              <p>バーチャル健康相談を利用するためには下記の事前準備を済ませておく必要があります。</p>
              <div :class="$style.check_list">
                <div :class="$style.check">Meta Quest</div>
                <div>
                  <p>お手持ちのMeta Quest2またはMeta Quest3を設定してください。</p>
                  <p><i class="fa-regular fa-arrow-right"></i> <a href="https://www.meta.com/ja-jp/help/quest/articles/getting-started/getting-started-with-quest-2/" target="_blank">https://www.meta.com/ja-jp/help/quest/articles/getting-started/getting-started-with-quest-2/</a></p>
                </div>
              </div>
              <div :class="$style.check_list">
                <div :class="$style.check">バーチャルキャスト</div>
                <div>
                  <p>バーチャルキャストのログインIDを取得し、ルームをお気に入り設定してください。</p>
                  <ul>
                    <li>
                      <p><i class="fa-regular fa-arrow-right"></i> アカウント生成: <a href="https://virtualcast.jp/guide/article/beginners-guide/" target="_blank">https://virtualcast.jp/guide/article/beginners-guide/</a></p>
                    </li>
                    <li>
                      <p><i class="fa-regular fa-arrow-right"></i> ルームのお気に入り登録: <a href="https://virtualcast.jp/guide/article/about-room/" target="_blank">https://virtualcast.jp/guide/article/about-room/</a></p>
                    </li>
                  </ul>
                </div>
              </div>
            </contents-box>
          </stack-item>

          <!-- {{ billingType }} -->
          <stack-item v-if="(billingType === 1 && futures.length) || billingType === 2">
            <contents-box
              title="予約"
              :icon="reserveIcon"
              :inner-icon="reserveInnerIcon"
              :iconColor="iconColor"
            >
              <div
                :class="`${$style.flex} ${$style.stretch}`">

                <div :class="$style.cover">
                  <basic-box>
                    <div>次の予約日</div>
                    <div :class="$style.large">
                      <label v-if="futures[0] && futures[0].schedule">{{filterMoment(futures[0].schedule.date, 'YYYY年MM月DD日')}}</label>
                      <label v-else>-</label>
                    </div>
                  </basic-box>
                </div>

                <spacer :x="2" v-if="billingType === 2"/>
                <div
                  :class="$style.cover"
                  v-if="billingType === 2">

                  <basic-box v-if="user.count">
                    <div>残り予約回数</div>
                    <div :class="$style.counts">
                      <div>心理士<span :class="$style.large">{{ user.count.psychologist }}</span>回</div>
                      <div>医師<span :class="$style.large">{{ user.count.doctor }}</span>回</div>
                    </div>
                  </basic-box>
                </div>
              </div>
              <spacer :y="2"/>

              <ul>
                <stack-item v-for="row in futures" :key="row.id">
                  <user-box
                    type="inner"
                    :user="row.toUser"
                    :showAge="false">
                    <template v-slot:btn>
                        <basic-btn
                          :class="$style.cancel"
                          type="bdr"
                          size="sm"
                          tag="button"
                          v-on:click="cancelReserve(row)">予約キャンセル</basic-btn>
                    </template>
                    <template v-slot:main>
                      <spacer :y="2"/>
                      <div :class="$style.check_wrap">
                        <div :class="$style.check_list">
                          <div :class="$style.check">予約日時</div>
                          <div :class="[$style.value, $style.type]">
                            <p>{{ filterMoment(row.schedule.date, 'YYYY年MM月DD日 HH:mm') }}</p>
                          </div>
                        </div>

                        <div :class="$style.check_list">
                          <div :class="$style.check">お悩み</div>
                          <div :class="[$style.value, $style.type]">
                            <p
                              v-for="type in row.type"
                              :key="type">{{ helper.master.labels.texts.type[type] }}<span v-if="row.other_text && type === 6">（{{ row.other_text }}）</span></p>
                          </div>
                        </div>

                        <div :class="$style.check_list">
                          <div :class="$style.check">URL</div>
                          <div :class="[$style.value, $style.type]">
                            <a
                              v-if="isVirtual"
                              :class="$style.link"
                              :href="getVirtualCastMedicalRooomURL(row.toUser.virtualCastProp.virtual_cast_room_key)"
                              target="_blank"
                            >{{ getVirtualCastMedicalRooomURL(row.toUser.virtualCastProp.virtual_cast_room_key) }}</a>
                            <a
                              v-else
                              :class="$style.link"
                              :href="row.meeting.join_url"
                              target="_blank"
                            >{{ row.meeting.join_url }}</a>
                          </div>
                        </div>

                        <div v-if="row.scoreWho5"
                          :class="$style.check_list">
                          <div :class="$style.check">ココモニ</div>
                          <div :class="$style.value">
                            <div :class="$style.who5_top">
                              <p :class="$style.who5_score"><span :class="$style.who5_score_label">WHO-5</span>{{ row.scoreWho5 }}点</p>
                              <div :class="$style.open" @click="changeOpenIds(row.id)">
                                <p v-if="!openIds.includes(row.id)">詳細を表示<i class="fa-solid fa-angles-down"/></p>
                                <p v-else>詳細を非表示<i class="fa-solid fa-angles-up"/></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="openIds.includes(row.id)" :class="$style.who5_detail">
                          <spacer :y="3"/>
                          <ul :class="$style.who5_detail_list">
                            <li v-for="(item, i) in helper.master.who5.items"
                              :key="i">
                                <p :class="$style.who5_label">{{item.label}}</p>
                                <p :class="$style.who5_answer">{{helper.master.who5.labels[row.who5Answer.answers[i]]}}</p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <spacer :y="3"/>

                      <p :class="$style.memo" v-if="row.memos.customer[0]">{{ row.memos.customer[0].memo }}</p>
                    </template>
                  </user-box>
                </stack-item>
              </ul>
            </contents-box>
          </stack-item>
          <spacer :y="4"/>

          <div>
            <contents-box
              :title="isVirtual ? 'バーチャル健康相談' : 'オンライン健康相談'"
              :icon="reserveIcon"
              :inner-icon="reserveInnerIcon"
              :iconColor="iconColor"
            >
              <select-counselor
                :tab="tab"
              />
            </contents-box>
          </div>
        </ul>
      </div>
    </loader-simple>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BasicBox from '@/views/components/BasicBox.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import SelectCounselor from '@/views/components/SelectCounselor.vue';
import Spacer from '@/views/components/Spacer.vue';
import UserBox from '@/views/components/UserBox.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'user-counseling-impl',
  props: [
    'tab', // 'online' || 'virtual'
    'counseling_type', // 1 || 3
  ],
  mixins: [cf],
  components: {
    BasicBtn,
    BasicBox,
    ContentsBox,
    StackItem,
    LoaderSimple,
    Spacer,
    SelectCounselor,
    UserBox,
  },
  data() {
    return {
      flag: {
        future: false,
      },
      openIds: [],
      futures: [],
    };
  },
  created() {
    if (this.targetReserves.future
      || this.user.counseling.texts.progress) {
      this.upSortReserves();
    } else {
      this.$store.subscribe((mutation) => {
        const mutationType = this.isVirtual ? 'user/counseling/putVirtualReserves' : 'user/counseling/putReserves';
        if (mutation.type === mutationType) {
          this.upSortReserves();
        }
      });
    }
  },
  computed: {
    ...mapState(['helper', 'user']),
    isVirtual() {
      return this.tab === 'virtual';
    },
    reserveIcon() {
      return this.isVirtual ? '<i class="fa-solid fa-vr-cardboard"></i>' : '<i class="fa-regular fa-laptop"></i>';
    },
    reserveInnerIcon() {
      return this.isVirtual ? null : '<i class="fa-regular fa-user-headset"></i>';
    },
    iconColor() {
      return this.isVirtual ? 'green' : 'orange';
    },
    targetReserves() {
      return this.isVirtual ? this.user.counseling.virtualReserves : this.user.counseling.reserves;
    },
    hasFuture() {
      let flag = false;
      if (this.user.counseling
      && this.targetReserves
      && this.targetReserves.future
      && this.targetReserves.future.length) {
        flag = true;
      }
      return flag;
    },
    hasHistory() {
      let flag = false;
      if (this.user.counseling
        && this.targetReserves
        && this.targetReserves.history
        && this.targetReserves.history.length) {
        flag = true;
      }
      return flag;
    },
    billingType() {
      let billType = null;
      if (this.user.school[0]
        && this.user.school[0].billing_type) billType = this.user.school[0].billing_type.billing_type;
      return billType;
    },
    loaderFlag() {
      let flag = false;
      if (!this.targetReserves.history) flag = true;
      return flag;
    },
  },
  methods: {
    /** 予約を昇順に並び替え */
    async upSortReserves() {
      if (!this.targetReserves.future.length) return;
      const futures = cloneDeep(this.targetReserves.future);
      this.futures = await cf.upSortReserves(futures);
    },

    cancelReserve(reserve) {
      const args = {
        modalName: 'reserveCancel',
        data: {
          reserve_id: reserve.id,
          counseling_type: this.counseling_type,
          schedule: reserve.schedule,
        },
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: null });
    },
    changeOpenIds(id) {
      if (this.openIds.includes(id)) {
        const idx = this.openIds.indexOf(id);
        this.openIds.splice(idx, 1);
      } else {
        this.openIds.push(id);
      }
    },
  },
};
</script>

<style lang="scss" module>
.flex {
  display: flex;
  &.center {
    align-items: center;
  }
}

.memo {
  @include sm-view {
    font-size: 13px;
  }
}

.cover {
  flex: 1;
  text-align: center;
}

.stretch {
  align-items: stretch;
}

.box {
  height: 100%;
  &_inner {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
}

.line {
  width: 1px;
  height: 100%;
  background-color: rgba(#333, .3);
}

.link {
  font-weight: normal;
}

.main {
  white-space: nowrap;
}

.text {
  font-size: 14px;
}

.large {
  font-size: 50px;
  font-weight: bold;
  margin-right: 5px;

  @include md-view {
    font-size: 30px;
  }
  @include sm-view {
    font-size: 28px;
  }
  @include xs-view {
    font-size: 20px;
  }
}

.date {
  font-size: 10px;
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include sm-view {
    display: block;
    .cancel {
      margin-top: 10px;
    }
  }
}

.counselor {
  display: flex;
  margin-right: 10px;
  @include sm-view {
    margin-bottom: 10px;
  }
  .img {
    margin-right: 10px;
  }
}

.position {
  font-size: 12px;
}

.labels {
  display: flex;
  align-items: center;
  @include sm-view {
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .md {
    width: 250px;
  }
  .sm {
    width: 130px;
  }

}

.url {
  a {
    color: var(--black);
    text-decoration: underline;
  }
}
.slider {
  width: 100%;
  height: 510px;
  border-radius: 10px;
  background-color: var(--primary-color);
}
.status {
  font-size: 12px;
}
.check_wrap {
  position: relative;
  flex-wrap: wrap;
  align-items: center;

  @include sm-view {
     flex-direction: column;
     align-items: flex-start;
  }
}

.check_list {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  &:not(:first-child) {
    margin-top: 15px;
  }
  @include sm-view {
    font-size: 13px;
    display: block;
    text-align: left;
  }
  @include xs-view {
    display: block;
    text-align: left;
  }
}

.check {
  background-color: var(--gray-sub);
  font-weight: bold;
  border-radius: 4px;
  padding: 4px 0;
  width: 140px;
  text-align: center;
  margin-right: 12px;
  @include sm-view {
    width: 100%;
    text-align: left;
    padding-left: 8px;
  }
}

.value {
  flex: 1;
  margin-top: 4px;
  font-weight: bold;

  &.type {
    display: flex;
    flex-wrap: wrap;
    p {
      white-space: nowrap;
      &:not(:last-child) {
        &::after {
          margin: 0 5px;
          content: '/';

        }
      }
      span {
        white-space: pre-wrap;
      }
    }
  }

  .who5_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .who5_score {
    margin-right: 8px;
    &_label {
      margin-right: 12px;
    }
  }
}
.open {
  font-size: 12px;
  font-weight: normal;
  color: var(--gray-side);
  cursor: pointer;
  i {
    margin-left: 4px;
  }
}
.who5_detail {
  &_list {
    li {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:not(:first-child) {
        margin-top: 8px;
      }

      .who5_answer {
        font-weight: bold;
      }

      @include sm-view {
        display: block;
        font-size: 13px;
      }
    }
  }
}
.counts {
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    span {
      margin-left: 5px;
    }
    &:not(:first-child) {
      margin-left: 1em;
    }
  }
  @include sm-view {
    display: block;
  }
}
</style>

<style lang="scss" scoped>
.cancel {
  color: red;
}
</style>

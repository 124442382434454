<template>
  <div>
    <contents-box v-if="graphData.meter.energy">
      <div :class="$style.graph_flex">
        <div :class="$style.graph_flex_left">
          <h2 :class="$style.energy_title">エナジーポイント</h2>
          <!-- <p :class="$style.energy_title_detail"> 今のあなたの元気度。この元気度をもとにパワーメータが算出されます。1日1回の音声入力を習慣づけよう!</p> -->
          <span :class="$style.larger">{{ filterMoment(graphData.period.end, 'M.D') }}</span>
          <span :class="$style.smaller">（{{ helper.master.labels.weekdays[filterDay(graphData.period.end)] }}）</span>
          <p :class="$style.meter_info">今のあなたの元気度。この元気度をもとにパワーメータが算出されます。1日1回の音声入力を習慣づけよう!</p>
        </div>
        <div :class="$style.graph_flex_right">
          <div :class="$style.sideBy">
            <div :class="$style.energyflex">
              <div v-for="i in 10" :key="i">
                <span :class="$style.energypoint"
                  :style="{height: (i * 5) + 30 + 'px',
                  backgroundColor: (graphData.meter.energy >= i?'var(--orange-main)':'silver'),
                  opacity: (graphData.meter.energy >= i? 0.5 + (i * 0.1) : 1),
                  marginTop: (30 - (i * 5)) + 'px'}"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </contents-box>
    <spacer :y="3" v-if="graphData.meter.energy" />

    <contents-box v-if="graphData.meter.power">
      <div :class="$style.graph_flex">
        <div :class="$style.graph_flex_left">
          <h2 :class="$style.power_title">パワーメータ</h2>
          <p :class="$style.meter_info">エナジーポイント全測定期間の傾向値。矢印はこころの状態の 「下向き」「平行/同じ」「上向き」傾向を表します。</p>
        </div>
        <div :class="$style.graph_flex_right">
          <contents-box :class="$style.finer_box">
            <div :class="$style.condition_title">Condition</div>
            <div :class="$style.graph_flex">
              <div :class="$style.condition">
                <div>
                  <p :class="$style.condition_value">{{ conditionValue.text }}</p>
                </div>
              </div>
              <div :class="$style.condition_icon">
                <img :src="`/img/default/${conditionValue.img}`" :alt="`${conditionValue.alt}`">
              </div>
            </div>
          </contents-box>
        </div>
      </div>
      <spacer :y="3"/>
      <div>
        <div :class="$style.gradientbar">
          <div :class="$style.gradientbar_scale">
          </div>
          <span :class="$style.plot" :style="{marginLeft: (graphData.meter.power * 10) + '%'}"></span>
        </div>
      </div>
    </contents-box>
    <spacer :y="5" v-if="graphData.meter.power" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Spacer from '@/views/components/Spacer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'Meter',
  mixins: [cf],
  components: {
    Spacer,
    ContentsBox,
  },
  props: {
    graphData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  created() {
  },
  computed: {
    ...mapState(['user', 'helper']),
    conditionValue() {
      const condition = {};
      if (this.graphData.meter.power >= 7) {
        condition.text = 'High';
        condition.img = 'arrow_up.png';
        condition.alt = '上向き矢印';
      } else if (this.graphData.meter.power < 7 && this.graphData.meter.power >= 3) {
        condition.text = 'Middle';
        condition.img = 'arrow_flat.png';
        condition.alt = '横ばい矢印';
      } else {
        condition.text = 'Low';
        condition.img = 'arrow_down.png';
        condition.alt = '下向き矢印';
      }
      return condition;
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" module>
$size: 30;
.sideBy {
  display: flex;
  justify-content: center;
  margin-top: 10%;
}
.graph {
  height: 400px;
}
.graph_flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
  &_left {
    text-align: center;
    padding: 20px;
    font-size: smaller;
    h2 {
      font-size: 2em;
    }
  }
  &_right {
    width: 50%;
    text-align: center;
    @media screen and (max-width: 500px) {
      margin: 0 auto;
      width: 80%;
    }
  }
  .larger {
    font-size: 1.5em;
  }
  .smaller {
    font-size: 0.8em
  }
  .finer_box {
    padding: 0 auto;
  }
  @media screen and (max-width: 610px) {
    &_left {
      h2 {
        font-size: 1.2em;
      }
    }
  }
  @media screen and (max-width: 500px) {
    display: block;
    &_left {
      h2 {
        font-size: 1.2em;
      }
    }
  }
}
.meter_info {
  padding-top: 10px;
  text-align: left;
  width: 70%;
  margin: 0 auto;
  @include sm-view {
    width: 100%;
  }
}
.condition {
  text-align: center;
  &_title {
    margin-bottom: 10px;
    background-color: black;
    color: white;
    border-radius: 20px;
    padding: 0 10px;
  }
  &_value {
    padding-top: 0.5em;
    font-size: 1.3em;
    font-weight: bold;
  }
  &_num {
    font-size: 5em;
    padding: 0 20px;
  }
  &_icon {
    width: 130px;
    @media screen and (max-width: 1080px) {
      scale: .9;
    }
    @media screen and (max-width: 880px) {
      scale: .8;
    }
    @media screen and (max-width: 500px) {
      margin: 0 auto;
    }
  }
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.gradientbar {
  position: relative;
  width: 100%;
  height: $size * 1px;
  border-radius: $size/2 *  1px;
  background: linear-gradient(to right, rgb(133, 194, 249), rgb(249, 255, 159), rgb(255, 149, 164));
  display: block;
  &_scale {
    width: 98%;
    position: absolute;
    left: 1%;
    display: flex;
    justify-content: space-between;
    color: white;
    font-weight: bold;
  }
}
.plot {
  width: $size * 1px;
  height: $size * 1px;
  border-radius: 50%;
  border: solid 3px white;
  display: block;
  background-color: rgba(0, 0, 0, 0);
}
.energyflex {
  display: flex;
}
.energypoint {
  margin-left: 2px;
  width: 18px;
  border-radius: 9px;
  display: inline-block;
  background-color: silver;

  @include md-view {
    margin-left: 3px;
    width: 18px;
    border-radius: 9px;
    display: inline-block;
    background-color: silver;
  }
}
.verticalText {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  text-orientation: upright;
  p {
    font-size: 1.8em;
    font-weight: bold;
    margin-top: -40%;
  }
}
.energy_title {
  position: relative;
}
.energy_title_detail {
  display: none;
  width: 300px;
  position: absolute;
  top: -22%;
  left: 3%;
  padding: 16px;
  border-radius: 5px;
  background: var(--primary-color);
  color: #fff;
  text-align: left;
  @media screen and (max-width: 380px) {
    transform: translate(-5%, 0);
  }
}
.energy_title_detail::after {
  position: absolute;
  width: 0;
  height: 0;
  right: 45%;
  bottom: -19px;
  margin-left: 10px;
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: var(--primary-color);
  border-width: 10px;
  pointer-events: none;
  content: " ";
}
.energy_title:hover + .energy_title_detail {
  display: block;
}
.power_title_detail {
  display: none;
  width: 300px;
  position: absolute;
  transform: translate(0, -150%);
  left: 3%;
  padding: 16px;
  border-radius: 5px;
  background: var(--primary-color);
  color: #fff;
  text-align: left;
  z-index: 10;
  @media screen and (max-width: 380px) {
    transform: translate(-5%, -140%);
  }
}
.power_title_detail::after {
  position: absolute;
  width: 0;
  height: 0;
  right: 45%;
  bottom: -19px;
  margin-left: 10px;
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: var(--primary-color);
  border-width: 10px;
  pointer-events: none;
  content: " ";
}
.power_title:hover + .power_title_detail {
  display: block;
}
</style>

<template>
  <div>
    <div :class="$style.top">
      <i
        :class="$style.top_icon"
        class="fa-regular fa-comment-lines"/>
      <div :class="$style.top_text"><span :class="$style.nowrap">事務局個別メッセージ詳細</span></div>
    </div>

    <div v-if="flag.valid">
      <spacer :y="10"/>
      <h3>URLが誤っています。ホームから再度アクセスし直してください。</h3>
    </div>

    <loader-simple :flag="flag.loader">
      <spacer :y="4"/>
      <contents-box v-if="!flag.loader && !flag.valid" :title="`メッセージ登録 ${data && data.fromUser && user && user.account_type === 31 ? 'to ' + data.fromUser.username : ''}`">
        <div>
          <textarea
            :class="$style.textarea"
            :disabled="flag.isConfirm"
            placeholder="内容を入力してください"
            v-model="message">
          </textarea>
        </div>
        <spacer :y="3"/>

        <btn-container
          v-if="!flag.isConfirm">
          <basic-btn
            tag="button"
            v-on:click="submit">登録する</basic-btn>
          <spacer :x="2"/>
        </btn-container>
      </contents-box>
      <spacer :y="4"/>
      <contents-box v-if="!flag.loader && !flag.valid && data && data.messages.length">
        <ul>
          <div :class="$style.comment" v-for="(chat, i) in data.messages" :key="i">
            <stack-item
              :class="chat.user.account_type === 31 ? $style.q : $style.a">
              <div :class="chat.user.account_type === 31 ? $style.question : $style.answer">
                <div :class="$style.name">{{ chat.user.account_type === 31 ? '事務局' : chat.user.username }} [{{ filterMoment(chat.updated_at, 'YYYY.MM.DD HH:mm') }}]</div>
                <spacer :y="1"/>
                <div class="n2br">
                  <p v-html="chat.message"></p>
                </div>
              </div>
            </stack-item>
          </div>
        </ul>
      </contents-box>

    <spacer :y="4"/>
    </loader-simple>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import Spacer from '@/views/components/Spacer.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'message-detail',
  mixins: [cf],
  components: {
    ContentsBox,
    StackItem,
    Spacer,
    LoaderSimple,
    BasicBtn,
    BtnContainer,
  },
  data() {
    return {
      id: null,
      data: null,
      message: null,
      flag: {
        loader: true,
        valid: false,
      },
    };
  },
  created() {
    this.id = cf.convert2Num(this.$route.params.slug);
    if (this.user.email) {
      this.getDetail();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.getDetail();
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
    getDetail() {
      this.axios({
        method: 'GET',
        url: '/v1/messagegroup/get/detail',
        params: {
          id: this.id,
          isOperator: 1,
        },
      })
        .then((response) => {
          this.data = response.data.detail;
          this.flag.loader = false;
          if (!this.data || (this.data.user_id !== this.user.id && this.data.target_user_id !== this.user.id)) {
            this.flag.valid = true;
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    /**
     * お知らせ送信
     */
    submit(e) {
      e.preventDefault();
      if (!this.message) {
        return alert('メッセージが入力されていません。');
      }
      const submitConfirm = confirm('返信は取り消せません。よろしいですか？');
      if (!submitConfirm) return;

      this.showLoading();

      // 必須項目
      const data = {
        group_id: this.data.id,
        messageData: {
          user_id: this.user.id,
          message: this.message,
        },
      };

      this.axios({
        method: 'POST',
        url: '/v1/messageGroup/set/register',
        data,
      })
        .then(async () => {
          await this.operatorMessageContact(this.data.id);
          alert('メッセージを登録しました');
          // 初期化
          this.message = null;
          this.hideLoading();
          this.flag.loader = true;
          this.getDetail();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          this.hideLoading();
        });
    },

    /** 該当者に新規メッセージの連絡 */
    operatorMessageContact(groupId) {
      // 送信する場合は送り主が事務局で、送り先が学校管理者または医師心理士の場合
      // （240205 利用者の除外を廃止）
      // if (this.user.account_type !== 31 || !([11, 12, 21, 22].includes(this.data.fromUser.account_type))) {
      //   return;
      // }
      // 240215 送信者が事務局の場合のみメッセージ連絡を発動
      if (this.user.account_type !== 31) return;

      // 対象者へ連絡
      this.axios({
        method: 'POST',
        url: '/v1/contact/regist/operatorMessage',
        data: { group_id: groupId },
      })
        .then((response) => {
          if (this.helper.env.name === 'local') {
            console.log(response.data);
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module>
.top {
  background-color: var(--blue-main);
  display: flex;
  justify-content: center;
  padding: 90px;
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  margin: 0 calc((100vw - 100%) / (-2));
  position: relative;

  &_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .2;
    font-size: 130px;

    &_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -70%);
      font-size: 50px;
    }
  }

  &_text {
    text-align: center;
    .nowrap {
      white-space: nowrap;
    }
    @include sm-view {
      font-size: 30px;
    }
    @include xs-view {
      font-size: 20px;
    }
  }
}

.textarea {
  padding: 20px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  width: 100%;
  border-radius: 8px;
  resize: vertical;
  min-height: 120px;
}

.info {
  font-size: 14px;
}
.smlabel {
  width: 150px;
}
.flex {
  display: flex;
  align-items: flex-start;

  &.center {
    align-items: center;
  }

  &.check {
    justify-content: space-between;
  }
}
.content {
  flex: 1;
}

.bold {
  font-weight: bold;
}
.label {
  @include sm-view {
    width: 180px;
  }
}
.item {
  &:not(:first-child) {
    margin-left: 10px;
  }
}
.space {
  margin-top: 8px;
  @include sm-view {
    margin-top: 0;
  }
}

.who5item {
  display: flex;
  justify-content: space-between;
  font-size: 12px;

  @include sm-view {
    flex-direction: column;
  }
}

.who5itemA {
  padding-left: var(--spacer-base);
  font-weight: bold;
  color: #444;
}

.comment {
  margin-bottom: 24px;
  &:not(:first-child) >li {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -24px;
      left: 30px;
      width: 3px;
      height: 24px;
    }

    &.q {
      &::before {
        background-color: var(--blue-main);
      }
    }
    &.a {
      &::before {
        background-color: var(--orange-main);
      }
    }
  }
  .name {
    font-size: 12px;
  }
}

.question, .answer {
  padding: 15px;
  border-radius: 8px;
}

.question {
  background-color: rgba(var(--blue-main-rgb), 0.2);
}
.answer {
  background-color: rgba(var(--orange-main-rgb), .2);
}
.message {
  margin-bottom: 24px;
}
</style>

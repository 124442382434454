<template>
  <div>
    <spacer :y="5"/>
    <contents-box title="プロフィール編集">
      <form v-on:submit.prevent="updateFlagConfirm">
        <div :class="$style.wrapper">
          <div :class="$style.row">
            <div :class="$style.label_wrap">
              <p :class="$style.label">プロフィールアイコン</p>
            </div>
            <div :class="$style.detail"
              v-if="flag.showFileInput">
              <div class="form-parts-file">
                <input
                  :class="$style.fileinput"
                  id="file"
                  type="file"
                  name="file"
                  accept=".jpg, .jpeg, .png, .gif"
                  v-if="validate.view"
                  v-bind:disabled="flag.confirm"
                  v-on:change="updateFile">
                <label for="file" :class="$style.filelabel">ファイルを選択</label>
                <spacer :y="1"/>
                <p :class="$style.assistant"
                  >推奨サイズ：110 x 110<br>ファイルタイプ：.jpg, .jpeg, .png, .gif</p>
              </div>
            </div>
            <div :class="$style.detail"
              v-if="!flag.showFileInput">
              <div
                :class="$style.icon_wrap"
                v-if="flag.isSaved">
                <!-- <div
                  :class="$style.icon"
                  v-bind:style="`background-image: url(${getMyIconUrl(user)})`"></div> -->
                <img :src="getMyIconUrl(user)" :alt="user.username">
              </div>
              <div
                :class="$style.icon_wrap"
                v-if="!flag.isSaved">
                <div
                  :class="$style.icon"
                  v-bind:style="`background-image: url(${previewIconUrl})`"></div>
              </div>
              <spacer :y="2"/>
              <button
                type="button"
                :class="$style.filelabel"
                v-if="flag.isSaved"
                v-on:click="deleteURL">画像削除・変更</button>
              <button
                type="button"
                :class="$style.filelabel"
                v-if="!flag.isSaved"
                v-on:click="deletePreviewImg">画像削除・変更</button>
            </div>
          </div>

          <div :class="$style.row"
            class="form-group"
            v-for="(item, i) in formItems"
            v-bind:key="i">
            <div :class="$style.label_wrap">
              <p :class="$style.label">{{ item.label }}</p>
            </div>
            <div :class="$style.detail">
              <div class="form-parts">
                <input
                  :class="$style.text"
                  :id="item.name"
                  :type="item.type"
                  :name="item.name"
                  :disabled="flag.confirm"
                  v-model="item.value"
                  v-if="item.type === 'text'"
                  @change="updateValue($event, i)">

                <textarea
                  :class="$style.textarea"
                  :id="item.name"
                  :name="item.name"
                  :disabled="flag.confirm"
                  v-model="item.value"
                  rows="5"
                  v-if="item.type === 'textarea'"
                  @change="updateValue($event, i)"></textarea>

                <select
                  :id="item.name"
                  :name="item.name"
                  :disabled="flag.confirm"
                  :class="$style.select"
                  v-model="item.value"
                  v-if="item.type === 'select'">
                  <option value="0">選択してください</option>
                  <option
                    v-for="option in item.options"
                    :key="option.value"
                    :value="option.value"
                    :selected="option.value === user[item.name]">{{ option.label }}</option>
                </select>
              </div>
              <p
                class="form-assistance"
                v-if="item.comment"
                v-html="item.comment"></p>
            </div>
          </div>
        </div>


        <div
          class="form-contents"
          v-if="formItems.length">
          <dl>
            <dt class="label">
            </dt>
            <dd
              class="detail">
            </dd>
          </dl>
        </div>


        <div :class="$style.flex">
          <button
            v-if="!flag.confirm"
            type="submit"
            :class="$style.btn"
            id="btn_check"
            >確認</button>
          <spacer v-if="!flag.confirm" :x="2"/>
          <router-link
            v-if="!flag.confirm"
            to="/account/"
            :class="`${$style.btn} ${$style.bdr}`">戻る</router-link>
          <div
            v-if="flag.confirm"
            :class="$style.btn"
            id="btn_submit"
            v-on:click="submit">登録</div>
          <spacer v-if="flag.confirm" :x="2"/>
          <div
            v-if="flag.confirm"
            :class="`${$style.btn} ${$style.bdr}`"
            id="btn_back"
            v-on:click="updateFlagConfirm">編集画面に戻る</div>
        </div>
      </form>

    </contents-box>
    <div id="edit-top" class="container">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'edit-profile',
  mixins: [cf],
  components: {
    ContentsBox,
    Spacer,
  },
  data() {
    return {
      file: null,
      previewIconUrl: null,
      validate: {
        size: false,
        view: true,
      },
      flag: {
        confirm: false,
        showFileInput: true,
        isSaved: true,
      },
      formItems: [
        // {
        //   name: 'username',
        //   type: 'text',
        //   label: 'お名前',
        //   value: null,
        //   comment: '',
        // },
        {
          name: 'tel',
          type: 'text',
          label: '電話番号',
          value: null,
          comment: '',
        },
        {
          name: 'kana',
          type: 'text',
          label: 'フリガナ',
          value: null,
          comment: '',
        },
        // {
        //   name: 'nickname',
        //   type: 'text',
        //   label: 'ニックネーム',
        //   value: null,
        //   comment: '',
        // },
        // {
        //   name: 'comment',
        //   type: 'textarea',
        //   label: 'ひとこと',
        //   value: null,
        // },
        {
          name: 'gender',
          type: 'select',
          label: '性別',
          value: null,
          options: [
            {
              value: 1,
              label: '男性',
            },
            {
              value: 2,
              label: '女性',
            },
          ],
        },
      ],
    };
  },
  created() {
    if (this.user.email) {
      this.setUserData();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          console.log(this.user);
          this.setUserData();
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** state.userの値をフォームにセット */
    setUserData() {
      const clone = cloneDeep(this.user);
      const keys = Object.keys(clone);
      // フォーム項目に該当する値のみを抽出
      this.formItems.forEach((item, i) => {
        if (keys.includes(item.name)) {
          this.formItems[i].value = clone[item.name];
        }
      });

      // アイコンが存在する
      if (clone.urls.icon.length
        && clone.urls.icon[0].url) {
        this.flag.showFileInput = false;
      }
    },

    /** 確認と編集の切り替え */
    updateFlagConfirm(e) {
      const id = e.currentTarget.id;
      const next = id && id === 'btn_back';
      this.flag.confirm = !next;
      if (next) {
        this.$scrollTo('#edit-top');
      }
    },

    /** プロフィールテキストの更新 */
    updateValue(e, index) {
      const value = e.currentTarget.value;
      this.formItems[index].value = value;
    },

    /** 画像の更新 */
    updateFile(e) {
      const files = e.target.files || e.dataTransfer.files;

      // files.lengthが0の場合はキャンセル時
      if (!files.length) {
        this.file = null;
        return;
      }

      // ファイルのサイズチェック（上限2mb）
      const limit = 1024 * 1024 * 2;
      if (files[0].size > limit) {
        this.validate.size = true;
        // ファイルのリセットは非表示→表示で生成しなおす
        this.validate.view = false;
        this.$nextTick(() => {
          this.validate.view = true;
        });
        return;
      }

      // ファイルを格納
      this.file = files[0];
      this.validate.size = false;

      // プレビュー表示
      this.previewIcon();
    },

    previewIcon() {
      const path = window.URL.createObjectURL(this.file);

      this.previewIconUrl = path;
      this.flag.isSaved = false;
      this.flag.showFileInput = false;
    },

    /**
     * 確認後のサブミット
     * ファイルが存在する場合は更新・登録
     * ファイル削除時にはnullを登録
     * それ以外はユーザ情報のみ更新
     */
    submit() {
      this.showLoading();

      if (this.file) {
        // 画像更新・登録
        this.uploadFile();
      } else if (this.user.urls.icon.length
        && !this.user.urls.icon[0].url) {
        // 画像URLをnullに更新
        this.updateUrl({ uploaded: { s3Path: null } });
      } else {
        // ユーザ情報だけ更新
        this.updateBaseData();
      }
    },

    /** ファイルのアップロード */
    uploadFile() {
      // 保存時formDataに落とし込む
      const form = new FormData();
      form.append('file', this.file);

      this.axios({
        method: 'POST',
        url: '/v1/user/set/uploadfile',
        data: form,
        params: {
          id: this.user.id,
          environment: this.helper.env.name,
        },
      })
        .then((response) => {
          // URLの紐付けを保存
          this.updateUrl(response.data.data);
        })
        .catch((error) => {
          alert('プロフィール画像のアップロードに失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.hideLoading();
        });
    },

    /** URLの紐付け更新 */
    updateUrl(uploadData) {
      // 既存の紐付けがあるか新規登録か
      const id = this.user.urls.icon.length
        ? this.user.urls.icon[0].id
        : null;

      const data = {
        id,
        user_id: this.user.id,
        url: uploadData.uploaded.s3Path,
        type: 2, // アイコンは2
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/updateUrl',
        data,
      })
        .then(() => {
          // 初期化
          this.file = null;
          // アイコンの表示非表示
          const next = uploadData.uploaded.s3Path === null;
          this.flag.showFileInput = next;
        })
        .catch((error) => {
          alert('プロフィール画像の更新に失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.updateBaseData();
        });
    },

    /** 基本データの更新 */
    updateBaseData() {
      const data = { id: this.user.id };
      this.formItems.forEach((item) => {
        data[item.name] = item.value;
      });

      this.axios({
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then(() => {
          alert('プロフィールを更新しました。');
          this.flag.confirm = false;
          this.$store.dispatch('user/update', null, { root: true });
        })
        .catch((error) => {
          alert('プロフィールの更新に失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    /** 画像URL削除 */
    deleteURL() {
      this.user.urls.icon[0].url = null;
      // input[type=file]の表示
      this.flag.showFileInput = true;
    },

    /** プレビュー画像URL削除 */
    deletePreviewImg() {
      this.file = null;
      this.previewIconUrl = null;
      // input[type=file]の表示
      this.flag.showFileInput = true;
    },
  },
};
</script>

<style lang="scss" module>
.row {
  padding: 24px 0;
  border-top: 1px solid var(--gray-main);
}
.label {
  border-radius: 4px;
  margin-right: 4px;
  font-weight: bold;
  display: inline-block;
  &_wrap {
    display: flex;
    align-items: baseline;
  }
}
.flex {
  display: flex;
  align-items: center;
}
.fileinput {
  display: none;
}
.filelabel {
  font-size: 12px;
  padding: 8px 20px;
  border-radius: 20px;
  background-color: var(--gray-main);
  // background-color: #666;
  // color: #fff;
  display: inline-block;
  border: none;
}
.icon {
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-position: center;
  &_wrap {
    overflow: hidden;
    border-radius: 50%;
    width: 100px;
  }
}
.text, .select, .textarea {
  &:disabled {
    color: black;
    background-color: transparent;
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }
  }
}
.assistant {
  font-size: 12px;
}
.space {
  padding-top: 6px;
}
.text {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
}
.textarea {
  padding: 13px;
  background-color: var(--gray-sub);
  width: 100%;
  border-radius: 8px;
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
}
.radio {
  padding-top: 8px;
  display: flex;

  li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
.select {
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;
  appearance: none;

  &_wrap {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.btn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
  &.bdr {
    background-color: transparent;
    color: var(--black);
    border: 1px solid var(--black);
  }
}
</style>

<template>
  <div>
    <spacer :y="5"/>
    <ul>
      <stack-item>
        <contents-box title="オンライン・バーチャル健康相談 シフト登録">
          <div :class="$style.calendar">
            <Calendar
              :schedule="calendarSchedule"
              v-on:sendDate="receiveDate"/>
          </div>
          <spacer :y="1"/>
          <p :class="$style.assistant">※日付を選択すると詳細が確認できます。</p>
          <div v-if="flag.showAll">
            <spacer :y="2"/>
            <ul>
              <stack-item v-for="(date, k) in selectHours()" :key="k">
                <contents-box :title="formatTimestamp(k, `YYYY年MM月DD日`)" type="inner">
                  <spacer :y="1"/>
                  <ul>
                    <stack-item v-for="row in date" :key="row" :space="2">
                      <contents-box type="inner">
                        <div :class="$style.wrap">
                          <!-- <div :class="$style.left">
                            <label-box :label="`${row.date}:00`" size="sm" :class="$style.timeLabel"/>
                            <div :class="$style.img">
                              <round-img width="45px" :src="getMyIconUrl(row.counselor)" />
                            </div>
                            <div :class="$style.text">
                              <p :class="$style.name">{{ row.counselor.username }}</p>
                              <p :class="$style.position" v-if="helper.master">{{ helper.master.labels.users.account_type[row.counselor.account_type] }}</p>
                            </div>
                          </div> -->
                          <div :class="$style.left">
                            <label-box :label="`${row.date}:00`" size="sm" :class="$style.timeLabel"/>
                            <div :class="$style.counselorList">
                                <div :class="$style.img">
                                <round-img width="45px" :src="getMyIconUrl(row.counselor)" />
                              </div>
                              <div :class="$style.text">
                                <p :class="$style.name">{{ row.counselor.username }}</p>
                                <p :class="$style.position" v-if="helper.master">{{ helper.master.labels.users.account_type[row.counselor.account_type] }}</p>
                              </div>
                              <div :class="$style.counselingIcon">
                                <i
                                  class="fa-regular fa-laptop"
                                  :class="$style.colorIcon_online"
                                >
                                  <i :class="$style.colorIcon_online_inner" class="fa-regular fa-user-headset" />
                                </i>
                                <i
                                  v-if="Number(row.raw.counseling_reception_type) === 2"
                                  class="fa-solid fa-vr-cardboard"
                                  :class="$style.colorIcon_virtual"
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <router-link
                              :class="$style.reserved"
                              v-if="row.raw.reserve && row.raw.reserve.flag === 1"
                              :to="getReserveURL(row.raw.reserve)"
                            >
                              <i
                                v-if="isVirtualReserve(row.raw.reserve)"
                                class="fa-solid fa-vr-cardboard"
                                :class="$style.colorIcon_virtual"
                              />
                              <i
                                v-else
                                class="fa-regular fa-laptop"
                                :class="$style.colorIcon_online"
                              >
                                <i :class="$style.colorIcon_online_inner" class="fa-regular fa-user-headset" />
                              </i>
                              予約あり
                            </router-link>
                            <i
                              v-else
                              :class="$style.trash"
                              class="fa-solid fa-trash-can"
                              v-on:click="deleteSchedule(row.raw)"/>
                          </div>
                        </div>
                      </contents-box>
                    </stack-item>
                  </ul>
                </contents-box>
              </stack-item>
            </ul>
          </div>
          <spacer :y="2"/>
          <div :class="$style.open_wrap">
            <div :class="$style.open" @click="flag.showAll = !flag.showAll">
              <p v-if="!flag.showAll">全て表示<i class="fa-solid fa-angles-down"/></p>
              <p v-else>折り畳む<i class="fa-solid fa-angles-up"/></p>
            </div>
          </div>
        </contents-box>
      </stack-item>
      <stack-item>
        <contents-box title="テキスト健康相談 シフト登録">
          <loader-simple :flag="flag.loader">
            <ul
              v-if="!flag.loader"
              :class="$style.counseling">
              <li v-for="(array, i) in adjustedWeekdayManager" :key="array" :class="$style.weekday">
                <label-box :label="weekday[i]" size="sm" :class="$style.label"/>
                <ul :class="$style.counselor_wrap">
                  <li
                    v-for="row in array"
                    :key="row.id">
                    <div :class="$style.counselor">
                      <p :class="$style.name">{{ row.medicalUsers.username }}</p>
                      <i
                        :class="$style.icon"
                        class="fa-light fa-circle-xmark"
                        v-on:click="deleteCounselor(row.id)" />
                    </div>
                  </li>
                  <li>
                    <select
                      :class="$style.select"
                      :name="day"
                      :id="i"
                      v-if="showSelect === i"
                      v-on:change="updateCounselor">
                      <option :value="0">選択してください</option>
                      <option
                        :value="row.id"
                        v-for="row in options[i]"
                        :key="row.id">{{ row.username }}</option>
                    </select>
                    <i
                      v-if="showSelect !== i"
                      :class="$style.clickarea"
                      class="fa-light fa-circle-plus"
                      v-on:click="selectCounselor(i)" />
                    <span
                      v-if="showSelect === i"
                      :class="$style.add"
                      v-on:click="addCounselor(i)">追加する</span>
                  </li>
                </ul>
              </li>
            </ul>
          </loader-simple>
        </contents-box>
      </stack-item>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions.js';
import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import RoundImg from '@/views/components/RoundImg.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Calendar from '@/views/components/DatePicker.vue';
import Spacer from '@/views/components/Spacer.vue';
import LabelBox from '@/views/components/LabelBox.vue';

export default {
  name: 'counselor-top',
  mixins: [cf],
  components: {
    StackItem,
    ContentsBox,
    RoundImg,
    Calendar,
    Spacer,
    LabelBox,
    LoaderSimple,
  },
  data() {
    return {
      flag: {
        loader: true,
        showAll: false,
      },
      // 曜日担当
      showSelect: null,
      selectedCounselor: 0,
      counselors: [],
      weekdayManager: [],
      adjustedWeekdayManager: [],
      options: {},
      date: null,
      calendarSchedule: [],
      weekday: ['日', '月', '火', '水', '木', '金'],
    };
  },
  computed: {
    ...mapState(['schedule', 'helper']),
  },
  created() {
    if (!this.schedule.list.length) {
      // store未登録であればシフトを取得し登録
      this.$store.dispatch('schedule/setSchedule');
    }
    this.getWeekdayManager();
  },
  methods: {
    selectHours() {
      const object = {};

      if (this.schedule.list) {
        this.schedule.list.forEach((row) => {
          let date = this.formatTimestamp(row.date, 'YYYY-MM-DD/HH');
          date = date.split('/');
          if (!object[date[0]]) object[date[0]] = [];
          if (row.flag === 1) {
            object[date[0]].push({
              date: Number(date[1]),
              counselor: row.counselor,
              raw: row,
            });
          }
        });
      }

      return object;
    },
    getWeekdayManager() {
      this.axios({
        method: 'GET',
        url: '/v1/weekdayManager/get/all',
      })
        .then((response) => {
          const res = response.data;
          this.weekdayManager = res.data.data;
          this.adjustManager();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    adjustManager() {
      // 全曜日分からの配列を用意
      // let i;
      // for (i = 0; i < 5; i += 1) {
      //   this.adjustedWeekdayManager[i] = [];
      // }
      this.adjustedWeekdayManager = {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
      };

      // 取得した週担当をflagで判別・曜日ごとに格納
      Object.keys(this.weekdayManager).forEach((key) => {
        // flagが1のもののみadjustedWeekdayManagerに格納
        if (this.weekdayManager[key].flag === 1) {
          const weekday = this.weekdayManager[key].weekday;
          this.adjustedWeekdayManager[weekday].push(this.weekdayManager[key]);
        }
      });
      this.getCounselor();
    },
    getCounselor() {
      const params = {
        accountTypes: [21, 22],
      };
      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.counselors = res.users.data;
          this.sortOptions();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    sortOptions() {
      this.options = {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
      };

      Object.keys(this.adjustedWeekdayManager).forEach((key) => {
        this.counselors.forEach((manager) => {
          let add = true;
          if (this.adjustedWeekdayManager[key].length) {
            this.adjustedWeekdayManager[key].forEach((row) => {
              if (manager.id === row.medicalUsers.id || manager.account_type === 21) add = false;
            });
          } else if (manager.account_type === 21) {
            add = false;
          }
          if (add) this.options[key].push(manager);
        });
      });
      this.flag.loader = false;
    },
    receiveDate(date) {
      this.date = date;
      if (!date) return;
      const data = {
        date: this.date,
      };
      const args = {
        modalName: 'daily-schedule',
        data,
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
    deleteSchedule(time) {
      if (!confirm(`こちらのシフトを削除してよろしいですか？\n${this.formatTimestamp(time.date, 'YYYY年MM月DD日 HH:00')}：${time.counselor.username}`)) return;
      const data = {
        schedules: [
          {
            id: time.id,
            date: this.formatTimestamp(time.date, 'YYYY-MM-DD HH:mm:ss'),
            flag: 999,
          },
        ],
      };
      this.axios({
        method: 'POST',
        url: '/v1/schedule/set/updater',
        data,
      })
        .then(() => {
          this.$store.dispatch('schedule/setSchedule');
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    selectCounselor(idx) {
      this.selectedCounselor = 0;
      this.showSelect = idx;
    },
    updateCounselor(e) {
      this.selectedCounselor = Number(e.target.value);
    },
    addCounselor(i) {
      const weekdayNum = Number(i);
      // 全件getした中にweekday・user_idともに同じものがあったらupdate
      let regist = true;
      let wmId = null;
      Object.keys(this.weekdayManager).forEach((key) => {
        const weekday = this.weekdayManager[key].weekday;
        const userId = this.weekdayManager[key].user_id;
        if (weekday === weekdayNum && userId === this.selectedCounselor) {
          wmId = this.weekdayManager[key].id;
          regist = false;
        }
      });
      const endpoint = regist ? 'register' : 'updater';
      const data = {
        id: wmId,
        weekday: weekdayNum,
        flag: 1,
        user_id: this.selectedCounselor,
      };

      this.axios({
        method: 'POST',
        url: `/v1/weekdayManager/set/${endpoint}`,
        data,
      })
        .then(() => {
          alert('更新しました。');
          this.getWeekdayManager();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    deleteCounselor(wid) {
      const data = {
        id: wid,
        flag: 999,
      };
      this.axios({
        method: 'POST',
        url: '/v1/weekdayManager/set/updater',
        data,
      })
        .then(() => {
          alert('更新しました。');
          this.getWeekdayManager();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    isVirtualReserve(reserve) {
      return reserve.type && Number(reserve.type.counseling_type) === 3;
    },
    getReserveURL(reserve) {
      return this.isVirtualReserve(reserve) ? `/operator/reserve/virtual/${reserve.id}/` : `/operator/reserve/online/${reserve.id}/`;
    },
  },
};
</script>

<style lang="scss" module>
.calendar {
  position: relative;
  z-index: 0;
}
.assistant {
  font-size: 12px;
}

.open {
  font-size: 12px;
  font-weight: normal;
  color: var(--gray-side);
  cursor: pointer;
  i {
    margin-left: 4px;
  }
  &_wrap {
    display: flex;
    justify-content: center;
  }
}

.counseling {
  li {
    padding: 25px 0 21px;
    &:not(:last-child) {
      border-bottom: 1px solid var(--gray-main);
    }
  }
}
.weekday {
  display: flex;
  align-items: flex-start;
  .label {
    width: 50px;
    margin-right: 15px;
  }
}
.counselor {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 14px;
  padding: 4px 12px;
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: var(--gray-sub);

  &_wrap {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li {
      padding: 0 0;
      &:not(:last-child) {
        border-bottom: none;
        margin-right: 10px;
      }
    }
  }
  .name {
    font-weight: bold;
  }
  .icon {
    margin-left: 5px;
    cursor: pointer;
  }
}
.clickarea {
  cursor: pointer;
}
.select {
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
  background-color: var(--gray-sub);
  margin-right: 10px;
  margin-bottom: 8px;
}
.add {
  display: inline-block;
  border: none;
  background-color: transparent;
  font-size: 12px;
  padding: 4px 12px;
  border: 1px solid var(--black);
  border-radius: 24px;
  cursor: pointer;
}
.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include sm-view {
    display: block;
  }
}
.left {
  display: flex;
  align-items: center;
}
.counselorList {
  display: flex;
  @include sm-view {
    display: block;
  }
}
.timeLabel {
  width: 80px;
  margin-right: 20px;
}
.img {
  margin-right: 20px;
}
.position {
  font-size: 10px;
}
.trash {
  font-size: 20px;
  color: var(--gray-main);
  cursor: pointer;
}
.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 12px;
  color: var(--black);
  border: 1px solid var(--black);
  border-radius: 20px;
  padding: 4px 12px;
  cursor: pointer;
}
.reserved {
  display: inline-block;
  font-size: 14px;
  color: var(--pink);
  border: 1px solid var(--pink);
  padding: 2px 12px;
  border-radius: 4px;
}
.counselingIcon {
  margin-left: .5em;
  > i {
    &:not(:first-child) {
      margin-left: .3em;
    }
  }
}
.colorIcon {
  &_online {
    color: var(--orange-main);
    position: relative;
    &_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
      font-size: .4em;
    }
  }
  &_virtual {
    color: var(--green-main);
  }
}
</style>

<template>
  <div>
    <spacer :y="4"/>
    <loader-simple :flag="flag.loader">
      <ul v-if="!flag.loader">
        <stack-item v-if="targetUser">
          <contents-box :title="'相談履歴'" :bg="userBoxColor(targetUser)">
            <user-box
              type="inner" :showId="true"
              :user="targetUser"
              :showCocomoniBtn="true"
              :ownUser="user">
            </user-box>
            <spacer :y="3" />
            <histories
              :histories="histories"
              :showNew="false"
              :hideCounseling="true"
              :riskApplications="riskApplications"
            />
          </contents-box>
        </stack-item>
      </ul>
    </loader-simple>
    <spacer :y="4"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Histories from '@/views/components/Histories.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import UserBox from '@/views/components/UserBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'history',
  mixins: [cf],
  components: {
    Histories,
    ContentsBox,
    UserBox,
    StackItem,
    LoaderSimple,
    Spacer,
  },
  data() {
    return {
      flag: {
        loader: true,
      },
      customerName: null,
      slug: null,
      reserve: null,
      reserves: [],
      texts: [],
      histories: [],
      memo: null,
      targetUser: null,
      riskApplications: [], // 承認された申請のみ
    };
  },
  async created() {
    const slug = cf.convert2Num(this.$route.params.slug);
    this.slug = slug;

    // 画面表示前に取得しておきたいのでawaitで実行する
    await this.getRiskApplications();

    this.getReserves();
    this.getUser();
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  methods: {
    getUser() {
      const params = {
        id: this.slug,
        isRisk: 1,
        includeDeleted: 1,
      };
      this.axios({
        method: 'get',
        url: '/v1/user/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.targetUser = res.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    getReserves() {
      const params = {
        from_user_id: this.slug,
        sort: true,
      };
      this.axios({
        method: 'get',
        url: '/v1/reserve/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.reserves = res.reserves.data;
          if (this.reserves.future.length) this.username = this.reserves.future[0].fromUser.username;
          else if (this.reserves.history.length) this.username = this.reserves.history[0].fromUser.username;
          this.getTexts();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    getTexts() {
      const params = {
        user_id: this.slug,
        sort: true,
      };
      this.axios({
        method: 'GET',
        url: '/v1/messagegroup/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.texts = res.groups.data;
          this.sortHistories();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    sortHistories() {
      this.histories = [];
      const now = this.formatTimestamp(new Date(), 'YYYY-MM-DD HH:mm:ss');
      const reserveKeys = Object.keys(this.reserves);
      reserveKeys.forEach((key) => {
        this.reserves[key].forEach((row) => {
          const schedule = this.formatTimestamp(row.schedule.date, 'YYYY-MM-DD HH:mm:ss');
          if (schedule < now || (schedule > now && row.flag > 900)) {
            // 事前キャンセルもリストへ含める
            this.histories.push({
              date: schedule,
              type: 'reserve',
              content: row,
            });
          }
        });
      });
      this.texts.answered.forEach((row) => {
        this.histories.push({
          date: row.messages[1].updated_at,
          type: 'text',
          content: row,
        });
      });
      this.histories.sort((a, b) => {
        if (a.date > b.date) return -1;
        return 1;
      });
      this.flag.loader = false;
    },
    updateMemo(e) {
      this.memo = e.target.value;
    },
    registMemo() {
      const data = {
        reserve_id: this.reserve.id,
        memo: this.memo,
        user_id: this.user.id,
      };

      if (this.reserve.memos.counselor[0]) data.id = this.reserve.memos.counselor[0].id;
      else data.id = null;

      const endpoint = data.id ? 'updater' : 'register';

      this.axios({
        method: 'POST',
        url: `/v1/reserve/memo/set/${endpoint}`,
        data,
      })
        .then(() => {
          alert('カルテを保存しました。');
          this.getReserve();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    async getRiskApplications() {
      const STATUS_APPROVED = 100; // 承認
      try {
        const response = await this.axios({
          method: 'GET',
          url: '/v1/riskApplications/get/list',
          params: {
            statuses: [STATUS_APPROVED],
            target_user_id: this.slug,
          },
        });
        this.riskApplications = response.data.data;
      } catch (e) {
        alert('リスク報告書情報の取得に失敗しました');
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" module>
.date {
  font-weight: bold;
}
.detail {
  li {
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    .content {
      font-size: 14px;
      margin-top: 3px;
      flex: 1;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    > p {
      flex: 1;
    }
  }
}
.label {
  width: 100px;
  margin-right: 20px;
}
.who5 {
  flex: 1;
  li {
    justify-content: space-between;
  }
}
.types {
  margin-right: 8px;
  &:not(:first-child) {
    &:before {
      content: "/";
    }
  }
}
.textarea {
  width: 100%;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  appearance: none;
  padding: 12px;
  outline: none;
}
.assistant {
  font-size: 12px;
  color: var(--pink);
}
</style>

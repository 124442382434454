<template>
  <div>
    <spacer :y="5"/>
    <contents-box title="アカウント">
      <data-grid
        v-show="!flag.loader">
        <data-row
          v-for="(category, i) in categories"
          v-bind:key="i">
          <data-term :class="$style.label"
            width="quater"
            v-if="this.user.account_type >= category.account_type_min
              && this.user.account_type <= category.account_type_max">
            <label-box :label="category.label"/>
          </data-term>

          <data-detail
            v-if="this.user.account_type >= category.account_type_min
              && this.user.account_type <= category.account_type_max">
            <div
              class="items"
              v-if="category.items.length">
              <div
                :class="$style.item"
                v-for="(item, j) in category.items"
                v-bind:key="`${i}-${j}`">

                <div
                  class="registed-value"
                  v-if="values[item.name] !== 'hidden' && category.name !== 'address'">
                  <p>
                    <span>{{ values[item.name] }}</span>
                    <span v-if="item.label">（{{ item.label }}）</span></p>
                </div>

                <div
                  class="registed-value n2br"
                  v-else-if="values[item.name] !== 'hidden'">
                  <p>{{ values.address }}</p>
                </div>

                <div
                  class="link"
                  v-if="item.change">

                  <p v-if="item.change.approval.includes(user.account_type)">
                    <router-link v-bind:to="item.change.link"><span :class="$style.hideSp">{{ item.change.label }}の</span>変更</router-link>
                  </p>
                </div>
              </div>
            </div>
          </data-detail>
        </data-row>
      </data-grid>
      <div
        class="loader-simple"
        v-if="flag.loader"></div>
    </contents-box>
    <spacer :y="5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';
// import BasicBtn from '@/views/components/BasicBtn.vue';
// import BtnContainer from '@/views/components/BtnContainer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import {
  DataGrid, DataRow, DataTerm, DataDetail,
} from '@/views/components/DataGrid';

export default {
  name: 'account',
  components: {
    // BasicBtn,
    // BtnContainer,
    ContentsBox,
    LabelBox,
    Spacer,
    DataGrid,
    DataRow,
    DataTerm,
    DataDetail,
  },
  mixins: [cf],
  data() {
    return {
      categories: [
        {
          label: 'メンバーシップ',
          name: 'membership',
          account_type_min: '1',
          account_type_max: '100',
          items: [
            {
              name: 'email',
              change: {
                link: '/edit/email/',
                label: 'メールアドレス',
                // 変更を許可するaccount_type
                approval: [1, 2, 3, 31],
              },
            },
            {
              name: 'password',
              change: {
                link: '/edit/password/',
                label: 'パスワード',
                approval: [1, 2, 3, 31],
              },
            },
          ],
        },
        {
          label: 'プロフィール',
          name: 'profile',
          account_type_min: '1',
          account_type_max: '100',
          items: [
            {
              name: 'username',
              label: 'お名前',
              change: {
                link: '/edit/profile/',
                label: 'プロフィール',
                approval: [1, 2, 3, 31],
              },
            },
          ],
        },
        {
          label: '住所',
          name: 'address',
          account_type_min: '1',
          account_type_max: '100',
          items: [
            {
              name: 'address',
              change: {
                link: '/edit/address/',
                label: '住所',
                approval: [1, 2, 3, 31],
              },
            },
          ],
        },
        {
          label: '生徒情報',
          name: 'student',
          account_type_min: '1',
          account_type_max: '1',
          items: [
            {
              name: 'student',
              change: {
                link: '/edit/student/',
                label: '生徒情報',
                approval: [1, 2, 3],
              },
            },
          ],
        },
        {
          label: '所属組織情報',
          name: 'school',
          account_type_min: '11',
          account_type_max: '20',
          items: [
            {
              name: 'school',
              change: {
                link: '/edit/school/',
                label: '所属組織情報',
                approval: [11, 12],
              },
            },
          ],
        },
        {
          label: '医療従事者情報',
          name: 'medicalworker',
          account_type_min: '21',
          account_type_max: '30',
          items: [
            {
              name: 'medicalworker',
              change: {
                link: '/edit/medicalworker/',
                label: '医療従事者情報',
                approval: [21, 22],
              },
            },
          ],
        },
      ],
      values: {},
      cardType: null,
      flag: {
        loader: true,
      },
    };
  },
  created() {
    if (this.user.email) {
      this.getUserValues();
    } else {
      this.$store.subscribe((mutation) => {
        // ユーザ情報を取得してvaluesを定義
        if (mutation.type === 'user/setUserData') this.getUserValues();
      });
    }
  },
  computed: {
    ...mapState(['user', 'page']),
  },
  methods: {
    getUserValues() {
      // categories.items[n].nameに応じたvalueを取得
      this.categories.forEach((category) => {
        if (category.items && category.name !== 'address') { // 住所以外
          category.items.forEach((item) => {
            if (item.name === 'student' || item.name === 'school' || item.name === 'medicalworker') {
              // this.values.password = 'hidden';
              // this.values.password = '********';
            } else if (this.user[item.name]) { // 一階層目にあればそのまま
              this.values[item.name] = this.user[item.name];
            } else if (item.name === 'password') { // なければ個別に
              this.values.password = '********';
            } else {
              this.values[item.name] = '未設定';
            }
          });
        } else if (category.name === 'address') { // 住所関連
          this.values.address = '未設定';
          if (this.user.address) { // 住所登録済み
            const address = this.user.address;
            this.values.address = `${cf.methods.filterZip(address.zip)}\n${address.pref}${address.city}${address.address}`;
            if (address.building) {
              this.values.address += `\n${address.building}`;
            }
          }
        }
      });
      // console.log(this.values);

      this.flag.loader = false;
    },
  },
};
</script>


<style lang="scss" module>
.label {
  width: 200px;
}
.item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  a {
    color: var(--black);
    &:hover {
      text-decoration: underline;
    }
  }
}
.hideSp {
  @include xs-view {
    display: none;
  }
}
</style>

<template>
  <div>
    <basic-box>
      <div :class="top">
        <div :class="$style.counselor">
          <div
            v-if="!user.lti"
            :class="$style.img"
            :style="`background-image: url(${getMyIconUrl(counselor)})`"/>
          <round-img-LTI
            v-else
            :src="getMyIconUrl(counselor)" />
          <div class="n2br" :class="$style.text">
            <p :class="$style.kana" v-if="counselor.kana">{{ counselor.kana }}</p>
            <p :class="$style.name">{{ counselor.username }}</p>
            <p :class="$style.position" v-if="helper.master && helper.master.labels">{{ helper.master.labels.users.account_type[counselor.account_type] }}</p>
            <spacer :y="1"/>
            <p v-html="counselor.comment" :class="$style.comment"></p>
            <spacer :y="1"/>
            <spacer :y="underTitle" />
            <!-- <div :class="$style.shortProfile">
              <p v-if="counselor.medicalWorker.birthplace" :class="$style.birthplace"><b>出身</b>：{{ counselor.medicalWorker.birthplace }}</p>
              <p v-if="counselor.medicalWorker.hobby" :class="$style.hobby"><b>趣味</b>：{{ counselor.medicalWorker.hobby }}</p>
              <p v-if="counselor.medicalWorker.note" :class="$style.note"><b>特記</b>：{{ counselor.medicalWorker.note }}</p>
              <p v-if="counselor.medicalWorker.blog" :class="$style.blog"><b>ブログ</b>：
                <a
                  v-if="counselor.medicalWorker.blog.includes('http')"
                  :href="counselor.medicalWorker.blog"
                  target="_blank">{{ counselor.medicalWorker.blog }}</a>
                <span v-else>{{ counselor.medicalWorker.blog }}</span>
              </p>
            </div> -->
          </div>
        </div>
        <div :class="$style.shortProfile">
          <div v-if="counselor.medicalWorker.birthplace" :class="$style.profile">
            <p :class="$style.profileTitle">出身</p>
            <p :class="$style.profileContent">{{ counselor.medicalWorker.birthplace }}</p>
          </div>
          <spacer :y="underTitle" />
          <div class="n2br" v-if="counselor.medicalWorker.hobby" :class="$style.profile">
            <p :class="$style.profileTitle">趣味</p>
            <div class="n2br" :class="$style.profileContent">{{ counselor.medicalWorker.hobby }}</div>
          </div>
          <spacer :y="underTitle" />
          <div v-if="counselor.medicalWorker.note" :class="$style.profile">
            <p :class="$style.profileTitle">特記</p>
            <p :class="$style.profileContent">{{ counselor.medicalWorker.note }}</p>
          </div>
          <spacer :y="underTitle" />
          <div v-if="counselor.medicalWorker.blog" :class="$style.profile">
            <p :class="$style.profileTitle">ブログ</p>
            <p :class="$style.profileContent">
              <a
              v-if="counselor.medicalWorker.blog.includes('http')"
              :href="counselor.medicalWorker.blog"
              target="_blank">{{ counselor.medicalWorker.blog }}</a>
              <span v-else>{{ counselor.medicalWorker.blog }}</span>
            </p>
          </div>
        </div>
      </div>
      <div v-if="showDetail">
        <spacer :y="2"/>
        <div>
          <div
            v-for="(column, o) in helper.master.labels.medicalWorkers.columns"
            :key="column.name"
            class="n2br">
            <spacer
              v-if="o !== 0 && (counselor.medicalWorker[column.name])"
              :y="2"/>
            <p
              v-if="counselor.medicalWorker[column.name]"
              :class="$style.name">{{ column.label }}</p>
            <p
              :class="$style.comment"
              v-if="counselor.medicalWorker[column.name] && column.name !== 'blog'"
              v-html="counselor.medicalWorker[column.name]"></p>
            <p
              :class="$style.comment"
              v-if="counselor.medicalWorker[column.name] && column.name === 'blog'"
              ><a :href="counselor.medicalWorker[column.name]" target="_blank">{{ counselor.medicalWorker[column.name] }}</a></p>
          </div>
        </div>
      </div>
      <slot name="btn"/>
      <slot name="other"/>
    </basic-box>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BasicBox from '@/views/components/BasicBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import RoundImgLTI from '@/views/components/RoundImg-LTI';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'counselor-box',
  mixins: [cf],
  components: {
    BasicBox,
    Spacer,
    RoundImgLTI,
  },
  props: {
    counselor: {
      type: Object,
      required: true,
    },
    showDetail: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['helper', 'user']),
  },
};
</script>

<style lang="scss" module>
.flex_center {
  display: flex;
  justify-content: center;
}
.qr {
  width: 100px;
}
.img_title {
  font-weight: bold;
}
.count {
  font-size: 50px;
  font-weight: bold;

  &_text {
    font-size: 12px;
    font-weight: bold;
  }
}
.search {
  &_items {
    padding: 35px 0;
    border-width: 1px;
    border-color: #eee transparent;
    border-style: solid;
  }
}
.select {
  display: flex;
  justify-content: space-between;

  &_btn {
    width: calc((100% - 16px) / 2);

    @include xs-view {
      font-size: 12px;
    }
  }

  input {
    display: none;
  }
  label {
    display: inline-block;
    width: 100%;
    border: 1px solid var(--black);
    border-radius: 4px;
    text-align: center;
    padding: 20px 0 15px;

    &.checked {
      color: #fff;
      border: none;
      background-color: var(--orange-main);
    }
  }

  &_icon {
    font-size: 30px;
    margin-bottom: 10px;
  }
}
.flex {
  display: flex;
  flex-wrap: wrap;

  &.sb {
    justify-content: space-between;
  }

  .item {
    margin: 0 20px 0 0;
    input {
      margin-right: 10px;
    }
  }
}
.label {
  width: 120px;
}
.counselor {
  display: flex;

  @include xs-view {
    display: block;
    .img {
      margin-bottom: 10px;
    }
  }

  @include sm-view {
    .img {
      margin-bottom: 10px;
    }
  }
}
.img {
  $size: 120px;
  padding-top: $size;
  width: $size;
  background-position: center;
  background-size: cover;
  margin-right: 16px;
  height: 0;
  border-radius: 100%;
  overflow: hidden;

  @include sm-view {
    $size: 20vw;
    width: $size;
    padding-top: $size;
  }
}
.text {
  flex: 1;
}
.kana {
  font-size: 11px;
}
.name {
  font-weight: bold;
}
.position {
  font-size: 12px;
}
.comment {
  font-size: 14px;
  @include sm-view {
    font-size: 13px;
  }
}
.profileTitle {
  font-weight: bold;
  @include sm-view {
    font-size: 16px;
  }
}
.profileContent {
  text-align: left;
  font-size: 14px;
}
.shortProfile {
  @include sm-view {
    font-size: 13px;
  }
  p {
    text-indent: -3em;
    padding-left: 3em;
  }
  .birthplace {}
  .hobby {}
  .blog {
    text-indent: -4em;
    padding-left: 4em;
  }
  .note {}
}
</style>

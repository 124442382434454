<template>
  <div>
    <form v-on:submit="registKeyword">
      <div :class="$style.wordsearch">
        <div :class="$style.wordsearch_form">
          <word-search-form
            placeholder="先生を検索"
            v-on:sendValue="receiveValue" />
        </div>
        <i
          :class="[$style.wordsearch_btn, isVirtual ? $style.virtual : null]"
          class="fa-regular fa-magnifying-glass"
          v-on:click="registKeyword"/>
      </div>
    </form>

    <spacer :y="4"/>
    <div :class="$style.select">
      <div :class="$style.select_btn">
        <input type="checkbox" id="date" v-on:change="changeSelectBy">
        <label for="date" :class="selectBy === 'date' ? [$style.checked, isVirtual ? $style.virtual : null] : '' ">
          <i :class="$style.select_icon" class="fa-regular fa-calendar-days"></i>
          <p>日時から選ぶ</p>
        </label>
      </div>
      <div :class="$style.select_btn">
        <input type="checkbox" id="counselor" v-on:change="changeSelectBy">
        <label for="counselor" :class="selectBy === 'counselor' ? [$style.checked, isVirtual ? $style.virtual : null] : '' ">
          <i :class="$style.select_icon" class="fa-solid fa-people-group"></i>
          <p>先生から選ぶ</p>
        </label>
      </div>
    </div>

    <div
      :class="$style.search_items"
      v-if="selectBy === 'date'">
      <Calendar
        v-on:sendDate="receiveDate"
        :selectOnlineDate="true"
        :selectableDates="calendarSelectableDates"
      />
    </div>

    <spacer :y="4"/>
    <ul v-if="counselors.length">
      <stack-item
        v-for="(counselor, n) of counselors"
        :key="counselor.id">
        <counselor-box :counselor="counselor" :showDetail="careerOpenIds.includes(n)">
          <template v-slot:btn>
            <spacer :y="2"/>
            <btn-container>
              <basic-btn
                tag="button"
                size="sm"
                v-if="!careerOpenIds.includes(n)"
                v-on:click="changeOpenIds(n, 'career')">詳細を表示</basic-btn>
              <spacer
                :y="2"
                :class="$style.only_sp"
                v-if="dateOpenIds.includes(n)"/>
              <basic-btn
                tag="button"
                type="bdr"
                size="sm"
                v-if="careerOpenIds.includes(n)"
                v-on:click="changeOpenIds(n, 'hidecareer')">詳細を非表示</basic-btn>
              <spacer
                :class="$style.only_pc"
                v-if="!dateOpenIds.includes(n) && selectBy === 'counselor' && !careerOpenIds.includes(n)"
                :x="1"/>
              <basic-btn
                tag="button"
                size="sm"
                v-if="(!dateOpenIds.includes(n) && selectBy === 'counselor' && !careerOpenIds.includes(n))
                  && counselor.schedules.length"
                v-on:click="changeOpenIds(n, 'date')">相談可能日時を表示</basic-btn>
            </btn-container>
          </template>
          <template v-slot:other>
            <spacer :y="3" v-if="dateOpenIds.includes(n) || selectBy === 'date'" />
            <spacer :y="2" v-else />
            <p v-if="!counselor.schedules.length"
              :class="$style.noSchedules">この先生の相談可能な日時は現在ありません</p>

            <ul v-if="(dateOpenIds.includes(n) || selectBy === 'date')
              && counselor.schedules.length">
              <stack-item
                v-for="schedule in counselor.schedules"
                :key="schedule"
                v-show="isShow(schedule.date)">
                <div>
                  <label-box
                    :label="filterDate(schedule.date)" size="sm" color="gray"
                    :class="$style.label"
                    v-if="selectBy === 'counselor'"/>
                  <spacer :y="1" v-if="selectBy === 'counselor'"/>
                  <ul>
                    <stack-item>
                      <contents-box type="inner">
                        <div :class="`${$style.flex} ${$style.sb}`">
                          <div>{{ filterMoment(schedule.date, 'YYYY.MM.DD HH:mm') }} ~ {{ filterMoment(schedule.date, 'HH:45') }}</div>
                          <spacer :y="1" :class="$style.only_sp"/>

                          <div v-if="isReserved(schedule)" :class="$style.reserved">予約済</div>

                          <span v-else-if="!checkCounselingReceptionType(schedule)">この時間はオンライン健康相談のみ受け付けています</span>

                          <basic-btn
                            v-else-if="canReserve(counselor.account_type)"
                            tag="a" @click="clickReserveOnline(schedule)" size="sm">この時間で予約する</basic-btn>

                          <span v-else>利用可能回数不足により予約できません</span>
                        </div>
                      </contents-box>
                    </stack-item>
                  </ul>
                </div>
              </stack-item>
            </ul>
            <spacer
              v-if="selectBy === 'counselor' && dateOpenIds.includes(n) && counselor.schedules.length"
              :y="3"/>
            <btn-container v-if="selectBy === 'counselor'">
              <basic-btn
                tag="button"
                type="bdr"
                size="sm"
                v-if="dateOpenIds.includes(n) && counselor.schedules.length"
                v-on:click="changeOpenIds(n, 'date')">相談可能日時を非表示</basic-btn>
            </btn-container>
          </template>
        </counselor-box>
      </stack-item>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import BasicBtn from '@/views/components/BasicBtn.vue';
import CounselorBox from '@/views/components/CounselorBox.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import WordSearchForm from '@/views/components/WordSearchForm.vue';
import Calendar from '@/views/components/DatePicker.vue';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'select-counselor',
  props: {
    tab: {
      type: String, // 'online' || 'virtual'
      require: false,
      default: () => 'online',
    },
  },
  mixins: [cf],
  components: {
    Spacer,
    StackItem,
    BasicBtn,
    CounselorBox,
    BtnContainer,
    LabelBox,
    ContentsBox,
    WordSearchForm,
    Calendar,
  },
  data() {
    return {
      date: null,
      rawCounselors: [],
      counselors: [],
      counselor: null,
      careerOpenIds: [],
      dateOpenIds: [],
      selectBy: 'date',
      keyword: null,
      searchTarget: {
        user: ['username', 'kana'],
        medicalWorker: ['hobby', 'birthplace', 'qualifications', 'specialty'],
      },
      items: [
        {
          label: '男性',
          name: 'male',
          value: false,
        },
        {
          label: '女性',
          name: 'female',
          value: false,
        },
        {
          label: '再度相談',
          name: 'counseled',
          value: false,
        },
        {
          label: '趣味が合う',
          name: 'hobby',
          value: false,
        },
      ],
      futureReservesForDuplicatedCheck: [], // 将来の時間帯で予約済みの「すべての」バーチャル健康相談情報（簡易版）
    };
  },
  async created() {
    // urlのqueryから初期表示画面を変更
    const query = this.$route.query;
    if (query && query.target) this.selectBy = query.target;
    if (query && query.counselor) this.counselor = cf.convert2Num(query.counselor);


    // バーチャル健康相談の場合は将来のすべての予約情報を取得しておく（予約済み時間は「予約済」表示をして重複予約を受け付けないようにするため）
    if (this.isVirtual) {
      this.futureReservesForDuplicatedCheck = await this.getFutureVirtualReservesLite(); // getMedicalWorkersによって表示する情報を取得する前に確実にこの情報を取得しておきたいのでawaitでする
    }

    // query.counselorで対象を絞り込む際
    // user.school情報が読み込まれてる必要がある
    if (this.user.email) {
      this.getMedicalWorkers();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.getMedicalWorkers();
        }
      });
    }
  },
  computed: {
    ...mapState(['helper', 'user', 'holiday']),
    isVirtual() {
      return this.tab === 'virtual';
    },
    minDate() {
      return cf.calcSalesMinDate(this.holiday);
    },
    maxDate() {
      let result = null;
      // 19日までは当月末まで、20日以降は翌月末まで
      const today = moment(new Date());
      if (today.date() < 20) {
        result = today.endOf('month').toDate();
      } else {
        result = today.add(1, 'month').endOf('month').toDate();
      }
      return result;
    },
    // カレンダー上で選択可能な日付群
    calendarSelectableDates() {
      const dateStrings = []; // 'YYYY-MM-DD'文字列の配列
      this.rawCounselors.forEach((counselor) => {
        counselor.schedules.forEach((schedule) => {
          // 対象のシフトスケジュールが予約済みの場合は対象外とする
          if (this.isReserved(schedule)) {
            return;
          }
          // バーチャル健康相談が表示対象だが、対象スケジュールがバーチャル健康相談を受け付けていない場合は対象外とする
          if (this.isVirtual && schedule.counseling_reception_type !== 2) {
            return;
          }
          // 表示対象の日付として格納
          const dateString = schedule.date.substring(0, 10); // 'YYYY-MM-DD'部分を抜き出し
          if (!dateStrings.includes(dateString)) { // 重複を除く
            dateStrings.push(dateString);
          }
        });
      });
      // return dateStrings.map((dateString) => moment(dateString).toDate()); // Date型の配列にして返却
      return dateStrings;
    },
  },
  methods: {
    isShow(d) {
      let flag = false;
      const date = this.filterMoment(d, 'YYYY-MM-DD');
      const min = this.filterMoment(this.minDate, 'YYYY-MM-DD');
      const max = this.filterMoment(this.maxDate, 'YYYY-MM-DD');
      if (date >= min && date <= max) flag = true;

      return flag;
    },
    receiveValue(data) {
      this.keyword = data.value;
    },
    registKeyword(e) {
      e.preventDefault();
      if (this.keyword === '') this.keyword = null;
      this.selectBy = 'counselor';
      this.counselor = null;
      this.sortCounselor();
    },
    changeSelectBy(e) {
      this.selectBy = e.target.id;
      const query = {
        target: e.target.id,
      };
      if (this.$route.path === '/') {
        query.tab = this.$route.query.tab || this.tab;
      }
      // urlにqueryを仕込む
      this.$router.replace({
        query,
      });
      if (this.selectBy === 'date') this.keyword = null;
      this.sortCounselor();
    },
    changeOpenIds(id, name) {
      if (name === 'career') {
        if (this.careerOpenIds.includes(id)) {
          const idx = this.careerOpenIds.indexOf(id);
          this.careerOpenIds.splice(idx, 1);
        } else {
          this.careerOpenIds.push(id);
        }
      } else if (name === 'date') {
        if (this.dateOpenIds.includes(id)) {
          const idx = this.dateOpenIds.indexOf(id);
          this.dateOpenIds.splice(idx, 1);
        } else {
          this.dateOpenIds.push(id);
        }
      } else {
        if (name === 'hidecareer' && this.careerOpenIds.includes(id)) {
          const idx = this.careerOpenIds.indexOf(id);
          this.careerOpenIds.splice(idx, 1);
        }
        if (name === 'hidedate' && this.dateOpenIds.includes(id)) {
          const idx = this.dateOpenIds.indexOf(id);
          this.dateOpenIds.splice(idx, 1);
        }
      }
    },
    receiveDate(date) {
      if (date) this.date = date;
      else this.date = null;
      this.sortCounselor();
    },
    getMedicalWorkers() {
      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params: {
          accountTypes: [21, 22],
          isSchedule: 1,
          order: 'kana',
          orderBy: 'asc',
          scheduleCounselingReceptionType: this.isVirtual ? 2 : null,
          onlyVirtual: this.tab === 'virtual' ? 1 : 0,
        },
      })
        .then((response) => {
          this.rawCounselors = response.data.users.data;
          this.sortCounselor();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    sortCounselor() {
      const rawData = cloneDeep(this.rawCounselors);
      this.counselors = [];
      if (this.selectBy === 'counselor') {
        if (this.counselor) {
          rawData.some((row) => {
            if (row.id === this.counselor) {
              this.counselors.push(row);
              this.dateOpenIds = [0];
              // this.counselor = null;
              return true;
            }
            return false;
          });
          this.sortSchedules();
        } else {
          this.counselors = rawData;
          this.dateOpenIds = [];
          if (this.keyword) this.searchCounselor();
          else this.sortSchedules();
        }
      } else {
        // 選択した日にシフトがある医師心理士を抽出
        rawData.forEach((row) => {
          let addFlag = false;
          row.schedules.forEach((s) => {
            const day = this.formatTimestamp(s.date, 'YYYY-MM-DD');
            if (this.date === day) {
              addFlag = true;
            }
          });
          if (addFlag) this.counselors.push(row);
        });
        this.selectDate();
      }
    },
    searchCounselor() {
      const rawData = cloneDeep(this.rawCounselors);
      const array = [];
      rawData.forEach((row) => {
        // 該当したらadd=true
        let add = false;
        Object.keys(this.searchTarget).forEach((key) => {
          this.searchTarget[key].forEach((target) => {
            if (key === 'user') {
              if (row[target] && row[target].includes(this.keyword)) add = true;
            }
            if (key === 'medicalWorker') {
              if (row.medicalWorker[target] && row.medicalWorker[target].includes(this.keyword)) add = true;
            }
          });
        });
        if (add) array.push(row);
      });
      this.counselors = array;
      this.sortSchedules();
    },
    sortSchedules() {
      this.counselors.forEach((row) => {
        const validSchedules = [];
        row.schedules.forEach((schedule) => {
          if (this.isShow(schedule.date)) validSchedules.push(schedule);
        });
        row.schedules = validSchedules;
      });
    },
    selectDate() {
      // 選択した日以外のシフトを除外
      this.counselors.forEach((counselor) => {
        const days = [];
        counselor.schedules.forEach((s) => {
          const day = this.formatTimestamp(s.date, 'YYYY-MM-DD');
          if (this.date === day) {
            days.push(s);
          }
        });
        counselor.schedules = days;
      });
    },
    async clickReserveOnline(schedule) {
      // 同時間帯での予約数チェック
      if (!(await cf.methods.checkOnlineReserveTime(schedule.date))) {
        return;
      }
      cf.deleteTabState();
      this.$router.replace({
        path: `/reserve/${this.tab}/`,
        query: {
          schedule_id: schedule.id,
        },
      });
    },
    canReserve(counselorAccountType) {
      const billingType = this.user.school[0].billing_type.billing_type;
      let result = false;
      if (billingType === 1) { // 従量課金
        const necessaryCount = counselorAccountType === 21 ? 2 : 1; // 医師は2, 心理士は1
        result = this.user.school[0].count >= necessaryCount;
      } else if (billingType === 2) { // アカウント課金
        const counselorType = counselorAccountType === 21
          ? 'doctor'
          : 'psychologist';
        result = this.user.count[counselorType] >= 1;
      }
      return result;
    },
    checkCounselingReceptionType(schedule) {
      // バーチャル健康相談でない場合はチェック不要
      if (!this.isVirtual) {
        return true; // true でチェック通過
      }
      // バーチャル健康相談の場合、シフトとしてバーチャルを受け付けているか判定する
      return schedule.counseling_reception_type === 2; // 2: オンラインもバーチャルも受付中
    },

    // 今日日付以降の予約済みバーチャル健康相談の簡易情報を取得します。
    async getFutureVirtualReservesLite() {
      const params = {
        flags: [1],
        scheduleStartDate: moment(new Date()).format('YYYY-MM-DD'),
        counseling_type: 3, // バーチャル健康相談
      };
      try {
        const response = await this.axios({
          method: 'get',
          url: '/v2/reserve/get/liteList',
          params,
        });
        return response.data.result.reserve;
      } catch (error) {
        alert('今日日付以降のバーチャル健康相談情報の取得に失敗しました。');
        throw error;
      }
    },

    // 対象のシフトスケジュールが予約済みか
    isReserved(schedule) {
      // 対象の医師・心理士の当該時刻開始に予約が入っているか
      if (schedule.reserve && schedule.reserve.flag === 1) {
        return true;
      }
      // 他の医師・心理士の当該時刻開始の予約を他人がしているか
      const reserved = this.futureReservesForDuplicatedCheck.find((reserve) => reserve.schedule.date === schedule.date);
      return !!reserved;
    },
  },
};
</script>

<style lang="scss" module>
.wordsearch {
  display: flex;
  align-items: center;
  &_form {
    flex: 1;
  }
  &_btn {
    background-color: var(--orange-main);
    color: #fff;
    margin-left: 10px;
    padding: 15px 30px;
    border-radius: 30px;
    font-weight: bold;
    &.virtual {
      background-color: var(--green-main);
    }
  }
}
.flex_center {
  display: flex;
  justify-content: center;
}
.qr {
  width: 100px;
}
.img_title {
  font-weight: bold;
}
.count {
  font-size: 50px;
  font-weight: bold;

  &_text {
    font-size: 12px;
    font-weight: bold;
  }
}
.search {
  &_items {
    padding: 35px 0;
    border-width: 1px;
    border-color: #eee transparent;
    border-style: solid;
  }
}
.noSchedules {
  text-align: center;
  @include sm-view {
    font-size: 13px;
  }
}
.select {
  display: flex;
  justify-content: space-between;

  &_btn {
    width: calc((100% - 16px) / 2);

    @include xs-view {
      font-size: 12px;
    }
  }

  input {
    display: none;
  }
  label {
    display: inline-block;
    width: 100%;
    border: 1px solid var(--black);
    border-radius: 4px;
    text-align: center;
    padding: 20px 0 15px;

    &.checked {
      color: #fff;
      border: none;
      background-color: var(--orange-main);
      &.virtual {
        background-color: var(--green-main);
      }
    }
  }

  &_icon {
    font-size: 30px;
    margin-bottom: 10px;
  }
}
.flex {
  display: flex;
  flex-wrap: wrap;

  @include sm-view {
    display: block;
    text-align: center;
  }

  &.sb {
    justify-content: space-between;
  }

  .item {
    margin: 0 20px 0 0;
    input {
      margin-right: 10px;
    }
  }
}
.label {
  width: 120px;
}
.counselor {
  display: flex;
  @include xs-view {
    display: block;
    .img {
      margin-bottom: 10px;
    }
  }
}
.img {
  padding-top: 144px;
  width: 120px;
  background-position: center;
  background-size: cover;
  margin-right: 16px;
  height: 0;

  @include sm-view {
    width: 20vw;
    padding-top: 24vw;
  }
}
.text {
  flex: 1;
}
.name {
  font-weight: bold;
}
.position {
  font-size: 12px;
}
.reserved {
  font-size: 14px;
  padding: 4px 16px;
  border-radius: 4px;
  color: var(--pink);
  border: 1px solid var(--pink);
}
.only_pc {
  @include sm-view {
    display: none;
  }
}
.only_sp {
  display: none;
  @include sm-view {
    display: block;
  }
}
</style>

<template>
  <div id="edit-top" class="editAddress edit">
    <spacer :y="5"/>
      <contents-box title="住所登録・更新">
        <div>
          <form
            class="form horizontal"
            autocomplete="off">

            <Address
              :items="items"
              :isConfirm="flag.confirm"
              @sendValue="receiveValue" />

            <spacer :y="4"/>

            <btn-container>
              <basic-btn
                v-if="!flag.confirm"
                tag="button"
                id="confirm"
                v-on:click="updateFlagConfirm"
                :disabled="!flag.readySubmit">確認</basic-btn>
              <basic-btn
                v-if="flag.confirm"
                tag="button"
                id="submit"
                v-on:click="submit">登録</basic-btn>
              <spacer v-if="flag.confirm" :x="2"/>
              <basic-btn
                v-if="flag.confirm"
                tag="button"
                id="back"
                type="bdr"
                v-on:click="updateFlagConfirm">編集画面に戻る</basic-btn>
            </btn-container>
            <spacer v-if="!flag.confirm" :y="2"/>
            <btn-container v-if="!flag.confirm">
              <basic-btn to="/account/" type="bdr">戻る</basic-btn>
            </btn-container>
          </form>
        </div>
    </contents-box>
    <spacer :y="5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Address } from '@/views/components/parts/Form';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'edit-Address',
  mixins: [cf],
  components: {
    Address,
    ContentsBox,
    BtnContainer,
    BasicBtn,
    Spacer,
  },
  data() {
    return {
      flag: {
        confirm: false,
        readySubmit: false,
      },
      address: {},

      items: [
        {
          name: 'zip',
          type: 'text',
          label: '郵便番号',
          value: null,
          required: true,
          placeholder: '1020074',
          comment: '半角・ハイフンなしで入力してください',
        },
        {
          name: 'pref',
          type: 'select',
          label: '都道府県',
          value: null,
          options: [],
          required: true,
          comment: '',
        },
        {
          name: 'city',
          type: 'text',
          label: '市区町村',
          value: null,
          required: true,
          placeholder: '千代田区九段南',
        },
        {
          name: 'address',
          type: 'text',
          label: '番地',
          value: null,
          required: true,
          placeholder: '1-5-6',
        },
        {
          name: 'building',
          type: 'text',
          label: '建物名・部屋番号',
          value: null,
          placeholder: 'りそな九段ビル5階 KSフロア',
        },
      ],
    };
  },
  created() {
    if (this.user.email) {
      this.setPrefs();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/putMaster') {
          this.setPrefs();
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
    submitFlag() {
      return false;
    },
  },
  methods: {
    /** マスターデータからprefsをセット */
    setPrefs() {
      this.items.some((item) => {
        const name = item.name;
        if (name === 'pref') {
          item.options = this.helper.master.prefs;
          return true;
        }
        return false;
      });
    },

    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 子から値を受け取る */
    receiveValue(values) {
      this.flag.readySubmit = values.readySubmit;
      this.address = values.address;
    },

    /** 確認フラグ更新 */
    updateFlagConfirm(e) {
      e.preventDefault();
      const id = e.currentTarget.id;
      const next = id && id === 'back';
      this.flag.confirm = !next;
      if (next) {
        this.$scrollTo('#edit-top');
      }
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();
      this.showLoading();

      const data = {
        id: null, // 新規登録時はnull
        model_type: 1, // １ => ユーザー, 2 => 学校 3 => クリニック
        foreign_id: this.user.id,
      };
      // 取得した住所情報をdataに格納
      Object.keys(this.address).forEach((key) => {
        if (key !== 'email') {
          data[key] = this.address[key];
        }
      });
      if (this.user.address) data.id = this.user.address.id;

      const endpoint = data.id ? 'updater' : 'register';
      const type = data.id ? '更新' : '登録';

      this.axios({
        method: 'POST',
        url: `/v1/address/set/${endpoint}`,
        data,
      })
        .then(() => {
          this.flag.confirm = false;
          // stateアップデート
          this.$store.dispatch('user/update', null, { root: true });
          alert(`住所を${type}しました`);
        })
        .catch((error) => {
          alert('住所の登録に失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>
<style lang="scss" module>
select {
  color: black;
}
</style>

import { createStore } from 'vuex';
import helper from './modules/helper';
import page from './modules/page';
import user from './modules/user';
import layout from './modules/layout';
import modal from './modules/modal';
import schedule from './modules/schedule';
import holiday from './modules/holiday';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    helper,
    page,
    user,
    layout,
    modal,
    schedule,
    holiday,
  },
});

<template>
  <div>
    <spacer :y="5"/>
    <ul>
      <stack-item>
        <contents-box type="inner">
          <div :class="$style.regist">
            <p :class="$style.regist_title">学校登録</p>
            <basic-btn to="/operator/regist-school/">登録する</basic-btn>
          </div>
        </contents-box>
      </stack-item>

      <!-- <stack-item>
        <contents-box type="inner">
          <div :class="$style.regist">
            <p :class="$style.regist_title">学校管理者個別登録</p>
            <basic-btn to="/operator/regist-schoolUser/">登録する</basic-btn>
          </div>
        </contents-box>
      </stack-item> -->

      <stack-item>
        <contents-box title="学校一覧">
          <loader-simple :flag="flag.loader">
            <ul>
              <stack-item
                space="2"
                v-for="row in schools"
                :key="row.id"
                v-show="row.name !== 'old_id already exists'">
                <div :class="$style.wrap">
                  <label-box
                    :label="`ID:${row.id}`"
                    size="xs"
                    color="gray"
                    :class="$style.id" />
                  <contents-box type="inner" :bg="row.flag !== 1 ? 'gray' : 'white'">
                    <div :class="$style.school">
                      <div>
                        <div :class="$style.kana">{{ row.kana }}</div>
                        <div :class="$style.name">{{ row.name }}</div>
                      </div>
                      <div :class="$style.icons">
                        <basic-btn
                          v-on:click="changeStatusFlag(row)"
                          tag="button"
                          type="bdr"
                          size="sm">{{ row.flag === 1 ? '非アクティブにする' : 'アクティブにする'}}</basic-btn>
                        <spacer :y="2" :class="$style.only_sp"/>
                        <router-link :to="`/operator/update-school/${row.id}`">
                          <i
                            v-if="openIds.includes(row.id)"
                            :class="$style.plus"
                            class="fa-solid fa-pen-to-square"></i>
                        </router-link>
                        <i
                          v-if="!openIds.includes(row.id)"
                          :class="$style.plus"
                          class="fa-solid fa-plus"
                          v-on:click="changeOpenIds(row.id);
                            getCountHistorys(row);
                            getBulkCountHistorys(row);
                            getSustainableAddCredit(row);
                            getTempcount(row);
                            getSchoolMemo(row)"/>
                        <i
                          v-if="openIds.includes(row.id)"
                          :class="$style.plus"
                          class="fa-solid fa-minus"
                          v-on:click="changeOpenIds(row.id)"/>
                      </div>
                    </div>

                    <ul
                      :class="$style.detail"
                      v-if="openIds.includes(row.id)">
                      <spacer :y="3"/>
                      <!-- アカウント課金の組織は本登録に必要な情報が異なるため、 -->
                      <li v-if="row.billing_type.billing_type === 1">
                        <i :class="$style.icon" class="fa-solid fa-qrcode"></i>
                        <p :class="$style.content"><a :href="`${baseURL}/signup/?school=${row.id}`" target="_blank">{{ baseURL }}/signup/?school={{ row.id }}</a></p>
                      </li>
                      <li v-if="row.billing_type.billing_type === 1">
                        <p :class="$style.content">
                          <img :src="`https://api.qrserver.com/v1/create-qr-code/?data=${baseURL}/signup?school=${row.id}&size=150x150`">
                        </p>
                      </li>
                      <li v-else>
                        <p v-if="row.registCount" :class="$style.regist_count">本登録
                          <span :class="$style.regist_count_num">{{ row.registCount }}<span :class="$style.regist_count_num_mini"> / {{ row.registCount + row.temporaryCount }}</span></span>名
                        </p>
                        <spacer :x="3" />
                        <p v-if="row.temporaryCount" :class="$style.regist_count">未登録
                          <span :class="$style.regist_count_num">{{ row.temporaryCount }}<span :class="$style.regist_count_num_mini"> / {{ row.registCount + row.temporaryCount }}</span></span>名
                        </p>
                      </li>
                      <li>
                        <i :class="$style.icon" class="fa-solid fa-file-user"></i>
                        <p :class="$style.content">
                          {{ helper.master.labels.schools.billing_type[row.billing_type.billing_type] }}モデル
                          <span :class="$style.ml_bold" v-if="row.billing_type.billing_type === 1 && row.contract && row.contract.cycle">{{ row.contract.cycle === 1 ? '月次' : '年次' }}</span>
                          <!-- <span :class="$style.ml" v-if="row.billing_type.billing_type === 2">心理士10回 医師2回</span> -->
                        </p>
                      </li>
                      <li v-if="row.school_type">
                        <i :class="$style.icon" class="fa-solid fa-graduation-cap"></i>
                        <p :class="$style.content">{{ helper.master.labels.schools.school_type[row.school_type] }}</p>
                      </li>
                      <li>
                        <i :class="$style.icon" class="fa-solid fa-calculator"></i>
                        <p style="line-height: 1.2;">
                          オンライン・バーチャル　保有クレジット <span :class="$style.bold">{{ schoolOnlineCredit(row) || 0 }}</span>
                          <span :class="$style.detailed_explanation"><br>※医師相談=２クレジット使用</span>
                        </p>

                        <spacer :x="3"/>
                        <div v-if="row.billing_type.billing_type === 1">
                          <loader-simple :flag="flag.school_count.includes(row.id)" :mini="true" :isDisableMinHight="true">
                            <div v-if="!(flag.school_count.includes(row.id))">
                              <i
                                v-if="!countHistoryIds.includes(row.id)"
                                :class="$style.count"
                                class="fa-solid fa-plus"
                                v-on:click="changeOpenCountHistoryIds(row)"/>
                              <i
                                v-if="countHistoryIds.includes(row.id)"
                                :class="$style.count"
                                class="fa-solid fa-minus"
                                v-on:click="changeOpenCountHistoryIds(row)"/>
                            </div>
                          </loader-simple>
                        </div>
                      </li>

                      <li v-if="row.billing_type.billing_type === 1 && countHistoryIds.includes(row.id)">
                        <div
                          :class="$style.categories"
                          v-if="countHistories[row.id] && countHistories[row.id].length">
                          <p :class="$style.content"><i :class="$style.icon" class="fa-solid fa-calculator-simple"></i>回数利用・調整履歴</p>
                          <dl :class="$style.category">
                            <dt
                              v-for="count in countLabels"
                              :key="count.label">{{ count.label }}
                            </dt>
                          </dl>
                          <dl
                            :class="$style.category"
                            v-for="history in countHistories[row.id]"
                            :key="history.id">
                            <dd
                              v-for="count in countLabels"
                              :key="count.label">
                              <p v-if="count.name === 'date'">{{ history.date }}</p>
                              <p v-if="count.name === 'reserve_date'">{{ history.reserve_date }}</p>
                              <p v-else-if="count.name === 'target'" v-html="history.target"></p>
                              <p v-else-if="count.name === 'content'">{{ history.content }}</p>
                            </dd>
                          </dl>
                        </div>

                        <div v-if="!countHistories[row.id] || !countHistories[row.id].length">
                          <p>回数使用・編集履歴は存在しません</p>
                        </div>
                      </li>

                      <li v-if="row.billing_type.billing_type === 1">
                        <i :class="$style.icon" class="fa-sharp fa-light fa-abacus"></i>
                        <p style="line-height: 1.2;">
                          テキスト　利用可能回数 <span :class="$style.bold">{{ schoolTextCredit(row) || 0 }}</span>
                        </p>
                        <spacer :x="3"/>
                        <div v-if="row.billing_type.billing_type === 1">
                          <loader-simple :flag="flag.school_textCount.includes(row.id)" :mini="true" :isDisableMinHight="true">
                            <div v-if="!(flag.school_textCount.includes(row.id))">
                              <i
                                v-if="!countTextHistoryIds.includes(row.id)"
                                :class="$style.count"
                                class="fa-solid fa-plus"
                                v-on:click="changeOpenTextCountHistoryIds(row)"/>
                              <i
                                v-if="countTextHistoryIds.includes(row.id)"
                                :class="$style.count"
                                class="fa-solid fa-minus"
                                v-on:click="changeOpenTextCountHistoryIds(row)"/>
                            </div>
                          </loader-simple>
                        </div>
                      </li>

                      <li v-if="row.billing_type.billing_type === 1 && countTextHistoryIds.includes(row.id)">
                        <div
                          :class="$style.categories"
                          v-if="countTextHistorys[row.id] && countTextHistorys[row.id].length">
                          <p :class="$style.content"><i :class="$style.icon" class="fa-solid fa-calculator-simple"></i>テキスト回数利用・調整履歴</p>
                          <dl :class="$style.category">
                            <dt
                              v-for="count in textCountLabels"
                              :key="count.label">{{ count.label }}
                            </dt>
                          </dl>
                          <dl
                            :class="$style.category"
                            v-for="history in countTextHistorys[row.id]"
                            :key="history.id">
                            <dd
                              v-for="count in textCountLabels"
                              :key="count.label">
                              <p v-if="count.name === 'date'">{{ history.date }}</p>
                              <p v-else-if="count.name === 'target'" v-html="history.target"></p>
                              <p v-else-if="count.name === 'content'">{{ history.content }}</p>
                            </dd>
                          </dl>
                        </div>

                        <div v-if="!countTextHistorys[row.id] || !countTextHistorys[row.id].length">
                          <p>回数使用・編集履歴は存在しません</p>
                        </div>
                      </li>

                      <li v-if="row.billing_type.billing_type === 2">
                        <i :class="$style.icon" class="fa-solid fa-clock-rotate-left"></i>
                        <p>インポートcsv 利用回数 履歴</p>
                        <spacer :x="3" />
                        <div>
                          <loader-simple :flag="flag.school_count.includes(row.id)" :mini="true" :isDisableMinHight="true">
                            <div v-if="!(flag.school_count.includes(row.id))">
                              <i
                                v-if="!bulkCountHistoryIds.includes(row.id)"
                                :class="$style.count"
                                class="fa-solid fa-plus"
                                v-on:click="changeOpenBulkCountHistoryIds(row)"/>
                              <i
                                v-if="bulkCountHistoryIds.includes(row.id)"
                                :class="$style.count"
                                class="fa-solid fa-minus"
                                v-on:click="changeOpenBulkCountHistoryIds(row)"/>
                            </div>
                          </loader-simple>
                        </div>
                      </li>

                      <li v-if="row.billing_type.billing_type === 2 && bulkCountHistoryIds.includes(row.id)">
                        <div v-if="!bulkCountHistorys[row.id].length">変更履歴はありません</div>
                        <div v-else :class="[$style.categories, $style.max_width]">
                          <p :class="$style.content"><i :class="$style.icon" class="fa-solid fa-calculator-simple"></i>履歴</p>
                          <dl :class="$style.category">
                            <dt v-for="count in sustainableCountLabels" :key="count.label">
                              {{ count.label }}
                            </dt>
                          </dl>
                          <dl :class="$style.category"
                            v-for="history in bulkCountHistorys[row.id]" :key="history">
                            <dd
                              v-for="count in sustainableCountLabels"
                              :key="count.label">
                              <p v-if="count.name === 'date'">{{ history.date }}</p>
                              <p v-if="count.name === 'counselor_type'">{{ history.counselor_type }}</p>
                              <p v-if="count.name === 'content'">{{ history.content }}</p>
                              <p v-if="count.name === 'import_file'" :class="$style.width_break">{{ history.import_file }}</p>
                            </dd>
                          </dl>
                        </div>
                      </li>

                      <li v-if="row.billing_type.billing_type === 2">
                        <i :class="$style.icon" class="fa-solid fa-square-plus"></i>
                        <p>オンラインクレジット編集履歴</p>
                        <spacer :x="3" />
                        <div>
                          <loader-simple :flag="flag.school_count.includes(row.id)" :mini="true" :isDisableMinHight="true">
                            <div v-if="!(flag.school_count.includes(row.id))">
                              <i
                                v-if="!susCreditIds.includes(row.id)"
                                :class="$style.count"
                                class="fa-solid fa-plus"
                                v-on:click="changeOpensusCreditIds(row)"/>
                              <i
                                v-if="susCreditIds.includes(row.id)"
                                :class="$style.count"
                                class="fa-solid fa-minus"
                                v-on:click="changeOpensusCreditIds(row)"/>
                            </div>
                          </loader-simple>
                        </div>
                      </li>

                      <li v-if="row.billing_type.billing_type === 2 && susCreditIds.includes(row.id)">
                        <div v-if="!susCreditHistorys[row.id].length">変更履歴はありません</div>
                        <div v-else :class="[$style.categories, $style.max_width]">
                          <p :class="$style.content"><i :class="$style.icon" class="fa-solid fa-calculator-simple"></i>履歴</p>
                          <dl :class="$style.category">
                            <dt v-for="count in susCreditHistorysLabals" :key="count.label">
                              {{ count.label }}
                            </dt>
                          </dl>
                          <dl :class="$style.category"
                            v-for="history in susCreditHistorys[row.id]" :key="history">
                            <dd
                              v-for="count in susCreditHistorysLabals"
                              :key="count.label">
                              <p v-if="count.name === 'date'">{{ history.date }}</p>
                              <p v-if="count.name === 'editor'">{{ history.editor }}</p>
                              <p v-if="count.name === 'target'">{{ history.target }}</p>
                              <p v-if="count.name === 'content'">{{ history.content }}</p>
                              <p v-if="count.name === 'counselor_type'">{{ history.counselor_type }}</p>
                              <p v-if="count.name === 'credit'">{{ history.credit }}</p>
                            </dd>
                          </dl>
                        </div>
                      </li>

                      <li v-if="row.email">
                        <i :class="$style.icon" class="fa-regular fa-envelope"></i>
                        <p :class="$style.content">{{ row.email }}</p>
                      </li>
                      <li v-if="row.tel">
                        <i :class="$style.icon" class="fa-solid fa-phone"></i>
                        <p :class="$style.content">{{ row.tel }}</p>
                      </li>
                      <!-- <li v-if="row.fax">
                        <i :class="$style.icon" class="fa-solid fa-fax"></i>
                        <p :class="$style.content">{{ row.fax }}</p>
                      </li> -->
                      <li>
                        <i :class="$style.icon" class="fa-regular fa-house"></i>
                        <p v-if="row.address" :class="$style.content">
                          <span>{{row.address.pref}}</span>
                          <span>{{row.address.city}}</span>
                          <span>{{row.address.address}}</span>
                          <span>{{row.address.building}}</span>
                        </p>
                        <p v-else>登録なし</p>
                      </li>
                      <li :class="$style.flexstart">
                        <label-box
                          :class="$style.label"
                          label="管理者"
                          size="xs" color="orange" />
                        <ul :class="$style.content_list">
                          <li
                            v-for="user in row.mainUser"
                            :key="user">
                            <span>{{ user.username }}</span>
                            <span v-if="user.kana && user.kana !== ''">（{{ user.kana }}）</span>
                            <span>（<a :href="`mailto:${user.email}`">{{ user.email }}</a>）</span>
                            <div :class="$style.message" class="fa-solid fa-messages" @click="messageDetail(user.id)">
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li
                        :class="$style.flexstart"
                        v-if="row.subUser">
                        <label-box
                          :class="$style.label"
                          label="副管理者"
                          size="xs" color="orange" />
                        <ul :class="$style.content_list">
                          <li v-for="user in row.subUser"
                            :key="user.id">
                            <span>{{ user.username }}</span>
                            <span v-if="user.kana && user.kana !== ''">（{{ user.kana }}）</span>
                            <span v-if="user.email">（<a :href="`mailto:${user.email}`">{{ user.email }}</a>）</span>
                          </li>
                        </ul>
                      </li>
                      <li
                        :class="$style.flexstart"
                        v-if="row.schoolMemo && row.schoolMemo.memo">
                        <i :class="$style.icon" class="fa-regular fa-note-sticky"></i>
                        <p>メモ</p>
                      </li>
                      <li
                        :class="$style.flexstart_noTopMargin"
                        v-if="row.schoolMemo && row.schoolMemo.memo">
                        <p :class="$style.school_memo" v-html="row.schoolMemo.memo.replace(/\n/g, '<br>')"></p>
                      </li>
                    </ul>
                  </contents-box>
                </div>
              </stack-item>
            </ul>
            <spacer :y="3"/>
          </loader-simple>
        </contents-box>
      </stack-item>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BasicBtn from '@/views/components/BasicBtn.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';
import moment from 'moment';

export default {
  name: 'operator-user-list',
  components: {
    BasicBtn,
    LabelBox,
    ContentsBox,
    StackItem,
    LoaderSimple,
    Spacer,
  },
  data() {
    return {
      flag: {
        loader: true,
        countLoader: false,
        inputCsv: false,
        school_count: [],
        school_textCount: [],
      },
      schools: [],
      openIds: [],
      countHistories: {},
      countTextHistorys: {},
      bulkCountHistorys: {},
      susCreditHistorys: {},
      countHistoryIds: [],
      bulkCountHistoryIds: [],
      susCreditIds: [],
      countTextHistoryIds: [],
      countLabels: [
        {
          label: '申込日',
          name: 'date',
        },
        {
          label: '予約日（実施日）',
          name: 'reserve_date',
        },
        {
          label: '利用者・編集者',
          name: 'target',
        },
        {
          label: '内容',
          name: 'content',
        },
      ],
      textCountLabels: [
        {
          label: '受付日',
          name: 'date',
        },
        {
          label: '利用者・編集者',
          name: 'target',
        },
        {
          label: '内容',
          name: 'content',
        },
      ],
      sustainableCountLabels: [ // アカウント課金、登録初期に操作する調整履歴の方で使用
        {
          label: '編集日',
          name: 'date',
        },
        // {
        //   label: '編集者',
        //   name: 'regist_user_id',
        // },
        {
          label: '種別',
          name: 'counselor_type',
        },
        {
          label: '内容',
          name: 'content',
        },
        {
          label: '範囲',
          name: 'import_file',
        },
      ],
      susCreditHistorysLabals: [ // アカウント課金、クレジット付与の履歴、初期にやる利用可能回数一括調整とは別
        {
          label: '編集日',
          name: 'date',
        },
        {
          label: '編集者',
          name: 'editor',
        },
        {
          label: '対象',
          name: 'target',
        },
        {
          label: '種別',
          name: 'counselor_type',
        },
        {
          label: '内容',
          name: 'content',
        },
        {
          label: 'クレジット数',
          name: 'credit',
        },
      ],
    };
  },
  created() {
    this.getSchools();
  },
  computed: {
    ...mapState(['helper', 'user']),
    baseURL() {
      return `${location.protocol}//${location.host}`;
    },
  },
  methods: {
    getSchools() {
      const params = {
        includeDeleted: 1,
        isMainUser: 1,
        isUsers: 0,
      };
      this.axios({
        method: 'GET',
        url: '/v1/school/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.schools = res.data.data;
          this.schools.forEach((school) => {
            this.flag.school_count.push(school.id);
            this.flag.school_textCount.push(school.id);
          });
        })
        .catch((error) => {
          if (error.message) console.log(error.massage);
          else console.log(error);
        })
        .finally(() => {
          this.flag.loader = false;
        });
    },

    async getCountHistorys(target) {
      this.axios({
        method: 'GET',
        url: '/v1/count/history/get/list',
        params: {
          foreign_id: target.id,
          billing_type: target.billing_type.billing_type,
          withReserve: 1,
          orderBy: 'asc',
        },
      })
        .then((response) => {
          // this.schoolで該当の学校の情報に付与
          const res = response.data.countHistories;
          const index = this.schools.findIndex((elem) => elem.id === target.id);
          this.schools[index].countHistories = res.data;
          this.sortCountHistories();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });

      this.axios({
        method: 'GET',
        url: '/v1/count/historyText/get/list',
        params: {
          school_id: target.id,
          billing_type: target.billing_type.billing_type,
          withMessageGroup: 1,
          orderBy: 'asc',
        },
      })
        .then((response) => {
          const res = response.data.textCountHistories;
          const index = this.schools.findIndex((elem) => elem.id === target.id);
          this.schools[index].textCountHistories = res.data;
          this.sortTextCountHistories();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    sortCountHistories() {
      this.schools.forEach((school) => {
        if (school.countHistories && school.countHistories.length) {
          this.countHistories[school.id] = [];
          school.countHistories.forEach((count) => {
            if (count.count) {
              let contentLabel;
              if (count.is_add) contentLabel = '追加';
              else if (!count.is_add && !count.reserve_id) contentLabel = '削除';
              else contentLabel = '使用';

              const insertData = {
                date: moment(count.updated_at).format('YYYY年MM月DD日'),
                target: count.reserve_id && count.reserve && count.reserve.fromUser ? count.reserve.fromUser.username : '事務局',
                content: `${count.count}クレジット ${contentLabel}`,
                // detail: count,
                reserve_date: count.reserve_id && count.reserve && count.reserve.schedule ? moment(count.reserve.schedule.date).format('YYYY年MM月DD日') : '',
              };
              this.countHistories[school.id].push(insertData);
            }
          });

          const index = this.flag.school_count.findIndex((elem) => elem === school.id);
          this.flag.school_count.splice(index, 1);
        }
      });
    },

    sortTextCountHistories() {
      this.schools.forEach((school) => {
        if (school.textCountHistories && school.textCountHistories.length) {
          this.countTextHistorys[school.id] = [];
          school.textCountHistories.forEach((count) => {
            if (count.count) {
              let contentLabel;
              if (count.is_add) contentLabel = '追加';
              else if (!count.is_add && !count.group_id) contentLabel = '削除';
              else contentLabel = '使用';

              const insertData = {
                date: moment(count.updated_at).format('YYYY年MM月DD日'),
                target: '事務局',
                content: `${count.count}回${contentLabel}`,
              };
              if (count.messageGroup && count.messageGroup.customer) insertData.target = count.messageGroup.customer.username;
              this.countTextHistorys[school.id].push(insertData);
            }
          });
          const index = this.flag.school_textCount.findIndex((elem) => elem === school.id);
          this.flag.school_textCount.splice(index, 1);
        }
      });
    },

    // アカウント課金タイプの契約の初期回数調整履歴を取得
    async getBulkCountHistorys(target) {
      if (target.billing_type.billing_type === 1) return true; // 従量課金契約であれば即返却

      const params = {
        school_id: target.id,
        isImport: 1,
      };

      this.axios({
        method: 'GET',
        url: '/v1/bulkCount/get/list',
        params,
      })
        .then((response) => {
          const res = response.data.data.data;
          const index = this.schools.findIndex((school) => school.id === target.id);
          this.flag.school_count.splice(index, 1);
          this.bulkCountHistorys[target.id] = [];
          res.forEach((data) => {
            const insertData = {
              date: moment(data.created_at).format('YYYY年MM月DD日'),
              counselor_type: data.counselor_type === 21 ? '医師' : '心理士',
              import_file: data.import ? data.import.name : '全体',
              content: `${data.count}回${data.is_add ? '追加' : '削除'}`,
            };
            this.bulkCountHistorys[target.id].push(insertData);
          });
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /**
     * アカウント課金タイプのオンラインクレジット付与履歴を取得
     * 表示するのは
     * 事務局→学校クレジット
     * 事務局→利用者クレジット
     * 学校→利用者クレジット
    */
    async getSustainableAddCredit(target) {
      if (target.billing_type.billing_type === 1) return true; // 学校タイプが従量課金だったら即返却

      const params = {
        school_id: target.id,
        isUsers: 1,
        isSchool: 1,
        isTargetUser: 1,
      };

      this.axios({
        methods: 'GET',
        url: '/v1/sustainableAddCredit/get/list',
        params,
      })
        .then((response) => {
          const res = response.data.data.data;
          const index = this.schools.findIndex((school) => school.id === target.id);
          this.flag.school_count.splice(index, 1);
          this.susCreditHistorys[target.id] = [];
          res.forEach((data) => {
            const insertData = {
              date: moment(data.created_at).format('YYYY年MM月DD日'),
              editor: data.regist_user.account_type === 31 ? '事務局' : data.regist_user.username,
              target: data.target_user_id ? data.target_user.username : data.school.name,
              content: `${data.credit}クレジット${data.is_add ? '追加' : '削除'}`,
              credit: `${data.credit}クレジット`,
              is_add: data.is_add,
              counselor_type: 'なし',
            };
            if (data.counselor_type) { // カウンセラータイプNULLになるのは事務局→学校のパターンのみ
              insertData.counselor_type = data.counselor_type === 22 ? '心理士' : '医師';
              const count = data.counselor_type === 22 ? data.credit : data.credit / 2; // 回数表記用
              insertData.content = `${count}回${data.is_add ? '追加' : '削除'}`;
            }
            this.susCreditHistorys[target.id].push(insertData);
          });
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error.message);
        });
    },

    // アカウント課金の仮登録、本登録の人数を返却
    async getTempcount(school) {
      // 従量課金タイプだったら即返却
      if (school.billing_type.billing_type === 1) return true;

      const params = { school_id: school.id };
      this.axios({
        method: 'GET',
        url: '/v1/user/get/tempCount',
        params,
      })
        .then((response) => {
          const res = response.data.result;
          const target = this.schools.find((elem) => elem.id === school.id);
          target.temporaryCount = res.temporary;
          target.registCount = res.regist;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    // 学校に対するメモがある場合は学校メモも取得
    async getSchoolMemo(school) {
      const params = { school_id: school.id };

      this.axios({
        method: 'GET',
        url: '/v1/schoolMemo/get/detail',
        params,
      })
        .then((response) => {
          if (response.data.detail.data.length) {
            const res = response.data.detail.data[0];
            const targetSchoolIndex = this.schools.findIndex((elem) => elem.id === school.id);
            this.schools[targetSchoolIndex].schoolMemo = res;
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    sortUser() {
      const type = [11, 12];
      this.schools.forEach((row) => {
        const manageUser = [];
        row.users.forEach((user) => {
          if (type.includes(user.account_type)) {
            manageUser.push(user);
          }
        });
        row.users = manageUser;
      });
      this.flag.loader = false;
    },

    changeOpenIds(id) {
      if (this.openIds.includes(id)) {
        const idx = this.openIds.indexOf(id);
        this.openIds.splice(idx, 1);
      } else {
        this.openIds.push(id);
      }
    },

    changeOpenCountHistoryIds(row) {
      if (this.countHistoryIds.includes(row.id)) {
        const idx = this.countHistoryIds.indexOf(row.id);
        this.countHistoryIds.splice(idx, 1);
      } else {
        this.countHistoryIds.push(row.id);
      }
    },

    changeOpenTextCountHistoryIds(row) {
      if (this.countTextHistoryIds.includes(row.id)) {
        const idx = this.countTextHistoryIds.indexOf(row.id);
        this.countTextHistoryIds.splice(idx, 1);
      } else {
        this.countTextHistoryIds.push(row.id);
      }
    },

    async changeOpenBulkCountHistoryIds(row) {
      if (this.bulkCountHistoryIds.includes(row.id)) {
        const idx = this.bulkCountHistoryIds.indexOf(row.id);
        this.bulkCountHistoryIds.splice(idx, 1);
      } else {
        this.bulkCountHistoryIds.push(row.id);
      }
    },

    async changeOpensusCreditIds(row) {
      if (this.susCreditIds.includes(row.id)) {
        const idx = this.susCreditIds.indexOf(row.id);
        this.susCreditIds.splice(idx, 1);
      } else {
        this.susCreditIds.push(row.id);
      }
    },

    changeStatusFlag(school) {
      const label = school.flag === 1 ? '非アクティブ' : 'アクティブ';
      if (!confirm(`この学校を${label}してよろしいですか？\n${school.name}`)) return;
      const data = {
        id: school.id,
        flag: school.flag === 1 ? 999 : 1,
      };

      this.axios({
        method: 'POST',
        url: '/v1/school/set/updater',
        data,
      })
        .then(() => {
          this.getSchools();
          alert(`${label}化が完了しました。`);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          this.hideLoading();
        });
    },
    messageDetail(targetUserId) {
      const ownUserId = this.user.id;
      if ((targetUserId && ownUserId) && (!this.user.counseling.operator.length || (this.user.counseling.operator.length && !this.user.counseling.operator.some((message) => (message.user_id === ownUserId && message.target_user_id === targetUserId))))) {
        // メッセージ新規作成
        const data = {
          user_id: ownUserId,
          target_type: 2, // 事務局個別メッセージ
          target_user_id: targetUserId,
        };

        this.axios({
          method: 'POST',
          url: '/v1/messageGroup/set/register',
          data,
        })
          .then((response) => {
            const res = response.data;
            const resolvedRoute = this.$router.resolve({
              name: 'OperatorMessageDetail',
              params: { slug: res.group_id },
            });
            window.open(resolvedRoute.href, '_blank');
          })
          .catch((error) => {
            if (error.message) console.log(error.message);
            else console.log(error);
            alert('メッセージの初期化に失敗しました。');
          });
      } else if (targetUserId && ownUserId) {
        const targetGroup = this.user.counseling.operator.find((message) => (message.user_id === ownUserId && message.target_user_id === targetUserId));
        if (!targetGroup) alert('対象のメッセージを読み込めませんでした。\n再度お試しください。');
        const resolvedRoute = this.$router.resolve({
          name: 'OperatorMessageDetail',
          params: { slug: targetGroup.id },
        });
        window.open(resolvedRoute.href, '_blank');
      }
    },
    /**
     * 学校の保持オンラインクレジット
     *   該当校の課金タイプが
     *     1 => 従量課金: school.count
     *     2 => アカウント課金: school.mainUser[0].count.credit
     */
    schoolOnlineCredit(school) {
      const billingType = school.billing_type.billing_type;
      let credit = 0;
      if (billingType === 1) credit = school.count;
      else if (billingType === 2) credit = school.mainUser[0].count.credit;
      return credit;
    },
    /**
     * 学校のテキスト利用可能回数
     * 従量課金のみ: school.textCount
     */
    schoolTextCredit(school) {
      const billingType = school.billing_type.billing_type;
      let credit = 0;
      if (billingType === 1) credit = school.textCount;
      return credit;
    },
  },
};
</script>

<style lang="scss" module>
.regist {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_title {
    font-size: 20px;
    font-weight: bold;
  }

  @include sm-view {
    display: block;
  }
}
.wrap {
  position: relative;
}
.id {
  position: absolute;
  z-index: 1;
  width: 120px;
  margin: 12px 0 0 -6px;
}
.kana {
  font-size: 12px;
}
.name {
  font-size: 16px;
  @include sm-view {
    font-size: 14px;
  }
}
.school {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  @include sm-view {
    margin-top: 40px;
    display: block;
  }
}
.icons {
  display: flex;
  align-items: center;
  @include sm-view {
    display: block;
    margin-top: 10px;
    text-align: right;
  }
}
.plus {
  cursor: pointer;
  font-size: 20px;
  color: var(--gray-side);
  margin-left: 20px;
}
.count {
  cursor: pointer;
  font-size: 20px;
  color: var(--gray-side);
  display: flex;
}
// .read_csv {
//   display: block;
//   padding: 3%;
//   text-align: center;
//   background-color: var(--gray-sub);
//   border-radius: 5px;
//   button {
//     margin-top: 10px;
//   }
// }
// .file_input:hover {
//   cursor: pointer;
// }
.detail {
  li {
    display: flex;
    align-items: center;
    @include sm-view {
      // display: block;
    }
    &.flexstart {
      align-items: flex-start;
      &_noTopMargin {
        margin-top: -15px;
      }
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    .icon {
      font-size: 20px;
      color: var(--orange-main);
      margin-right: 10px;
    }
    .label {
      width: 60px;
      white-space: nowrap;
      margin-right: 10px;
    }
    .content {
      &_block {
        display: block;
      }
      flex: 1;
      &_list {
        @include sm-view {
          font-size: 14px;
        }
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        li {
          display: flex;
          flex-wrap: wrap;
          &:not(:last-child) {
            margin-bottom: 0;
            &::after {
              content: '/';
              padding: 0 10px;
            }
          }
        }
      }
      .ml {
        margin-left: 1em;
        &_bold {
          font-weight: bold;
        }
      }
      @include sm-view {
        font-size: 14px;
      }
    }
  }
  a {
    color: var(--black);
    text-decoration: underline;
    transition: all .4s;
    &:hover {
      opacity: .6;
    }
  }
}
.regist_count{
  font-size: 1.1em;
  font-weight: bold;
  &_num {
    font-size: 1.3em;
    &_mini {
      font-size: 15px;
    }
  }
}
.bold {
  font-weight: bold;
}
.center {
  text-align: center;
}
.inactive {
  font-size: 12px;
  padding: 2px 12px;
  border-radius: 12px;
  border: 1px solid var(--black);
  cursor: pointer;
}
.only_pc {
  @include sm-view {
    display: none;
  }
}
.only_sp {
  display: none;
  @include sm-view {
    display: block;
  }
}
.detailed_explanation{
  font-size: .7em;
}
.categories {
  $mgn: 40px;
  $gry: #ccc;
  $bdr: 1px solid $gry;
  $pd: .5em;
  width: 75%;
  .category {
    font-size: .8em;
    padding: $mgn/5 0;
    border-bottom: $bdr;
    display: flex;
    margin-block-start: 0;
    margin-block-end: 0;
    // width: 90%;
    &:first-child {
      border-top: $bdr;
    }
     > dt, dd {
      padding: 0 $pd;
      margin-inline-start: 0;
    }
   > dt {
      width: 45%;
    }
    > dd {
      flex: 1;
    }
    .link {
      margin-left: auto;
      padding-left: 1em;
    }
    .width_break {
      word-break: break-word;
    }
  }

  &.max_width {
    width: 100%;
  }
}
.message {
  margin-left: 5px;
  color: var(--gray-side);
  cursor: pointer;
}
.school_memo {
  padding: .7em;
  border-radius: 12px;
  background-color: var(--gray-sub);
}
</style>

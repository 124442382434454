<template>
  <div>
    <spacer :y="5"/>
    <contents-box title="事務局からのお知らせ" icon="<i class='fa-regular fa-circle-info'></i>" id="scroll-top">
      <loader-simple :flag="flag.loader">
        <div v-if="informations.length">
          <stack-item
            v-for="row in informations"
            :key="row.title">
            <router-link
              :to="`${prefix}/information/${row.id}/`" :class="$style.link">
              <contents-box type="inner">
                <label-box
                  label="NEW"
                  size="sm"
                  color="orange"
                  :class="$style.label"
                  v-if="user.readflags && !user.readflags.informations.includes(row.id)" />
                <spacer
                  :x="2"
                  v-if="user.readflags && !user.readflags.informations.includes(row.id)"/>
                <div :class="$style.flex">
                  <div :class="$style.labels">
                    <label-box
                      :label="filterMoment(row.updated_at, 'YYYY-MM-DD')"
                      size="sm"
                      color="orange"
                      :class="`${$style.label} ${$style.date}`" />
                    <spacer :x="2" v-if="row.flag === 11"/>
                    <label-box
                      label="重要"
                      size="sm"
                      color="pink"
                      v-if="row.flag === 11"
                      :class="$style.label" />
                  </div>
                </div>
                <spacer :y="1.5"/>
                <div :class="$style.title">{{ row.title }}</div>
                <spacer :class="$style.hideSp" :x="1"/>
                <div :class="$style.content">
                  <p v-html="replaceImgTag(row.content)"></p>
                </div>
              </contents-box>
            </router-link>
          </stack-item>
        </div>
        <spacer :y="3"/>
          <pagination
            :page="page"
            :pageMax="pageMax"
            :path="path"
            v-if="informations.length && pageMax !== 1" />

        <spacer :y="5"/>
        <btn-container>
          <basic-btn :to="`${prefix}/`">トップへ戻る</basic-btn>
        </btn-container>
      </loader-simple>
    </contents-box>
    <spacer :y="5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import cf from '@/mixins/commonFunctions';
import Pagination from '@/views/components/Pagination.vue';

export default {
  name: 'information-list',
  mixins: [cf],
  components: {
    ContentsBox,
    StackItem,
    LabelBox,
    Spacer,
    BasicBtn,
    BtnContainer,
    LoaderSimple,
    Pagination,
  },
  data() {
    return {
      flag: {
        loader: true,
        pageQuery: false,
      },
      informations: [],
      prefix: '',
      page: 1,
      pageMax: 1,
      limit: 20,
      total: 0,
      path: null,
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  created() {
    const path = this.$route;
    this.path = path.path;
    if (path.query.page) {
      this.page = Number(path.query.page);
    } else if (path.path === '/information/') {
      // queryにpageがなければ追加してreplace
      this.$router.replace('/information/?page=1');
    } else if (path.path === '/counselor/information/') {
      // queryにpageがなければ追加してreplace
      this.$router.replace('/counselor/information/?page=1');
    } else if (path.path === '/teacher/information/') {
      // queryにpageがなければ追加してreplace
      this.$router.replace('/teacher/information/?page=1');
    }
    if (this.user.email) {
      this.getInformations();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.getInformations();
        }
      });
    }
  },
  watch: {
    $route() {
      const path = this.$route;
      if (path.query.page) {
        this.page = Number(path.query.page);
      } else if (path.path === '/information/') {
        // queryにpageがなければ追加してreplace
        this.$router.replace('/information&page=1');
      } else if (path.path === '/counselor/information/') {
      // queryにpageがなければ追加してreplace
        this.$router.replace('/counselor/information/?page=1');
      } else if (path.path === '/teacher/information/') {
      // queryにpageがなければ追加してreplace
        this.$router.replace('/teacher/information/?page=1');
      }
      this.getInformations();
      this.flag.pageQuery = true;
    },
  },
  methods: {
    /** お知らせ取得 */
    getInformations() {
      const user = this.user;
      const params = {
        page: this.page,
        limit: this.limit,
        onwards: user.created_at,
      };
      const types = {
        customer: [1, 2, 3],
        teacher: [11, 12],
        counselor: [21, 22],
      };
      if (types.customer.includes(user.account_type)) {
        params.targetNumber = 2;
      } else if (types.teacher.includes(user.account_type)) {
        params.targetNumber = 3;
        this.prefix = '/teacher';
      } else if (types.counselor.includes(user.account_type)) {
        params.targetNumber = 4;
        this.prefix = '/counselor';
      }

      this.axios({
        method: 'GET',
        url: '/v1/information/get/list',
        params,
      })
        .then((response) => {
          this.informations = response.data.data.data;
          this.flag.loader = false;
          this.pageMax = response.data.data.lastPage;
          this.total = response.data.data.total;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          if (this.flag.pageQuery) this.scroll2Top();
          this.flag.pageQuery = false;
        });
    },
    scroll2Top() {
      // 上までスクロール
      const elem = document.getElementById('scroll-top');
      const position = elem.getBoundingClientRect().top;
      scrollBy(0, position - 77);
    },
  },
};
</script>

<style lang="scss" module>
.link {
  color: var(--black);
  &:hover {
    .title {
      text-decoration: underline;
    }
  }
}
.labels {
  display: flex;
  @include sm-view {
    display: block;
  }
}
.label {
  width: 80px;
  display: inline-block;
  &.date {
    width: 120px;
    @include sm-view {
      display: block;
      width: 100%;
    }
  }
  @include sm-view {
    display: block;
    width: 100%;
  }
}
.flex {
  display: flex;
  align-items: flex-start;

  @include sm-view {
    display: block;
    .label {
      width: 100%;
    }
  }
}
.hideSp {
  @include sm-view {
    display: none;
  }
}
.title {
  flex: 1;
}
.content {
  font-size: 14px;
  overflow: hidden;
  p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
}
</style>

<template>
  <div>
    <Doughnut
      :chartData="ChartData"
      :chart-options="chartOptions" />
  </div>
</template>

<script>
import {
  Chart,
  registerables,
} from 'chart.js';
import { Doughnut } from 'vue-chartjs';

Chart.register(...registerables);

export default {
  name: 'DoughnutChart',
  components: {
    Doughnut,
  },
  props: {
    labelsData: { // 表の判例の情報
      type: Array,
      default: null,
    },
    datasetsData: { // グラフ描画用データ
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      ChartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        cutout: '80%',
        plugins: {
          legend: {
            display: false,
            // position: 'bottom',
          },
        },
      },
    };
  },
  created() {},
  mounted() {
    this.drawGraph();
  },
  watch: {
    labelsData() { // 親コンポーネントでフィルターの条件を変更して再度描画する時用
      this.updateGraph();
    },
  },
  methods: {
    updateGraph() {
      this.ChartData.labels = [];
      this.ChartData.datasets = [];
      this.drawGraph();
    },
    drawGraph() {
      this.labelsData.forEach((elem) => {
        this.ChartData.labels.push(elem);
      });
      this.ChartData.datasets.push(this.datasetsData);
    },
  },
};
</script>

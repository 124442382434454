/**
 * オンライン健康相談予約管理
 */

import axios from '@/plugins/axios';
import { cloneDeep } from 'lodash';
// import router from '@/router';
// import cf from '@/mixins/commonFunctions';

const STATE_OPERATOR_TEXT_DEFAULT = {
  progress: [], // 回答待ち（下書き含む）
  answered: { 1: [] }, // ページネーション
  answeredOptions: {
    pageMax: 1,
  },
};

const actions = {
  getCounseling({ dispatch }) {
    dispatch('getReserves');
    dispatch('getTexts');
    dispatch('getVirtualReserves');
    // 各サイト表示タブ内で取得しているためstoreは不要
    // dispatch('getOperatorMessage');
  },

  getReserves({ commit, rootState }) {
    const user = rootState.user;
    let params;
    if (user.account_type < 11) { // 利用者
      params = {
        from_user_id: user.id,
        sort: true,
        // counseling_type: 1, // オンライン健康相談（パラメータで明示指定しない場合はデフォルトでオンライン健康相談を取得する）
      };
    } else if (user.account_type < 31) { // 学校管理者、医師・心理士
      // params = { to_user_id: user.id };
      // 「オンライン」タブで取得するよう改修
      commit('putReserves', []);
      return;
    } else { // 事務局
      params = {};
      commit('putReserves', []);
      return;
    }

    axios({
      method: 'get',
      url: '/v1/reserve/get/list',
      params,
    })
      .then((response) => {
        commit('putReserves', response.data.reserves.data);
      })
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      });
  },

  getTexts({ commit, rootState }) {
    /**
     * 【テキスト健康相談】
     *   利用者
     *     自身が作成者なのでuser_idで絞り込み
     *   学校管理者
     *     作成者が所属する学校idで絞り込みx
     *   医師・心理士
     *     自身が担当する曜日で絞り込み
     * 【対事務局】
     */

    const user = rootState.user;
    let params;
    if (user.account_type < 11) { // 利用者
      params = { user_id: user.id, sort: true, target_type: 1 };
    } else if (user.account_type < 31) { // 学校管理者、医師・心理士
      // 「テキスト」タブで取得するよう改修
      commit('putTexts', []);
      return;
    } else { // 事務局
      commit('putTexts', []);
      return;
    }

    axios({
      method: 'GET',
      url: '/v1/messagegroup/get/list',
      params,
    })
      .then((response) => {
        // console.log(response.data.groups.data);
        // console.log('=====');
        commit('putTexts', response.data.groups.data);
      })
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      });
  },

  getVirtualReserves({ commit, rootState }) {
    const user = rootState.user;
    let params;
    if (user.account_type < 11) { // 利用者
      params = {
        from_user_id: user.id,
        sort: true,
        counseling_type: 3, // バーチャル健康相談
      };
    } else if (user.account_type < 31) { // 学校管理者、医師・心理士
      // 「バーチャル」タブで取得するよう改修
      commit('putVirtualReserves', []);
      return;
    } else { // 事務局
      params = {};
      commit('putVirtualReserves', []);
      return;
    }

    axios({
      method: 'get',
      url: '/v1/reserve/get/list',
      params,
    })
      .then((response) => {
        commit('putVirtualReserves', response.data.reserves.data);
      })
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      });
  },

  getOperatorMessage({ commit, rootState }) {
    /**
     * 【事務局個別メッセージ】
     *   利用者 target_user_idで絞り込み
     *   事務局 user_id(事務局ユーザーのid)
     */

    const user = rootState.user;
    let params;
    if (user.account_type < 31) { // 利用者
      params = { target_user_id: user.id, target_type: 2 };
    } else { // 事務局はタブで取得
      params = { user_id: user.id, target_type: 2 };
      return;
    }

    axios({
      method: 'GET',
      url: '/v1/messagegroup/get/list',
      params,
    })
      .then((response) => {
        commit('putOperatorMessage', response.data.groups.data);
      })
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      });
  },
};


const mutations = {
  putReserves(state, data) {
    state.reserves = data;
  },
  putTexts(state, data) {
    state.texts = data;
  },
  putTextsprogress(state, data) {
    state.typeTexts.progress = data;
  },
  putTextsdraft(state, data) {
    state.typeTexts.draft = data;
  },
  // putTextsanswered(state, data) { // ページネーションがあるため除外
  //   state.typeTexts.answered = data;
  // },
  putVirtualReserves(state, data) {
    state.virtualReserves = data;
  },
  putOperatorMessage(state, data) {
    state.operator = data;
  },
  // 医師・心理士用
  putCounselorDatas(state, data) {
    // dataにuserIdが含まれていたら、自身の
    // ユーザーIDと合致するオンライン予約のみを抽出
    const vls = data.userId
      ? data.values.filter((value) => value.to_user_id === data.userId)
      : data.values;

    if (data.page) {
      // finishedまたはanswered
      state.counselor[data.type][data.division][data.page] = vls;
    } else if (data.division === 'report') {
      /**
       * 医師・心理士が報告書の下書きを行った際
       * レコードが2件登録されてしまうバグあり。（原因未解決）
       * その場合投稿後に下書きと投稿済みレコードが混在する事になり、
       * 「報告書未記入」に投稿済み予約が表示されてしまう。
       * 予約に対し2件レコードが存在し、かつ1件はflag=1であれば除外
       */
      const filtered = [];
      vls.forEach((v) => {
        const max = v.reports.length - 1;
        // reportが0件の場合は未記入なので追加
        if (!v.reports.length) filtered.push(v);
        v.reports.some((r, i) => {
          // flag1があれ場合は除外
          if (r.flag === 1) return true;
          // 最後までflag=1がなければ追加
          if (i === max) filtered.push(v);
          return false;
        });
      });
      // vls = vls.filter((v) => v.reports.length < 2);
      state.counselor[data.type][data.division] = filtered;
    } else {
      // それ以外
      state.counselor[data.type][data.division] = vls;
    }
  },
  // 事務局用
  putOperatorDatas(state, data) {
    if (data.page) {
      // finishedまたはanswered
      state.op[data.type][data.division][data.page] = data.values;
    } else {
      // それ以外
      state.op[data.type][data.division] = data.values;
    }

    // 関連情報の登録
    if (data.options && data.options.items && data.options.items.length) {
      data.options.items.forEach((item) => {
        state.op[data.type][data.options.name][item.key] = item.value;
      });
    }
  },
  // 事務局用: テキスト相談情報を消去する
  clearOperatorTextDatas(state) {
    state.op.text = cloneDeep(STATE_OPERATOR_TEXT_DEFAULT);
  },
};


const state = {
  reserves: [],
  texts: [],
  virtualReserves: [],
  operator: [],
  typeTexts: {
    progress: [],
    draft: [],
    // answered: [], // ページネーションがあるため除外
  },
  /**
   * 医師・心理士用
   * reserve:
   *   予約・報告書未記入・実施済みに分けて登録
   * text:
   *   受付中（未回答）・下書き保存・実施済みに分けて登録
   */
  counselor: {
    reserve: {
      progress: [],
      report: [],
      finished: {
        1: [], // ページ毎のレスポンスを格納
      },
    },
    text: {
      progress: [],
      draft: [],
      answered: {
        1: [],
      },
    },
    virtualReserve: {
      progress: [],
      report: [],
      finished: {
        1: [], // ページ毎のレスポンスを格納
      },
    },
  },
  /**
   * 事務局用
   * reserve: // <- オンラインとバーチャルを両方併せ持たせる
   *   future:
   *     実施日が未来のオンライン・バーチャル健康相談
   *   past:
   *     実施日が過去のオンライン・バーチャル健康相談
   * text:
   *   progress:
   *     回答待ち（下書き含む）
   *   answered
   *     回答済み
   */
  op: {
    reserve: {
      future: [], // 全件取得
      past: { 1: [] }, // ページネーション
      pastOptions: {
        reservesTotal: 0,
        pageMax: 1,
      },
    },
    text: cloneDeep(STATE_OPERATOR_TEXT_DEFAULT),
    // virtualReserve: {
    //   future: [], // 全件取得
    //   past: { 1: [] }, // ページネーション
    //   pastOptions: {
    //     reservesTotal: 0,
    //     pageMax: 1,
    //   },
    // },
    user: {
      customer: { 1: [] },
      customerOptions: {
        total: 0,
        pageMax: 1,
      },
    },
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
};

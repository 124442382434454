<template>
  <div>
    <spacer :y="5"/>
    <ul>
      <stack-item>
        <contents-box title="オンライン・バーチャル健康相談">
          <loader-simple :flag="flag.loaderReserves" v-if="flag.loaderReserves"/>
          <div v-if="!flag.loaderReserves">
            <div v-if="reserves.length">
              <p>予約中のオンライン・バーチャル健康相談が <span :class="$style.bold">{{ reserves.length }}件 </span>あります。</p>
              <spacer :y="2"/>
              <ul :class="$style.unchecked">
                <li
                  v-for="row in reserves"
                  :key="row.id">
                  <router-link :to="`/operator/?tab=online&open=${row.id}`">
                    <p v-html="`${formatTimestamp(row.schedule.date, `YYYY/MM/DD（${row.weekday.label}） HH:00`)} 　${row.fromUser.username}　${row.fromUser.school[0].name}　${getOnlineVirtualLabel(row.type)}`"></p>
                  </router-link>
                </li>
              </ul>
            </div>
            <p v-if="!reserves.length">予約中のオンライン・バーチャル健康相談はありません。</p>
          </div>
        </contents-box>
      </stack-item>
      <stack-item>
        <contents-box title="報告書" icon="<i class='fa-regular fa-clipboard-list-check'></i>">
          <loader-simple :flag="flag.loaderReserves" v-if="flag.loaderReserves"/>
          <div v-if="!flag.loaderReserves">
            <div v-if="reports.length">
              <p>報告書未記入のオンライン・バーチャル健康相談が <span :class="$style.bold">{{ reports.length }}件 </span>あります。</p>
              <spacer :y="2"/>
              <ul :class="$style.unchecked">
                <li
                  v-for="row in reports"
                  :key="row.id">
                  <router-link :to="`/operator/?tab=history&open=${row.id}`">
                    <p>{{ formatTimestamp(row.schedule.date, `YYYY/MM/DD（${row.weekday.label}） HH:00`) }}　{{ row.fromUser.username }}（{{ row.fromUser.school[0].name }}） {{ getOnlineVirtualLabel(row.type) }}</p>
                  </router-link>
                </li>
              </ul>
            </div>
            <p v-if="!reports.length">未記入の報告書はありません。</p>
          </div>
        </contents-box>
      </stack-item>
      <stack-item>
        <contents-box
          title="テキスト健康相談"
          icon="<i class='fa-regular fa-comment-lines'></i>"
          iconColor="blue"
        >
          <loader-simple :flag="flag.loaderTexts" v-if="flag.loaderTexts"/>
          <div v-if="!flag.loaderTexts">
            <div v-if="texts.length">
              <p>回答待ちのテキスト健康相談が <span :class="$style.bold">{{ texts.length }}件 </span>あります。</p>
              <spacer :y="2"/>
              <ul :class="$style.unchecked">
                <li
                  v-for="row in texts"
                  :key="row.id">
                  <router-link :to="`/operator/?tab=text&page=1&target=${row.id}`">
                    <p v-if="helper.master" v-html="`${formatTimestamp(row.messages[0].created_at, `YYYY/MM/DD（${helper.master.labels.weekdays[new Date(row.messages[0].created_at).getDay()]}） HH:mm`)}　${row.customer.username}　(${row.customer.school[0].name})`"></p>
                  </router-link>
                </li>
              </ul>
            </div>
            <p v-if="!texts.length">未回答のテキスト健康相談はありません。</p>
          </div>
        </contents-box>
      </stack-item>
      <stack-item v-if="informations.length">
        <contents-box title="事務局からのお知らせ" icon="<i class='fa-regular fa-circle-info'></i>">
          <ul
            :class="$style.newsOperator">
            <li
              v-for="row in informations"
              :key="row.title">
              <div :class="$style.date_wrap">
                <p
                  v-if="helper.master"
                  :class="[$style[`target_${row.target}`], $style.target]">{{ helper.master.labels.informations.target[row.target] }}
                </p>
                <spacer :x="1" v-if="row.flag === 11"/>
                <p
                  :class="[$style.pink, $style.target]"
                  v-if="row.flag === 11">重要</p>
                <spacer :x="1"/>
                <p :class="$style.date">{{ filterDate_ja(row.updated_at) }}</p>
              </div>
              <spacer :y="1"/>
              <router-link :to="`/operator/information/${row.id}/`">
                <p :class="$style.title">{{ row.title }}</p>
                <spacer :y="1"/>
                <div :class="$style.content"><p v-html="replaceImgTag(row.content)"></p></div>
              </router-link>
            </li>
          </ul>
          <spacer :y="3"/>
          <btn-container>
            <basic-btn to="/operator/?tab=information">一覧</basic-btn>
          </btn-container>
        </contents-box>
      </stack-item>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions.js';
import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import Spacer from '@/views/components/Spacer.vue';

export default {
  name: 'counselor-top',
  mixins: [cf],
  components: {
    StackItem,
    ContentsBox,
    LoaderSimple,
    BtnContainer,
    BasicBtn,
    Spacer,
  },
  data() {
    return {
      flag: {
        loaderReserves: true,
        loaderTexts: true,
      },
      informations: [],
      reserves: [],
      reports: [],
      texts: [],
    };
  },
  async created() {
    // this.getInformations();
    // this.countTextsV2();
    // this.countReservesLite();


    try {
      const reserve = await this.countReservesLite();
      if (reserve) console.log(reserve);
    } catch (error) {
      console.log(error);
    }

    try {
      const text = await this.getLiteMessageGroups();
      if (text) console.log(text);
    } catch (error) {
      console.log(error);
    }

    try {
      const infomation = await this.getInformations();
      if (infomation) console.log(infomation);
    } catch (error) {
      console.log(error);
    }

    this.checkTabState();
  },
  mounted() {
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  methods: {
    /** 事務局お知らせ取得 */
    getInformations() {
      this.axios({
        method: 'GET',
        url: '/v1/information/get/list',
        params: {
          limit: 3,
        },
      })
        .then((response) => {
          this.informations = response.data.data.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    countReservesLite() {
      const params = {
        flags: [1, 11],
        counseling_type: -1, // オンライン・バーチャル両方取得する
      };

      this.axios({
        method: 'GET',
        url: '/v2/reserve/get/liteList',
        params,
      })
        .then((response) => {
          this.reserves = response.data.result.reserve;
          this.reports = response.data.result.blank;
          cf.upSortReserves(this.reserves);
          cf.upSortReserves(this.reports);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.loaderReserves = false;
        });
    },

    // 実施済みはあらかじめ含めず検索
    async countTextsV2() {
      const types = ['draft', 'progress'];

      await Promise.all(
        types.map(async (type) => {
          const params = {
            includeDraft: type === 'draft' ? 1 : 0,
            target_type: 1,
            isWho5: 0,
            isType: 0,
            type,
          };

          await this.axios({
            method: 'GET',
            url: '/v2/messageGroup/get/typeList',
            params,
          })
            .then(async (response) => {
              await response.data.groups.data.forEach((elem) => this.texts.push(elem));
            })
            .catch((error) => {
              if (error.message) console.log(error.message);
              else console.log(error);
            });
        }),
      );

      this.flag.loaderTexts = false;
    },

    async getLiteMessageGroups() {
      const params = {
        orderBy: 'acs',
        target_type: 1,
        flags: [1, 21],
      };

      this.axios({
        method: 'GET',
        url: '/v2/messagegroup/get/liteList',
        params,
      })
        .then(async (response) => {
          await response.data.groups.data.forEach((elem) => this.texts.push(elem));
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });

      this.flag.loaderTexts = false;
    },

    /** LSにtab情報が保存されているか確認する */
    checkTabState() {
      const ls = cf.getLocalStorage('wtt');
      if (ls.saveState && ls.saveState.tab) {
        this.$router.replace({
          query: {
            tab: ls.saveState.tab,
          },
        });
        // localStorageのsaveStateを削除
        cf.deleteLocalStorage('wtt', 'saveState');
      }
    },

    /** 健康相談タイプに適したラベルを返却する */
    getOnlineVirtualLabel(type) {
      return type.counseling_type === 3 ? 'バーチャル' : 'オンライン';
    },
  },
};
</script>

<style lang="scss" module>
.newsOperator {
  li {
    padding: 20px;
    border-bottom: 1px solid var(--gray-main);
    .title {
      font-weight: bold;
    }
  }
  a {
    color: var(--black);
    &:hover {
      .title {
        text-decoration: underline;
      }
    }
  }
  .date {
    font-size: 14px;
    &_wrap {
      display: flex;
      align-items: center;
    }
  }
  .target {
    padding: 2px 12px;
    font-size: 12px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    display: inline-block;
    font-weight: bold;
    color: #fff;

    &_1 {
      background-color: var(--gray-main);
    }
    &_2 {
      background-color: var(--orange-main);
    }
    &_3 {
      background-color: var(--green-main);
    }
    &_4 {
      background-color: var(--blue-main);
    }
    &.pink {
      background-color: var(--pink);
    }
  }
}
.unchecked {
  li {
    a {
      color: var(--black);
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.bold {
  font-weight: bold;
}
</style>

<template>
  <div>
    <hr id="scroll-top">
    <spacer :y="5"/>
      <ul>
        <stack-item>
          <contents-box title="履歴一覧" icon="<i class='fa-regular fa-files'></i>">
            <spacer :y="5"/>
            <loader-simple :flag="flag.loader">
              <div v-show="!flag.loader">
                <pagination
                  :page="page"
                  :pageMax="pageMax"
                  :path="'/teacher/?tab=history'"
                  v-if="histories.length && pageMax !== 1" />
                <spacer :y="4"/>
                <histories
                  :histories="histories"
                  :showNew="false"
                  :hideCounseling="true"
                  :showFromUser="true"
                  :isAbleToClickUserBox="true"
                  :isCompany="isCompany" />
                <spacer :y="4"/>
                <pagination
                  :page="page"
                  :pageMax="pageMax"
                  :path="'/teacher/?tab=history'"
                  v-if="histories.length && pageMax !== 1" />
              </div>
            </loader-simple>
          </contents-box>
        </stack-item>
      </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Histories from '@/views/components/Histories.vue';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions.js';
import Pagination from '@/views/components/Pagination.vue';

export default {
  name: 'teacher-history',
  mixins: [cf],
  components: {
    StackItem,
    ContentsBox,
    LoaderSimple,
    Spacer,
    Histories,
    Pagination,
  },
  data() {
    return {
      flag: {
        loader: false,
        pageQuery: false,
      },
      histories: [],
      openIds: [],
      page: 1,
      pageMax: 1,
      limit: 10,
    };
  },
  created() {
    // Teacher/Home.vueでユーザ情報取得の後
    // componentを呼び出しているためthis.userは
    // すぐに取得できる
    this.getHistory();
  },
  watch: {
    $route() {
      this.getHistory();
      this.flag.pageQuery = true;
    },
  },
  computed: {
    ...mapState(['user', 'helper']),
    isCompany() {
      return Boolean(this.user.school[0].is_company);
    },
  },
  methods: {
    getHistory() {
      const query = this.$route.query;
      if (query.page) {
        this.page = Number(query.page);
      } else if (query.tab === 'history') {
        // queryにpageがなければ追加してreplace
        this.$router.replace('/teacher/?tab=history&page=1');
        return;
      }

      // console.log(`get history: ${this.page}`);
      // console.log('-----------------------------------');

      // 多重実行防止
      if (this.flag.loader) {
        return;
      }
      this.flag.loader = true;

      const params = {
        schoolId: this.user.school[0].id,
        isRisk: 1,
        page: this.page,
        limit: this.limit,
        type: 1,
      };
      this.axios({
        method: 'GET',
        url: '/v1/history/get/list',
        params,
      })
        .then((response) => {
          const res = response.data.data;
          this.pageMax = res.lastPage;
          this.histories = res.data;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(async () => {
          await this.$nextTick();
          if (this.flag.pageQuery) this.scroll2Top();
          this.flag.loader = false;
          this.flag.pageQuery = false;
        });
    },
    changeOpenIds(n) {
      if (this.openIds.includes(n)) {
        const idx = this.openIds.indexOf(n);
        this.openIds.splice(idx, 1);
      } else {
        this.openIds.push(n);
      }
    },
    scroll2Top() {
      // 上までスクロール
      const elem = document.getElementById('scroll-top');
      const position = elem.getBoundingClientRect().top;
      scrollBy(0, position - 77);
    },
  },
};
</script>

<style lang="scss" module>
.list {
  display: flex;
  align-items: center;
  cursor: pointer;

  &_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.label {
  width: 100px;
  margin-right: 20px;
}
.text_wrap {
  margin-left: 15px;
}
.id {
  font-size: 12px;
}
.clickarea {
  cursor: pointer;
}
.icon {
  font-size: 20px;
  color: var(--gray-side);
}
.detail {
  li {
    display: flex;
    align-items: flex-start;
    .content {
      font-size: 14px;
      margin-top: 3px;
      flex: 1;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
.who5 {
  li {
    justify-content: space-between;
  }
}
.types {
  margin-right: 20px;
}
</style>

<template>
  <div>
    <contents-box>
      <loader-simple :flag="flag.loader">
        <ul v-if="!flag.loader">
          <stack-item
            v-for="row of counselors"
            :key="row.id">
            <counselor-box :counselor="row" :showDetail="openIds.includes(row.id)">
              <template v-slot:btn>
                <spacer :y="2"/>
                <div :class="$style.open" v-on:click="changeOpenIds(row.id)">
                  <p v-if="!openIds.includes(row.id)">詳細を表示<i class="fa-regular fa-angles-down"/></p>
                  <p v-if="openIds.includes(row.id)">詳細を非表示<i class="fa-regular fa-angles-up"/></p>
                </div>
              </template>
              <template v-slot:other>
                <spacer :y="2"/>
                <btn-container>
                  <basic-btn
                    :to="`/reserve/select/?target=counselor&counselor=${row.id}`"
                    size="sm">この先生にオンライン相談する</basic-btn>
                </btn-container>
              </template>
            </counselor-box>
          </stack-item>
        </ul>
      </loader-simple>
    </contents-box>
  </div>
</template>

<script>
import BasicBtn from '@/views/components/BasicBtn.vue';
import CounselorBox from '@/views/components/CounselorBox.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';

export default {
  name: 'counselors',
  components: {
    BasicBtn,
    BtnContainer,
    CounselorBox,
    StackItem,
    ContentsBox,
    LoaderSimple,
    Spacer,
  },
  data() {
    return {
      flag: {
        loader: true,
      },
      counselors: [],
      openIds: [],
      tempCounselors: [],
    };
  },
  created() {
    this.getCounselors(22);
  },
  methods: {
    getCounselors(accountType) {
      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params: {
          accountTypes: [accountType],
          order: 'kana',
          orderBy: 'asc',
        },
      })
        .then((response) => {
          if (response.data.users.data.length) {
            const length = response.data.users.data.length - 1;
            response.data.users.data.forEach((user, i) => {
              // テスト心理士を最後に表示に変更
              if (user.id === 107) this.tempCounselors.push(user);
              else this.counselors.push(user);

              if (length === i && accountType === 22) {
                this.getCounselors(21);
              } else if (length === i && accountType === 21) {
                if (this.tempCounselors.length) this.tempCounselors.forEach((u) => { this.counselors.push(u); });
                this.flag.loader = false;
              }
            });
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    changeOpenIds(id) {
      if (this.openIds.includes(id)) {
        const idx = this.openIds.indexOf(id);
        this.openIds.splice(idx, 1);
      } else {
        this.openIds.push(id);
      }
    },
  },
};
</script>

<style lang="scss" module>
.open {
  font-size: 12px;
  font-weight: normal;
  color: var(--gray-side);
  cursor: pointer;
  display: flex;
  justify-content: center;
  i {
    margin-left: 4px;
  }
}
</style>

<template>
  <div v-if="helper.master.labels">
    <spacer :y="5"/>
    <contents-box
      :title="`${helper.master.labels.organizationTypes[is_company]}情報入力`"
      id="confirm">
      <div :class="$style.wrapper">
        <div
          v-for="row in organizationTypes"
          :key="row">
          <div :class="$style.row" v-if="hideDisplay(row)">
            <div :class="$style.label_wrap">
              <p :class="$style.label">{{ row.label }}</p>
              <p v-if="row.required" :class="$style.required">※必須項目</p>
            </div>
            <div
              :class="$style.detail"
              v-if="row.type === 'radio'">
              <ul :class="$style.radio">
                <li
                  v-for="opt in row.options"
                  :key="opt">
                  <input
                    type="radio"
                    :name="row.name"
                    :id="opt.name"
                    :disabled="flag.isConfirm"
                    :value="opt.value"
                    v-model="this[row.name]">
                  <label :for="opt.name">{{ opt.label }}</label>
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div v-for="row in contents" :key="row">
          <div :class="$style.row" v-if="hideDisplay(row)">
            <div :class="$style.label_wrap">
              <p :class="$style.label">
                {{ is_company && row.clabel ? row.clabel : row.label }}
              </p>
              <p v-if="row.required" :class="$style.required">※必須項目</p>
            </div>
            <!-- ラジオボタン（課金モデル） -->
            <div :class="$style.detail" v-if="row.type === 'radio' && row.name === 'billing_type'">
              <ul :class="$style.radio">
                <li v-for="opt in row.options" :key="opt">
                  <input
                    type="radio"
                    :name="row.name"
                    :id="opt.name"
                    :disabled="flag.isConfirm"
                    :value="opt.value"
                    v-model="row.value"
                    v-on:change="updateBillingType">
                  <label :for="opt.name">{{ opt.label }}</label>
                </li>
              </ul>
              <div>
                <spacer :y="2" />
                <ul :class="$style.contract">
                  <li v-for="row in measuredDatas" :key="row"
                    :class="$style.contract_list">
                    <div v-if="judgeDisplay(row)" :class="$style.label_wrap">
                      <p :class="$style.label">{{ row.label }}</p>
                      <p v-if="row.required" :class="$style.required">※必須項目</p>
                    </div>
                    <div v-if="row.inputType === 'radio'">
                      <ul v-if="row.cycle && contents[0].value === 1" :class="$style.radio">
                        <li v-for="opt in row.cycle" :key="opt">
                          <input
                            type="radio"
                            :name="row.label"
                            :id="opt.label"
                            :value="opt.value"
                            v-model="row.value">
                          <label :for="opt.label">{{ opt.label }}</label>
                        </li>
                      </ul>
                    </div>
                    <div v-else-if="row.name === 'contractDate'"><!-- 契約年月日 -->
                      <ul :class="$style.select_wrap">
                        <li>
                          <select
                            :class="$style.select"
                            name="contractDate"
                            id="year"
                            @change="updateContractDate"
                            v-model="row.values.year">
                          <option :value="0"></option>
                          <option
                            v-for="n of 20"
                            :key="n"
                            :value="optionYear(n)">{{ optionYear(n) }}</option>
                          </select>年
                        </li>
                        <li>
                          <select
                            :class="$style.select"
                            name="contractDate"
                            id="month"
                            @change="updateContractDate"
                            v-model="row.values.month">
                          <option :value="0"></option>
                          <option
                            v-for="n of 12"
                            :key="n"
                            :value="n">{{ n }}</option>
                          </select>月
                        </li>
                        <li>
                          <select
                            :class="$style.select"
                            name="contractDate"
                            id="day"
                            @change="updateContractDate"
                            v-model="row.values.day">
                          <option :value="0"></option>
                          <option
                            v-for="n of 31"
                            :key="n"
                            :value="n">{{ n }}</option>
                          </select>日
                        </li>
                      </ul>
                    </div>
                    <input v-else
                      :class="row.inputType === 'text' ? $style.text : $style.text_number"
                      :type="row.inputType"
                      v-model="row.value">
                    <span v-if="row.name === 'textDefaultCount'" :class="[$style.label, $style.text_default_count]"> 回</span>
                  </li>
                </ul>
              </div>
            </div>

            <!-- ラジオボタン（コース） -->
            <!-- <div :class="$style.detail" v-if="row.type === 'radio' && row.name === 'course'">
              <ul :class="$style.radio">
                <li v-for="opt in courseOptions[billing_type]" :key="opt">
                  <input
                    type="radio"
                    :name="row.name"
                    :id="opt.name"
                    :disabled="flag.isConfirm"
                    :value="opt.value"
                    v-model="row.value">
                  <label :for="opt.name">{{ opt.label }}</label>
                </li>
              </ul>
            </div> -->

            <!-- ラジオボタン（上記以外） -->
            <div :class="$style.detail" v-if="row.type === 'radio' && row.name !== 'billing_type' && row.name !== 'course'">
              <ul :class="$style.radio">
                <li v-for="opt in row.options" :key="opt">
                  <input
                    type="radio"
                    :name="row.name"
                    :id="opt.name"
                    :disabled="flag.isConfirm"
                    :value="opt.value"
                    v-model="row.value">
                  <label :for="opt.name">{{ opt.label }}</label>
                </li>
              </ul>
            </div>

            <!-- テキスト -->
            <div :class="$style.detail" v-if="row.type === 'text'">
              <div>
                <input
                  v-if="row.name != 'kana' && row.name != 'kana1'"
                  :class="$style.text"
                  :name="row.name"
                  :disabled="flag.isConfirm"
                  :placeholder="is_company && row.cplaceholder
                    ? row.cplaceholder
                    : row.placeholder"
                  type="text" v-model="row.value">
                <input
                  v-if="row.name === 'kana'"
                  :class="$style.text"
                  :name="row.name"
                  :disabled="flag.isConfirm"
                  :placeholder="row.placeholder"
                  v-model="row.value"
                  v-model.trim="$v.kana.$model"
                  v-on:input="invalidsCheck('kana', $v.kana.$invalid)"
                  type="text">
                <input
                  v-if="row.name === 'kana1'"
                  :class="$style.text"
                  :name="row.name"
                  :disabled="flag.isConfirm"
                  :placeholder="row.placeholder"
                  v-model="row.value"
                  v-model.trim="$v.kana1.$model"
                  v-on:input="invalidsCheck('kana1', $v.kana1.$invalid)"
                  type="text">
              </div>
              <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
              <p v-if="row.name === 'kana'&& $v.kana.$dirty && $v.kana.custom.$invalid" :class="$style.required">カナ文字のみ入力してください</p>
              <p v-if="row.name === 'kana1'&& $v.kana1.$dirty && $v.kana1.custom.$invalid" :class="$style.required">カナ文字のみ入力してください</p>
            </div>

            <!-- 数値 -->
            <div :class="$style.detail" v-if="row.type === 'number'">
              <div :class="$style.number">
                <input
                  :class="$style.text_number"
                  :name="row.name"
                  :disabled="flag.isConfirm || contents[0].value === 2"
                  v-model="row.value"
                  :placeholder="row.placeholder"
                  type="number"
                  :min="row.min">
                <spacer :y="2"/>
                <p :class="$style.label">{{ row.unit }}</p>
                <spacer :y="4"/>
              </div>
              <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
            </div>

            <div :class="$style.detail" v-if="row.name === 'email'">
              <div>
                <input
                  :class="$style.text"
                  :disabled="flag.isConfirm"
                  placeholder="メールアドレス"
                  v-model.trim="$v.mail.$model"
                  id="mail"
                  type="email"
                  name="mail"
                  v-on:input="invalidsCheck('mail', $v.mail.$invalid)">
                <p
                  :class="$style.required"
                  v-if="$v.mail.$dirty
                    && $v.mail.email.$invalid">正しいメールアドレスの形式で入力してください</p>
              </div>
            </div>

            <div :class="$style.detail" v-if="row.name === 'emailUser'">
              <div>
                <input
                  :class="$style.text"
                  :disabled="flag.isConfirm"
                  placeholder="メールアドレス"
                  v-model.trim="$v.mailUser.$model"
                  id="mailUser"
                  type="emailUser"
                  name="mailUser"
                  v-on:input="invalidsCheck('mailUser', $v.mailUser.$invalid)">
                <p
                  :class="$style.required"
                  v-if="$v.mailUser.$dirty
                    && $v.mailUser.required.$invalid">メールアドレスを入力してください</p>
                <p
                  :class="$style.required"
                  v-if="$v.mailUser.$dirty
                    && $v.mailUser.email.$invalid">正しいメールアドレスの形式で入力してください</p>
              </div>
            </div>

            <!--住所-->
            <div :class="$style.detail" v-if="row.type === 'address'">
              <Address
                @sendValue="receiveValue"
                :items="items"
                :isConfirm="flag.isConfirm" />
            </div>
          </div>
        </div>

        <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">{{ helper.master.labels.organizationTypes[is_company] }}副管理者</p>
          </div>
          <spacer :y="1"/>
          <div :class="$style.detail" v-if="!flag.registSubUser">
            <basic-btn
              tag="button"
              size="sm"
              v-on:click="flag.registSubUser = !flag.registSubUser">副管理者を登録する</basic-btn>
          </div>
          <div :class="$style.detail" v-else>
            <contents-box title="副管理者登録" type="inner">
              <div :class="$style.row" v-for="item in subUserItems" :key="item">
                <div :class="$style.label_wrap">
                  <p :class="$style.label">
                    {{ is_company && item.clabel ? item.clabel : item.label }}
                  </p>
                  <p v-if="item.required" :class="$style.required">※必須項目</p>
                </div>
                <div :class="$style.detail">
                  <div>
                    <input
                      v-if="item.name === 'email2'"
                      :class="$style.text"
                      :disabled="flag.isConfirm"
                      v-model.trim="$v.subUser.$model"
                      v-model="item.value"
                      id="subUser"
                      type="subUser"
                      name="subUser"
                      v-on:input="invalidsCheck('subUser', $v.subUser.$invalid)">
                    <input
                      v-else-if="item.name === 'kana2'"
                      :class="$style.text"
                      :disabled="flag.isConfirm"
                      v-model.trim="$v.kana2.$model"
                      v-model="item.value"
                      id="subUser"
                      type="subUser"
                      name="subUser"
                      v-on:input="invalidsCheck('subUser', $v.kana2.$invalid)">
                    <input
                      v-else
                      type="text"
                      v-model="item.value"
                      :class="$style.text">
                  </div>
                </div>
                <div :class="$style.label_wrap"
                  v-if="item.name === 'email2'
                    && $v.subUser.$dirty
                    && $v.subUser.email.$invalid">
                  <p :class="$style.required">正しいメールアドレスの形式で入力してください</p>
                </div>
                <div :class="$style.label_wrap"
                  v-if="item.name === 'kana2'
                    && $v.kana2.$dirty
                    && $v.kana2.$invalid">
                  <p :class="$style.required">カナ文字のみ入力してください</p>
                </div>
              </div>
            </contents-box>
          </div>
        </div>
        <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">メモ（事務局のみ閲覧可）</p>
          </div>
          <spacer :y="1" />
          <div :class="$style.detail">
            <textarea
              :class="$style.textarea"
              v-model="schoolMemo"
              :disabled="flag.isConfirm"
              placeholder="備考"
              rows="5"></textarea>
          </div>
        </div>

      </div>
      <spacer :y="5"/>
      <btn-container>
        <basic-btn
          tag="button"
          v-if="!flag.isConfirm"
          v-on:click="changeFlag">入力内容を確認する</basic-btn>
        <basic-btn
          tag="button"
          v-if="flag.isConfirm"
          v-on:click="changeFlag">編集画面に戻る</basic-btn>
        <spacer :x="2"/>
        <basic-btn
          tag="button"
          v-if="flag.isConfirm"
          v-on:click="regist">入力内容を確定する</basic-btn>
      </btn-container>
    </contents-box>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import { Address } from '@/views/components/parts/Form';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions.js';
import moment from 'moment';

export default {
  name: 'signup',
  mixins: [cf],
  components: {
    BasicBtn,
    BtnContainer,
    ContentsBox,
    Spacer,
    Address,
  },
  data() {
    return {
      flag: {
        isConfirm: false,
        readySubmit: false,
        registSubUser: false,
      },
      validationFlags: {
        mail: false,
        mailUser: false,
        subUser: false,
        kana: false,
        kana1: false,
        kana2: false,
      },
      invalids: {
        mail: true,
        mailUser: true,
        subUser: true,
      },
      billing_type: 'default',
      is_company: 0,
      organizationTypes: [
        {
          type: 'radio',
          name: 'is_company',
          label: '組織タイプ',
          required: true,
          value: 0,
          options: [
            {
              name: 'school',
              label: '学校',
              value: 0,
            },
            {
              name: 'company',
              label: '企業',
              value: 1,
            },
          ],
        },
      ],
      contents: [
        // contents[0]は課金モデルで固定
        {
          type: 'radio',
          name: 'billing_type',
          label: '課金モデル',
          required: true,
          value: 1,
          datatype: 2,
          options: [
            {
              name: 'default',
              label: '従量課金',
              value: 1,
            },
            {
              name: 'account',
              label: 'アカウント課金',
              value: 2,
            },
          ],
        },
        // contents[1]は学校タイプで固定
        {
          type: 'radio',
          name: 'school_type',
          label: '学校タイプ',
          required: true,
          value: 1,
          datatype: 1,
          options: [
            {
              name: 'elementary',
              label: '小学校',
              value: 1,
            },
            {
              name: 'juniorHigh',
              label: '中学校',
              value: 2,
            },
            {
              name: 'high',
              label: '高校',
              value: 3,
            },
            {
              name: 'univ',
              label: '専門・短大・大学',
              value: 4,
            },
            {
              name: 'free',
              label: 'フリースクール',
              value: 5,
            },
          ],
        },
        // contents[2]は初期利用可能回数で固定
        {
          type: 'number',
          name: 'count',
          label: 'オンライン・バーチャル利用可能回数',
          required: false,
          placeholder: '例：24',
          unit: 'クレジット',
          min: 0,
          value: '',
          datatype: 6,
          hideAccount: true,
        },
        {
          type: 'number',
          name: 'textDefaultCount',
          label: 'テキスト基本利用回数',
          required: true,
          placeholder: '例：30',
          unit: '回',
          value: '',
          hideAccount: true,
        },
        // { // オンラインの基本回数はまだ実装しない
        //   inputType: 'number',
        //   label: 'オンライン基本回数',
        //   naem: 'onlineDefaultCount',
        //   value: null,
        //   required: true,
        // },
        {
          type: 'text',
          name: 'name',
          label: '学校名',
          clabel: '企業名',
          required: true,
          placeholder: '例：〇〇高校',
          cplaceholder: '例：〇〇株式会社',
          value: '',
          datatype: 1,
        },
        {
          type: 'text',
          name: 'kana',
          label: 'フリガナ',
          placeholder: '例：◯◯コウコウ',
          cplaceholder: '例：◯◯カブシキガイシャ',
          value: '',
          datatype: 1,
        },
        {
          type: 'text',
          name: 'tel',
          label: '電話番号',
          required: true,
          value: '',
          datatype: 1,
        },
        {
          type: 'text',
          name: 'fax',
          label: 'fax',
          value: '',
          datatype: 1,
        },
        {
          type: 'email',
          name: 'email',
          label: '学校メールアドレス',
          clabel: '企業メールアドレス',
          datatype: 999,
        },
        {
          type: 'address',
          name: 'address',
          label: '住所',
          required: true,
          value: '',
          datatype: 3,
        },
        {
          type: 'text',
          name: 'username1',
          label: '学校管理者名',
          clabel: '企業管理者名',
          required: true,
          placeholder: '例：山田　太郎',
          value: '',
          datatype: 4,
        },
        {
          type: 'text',
          name: 'kana1',
          label: '学校管理者カナ',
          clabel: '企業管理者カナ',
          placeholder: '例：ヤマダ　タロウ',
          value: '',
          datatype: 4,
        },
        {
          type: 'email',
          name: 'emailUser',
          label: '学校管理者メールアドレス',
          clabel: '企業管理者メールアドレス',
          datatype: 999,
          required: true,
        },
        {
          type: 'text',
          name: 'password',
          label: '学校管理者パスワード',
          clabel: '企業管理者パスワード',
          required: true,
          placeholder: 'パスワード',
          value: '',
          datatype: 4,
        },
      ],
      subUserItems: [
        // usernameはsubUserItems[0]
        {
          label: '学校副管理者名',
          clabel: '企業副管理者名',
          name: 'username2',
          value: null,
          required: true,
        },
        {
          label: '学校副管理者カナ',
          clabel: '企業副管理者カナ',
          name: 'kana2',
          value: null,
          required: true,
        },
        {
          label: '学校副管理者メールアドレス',
          clabel: '企業副管理者メールアドレス',
          name: 'email2',
          value: null,
          required: true,
        },
      ],
      items: [
        {
          name: 'zip',
          type: 'text',
          label: '郵便番号',
          value: null,
          required: true,
          placeholder: '1020074',
          comment: '半角・ハイフンなしで入力してください',
        },
        {
          name: 'pref',
          type: 'select',
          label: '都道府県',
          value: null,
          options: [],
          required: true,
          comment: '',
        },
        {
          name: 'city',
          type: 'text',
          label: '市区町村',
          value: null,
          required: true,
          placeholder: '千代田区九段南',
        },
        {
          name: 'address',
          type: 'text',
          label: '番地',
          value: null,
          required: false,
          placeholder: '1-5-6',
        },
        {
          name: 'building',
          type: 'text',
          label: '建物名・部屋番号',
          placeholder: 'りそな九段ビル5階 KSフロア',
          value: null,
        },
      ],
      schoolMemo: null,
      address: {},
      schoolID: null,
      measuredDatas: [
        {
          inputType: 'text',
          label: '契約名',
          name: 'contractName',
          value: null,
          required: false,
        },
        {
          inputType: 'number',
          label: '契約年月日',
          name: 'contractDate',
          values: {
            year: null,
            month: null,
            day: null,
          },
          required: true,
        },
        {
          cycle: [
            {
              label: '月次',
              value: 1,
              checked: true,
            },
            {
              label: '年次',
              value: 2,
              checked: false,
            },
          ],
          inputType: 'radio',
          label: '契約サイクル',
          naem: 'cycle',
          value: 1,
          required: true,
        },
      ],
    };
  },
  setup() {
    const mail = ref('');
    const mailUser = ref('');
    const subUser = ref('');
    const kana = ref('');
    const kana1 = ref('');
    const kana2 = ref('');

    // カタカナ・半角スペース・全角スペースのみ許す
    const kanaTextRule = (value) => /^[ァ-ヶー\s　]+$/u.test(value);

    const rules = {
      // メールはemail形式
      mail: { email },
      mailUser: { required, email },
      subUser: { email },
      kana: {
        custom: kanaTextRule,
      },
      kana1: {
        custom: kanaTextRule,
      },
      kana2: {
        custom: kanaTextRule,
      },
    };

    const $v = useVuelidate(rules, {
      mail, mailUser, subUser, kana, kana1, kana2,
    });

    return {
      mail,
      mailUser,
      subUser,
      kana,
      kana1,
      kana2,
      $v,
    };
  },
  created() {
    if (this.helper.master.prefs) {
      this.items[1].options = this.helper.master.prefs;
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/putMaster') {
          this.items[1].options = mutation.payload.prefs;
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  methods: {
    judgeDisplay(args) {
      let result = true;
      if (args.cycle) {
        if (this.contents[0].value === 2) result = false;
      }
      return result;
    },
    optionYear(n) {
      const year = Number(this.formatTimestamp(new Date(), 'YYYY')) + 2 - n;
      return year;
    },
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
    hideDisplay(row) {
      let showFlag = true;
      if (this.billing_type === 'account') {
        if (row.hideAccount) showFlag = false;
      }
      // 2023/9/8FBでFAXは非表示にしてほしいとのお願い
      if (row.label === 'fax') showFlag = false;
      return showFlag;
    },
    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },
    /** 子から値を受け取る */
    receiveValue(values) {
      this.flag.readySubmit = values.readySubmit;
      this.address = values.address;
    },
    updateBillingType(e) {
      this.billing_type = e.target.id;
      this.contents[1].value = 1;
    },
    /** 必須項目の入力をチェック */
    changeFlag() {
      let filledRequired = true;
      this.contents.forEach((row) => {
        if (row.required) {
          if (row.name === 'address' || row.name === 'emailUser') {
            filledRequired = true;
          } else if (row.value === null || row.value === '') {
            filledRequired = false;
          }
        }
        // アカウント課金モデルの場合、初期利用可能回数は未入力にする
        if (row.name === 'billing_type' && row.value === 2) this.contents[2].value = '';
      });

      if (this.contents[0].value === 1) { // 従量課金の時は契約内容の入力を必須化
        this.measuredDatas.forEach((elem) => {
          if (elem.required) {
            if (elem.name === 'contractDate') {
              Object.keys(elem.values).forEach((data) => {
                if (!elem.values[data]) filledRequired = false;
              });
            } else if (!elem.value) filledRequired = false;
          }
        });
      }

      if (!filledRequired) {
        alert('必須項目が未入力です');
        return;
      }
      if (this.invalids.mail && this.mail !== '') {
        alert('学校のメールアドレスを正しく入力してください。');
        return;
      }
      if (this.invalids.mailUser) {
        alert('学校管理者のメールアドレスは必須項目です。正しく入力してください。');
        return;
      }
      if (!this.flag.readySubmit) {
        alert('住所の項目に不備があります。');
        return;
      }
      if (this.flag.registSubUser && (!this.subUserItems[0].value || this.subUserItems[0].value === '')) {
        alert('副管理者の名前を入力してください');
        return;
      }
      if (this.flag.registSubUser && (!this.subUserItems[1].value || this.subUserItems[1].value === '')) {
        alert('副管理者のカナを入力してください');
        return;
      }
      if (this.flag.registSubUser && (!this.subUserItems[2].value || this.subUserItems[2].value === '')) {
        alert('副管理者のメールアドレスを入力してください');
        return;
      }

      this.flag.isConfirm = !this.flag.isConfirm;
      if (this.flag.isConfirm) {
        const elem = document.getElementById('confirm');
        const position = elem.getBoundingClientRect().top;
        scrollBy(0, position - 77);
      }
    },

    /** 登録 */
    regist() {
      this.showLoading();
      const data = {
        mainUserData: {},
        billingTypeData: {},
        countHistoriesData: {},
        countHistoriesTextData: {},
      };
      this.contents.forEach((row) => {
        if (row.datatype === 1) {
          data[row.name] = row.value;
        } else if (row.datatype === 2) {
          data.billingTypeData[row.name] = row.value;
        } else if (row.datatype === 4) {
          data.mainUserData[row.name] = row.value;
        } else if (row.datatype === 6) {
          data.countHistoriesData[row.name] = row.value;
        }
      });
      data.email = this.mail;
      data.mainUserData.email1 = this.mailUser;
      data.is_company = this.is_company;

      // courseは入力されないので5の0回を入力してあげる
      data.billingTypeData.course = 5;

      if (this.flag.registSubUser) {
        data.subUserData = {};
        this.subUserItems.forEach((row) => {
          data.subUserData[row.name] = row.value;
        });
      }
      this.axios({
        method: 'POST',
        url: '/v1/school/set/register',
        data,
      })
        .then((response) => {
          this.schoolID = response.data.schoolID;
          this.registAddress();
          this.registBillingType(data.billingTypeData);
          this.registContract(data.billingTypeData);
          if (data.countHistoriesData.count) {
            this.registCountHistories(data.countHistoriesData);
          }
          if (this.billing_type === 'default') {
            this.setTextCredit();
          }
          if (this.schoolMemo) {
            this.registSchoolMemo();
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          this.hideLoading();
        });
    },

    registAddress() {
      const data = this.address;
      data.model_type = 2;
      data.foreign_id = this.schoolID;
      this.axios({
        method: 'POST',
        url: '/v1/address/set/register',
        data,
      })
        .then(() => {
          alert('登録が完了しました。');
          this.hideLoading();
          this.$router.push('/operator/?tab=schools');
        })
        .catch((error) => {
          console.log(error);
          this.hideLoading();
          alert('学校の登録は成功しましたが、学校の住所の登録に失敗しました。');
        });
    },

    registBillingType(billingTypedata) {
      const data = billingTypedata;
      data.school_id = this.schoolID;
      this.axios({
        method: 'POST',
        url: '/v1/school/billingtype/set/register',
        data,
      })
        .then(() => {
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.hideLoading();
          alert('課金モデルの登録に失敗しました。');
        });
    },

    registCountHistories(countHistoriesData) {
      const data = countHistoriesData;
      data.billing_type = 1; // アカウント課金モデルの場合はこのメソッドは叩かれない
      data.foreign_id = this.schoolID;
      data.is_add = 1; // 事務局加算扱い
      this.axios({
        method: 'POST',
        url: '/v1/count/history/set/register',
        data,
      })
        .then(() => {
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.hideLoading();
          alert('初期利用可能回数の登録に失敗しました。\nお手数ですが、学校情報更新ページから手動で回数を追加してください。');
        });
    },

    registContract(billingTypeData) {
      // 2024-4-1等をmomentに渡すとInvalid dateになってしまう
      const y = this.measuredDatas[1].values.year;
      const m = String(this.measuredDatas[1].values.month).length === 1
        ? `0${this.measuredDatas[1].values.month}`
        : this.measuredDatas[1].values.month;
      const d = String(this.measuredDatas[1].values.day).length === 1
        ? `0${this.measuredDatas[1].values.day}`
        : this.measuredDatas[1].values.day;
      const contractDate = `${y}-${m}-${d}`;
      let expiry;
      if (this.measuredDatas[2].value === 1) {
        expiry = moment(contractDate).add(30, 'd').format('YYYY-MM-DD');
      } else {
        expiry = moment(contractDate).add(1, 'years').format('YYYY-MM-DD');
      }
      const data = {
        school_id: this.schoolID,
        name: this.measuredDatas[0].value,
        contract_date: contractDate,
        expiry,
        cycle: this.measuredDatas[2].value,
      };

      if (billingTypeData.billing_type === 1) { // 従量課金モデルの場合はテキスト、オンラインの基本回数が存在する
        data.base_text = this.contents[3].value;
        // data.base_online = this.contents[4].value;
      } else {
        data.base_text = 0;
        // data.base_online = 0;
      }

      this.axios({
        method: 'POST',
        url: '/v1/contract/set/register',
        data,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.hideLoading();
          alert('契約情報の登録に失敗しました。');
        });
    },

    // 学校メモの記述があったら登録
    registSchoolMemo() {
      const data = {
        school_id: this.schoolID,
        memo: this.schoolMemo,
      };

      this.axios({
        method: 'POST',
        url: '/v1/schoolMemo/set/register',
        data,
      })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          alert('メモの登録に失敗しました。');
        });
    },

    setTextCredit() {
      const data = {
        school_id: this.schoolID,
        count: this.contents[3].value,
        is_add: 1, // 1:特別加算、0:通常（使用）
      };

      this.axios({
        method: 'POST',
        url: '/v1/count/historyText/set/register',
        data,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module>
.row {
  padding: 24px 0;
  border-top: 1px solid var(--gray-main);
}
.label {
  border-radius: 4px;
  margin-right: 4px;
  font-weight: bold;
  display: inline-block;
  &_wrap {
    display: flex;
    align-items: baseline;
  }
}
.text, .select {
  &:disabled {
    color: black;
    background-color: transparent;
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }
  }
}
.required {
  font-size: 12px;
  color: red;
}
.assistant {
  font-size: 12px;
}
.textarea {
  width: 100%;
  resize: vertical;
  min-height: 152.5px;
  border: none;
  outline: none;
  background-color: var(--gray-sub);
  border-radius: 10px;
  padding: 20px;
}
.space {
  padding-top: 6px;
}
.text {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
  &_number {
    width: 20%;
    padding: 13px;
    border-radius: 8px;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
    $background: var(--gray-sub);
    text-align: right;
  }
}
.radio {
  padding-top: 8px;
  display: flex;

  li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
.select {
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;
  appearance: none;

  &_wrap {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.tel {
  width: 70px;
  padding: 13px;
}
.number {
  display: flex;
  align-items: baseline;
  ::placeholder {
    text-align: left;
  }
}
.contract {
  padding-left: 1em;
  &_list {
    padding-top: 20px;
  }
}
.text_default_count {
  padding-left: .5em;
}
</style>

<template>
  <div>
    <spacer :y="5"/>
    <ul>
      <stack-item>
        <contents-box type="inner">
          <div :class="$style.register">
            <h3>新規お知らせ登録</h3>
            <basic-btn
              tag="button"
              v-if="editId !== 0"
              v-on:click="editStart( { id: 0 })">登録する</basic-btn>
            <basic-btn
              tag="button"
              type="bdr"
              size="sm"
              v-if="editId === 0"
              v-on:click="editStart({ id: null })">閉じる</basic-btn>
          </div>
          <div v-if="editId === 0">
            <spacer :y="2"/>
            <div>
              <select
                :class="$style.target"
                name="target"
                id="target"
                :disabled="flag.isConfirm"
                v-on:change="changeTarget">
                <option
                  v-for="row in targets"
                  :key="row"
                  :value="row.value"
                  :selected="target === row.value">{{row.label}}</option>
              </select>

              <input
                type="checkbox"
                id="important"
                :checked="important"
                :class="$style.important_input"
                :disabled="flag.isConfirm"
                v-model="important"
                v-on:click="changeImportant">
              <label
                for="important"
                :class="importantClassName">重要</label>
            </div>

            <spacer :y="2"/>
            <div :class="$style.publishdate">
              <p>公開日設定</p>
              <spacer :y="1"/>
              <calendar
                :information="true"
                :class="$style.calendar"
                :initialDate="publish_date"
                v-on:sendDate="receiveDate" />
            </div>

            <spacer :y="2"/>
            <input
              type="text"
              name="title"
              id="title"
              placeholder="タイトルを入力してください。"
              v-model="title"
              :disabled="flag.isConfirm"
              :class="$style.textinput">

            <spacer :y="2"/>
            <Editor
              name="content"
              id="content"
              rows="5"
              v-model="content"
              placeholder="投稿内容を入力してください。"
              :disabled="flag.isConfirm"
              :class="$style.textarea"
              v-bind:api-key="TINYMCE_API_KEY"
              v-bind:init="editorConf"
            />
            <spacer :y="3"/>

            <btn-container
              v-if="!flag.isConfirm">
              <basic-btn
                tag="button"
                :disabled="!publish_date"
                v-on:click="regist($event, 1)">投稿{{ isFutureDate ? '予約' : '' }}する</basic-btn>
              <spacer :x="2"/>
              <basic-btn
                tag="button"
                type="bdr"
                v-on:click="regist($event, 2)">下書き保存</basic-btn>
              <spacer :x="2"/>
            </btn-container>
          </div>
        </contents-box>
      </stack-item>


      <stack-item id="scroll-top">
        <contents-box title="公開中">
          <loader-simple :flag="flag.loader">
            <ul v-if="informations.length">
              <stack-item
                space="2"
                v-for="n of count"
                :key="n">
                <contents-box type="inner">
                  <div :class="$style.info_wrap">
                    <div :class="$style.info_title">
                      <div :class="$style.labels">
                        <label-box
                          :label="filterMoment(informations[infoIdx(n)].publish_date, 'YYYY-MM-DD')"
                          size="sm"
                          :color="checkFutureDate(informations[infoIdx(n)].publish_date) ? 'blue' : 'orange'"
                          :class="`${$style.label} ${$style.date}`" />
                        <label-box
                          label="重要"
                          size="sm"
                          color="pink"
                          v-if="[11, 12, 13].includes(informations[infoIdx(n)].flag)"
                          :class="$style.label" />
                      </div>

                      <spacer :class="$style.hideSp" :x="1"/>
                      <div :class="$style.title">
                        <span>{{ informations[infoIdx(n)].title }}</span>
                        <span
                          v-if="helper.master"
                          :class="$style.assistant">（{{ helper.master.labels.informations.target[informations[infoIdx(n)].target] }}・{{ helper.master.labels.informations.flag[informations[infoIdx(n)].flag] }}）</span>
                      </div>
                    </div>
                    <i
                      :class="$style.pencil"
                      class="fa-solid fa-pen-to-square"
                      v-if="editId !== informations[infoIdx(n)].id"
                      v-on:click="editStart(informations[infoIdx(n)])"/>
                    <i
                      :class="$style.pencil"
                      class="fa-regular fa-circle-xmark"
                      v-if="editId === informations[infoIdx(n)].id"
                      v-on:click="editStart({ id: null })"/>
                  </div>
                  <div v-if="editId === informations[infoIdx(n)].id">
                    <div>
                      <spacer :y="2"/>
                      <select
                        :class="$style.target"
                        name="target"
                        id="target"
                        :disabled="flag.isConfirm"
                        v-on:change="changeTarget">
                        <option
                          v-for="row in targets"
                          :key="row"
                          :value="row.value"
                          :selected="target === row.value">{{row.label}}</option>
                      </select>
                      <input
                        type="checkbox"
                        id="important"
                        :checked="important"
                        :class="$style.important_input"
                        :disabled="flag.isConfirm"
                        v-model="important"
                        v-on:click="changeImportant">
                      <label
                        for="important"
                        :class="importantClassName">重要</label>
                    </div>

                    <spacer :y="2"/>
                    <div :class="$style.publishdate">
                      <p>公開日変更</p>
                      <spacer :y="1"/>
                      <calendar
                        :information="true"
                        :class="$style.calendar"
                        :initialDate="publish_date"
                        v-on:sendDate="receiveDate" />
                    </div>

                    <spacer :y="2"/>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      placeholder="タイトルを入力してください。"
                      v-model="title"
                      :disabled="flag.isConfirm"
                      :class="$style.textinput">
                    <spacer :y="1"/>

                    <Editor
                      name="content"
                      id="content"
                      v-model="content"
                      placeholder="投稿内容を入力してください。"
                      :disabled="flag.isConfirm"
                      :class="$style.textarea"
                      v-bind:api-key="TINYMCE_API_KEY"
                      v-bind:init="editorConf"
                    />

                    <spacer :y="3"/>
                    <btn-container
                      v-if="!flag.isConfirm">
                      <basic-btn
                        tag="button"
                        :disabled="!publish_date"
                        v-on:click="regist($event, 1)">投稿{{ isFutureDate ? '予約' : '' }}する</basic-btn>
                      <spacer :x="2"/>
                      <basic-btn
                        tag="button"
                        type="bdr"
                        v-on:click="regist($event, 2)">下書き保存</basic-btn>
                      <spacer :x="2"/>
                      <basic-btn
                        tag="button"
                        type="bg"
                        :addClass="'danger'"
                        v-on:click="regist($event, 999)">削除</basic-btn>
                    </btn-container>
                  </div>
                </contents-box>
              </stack-item>
              <spacer :y="4" v-if="informations.length"/>
              <pagination
                :page="page"
                :pageMax="pageMax"
                :path="'/operator/?tab=information'"
                v-if="informations.length && pageMax !== 1" />
            </ul>
          </loader-simple>
        </contents-box>
      </stack-item>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import Editor from '@tinymce/tinymce-vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import Spacer from '@/views/components/Spacer.vue';
import Calendar from '@/views/components/DatePicker.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import cf from '@/mixins/commonFunctions';
import Pagination from '@/views/components/Pagination.vue';

const TINYMCE_API_KEY = process.env.VUE_APP_TINYMCE_API_KEY;

export default {
  name: 'operator-news',
  mixins: [cf],
  components: {
    Editor,
    BasicBtn,
    BtnContainer,
    LabelBox,
    ContentsBox,
    StackItem,
    Spacer,
    Calendar,
    LoaderSimple,
    Pagination,
  },
  data() {
    return {
      TINYMCE_API_KEY,
      flag: {
        isConfirm: false,
        loader: true,
        pageQuery: false,
      },
      infoflag: 1,
      editId: null,
      important: false,
      title: null,
      content: null,
      publish_date: null,
      target: 1,
      targets: [
        {
          label: '全て',
          value: 1,
        },
        {
          label: '利用者',
          value: 2,
        },
        {
          label: '学校・企業管理者',
          value: 3,
        },
        {
          label: '医師・心理士',
          value: 4,
        },
      ],
      informations: [],
      editorConf: {
        selector: 'textarea',
        content_css: '/editor/css/editor.css',
        language: 'ja',
        language_url: '/editor/langs/ja.js',
        menubar: false,
        plugins: 'lists link image code textcolor autolink',
        toolbar: 'styleselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | link | undo redo | code',
        height: 400,
      },
      page: 1,
      pageMax: 1,
      num: 20,
    };
  },
  created() {
    const query = this.$route.query;
    if (query.page) {
      this.page = Number(query.page);
    } else if (query.tab === 'information') {
      // queryにpageがなければ追加してreplace
      this.$router.replace('/operator/?tab=information&page=1');
    }
    this.getInformations();
  },
  watch: {
    $route() {
      const query = this.$route.query;
      if (query.page) {
        this.page = Number(query.page);
      } else if (query.tab === 'information') {
        // queryにpageがなければ追加してreplace
        this.$router.replace('/operator/?tab=information&page=1');
      }
      this.getInformations();
      this.flag.pageQuery = true;
    },
  },
  computed: {
    ...mapState(['user', 'helper']),
    importantClassName() {
      if (this.important) return `${this.$style.important_label} ${this.$style.checked}`;
      return `${this.$style.important_label}`;
    },
    count() {
      let count = this.num;
      if (this.page !== this.pageMax) count = this.num;
      // 割り切れて0になる（全部でthis.num件の）場合もthis.num件表示
      else if (this.informations.length % this.num === 0) count = this.num;
      else count = this.informations.length % this.num;
      return count;
    },
    isFutureDate() {
      return Boolean(this.publish_date && moment(this.publish_date).isAfter(moment()));
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
    checkFutureDate(date) {
      return moment(date).isAfter(moment());
    },
    infoIdx(n) {
      return this.num * (this.page - 1) + n - 1;
    },
    changeImportant() {
      this.important = !this.important;
    },
    editStart(item) {
      // console.log(item);
      this.isConfirm = false;
      this.editId = item.id;
      if (!item.id) {
        this.title = null;
        this.content = null;
        this.target = 1;
        this.infoflag = 1;
        this.publish_date = moment().format('YYYY-MM-DD');
      } else {
        this.title = item.title;
        this.content = item.content;
        this.target = item.target;
        this.infoflag = item.flag;
        this.important = [11, 12, 13].includes(item.flag) || false;
        this.publish_date = moment(item.publish_date).format('YYYY-MM-DD');
      }
    },
    changeTarget(e) {
      this.target = Number(e.target.value);
    },
    changeFlag() {
      this.flag.isConfirm = !this.flag.isConfirm;
    },

    /** カレンダーからの値受け取り */
    receiveDate(date) {
      this.publish_date = date;
    },

    /** お知らせ登録 */
    regist(e, flag) {
      e.preventDefault();
      if (!this.publish_date) {
        alert('公開日を設定してください。');
        return;
      }
      this.showLoading();

      let flagControl = flag;
      // 重要&公開 => 11 / 重要&下書き => 12
      if (flag !== 999 && this.important) flagControl += 10;
      if (flagControl === 1 && this.isFutureDate) flagControl = 3; // 通常の公開予約
      if (flagControl === 11 && this.isFutureDate) flagControl = 13; // 重要な公開予約

      const data = {
        id: this.editId === 0 ? null : this.editId,
        user_id: this.user.id,
        flag: flagControl,
        target: this.target,
        title: this.title,
        content: this.content,
        publish_date: this.publish_date,
      };

      const endpoint = this.editId ? 'updater' : 'register';

      let nowFlag = null;
      if (this.editId) {
        const nowInfoData = this.informations.filter((info) => info.id === this.editId);
        nowFlag = nowInfoData.length ? nowInfoData[0].flag : null;
      }

      this.axios({
        method: 'POST',
        url: `/v1/information/set/${endpoint}`,
        data,
      })
        .then(async () => {
          if (
            [1, 3, 4].includes(this.target) // 利用者だけの場合以外
            && [1, 11].includes(flagControl) // 「投稿する」ボタン押下時(投稿予約は除く)
            && (!data.id || (data.id && nowFlag && !([1, 11].includes(nowFlag))))
            // 新規登録時、または、登録済みかつ登録済みのフラグが「公開」以外のとき(今回の更新で「公開」にしたとき)
          ) {
            await this.operatorInformationContact(this.target);
          }
          // 初期化
          this.flag.isConfirm = false;
          this.flag.loader = true;
          this.infoflag = 1;
          this.editId = null;
          this.important = false;
          this.title = null;
          this.content = null;
          this.publish_date = null;
          this.target = 1;
          this.informations = [];
          this.getInformations();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    /** 該当者に新規お知らせの連絡 */
    operatorInformationContact(target) {
      // 対象者へ連絡
      this.axios({
        method: 'POST',
        url: '/v1/contact/regist/operatorInformation',
        data: {
          target,
        },
      })
        .then((response) => {
          if (this.helper.env.name === 'local') {
            console.log('お知らせを送りました');
            console.log(response.data);
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /** お知らせ取得 */
    getInformations() {
      this.axios({
        method: 'GET',
        url: '/v1/information/get/list',
      })
        .then((response) => {
          this.informations = response.data.data.data;
          this.pageMax = Math.ceil(this.informations.length / this.num);
          this.flag.loader = false;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          if (this.flag.pageQuery) this.scroll2Top();
          this.flag.pageQuery = false;
          this.flag.loader = false;
        });
    },

    scroll2Top() {
      // 上までスクロール
      const elem = document.getElementById('scroll-top');
      const position = elem.getBoundingClientRect().top;
      scrollBy(0, position - 77);
    },
  },
};
</script>

<style lang="scss" module>
.register {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include sm-view {
    display: block;
  }
}
.target {
  padding: 12px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  margin-right: 20px;
}
.important {
  &_input {
    display: none;
  }
  &_label {
    padding: 12px 18px;
    border: 1px solid var(--gray-main);
    color: var(--gray-main);
    border-radius: 30px;
    font-size: 14px;

    &.checked {
      background-color: var(--pink);
      color: #fff;
      border: none;
      font-weight: bold;
    }
  }
}
.textinput {
  padding: 20px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  width: 100%;
  border-radius: 8px;
}
.textarea {
  padding: 20px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  width: 100%;
  border-radius: 8px;
}
.info_title {
  display: flex;
  align-items: flex-start;
  @include sm-view {
    display: block;
  }
}
.labels {
  display: flex;
  .label {
    width: 80px;
    &.date {
      width: 120px;
    }
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

}
.flex {
  display: flex;
  align-items: flex-start;

  @include sm-view {
    display: block;
  }
}
.hideSp {
  @include sm-view {
    display: none;
  }
}
.title {
  flex: 1;
}
.info_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pencil {
  font-size: 20px;
  color: var(--gray-side);
  cursor: pointer;
}
.publishdate {
  > p {
    font-weight: bold;
  }
  .calendar {
  }
}
.assistant {
  font-size: 12px;
}
.pagenation {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray-side);
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    margin: 0 10px;
    border-radius: 50%;
    border: 1px solid var(--orange-main);
    color: var(--orange-main);
    cursor: pointer;
    &.selected {
      background-color: var(--orange-main);
      color: #fff;
    }
    span {
      cursor: pointer;
    }
  }
  i {
    margin: 0 15px;
    font-size: 30px;
    color: var(--orange-main);
    cursor: pointer;
    &.invalid {
      color: var(--gray-main);
      cursor: initial;
    }
  }
}
</style>

<template>
  <div :class="$style.container">
    <header :class="$style.header">
      <h1 :class="$style.logo">
        <router-link
          :class="$style.logoLink"
          :to="user.account_type > 30 ? '/operator/'
            : user.account_type > 20 ? '/counselor/'
            : user.account_type > 10 ? '/teacher/'
            : user.account_type ? '/'
            : ''"
            @click="deleteTabState">
          <img
            :class="$style.logoImg"
            :src="require('@/assets/images/logo.png')"
            alt="welcome to talk"
          />
        </router-link>
      </h1>

      <div
        :class="$style.nav"
        v-if="user.login.isLogin
          && !page.name.includes('Signup')">
        <div :class="`${$style.navItem} ${$style.hiddenItem}`">
          <round-img
            v-if="!user.lti"
            width="40px"
            :src="getMyIconUrl(user)" />
          <round-img-LTI
            v-else
            :src="getMyIconUrl(user)"
            :size="'xs'" />
          <spacer :x="1.5" />
          <p
            :class="$style.navItem__label"
            v-if="(user.customer_id && user.account_type <= 10)">{{ user.customer_id }}</p>
          <spacer :x="1.5" />
          <p
            :class="$style.navItem__label"
            v-if="user.account_type > 30">事務局サイト</p>
          <p
            :class="$style.navItem__label"
            v-else-if="[11, 12].includes(user.account_type)" v-html="user.school[0].name"></p>
          <p v-else v-html="user.username"></p>
        </div>
        <spacer :x="3" />
        <div :class="`${$style.navItem} ${$style.hiddenItem}`">
          <router-link
            :to="user.account_type > 30 ? '/account/'
              : user.account_type > 20 ? '/counselor/account/'
              : user.account_type > 10 ? '/teacher/school/'
              : '/settings/'"
            @click="deleteTabState">
            <round-img width="40px" src="/img/dummy/setting.png" />
          </router-link>
        </div>
        <spacer :x="3" />
        <div :class="$style.navItem">
          <button
            @click="toggleIsShownMenu"
            :class="$style.triggerDrawer"
          >
            <i class="fa-solid fa-bars" />
          </button>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import RoundImg from '@/views/components/RoundImg';
import RoundImgLTI from '@/views/components/RoundImg-LTI';
import Spacer from '@/views/components/Spacer';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'GlobalHeader',
  components: {
    RoundImg,
    RoundImgLTI,
    Spacer,
  },
  mixins: [cf],
  computed: {
    ...mapState(['user', 'layout', 'page']),
  },
  methods: {
    ...mapActions('layout', [
      'toggleIsShownMenu',
    ]),
    /** LSにtab情報があれば削除 */
    deleteTabState() {
      cf.deleteTabState();
    },
  },
};
</script>

<style module lang="scss">
.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 20px;
  background-color: var(--primary-color);
  color: #fff;
  z-index: 3;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

  @include sm-view {
    padding: 0 16px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
}

.logo {
  max-width: 250px;

  @include sm-view {
    max-width: 170px;
  }
}

.nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navItem {
  display: flex;
  align-items: center;

  @include sm-view {
    &.hiddenItem {
      display: none;
    }
  }
}

.navItem__label {
  font-size: 16px;
  color: #fff;
}

.triggerDrawer {
  color: #fff;
  font-size: 30px;
  background-color: transparent;
  border: none;
  appearance: none;

  @include sm-view {
    width: 30px;
  }
}

</style>

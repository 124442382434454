<template>
  <stack-item
    v-if="user.school && user.school[0]
      && user.school[0].billing_type
      && user.school[0].billing_type.billing_type === 2">
    <contents-box title="オンライン健康相談" icon="<i class='fa-solid fa-user-doctor'></i>">
      <div :class="$style.flex_center">
        <div :class="$style.count_wrap">
          <div
            v-for="(count, countType) in user.count"
            :key="countType">
            <p
              :class="$style.count"
              v-if="countType !== 'credit'">
              <span>{{ countType === 'doctor' ? '医師': '心理士' }}</span>{{ count }}<span>回</span>
            </p>
          </div>
        </div>
      </div>
    </contents-box>
  </stack-item>
</template>

<script>
import { mapState } from 'vuex';
import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';


export default {
  name: 'limit-count-for-billing-account',
  props: {
  },
  components: {
    StackItem,
    ContentsBox,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
  },
};
</script>

<style lang="scss" module>
.flex_center {
  display: flex;
  justify-content: center;
}
.count {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
  &_wrap {
    display: flex;
    @include sm-view {
      display: block;
    }
  }
  span {
    font-size: 14px;
    margin: 12px;
    font-weight: normal;
    &:first-of-type {
      margin-right: .5em;
    }
    &:last-of-type {
      margin-left: .5em;
    }
  }

  &_text {
    font-size: 12px;
    font-weight: bold;
  }
}
</style>

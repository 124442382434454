/**
* スケジュール
*/
import axios from '@/plugins/axios';

const actions = {
  /** ページ情報のセット */
  setSchedule({ commit }, params = {}) {
    axios({
      method: 'GET',
      url: '/v1/schedule/get/list',
      params,
    })
      .then((response) => {
        const schedule = response.data.schedules.data;
        commit('setSchedule', schedule);
      })
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      });
  },
};


const mutations = {
  setSchedule(state, data) {
    state.list = data;
  },
};

const state = {
  list: [],
};


export default {
  namespaced: true,
  actions,
  mutations,
  state,
};

<template>
  <div>
    <loader-simple :flag="!backendData">
      <contents-box type="inner">
        <div v-if="!backendData" :class="$style.longer_box"> </div>
        <div v-if="backendData">
          <div :class="$style.graph_contents_title">ユーザー</div>
          <table v-if="accountsData && accountsData.allAccounts" :class="$style.analyticsTable">
            <tr>
              <th rowspan="2" :class="$style.dark">地域</th>
              <td colspan="3" :class="[$style.dark, $style.alg_center]">
                登録アカウント
                <span v-for="data in displayRanges" :key="data">
                  <span :class="$style.sub_contents_wide" v-if="displayRange === data.name">{{ data.label }}</span>
                </span>
                <span :class="$style.sub_contents_exwide" v-if="includeInactive">非アクティブ含む</span>
              </td>
            </tr>
            <tr>
              <td :class="[$style.dark, $style.alg_center]">ユーザー</td>
              <td :class="[$style.dark, $style.alg_center, $style.retention]">ユーザー維持率　
                <GuideIcon
                  icon="fa-regular fa-circle-question"
                  boxSize="450"
                  hoverText="3か月以内にココモニ、テキスト、オンラインを利用したユニークユーザー数/ユーザー×100" />
              </td>
              <td :class="[$style.dark, $style.alg_center]">WHO-5</td>
            </tr>
            <tr :class="$style.on_color_tr">
              <th :class="$style.smoke">全国</th>
              <td :class="[$style.smoke, $style.alg_right]">
                {{ accountsData.allAccounts.all }}人
                <span :class="$style.sub_contents">(100%)</span>
              </td>
              <td :class="[$style.smoke, $style.alg_right]">
                {{ calcPercentageDecimal(accountsData.allAccounts.retentionRateAll, accountsData.allAccounts.all) }}%
                <span :class="$style.sub_contents_thin">
                  ({{ accountsData.allAccounts.retentionRateAll }}人)
                </span>
              </td>
              <td :class="[$style.smoke, $style.alg_right]">
                {{ accountsData.allAccounts.allWho5ave }}点
                <span :class="$style.sub_contents_thin">ー</span>
              </td>
            </tr>
            <tr v-for="(value, key, index) in accountsData.allAccounts.allData"
              :key="key"
              :class="$style.on_color_tr">
              <th :class="[$style.smoke, $style.area_title_header]">
                <span :class="$style.sub_contents">{{ index + 1 }}.</span>
                <span :class="$style.area_title_header_pref">{{ key === 'null' ? '情報なし' : key}}</span>
              </th>
              <td :class="[$style.smoke, $style.alg_right]">
                {{ value.userCount }} 人
                <span :class="$style.sub_contents">({{ calcPercentageDecimal(value.userCount, accountsData.allAccounts.all) }}%)</span>
              </td>
              <td :class="[$style.smoke, $style.alg_right]">
                {{ value.retentionalPercentage }}%
                <span :class="$style.sub_contents">({{ value.retentionRate }}人)</span>
              </td>
              <td :class="[$style.smoke, $style.alg_right]">
                {{ value.who5ave }}点
                <span :class="$style.sub_contents_thin">
                  <i v-if="value.who5ave > accountsData.allAccounts.allWho5ave" class="fa-solid fa-up"></i>
                  <i v-else class="fa-solid fa-down"></i>
                </span>
              </td>
            </tr>
          </table>
          <div :class="$style['col-3']">
            <div>
              <p :class="$style.filter_title">表示範囲</p>
              <div :class="$style.filter_contents">
                <ul :class="$style.btns">
                  <li
                    v-for="row in displayRanges"
                    :key="row.name">
                    <input
                      type="radio"
                      name="displayRange"
                      :id="row.name"
                      :value="row.name"
                      v-model="displayRange"
                      :checked="displayRange === row.name"
                      @change="sendData">
                    <label
                      :for="row.name"
                      :class="$style.btn">{{ row.label }}</label>
                  </li>
                </ul>
              </div>
            </div>

            <div :class="$style.filter_flex">
              <div>
                <p :class="$style.filter_title">ソート順</p>
                <div :class="$style.filter_contents">
                  <select
                    :class="$style.select_box"
                    name="sortedOrder"
                    id="sortedOrder"
                    v-model="sortedOrder"
                    @change="sendData">
                    <option
                      v-for="row in sortedOrders"
                      :key="row.name"
                      :value="row.name"
                      :selected="sortedOrder=== row.name">{{ row.label }}</option>
                  </select>
                </div>
              </div>
              <div :class="$style.inactive_box">
                <input type="checkbox"
                  id="inactive_box"
                  :class="$style.inactive_box_checkbox"
                  v-model="includeInactive"
                  @change="sendData">
                <label for="inactive_box"
                  :class="includeInactive ? $style.inactive_box_label_active : $style.inactive_box_label">非アクティブを含める</label>
              </div>
            </div>
          </div>
        </div>
      </contents-box>
    </loader-simple>
    <spacer :y="1" />
    <contents-box v-if="backendData" type="inner">
      <!-- 都道府県別利用アカウントの棒グラフ -->
      <div
        v-if="backendData"
        :class="$style.pref_flex">
        <div :class="$style.pref_flex_right">
          <contents-box
            type="inner"
            style="height: 100%">
            <div :class="$style.graph_contents">
              <div :class="$style.graph_contents_title">ユーザーの動向</div>
              <div :class="$style.graph_contents_table">
                <table>
                  <tr>
                    <th rowspan="2">都道府県</th>
                    <th rowspan="2">新規ユーザー</th>
                    <th colspan="3">利用状況</th>
                    <th rowspan="2">WHO-5</th>
                  </tr>
                  <tr>
                    <th>ココモニ</th>
                    <th>テキスト</th>
                    <th>オンライン</th>
                  </tr>
                  <tr
                    v-for="row in backendData.area.table"
                    :key="row.pref">
                    <th>{{ row.pref === '' ? '情報なし' : row.pref }}</th>
                    <td :class="$style.alg_right">{{ row.user }}人</td>
                    <td :class="$style.alg_right">{{ row.cocomoni }}回</td>
                    <td :class="$style.alg_right">{{ row.texts }}件</td>
                    <td :class="$style.alg_right">{{ row.onlines }}件</td>
                    <td v-if="row.pref === '全国'" :class="$style.alg_right">{{ backendData.month.graph.common.datasets[0].data[0] }}点</td> <!-- 各都道府県の平均点の平均点 != 全国の平均点 -->
                    <td v-else :class="$style.alg_right">{{ row.who5 || 0 }}点</td>
                  </tr>
                </table>
              </div>
            </div>
            <div :class="$style['col-3']">
            <div>
              <p :class="$style.filter_title">表示範囲</p>
              <div :class="$style.filter_contents">
                <ul :class="$style.btns">
                  <li
                    v-for="item in displayTrends"
                    :key="item.name">
                    <input
                      type="radio"
                      name="displayTrend"
                      :id="item.name"
                      :value="item.name"
                      v-model="displayTrend"
                      :checked="displayTrend === item.name"
                      @change="sendTrend">
                    <label
                      :for="item.name"
                      :class="$style.btn">{{ item.label }}</label>
                  </li>
                </ul>
              </div>
            </div>

            <div :class="$style.filter_flex">
              <div>
                <p :class="$style.filter_title">ソート順</p>
                <div :class="$style.filter_contents">
                  <select
                    :class="$style.select_box"
                    name="sortedTrend"
                    id="sortedTrend"
                    v-model="sortedTrend"
                    @change="sendTrend">
                    <option
                      v-for="row in sortedTrends"
                      :key="row.name"
                      :value="row.name"
                      :selected="sortedTrend=== row.name">{{ row.label }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          </contents-box>
        </div>
      </div>
      <spacer :y="2" />
      <div v-if="backendData">
        <contents-box
          type="inner">
          <div :class="$style.graph_contents">
            <div :class="$style.graph_contents_title">ステータス</div>
            <spacer :y="2" />
            <mix-graph
              :labels-data="backendData.month.graph.common.labels"
              :datasets-data="backendData.month.graph.common.datasets" />
          </div>
        </contents-box>
      </div>
      <spacer :y="2" />
      <div :class="$style.card_container">
        <div :class="$style.card">
          <contents-box type="inner" style="height: 100%">
            <div
              :class="$style.graph_contents"
              v-if="backendData">
              <div :class="$style.graph_contents_title">相談方法</div>
              <spacer :y="2" />
              <div :class="$style.graph_contents_fiure">
                <div :class="$style.graph_size_max">
                  <donut-graph
                    :labelsData="backendData.counselingType.graph.labels"
                    :datasetsData="backendData.counselingType.graph.datasets" />
                </div>
              </div>
              <div :class="$style.graph_contents_table">
                <table>
                  <tr><th>利用率</th><th>件・人</th><th>割合</th></tr>
                  <tr
                    v-for="row, index in backendData.counselingType.table"
                    :key="row.name">
                    <td :class="$style.alg_center">
                      <span v-if="row.color" :style="`color: ${row.color}`">● </span>{{ row.label }}
                    </td>
                    <td :class="$style.alg_right">
                      <!-- {{ roundN(row.value, 1) }}<span v-if="helper.master.labels.suffix[row.name]">{{ helper.master.labels.suffix[row.name] }}</span> -->
                      {{ roundN(row.value, 1) }}<span>{{ scaleUnits[index] }}</span>
                    </td>
                    <td :class="$style.alg_right">
                      {{ roundN(row.ratio, 1) || '' }}<span v-if="row.ratio">%</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </contents-box>
        </div>
        <div :class="$style.card">
          <contents-box
            type="inner"
            style="height: 100%;">
            <div v-if="backendData" :class="$style.graph_contents">
              <div :class="$style.graph_contents_title">性別</div>
              <spacer :y="2" />
              <div :class="$style.graph_contents_fiure">
                <div :class="$style.graph_size_max">
                  <pie-graph
                    :labelsData="backendData.gender.common.labels"
                    :datasetsData="backendData.gender.common.datasets" />
                </div>
              </div>
              <div :class="$style.graph_contents_table">
                <table>
                  <tr>
                    <th>性別</th><th>件数</th><th>ユニーク数</th><th>割合</th>
                  </tr>
                  <tr>
                    <td :class="$style.alg_center"><span :style="`color: ${backendData.gender.common.datasets.backgroundColor[0]}`">● </span>男性</td>
                    <td :class="$style.alg_right">{{ backendData.gender.common.datasets.data[0] }}件</td>
                    <td :class="$style.alg_right">{{ backendData.gender.common.unique.male.length }}人</td>
                    <td :class="$style.alg_right">{{ calcPercentage(backendData.gender.common.datasets.data[0], backendData.gender.common.datasets.data[0] + backendData.gender.common.datasets.data[1]) }}%</td>
                  </tr>
                  <tr>
                    <td :class="$style.alg_center"><span :style="`color: ${backendData.gender.common.datasets.backgroundColor[1]}`">● </span>女性</td>
                    <td :class="$style.alg_right">{{ backendData.gender.common.datasets.data[1] }}件</td>
                    <td :class="$style.alg_right">{{ backendData.gender.common.unique.female.length }}人</td>
                    <td :class="$style.alg_right">{{ calcPercentage(backendData.gender.common.datasets.data[1], backendData.gender.common.datasets.data[0] + backendData.gender.common.datasets.data[1]) }}%</td>
                  </tr>
                  <tr>
                    <td :class="$style.alg_center">合計</td>
                    <td :class="$style.alg_right">{{ backendData.gender.common.datasets.data[0] + backendData.gender.common.datasets.data[1] }}件</td>
                    <td :class="$style.alg_right">{{ backendData.gender.common.unique.male.length + backendData.gender.common.unique.female.length }}人</td>
                    <td>　</td>
                  </tr>
                </table>
              </div>
            </div>
          </contents-box>
        </div>
        <div :class="$style.card">
          <contents-box
            type="inner"
            style="height: 100%;">
            <div v-if="backendData" :class="$style.graph_contents">
              <div :class="$style.graph_contents_title">年齢</div>
              <spacer :y="2" />
              <div :class="$style.graph_contents_fiure">
                <div :class="$style.graph_size_max">
                  <pie-graph
                    :labelsData="backendData.ageData.common.labels"
                    :datasetsData="backendData.ageData.common.datasets" />
                </div>
              </div>
              <div :class="$style.graph_contents_table">
                <table>
                  <tr>
                    <th>年齢</th><th>件数</th><th>割合</th>
                  </tr>
                  <tr v-for="data, index in backendData.ageData.common.labels" :key="data">
                    <td :class="$style.alg_center">
                      <span :style="`color: ${backendData.ageData.common.datasets.backgroundColor[index]}`">●</span>{{ data }}歳
                    </td>
                    <td :class="$style.alg_right">{{ backendData.ageData.common.datasets.data[index] }}件</td>
                    <td :class="$style.alg_right">{{ calcPercentageDecimal(backendData.ageData.common.datasets.data[index], calcSum(backendData.ageData.common.datasets.data)) }}%</td>
                  </tr>
                  <tr>
                    <td :class="$style.alg_center">合計</td>
                    <td :class="$style.alg_right">{{ calcSum(backendData.ageData.common.datasets.data) }}件</td>
                    <td>　</td>
                  </tr>
                </table>
              </div>
            </div>
          </contents-box>
        </div>
      </div>
      <spacer :y="2" />
        <contents-box
          type="inner">
          <p :class="$style.graph_contents_title">WHO-5</p>
          <spacer :y="1" />
          <div
            :class="$style.card_container">
            <div :class="$style.card">
              <contents-box
                type="inner"
                style="height: 100%;">
                <div v-if="backendData" :class="$style.graph_contents">
                  <div :class="$style.graph_contents_title">月</div>
                  <div :class="$style.graph_contents_table">
                    <table>
                      <tr>
                        <th>年-月</th><th>平均点</th>
                      </tr>
                      <tr v-for="data, index in backendData.month.graph.common.labels" :key="data">
                        <th>{{ data }}</th>
                        <td :class="$style.alg_right">{{ backendData.month.graph.common.datasets[0].data[index] }}点</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </contents-box>
            </div>
            <div :class="$style.card">
            <contents-box
              type="inner"
              style="height: 100%;">
              <div v-if="backendData" :class="$style.graph_contents">
                <div :class="$style.graph_contents_title">曜日</div>
                <div :class="$style.graph_contents_table">
                  <table>
                    <tr>
                      <th>曜日</th><th>平均点</th>
                    </tr>
                    <tr v-for="weekDay in backendData.month.table.weekDays.common" :key="weekDay">
                      <th>{{ weekDay.label }}</th>
                      <td :class="$style.alg_right">{{ weekDay.value }}点</td>
                    </tr>
                  </table>
                </div>
              </div>
            </contents-box>
          </div>
          <div :class="$style.card">
            <contents-box
              type="inner"
              style="height: 100%;">
              <div v-if="backendData" :class="$style.graph_contents">
                <div :class="$style.graph_contents_title">性別</div>
                <div :class="$style.graph_contents_table">
                  <table>
                    <tr>
                      <th>性別</th><th>平均点</th>
                    </tr>
                    <tr v-for="data in backendData.month.table.gender.common" :key="data">
                      <th>{{ data.label }}</th>
                      <td :class="$style.alg_right">{{ data.value }}点</td>
                    </tr>
                  </table>
                </div>
              </div>
            </contents-box>
          </div>
        </div>
        <spacer :y="2" />
        <analytics-graph
          :backendData="backendData"
          :allFilter="allFilter"
          :schools="schools" />
      </contents-box>
    </contents-box>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import DonutGraph from '@/views/components/DonutGraph.vue';
import PieGraph from '@/views/components/PieGraph.vue';
import MixGraph from '@/views/components/MixGraph.vue';
import AnalyticsGraph from '@/views/pages/Operator/Analytics/AnalyticsGraph.vue';
// import LoaderSimple from '@/views/components/LoaderSimple.vue';
import GuideIcon from '@/views/components/GuideIcon.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'analyticsCommon',
  mixins: [cf],
  components: {
    ContentsBox,
    LoaderSimple,
    Spacer,
    DonutGraph,
    PieGraph,
    MixGraph,
    AnalyticsGraph,
    // LoaderSimple,
    GuideIcon,
  },
  props: {
    starting: {
      type: String,
    },
    drowData: {
      type: Object,
    },
    backendData: {
      type: Object,
    },
    allFilter: {
      type: Object,
    },
    schools: {
      type: Object,
    },
    accountsData: {
      type: Object,
    },
  },
  data() {
    return {
      flag: {
        reset: 0,
        getTextDatas: false,
        getOnlineDatas: false,
      },
      commonPrefData: {
        new: {},
        already: {},
      },
      who5Data: null,
      reload: false,
      graphOptions: {
        // 都道府県別
        area: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              ticks: {
                stepSize: 1,
              },
            },
            y: {
              title: {
                display: true,
                text: '人・回',
              },
            },
          },
          barPercentage: 0.7, // バーの太さを余白と相対的に指定
          maxBarThickness: 40, // バーの最大太さ40px
        },
      },
      displayRange: 'topTen',
      displayRanges: [
        { name: 'topTen', label: '上位10件' },
        { name: 'topTwenty', label: '上位20件' },
        { name: 'topThirty', label: '上位30件' },
        { name: 'all', label: '全て' },
      ],
      sortedOrder: 'userCount',
      sortedOrders: [
        { name: 'userCount', label: 'ユーザー数' },
        { name: 'retentionRate', label: 'ユーザー維持率（人）' },
        { name: 'who5ave', label: 'WHO-5' },
        { name: 'retentionalPercentage', label: 'ユーザー維持率（％）' },
      ],
      displayTrend: 'ten',
      displayTrends: [
        { name: 'ten', label: '上位10件' },
        { name: 'twenty', label: '上位20件' },
        { name: 'thirty', label: '上位30件' },
        { name: 'allTrend', label: '全て' },
      ],
      sortedTrend: 'user',
      sortedTrends: [
        { name: 'user', label: '新規ユーザー' },
        { name: 'cocomoni', label: 'ココモニ' },
        { name: 'texts', label: 'テキスト' },
        { name: 'onlines', label: 'オンライン' },
        { name: 'who5', label: 'WHO-5' },
      ],
      scaleUnits: ['件', '人', '回', '件', '件', '件', '件', '件', '件', '件'],
      includeInactive: false,
    };
  },
  async created() {
    await this.readParameter();
    this.sendData();
    this.sendTrend();
  },
  computed: {
    ...mapState(['helper', 'user']),
    weightedAverage() {
      const datas = this.backendData.month.graph.common.datasets;
      let who5Sum = 0;
      const monthCount = datas[0].data.length;
      datas.forEach((data) => {
        if (data.label === 'WHO-5') {
          who5Sum += data.data.reduce((a, b) => a + b);
        }
      });
      return Math.round((who5Sum / monthCount) * 10) / 10;
    },
  },
  watch: {
    textData: {
      handler() {
        this.flag.getTextDatas = true;
      },
      deep: true,
    },
    onlineData: {
      handler() {
        this.flag.getOnlineDatas = true;
        if (this.flag.getTextDatas) {
          this.setPrefData();
        }
      },
      deep: true,
    },
    drowData: {
      handler() {
        this.reload = true;
        setTimeout(() => {
          this.reload = false;
        }, 1000);
      },
      deep: true,
    },
    displayRange: {
      handler() {
        const query = { ...this.$route.query };
        query.displayRange = this.displayRange;
        this.$router.push({ query });
      },
      immediate: true,
    },
    sortedOrder: {
      handler() {
        const query = { ...this.$route.query };
        query.sortedOrder = this.sortedOrder;
        this.$router.push({ query });
      },
      immediate: true,
    },
    displayTrend: {
      handler() {
        const query = { ...this.$route.query };
        query.displayTrend = this.displayTrend;
        this.$router.push({ query });
      },
      immediate: true,
    },
    sortedTrend: {
      handler() {
        const query = { ...this.$route.query };
        // タブは強制的にanalyticsselect
        query.tab = 'analyticsselect';
        query.sortedTrend = this.sortedTrend;
        this.$router.push({ query });
      },
      immediate: true,
    },
  },
  methods: {
    // 配列の合計を計算
    calcSum(arr) {
      let result = 0;
      arr.forEach((data) => {
        result += data;
      });
      return result;
    },
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
    sendData() {
      this.$emit('send-data', {
        graphRange: this.displayRange,
        sortedCriteria: this.sortedOrder,
        includeInactive: this.includeInactive,
      });
    },
    sendTrend() {
      this.$emit('send-trend', {
        displayTrend: this.displayTrend,
        sortedTrend: this.sortedTrend,
      });
    },
    readParameter() {
      // 該当パラメータがある場合、反映
      const query = this.$route.query;
      if (query.tab === 'analyticsselect') {
        if (query.displayRange) {
          this.displayRange = query.displayRange;
        }
        if (query.sortedOrder) {
          this.sortedOrder = query.sortedOrder;
        }
        if (query.displayTrend) {
          this.displayTrend = query.displayTrend;
        }
        if (query.sortedTrend) {
          this.sortedTrend = query.sortedTrend;
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
.longer_box {
  height: 100px;
}
.analyticsTable {
  width: 100%;
  table-layout: fixed;
  .dark {
      background-color: #E8E8E8;
    }
  .smoke {
    background-color: WhiteSmoke;
  }
  .on_color_tr {
    th {
      transition: background-color .3s;
    }
    td {
      transition: background-color .3s;
    }
  }
  .on_color_tr :hover {
    th {
      background-color: #CCCCCC;
    }
    td {
      background-color: #CCCCCC;
    }
  }
  .sub_contents {
    color: gray;
    font-size: .8em;
    display: inline-block;
    width: 40px;
    font-weight: normal;
    &_exwide {
      color: gray;
      font-size: .8em;
      display: inline-block;
      width: 120px;
    }
    &_wide {
      color: gray;
      font-size: .8em;
      display: inline-block;
      width: 90px;
    }
    &_thin {
      color: gray;
      font-size: .8em;
      font-display: inline-block;
      width: 20px;
    }
  }
}
.retention {
  position: relative;
}
.area_title_header {
  display: flex;
  &_pref {
    flex-grow: 1;
    margin-left: -2em;
  }
}
.pref_flex {
  justify-content: space-between;
  &_right {
    margin-left: 8px;
    @media screen and (max-width:1080px){
      margin-left: 0;
      margin-top: 15px;
      width: 100%;
    }
  }
  @media screen and (max-width:1080px) {
    display: block;
  }
}
.wide_contents {
  margin-bottom: 20px;
  &_block {
    display: flex;
    @include md-view {
      display: block;
    }
  }
}
.card {
  margin-top: 10px;
  width: 32.5%;
  &_container {
    margin-top: -10px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    @media screen and (max-width:927px) {
      margin-left: -13px;
    }
    @media screen and (max-width:764px) {
      margin-left: 0;
    }
  }
  @media screen and (max-width:927px) {
    margin-left: 13px;
    width: 48%;
  }
  @media screen and (max-width:764px) {
    width: 100%;
    margin-left: 0;
  }
}
.graph_contents {
  &_title {
    padding: 3px;
    font-size: 1.1em;
    font-weight: 500;
  }
  &_figure {
    &_equality {
      flex-grow: 1;
    }
  }
  &_table {
    flex-grow: 1;
    display: flex;
    margin: 15px auto;
    font-size: .8em;
    table {
      table-layout: fixed;
      width: 100%;
    }
    tr {
      background-color: #f8f8f8;;
      max-height: 20px;
    }
    tr:nth-child(odd) {
      background-color: var(--gray-sub);
    }
  }
}
.inactive_box {
  padding-top: 40px;
  padding-left: 1.5em;
  &_checkbox {
    display: none;
  }
  &_label {
    background-color: #ababab;
    padding: 10px 20px;
    color: white;
    font-weight: bold;
    border-radius: 20px;
    font-size: .9em;
    &_active {
      background-color:  var(--orange-main);
      padding: 10px 20px;
      color: white;
      font-weight: bold;
      border-radius: 20px;
      font-size: .9em;
      transition: 0.4s all;
    }
    &:hover {
      background-color:  var(--orange-main);
      padding: 10px 20px;
      color: white;
      font-weight: bold;
      border-radius: 20px;
      font-size: .9em;
      transition: 0.4s all;
    }
  }
}
.alg {
  &_left {
    text-align: left;
    padding-left: .5em;
  }
  &_right {
    text-align: right;
    padding-right: .5em;
  }
  &_center {
    text-align: center;
  }
}

.filter {
  &_container {
    // display: flex;
    // @include md-view {
    //   display: block;
    // }
  }
  &_title {
    padding-top: 8px;
  }
}
.select {
  margin-left: -10px;
  display: flex;
  flex-wrap: wrap;
  &_elem {
    margin-left: 10px;
  }
  &_box {
    max-width: 220px;
    padding: 13px;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
    border-radius: 8px;
    appearance: none;

    &_wrap {
      display: flex;

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
.btns {
  display: flex;
  // width: 102%;
  width: 100%;
  li {
    margin-right: 1em;
  }
  input[type=radio] {
    display: none;
    &:checked {
      + label {
        background-color: var(--orange-main);
      }
    }
  }
}
.btn {
  padding: 6px 20px;
  font-size: 14px;
  text-align: center;
  border: none;
  background-color: #ababab;
  font-weight: bold;
  color: #fff;
  transition: 0.4s all;
  display: inline-block;
  border-radius: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  &:not(:disabled) {
    &:hover {
      background-color: var(--orange-main);
    }
  }
  &:disabled {
    opacity: .5;
    cursor: default;
  }
}

.filter_flex {
  display: flex;
}
</style>

<template>
  <div>
    <spacer :y="5"/>
    <ul>
      <stack-item>
        <contents-box :title="title" v-if="reserve && helper">
          <user-box :showId="true" type="inner" :user="reserve.fromUser">
            <template v-slot:main>
              <div>
                <spacer :y="3"/>
                <ul :class="$style.detail">
                  <li>
                    <label-box label="実施日" color="gray" size="sm" :class="$style.userlabel"/>
                    <p :class="$style.content">{{ formatTimestamp(reserve.schedule.date, 'YYYY/MM/DD HH:00') }}</p>
                  </li>
                  <li>
                    <label-box label="お悩み" color="gray" size="sm" :class="$style.userlabel"/>
                    <p :class="$style.content">
                      <span
                        v-for="r_type in reserve.type"
                        :key="r_type"
                        :class="$style.types">{{ helper.master.labels.reserve.type[r_type] }}</span>
                    </p>
                  </li>
                  <li class="n2br">
                    <label-box label="自由欄" color="gray" size="sm" :class="$style.userlabel"/>
                    <p
                      :class="$style.content"
                      v-if="reserve.memos.customer[0]"
                      v-html="reserve.memos.customer[0].memo"></p>
                  </li>
                  <li>
                    <label-box label="ココモニ" color="gray" size="sm" :class="$style.userlabel"/>
                    <div :class="$style.content">
                      <ul :class="$style.who5">
                        <li v-for="(item, i) in helper.master.who5.items" :key="item">
                          <p>{{ item.label }}</p>
                          <p>{{ helper.master.who5.labels[reserve.who5Answer.answers[i]] }}</p>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li class="n2br">
                    <label-box :label="isCompany ? '企業コメント' : '学校コメント'" color="gray" size="sm" :class="$style.userlabel"/>
                    <p
                      :class="$style.content"
                      v-if="reserve.memos.teacher[0]"
                      v-html="reserve.memos.teacher[0].memo"></p>
                    <p v-if="!reserve.memos.teacher[0]">記入なし</p>
                  </li>
                </ul>
                <spacer :y="2"/>
                <contents-box title="カルテ" type="inner">
                  <spacer :y="1"/>
                  <div
                  :class="$style.textareaDiv"
                  :flag="flag.memo">
                  <textarea
                    name="memo"
                    id="memo"
                    rows="8"
                    :value="memo"
                    v-on:input="updateMemo"
                    :class="$style.textarea">
                  </textarea>
                  <div
                  id="placeholderDiv"
                  v-if="!flag.memo"
                  :class="$style.placeholderDiv">
                    カルテ記入欄<br>カルテ冒頭に「本人確認の方法」をご入力ください。<br>書き方例<br>・生徒手帳にて本人確認済み<br>・生徒手帳忘れにより学校名・氏名にて本人確認済<br>・保護者/教職員のため学校名・氏名にて本人確認済
                  </div>
                </div>
                  <p :class="$style.assistant">※保存せずにページを離れると入力した内容は失われます。編集した場合は、忘れずに更新ボタンを押してください。</p>
                  <spacer :y="2"/>
                  <btn-container>
                    <basic-btn tag="button" v-on:click="registMemo">更新</basic-btn>
                  </btn-container>
                </contents-box>
              </div>
            </template>
          </user-box>
        </contents-box>
      </stack-item>

      <stack-item>
        <contents-box title="報告書" id="confirm" v-if="reserve && helper">
          <ul :class="$style.labellist">
            <li v-if="reserve.fromUser">
              <spacer :y="1"/>
              <div :class="$style.labels" >
                <label-box label="ID" color="gray" size="sm" :class="$style.userlabel"/>
                <p :class="$style.content">{{ reserve.fromUser.customer_id }}</p>
                <spacer :x="3"/>
                <label-box label="性別" color="gray" size="sm" :class="$style.userlabel"/>
                <p :class="$style.content">{{ helper.master.labels.users.gender[reserve.fromUser.gender] }}</p>
                <spacer :x="3"/>
                <label-box label="年齢" color="gray" size="sm" :class="$style.userlabel"/>
                <p :class="$style.content">{{ reserve.customer_age || reserve.fromUser.age }}歳</p>
              </div>
            </li>
            <li class="n2br">
              <label-box label="相談概要" :class="$style.label"/>
              <spacer :y="2"/>
              <textarea
                name="summary"
                id="summary"
                placeholder="相談概要を入力してください。"
                rows="5"
                v-model="summary"
                v-if="!flag.isConfirm"
                :class="$style.textarea"></textarea>
              <p v-if="flag.isConfirm">{{ summary }}</p>
            </li>
            <li class="n2br">
              <label-box label="医学的所見に関する特記事項" :class="$style.label"/>
              <spacer :y="2"/>
              <textarea
                name="notice"
                id="notice"
                placeholder="医学的所見に関する特記事項があれば入力してください。ない場合には特になしと入力してください。"
                rows="5"
                v-model="notice"
                v-if="!flag.isConfirm"
                :class="$style.textarea"></textarea>
              <p v-if="flag.isConfirm">{{ notice }}</p>
            </li>
            <li v-for="(row, i) in radios" :key="row">
              <label-box :label="row.label" :class="$style.label"/>
              <div v-if="!flag.isConfirm">
                <div :class="$style.radio_wrap">
                  <div
                    :class="$style.radio"
                    v-for="item in row.items"
                    :key="item">
                    <input
                      type="radio"
                      :id="item.name"
                      :name="row.name"
                      :class="$style.hidden"
                      :checked="row.value === item.value"
                      v-on:click="changeValue('radios', item.value, i)">
                    <label
                      :for="item.name"
                      :class="row.value === item.value ? `${$style.selectlabel} ${$style.checked}` : `${$style.selectlabel}`">{{ item.label }}</label>
                  </div>
                </div>
                <spacer :y="1"/>
                <input
                  v-if="row.name === 'finding'"
                  type="text"
                  :class="$style.text"
                  placeholder="所見ありを選択した場合はこちらにご入力ください。"
                  v-model="row.text">
              </div>
              <div v-if="flag.isConfirm">
                <spacer :y="2"/>
                <div v-for="item in row.items" :key="item">
                  <p v-if="item.value === row.value">{{ item.label }}<span v-if="row.value === 1 && row.name === 'finding'">（{{ row.text }}）</span></p>
                </div>
              </div>
            </li>
            <li>
              <label-box label="指導区分・指導期間" :class="$style.label"/>
              <div v-if="!flag.isConfirm">
                <div :class="$style.radio_wrap">
                  <div
                    :class="$style.radio"
                    v-for="(row, k) in checks" :key="row">
                    <input
                      v-if="k !== 'medical_cooperation' || user.account_type === 21"
                      type="radio"
                      :id="`type_${k}`"
                      name="supportType"
                      :class="$style.hidden"
                      v-on:change="updateSupportType(k)">
                    <label
                      :for="`type_${k}`"
                      v-if="k !== 'medical_cooperation' || user.account_type === 21"
                      :class="k === supportType ? `${$style.selectlabel} ${$style.checked}` : `${$style.selectlabel}`">{{ row.label }}</label>
                  </div>
                </div>
                <p :class="$style.support_label" v-if="supportType && supportType !== 'medical_cooperation'">指導期間を選択してください。</p>
                <div v-if="supportType && supportType !== 'medical_cooperation'">
                  <div :class="$style.radio_wrap">
                    <div
                      :class="$style.radio"
                      v-for="item in checks[supportType].items"
                      :key="item">
                      <input
                        type="radio"
                        :id="`${supportType}-${item.value}`"
                        :name="supportType"
                        :class="$style.hidden"
                        :checked="checks[supportType].value === item.value"
                        v-on:change="changeValue('checks', item.value, null)">
                      <label
                        :for="`${supportType}-${item.value}`"
                        :class="checks[supportType].value === item.value ? `${$style.selectlabel} ${$style.checked}` : `${$style.selectlabel}`">{{ item.label }}</label>
                    </div>
                  </div>
                  <spacer :y="2"/>
                  <input
                    :class="$style.text"
                    placeholder="その他を選択した場合こちらに入力してください。"
                    type="text"
                    v-model="checks[supportType].text">
                </div>
              </div>
              <div v-if="flag.isConfirm">
                <spacer :y="2"/>
                <p>{{ checks[supportType].label }}</p>
                <p v-for="item in checks[supportType].items" :key="item">
                  <span v-if="checks[supportType].value === item.value">{{ item.label }}</span>
                  <span v-if="checks[supportType].value === item.value && (checks[supportType].text || checks[supportType].text !== '')">（{{ checks[supportType].text }}）</span></p>
              </div>
            </li>
            <li v-if="user.account_type === 21">
              <label-box
                label="暫定診断 ICD-11"
                :class="$style.label"/><!--診断は医師のみにしか表示しない-->
              <spacer :y="2"/>
              <select
                :class="$style.select_box"
                name="diagnosis_category"
                id="diagnosis_category"
                @change="updateDiagnosis()"
                v-model="diagnosis.rough">
                <option :value="0" :selected="diagnosis.rough === 0">分類</option>
                <option
                  v-for="n of symptomsList"
                  :value="n"
                  :key="n">{{ n.label }}</option>
              </select><br>
              <spacer :y="2"/>
              <select
                :class="$style.select_box"
                name="diagnosis_deteil"
                id="diagnosis_detail"
                v-model="diagnosis.detail_value">
                <option :value="0" :selected="diagnosis.detail_value === 0">病名</option>
                <option
                  v-for="n of diagnosis.detail_menu" :key="n"
                  :value="n.value">{{ n.label }}</option>
              </select>

            </li>
            <li class="n2br">
              <label-box label="対応・予約に関して" :class="$style.label"/>
              <spacer :y="2"/>
              <textarea
                name="report_memo"
                id="report_memo"
                :placeholder="`${isCompany ? '企業' : '学校'}関係者対応・次回予約に関して何かあれば入力してください。`"
                rows="5"
                v-if="!flag.isConfirm"
                v-model="report_memo"
                :class="$style.textarea"></textarea>
              <p v-if="flag.isConfirm">{{ report_memo }}</p>
            </li>
          </ul>
          <spacer :y="4"/>
          <btn-container>
            <basic-btn
              tag="button"
              v-if="!flag.isConfirm"
              v-on:click="regist(2);">下書き保存</basic-btn>
            <basic-btn
              tag="button"
              v-if="flag.isConfirm"
              v-on:click="regist(1);">確定</basic-btn>
            <spacer :x="2"/>
            <basic-btn
              tag="button"
              v-if="!flag.isConfirm"
              v-on:click="changeFlag">確認する</basic-btn>
            <basic-btn
              tag="button"
              type="bdr"
              v-if="flag.isConfirm"
              v-on:click="changeFlag">編集画面に戻る</basic-btn>
          </btn-container>
        </contents-box>
      </stack-item>
    </ul>
    <spacer :y="5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import UserBox from '@/views/components/UserBox.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'report',
  mixins: [cf],
  components: {
    StackItem,
    ContentsBox,
    UserBox,
    LabelBox,
    Spacer,
    BasicBtn,
    BtnContainer,
  },
  data() {
    return {
      flag: {
        isConfirm: false,
        memo: true,
        sendFlag: false,
      },
      memo: null,
      reportId: null,
      diagnosisId: null,
      reserveId: null,
      reserve: null,
      notice: null,
      summary: null,
      supportType: null,
      report_memo: null,
      diagnosis: {
        rough: 0,
        detail_menu: null,
        category_value: 0,
        detail_value: 0,
      },
      symptomsList: [],
      radios: [
        {
          name: 'finding',
          label: 'その他の心身の状況',
          value: 0,
          items: [
            {
              label: '所見なし',
              name: 'notFound',
              value: 0,
            },
            {
              label: '所見あり',
              name: 'found',
              value: 1,
            },
          ],
          text: null,
        },
        {
          name: 'cause',
          label: '要因',
          value: 0,
          items: [
            {
              label: '医学的要因（病気・障害等の疑い）',
              name: 'medical',
              value: 1,
            },
            {
              label: '心理社会・環境的要因（友人・家族など）',
              name: 'environmental',
              value: 2,
            },
          ],
        },
      ],
      checks: {
        observe: {
          name: 'observe',
          label: '健康観察',
          value: 0,
          text: null,
          needText: 3,
          items: [
            {
              label: '１ヶ月程度',
              value: 1,
            },
            {
              label: '３ヶ月程度',
              value: 2,
            },
            {
              label: 'その他',
              value: 3,
            },
          ],
        },
        re_counseling: {
          name: 're_counseling',
          label: 'カウンセリング',
          value: 0,
          text: null,
          needText: 3,
          items: [
            {
              label: '２週間後',
              value: 1,
            },
            {
              label: '１ヶ月後',
              value: 2,
            },
            {
              label: 'その他',
              value: 3,
            },
          ],
        },
        re_online: {
          name: 're_online',
          label: '健康相談',
          value: 0,
          text: null,
          needText: 3,
          items: [
            {
              label: '２週間後',
              value: 1,
            },
            {
              label: '１ヶ月後',
              value: 2,
            },
            {
              label: 'その他',
              value: 3,
            },
          ],
        },
        medical_cooperation: {
          name: 'medecal_cooperation',
          label: '医療連携',
          value: 1,
          text: null,
        },
      },
    };
  },
  created() {
    this.reserveId = Number(this.$route.params.slug);
    this.getReserve();
    if (this.user.email) {
      this.setsymptoms();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/putMaster') {
          this.setsymptoms();
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
    isCompany() {
      return Boolean(this.reserve && this.reserve.fromUser && this.reserve.fromUser.school.length && this.reserve.fromUser.school[0].is_company);
    },
    isVirtual() {
      if (!this.reserve || !this.reserve._counselingType) { // まだ情報が取得できないタイミングへの対処
        return false;
      }
      return this.reserve._counselingType.counseling_type === 3;
    },
    title() {
      const prefix = this.isVirtual ? 'バーチャル' : 'オンライン';
      return `${prefix}健康相談内容`;
    },
  },
  methods: {
    updateDiagnosis() {
      this.diagnosis.detail_menu = this.diagnosis.rough.detail;
      this.diagnosis.category_value = this.diagnosis.rough.value;
      // 症名が選択された状態で症群の変更があったら選択解除
      if (this.diagnosis.detail_value !== 0) {
        this.diagnosis.detail_value = 0;
      }
    },
    setsymptoms() {
      this.symptomsList = this.helper.master.symptoms;
    },
    getReserve() {
      const params = {
        id: this.reserveId,
      };
      this.axios({
        method: 'GET',
        url: '/v1/reserve/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.reserve = res.reserve;
          if (this.reserve.memos.counselor[0]) this.memo = this.reserve.memos.counselor[0].memo;
          if (!this.memo) {
            this.flag.memo = false;
          } else if (this.memo) {
            this.flag.memo = true;
          }
          if (this.reserve.report) this.setReportItem();
          // 下書きでdiagnosisが選択済みで書き込みをおこなってるのが医師ならserectboxに反映
          if (this.user.account_type === 21 && this.reserve.diagnosis.category !== 0) {
            this.diagnosis.rough = this.symptomsList[this.reserve.diagnosis.category - 1];
            this.diagnosis.detail_menu = this.diagnosis.rough.detail;
            this.diagnosis.category_value = this.diagnosis.rough.value;
            this.diagnosis.detail_value = this.diagnosis.rough.detail[this.reserve.diagnosis.diagnosis - 1].value;
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    setReportItem() {
      const report = this.reserve.report;
      if (report.flag === 999) return; // flag = 999は除外
      this.reportId = report.id;
      if (this.user.account_type === 21 && this.reserve.diagnosis) {
        this.diagnosisId = this.reserve.diagnosis.id;
      }
      this.summary = report.summary;
      this.notice = report.notice;
      this.medical_cooperation = report.medical_cooperation;
      this.report_memo = report.report_memo;
      this.radios.forEach((row) => {
        row.value = report[row.name];
        row.text = report[`${row.name}_memo`];
      });
      Object.keys(this.checks).forEach((key) => {
        this.checks[key].value = key === 'medical_cooperation' ? 1 : report[key];
        if (key !== 'medical_cooperation') {
          this.checks[key].text = report[`${key}_memo`];
        }
        if (report[key] !== 0) this.supportType = key;
      });
    },
    changeValue(type, value, idx) {
      if (type === 'radios') {
        this[type][idx].value = value;
      } else {
        this[type][this.supportType].value = value;
      }
    },
    updateCheck(e) {
      this[e.target.id] = e.target.checked ? 1 : 0;
    },
    updateSupportType(name) {
      this.supportType = name;
      Object.keys(this.checks).forEach((key) => {
        if (key !== name) {
          this.checks[key].value = key === 'medical_cooperation' ? 1 : 0;
          if (key === 'medical_cooperation') this.checks[name].text = null;
        }
      });
    },
    changeFlag() {
      if (this.summary === null || this.summary === '') {
        alert('相談概要を入力してください。');
        return;
      }
      if (this.notice === null || this.notice === '') {
        alert('医学的所見に関する特記事項を入力してください、');
        return;
      }
      if (this.report_memo === null || this.report_memo === '') {
        alert('対応・予約に関してを入力してください、');
        return;
      }
      if ((this.diagnosis.category_value === 0 || this.diagnosis.detail_value === 0) && this.user.account_type === 21) {
        alert('暫定診断 ICD-11を選択してください');
        return;
      }
      let invalid = false;
      this.radios.forEach((row) => {
        if (row.name === 'cause' && row.value === 0) {
          invalid = true;
        } else if (row.name === 'finding' && row.value === 1 && (row.text === null || row.text === '')) {
          invalid = true;
        }
      });
      if (invalid) {
        alert('未入力の項目があります。');
        return;
      }

      if (!this.supportType) {
        alert('指導区分を選択してください。');
        return;
      }
      if (this.checks[this.supportType].value === 0) {
        alert('指導期間を選択してください。');
        return;
      }
      if (this.checks[this.supportType].value === this.checks[this.supportType].needText
        && (!this.checks[this.supportType].text || this.checks[this.supportType].text === '')) {
        alert('指導期間でその他を選択した場合、テキストエリアに詳細を記入してください。');
        return;
      }
      // 必須項目が入力されていたら進む
      this.flag.isConfirm = !this.flag.isConfirm;
      if (this.flag.isConfirm) {
        const elem = document.getElementById('confirm');
        const position = elem.getBoundingClientRect().top;
        scrollBy(0, position - 77);
      }
    },
    // reserveReportの登録、diagnosisの登録とは分けている。下書き保存の時はn=2、本登録の時はn=1
    regist(n) {
      const data = {
        flag: n,
        reserve_id: this.reserve.id,
        user_id: this.user.id,
        summary: this.summary,
        notice: this.notice,
        medical_cooperation: this.medical_cooperation,
        report_memo: this.report_memo,
      };

      // 報告書を本登録した相談をにstoreの報告書未記入から削除
      if (n === 1 && this.user.counseling.counselor.reserve.report) { // 報告書記入画面でリロードが走ったときはstoreが空になるので除外
        const targetIndex = this.user.counseling.counselor.reserve.report.findIndex((elem) => elem.id === this.reserve.id);
        this.user.counseling.counselor.reserve.report.splice(targetIndex, 1);
      }

      if (this.reportId) data.id = this.reportId;

      this.radios.forEach((row) => {
        data[row.name] = row.value;
        if (row.name === 'finding') data[`${row.name}_memo`] = row.text;
      });

      Object.keys(this.checks).forEach((key) => {
        if (this.supportType === key) {
          data[key] = this.checks[key].value;
          if (key !== 'medical_cooperation') data[`${key}_memo`] = this.checks[key].text;
        } else {
          data[key] = 0;
          if (key !== 'medical_cooperation') data[`${key}_memo`] = null;
        }
      });

      const endpoint = this.reportId ? 'updater' : 'register';

      this.axios({
        method: 'POST',
        url: `/v1/reserve/report/set/${endpoint}`,
        data,
      })
        .then((response) => {
          let label = '';
          if (n === 1) label = '本登録';
          else if (endpoint === 'register') label = '下書きの登録';
          else label = '下書きの更新';
          alert(`報告書の${label}が完了しました。`);
          this.$store.dispatch('user/counseling/getCounseling');
          // 連絡(下書きは除く)
          if (data.flag === 1 && !this.sendFlag) { // sendFlagは連絡が重複されて行われるのを防ぐため
            this.onlineCounselingContact(response.data.reportID);
          }
          const tab = this.isVirtual ? 'virtual' : 'online';
          this.$router.push(`/counselor/?tab=${tab}`);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });

      if (this.user.account_type === 21) { // 医師は報告書に暫定診断を記入
        this.registDiagnosis(n);
      }
    },

    registDiagnosis(n) {
      const data = {
        flag: n,
        reserve_id: this.reserve.id,
        category: this.diagnosis.category_value,
        diagnosis: this.diagnosis.detail_value,
      };

      if (this.diagnosisId) data.id = this.diagnosisId;

      const endpoint = this.reportId ? 'updater' : 'register';

      this.axios({
        method: 'POST',
        url: `/v1/diagnosis/set/${endpoint}`,
        data,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /** オンライン健康相談の連絡 */
    onlineCounselingContact(reportId) {
      this.sendFlag = true;
      // 学校管理者（と事務局）へ連絡
      this.axios({
        method: 'POST',
        url: '/v1/contact/report/online/teacher',
        data: { report_id: reportId },
      })
        .then((response) => {
          console.log(response.data.status);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });

      // 心理士へ連絡
      this.axios({
        method: 'POST',
        url: '/v1/contact/report/online/counselor',
        data: { report_id: reportId },
      })
        .then((response) => {
          console.log(response.data.status);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    updateMemo(e) {
      this.memo = e.target.value;
      if (this.memo) {
        this.flag.memo = true;
      } else if (!this.memo) {
        this.flag.memo = false;
      }
    },
    registMemo() {
      const data = {
        reserve_id: this.reserve.id,
        memo: this.memo,
        user_id: this.user.id,
      };

      if (this.reserve.memos.counselor[0]) data.id = this.reserve.memos.counselor[0].id;
      else data.id = null;

      const endpoint = data.id ? 'updater' : 'register';

      this.axios({
        method: 'POST',
        url: `/v1/reserve/memo/set/${endpoint}`,
        data,
      })
        .then(() => {
          alert('カルテを保存しました。');
          this.getReserve();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module>
.select_box {
  min-width: 270px;
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;
  appearance: none;
}
.labellist {
  li {
    &:not(:last-child) {
      padding-bottom: 32px;
    }
    .support {
      margin-top: 10px;
      &_label {
        font-size: 14px;
        margin-top: 20px;
      }
      li {
        &:not(:last-child) {
          padding-bottom: 0;
        }
      }
    }
  }
}
.label {
  width: 250px;
}
.labels {
  display: flex;
  align-items: flex-start;
}
.textarea {
  width: 100%;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  padding: 20px;
  border-radius: 8px;
}
.text {
  background-color: var(--gray-sub);
  padding: 13px;
  outline: none;
  border: none;
  border-radius: 8px;
  width: 100%;
}
.hidden {
  display: none;
}
.radios {
  li {
    padding: 20px 0;
    border-bottom: 1px solid var(--gray-main);
  }
}
.radio {
  margin-top: 10px;
  &_wrap {
    display: flex;
  }
  &:not(:last-child) {
    margin-right: 20px;
  }
}
.selectlabel {
  display: inline-block;
  padding: 8px 24px;
  border-radius: 24px;
  border: 1px solid #999;
  color: #999;
  // border: 1px solid var(--black);
  // color: var(--black);

  &.checked {
    background-color: var(--blue-main);
    border-color: var(--blue-main);
    color: #fff;
  }
}
.userlabel {
  width: 100px;
  margin-right: 20px;
}
.detail {
  li {
    display: flex;
    align-items: flex-start;
    .content {
      font-size: 14px;
      margin-top: 3px;
      flex: 1;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
.types {
  margin-right: 20px;
}
.who5 {
  li {
    justify-content: space-between;
  }
}
.assistant {
  font-size: 12px;
  color: var(--pink);
}
.textareaDiv {
  position: relative;

  .textarea {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: none;
    appearance: none;
    padding: 20px;
    outline: none;
    z-index:0;
    position: relative;
    resize: vertical;
    min-height: 170px;
    background-color: var(--gray-sub);
  }
  .placeholderDiv{
    border: none;
    color: #999;
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 20px;
    font-size: 14px;
    z-index: 1;
    pointer-events:none;
  }
}

</style>

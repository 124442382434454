<template>
  <div>
    <spacer :y="5"/>
    <div v-if="!flag.invalidUrl">
      <contents-box :title="`メールアドレス${isBillAccount ? '確認' : '登録'}`" id="confirm">
        <div :class="$style.wrapper">
          <div :class="$style.row">
            <div :class="$style.label_wrap">
              <p :class="$style.label">{{ helper.master && school ? helper.master.labels.organizationTypes[school.is_company] : '' }}名</p>
            </div>
            <div :class="$style.detail">
              <p :class="$style.school" v-if="school">{{ school.name }}</p>
            </div>
          </div>

          <div :class="$style.row">
            <div :class="$style.label_wrap">
              <p :class="$style.label">メールアドレス</p>
              <p :class="$style.required">※必須項目</p>
            </div>
            <div :class="$style.detail">
              <div>
                <input
                  :class="$style.text"
                  :disabled="flag.isConfirm"
                  placeholder="メールアドレス"
                  v-model.trim="$v.mail.$model"
                  id="mail"
                  type="email"
                  name="mail"
                  v-on:input="invalidsCheck('mail', $v.mail.$invalid)">
                <p
                  :class="$style.error"
                  v-if="$v.mail.$dirty
                    && $v.mail.required.$invalid">メールアドレスを入力してください</p>
                <p
                  :class="$style.error"
                  v-if="$v.mail.$dirty
                    && $v.mail.email.$invalid">正しいメールアドレスの形式で入力してください</p>
              </div>
            </div>
          </div>
        </div>


        <spacer :y="5"/>
        <btn-container>
          <basic-btn
            tag="button"
            v-on:click="submit"
            :disabled="invalids.mail"
            :class="{ disabled: invalids.mail }">送信する</basic-btn>
        </btn-container>
        <spacer :y="2" v-if="exists.flag || account.flag" />
        <div v-if="exists.flag">
          <p :class="[$style.assistant, $style.center]" v-html="exists[exists.type]"></p>
        </div>

        <div v-else-if="account.flag">
          <p :class="[$style.assistant, $style.center]" v-html="account.success"></p>
        </div>
      </contents-box>
    </div>
    <div v-else>
      URL無効です。URLを確認してください。
    </div>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Spacer from '@/views/components/Spacer.vue';

export default {
  name: 'signup',
  components: {
    BasicBtn,
    BtnContainer,
    ContentsBox,
    Spacer,
  },
  setup() {
    const mail = ref('');
    const rules = {
      // メールは必須・email形式
      mail: { required, email },
    };
    const $v = useVuelidate(rules, { mail });
    return { mail, $v };
  },
  data() {
    return {
      flag: {
        invalidUrl: false,
      },
      school: null,
      schoolId: null,
      invalids: {
        mail: true,
      },
      exists: {
        flag: false,
        type: 'customer',
        customer: 'ご入力いただいたメールアドレスは既に仮登録済みです。メールをご確認の上、本登録を完了させてください。',
        user: 'ご入力いただいたメールアドレスは既に本登録済みです。<br>パスワードを忘れてログインできない場合は、<a href="/forgot-password/">こちら</a>よりパスワードを再設定の上ログインしてください。',
      },
      account: {
        flag: false,
        success: 'ご入力のメールアドレスへ本登録用のURLをお送りしました。メールに記載のURLから本登録をお願いします。',
        customer: null,
      },
    };
  },
  created() {
    if (this.$route.query.school) {
      this.schoolId = Number(this.$route.query.school);
    } else {
      this.flag.invalidUrl = true;
    }
    this.getSchool();
  },
  computed: {
    ...mapState(['user', 'helper']),
    // アカウント課金
    isBillAccount() {
      return this.school && this.school.billing_type && this.school.billing_type.billing_type === 2;
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.exists.flag = false;
      this.invalids[name] = bool;
    },

    getSchool() {
      const params = {
        id: this.schoolId,
        isUsers: 0,
        isAddress: 0,
        isMainUser: 0,
      };
      this.axios({
        method: 'GET',
        url: '/v1/school/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.school = res.detail;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          this.flag.invalidUrl = true;
        });
    },

    submit() {
      this.showLoading();

      if (!this.isBillAccount) {
        // 従量課金
        this.axios({
          method: 'POST',
          url: '/v1/qrCustomer/set/register',
          data: {
            email: this.mail,
            school_id: this.schoolId,
          },
        })
          .then((response) => {
            // 送信するボタン無効化
            this.invalids.email = true;
            if (response.data.exists) {
              // メールアドレス重複
              this.exists.flag = true;
              this.exists.type = response.data.existsType;
              this.hideLoading();
            } else {
              this.main = '';
              // メール連絡
              this.contact(response.data.customerId);
            }
          })
          .catch((error) => {
            alert('メールアドレスの登録に失敗しました。メールアドレスが正しく入力できているかご確認ください。');
            if (error.message) console.log(error.message);
            else console.log(error);
            this.hideLoading();
          });
      } else {
        // アカウント課金
        this.axios({
          method: 'GET',
          url: '/v1/user/get/detail',
          params: {
            email: this.mail,
          },
        })
          .then((response) => {
            const data = response.data.data;
            if (data.school.length) {
              const billingType = data.school[0].billing_type.billing_type;
              if (billingType === 1) { // 従量課金に紐づく利用者として登録済み
                this.failedEmail();
              } else if (data.birthday) { // アカウント課金だけど詳細登録済み
                this.failedEmail();
              } else { // 誕生日（必須）が登録されていない場合は本登録
                this.account.customer = data;
                this.accountContact();
              }
            } else { // 事務局等
              this.failedEmail('ご入力のメールアドレスは無効です。');
            }
          })
          .catch((error) => {
            if (error.message) console.log(error.message);
            else console.log(error);
            this.failedEmail('ご入力いただいたメールアドレスは登録されていないため、本登録へ進むことはできません。');
            this.hideLoading();
          });
      }
    },

    /**
     * メールアドレスの入力に失敗
     */
    failedEmail(message) {
      const def = 'ご入力のメールアドレスはすでに登録されています。';
      const mes = message || def;
      alert(mes);
      this.mail = null;
    },

    /** アカウント課金の連絡 */
    accountContact() {
      this.axios({
        method: 'POST',
        url: '/v1/contact/account/user',
        data: {
          user: this.account.customer,
        },
      })
        .then(() => {
          this.account.flag = true;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    /** 従量課金の連絡 */
    contact(customerId) {
      this.axios({
        method: 'POST',
        url: '/v1/contact/qr/user',
        data: {
          customer_id: customerId,
        },
      })
        .then((response) => {
          console.log(response.data.status);
          alert('メールアドレスを仮登録しました。配信されたメールをご確認の上、本登録を完了してください。');
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module>
.row {
  padding: 24px 0;
  border-top: 1px solid var(--gray-main);
}
.label {
  border-radius: 4px;
  margin-right: 4px;
  font-weight: bold;
  display: inline-block;
  &_wrap {
    display: flex;
    align-items: baseline;
  }
}
.school {
  margin-top: 7px;
}
.text, .select {
  &:disabled {
    color: black;
    background-color: transparent;
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }
  }
}
.required {
  font-size: 12px;
  color: var(--pink);
}
.assistant {
  font-size: 12px;
}
.error {
  font-size: 12px;
  color: var(--pink);
}
.space {
  padding-top: 6px;
}
.text {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
}
.radio {
  padding-top: 8px;
  display: flex;

  li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
.select {
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;

  &_wrap {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.tel {
  width: 70px;
  padding: 13px;
}
.center {
  text-align: center;
}
</style>

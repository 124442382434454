<template>
  <div>
    <contents-box title="占いボイス" icon="<i class='fa-solid fa-microphone'></i>">
      <div :class="$style.uranai_outer">
        <div id="uranai" :class="$style.uranai_mark"></div> <!--特定の要素から遷移してきたときに占いまでスクロールさせるための基準位置-->
      </div>
      <transition name="fade-voice">
        <div v-show="showVoice">
          <div :class="$style.animationBox">
            <div :class="$style.particleAnime">
            <Particle
              :size="this.size"/>
            </div>
          </div>
          <div :class="$style.recoding_explanation">
            <p v-if="!flag.isRecording">声を送って<br>今日の運勢を調べよう</p>
            <transition appear>
              <p v-if="flag.isRecording && flag.isDisplay"
                :class="$style.bigger_text"
                v-html="recodingMessage.main"></p>
            </transition>
            <p v-if="recodingMessage.plain"
              v-html="recodingMessage.plain"></p>
            <spacer :y="3" />
            <basic-btn
              tag='button'
              style="width:50%;"
              @click="changeRecodingStatus"
              :disabled="flag.notAllowedCapture"
              v-if="!flag.isRecoding">はじめる</basic-btn>
          </div>
        </div>
      </transition>

      <transition name="fade-fortune">
        <Fortune
          v-show="!showVoice" />
      </transition>
    </contents-box>
    <spacer :y="6" />

    <stack-item v-if="graphData">
      <contents-box title="ココモニ" icon="<i class='fa-solid fa-monitor-waveform'></i>">
        <Meter
          :graphData="graphData" />

        <contents-box
          title="トレンド"
          id="graph"
          icon="<i class='fa-solid fa-chart-line-up'></i>">
          <line-graph
            :fromWhere="'cocomoni'"
            :power="graphData.graph.stenScores"
            :datetime="graphData.graph.dateTimes"
            :who5="graphData.graph.who5Scores"
            :labels="graphData.graph.dataLabels"
            :height="graphHeight" />
        </contents-box>

        <spacer :y="3" />

        <GraphPeriod
          :start="start"
          :end="end"
          v-on:sendPeriod="receivePeriod" />
      </contents-box>
    </stack-item>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VueScrollTo from 'vue-scrollto';
import moment from 'moment';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';
import BasicBtn from '@/views/components/BasicBtn.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Recorder from '../../../lib/recorder';
import Meter from '@/views/components/Meter.vue';
import LineGraph from '@/views/components/LineGraph.vue';
import GraphPeriod from '@/views/components/GraphPeriod.vue';
import Particle from '@/views/components/Particle.vue';
import Fortune from '@/views/components/Fortune.vue';
import StackItem from '@/views/components/StackItem.vue';

export default {
  name: 'mimosys',
  mixins: [cf],
  components: {
    Spacer,
    BasicBtn,
    ContentsBox,
    Meter,
    LineGraph,
    GraphPeriod,
    Particle,
    Fortune,
    StackItem,
  },
  data() {
    return {
      size: 1,
      recorder: this.initRecorder(),
      graphData: null,
      graphHeight: 200,
      recordList: [],
      recordCount: 0,
      recodingMessage: {
        main: null,
        plain: null,
      },
      flag: {
        isRecording: false,
        notAllowedCapture: false,
        count: 0,
        effectSize: true,
        isDisplay: false,
        loader: true,
      },
      fortune: {},
      recodingWord: [
        'チョコレート',
        'フルーツサンド',
        'コーヒーゼリー',
        'クリームソーダ',
        'フライドチキン',
        'サンドウィッチ',
        'パンケーキ',
        'シーザーサラダ',
        'キャラメル',
        'ツナサンド',
        'ドーナッツ',
        '夏みかん',
        'ナポリタン',
        'ヨーグルト',
        'オムライス',
        'カレーライス',
        'ショートケーキ',
        'チーズケーキ',
        'シフォンケーキ',
        'モンブラン',
        'ソフトクリーム',
      ],
      wordCheck: [],
      start: null,
      end: null,
      clubs: [],
      UA: null,
      isAndroid: false,
    };
  },
  created() {
    // initRecorderでthis.UAにUAを格納すると参照できない
    const UA = navigator.userAgent;
    this.UA = UA;
    this.isAndroid = UA.toLowerCase().includes('android');

    this.getGraphData();
    if (this.helper.master.clubs) {
      this.getClubMembers();
    } else {
      this.$store.subscribe((mutations) => {
        if (mutations.type === 'helper/putMaster') {
          this.getClubMembers();
        }
      });
    }
  },
  mounted() {
    const hash = this.$route.hash;
    if (hash) {
      const options = {
        offset: 0,
      };
      let element;
      if (hash.includes('uranai')) {
        // 占いへスクロール
        element = document.getElementById('uranai');
        VueScrollTo.scrollTo(element, 1000, options);
      } else if (hash.includes('graph')) {
        // グラフへスクロール
        setTimeout(() => {
          if (window.innerWidth <= 375) { // スマホだったらスクロール位置を少し上にする
            options.offset = -150;
          } else {
            options.offset = -180;
          }
          element = document.getElementById('graph');
          VueScrollTo.scrollTo(element, 1000, options);
        }, 500);
      }
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
    showVoice() {
      return this.graphData && !this.graphData.meter.energy;
    },
  },
  methods: {
    async getClubMembers() {
      const clubs = [];
      // バリデーション
      if (!this.helper.master.clubs || !this.helper.master.clubs.clubs || this.user.account_type !== 3) {
        this.clubs = clubs;
        this.flag.loader = false;
        return;
      }
      // clubs情報を整形
      Object.keys(this.helper.master.clubs.clubs).forEach((club) => {
        if (this.helper.master.clubs.clubs[club].teachers.includes(this.user.id)) {
          clubs.push({
            name: club,
            label: this.helper.master.clubs.club_names[club],
            memberIds: this.helper.master.clubs.clubs[club].students,
            members: [],
          });
        }
      });
      // user情報を取得
      await Promise.all(
        clubs.map(async (club) => {
          if (club.memberIds.length) {
            const params = {
              orderBy: 'desc',
              ids: club.memberIds,
            };
            await this.axios({
              method: 'GET',
              url: '/v1/user/get/search',
              params,
            })
              .then((response) => {
                const res = response.data;
                club.members = res.users.data;
              })
              .catch((error) => {
                if (error.message) console.log(error.message);
                else console.log(error);
              });
          }
          return true;
        }),
      );
      this.clubs = clubs;
      this.flag.loader = false;
    },

    /** パーティクル拡大 */
    expansionSize() {
      const timerId = setInterval(() => {
        this.size += 0.0012;
        if (this.size >= 1.3) {
          clearInterval(timerId);
        }
      }, 1);
    },
    /** パーティクル縮小 */
    shrinkSize() {
      const timerId = setInterval(() => {
        this.size -= 0.0015;
        if (this.size <= 1) {
          clearInterval(timerId);
        }
      }, 1);
    },
    initRecorder() {
      // recorder.jsに渡して判定に使用
      const UA = navigator.userAgent;
      return new Recorder({
        sampleRate: 11025,
        format: 'wav',
        UA,
      });
    },

    // 録音タイミング調整一旦戻し
    changeRecodingStatus() {
      navigator.mediaDevices.getUserMedia({
        video: false,
        audio: {
          channelCount: 1,
          echoCancellation: false,
        },
      })
        .then(async (stream) => { // マイク許可済み
          if (!this.flag.isRecording) await this.startRecording();
          if (this.flag.isRecording) await this.stopRecording();
          this.flag.isRecording = !this.flag.isRecording;
          stream.getTracks().forEach((track) => track.stop()); // マイクの使用を終えたらマイクの使用を明示的に停止
        })
        .catch((error) => { // マイクアクセス許可がない
          console.log(error);
          alert('マイクの使用を許可してもう一度お試しください。');
        });
    },

    async startRecording() {
      this.runAnimation();
      await this.recorder.start()
        .then((cap) => {
          if (String(cap).includes('Error')) {
            this.flag.notAllowedCapture = true;
            this.flag.isRecording = false;
          }
        });
    },

    async stopRecording() {
      await this.recorder.stop();
      this.recordList = this.recorder.recordList();
      const record = this.recorder.lastRecord();

      const now = moment(new Date());
      const form = new FormData();
      const file = new File([record.record.blob], `${moment(now).format('YYYY.MM.DD.HH.mm.ss')}.wav`);
      form.append('file', file);

      this.axios({
        method: 'POST',
        url: '/v1/mimosys/start/analysis',
        params: {
          user_id: this.user.id,
          // start_time: record.startTime,
        },
        data: form,
      })
        .then((res) => {
          if (res.data && res.data.error) {
            switch (res.data.error_code) {
              case 'E_LIC999':
                alert('システムエラーが発生しました。お手数ですが管理者にお問い合わせください。');
                this.faildRecoding();
                break;
              case 'E_APP001':
                alert('システムエラーが発生しました。再度お試しいただき、改善されない場合はお手数ですが管理者にお問い合わせください。');
                this.faildRecoding();
                break;
              case 'E_APP020':
                alert('音声の解析に失敗しました。端末のマイクに近づき再度お試しください。');
                this.faildRecoding();
                break;
              case 'E_APP999':
                alert('システムエラーが発生しました。お手数ですが管理者にお問い合わせください。');
                this.faildRecoding();
                break;
              default:
                alert('システムエラーが発生しました。再度お試しください。');
                this.faildRecoding();
                break;
            }
          } else {
            alert('データの解析が完了しました！結果よりご確認ください。');
            // グラフデータの更新
            this.start = null;
            this.end = null;
            this.getGraphData();
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          alert('予期せぬシステムエラーが発生しました。お手数ですが管理者にお問い合わせください。');
        });
    },

    runAnimation() { // 録音時の指示を時間区切りで切り替えていく処理
      this.flag.isRecoding = true;
      this.recodingMessage.plain = '<p>次の3つの単語を<br>ゆっくり言ってください</p>';
      setTimeout(this.displayWord, 2000);
    },

    displayWord() { // ランダムな単語を配列から選択して表示
      this.flag.isDisplay = true;
      /**
       * gainは増幅度は常用対数なので大きめに変更
       * compressorを通すのでgainが上がっても音割れ緩和
       */
      // Androidは収録レベルが大きくなりすぎてしまうため減らす
      const gain = this.isAndroid ? 14 : 20;
      this.recorder.applyGainValue(gain);
      this.recodingMessage.plain = '';
      let word = null;
      if (this.flag.count >= 3) {
        this.stopRecording();
        this.recodingMessage.main = '';
        this.recodingMessage.plain = '';
      } else {
        while (true) {
          word = this.recodingWord[Math.floor(Math.random() * this.recodingWord.length)];
          if (!this.wordCheck.includes(word)) {
            this.wordCheck.push(word);
            break;
          }
        }
        this.recodingMessage.main = `<p>${word}</p>`;
        this.flag.count += 1;
        this.expansionSize();
        setTimeout(() => {
          this.flag.isDisplay = false;
        }, 3000);
        setTimeout(() => {
          this.recorder.applyGainValue(0);
          this.shrinkSize();
        }, 2000);
        setTimeout(this.displayWord, 4000);
        this.flag.effectSize = !this.flag.effectSize;
        setTimeout(() => { this.flag.effectSize = !this.flag.effectSize; }, 2000);
      }
    },

    /** グラフ描画データの取得 */
    async getGraphData() {
      if (window.innerWidth <= 440) this.graphHeight = 400;

      this.axios({
        method: 'GET',
        url: '/v1/graph/get/charts',
        params: {
          user_id: this.user.id,
          start: this.start,
          end: this.end,
        },
      })
        .then((response) => {
          this.graphData = response.data.result;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /** 期間を受け取ってグラフ再取得 */
    receivePeriod(data) {
      this.start = data.start;
      this.end = data.end;
      this.getGraphData();
    },
    faildRecoding() {
      this.flag.isRecording = false;
      this.flag.isRecoding = false;
      this.flag.notAllowedCapture = false;
      location.reload();
    },
  },
};
</script>

<style lang="scss" module>
.uranai {
  &_outer {
    position: relative;
  }
  &_mark {
    position: absolute;
    top: -177px;
    @include sm-view {
      top: -130px;
    }
  }
}
.danger {
  color: red;
  text-align: center;
}
.flex_item {
  display: flex;
  justify-content: space-around;
}
.animationBox {
  background: -moz-linear-gradient(top, #333333, black 12.5%, black);
  background: -webkit-linear-gradient(top, #333333, black 12.5%, black);
  background: linear-gradient(to bottom, #333333, black 12.5%, black);
  border-radius: 10px;
  margin: 0 auto;
  height: 90vmin;
  max-height: 600px;
}
.particleAnime {
  position: relative;
  // top: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.recoding_explanation {
  color: white;
  text-shadow: 1px 2px 2px #222222;
  font-size: 1.5em;
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: bold;
  display: table;
  width: 100%;
  .bigger_text {
    font-size: 1.5em;
    @include sm-view {
      font-size: 1.3em;
    }
  }
  @include sm-view {
    font-size: 1.2em;
  }
}
</style>

<style lang="scss" scoped>
.v {
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
  &-enter-active {
    transition: opacity .5s;
  }
  &-enter-to,
  &-leave {
    opacity: 1;
  }
  &-leave-active {
    transition: opacity .5s;
  }
}


/* ボイスと占い結果の切り替わり */
.fade-voice {
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
  &-enter-active {
    transition: opacity .5s;
  }
  &-enter-to,
  &-leave {
    opacity: 1;
  }
  &-leave-active {
    transition: opacity 1s;
  }
}


.fade-fortune {
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
  &-enter-active {
    transition: opacity 5s;
  }
  &-enter-to,
  &-leave {
    opacity: 1;
  }
  &-leave-active {
    transition: opacity .5s;
  }
}
</style>

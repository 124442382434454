<template>
  <div>
    <Pie
    :chartData="ChartData"
    :chart-options="chartOptions" />
  </div>
</template>

<script>
import {
  Chart,
  registerables,
} from 'chart.js';
import { Pie } from 'vue-chartjs';

Chart.register(...registerables);

export default {
  name: 'PieChart',
  components: {
    Pie,
  },
  props: {
    labelsData: { // 表に付属する色と対応しているデータの情報を表すラベル
      type: Array,
      default: null,
    },
    datasetsData: { // グラフ描画用データ
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      ChartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        plugins: {
          legend: {
            display: false,
            // position: 'bottom',
          },
        },
        // maintainAspectRatio: false, // グラフ幅が狭くなっても高さを維持する
      },
    };
  },
  created() {
  },
  mounted() {
    this.drawGraph();
  },
  watch: {
    labelsData() { // 期日や絞り込み条件の変動があったら再描画
      this.updateGraph();
    },
  },
  methods: {
    updateGraph() {
      this.ChartData.labels = [];
      this.ChartData.datasets = [];
      this.drawGraph();
    },
    drawGraph() {
      this.labelsData.forEach((elem) => {
        this.ChartData.labels.push(elem);
      });
      this.ChartData.datasets.push(this.datasetsData);
    },
  },
};
</script>

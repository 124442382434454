<template>
  <div>
    <div>
      <h2 :class="$style.title">{{ typeLabel }}健康相談<br>{{ isCounselor ? '当日キャンセル' : '予約キャンセル'}}</h2>
      <spacer v-if="!isCounselor" :y="2"/>
      <div :class="$style.policy" v-if="!isCounselor">
        <p :class="$style.text">
          キャンセルポリシー<br>
          <span :class="$style.red">ご予約日前日の17時以降のキャンセルは、当日キャンセル扱いとなります。</span>
        </p>
      </div>
    </div>
    <spacer :y="2"/>
    <div>
      <p :class="$style.text">キャンセル理由を選択してください。</p>
      <spacer :y="1"/>
      <ul :class="$style.radio">
        <li v-if="!isCounselor">
          <input
            :class="$style.input"
            type="radio"
            name="cancel"
            id="appointment"
            v-on:click="selectRadio"
            checked>
          <label
            :class="selected === 'appointment' ? [$style.label, $style.selected, isVirtual ? $style.virtual : null] : `${$style.label}`"
            for="appointment">急遽予定が入ったため</label>
        </li>
        <li v-if="!isCounselor">
          <input
            :class="$style.input"
            type="radio"
            name="cancel"
            id="health"
            v-on:click="selectRadio">
          <label
            :class="selected === 'health' ? [$style.label, $style.selected, isVirtual ? $style.virtual : null] : `${$style.label}`"
            for="health">体調不良のため</label>
        </li>
        <li v-if="isCounselor">
          <input
            :class="$style.input"
            type="radio"
            name="cancel"
            id="noAccess"
            v-on:click="selectRadio">
          <label
            :class="selected === 'noAccess' ? [$style.label, $style.selected, isVirtual ? $style.virtual : null] : `${$style.label}`"
            for="noAccess">利用者のアクセスがなかったため</label>
        </li>
        <li>
          <input
            :class="$style.input"
            type="radio"
            name="cancel"
            id="other"
            v-on:click="selectRadio">
          <label
            :class="selected === 'other' ? [$style.label, $style.selected, isVirtual ? $style.virtual : null] : `${$style.label}`"
            for="other">その他</label>
        </li>
      </ul>
      <spacer :y="3"/>
      <textarea
        :class="$style.textarea"
        placeholder="その他を選択した方は理由をこちらに記述してください。"
        name="textarea"
        id="textarea"
        cols="30"
        rows="5"
        v-model="message"></textarea>
    </div>
    <spacer :y="3"/>
    <div :class="$style.bottom">
      <button
        type="button"
        :class="$style.btn"
        v-on:click="cancelReserve">キャンセルを確定する</button>
    </div>
    <div :class="$style.close" v-on:click="hideModal"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import Spacer from '@/views/components/Spacer.vue';

export default {
  name: 'reserve-cancel',
  props: ['data'], // reserve_id | counseling_type
  components: {
    Spacer,
  },
  data() {
    return {
      selected: null,
      message: null,
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
    isVirtual() {
      return Number(this.data.counseling_type) === 3;
    },
    typeLabel() {
      return this.isVirtual ? 'バーチャル' : 'オンライン';
    },
    isCounselor() {
      return [21, 22].includes(this.user?.account_type) || this.data.operatorAsCounselor;
    },
  },
  created() {
    this.selected = this.isCounselor ? 'noAccess' : 'appointment';
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    selectRadio(e) {
      this.selected = e.target.id;
    },

    cancelReserve() {
      this.showLoading();
      let reason;
      if (this.selected === 'appointment') reason = 1;
      else if (this.selected === 'health') reason = 2;
      else if (this.selected === 'other') reason = 3;
      else if (this.selected === 'noAccess') reason = 4;
      const data = {
        id: this.data.reserve_id,
        user_id: this.user.id,
        cancel_reason: reason,
        flag: this.isCounselor ? 902 : 901, // キャンセル
      };
      // キャンセルコメント
      if (this.message) {
        let flag = 11; // 利用者キャンセルコメント
        if ([21, 22].includes(this.user.account_type)) {
          flag = 12; // 医師・心理士キャンセルコメント
        } else if (this.user.account_type === 31) {
          flag = 13; // 事務局キャンセルコメント
        } else if ([11, 12].includes(this.user.account_type)) {
          flag = 14; // 学校管理者キャンセルコメント
        }
        data.memoData = {
          flag,
          memo: this.message,
        };
      }

      this.axios({
        method: 'POST',
        url: '/v1/reserve/set/updater',
        data,
      })
        .then(() => {
          this.$store.dispatch('user/counseling/getCounseling', null, { root: true });
          alert('ご予約のキャンセルを承りました。\nトップページへリダイレクトします。');
          // キャンセルの連絡
          this.onlineCounselingContact(this.data.reserve_id);
          // 予約storeの更新
          this.$store.dispatch('user/counseling/getCounseling', null, { root: true });
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideModal();
        });
    },

    hideModal(args = null) {
      this.$store.dispatch('modal/contents/hideModal', args, { root: true });
    },


    /** オンライン健康相談キャンセルの連絡 */
    onlineCounselingContact(reserveId) {
      const data = { reserve_id: reserveId };
      if ([11, 12].includes(this.user.account_type)) {
        // 学校管理者が行ったキャンセル処理
        data.fromSchool = true;
      }

      // 当日キャンセルか事前キャンセルかの判定
      const now = moment(new Date());
      const limitDate = moment(this.data.schedule.date).subtract(1, 'd').format('Y-MM-DD');
      const limitHour = moment(`${limitDate} 17:00:00`);
      // 現在時刻のlimit前後で判定
      const cancelType = now.isBefore(limitHour) ? 'online' : 'onTheDay';

      // 利用者へ連絡
      this.axios({
        method: 'POST',
        url: `/v1/contact/cancel/${cancelType}/user`,
        data,
      })
        .then((response) => {
          if (this.helper.env.name === 'local') {
            console.log(response.data);
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });

      // 心理士へ連絡
      this.axios({
        method: 'POST',
        url: `/v1/contact/cancel/${cancelType}/counselor`,
        data: { reserve_id: reserveId },
      })
        .then((response) => {
          if (this.helper.env.name === 'local') {
            console.log(response.data);
          }
          // トップへリダイレクト
          if (this.user.account_type < 11) {
            this.$router.push({
              path: '/',
              query: {
                tab: this.isVirtual ? 'virtual' : 'online',
              },
            });
          } else if (this.user.account_type < 21) {
            this.$router.push({
              path: '/teacher',
              query: { tab: 'history' },
            });
          } else if (this.user.account_type < 31) {
            const tabName = this.isVirtual ? 'virtual' : 'online';
            this.$router.push({
              path: '/counselor',
              query: { tab: tabName, page: 1 },
            });
          } else if (this.user.account_type === 31) {
            this.$router.push({
              path: '/operator',
              query: { tab: this.data.operatorAsCounselor ? 'history' : 'online' },
            });
          }
          // 強制リロード（TODO: storeの変更を検知してupdateしたい）
          setTimeout(() => { this.$router.go(this.$router.currentRoute.path); }, 500);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          // ローディング非表示
          this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module>
.title {
  @include sm-view {
    font-size: 16px;
  }
}
.policy {
  padding: 13px;
  border: 1px solid var(--pink);
  border-radius: 8px;
}
.text {
  font-size: 12px;
}
.red {
  color: var(--pink);
  font-size: 16px;
  @include sm-view {
    font-size: 14px;
  }
}
.radio {
  display: flex;
  flex-wrap: wrap;
  li {
    padding-bottom: 10px;
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
  .input {
    display: none;
  }
  .label {
    display: block;
    padding: 8px 25px;
    font-size: 16px;
    border: 1px solid var(--black);
    border-radius: 30px;
    &.selected {
      border: none;
      background-color: var(--orange-main);
      font-weight: bold;
      color: #fff;
      &.virtual {
        background-color: var(--green-main);
      }
    }
  }
}
.textarea {
  resize: vertical;
  min-height: 152.5px;
  border: none;
  outline: none;
  padding: 20px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  width: 100%;
}
.bottom {
  display: flex;
  justify-content: center;
}
.btn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
}
.close {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background-color: var(--gray-main);
  border-radius: 50%;
  cursor: pointer;
  &::before, &::after {
    content: '';
    width: 3px;
    height: 30px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  @include sm-view {
    width: 20px;
    height: 20px;
    &::before, &::after {
      content: '';
      width: 2px;
      height: 13px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
}
</style>

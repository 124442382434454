<template>
  <div v-if="flag.display">
    <basic-btn
      tag="button"
      size="sm"
      @click="prev()">
      戻る
    </basic-btn>
  </div>
</template>

<script>
/**
 * 別タブで開いた状態で戻るボタンを、クリックすると同じ内容のタブが2つになるので注意
 * 各サイトタブからアクセスできるページかつ同一タブで開くもの直下でのみ使うようにすること
 */
import { mapState } from 'vuex';
import BasicBtn from './BasicBtn.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  mixins: [cf],
  name: 'PrevBtn',
  props: {},
  components: {
    BasicBtn,
  },
  data() {
    return {
      flag: {
        display: false,
      },
      prevPage: null,
    };
  },
  created() {
    const ls = cf.getLocalStorage('wtt');
    if (ls && ls.saveState) {
      this.flag.display = true;
      this.prevPage = ls.saveState;
    }
  },
  watch: {},
  computed: {
    ...mapState(['user', 'helper']),
  },
  methods: {
    prev() {
      const userType = this.user.account_type;
      let typeName;
      // サイトタイプによってURLに行う操作を選択
      if (userType >= 30) typeName = new RegExp(/\/(?!operator\/).*/);
      else if (userType >= 20) typeName = new RegExp(/\/(?!counselor\/).*/);
      else if (userType >= 10) typeName = new RegExp(/\/(?!teacher\/).*/);
      else typeName = new RegExp(/\/.*/);
      const queryKey = Object.keys(this.prevPage)[0];
      const queryValue = Object.values(this.prevPage)[0];
      const fullPath = this.$route.fullPath;
      let pagePath;
      // 利用者サイトは/がトップなので以下にて変換↓
      if (userType <= 3) pagePath = fullPath.replace(typeName, '/');
      else pagePath = fullPath.replace(typeName, '');
      const query = {};
      query[queryKey] = queryValue;
      this.$router.push({
        path: pagePath,
        query,
      });
    },
  },
};
</script>

<style lang="scss" module>
</style>

<template>
  <div>
    <ul :class="$style.items">
      <stack-item>
        <label-box label="受付日時" color="gray" size="sm" :class="[$style.label, showReserveIds.length ? $style.label_wide : '' ]" smAlign="left"/>
        <p :class="$style.limitStatus">
          <span>{{ filterMoment(data.created_at, 'YYYY年MM月DD日 HH:mm') }}</span>
        </p>
      </stack-item>

      <stack-item v-if="!data.done">
        <label-box label="回答期限" color="gray" size="sm" :class="[$style.label, showReserveIds.length ? $style.label_wide : '' ]" smAlign="left"/>
        <p :class="$style.limitStatus">
          <span
            :style="`background: ${getLimitAlert(data.limit).color}`"
            :class="$style.circleStatus"
            v-if="getLimitAlert(data.limit).color"></span> <span>{{ filterMoment(data.limit, 'YYYY年MM月DD日') }}<b v-if="getLimitAlert(data.limit).label">（{{ getLimitAlert(data.limit).label }}）</b></span>
        </p>
      </stack-item>

      <stack-item
        v-if="data.customer.messageGroups.length">
        <label-box label="相談履歴" color="gray" size="sm" :class="[$style.label, showReserveIds.length ? $style.label_wide : '' ]" smAlign="left"/>
        <div :class="$style.content">
          <div :class="$style.who5_top">
            <p>{{ data.customer.messageGroups.length }}件</p>
            <div :class="$style.plus" @click="changeShowFlag($event, 'history')">
              <i v-if="flag.show.history" class="fa-solid fa-minus"></i>
              <i v-if="!flag.show.history" class="fa-solid fa-plus"></i>
            </div>
          </div>

          <div v-if="flag.show.history">
            <ul :class="$style.btns">
              <li
                :class="$style.btn"
                v-for="row in data.customer.messageGroups"
                :key="row.id">
                <a
                  :href="`/counselor/reserve/text/${row.id}/`"
                  target="_blank">{{ filterMoment(row.created_at, 'M/D') }} <span v-for="typeNum in row.type.type" :key="typeNum">{{ helper.master.labels.texts.type[typeNum] }}</span></a>
              </li>
            </ul>
          </div>
        </div>
      </stack-item>

      <stack-item v-if="data.who5Answer">
        <label-box label="ココモニ" color="gray" size="sm" :class="[$style.label, showReserveIds.length ? $style.label_wide : '' ]" smAlign="left"/>
        <div :class="$style.content">
          <div :class="$style.who5_top">
            <p v-if="data.scoreWho5 !== null"><span :class="$style.sub">WHO-5</span>{{ data.scoreWho5 }}点
            </p>
            <div
              :class="$style.plus"
              v-on:click="changeShowFlag($event, 'who5')">
              <i v-if="flag.show.who5" class="fa-solid fa-minus"></i>
              <i v-if="!flag.show.who5" class="fa-solid fa-plus"></i>
            </div>
          </div>

          <div v-if="flag.show.who5 && data.who5Answer.answers.length">
            <spacer :y="1"/>
            <ul :class="$style.who5">
              <li v-for="(item, i) in helper.master.who5.items" :key="item">
                <p>{{ item.label }}</p>
                <p :class="$style.who5_answer">{{ helper.master.who5.labels[data.who5Answer.answers[i]] }}</p>
              </li>
            </ul>
          </div>
        </div>
      </stack-item>

      <stack-item v-if="data.type && data.type.length">
        <label-box label="お悩み" color="gray" size="sm" :class="[$style.label, showReserveIds.length ? $style.label_wide : '' ]" smAlign="left"/>
        <div :class="[$style.value, $style.type]">
          <p v-for="dataType in data.type" :key="dataType">{{ helper.master.labels.texts.type[dataType] }}
          <span v-if="data.other_text && dataType === 6" :class="$style.worries_detail">（{{ data.other_text }}）</span></p>
        </div>
      </stack-item>

      <stack-item v-if="data.messages.length" class="n2br">
        <label-box label="相談内容" color="gray" size="sm" :class="[$style.label, showReserveIds.length ? $style.label_wide : '' ]" smAlign="left"/>
        <p :class="$style.content" v-html="data.messages[0].message"></p>
      </stack-item>

      <stack-item
        :style="showReserveIds.length ? 'align-items: stretch;' : null">
        <label-box :label="'オンライン健康相談予約'" color="gray" size="sm" :class="[$style.label, $style.label_wide]" smAlign="left"/>
        <div :class="$style.reserve_flex" v-if="showReserveIds.length">
          <div :class="$style.who5_top">
            <ul :class="$style.reserve_btns">
              <li
                v-for="(row, i) in data.customer.reserves"
                :key="row.id">
                <div v-if="showReserveIds.includes(row.id)" :class="$style.reserve_contents">
                  <div :class="$style.reserve_dflex">
                    <label-box label="実施情報" color="gray" size="sm" :class="[$style.label, $style.label_narrow]" smAlign="left"/>
                    <p><span>{{ formatTimestamp(row.created_at, 'YYYY/MM/DD') }}</span><span>{{ formatTimestamp(row.schedule.date, 'YYYY/MM/DD HH:00〜HH:45') }}</span><span>{{ row.toUser.username }} ({{ helper.master.labels.users.account_type[row.toUser.account_type] }})</span></p>
                  </div>
                  <spacer :y="1"/>
                  <div :class="$style.worries">
                    <label-box label="お悩み" color="gray" size="sm" :class="[$style.label, $style.label_narrow]" smAlign="left"/>
                    <div :class="$style.dd">
                      <div :class="[$style.value, $style.type]">
                        <p
                          v-for="typeNum in row.type.type"
                          :key="typeNum"
                          class="n2br"
                        >{{ helper.master.labels.texts.type[typeNum] }}<span v-if="typeNum === 6 && row.type.text">（{{ row.type.text }}）</span></p>
                      </div>
                    </div>
                  </div>
                  <spacer v-if="i !== data.customer.reserves.length - 1" :y="2"/>
                  <spacer :y="1"/>
                  <div :class="$style.contents">
                    <label-box label="相談内容" color="gray" size="sm" :class="[$style.label, $style.label_narrow]" smAlign="left"/>
                    <div v-if="row.memos && row.memos.customer && row.memos.customer.length">
                      <div v-for="(detail) in row.memos.customer" :key="detail" class="n2br" consult_content>
                        <p v-if="showReserveIds.includes(detail.reserve_id)" >{{ detail.memo }}</p>
                      </div>
                    </div>
                    <div v-else>
                      <div :class="$style.consult_content">
                        <p>未入力</p>
                      </div>
                    </div>
                  </div>
                  <spacer v-if="i !== data.customer.reserves.length - 1" :y="2"/>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div :class="$style.reserve_flex" v-if="!showReserveIds.length">
          <p :class="$style.content">予約なし</p>
        </div>
      </stack-item>
    </ul>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import StackItem from '@/views/components/StackItem.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';
import moment from 'moment';

export default {
  name: 'counseling-base-data',
  mixins: [cf],
  components: {
    StackItem,
    LabelBox,
    Spacer,
  },
  props: {
    data: {
      type: Object,
    },
    showProgressReserve: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      flag: {
        show: {
          who5: false,
          history: false,
        },
      },
    };
  },
  created() {
  },
  computed: {
    ...mapState(['helper']),
    showReserveIds() {
      const array = [];
      if (this.showProgressReserve
        && this.data.customer
        && this.data.customer.reserves.length) {
        this.data.customer.reserves.forEach((reserve) => {
          if (reserve.flag === 1
            && moment(reserve.schedule.date).isAfter(new Date())) {
            array.push(reserve.id);
          }
        });
      }
      return array;
    },

  },
  methods: {
    changeShowFlag(e, name) {
      e.preventDefault();
      const next = !this.flag.show[name];
      this.flag.show[name] = next;
    },
  },
};
</script>

<style lang="scss" module>
.items {
  li {
    display: flex;
    align-items: flex-start;
    @include sm-view {
      font-size: 14px;
      display: block;
    }
    .content {
      margin-top: 3px;
      flex: 1;
    }
  }
}
.label {
  display: flex;
  width: 100px;
  margin-right: 20px;
    align-items: center;
    justify-content: center;
  @include sm-view {
    width: 100%;
    margin-bottom: 8px;
  }
  &_wide {
    width: 180px;
    @include sm-view {
      width: 100%;
      margin-bottom: 8px;
    }
  }
  &_narrow {
    width: 100px;
    min-width: 100px;
    @include sm-view {
      width: 80px;
      min-width: 80px;
      margin-bottom: 0px;
    }
  }
}
.btns {
  display: flex;
  flex-wrap: wrap;
  li {
    margin-right: 10px;
    margin-top: 8px;
    white-space: nowrap;
    a {
      color: var(--black);
    }
  }
  .btn {
    padding: 4px 12px;
    border-radius: 4px;
    background-color: var(--gray-sub);
    span {
      &:not(:first-child) {
        margin-left: .3em;
      }
    }
  }
}
.reserve_btns {
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  .btn {
    padding: 4px 12px;
    border-radius: 4px;
    background-color: var(--gray-sub);
  }
}
.who5 {
  li {
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
  .who5_answer {
    font-weight: bold;
  }

  &_top {
    display: flex;
    justify-content: space-between;
    .plus {
      font-size: 20px;
      color: var(--gray-main);
      cursor: pointer;
    }
    .sub {
      margin-right: 12px;
    }
  }
}
.reserve {
  margin-top: 5px;
  &_flex {
    flex: 1;
  }
  &_dflex {
    display: flex;
  }
  &_center {
    align-items: center;
  }
}
.limitStatus {
  margin-top: 3px;
  display: flex;
  align-items: center;
  .circleStatus {
    $size: 1.2em;
    margin-right: .5rem;
    width: $size;
    height: $size;
    border: 1px solid #ddd;
    border-radius: 100%;
    overflow: hidden;
  }
}

.reserve_dflex {
  p {
    span {
      font-size: .9em;
      &:not(:first-child) {
        margin-left: .8em;
      }
    }
  }
  li {
    font-size: .9em;
    p {
      width: 100%;
    }
  }
}

.worries {
  display: flex;
  font-size: .9em;
  .dd {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.6;
    > * {
      &:not(:last-child) {
        margin-right: .8em;
      }
    }
  }
  &_detail {
    width: 100%;
    white-space: normal;
  }
}
.value {
  flex: 1;
  margin-top: 3px;
  &.type {
    display: flex;
    flex-wrap: wrap;
    p {
      white-space: nowrap;
      &:not(:last-child) {
        &::after {
          margin: 0 5px;
          content: '/';
        }
      }
    }
  }
}
.contents {
  display: flex;
}
.consult_content {
  margin-top: 3px;
}
</style>

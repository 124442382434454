<template>
  <div>
    <spacer :y="8.5" :md-y="4" :sm-y="2"/>
    <loader-simple :flag="flag.loader">
      <div v-if="!flag.loader">
        <carousel
          :items-to-show="1"
          :wrapAround="true"
          dir="ltr"
          :autoplay="10000"
          :pauseAutoplayOnHover="true">
          <slide
            v-for="slide in sliders"
            :key="slide.name">
              <div @click="clickSlider(slide.name)" :class="$style.sliderOuter">
                <a :href="`${slide.to}`">
                  <div
                    class="slide"
                    :class="[
                      $style.carousel__item,
                      $style.slider_setting,
                      `${slide.name}`,
                      sliderUnvClass(slide),
                    ]"></div>
                </a>
              </div>
          </slide>
            <template #addons>
              <navigation />
              <pagination />
            </template>
        </carousel>
        <tab-nav
          id="tab"
          :items="tabItems"
          :isActive="isActive"
          v-on:sendIsActiveValue="receiveIsActiveValue"/>
        <div v-if="helper && helper.textDisableStatus && helper.textDisableStatus.emphasis_notice">
          <spacer :y="3" :sm-y="3" />
          <emphasis-notice
            v-if="helper.textDisableStatus.emphasis_notice
              && helper.textDisableStatus.show_emphasis_notice"
            :message="helper.textDisableStatus.emphasis_notice" />
          <spacer :y="3" :sm-y="3" />
        </div>
        <spacer v-else :y="6" :sm-y="3" />
        <!-- ホーム以外のタブ -->
        <div v-if="isActive !== 'home'">
          <div
            v-for="(tab, key) in tabItems"
            :key="tab.name"
            :id="`panel-${tab.name}`"
            :aria-labelledby="`tab-${tab.name}`"
            :aria-hidden="key !== isActive">
            <component
              v-if="key === isActive"
              :is="`${tab.name}`"
              :project="project" />
          </div>
        </div>
        <!-- ホームタブ -->
        <two-column v-if="isActive === 'home'">
          <template v-slot:main >
            <ul>
              <loader-simple :flag="loaderFlag">
                <div v-show="!loaderFlag">
                  <stack-item v-if="!hasFuture && !hasHistory && !hasProgress && !hasAnswered && !hasVirtualFuture && !hasVirtualHistory">
                    <contents-box title="まずは気軽にご相談ください" icon="<i class='fa-solid fa-user-doctor'></i>">
                      <p>Welcome to talkでは、医師・心理士の先生に対面式（ZOOM）で相談できる「オンライン健康相談」、心理士の先生にテキストベースで相談できる「テキスト健康相談」を用意しています。</p>
                      <spacer :y="2"/>
                      <btn-container>
                        <basic-btn to="/reserve/">くわしく見る</basic-btn>
                      </btn-container>
                    </contents-box>
                  </stack-item>

                  <stack-item>
                    <contents-box title="ステータス" icon="<i class='fa-solid fa-user-doctor'></i>">

                      <stack-item>
                        <contents-box
                          type="inner"
                          title="バーチャル健康相談"
                          icon="<i class='fa-solid fa-vr-cardboard'></i>"
                          iconColor="green"
                        >
                          <spacer :y="2"/>
                          <ul :class="$style.status" v-if="hasVirtualFuture">
                            <stack-item :space="1" v-for="row in virtualReserves" :key="row.id">
                              <router-link :to="`/reserve/virtual/${row.id}/`">
                                <p>{{ filterMoment(row.schedule.date, 'M月D日H時') }}～バーチャル健康相談の予約が入っています。</p>
                              </router-link>
                            </stack-item>
                          </ul>
                          <div
                            v-else
                            :class="$style.flex_counseling_container"
                          >
                            <div :class="$style.first_box">
                              <p>受付中</p>
                            </div>
                            <div :class="$style.second_box">
                              <btn-container>
                                <basic-btn :to="{
                                  path: '/',
                                  query: {
                                    tab: 'virtual',
                                  },
                                }" size="sm">相談する</basic-btn>
                              </btn-container>
                            </div>
                          </div>
                        </contents-box>
                      </stack-item>

                      <stack-item>
                        <contents-box
                          type="inner"
                          title="オンライン健康相談"
                          icon="<i class='fa-regular fa-laptop'></i>"
                          inner-icon="<i class='fa-regular fa-user-headset'></i>"
                          iconColor="orange"
                        >
                          <spacer :y="2"/>
                          <ul :class="$style.status" v-if="hasFuture">
                            <stack-item :space="1" v-for="row in reserves" :key="row.id">
                              <router-link :to="`/reserve/online/${row.id}/`">
                                <p>{{ filterMoment(row.schedule.date, 'M月D日H時') }}～オンライン健康相談の予約が入っています。</p>
                              </router-link>
                            </stack-item>
                          </ul>
                          <div
                            v-else
                            :class="$style.flex_counseling_container"
                          >
                            <div :class="$style.first_box">
                              <p>受付中</p>
                            </div>
                            <div :class="$style.second_box">
                              <btn-container>
                                <basic-btn :to="{
                                  path: '/',
                                  query: {
                                    tab: 'online',
                                  },
                                }" size="sm">相談する</basic-btn>
                              </btn-container>
                            </div>
                          </div>
                        </contents-box>
                      </stack-item>

                      <stack-item>
                        <contents-box
                          type="inner"
                          title="テキスト健康相談"
                          icon="<i class='fa-regular fa-comment-lines'></i>"
                          iconColor="blue"
                        >
                          <spacer :y="2"/>
                          <div :class="$style.flex_counseling_container">
                            <div :class="$style.first_box">
                              <p v-if="hasProgress">回答待ち</p>
                              <p v-if="!hasProgress
                                && user.school[0].billing_type.billing_type === 1
                                && user.school[0].textCount > 0">受付中</p>
                              <p v-if="!hasProgress
                                && user.school[0].billing_type.billing_type === 1
                                && user.school[0].textCount <= 0
                                && !ignores()">ご利用上限回数に達しているため受付できません</p>
                              <p v-if="!hasProgress
                                && user.school[0].billing_type.billing_type === 2">受付中</p>
                            </div>
                            <div :class="$style.second_box">
                              <btn-container v-if="!hasProgress
                                && user.school[0].billing_type.billing_type === 1
                                && user.school[0].textCount > 0">
                                <basic-btn :to="{
                                  path: '/',
                                  query: {
                                    tab: 'text',
                                  },
                                }" size="sm">相談する</basic-btn>
                              </btn-container>
                              <btn-container v-if="!hasProgress && user.school[0].billing_type.billing_type === 2">
                                <basic-btn :to="{
                                  path: '/',
                                  query: {
                                    tab: 'text',
                                  },
                                }" size="sm">相談する</basic-btn>
                              </btn-container>
                            </div>
                          </div>
                        </contents-box>
                      </stack-item>

                      <stack-item v-if="operatorMessages.length">
                        <contents-box type="inner" title="事務局メッセージ" icon="<i class='fa-regular fa-circle-info'></i>">
                          <spacer :y="1"/>
                          <ul :class="$style.operatorMessageList">
                            <li v-for="operatorMessage in operatorMessages" :key="operatorMessage.id">
                              <router-link :to="`/text/${operatorMessage.pivot.group_id}/`" :class="$style.link">
                                <div :class="$style.operator_wrap">
                                  <div :class="$style.title_wrap">
                                    <p :class="$style.operator_title">{{ operatorMessage.message }}</p>
                                  </div>
                                </div>
                              </router-link>
                            </li>
                          </ul>
                        </contents-box>
                      </stack-item>
                    </contents-box>
                  </stack-item>

                  <stack-item>
                    <contents-box title="占いボイス" icon="<i class='fa-solid fa-microphone'></i>">
                      <div v-if="showBanner">
                        <a href="/?tab=cocomoni#uranai"><img src="/img/uranai/uranai-bnr.png" alt="占いボイス" style="width: 100%;"></a>
                        <spacer :y="2"/>
                        <p> 声からはじめるココロのセルフケア。今日の運勢もわかるよ。</p>
                        <spacer :y="2"/>
                        <div class="btn-animation">
                          <p class="animationBtn1"><a href="/?tab=cocomoni#uranai">はじめる</a></p>
                        </div>
                      </div>

                      <Fortune v-else />
                    </contents-box>
                  </stack-item>

                  <stack-item v-if="graphData">
                    <contents-box title="ココモニ" icon="<i class='fa-solid fa-monitor-waveform'></i>">
                      <line-graph
                        :fromWhere="'home'"
                        :power="graphData.graph.stenScores"
                        :datetime="graphData.graph.dateTimes"
                        :who5="graphData.graph.who5Scores"
                        :labels="graphData.graph.dataLabels"
                        :height="graphHeight" />

                      <spacer y="3" />
                      <btn-container>
                        <basic-btn href="/?tab=cocomoni#graph" tag="a">詳細</basic-btn>
                      </btn-container>

                    </contents-box>
                  </stack-item>
                </div>
              </loader-simple>
            </ul>
          </template>

          <template v-slot:side >
            <ul v-show="!loaderFlag">
              <limit-count />

              <stack-item>
                <contents-box title="LINE登録でより簡単に" icon="<i class='fa-solid fa-megaphone'></i>">
                  <p>オンライン健康相談の予約やテキスト健康相談の回答をLINEでチェックできます。</p>
                  <spacer :y="2"/>
                  <contents-box type="inner">
                    <div :class="$style.flex_center">
                      <a :href="`https://lin.ee/${helper.env.LINEBOT}`" target="_blank"><img :class="$style.qr" :src="`/img/default/line_${helper.env.name}.png`"></a>
                    </div>
                    <spacer :y="2"/>
                    <div :class="$style.flex_center">
                      <p :class="$style.img_title">LINE 公式アカウント</p>
                    </div>
                    <div :class="$style.flex_center">
                      <p :class="$style.position">連携するとリマインドやお知らせが届きます！</p>
                    </div>
                  </contents-box>
                </contents-box>
              </stack-item>

              <stack-item>
                <contents-box title="サービスをみる" icon="<i class='fa-regular fa-paperclip-vertical'></i>">
                  <p>それぞれのサービスの内容や違いについて見てみよう。</p>
                  <spacer :y="2"/>
                  <btn-container>
                    <basic-btn to="/reserve/">詳細</basic-btn>
                  </btn-container>
                </contents-box>
              </stack-item>

              <stack-item v-if="this.user.account_type === 1 && this.user.school[0].name !== '個人向け'">
                <contents-box :title="helper.master && helper.master.labels && user.school && user.school.length ? helper.master.labels.organizationNews[user.school[0].is_company] : ''" icon="<i class='fa-regular fa-bullhorn'></i>">
                  <div v-if="schoolInformations.length">
                    <stack-item
                      v-for="row in schoolInformations"
                      :key="row.id">
                      <router-link
                        :to="`/schoolInformation/${row.id}/`"
                        :class="$style.link">
                        <contents-box type="inner">
                          <div :class="$style.labels">
                            <label-box
                              label="NEW"
                              size="sm"
                              color="orange"
                              :class="$style.label"
                              v-if="user.readflags && !user.readflags.schoolInformations.includes(row.id)" />
                          </div>
                          <spacer
                            :y="1"
                            v-if="user.readflags && !user.readflags.schoolInformations.includes(row.id)"/>
                          <label-box
                            :label="filterMoment(row.updated_at, 'YYYY-MM-DD')"
                            size="sm"
                            color="gray"
                            :class="$style.infoDate" />

                          <spacer :y="1"/>
                          <div :class="$style.texts">
                            <div :class="$style.title">{{ row.title }}</div>
                            <spacer :y=".5"/>
                            <div :class="$style.content"><p v-html="replaceImgTag(row.content)"></p></div>
                          </div>
                        </contents-box>
                      </router-link>
                    </stack-item>

                    <spacer :y="3"/>
                    <btn-container>
                      <basic-btn to="/schoolInformation/">一覧</basic-btn>
                    </btn-container>
                  </div>
                  <div v-else>
                    <p>現在{{ helper.master && helper.master.labels && user.school && user.school.length ? helper.master.labels.organizationTypes[user.school[0].is_company] : '' }}からのお知らせはありません</p>
                  </div>
                </contents-box>
              </stack-item>

              <stack-item v-if="informations.length">
                <contents-box title="事務局からのお知らせ" sub="welcome to talk" icon="<i class='fa-regular fa-circle-info'></i>">
                  <stack-item
                    v-for="row in informations"
                    :key="row.title">
                    <router-link
                      :to="`/information/${row.id}/`"
                      :class="$style.link">
                      <contents-box type="inner">
                        <div :class="$style.labels">
                          <label-box
                            label="NEW"
                            size="sm"
                            color="orange"
                            :class="$style.label"
                            v-if="user.readflags && !user.readflags.informations.includes(row.id)" />
                        </div>
                        <spacer
                          :y="1"
                          v-if="user.readflags && !user.readflags.informations.includes(row.id)"/>

                        <div :class="$style.news">

                          <label-box
                            :label="filterMoment(row.updated_at, 'YYYY-MM-DD')"
                            size="sm"
                            color="orange"
                            :class="$style.infoDate" />

                          <spacer :y="1" v-if="row.flag === 11" />

                          <label-box
                            label="重要"
                            size="sm"
                            color="pink"
                            v-if="row.flag === 11"
                            :class="$style.emphasis" />
                        </div>
                        <spacer :x="2"/>

                        <div :class="$style.texts">
                          <div :class="$style.title">{{ row.title }}</div>
                          <spacer :y=".5"/>
                          <div :class="$style.content"><p v-html="replaceImgTag(row.content)"></p></div>
                        </div>
                      </contents-box>
                    </router-link>
                  </stack-item>

                  <spacer :y="3"/>
                  <btn-container>
                    <basic-btn to="/information/">一覧</basic-btn>
                  </btn-container>
                </contents-box>
              </stack-item>

              <stack-item :space="5" :class="$style.banners">
                <spacer :y="1"/>
                <stack-item tag="div">
                  <a href="https://www.youtube.com/watch?v=De7L0L5rhL4" target="_blank"><img src="/img/banner/banner01.jpg"></a>
                </stack-item>
                <stack-item tag="div">
                  <a href="https://welcometotalk.co.jp/blog" target="_blank"><img src="/img/banner/banner02.jpg"></a>
                </stack-item>
              </stack-item>
            </ul>
          </template>
        </two-column>
      </div>
    </loader-simple>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TwoColumn from '@/views/layouts/TwoColumn.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import UserBox from '@/views/components/UserBox.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import TabNav from '@/views/components/TabNav';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';
import LineGraph from '@/views/components/LineGraph.vue';
import EmphasisNotice from '@/views/components/EmphasisNotice.vue';
import TextCounseling from '@/views/pages/User/TextCounseling.vue';
import OnlineCounseling from '@/views/pages/User/OnlineCounseling.vue';
import VirtualCounseling from '@/views/pages/User/VirtualCounseling.vue';
import History from '@/views/pages/User/History.vue';
import Counselors from '@/views/pages/User/Counselors.vue';
import Cocomoni from '@/views/pages/User/Cocomoni.vue';
import Fortune from '@/views/components/Fortune.vue';
import Units from '@/views/pages/User/Units.vue';
import LimitCount from '@/views/components/LimitCount4BillingAccount.vue';
import cf from '@/mixins/commonFunctions';
import {
  Carousel,
  Slide,
  Pagination,
  Navigation,
} from 'vue3-carousel';

export default {
  name: 'Home',
  mixins: [cf],
  components: {
    BasicBtn,
    BtnContainer,
    TwoColumn,
    UserBox,
    LabelBox,
    TabNav,
    ContentsBox,
    StackItem,
    LoaderSimple,
    Spacer,
    TextCounseling,
    OnlineCounseling,
    VirtualCounseling,
    History,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Counselors,
    Cocomoni,
    Fortune,
    LineGraph,
    Units,
    LimitCount,
    EmphasisNotice,
  },
  data() {
    return {
      flag: {
        loader: true,
      },
      /**
       * sliderを追加する場合、専門・大学以上の
       * 利用者用画像（_unv）も用意する必要があるので注意
       */
      sliders: [
        { name: 'virtual', to: '/reserve/virtual/' },
        { name: 'cocomoni', to: '/?tab=cocomoni#uranai' },
        { name: 'reserve', to: '/reserve/' },
        { name: 'text', to: '/reserve/text/' },
        { name: 'online', to: '/reserve/online/' },
      ],

      tabItems: {
        home: {
          label: 'ホーム',
          link: '/',
          new: false,
        },
        cocomoni: {
          label: 'ココモニ',
          name: 'cocomoni',
          new: false,
        },
        text: {
          label: 'テキスト',
          name: 'text-counseling',
        },
        online: {
          label: 'オンライン',
          name: 'online-counseling',
        },
        virtual: {
          label: 'バーチャル',
          name: 'virtual-counseling',
        },
        history: {
          label: '履歴',
          name: 'history',
          new: false,
        },
        counselors: {
          label: '医師・心理士',
          name: 'counselors',
          new: false,
        },
        units: {
          label: 'グループ',
          name: 'units',
          new: false,
        },
      },
      isActive: 'home',
      informations: [],
      schoolInformations: [],
      operatorMessages: [],
      graphData: null,
      graphHeight: 200,
    };
  },
  created() {
    if (this.user.email) {
      if (this.user.account_type > 10) this.redirect2top();
      else {
        this.getSchoolInformations();
        this.getInformations();
        this.getOperatorMessage();
        this.changeTab();
        this.getGraphData();
        this.setTabItems();
        this.checkTabState();
      }
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          if (this.user.account_type > 10) this.redirect2top();
          else {
            this.getSchoolInformations();
            this.getInformations();
            this.getOperatorMessage();
            this.changeTab();
            this.getGraphData();
            this.setTabItems();
            this.checkTabState();
          }
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
    hasFuture() {
      return this.reserves.length > 0;
    },
    hasHistory() {
      let flag = false;
      if (this.user.counseling
      && this.user.counseling.reserves
      && this.user.counseling.reserves.history
      && this.user.counseling.reserves.history.length) {
        flag = true;
      }
      return flag;
    },
    hasProgress() {
      let flag = false;
      if (this.user.counseling
      && this.user.counseling.texts
      && this.user.counseling.texts.progress
      && this.user.counseling.texts.progress.length) {
        flag = true;
      }
      return flag;
    },
    hasAnswered() {
      let flag = false;
      if (this.user.counseling
      && this.user.counseling.texts
      && this.user.counseling.texts.answered
      && this.user.counseling.texts.answered.length) {
        flag = true;
      }
      return flag;
    },
    hasVirtualFuture() {
      return this.virtualReserves.length > 0;
    },
    hasVirtualHistory() {
      let flag = false;
      if (this.user.counseling
      && this.user.counseling.virtualReserves
      && this.user.counseling.virtualReserves.history
      && this.user.counseling.virtualReserves.history.length) {
        flag = true;
      }
      return flag;
    },
    loaderFlag() {
      let flag = false;
      if (!this.user.counseling.reserves.history) flag = true;
      return flag;
    },
    // TODO virtual版のloaderFlagは必要か
    reserves() {
      let array = [];
      if (this.user.counseling
      && this.user.counseling.reserves
      && this.user.counseling.reserves.future
      && this.user.counseling.reserves.future.length) {
        array = cf.upSortReserves(this.user.counseling.reserves.future);
      }
      return array;
    },
    virtualReserves() {
      let array = [];
      if (this.user.counseling
      && this.user.counseling.virtualReserves
      && this.user.counseling.virtualReserves.future
      && this.user.counseling.virtualReserves.future.length) {
        array = cf.upSortReserves(this.user.counseling.virtualReserves.future);
      }
      return array;
    },
    showBanner() {
      return this.graphData && !this.graphData.meter.energy;
    },
  },
  watch: {
    $route(to, from) {
      this.changeTab();
      if (from.query && from.query.tab === 'cocomoni'
        && to.query && to.query.tab === 'home') {
        // ココモニからトップへ遷移した場合はグラフ更新
        this.getGraphData();
      }
    },
  },
  methods: {
    ignores() {
      let result = false;
      const ignoreSchools = [];
      if (ignoreSchools.includes(this.user.school[0].id)) result = true;
      return result;
    },
    changeTab() {
      // クエリでどのタブを表示させるか判定
      const query = this.$route.query;
      const tabNames = Object.keys(this.tabItems);
      if (query.tab && tabNames.includes(query.tab)) this.isActive = query.tab;
      else this.isActive = 'home';
      this.flag.loader = false;
    },
    receiveIsActiveValue(name) {
      this.isActive = name;
      this.$router.replace(`/?tab=${name}`);
    },
    clickSlider(name) {
      const scrollTabs = ['text', 'cocomoni'];
      if (scrollTabs.includes(name) && this.hasProgress) {
        this.$router.replace({
          path: '/',
          query: { tab: name },
        });
        // タブまでスクロール
        const elem = document.getElementById('tab');
        const position = elem.getBoundingClientRect().top;
        scrollBy(0, position - 77);
      } else this.$router.push(this.sliders[name].to);
    },
    clickSliderLink() {
      if (this.hasProgress) {
        // テキスト相談中のため新規テキスト登録画面には遷移させない
        this.receiveIsActiveValue('text');
        return;
      }
      this.$router.replace('/reserve/text/');
    },
    // clickOnline() { // 未使用
    //   if (this.hasFuture || this.hasVirtualFuture) {
    //     alert('予約中の健康相談があるため、新たな相談予約は行えません。');
    //     return;
    //   }
    //   this.$router.replace({
    //     path: '/reserve/select/',
    //   });
    // },

    /** 利用者タイプによってタブを追加 */
    setTabItems() {
      if (this.user.account_type !== 3) {
        delete this.tabItems.units;
      }
    },

    /** 事務局個別メッセージ取得 */
    getOperatorMessage() {
      this.axios({
        method: 'GET',
        url: '/v1/messagegroup/get/list',
        params: {
          target_user_id: this.user.id,
          target_type: 2,
          isOperator: 1,
        },
      })
        .then((response) => {
          this.operatorMessages = response.data.groups.data.length
            ? response.data.groups.data.filter((group) => group.messages.length) // 一番初めのメッセージは事務局から
              .map((group) => group.messages.find((message) => message.user_id !== this.user.id))
            : [];
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    /** お知らせ取得 */
    getInformations() {
      this.axios({
        method: 'GET',
        url: '/v1/information/get/list',
        params: {
          targetNumber: 2,
          limit: 2,
          onwards: this.user.created_at,
        },
      })
        .then((response) => {
          this.informations = response.data.data.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    /** 学校だより取得 */
    getSchoolInformations() {
      this.axios({
        method: 'GET',
        url: '/v1/schoolinformation/get/list',
        params: {
          school_id: this.user.school[0].id,
          limit: 2,
        },
      })
        .then((response) => {
          this.schoolInformations = response.data.schoolinformations.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    /** ログイン済みユーザはトップリダイレクト */
    redirect2top() {
      const ls = cf.getLocalStorage('wtt');
      // ログアウト時にログインへリダイレクトされた場合の対応
      if (!ls.jwt) return;

      const accountType = this.user.account_type;
      const customerTypes = [1, 2, 3];
      const counselorTypes = [21, 22];
      const teacherTypes = [11, 12];
      const operatorTypes = [31];
      let path = '';
      if (this.user.account_type) {
        if (customerTypes.includes(accountType)) {
          path = { path: '/' };
        } else if (counselorTypes.includes(accountType)) {
          path = { path: '/counselor/' };
        } else if (teacherTypes.includes(accountType)) {
          path = { path: '/teacher/' };
        } else if (operatorTypes.includes(accountType)) {
          path = { path: '/operator/' };
        }
      }
      this.$router.push(path);
    },

    /** グラフ描画データの取得 */
    async getGraphData() {
      /** スマホ版で見ると極端にグラフサイズが落ちるので拡大 */
      if (window.innerWidth <= 440) this.graphHeight = 400;

      this.axios({
        method: 'GET',
        url: '/v1/graph/get/charts',
        params: {
          user_id: this.user.id,
          start: null,
          end: null,
        },
      })
        .then((response) => {
          this.graphData = response.data.result;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    // 大学生用のclass付与
    sliderUnvClass(slide) {
      return (this.user.school[0].school_type === 4) && (slide.name === 'text' || slide.name === 'online' || slide.name === 'virtual') ? 'unv' : '';
    },
    /** LSにtab情報が保存されているか確認する */
    checkTabState() {
      const ls = cf.getLocalStorage('wtt');
      if (ls.saveState && ls.saveState.tab) {
        this.$router.replace({
          query: {
            tab: ls.saveState.tab,
          },
        });
        // localStorageのsaveStateを削除
        cf.deleteLocalStorage('wtt', 'saveState');
      }
    },
  },
};
</script>

<style lang="scss" module>
.date {
  font-size: 12px;
}
.link {
  color: var(--black);
  .operator_title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      text-decoration: underline;
    }
  }
  &:hover {
    .title {
      text-decoration: underline;
    }
  }
  .infoDate {
    width: 120px;
    @include sm-view {
      width: 100%;
    }
  }
}
.operatorMessageList {
  li:not(:last-child) {
    border-bottom: 1px solid var(--gray-main);
  }
  li {
    padding: 9px 0;
  }
}
.operator_wrap {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  .title_wrap {
    display: flex;
    align-items: center;
    .operator_label {
      width: fit-content;
      min-width: 100px;
    }
  }
}
.labels {
  display: flex;
  flex-wrap: wrap;
  @include sm-view {
    width: 100%;
  }
}
.label {
  width: 80px;
  @include sm-view {
    width: 100%;
  }
}
.slider {
  width: 100%;
  border-radius: 10px;
  background-color: var(--primary-color);
}
.sliderOuter {
  width: 100%;
  @include xs-view {
    width: 120%;
  }
}
.slider_setting {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: percentage(453/1064);
  @include sm-view {
    padding-top: percentage(1000 / 780);
  }
}
.bold {
  font-weight: bold;
}
.center {
  text-align: center;
}
.img_title {
  font-weight: bold;
}
.flex_center {
  display: flex;
  justify-content: center;
}
.flex_counseling_container {
  display: flex;
  justify-content: center; /* アイテムを水平方向に中央に配置 */
  align-items: center; /* アイテムを垂直方向に中央に配置（オプション） */
}
.first_box {
    margin-right: auto; /* これにより第二のボックスが中央に押し出されます */
}
.second_box {
    margin-right: auto; /* これも中央配置を助けます */
}
.qr {
  width: 100px;
}
.news {
  display: flex;
  .infoDate {
    width: 120px;
    @include sm-view {
      width: 100%;
    }
  }
  .emphasis {
    width: 80px;
    @include sm-view {
      width: 100%;
    }
  }
  @include sm-view {
    display: block;
  }
}
.position {
  font-size: 12px;
}
.content,
.schoolContent {
  font-size: 12px;
  overflow: hidden;
  &.content {
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    a {
      pointer-events: none;
    }
  }
}
.carousel__item {
  width: 100%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.count {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
  span {
    font-size: 14px;
    &:first-of-type {
      margin-right: .5em;
    }
    &:last-of-type {
      margin-left: .5em;
    }
  }

  &_text {
    font-size: 12px;
    font-weight: bold;
  }
}

.container {
  margin: 0 auto;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.status {
  a {
    color: var(--black);
    &:hover {
      text-decoration: underline;
    }
  }
}
.banners {
  a {
    display: block;
    transition: all .4s;
    &:hover {
      opacity: .7;
    }
  }
  img {
    margin: 0 auto;
  }
}
</style>

<style lang="scss">
.slide {
  $names: 'cocomoni', 'reserve', 'text', 'online', 'virtual';
  @each $name in $names {
    &.#{$name} {
      background-image: url("/img/slider/#{$name}.jpg");
      &.unv {
        background-image: url("/img/slider/#{$name}_unv.jpg");
      }
      @include sm-view {
        background-image: url("/img/slider/#{$name}_sp.jpg");
        &.unv {
          background-image: url("/img/slider/#{$name}_unv_sp.jpg");
        }
      }
    }
  }
}
.carousel__prev {
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%);
}
.carousel__next {
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
}
.carousel__prev, .carousel__next {
  color: gray;
  background-color: rgb(248, 248, 248);
  @include sm-view {
    display: none;
  }
}
.carousel__pagination-button {
  margin: var(--vc-pgn-margin);
  padding: 3px;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  background-color: silver;
}
.carousel__pagination-button {
  background-color: silver;
}
.carousel__pagination-button--active {
  background-color: #333333;
}
.btn-animation a {

/*デザイン*/
display:block;
padding:10px;
color:#000;
text-decoration:none;
width:25%;
border-radius:25px;
margin:0 auto;
text-align:center;

/*アニメーション*/
animation: animScale 4s infinite ease-out;
transform-origin: 50% 50%;
animation-play-state:running;
  @include sm-view {
    font-size: 13px;
    padding: 5px;
    width: 100%;
  }
}
@keyframes animScale {
0% { transform: scale(0.8, 0.8); }
5% { transform: scale(1.2, 1.2); }
10% { transform: scale(1, 1); }
15% { transform: scale(1.1, 1.1); }
20% { transform: scale(1, 1); }
100% { transform: scale(1, 1); }
}
.btn-animation a:hover {
  animation-play-state: paused;
}
.btn-animation p.animationBtn1 a{
  background:#FFEFD5;
}
.btn-animation p.animationBtn2 a{
  background:#D7EEFF;
}
</style>

<template>
  <div>
    <spacer :y="8.5" :sm-y="4" />
    <div :class="$style.topVisual">
      <img src="/img/dummy/reserve_top.png" alt="" />
    </div>
    <spacer :y="8.5" :sm-y="4" />
    <div :class="$style.read_text">
      <div>
        <p>こころの専門家との相談をいつでも・どこでも・手軽に・気軽に。</p>
        <p>Welcome to talkメンタルヘルスケアは、ICTを活用した国内初のオンラインサービス。マイページから自分に合った方法でメンタルヘルスケアをはじめることができます。</p>
        <p>自分のこころの状態をセルフチェックできる「ココモニ」から、24時間365日いつでも書いて相談できる「テキスト健康相談」、リアルタイムに専門家の顔を見ながら相談できる「オンライン健康相談」まで、各種サービスを用意。</p>
        <p>相談員はすべて経験豊富な精神科医・児童精神科医・臨床心理士・公認心理師。どんな些細なことでもご相談ください。</p>
      </div>
    </div>
    <spacer :y="8.5" />
    <div :class="$style.text_wrap">
      <p :class="$style.assistant">
        <span><i class="fa-solid fa-user-doctor-message"></i> Welcome to talkの健康相談</span>
        <spacer :y="1"/>
        <ul>
          <li>相談員はすべて現役の医師・心理士。経験豊富なこころの専門家が一人ひとりの相談に応じます。 </li>
          <li>診療や診断など医療行為は行いませんが、どんな些細なことでも気軽にご相談ください。 </li>
          <li>いのちに関わるご相談は、学校や企業、警察と連携する場合があります。あらかじめご了承ください。 </li>
        </ul>
      </p>
    </div>
    <spacer :y="8.5" />
    <div :class="$style.cocomoni_link">
      <a href="/?tab=cocomoni">
        <div :class="$style.cocomoi_image_sw"></div>
      </a>
    </div>
    <spacer :y="10" />
    <div :class="$style.section_wrap">
      <section :class="`${$style.section} ${$style.text}`">
        <div
          v-if="user.email"
          :class="$style.section_top"
          :style="`background-image: url(${ textImagePath });`"></div>
        <div :class="$style.section_text_wrap">
          <div>
            <div :class="$style.section_title_wrap">
              <i :class="$style.section_icon" class="fa-regular fa-comment-lines"/>
              <div :class="$style.section_title">テキストで相談する</div>
            </div>
            <spacer :y="3"/>
            <p :class="$style.commentary">
              いつでも<b :class="$style.emphasis">24時間受付</b><br>
              <b :class="$style.emphasis">マイページ</b>からテキスト入力<br>
              対応端末：スマホ・タブレット・パソコン
            </p>
            <spacer :y="2"/>
            <ul :class="$style.list">
              <li><b :class="$style.emphasis">営業日2日</b>以内返信</li>
              <li><b :class="$style.emphasis">予約不要／いつでも相談</b></li>
              <li><b :class="$style.emphasis">心理士回答</b>　※選択不可</li>
              <li>1相談<b :class="$style.emphasis">600文字</b>まで</li>
            </ul>
          </div>
          <div :class="$style.btn_wrap">
            <router-link
              to=''
              @click="clickTextLink"
              :class="$style.btn">{{ textLinkLabel }}</router-link>
          </div>
        </div>
      </section>
      <section :class="`${$style.section} ${$style.online}`">
        <div
          v-if="user.email"
          :class="$style.section_top"
          :style="`background-image: url(${ onlineImagePath });`"></div>
        <div :class="$style.section_text_wrap">
          <div>
            <div :class="$style.section_title_wrap">
              <i :class="$style.section_icon" class="fa-regular fa-laptop">
                <i :class="$style.section_icon_inner" class="fa-regular fa-user-headset"/>
              </i>
              <div :class="$style.section_title">オンラインで話す</div>
            </div>
            <spacer :y="3"/>
            <p :class="$style.commentary">
              <b :class="$style.emphasis">顔を見ながらじっくり相談</b><br>
              相談1回=<b :class="$style.emphasis">45分</b><br>
              対応端末：スマホ・タブレット・パソコン
            </p>
            <spacer :y="2"/>
            <ul :class="$style.list">
              <li><b :class="$style.emphasis">予約制（営業日3日以降～）</b><br><span>※毎月20日、翌月スケジュール公開</span></li>
              <li><b :class="$style.emphasis">夜時間を活用！</b><br>平日13:00-22:00</li>
              <li><b :class="$style.emphasis">医師・心理士選択</b></li>
              <li><b :class="$style.emphasis">１対１のプライベート空間</b></li>
            </ul>
          </div>
          <div :class="$style.btn_wrap">
            <router-link
              to=''
              @click="clickOnlineLink"
              :class="$style.btn">{{ onlineLinkLabel }}</router-link>
          </div>
        </div>
      </section>
    </div>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Spacer from '@/views/components/Spacer.vue';

export default {
  name: 'reserve',
  components: {
    Spacer,
  },
  computed: {
    ...mapState(['user']),
    hasProgress() {
      let flag = false;
      if (this.user.counseling
      && this.user.counseling.texts
      && this.user.counseling.texts.progress
      && this.user.counseling.texts.progress.length) {
        flag = true;
      }
      return flag;
    },
    hasFutureReservation() {
      let flag = false;
      if (this.user.counseling
      && this.user.counseling.reserves
      && this.user.counseling.reserves.future
      && this.user.counseling.reserves.future.length) {
        flag = true;
      }
      return flag;
    },
    textLinkLabel() {
      return this.hasProgress ? '確認する' : '入力する';
    },
    onlineLinkLabel() {
      return '予約する';
    },
    textImagePath() {
      return this.user.school[0].school_type === 4 ? '/img/dummy/reserve_text_university.png' : '/img/dummy/reserve_text.png';
    },
    onlineImagePath() {
      return this.user.school[0].school_type === 4 ? '/img/dummy/reserve_online_university.png' : '/img/dummy/reserve_online.png';
    },
  },
  methods: {
    clickTextLink() {
      const path = this.hasProgress ? '/?tab=text' : '/reserve/text/';
      this.$router.push(path);
    },
    clickOnlineLink() {
      const path = this.hasFutureReservation ? '/?tab=online' : '/reserve/select/';
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" module>
.topVisual {
  width: 100%;
  border-radius: 10px;
  background-color: var(--primary-color);
}
.cocomoni_link {
  width: 100%;
  margin: 0 auto;
  .cocomoi_image_sw {
    width: 100%;
    height: 0;
    padding-top: percentage(453 / 1064);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/img/slider/cocomoni.jpg");
    @include sm-view {
      padding-top: percentage(1000/ 780);
      background-image: url("/img/slider/cocomoni_sp.jpg");
    }
  }
}
.section {
  width: 49%;
  max-width: 480px;
  border-radius: 10px;
  display: flex;
  flex-flow: column;

  &_wrap {
    width: 100%;
    max-width: 1040px;
    display: flex;
    justify-content: space-between;
  }

  @include sm-view {
    width: 100%;
    margin: auto;

    &:first-child {
      margin-bottom: 30px;
    }
    &_wrap {
      display: block;
    }
  }

  &_top {
    width: 100%;
    padding-top: 60%;
    background-size: cover;
  }

  &_title {
    font-weight: 700;
    color: #fff;
    font-size: max(25px, 1vw);

    @include md-view {
      font-size: 22px;
    }

    @include sm-view {
      font-size: max(21px, 1vw);
    }

    &_wrap {
      display: flex;
      align-items: center;
    }
  }

  &_icon {
    font-size: 50px;
    padding-right: .3em;
    position: relative;

    @include md-view {
      font-size: 30px;
    }

    &_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-95%, -70%);
      font-size: 0.4em;
    }
  }

  &_text {
    &_wrap {
      flex: 1;
      color: #fff;
      padding: 30px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      margin-bottom: 0;

      @include md-view {
        padding: 20px;
        font-size: 14px;
      }
    }
  }

  &.online {
    background-color: var(--orange-main);
  }

  &.text {
    background-color: var(--blue-main);
  }
}
.commentary {
  line-height: 2;
  font-size: max(18px, 1vw);
  &_wrap {
    padding: 0 60px;

    @include md-view {
      padding: 0;
    }

    @include sm-view {
      font-size: 14px;
    }
  }
}
.emphasis {
  font-size: max(22px, 1vw);
  @include sm-view {
    font-size: max(20px, 1vw);
  }
}
.assistant {
  width: 80%;
  margin: 0 auto;
  background-color: var(--gray-sub);
  font-size: 1.2em;
  line-height: 1.8;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 5px 5px 10px rgba(black, .15);

  span {
    font-size: 18px;
    display: block;
    font-weight: bold;
    i {
      margin-right: .5em;
    }
  }

  ul {
    padding-left: 1em;
  }

  li {
    margin-bottom: .5em;
    list-style: disc;
    list-style-position: outside;
  }
  li:last-child {
    margin-bottom: 0;
  }

  @include sm-view {
    font-size: 14px;
    width: 100%;
    span {
      font-size: 16px;
    }
  }
}
.btn {
  display: inline-block;
  padding: 15px 60px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 30px;
  font-weight: bold;
  line-height: 1.5;

  &_wrap {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
.list {
  padding-left: 1em;
  line-height: 1.4em;
  font-size: 1.3em;

  li {
    list-style: disc;
    list-style-position: outside;
    margin-bottom: 15px;

    & + li {
      margin-top: 4px;
    }

    span {
      font-size: 0.85em;
    }
  }
}
</style>

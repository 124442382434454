<template>
  <div :class="$style.content_height">
    <p :class="$style.content_text_bold">一括で利用者のステータスを編集します</p>
    <div :class="$style.sortBox">
      <spacer :y="3" />
      <div :class="$style.top">
        <div :class="$style.search_wrap">
          <word-search-form
            :class="$style.search_word"
            v-on:sendValue="receiveValue"
            :placeholder="isCompany ? '利用者を検索' : '生徒を検索'" />
        </div>
      </div>
      <spacer :y="2"/>
      <div :class="$style.birth">
        <dl>
          <dt><i class="fa-solid fa-calendar-days"></i>生年月日</dt>
          <dd>
            <div>
              <date-small-picker
                :name="'startBirthday'"
                v-on:sendDate="receiveDate" />
            </div>
              <date-small-picker
                :name="'endBirthday'"
                v-on:sendDate="receiveDate" />
          </dd>
        </dl>
        <spacer :y="2"/>
        <dl>
          <dt><i class="fa-solid fa-calendar-plus"></i>登録年月日</dt>
          <dd>
            <div>
              <date-small-picker
                :name="'startCreatedAt'"
                v-model="startCreatedAt"
                v-on:sendDate="receiveDate, autoEnter()" />
            </div>
              <date-small-picker
                :name="'endCreatedAt'"
                v-model="endCreatedAt"
                v-on:sendDate="receiveDate" />
          </dd>
        </dl>
      </div>

      <div v-if="!isCompany" :class="$style.birth">
        <spacer :y="2" />
        <dl>
          <dt><i class="fa-solid fa-graduation-cap"></i>学年</dt>
          <dd>
            <div>
              <select
                :class="$style.select_box"
                v-model="schoolYear"
                name="schoolYear"
                id="schoolYear"
                @change="updateSchoolYear">
                <option :value="null">選択なし</option>
                <option
                  v-for="n of data.maxSchoolyear"
                  :key="n"
                  :value="n">{{ n }}</option>
                <option :value="0">登録なし</option>
              </select>
            </div>
          </dd>
        </dl>
      </div>
    </div>
    <spacer :y="4" />
    <loader-simple :flag="flag.loader">
      <div v-if="!flag.loader">
        <div v-if="users.length" :class="$style.batch_check">
          <input
            type="checkbox"
            id="batch_check"
            v-model="flag.batchFlag"
            @change="batchCheck(!flag.batchCheck)">
          <label for="batch_check">一括チェック</label>
        </div>
        <spacer :y="3" />
        <div :class="$style.users_box">
          <stack-item v-for="row in users" :key="row.id">
            <div
              v-if="row.account_type === 1
                || row.account_type === 2
                || row.account_type === 3"
              :class="$style.user_check">
              <input
                :class="$style.user_check_box"
                type="checkbox"
                :id="row.id"
                v-model="checkFlags[row.id]"
                @change="countCheckFlags()" />
              <label
                :class="$style.user_check_user_box"
                :for="row.id">
                <user-box
                  type="inner"
                  :showId="true"
                  :showCocomoniBtn="true"
                  :ownUser="user"
                  v-if="users.length"
                  :user="row"
                  saveTab="students"
                  :bg="userBoxColor(row)">
                </user-box>
              </label>
            </div>
          </stack-item>
        </div>
        <spacer :y="2" />
        <div v-if="users.length" :class="$style.batch_chenge_school_year">
          <p>
            <span>
              {{ `以上${countCheckFlags()}名${targetSchoolYear !== 998 ? 'の学年' : ''}を` }}
            </span>
            <span>
              <select
                :class="$style.select_box"
                v-model="targetSchoolYear"
                name="targetSchoolYear"
                id="targetSchoolYear">
                <option :value="null">選択</option>
                <option
                  v-for="n of 6"
                  :key="n"
                  :value="n">{{ n }}</option>
                <option
                  :value="998">非アクティブ</option>
              </select>
            </span>
            <span v-if="targetSchoolYear">{{ `${targetSchoolYear !== 998 ? '年生' : ''}` }}</span>
            <span>へ変更</span>
          </p>
          <div :class="$style.batch_chenge_school_year_buttons">
            <btn-container>
              <basic-btn :disabled="!targetSchoolYear" tag="button" v-on:click="save">確定</basic-btn>
              <spacer :x="2" />
              <basic-btn tag="button" type="bdr" v-on:click="hideModal">キャンセル</basic-btn>
            </btn-container>
          </div>
        </div>
        <p v-else-if="targetSchoolYear
          || startBirthday
          || endBirthday
          || schoolYear
          || startCreatedAt
          || endCreatedAt">お探しの検索条件に該当するアカウントは見つかりませんでした。</p>
        <spacer :y="4" />
      </div>
    </loader-simple>
  </div>
</template>

<script>
import UserBox from '@/views/components/UserBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import StackItem from '@/views/components/StackItem.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import WordSearchForm from '@/views/components/WordSearchForm.vue';
import DateSmallPicker from '@/views/components/DateSmallPicker.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import moment from 'moment';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'bulk-edit-school-year',
  mixins: [cf],
  components: {
    UserBox,
    Spacer,
    StackItem,
    BasicBtn,
    BtnContainer,
    WordSearchForm,
    DateSmallPicker,
    LoaderSimple,
  },
  props: ['data'],
  data() {
    return {
      flag: {
        loader: false,
        isSort: false,
        forcePage1: false,
        batchCheck: false,
        batchFlag: false,
      },
      checkFlags: {},
      targetSchoolYear: null,
      openIds: [],
      onlineOpenIds: [],
      users: [],
      keyword: null,
      targetColumn: [],
      schoolYear: null,
      startBirthday: null, // 文字列で渡す
      endBirthday: null, // 文字列で渡す
      startCreatedAt: null,
      endCreatedAt: null,
      totalUser: 0,
      page: 1,
      pageMax: 1,
      limit: 999999,
      activeTypeItems: [ // 順番固定 computed?
        {
          name: 'nonActiveUser',
          label: '非アクティブユーザー含む',
          value: 1,
          checked: false,
        },
      ],
      items: [
        {
          label: '登録年月日',
          name: 'created_at',
          icon: 'fa-solid fa-right-to-bracket',
          constant: true,
        },
        {
          label: 'メール',
          name: 'email',
          icon: 'fa-regular fa-envelope',
          constant: true,
        },
        {
          label: '誕生日',
          name: 'birthday',
          icon: 'fa-regular fa-cake-candles',
          constant: false,
        },
        {
          label: '携帯番号',
          name: 'tel',
          icon: 'fa-solid fa-phone',
          constant: false,
        },
        {
          label: '学籍番号',
          companyLabel: '特記事項',
          name: 'student_id',
          icon: 'fa-regular fa-id-badge',
          constant: false,
        },
        {
          label: 'ひとこと',
          name: 'comment',
          icon: 'fa-regular fa-comment-dots',
          constant: false,
        },
      ],
      accountTypeItems: [
        {
          name: 'user',
          label: '在学生',
          value: 1,
          checked: false,
        },
        {
          name: 'parent',
          label: '保護者',
          value: 2,
          checked: false,
        },
        {
          name: 'teacher',
          label: '教職員',
          value: 3,
          checked: false,
        },
      ],
      companyAccountTypeItems: [
        {
          name: 'user',
          label: 'プログラム参加者',
          value: 1,
          checked: false,
        },
        {
          name: 'teacher',
          label: '企業担当者',
          value: 3,
          checked: false,
        },
      ],
    };
  },
  created() {
    // this.getUsers();
  },
  methods: {
    receiveValue(data) {
      const userIdRegex = new RegExp(/^[0-9０-９]{4}(-|ー|−|―|‐|-|ｰ)[0-9０-９]{1,}(-|ー|−|―|‐|-|ｰ)[0-9０-９]{4}$/);
      if (userIdRegex.test(data.value)) {
        const userId = data.value.split(/[-|ー|−|―|‐|-|ｰ]/);
        this.keyword = String(Number(this.toHalfNumber(userId[2]))); // user.id指定
        this.targetColumn = ['id', 'old_id', 'tel']; // 0120-12-3456のtelも引っかかる
      } else {
        this.keyword = data.value;
        this.targetColumn = ['username', 'kana', 'email', 'tel', 'id', 'old_id'];
      }
      this.getUsers();
    },

    /** 誕生日と登録日の始点と終点取得 */
    receiveDate(args) {
      if (args.date === 'Invalid date') this[args.name] = null;
      else this[args.name] = args.date;
      this.checkFlags = {};
      this.getUsers();
    },

    /** 登録日の開始点のみ入力されたら終点を自動入力 */
    autoEnter() {
      if (this.startCreatedAt && !this.endCreatedAt) {
        this.endCreatedAt = moment(new Date()).format('YYYY-MM-DD');
      }
    },

    // 学年で絞り込み
    updateSchoolYear(e) {
      this.schoolYear = Number(e.target.value);
      // 登録なしが加わったので条件変更
      if (e.target.value === '選択なし') this.schoolYear = null;
      this.checkFlags = {};
      this.getUsers();
    },

    getUsers() {
      this.flag.loader = true;
      // 一覧のユーザーを取得してくる
      let targetSchoolId;
      if (this.data.account_type === 31) {
        targetSchoolId = this.selectedId;
      } else if (this.data.account_type === 11) {
        targetSchoolId = this.data.school[0].id;
      }

      const params = {
        school_id: targetSchoolId, // 学校の絞り込みは必須
        school_year: this.schoolYear,
        isSchool: 0,
        targetColumn: this.keyword && this.keyword !== '' ? this.targetColumn : null,
        keyword: this.keyword && this.keyword !== '' ? this.keyword : null,
        accountTypes: this.isCompany ? this.targetCompanyAccountType : this.targetAccountType,
        betweenBirthday: [this.startBirthday, this.endBirthday],
        betweenCreatedAt: [this.startCreatedAt, this.endCreatedAt],
        page: this.page,
        limit: this.limit,
        includeDeleted: this.activeTypeItems[0].checked ? 1 : 0,
        target: 'account_type',
        symbols: '=',
        find: 1,
      };

      this.axios({
        method: 'GET',
        url: '/v1/user/get/searchGrant',
        params,
      })
        .then((response) => {
          const res = response.data;
          const users = res.users.data;
          this.totalUser = res.users.total || 0;
          if (users && users.length && this.keyword) {
            const searchTargets = [...this.targetColumn, 'customer_id'];
            users.forEach((user) => {
              searchTargets.forEach((column) => {
                if (user[column] && String(user[column]).includes(this.keyword)) {
                  if (column === 'id') user.rawId = Number(user[column]);
                  user[column] = String(user[column]).replace(new RegExp(this.keyword, 'g'), `<span class="highlight">${this.keyword}</span>`);
                }
              });
            });
          }

          // 学校管理者が一括編集する際は自分の学校データを利用者学校情報へセット
          if ((this.data.account_type === 11
            || this.data.account_type === 12)
            && this.data.school) {
            users.forEach((user) => {
              user.school = [this.data.school[0]];
            });
          }

          this.users = users;
          this.pageMax = res.users.lastPage;
          this.flag.loader = false;
          // this.checkSortFlagStatus();
          if (this.page > this.pageMax) {
            this.$router.push({ query: { tab: 'students', page: 1 } });
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    // ソートされている状態か確認
    checkSortFlagStatus() {
      if (this.keyword
        || this.startBirthday
        || this.endBirthday
        || this.startCreatedAt
        || this.endCreatedAt
        || this.targetAccountType.length !== 3) {
        this.flag.isSort = true;
      } else {
        this.flag.isSort = false;
      }
    },

    /** userBox背景色指定用 */
    userBoxColor(user) {
      let color;
      if (user.flag !== 1) {
        color = 'gray';
      } else if (user.temporary) {
        color = 'silver';
      } else {
        color = 'white';
      }
      return color;
    },

    // 一括チェック、現在のチェックされている数にかかわらず一括でチェックをつける、外す
    batchCheck(checkValue) {
      this.users.forEach((elem) => {
        if (elem.account_type === 1) {
          this.checkFlags[elem.id] = checkValue;
        }
      });
      this.flag.batchFlag = !this.flag.batchFlag;
      this.flag.batchCheck = !this.flag.batchCheck;
    },

    // 一括で変更する人数を集計
    countCheckFlags() {
      const trueList = Object.values(this.checkFlags).filter((value) => value);
      // 一括チェック後に一つでもチェックが外れたら再度一括チェックの押し下し可能
      if (trueList.length !== this.users.length) {
        this.flag.batchCheck = false;
        this.flag.batchFlag = false;
      }
      const list = Object.keys(this.checkFlags).filter((elem) => this.checkFlags[elem]);
      return list.length;
    },

    /**
     * 学年の登録以外にも非アクティブも一括で可能になっている
     * 非アクティブの際も998はtargetSchoolYearに入ってくるので値で判定
     */
    async save() {
      // 学年を変更するリストを作成
      const strList = Object.keys(this.checkFlags).filter((elem) => this.checkFlags[elem]);
      const target = strList.map((elem) => Number(elem));

      // 選択確認
      if (target.length <= 0) {
        alert('学年を変更する生徒を選択してください');
        return true;
      }
      if (!this.targetSchoolYear) {
        alert('変更後の学年を選択してください');
        return true;
      }

      let confirmation;
      if (this.targetSchoolYear !== 998) confirmation = confirm(`${this.countCheckFlags()}人の生徒の学年を${this.targetSchoolYear ? `${this.targetSchoolYear}年生` : '未登録'}に変更します`);
      else confirmation = confirm(`${this.countCheckFlags()}人の生徒を非アクティブにします`);

      if (!confirmation) return true; // キャンセルは即時中断

      try {
        const promises = target.map((data) => {
          let params;
          if (this.targetSchoolYear === 998) {
            params = {
              id: data,
              flag: 998,
            };
          } else {
            params = {
              id: data,
              school_year: this.targetSchoolYear,
            };
          }
          return this.axios({
            method: 'POST',
            url: '/v1/user/set/update',
            params,
          })
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              if (error.message) console.log(error.message);
              else console.log(error);
              alert('学年の更新に失敗しました');
            });
        });
        // 全ての非同期操作が完了してからモーダルを閉じる
        await Promise.all(promises);
        alert(`${this.countCheckFlags()}人の情報を更新しました`);
        this.hideModal();
      } catch {
        alert('学年の更新に失敗しました');
        this.hideModal();
      }
    },

    hideModal() {
      this.$store.dispatch('modal/contents/hideModal', null, { root: true });
    },
  },
  unmounted() {
    this.getUsers();
  },
};
</script>

<style lang="scss" module>
.content {
  &_height {
    height: 600px;
  }
  &_text {
    &_bold {
      font-weight: bold;
    }
  }
}
.user_check {
  display: flex;
  &_box {
    margin-right: 1em;
  }
  &_user_box {
    flex-grow: 1;
  }
}
.search {
  &_wrap {
    display: flex;
    width: 100%;
  }
  &_word {
    flex: 1;
    margin-right: 20px;
  }
  &_items {
    display: flex;
    align-items: center;
    select {
      border: none;
      outline: none;
      padding: 13px;
      border-radius: 8px;
      background-color: var(--gray-sub);
      margin-right: 10px;
    }
    .checklabel {
      // font-size: 14px;
      margin-left: 5px;
    }
  }
}
.select_box {
  text-align: center;
}
.icon {
  color: var(--orange-main);
  margin-right: 8px;
  font-size: 20px;
  display: inline-block;
  width: 20px;
}
.items {
  // font-size: 14px;
  margin-top: -16px;
  .item {
    display: flex;
    align-items: flex-start;
    margin-top: 16px;
    line-height: 20px;
  }
}
.open {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray-side);
  font-size: 14px;
  cursor: pointer;

  &:hover {
    opacity: .7;
  }
}
.history {
  font-size: 14px;
  // background-color: var(--orange-main);
  // color: #fff;
  font-weight: bold;
  color: var(--orange-main);
  border: 1px solid var(--orange-main);
  padding: 8px 16px;
  border-radius: 24px;
}
.pagenation {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray-side);
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    margin: 0 10px;
    border-radius: 50%;
    border: 1px solid var(--orange-main);
    color: var(--orange-main);
    cursor: pointer;
    &.selected {
      background-color: var(--orange-main);
      color: #fff;
    }
    span {
      cursor: pointer;
    }
  }
  i {
    margin: 0 15px;
    font-size: 30px;
    color: var(--orange-main);
    cursor: pointer;
    &.invalid {
      color: var(--gray-main);
      cursor: initial;
    }
  }
}
.sortBox {
  > * {
    align-items: center;
  }
  @include sm-view {
    > * {
      display: block;
      .search_wrap {
        width: 100%;
        .search_word {
          margin: 0;
        }
      }
    }
  }
}
.birth {
  flex: 1;
  dl {
    display: flex;
    align-items: center;
    margin: 0;
  }
  dt {
    width: 7em;
    i {
      margin-right: .5em;
    }
  }
  dd {
    flex: 1;
    display: flex;
    margin-left: 1em;
    > div {
      display: flex;
      align-items: center;
      &:nth-child(2) {
        margin-left: 1em;
        &:before {
          content: "~";
          margin-right: 1em;
        }
      }
    }
  }
  @include sm-view {
    dl {
      display: block;
      margin: 1em 0 .4em;
    }
    dt {
      font-size: 14px;
    }
    dd {
      margin: 0;
    }
  }
}
.select {
  display: flex;
  > li {
    &:not(:first-child) {
      margin-left: 1em;
    }
  }
  &_box {
    padding: 13px;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
    border-radius: 8px;
    appearance: none;

    &_wrap {
      display: flex;

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
.batch_chenge_school_year {
  display: flex;
  &_buttons {
    margin-left: auto;
  }
}
.edit_school_year {
  padding-left: 1em;
}
.bulk_edit {
  margin-left: auto;
}
.label {
  display: flex;
  font-weight: bold;
  font-size: 20px;
  align-items: center;

  @include sm-view {
    font-size: 16px;
  }
  .large {
    margin-left: 1em;
    font-size: 24px;
    @include sm-view {
      font-size: 18px;
    }
  }
}
</style>

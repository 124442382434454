<template>
  <div>
    <div :class="overlayClassName" @click="toggleIsShownMenu"/>
    <div :class="menuClassName">
      <div :class="$style.header">
        <div :class="$style.user">
          <round-img width="35px" :src="getMyIconUrl(user)" />
          <spacer :x="1.5" />
          <div :class="$style.user_detail">
            <p
              :class="$style.navItem__label"
              v-if="(user.customer_id && user.account_type <= 10)">{{ user.customer_id }}</p>
            <p
              :class="$style.navItem__label"
              v-else-if="[11, 12].includes(user.account_type)">{{ user.school[0].name }}</p>
            <p :class="$style.navItem__label" v-if="user.username">{{ user.username }}</p>
          </div>
        </div>
        <button
          :class="$style.btnClose"
          @click="toggleIsShownMenu">
          <i class="fa-solid fa-xmark" />
        </button>
      </div>

      <div :class="$style.main">
        <ul :class="$style.navList" v-if="menuType">
          <stack-item
            :space="2"
            v-for="nav in nav[menuType]"
            :key="nav.label"
            :class="$style.navListItem">
            <div v-if="!nav.children">
              <router-link
                v-if="nav.to"
                :to="nav.to"
                @click="toggleIsShownMenu(); deleteTabState()"
                :class="$style.listContent">
                  <i class="fa-solid fa-caret-right" style="margin-right: 5px;" />
                  {{ nav.label === '学校だより' && helper.master && helper.master.labels && user.school ? helper.master.labels.organizationNews[user.school[0].is_company] : nav.label }}
              </router-link>
              <a
                :href="nav.link"
                target="_blank"
                @click="toggleIsShownMenu(); deleteTabState()"
                v-if="nav.link"
                :class="$style.listContent">
                  <i class="fa-solid fa-caret-right" style="margin-right: 5px;" />
                  {{ nav.label === '学校だより' && helper.master && helper.master.labels && user.school ? helper.master.labels.organizationNews[user.school[0].is_company] : nav.label }}
              </a>
            </div>

            <div v-else>
              <p><b>{{ nav.label }}</b></p>
              <spacer :y="1" />
              <ul v-if="nav.children.length">
                <stack-item
                  :space="1"
                  v-for="child in nav.children"
                  :key="child"
                  :class="$style.navListItem">
                  <router-link
                    :to="child.to"
                    @click="toggleIsShownMenu(); deleteTabState()"
                    v-if="child.to"
                    :class="[
                      $style.listContent,
                      (user.account_type === 31 && helper.isAccountingAccount && !child.showAccountingAccount) ? $style.disabled : ''
                    ]">
                      <i class="fa-solid fa-caret-right" style="margin-right: 5px;" />
                      {{ child.label }}
                  </router-link>
                  <a
                    :href="child.link"
                    target="_blank"
                    @click="toggleIsShownMenu(); deleteTabState()"
                    v-if="child.link"
                    :class="$style.listContent">
                      <i class="fa-solid fa-caret-right" style="margin-right: 5px;" />
                      {{ child.label }}
                  </a>
                </stack-item>
              </ul>
            </div>
          </stack-item>
        </ul>

        <spacer :y="2" />
        <hr />

        <spacer :y="2" />
        <div :class="$style.setting">
          <div :class="$style.icon"><i class="fa-solid fa-gear"></i></div>
          <spacer :x="1.5" />
          <p :class="$style.navItem__label"><router-link
            :to="user.account_type > 30 ? '/account/'
              : user.account_type > 20 ? '/counselor/account/'
              : user.account_type > 10 ? '/teacher/school/'
              : '/settings/'"
            @click="toggleIsShownMenu(); deleteTabState()">設定</router-link></p>
        </div>

        <spacer :y="2" />
        <div
          v-if="!user.lti"
          :class="$style.setting">
          <div :class="$style.icon"><i class="fa-solid fa-right-from-bracket"></i></div>
          <spacer :x="1.5" />
          <p :class="$style.navItem__label">
            <router-link
              to="/"
              @click="logout($event); deleteTabState()">ログアウト</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import RoundImg from '@/views/components/RoundImg';
import Spacer from '@/views/components/Spacer';
import StackItem from '@/views/components/StackItem';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'DrawerMenu',
  mixins: [cf],
  components: {
    RoundImg,
    Spacer,
    StackItem,
  },
  data() {
    return {
      space: 2,
      nav: {
        customer: [
          {
            label: 'サービスをみる',
            to: '/reserve/',
          },
          {
            label: '履歴',
            to: '/?tab=history',
          },
          {
            label: '学校だより',
            to: '/schoolInformation',
          },
          {
            label: '事務局からのお知らせ',
            to: '/information/',
          },
        ],
        counselor: [
          {
            label: '事務局からのお知らせ',
            to: '/counselor/information/',
          },
          {
            label: 'シフト登録',
            to: '/counselor/?tab=schedule',
          },
        ],
        teacher: [
          {
            label: '履歴',
            to: '/teacher/?tab=history',
          },
          {
            label: '学校だより',
            to: '/teacher/schoolInformation',
          },
          {
            label: '事務局からのお知らせ',
            to: '/teacher/information/',
          },
        ],
        operator: [
          {
            label: 'サービス管理',
            children: [
              {
                label: 'オンライン健康相談',
                to: '/operator/?tab=online',
              },
              {
                label: 'テキスト健康相談',
                to: '/operator/?tab=text',
              },
              {
                label: '履歴',
                to: '/operator/?tab=history',
                showAccountingAccount: true,
              },
            ],
          },
          {
            label: 'マスタ管理',
            children: [
              {
                label: '利用者',
                to: '/operator/?tab=users',
              },
              {
                label: '契約者（学校・企業）',
                to: '/operator/?tab=schools',
              },
              {
                label: '医師心理士',
                to: '/operator/?tab=counselors',
              },
              {
                label: 'シフト登録',
                to: '/operator/?tab=counseling',
              },
              {
                label: 'お知らせ',
                to: '/operator/?tab=information',
              },
              {
                label: 'メッセージ',
                to: '/operator/?tab=message',
              },
              {
                label: '祝日',
                to: '/operator/?tab=holidays',
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(['layout', 'user', 'helper']),
    overlayClassName() {
      return `${this.$style.overlay} ${this.layout.isShownMenu ? this.$style.isActive : ''}`;
    },
    menuClassName() {
      return `${this.$style.menu} ${this.layout.isShownMenu ? this.$style.isActive : ''}`;
    },
    style() {
      return {
        '--space': `calc(var(--spacer-base) * ${this.space})`,
      };
    },
    menuType() {
      let type = 'customer';
      const customerTypes = [1, 2, 3];
      const counselorTypes = [21, 22];
      const teacherTypes = [11, 12];
      const operatorTypes = [31];
      const accountType = this.user.account_type;
      if (this.user.account_type) {
        if (customerTypes.includes(accountType)) {
          type = 'customer';
        } else if (counselorTypes.includes(accountType)) {
          type = 'counselor';
        } else if (teacherTypes.includes(accountType)) {
          type = 'teacher';
        } else if (operatorTypes.includes(accountType)) {
          type = 'operator';
        }
      }
      return type;
    },
  },
  methods: {
    ...mapActions('layout', [
      'toggleIsShownMenu',
    ]),
    logout(e) {
      e.preventDefault();
      this.$store.dispatch('layout/toggleIsShownMenu');
      this.$store.dispatch('user/login/logout');
    },
    /** LSにtab情報があれば削除 */
    deleteTabState() {
      cf.deleteTabState();
    },
  },
};
</script>

<style module lang="scss">
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  transition: opacity 400ms ease-in-out;

  &.isActive {
    pointer-events: auto;
    opacity: 1;
  }
}

.menu {
  position: fixed;
  top: 0;
  left: 100%;
  height: 100%;
  width: 320px;
  background-color: #fff;
  z-index: 2;
  transition: transform 800ms cubic-bezier(0.83, 0.02, 0.21, 0.99);
  transform: translate3d(0, 0, 0);

  &.isActive {
    transform: translate3d(-100%, 0, 0);
  }

  @include sm-view {
    background-color: #f5f5f5;
    margin-top: 55px;
  }
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: var(--header-height);
  padding: 0 20px;
  background-color: #fff;

  @include sm-view {
    height: 80px;
    padding: 0 16px;
    justify-content: space-between;
  }
}

.user_detail {
  @include sm-view {
    font-size: 14px;
    display: block;
  }
}

.user {
  display: none;

  @include sm-view {
    display: flex;
    align-items: center;
  }
}

.btnClose {
  color: #aaa;
  font-size: 30px;
  background-color: transparent;
  border: none;
  appearance: none;
}

.main {
  padding: 20px;
  a {
    color: var(--black);
    &:hover {
      text-decoration: underline;
    }
  }

  @include sm-view {
    padding: 16px;
    justify-content: space-between;
  }
}

.navListItem,
// .navItem__label {
//   font-size: 14px;
// }

.listContent {
  display: block;
  &.disabled {
    opacity: .5;
    cursor: default;
    pointer-events: none;
  }
}

.setting {
  // font-size: 14px;
  display: flex;
  align-items: center;
}

.icon {
  $size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $size;
  height: $size;
  border-radius: 50%;
  background-color: #959595;
  color: #fff;
  font-size: 12px;
}

.stackItem {
  & + & {
    margin-top: var(--space);
  }
}

</style>

<template>
  <div :class="className" v-html="label"></div>
</template>

<script>
export default {
  name: 'label-box',
  props: {
    label: {
      required: true,
    },
    color: {
      type: String,
      default: 'orange',
      validator: (value) => ['orange', 'blue', 'green', 'gray', 'pink'].includes(value),
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => ['md', 'sm', 'xs'].includes(value),
    },
    smAlign: {
      type: String,
      default: 'center',
      validator: (value) => ['left', 'center', 'right'].includes(value),
    },
  },
  computed: {
    className() {
      return `${this.$style.box} ${this.$style[this.color]} ${this.$style[this.size]} ${this.$style[this.smAlign]}`;
    },
  },
};
</script>

<style lang="scss" module>
.box {
  font-weight: bold;
  width: 100%;
  text-align: center;
  white-space: nowrap;

  @include sm-view {
    font-size: 0.85em;
  }

  &.md {
    padding: 8px 16px ;
    border-radius: 8px;

    @include sm-view {
      padding: 6px 14px;
      border-radius: 6px;
    }
  }

  &.sm {
    font-size: 14px;
    padding: 4px;
    border-radius: 4px;

    @include sm-view {
      padding: 3px;
      border-radius: 3px;
    }
  }

  &.xs {
    font-size: 12px;
    padding: 2px;
    border-radius: 2px;
  }

  &.orange {
    color: #fff;
    background-color: var(--orange-main);
  }

  &.border-orange {
    color: var(--orange-main);
    border: 1px solid var(--orange-main);
  }

  &.blue {
    color: #fff;
    background-color: var(--blue-main);
  }

  &.green {
    color: #fff;
    background-color: var(--green-main);
  }

  &.gray {
    color: var(--black);
    background-color: var(--gray-sub);
  }

  &.pink {
    width: 80px;
    color: #fff;
    background-color: var(--pink);
  }
  &.left {
    @include sm-view {
      text-align: left;
      padding-left: 8px;
    }
  }
  &.center {
    @include sm-view {
      text-align: center;
    }
  }
  &.right {
    @include sm-view {
      text-align: right;
      padding-right: 8px;
    }
  }
}
</style>
<style lang="scss" scoped>
:deep(.highlight) {
  background: linear-gradient(transparent 0%, #FFD700 0%);
}
</style>

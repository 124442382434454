<template>
  <div :class="$style.dataGrid">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DataGrid',
  props: {
  },
};
</script>

<style lang="scss" module>
.dataGrid {
  display: table;
  width: 100%;
  border-collapse: collapse;

  @include sm-view {
    display: block;
  }
}

</style>

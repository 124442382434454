<template>
  <div :class="$style.wrap">
    <ul :class="$style.contents">
      <li v-for="breadcrumb in page.breadcrumbs.list" :key="breadcrumb">
        <router-link :to="breadcrumb.link">{{ breadcrumb.label }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'breadcrumbs',
  computed: {
    ...mapState(['page']),
  },
  created() {
  },
};
</script>

<style lang="scss" module>
.wrap {
  position: fixed;
  top: var(--header-height);
  width: 100%;
  background-color: var(--gray-sub);
  padding: 0 20px;
  height: 50px;
  z-index: 2;
}
.contents {
  display: flex;
  align-items: center;
  height: 100%;
  li {
    font-size: 12px;
    a {
      color: var(--black);
    }
    &:not(:last-child) {
      &::after {
        content: '>';
        margin: 0 13px;
      }
    }
    &:last-child {
      a {
        pointer-events: none;
      }
    }
  }
}
</style>

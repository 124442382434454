<template>
  <div id="scroll-top">
    <spacer :y="5"/>
    <ul>
      <stack-item v-if="this.page === 1">
        <contents-box title="受付中（未回答）">
          <loader-simple :flag="flag.loaders.progress">
            <ul v-if="user.counseling.counselor.text.progress.length">
              <stack-item
                v-for="(row, i) of user.counseling.counselor.text.progress"
                :key="row.id"
                v-show="row.messages.length === 1">
                <user-box
                  :showCocomoniBtn="true"
                  :ownUser="user"
                  :bg="userBoxColor(row.customer)"
                  :user="row.customer"
                  :showId="true"
                  :link="`/counselor/history/user/${row.customer.id}`"
                  :targetBlank="true">
                  <template v-slot:main>
                    <spacer :y="4"/>
                    <counseling-base
                      :data="row"
                      :showProgressReserve="true" />

                    <spacer :y="3"/>
                    <hr>
                    <spacer :y="3" />
                    <div>
                      <textarea
                        name="answer"
                        id="answer"
                        rows="8"
                        v-model="counselorMessages[row.id]"
                        :class="$style.textarea"></textarea>

                      <div :class="$style.count">
                        <p v-if="row.answer && row.answer.length">{{ row.answer.length }}/<span>600</span></p>
                      </div>
                    </div>

                    <spacer :y="3"/>
                    <div>
                      <btn-container>
                        <basic-btn
                          tag="button"
                          :class="$style.btn"
                          v-on:click="submit($event, i, 'progress', 1)">回答</basic-btn>
                        <spacer :x="2"/>
                        <basic-btn
                          tag="button"
                          :class="$style.btn"
                          v-on:click="submit($event, i, 'progress', 2)">下書き保存</basic-btn>
                        <spacer :x="2"/>
                      </btn-container>

                      <risk-application-btn
                        :counseling_type="CONSTANTS.COUNSELING_TYPE_TEXT"
                        :foreign_id="row.id"
                        :target_user_id="row.user_id"
                      />

                    </div>
                  </template>
                </user-box>
              </stack-item>
            </ul>

            <p v-else-if="!flag.loaders.progress">ただいま未回答中のテキスト健康相談はありません。</p>
          </loader-simple>
        </contents-box>
      </stack-item>


      <stack-item v-if="this.page === 1">
        <contents-box title="下書き保存">
          <loader-simple :flag="flag.loaders.draft">
            <ul v-if="user.counseling.counselor.text.draft.length">
              <stack-item
                v-for="(row, i) of user.counseling.counselor.text.draft"
                :key="row.id">
                <user-box
                  :showCocomoniBtn="true"
                  :ownUser="user"
                  :user="row.customer"
                  :bg="userBoxColor(row.customer)"
                  :showId="true"
                  :link="`/counselor/history/user/${row.customer.id}`"
                  :targetBlank="true">
                  <template v-slot:main>
                    <spacer :y="4"/>
                    <counseling-base
                      :data="row"
                      :showProgressReserve="true" />

                    <spacer :y="3"/>
                    <hr>
                    <spacer :y="3" />
                    <div>
                      <p>回答者：{{ row.messages[1].user.username }}</p>
                      <spacer :y="1" />
                      <textarea
                        name="answer"
                        id="answer"
                        rows="8"
                        v-model="counselorMessages[row.id]"
                        :class="$style.textarea"
                        :disabled="row.messages[1].user.id !== user.id"></textarea>

                      <div :class="$style.count">
                        <p v-if="row.answer && row.answer.length">{{ row.answer.length }}/<span>600</span></p>
                      </div>
                    </div>

                    <spacer :y="3"/>
                    <div>
                      <btn-container>
                        <basic-btn
                          tag="button"
                          :class="$style.btn"
                          v-on:click="submit($event, i, 'draft', 1)"
                          :disabled="row.messages[1].user.id !== user.id">回答</basic-btn>
                        <spacer :x="2"/>
                        <basic-btn
                          tag="button"
                          :class="$style.btn"
                          v-on:click="submit($event, i, 'draft', 2)"
                          :disabled="row.messages[1].user.id !== user.id">下書き保存</basic-btn>
                        <spacer :x="2"/>
                      </btn-container>

                      <risk-application-btn
                        :counseling_type="CONSTANTS.COUNSELING_TYPE_TEXT"
                        :foreign_id="row.id"
                        :target_user_id="row.user_id"
                      />

                    </div>
                  </template>
                </user-box>
              </stack-item>
            </ul>

            <p v-else-if="!flag.loaders.draft">ただいま下書き保存中のテキスト健康相談はありません。</p>
          </loader-simple>
        </contents-box>
      </stack-item>


      <stack-item>
        <contents-box title="実施済み">
          <pagination
            v-if="!flag.loaders.answered && pageMax && pageMax !== 1"
            :page="page"
            :pageMax="pageMax"
            :path="'/counselor/?tab=text'" />
          <spacer :y="4"/>

          <loader-simple :flag="flag.loaders.answered">
            <ul
              v-if="!flag.loaders.answered
                && user.counseling.counselor.text.answered[page]
                && user.counseling.counselor.text.answered[page].length">
              <stack-item
                v-for="row of user.counseling.counselor.text.answered[page]"
                :key="row.id">
                <user-box
                  :showCocomoniBtn="true"
                  :ownUser="user"
                  :user="row.customer"
                  :bg="userBoxColor(row.customer)"
                  :showId="true"
                  :link="`/counselor/history/user/${row.customer.id}`"
                  :targetBlank="true">
                  <template v-slot:main>
                    <spacer :y="4"/>
                    <counseling-base
                      :data="row"
                      :showProgressReserve="true" />

                    <spacer :y="4" />
                    <user-box
                      type="inner"
                      :showId="false"
                      v-if="row.messages[1] && row.messages[1].user"
                      :user="row.messages[1].user">
                      <template v-slot:btn>
                        <div :class="$style.open" v-on:click="handleShowAnswer(row.id)">
                          <p :class="$style.open_p">{{ row.showBottom ? '回答をしまう' : '回答をみる'}}</p>
                          <i v-if="!showAnswers[row.id]" :class="$style.icon" class="fa-regular fa-angles-down"></i>
                          <i v-if="showAnswers[row.id]" :class="$style.icon" class="fa-regular fa-angles-up"></i>
                        </div>
                      </template>
                      <template v-slot:main>
                        <div v-if="showAnswers[row.id]">
                          <spacer :y="2"/>
                          <div class="n2br" :class="$style.answer">
                            <p v-html="row.messages[1].message"></p>
                          </div>
                        </div>
                      </template>
                    </user-box>

                    <risk-application-btn
                      :counseling_type="CONSTANTS.COUNSELING_TYPE_TEXT"
                      :foreign_id="row.id"
                      :target_user_id="row.user_id"
                    />

                  </template>
                </user-box>
              </stack-item>
            </ul>
            <spacer :y="4" />
            <spacer v-if="flag.loaders.answered" :y="6" />

            <pagination
              v-if="!flag.loaders.answered && pageMax && pageMax !== 1"
              :page="page"
              :pageMax="pageMax"
              :path="'/counselor/?tab=text'" />

            <p
              v-else-if="!flag.loaders.answered
                && user.counseling.counselor.text.answered[page]
                && !user.counseling.counselor.text.answered[page].length">実施済みのテキスト健康相談はありません。</p>
          </loader-simple>
        </contents-box>
      </stack-item>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import UserBox from '@/views/components/UserBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import { CounselingBase } from '@/views/components/parts/Counseling/';
import Pagination from '@/views/components/Pagination.vue';
import RiskApplicationBtn from '@/views/pages/Counselor/parts/RiskApplicationBtn.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'counselor-text-counseling',
  mixins: [cf],
  components: {
    BtnContainer,
    BasicBtn,
    StackItem,
    ContentsBox,
    UserBox,
    Spacer,
    LoaderSimple,
    CounselingBase,
    Pagination,
    RiskApplicationBtn,
  },
  data() {
    return {
      flag: {
        runInterval: false,
        alert: false,
        loaders: {
          progress: true,
          draft: true,
          answered: true,
        },
        pageQuery: false,
      },
      page: 1,
      pageMax: 1,
      // limit: 10,
      limit: 5,
      counselorMessages: {},
      showAnswers: {},
      CONSTANTS: { // 定数
        COUNSELING_TYPE_TEXT: '2', // テキスト健康相談タイプ
      },
    };
  },
  created() {
    if (this.$route.query.tab !== 'text') return;
    const query = this.$route.query;
    if (query.page) {
      this.page = Number(query.page);
    } else if (query.tab === 'text') {
      // queryにpageがなければ追加してreplace
      this.$router.replace('/counselor/?tab=text&page=1');
      return;
    }
    this.getTypeTexts();
  },
  watch: {
    $route() {
      if (this.$route.query.tab !== 'text') return;
      this.flag.loaders = { progress: true, draft: true, answered: true };

      const query = this.$route.query;
      if (query.page) {
        this.page = Number(query.page);
      } else if (query.tab === 'text') { // queryにpageがなければ追加してreplace
        this.$router.replace('/counselor/?tab=text&page=1');
        return;
      }

      if (!this.user.counseling.counselor.text.progress.length
        && !this.user.counseling.counselor.text.draft.length
        && !this.user.counseling.counselor.text.answered[1].length) {
        // 全てデータがない（ホーム等別ページから遷移してきた）場合は全取得
        this.getTypeTexts();
      } else if (!this.user.counseling.counselor.text.answered[this.page]
        || (this.user.counseling.counselor.text.answered[this.page]
          && !this.user.counseling.counselor.text.answered[this.page].length)) {
        // 実施済みの指定ページにデータがなければ取得
        this.flag.loaders.progress = false;
        this.flag.loaders.draft = false;
        this.getTypeText('answered');
      } else {
        this.flag.loaders.progress = false;
        this.flag.loaders.draft = false;
        this.flag.loaders.answered = false;
        this.scroll2Top();
      }

      this.flag.pageQuery = true;
    },
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /**
     * テキスト健康相談データをtypeごとに取得
     */
    async getTypeTexts() {
      if (!this.user.manageWeekdays.length) return;
      const types = ['progress', 'draft', 'answered'];
      types.forEach((type) => {
        if (this.page > 1 && (type === 'progress' || type === 'draft')) {
          // this.datas[type] = [];
        } else {
          const limit = type === 'answered' ? this.limit : 999999;
          const params = {
            orderBy: type === 'answered' ? 'desc' : 'asc',
            weekdays: this.user.manageWeekdays,
            includeDraft: type === 'draft' ? 1 : 0,
            target_type: 1,
            page: type === 'answered' ? this.page : 1,
            isRisk: 1,
            limit,
            type,
          };

          this.axios({
            method: 'GET',
            url: '/v2/messageGroup/get/typeList',
            params,
          })
            .then(async (response) => {
              const groups = response.data.groups;
              const commitData = {
                type: 'text',
                division: type,
                values: groups.data,
              };
              // 「実施済み」のみページネーション
              if (type === 'answered') commitData.page = this.page;
              // それぞれのstoreへデータを登録
              this.$store.commit('user/counseling/putCounselorDatas', commitData);

              if (type === 'answered') {
                // 「実施済み」のみページネーション用の仕込み・[回答をみる]のフラグ追加
                this.pageMax = groups.lastPage;
                this.putShowAnsweres(groups.data);
              } else {
                // 「受付中」「下書き」は該当するmodelデータを作成
                this.putCounselorMessages(groups.data);
              }
            })
            .catch((error) => {
              if (error.response) console.log(error.response.data);
              else console.log(error);
            })
            .finally(() => {
              this.flag.loaders[type] = false;
              if (type === 'answered' && this.flag.pageQuery) {
                this.scroll2Top();
              }
            });
        }
      });
    },

    /**
     * テキスト健康相談データ typeを指定して取得
     */
    async getTypeText(type) {
      if (!this.user.manageWeekdays.length) return;
      const types = ['progress', 'draft', 'answered'];
      if (!types.includes(type)) return;

      const limit = type === 'answered' ? this.limit : 999999;
      const params = {
        orderBy: type === 'answered' ? 'desc' : 'asc',
        weekdays: this.user.manageWeekdays,
        includeDraft: type === 'draft' ? 1 : 0,
        target_type: 1,
        isRisk: 1,
        page: type === 'answered' ? this.page : 1,
        limit,
        type,
      };

      this.axios({
        method: 'GET',
        url: '/v2/messageGroup/get/typeList',
        params,
      })
        .then(async (response) => {
          const groups = response.data.groups;
          const commitData = {
            type: 'text',
            division: type,
            values: groups.data,
          };
          // 「実施済み」のみページネーション
          if (type === 'answered') commitData.page = this.page;
          // それぞれのstoreへデータを登録
          this.$store.commit('user/counseling/putCounselorDatas', commitData);
          if (type === 'answered') {
            // 「実施済み」のみ[回答をみる]のフラグ追加
            this.putShowAnsweres(groups.data);
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.loaders[type] = false;
          if (type === 'answered' && this.flag.pageQuery) {
            this.scroll2Top();
          }
        });
    },

    checkDrafts() {
      this.datas.progress.forEach((row) => {
        const params = {
          id: row.id,
          includeDraft: 1,
        };

        this.axios({
          method: 'GET',
          url: '/v1/messageGroup/get/detail',
          params,
        })
          .then((response) => {
            const res = response.data;
            if (res.detail.messages.length === 2) {
              // alertが重複して出ないように
              if (!this.flag.alert) {
                this.flag.alert = true;
                alert('新たに回答が作成されたテキスト健康相談があります。\nリロードで情報を更新してください。');
              }
              this.flag.alert = false;
            }
          })
          .catch((error) => {
            if (error.message) console.log(error.message);
            else console.log(error);
          });
      });
    },

    /** userBox背景色指定用 */
    userBoxColor(user) {
      let color;
      if (user.risk.length && user.risk[user.risk.length - 1].type !== 11) {
        color = 'red';
      } else {
        color = 'white';
      }
      return color;
    },

    // 入力する回答のモデル
    putCounselorMessages(data) {
      data.forEach((d) => {
        // 下書きの場合はlengthが2になるため[1].message
        // 利用者メッセージのみの場合はlengthが1なので空
        const counselorMessage = d.messages.length > 1 ? d.messages[1].message : '';
        this.counselorMessages[d.id] = counselorMessage;
      });
    },

    // 回答をみるフラグの初期値
    putShowAnsweres(data) {
      data.forEach((d) => {
        this.showAnswers[d.id] = false;
      });
    },

    // 回答をみるフラグのハンドリング
    handleShowAnswer(id) {
      const next = !this.showAnswers[id];
      this.showAnswers[id] = next;
    },

    /**
     * 回答・下書き保存
     */
    submit(e, index, type, flag) {
      e.preventDefault();
      let submitConfirm = true;
      if (flag === 1) submitConfirm = confirm('回答は取り消せません。よろしいですか？');
      if (!submitConfirm) return;

      this.showLoading();

      const target = this.user.counseling.counselor.text[type][index];
      // 登録時はnull
      const messageId = target.messages.length === 2
        ? target.messages[1].id
        : null;
      const data = {
        flag, // 1 => 回答, 2 => 下書き
        user_id: this.user.id,
        message: this.counselorMessages[target.id],
        group_id: target.id,
        message_id: messageId,
      };

      let action = '登録';
      if (flag === 2) action = '下書き保存';
      const method = !messageId ? 'register' : 'updater';

      if (method === 'register') this.checkDuplicateText(target, data, method, action, flag);
      else this.updateTexts(target, data, method, action, flag);
    },

    checkDuplicateText(target, data, method, action, flag) {
      const params = {
        id: target.id,
      };

      this.axios({
        method: 'GET',
        url: '/v2/messageGroup/get/liteDuplicateCheck',
        params,
      })
        .then((response) => {
          const res = response.data.result.data[0];
          if (res.flag !== 1) {
            const status = res.flag === 11 ? '回答' : '下書き';
            alert(`このテキスト健康相談はすでに${status}が登録されています。\nリロードして情報を更新してください。`);
            this.hideLoading();
          } else {
            this.updateTexts(target, data, method, action, flag);
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    updateTexts(target, data, method, action, flag) {
      this.axios({
        method: 'POST',
        url: `/v1/message/set/${method}`,
        data,
      })
        .then(() => {
          alert(`ありがとうございます。ご回答内容を${action}しました！`);
          // 描画初期化
          this.flag.loaders = { progress: true, draft: true, answered: true };
          // 表示用のstore初期化
          const types = ['progress', 'draft', 'answered'];
          types.forEach((type) => {
            const commitData = { type: 'text', division: type, values: [] };
            if (type === 'answered') commitData.page = 1;
            this.$store.commit('user/counseling/putCounselorDatas', commitData);
          });
          this.getTypeTexts();
          // 回答の場合はメール・LINE連絡
          if (flag === 1) this.textCounselingContact(target.id);
          else this.hideLoading();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          this.hideLoading();
        });
    },

    /** テキストカウンセリング回答の連絡 */
    textCounselingContact(groupId) {
      // 利用者へ連絡
      this.axios({
        method: 'POST',
        url: '/v1/contact/answer/text',
        data: { group_id: groupId },
      })
        .then((response) => {
          if (this.helper.env.name === 'local') {
            console.log(response.data);
          }
          this.hideLoading();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          this.hideLoading();
        });
    },

    scroll2Top() {
      // 上までスクロール
      const elem = document.getElementById('scroll-top');
      const position = elem.getBoundingClientRect().top;
      scrollBy(0, position - 77);
    },
  },
};
</script>

<style lang="scss" module>
.textarea {
  width: 100%;
  resize: vertical;
  border: none;
  outline: none;
  background-color: var(--gray-sub);
  border-radius: 8px;
  padding: 1em;
  line-height: 1.8;
}
.count {
  display: flex;
  justify-content: flex-end;
  // font-size: 14px;
}
.open {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--gray-side);
  .icon {
    margin-left: 8px;
  }
  @include sm-view {
    justify-content: center;
  }
}
.answer {
  // font-size: 14px;
  padding: 12px;
  background-color: rgba(var(--orange-main-rgb), .15);
  border-radius: 4px;
}
.message {
  color: red;
  font-size: 12px;
  margin-bottom: 1rem;
}
</style>

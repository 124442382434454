<template>
  <div :class="$style.pagenation">
    <router-link
      v-if="page > 1"
      :to="{
        path,
        query: getQuery(jumpPagePre)
      }"
      @click="clickPage(jumpPagePre)"
    >
      <p :class="$style.jumppage"><span>&lt;&lt;</span></p>
    </router-link>
    <router-link
      v-if="page > 1"
      :to="{
        path,
        query: getQuery(page - 1)
      }"
      @click="clickPage(page - 1)"
    >
      <p><span>◁</span></p>
    </router-link>
    <router-link
      v-if="page > 1"
      :to="{
        path,
        query: getQuery(1)
      }"
      @click="clickPage(1)"
    >
      <p><span>1</span></p>
    </router-link>
    <span v-if="page > 2" :class="$style.intermediate">・・・</span>
    <!-- <router-link
      v-if="page > 2"
      :to="{
        path,
        query: getQuery(page - 1)
      }">
      <p><span>{{ page-1 }}</span></p>
    </router-link> -->
    <router-link
      :to="{
        path,
        query: getQuery(page)
      }"
      @click="clickPage(page)"
    >
      <p :class="{ [$style.selected] : page === page }"><span>{{ page }}</span></p>
    </router-link>
    <span v-if="page < pageMax -1" :class="$style.intermediate">・・・</span>
    <!-- <router-link
      v-if="page < pageMax - 1"
      :to="{
        path,
        query: getQuery(page + 1)
      }">
      <p><span>{{ page+1 }}</span></p>
    </router-link> -->
    <!-- <router-link
      v-if="page < pageMax - 2"
      :to="{
        path,
        query: getQuery(page + 2)
      }">
      <p><span>{{ page+2 }}</span></p>
    </router-link> -->
    <router-link
      v-if="page < pageMax"
      :to="{
        path,
        query: getQuery(pageMax)
      }"
      @click="clickPage(pageMax)"
    >
      <p><span>{{ pageMax }}</span></p>
    </router-link>
    <router-link
      v-if="page < pageMax"
      :to="{
        path,
        query: getQuery(page + 1)
      }"
      @click="clickPage(page + 1)"
    >
      <p><span>▷</span></p>
    </router-link>
    <router-link
      v-if="page < pageMax"
      :to="{
        path,
        query: getQuery(jumpPagePost)
      }"
      @click="clickPage(jumpPagePost)"
    >
      <p :class="$style.jumppage"><span>&gt;&gt;</span></p>
    </router-link>
  </div>
</template>

<script>
const JUMP_PAGE_AMOUNT = 20;
export default {
  props: {
    page: {
      type: Number,
      default: 0,
    },
    pageMin: {
      type: Number,
      default: 0,
    },
    pageMax: {
      type: Number,
      default: 0,
    },
    path: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    needEmit: { // ページ移動アイコンをクリックした際に、path操作ではなく親コンポーネントへのemitを実行するか
      type: Boolean,
      default: false,
    },
  },
  data() {
  },
  computed: {
    jumpPagePre() {
      const min = this.pageMin > 1 ? this.pageMin : 1; // 1より前には行かせないが、呼び出し元からpageMinとして2以上を指定されている場合はその数値を採用する
      return this.page - JUMP_PAGE_AMOUNT < min ? min : this.page - JUMP_PAGE_AMOUNT;
    },
    jumpPagePost() {
      return this.pageMax < this.page + JUMP_PAGE_AMOUNT ? this.pageMax : this.page + JUMP_PAGE_AMOUNT;
    },
  },
  methods: {
    getQuery(n) {
      const query = {};
      Object.keys(this.$route.query).forEach((key) => {
        if (key === this.type) query[this.type] = n;
        else if (key !== 'page') query[key] = this.$route.query[key];
        else query.page = n;
      });
      return query;
    },
    clickPage(n) {
      if (this.needEmit) {
        this.$emit('click-page', n);
      }
    },
  },
};
</script>

<style lang="scss" module>

.pagenation {
  $mgn: .5em;
  display: flex;
  flex-wrap: wrap;
  color: var(--gray-side);
  justify-content: center;
  a {
    margin-top: $mgn;
  }
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    margin: 0 10px;
    border-radius: 50%;
    border: 1px solid var(--orange-main);
    color: var(--orange-main);
    cursor: pointer;
    &.selected {
      background-color: var(--orange-main);
      color: #fff;
    }
    &.jumppage {
      margin-left: 0;
      margin-right: 0;
    }
    span {
      cursor: pointer;
    }
  }
  p:hover {
    background-color: var(--orange-main);
    color: #fff;
    transition: .3s all;
  }
  i {
    margin: 0 15px;
    font-size: 30px;
    color: var(--orange-main);
    cursor: pointer;
    &.invalid {
      color: var(--gray-main);
      cursor: initial;
    }
  }
}
.intermediate {
  display: flex;
  align-items: center;
  padding-top: 5px;
  color: var(--orange-main);
}
</style>

<template>
  <div :class="[isDisableMinHight ? $style.loader_wrapper : [$style.loader_wrapper, $style.min_height]]">
    <slot />
    <div
      :class="[$style.loader_simple, mini ? $style.mini : '']"
      v-if="flag" />
  </div>
</template>

<script>
export default {
  name: 'LoaderSimple',
  props: {
    flag: {
      type: Boolean,
      default: false,
    },
    isDisableMinHight: {
      type: Boolean,
      default: false,
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
  },
};
</script>

<style lang="scss" module>
.loader_simple {
  width: 2em;
  height: 2em;
  animation: rotation 1s infinite linear;
  border: 1px solid rgba(black, 0.2);
  border-top-color: rgba(black, 0.7);
  border-radius: 50%;
  position: absolute;
  top: 0; bottom: 0;
  left: 0; right: 0;
  margin: auto;
  z-index: 1;
  &.mini {
    width: 1.2em;
    height: 1.2em;
  }
}

.loader_wrapper {
  position: relative;
}

.min_height {
  min-height: 50px;
}

@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}
</style>

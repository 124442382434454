<template>
  <div :class="$style.container">
    <spacer :y="7"/>
    <div :class="$style.tabs_wrap" id="wrap">
      <ul :class="tabsClass()" id="tabs">
        <li
          v-for="(value, key) in items"
          :key="key"
          :class="tabClassName(key)"
        >
          <button
            :class="$style.button"
            @click="handleClick(key)"
          >{{value.label}}</button>
          <div v-if="value.new" :class="$style.new">NEW</div>
        </li>
      </ul>
      <div
        :class="$style.left"
        v-on:click="scroll('left')"
        v-if="flag.showArrow"><i class="fa-solid fa-angles-left"/></div>
      <div
        :class="$style.right"
        v-on:click="scroll('right')"
        v-if="flag.showArrow"><i class="fa-solid fa-angles-right"/></div>
    </div>
  </div>
</template>

<script>
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'TabNav',
  components: { Spacer },
  props: {
    items: {
      type: Object,
      requied: true,
    },
    isActive: {
      type: String,
      requied: true,
    },
  },
  data() {
    return {
      flag: {
        showArrow: false,
      },
    };
  },
  created() {
  },
  mounted() {
    this.checkTabWidth();
    addEventListener('resize', this.checkTabWidth);
  },
  methods: {
    handleClick(key) {
      cf.saveTabState({ tab: key });
      this.$emit('sendIsActiveValue', key);
    },
    tabClassName(key) {
      return `${this.$style.tabItem} ${this.isActive === key ? this.$style.isActive : ''}`;
    },
    tabsClass() {
      let className = this.$style.tabs;
      if (this.flag.showArrow) className += ` ${this.$style.arrow}`;
      return className;
    },
    checkTabWidth() {
      // タブが全て表示される場合には矢印非表示
      this.flag.showArrow = false;
      const wrap = document.getElementById('wrap');
      const tabs = document.getElementById('tabs');
      if (wrap && tabs) {
        const wrapW = wrap.clientWidth;
        const tabsW = tabs.scrollWidth;
        if (wrapW < tabsW) this.flag.showArrow = true;
      }
    },
    scroll(direction) {
      const tabs = document.getElementById('tabs');
      const tabsW = tabs.scrollWidth;
      const viewW = tabs.clientWidth;
      const positionX = tabs.scrollLeft;
      const maxX = tabsW - viewW;
      let X = direction === 'right' ? viewW : viewW * -1;
      if (positionX + X > maxX && direction === 'right') X = maxX - positionX;
      if (positionX < maxX && direction === 'left') X = positionX * -1;

      tabs.scrollLeft += X;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: block;
  width: 100%;
  border-bottom: 1px solid #cecece;
  padding: 0 calc(var(--spacer-base) * 2);

  @include sm-view {
    display: none;
  }
}

.tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 4px;
  padding-top: 12px;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  &_wrap {
    position: relative;
  }
  &.arrow {
    margin: 0 30px;
  }
}
.right, .left {
  position: absolute;
  top: 50%;
  transform: translate(0, calc(-50% + 4px));
  font-size: 20px;
  color: var(--gray-side);
  cursor: pointer;
  &:hover {
    opacity: .4;
  }
  transition: all .3s;
}
.right {
  right: 0;
}
.left {
  left: 0;
}

.tabItem {
  position: relative;
  white-space: nowrap;

  & + & {
    margin-left: calc(var(--spacer-base) * 2);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 7px;
    border-radius: 4px;
    background-color: transparent;
    transform: translate3d(0, 3px, 0);
    transition: all 250ms ease-in-out;
  }

  &.isActive::after {
    background-color: var(--secondary-color);
    transform: translate3d(0, 0, 0);
  }
}

.button {
  padding: 8px calc(var(--spacer-base) * 2);
  font-size: 20px;
  color: #696969;
  appearance: none;
  border: none;
  background-color: transparent;
  box-shadow: none;
  transition: all 250ms ease-in-out;
  border-radius: 10px;

  .tabItem:not(.isActive) &:hover {
    cursor: pointer;
    background-color: #e9e9e9;
  }
}

.new {
  position: absolute;
  top: -12px;
  right: -5px;
  padding: 3px 5px;
  background-color: var(--pink);
  border-radius: 3px;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
}
</style>

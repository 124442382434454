/* eslint-disable */
export default class {
  constructor(options) {
    this.bufferSize = options.bufferSize || 4096;
    this.sampleRate = options.sampleRate;
    this.samples = options.samples;
  }
  finish() {
    const dataview = this.encode(this.samples, this.sampleRate)
    const audioBlob = new Blob([dataview], { type: 'audio/wav' })
    return {
      id: Date.now(),
      blob: audioBlob,
      url: URL.createObjectURL(audioBlob),
    };
  }

  encode(samples, sampleRate) {
    samples = this._joinSamples(samples);

    const buffer = new ArrayBuffer(44 + samples.length * 2);
    const view = new DataView(buffer);

    this._writeString(view, 0, 'RIFF'); // RIFFヘッダ
    view.setUint32(4, 32 + samples.length * 2, true); // RIFFチャンク長(以降のファイルサイズ)
    this._writeString(view, 8, 'WAVE'); // RIFFタイプ・WAVEヘッダ
    this._writeString(view, 12, 'fmt '); // fmtチャンク
    view.setUint32(16, 16, true); // fmtチャンク長
    view.setUint16(20, 1, true); // formatID
    view.setUint16(22, 1, true); // チャンネル数
    view.setUint32(24, sampleRate, true); // サンプリングレート
    view.setUint32(28, sampleRate * 4, true); // データ速度
    view.setUint16(32, 4, true); // ブロックサイズ
    view.setUint16(34, 16, true); // ビット数
    this._writeString(view, 36, 'data'); // dataチャンク
    view.setUint32(40, samples.length * 2, true); // 波形のバイト数

    this._floatTo16BitPCM(view, 44, samples); // 波形データ

    return view;
  }

  _floatTo16BitPCM(output, offset, input) {
    let offsetParam = offset;
    for (let i = 0; i < input.length; i += 1, offsetParam += 2) {
      const s = Math.max(-1, Math.min(1, input[i]));
      output.setInt16(offsetParam, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
    }
  }

  _joinSamples(samples) {
    const recordLength = samples.length * this.bufferSize;
    const joinedSamples = new Float64Array(recordLength);
    let offset = 0;

    for (let i = 0; i < samples.length; i += 1) {
      const sample = samples[i];
      joinedSamples.set(sample, offset);
      offset += sample.length;
    }

    return joinedSamples;
  }

  _writeString(view, offset, string) {
    for (let i = 0; i < string.length; i += 1) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  }
}

<template>
  <div>
    <p>利用者の学年を変更します</p>
    <spacer :y="4" />
    <user-box :showId="false" type="inner" :user="data"></user-box>
    <spacer :y="4" />
    <div :class="$style.edit_text">
      <p>
        {{ data.school_year ? `${data.school_year}年生` : '未登録' }}から
        <select
          :class="$style.select_box"
          v-model="schoolYear"
          name="schoolYear"
          id="schoolYear">
          <option :value="null">未登録</option>
          <option
            v-for="n of data.maxSchoolyear"
            :key="n"
            :value="n">{{ n }}</option>
        </select>
        <span v-if="schoolYear !== null">年生</span>に変更
      </p>
    </div>
    <spacer :y="4" />
    <btn-container>
      <basic-btn :disabled="!schoolYear" tag="button" v-on:click="save">確定</basic-btn>
      <spacer :x="2" />
      <basic-btn tag="button" type="bdr" v-on:click="hideModal">キャンセル</basic-btn>
    </btn-container>
  </div>
</template>

<script>
import UserBox from '@/views/components/UserBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';

export default {
  name: 'edit-school-year',
  props: ['data'],
  components: {
    UserBox,
    Spacer,
    BasicBtn,
    BtnContainer,
  },
  data() {
    return {
      schoolYear: null,
    };
  },
  created() {},
  methods: {
    save() {
      const data = {
        id: this.data.id,
        school_year: this.schoolYear,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          alert('利用者の学年の更新に失敗しました。');
        })
        .finally(() => {
          this.hideModal();
        });
    },
    hideModal() {
      this.$store.dispatch('modal/messages/hideModal', null, { root: true });
    },
  },
};
</script>

<style lang="scss" module>
.edit_text {
}
.select_box {
  text-align: center;
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;
  appearance: none;

  &_wrap {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
</style>

<template>
  <div :class="$style.spacious">
    <spacer :y="5" />
    <div :class="$style.outer_flex">
      <div :class="$style.outer_flex_left">
        <contents-box type="inner">
          <div :class="$style.search_frame">
            <div :class="$style.search_frame_title"><i class="fa-solid fa-calendar-days"></i> 期間</div>
            <div :class="$style.search_period">
              <span :class="$style.search_period_box">
                <date-small-picker
                  :name="'startDate'"
                  v-model="startDate"
                  v-on:sendDate="receiveDate" />
              </span>
              <span :class="$style.search_period_box_bar">〜</span>
              <span :class="$style.search_period_box">
                <date-small-picker
                  :name="'endDate'"
                  v-model="endDate"
                  v-on:sendDate="receiveDate" />
              </span>
            </div>
          </div>
          <spacer :y="2" />
          <!-- 利用者タイプ -->
          <div :class="$style.search_frame">
            <div :class="$style.search_frame_title"><i class="fa-solid fa-users"></i> 利用者タイプ</div>
            <div>
              <ul :class="$style.select">
                <li v-for="item in accountTypeItems" :key="item" :class="$style.select_elem">
                  <input
                    type="checkbox"
                    :id="item.name"
                    :value="item.value"
                    :checked="item.checked"
                    v-on:change="updateType($event, 'account')">
                  <label :for="item.name">{{ item.label }}</label>
                </li>
              </ul>
            </div>
          </div>
          <spacer :y="2" />
          <div :class="$style.search_frame">
            <div :class="$style.search_frame_title"><i class="fa-solid fa-school"></i> 学校タイプ</div>
            <div :class="$style.search_frame_contents">
              <ul :class="$style.select">
                <li v-for="item in schoolTypeItems" :key="item" :class="$style.select_elem">
                  <input
                    type="checkbox"
                    :id="item.name"
                    :value="item.value"
                    :checked="item.checked"
                    v-on:change="updateType($event, 'school')">
                  <label :for="item.name">{{ item.label }}</label>
                </li>
              </ul>
            </div>
          </div>
          <spacer :y="2" />
          <div :class="$style.search_frame">
            <div :class="$style.search_frame_title"><i class="fa-solid fa-arrow-down-wide-short"></i> 学校ソート</div>
            <div :class="$style.search_frame_contents">
              <select
                :class="$style.select_box"
                name="school"
                id="school"
                v-on:change="updateSchool">
                <option :value="0" :selected="targetSchool === 0">すべて</option>
                <option
                  v-for="n of schools"
                  :key="n"
                  :value="n.id"
                  :selected="n.id === targetSchool">{{ n.name }}</option>
              </select>
            </div>
          </div>
          <spacer :y="2" />
          <div :class="$style.search_frame">
            <div :class="$style.search_frame_title"><i class="fa-solid fa-arrow-down-wide-short"></i> 8地方区分</div>
            <div>
              <select
                :class="$style.select_box"
                name="area"
                id="area"
                v-model="targetArea">
                <option :value="0" :selected="targetArea === 0">全国</option>
                <option
                  v-for="i of areas"
                  :key="i"
                  :value="i"
                  :selected="i === targetArea">{{ i }}</option>
              </select>
            </div>
          </div>
          <spacer :y="2" />
          <div :class="$style.search_frame">
            <div :class="$style.search_frame_title"><i class="fa-regular fa-filter"></i> 除外</div>
            <div :class="$style.search_frame_contents">
              <input
                type="checkbox"
                id="exclusionCancel"
                v-model="exclusionCancel">
              <label for="exclusionCancel">キャンセルを除外</label>
            </div>
          </div>
          <spacer :y="3" />
          <div>
            <loader-simple :flag="flag.loader" :mini="true">
              <basic-btn
                tag="button"
                addClass="wide"
                :disabled="flag.loader || startDate === null || endDate === null "
                @click="startAnalytics()">実行する</basic-btn>
                <p v-if="startDate === null || endDate === null">期間を選択してください</p>
            </loader-simple>
          </div>
        </contents-box>
      </div>
      <spacer :x="1" />
      <div :class="$style.outer_flex_right">
        <div :class="$style.analytics_common">
          <analytics-common
            :starting="startDate"
            :textData="analyzedTextData"
            :onlineData="analyzedReserveData"
            :drowData="graphDatas"
            :backendData="backendData"
            :allFilter="allFilter"
            :schools="schools"
            :accountsData="accountsData"
            @send-data="getAccounts"
            @send-trend="getAnalytics"
            ref="analyticsCommon" />
        </div>
        <spacer :y="5" />
        <!-- タブによってグラフ内容出しわけ（表示のみ管理） -->
        <div
          :class="$style.search_tab">
          <ul :class="$style.tab_list">
            <li
              v-for="tab in analytics_tabs"
              :key="tab"
              :class="[`${tab.check ? $style.check_tab : $style.not_check}`, $style[`tab_${tab.value}`]]">
              <div @click="toggleTab(tab.value)" :class="$style.cursor_poiter">{{ tab.label }}</div>
            </li>
          </ul>
        </div>
        <loader-simple :flag="!backendData">
          <contents-box>
            <div v-if="backendData">
              <div v-for="tab in analytics_tabs" :key="tab">
                <div v-if="tab.check">
                  <div>
                    <!-- テキスト -->
                    <div v-if="tab.value === 'text'">
                      <!-- <div v-if="!analyzedTextData.allCount">
                        <p>選択の期間内に該当するデータがありません</p>
                      </div> -->
                      <div>
                        <div :class="$style.wide_contents">
                          <contents-box type="inner">
                            <div v-if="backendData" :class="$style.graph_contents">
                              <div :class="$style.graph_contents_title">ステータス</div>
                              <spacer :y="2" />
                              <mix-graph
                                :labels-data="backendData.month.graph.text.labels"
                                :datasets-data="backendData.month.graph.text.datasets" />
                            </div>
                          </contents-box>
                        </div>
                        <div :class="$style.card_container">
                          <div :class="$style.card"> <!-- 画面幅に応じて個数の変化するグラフボックス -->
                            <contents-box
                              type="inner"
                              style="height: 100%;"> <!-- あまり綺麗ではないがここで高さ調整 -->
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">性別</div>
                                <spacer :y="2" />
                                <div :class="$style.graph_contents_figure">
                                  <div :class="$style.graph_size_max">
                                    <pie-graph
                                      :labelsData="backendData.gender.text.labels"
                                      :datasetsData="backendData.gender.text.datasets" />
                                  </div>
                                </div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th>性別</th><th>件数</th><th>ユニーク数</th><th>割合</th></tr>
                                    <tr>
                                      <td :class="$style.alg_center"><span :style="`color: ${backendData.gender.text.datasets.backgroundColor[0]}`">● </span>男性　</td>
                                      <td :class="$style.alg_right">{{ backendData.gender.text.datasets.data[0] }}件</td>
                                      <td :class="$style.alg_right">{{ backendData.gender.text.unique.male.length }}人</td>
                                      <td :class="$style.alg_right">{{ calcPercentage(backendData.gender.text.datasets.data[0], backendData.gender.text.datasets.data[0] + backendData.gender.text.datasets.data[1]) }}%</td>
                                    </tr>
                                    <tr>
                                      <td :class="$style.alg_center"><span :style="`color: ${backendData.gender.text.datasets.backgroundColor[1]}`">● </span>女性　</td>
                                      <td :class="$style.alg_right">{{ backendData.gender.text.datasets.data[1] }}件</td>
                                      <td :class="$style.alg_right">{{ backendData.gender.text.unique.female.length }}人</td>
                                      <td :class="$style.alg_right">{{ calcPercentage(backendData.gender.text.datasets.data[1], backendData.gender.text.datasets.data[0] + backendData.gender.text.datasets.data[1]) }}%</td>
                                    </tr>
                                    <tr>
                                      <td :class="$style.alg_center">合計　</td>
                                      <td :class="$style.alg_right">{{ backendData.gender.text.datasets.data[0] + backendData.gender.text.datasets.data[1] }}件</td>
                                      <td :class="$style.alg_right">{{ backendData.gender.text.unique.male.length + backendData.gender.text.unique.female.length }}人</td>
                                      <td :class="$style.alg_right">　</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">年齢</div>
                                <spacer :y="2" />
                                <div :class="$style.graph_contents_figure">
                                  <div :class="$style.graph_size_max">
                                    <pie-graph
                                      :labelsData="backendData.ageData.text.labels"
                                      :datasetsData="backendData.ageData.text.datasets" />
                                  </div>
                                </div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th>年齢</th><th>件数</th><th>割合</th></tr>
                                    <tr v-for="data, index in backendData.ageData.text.labels" :key="data">
                                      <td :class="$style.alg_center">
                                        <span :style="`color: ${backendData.ageData.text.datasets.backgroundColor[index]}`">●</span>{{ data }}歳
                                      </td>
                                      <td :class="$style.alg_right">{{ backendData.ageData.text.datasets.data[index] }}件</td>
                                      <td :class="$style.alg_right">{{ calcPercentage(backendData.ageData.text.datasets.data[index], calcSum(backendData.ageData.text.datasets.data)) }}%</td>
                                    </tr>
                                    <tr>
                                      <td :class="$style.alg_center">合計</td>
                                      <td :class="$style.alg_right">{{ calcSum(backendData.ageData.text.datasets.data) }}件</td>
                                      <td>　</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">時間</div>
                                <spacer :y="2" />
                                <div :class="$style.graph_contents_figure">
                                  <div :class="$style.graph_size_max">
                                    <pie-graph
                                      :labelsData="backendData.scheduleTime.text.labels"
                                      :datasetsData="backendData.scheduleTime.text.datasets" />
                                  </div>
                                </div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th>時間帯</th><th>件数</th><th>割合</th></tr>
                                    <tr v-for="data, index in backendData.scheduleTime.text.labels" :key="data">
                                      <td :class="$style.alg_center">
                                        <span :style="`color:${backendData.scheduleTime.text.datasets.backgroundColor[index]}`">●</span>{{ data }}
                                      </td>
                                      <td :class="$style.alg_right">{{ backendData.scheduleTime.text.datasets.data[index] }}件</td>
                                      <td :class="$style.alg_right">{{ calcPercentage(backendData.scheduleTime.text.datasets.data[index], calcSum(backendData.scheduleTime.text.datasets.data)) }}%</td>
                                    </tr>
                                    <tr>
                                      <td :class="$style.alg_center">合計</td>
                                      <td :class="$style.alg_right">{{ calcSum(backendData.scheduleTime.text.datasets.data) }}件</td>
                                      <td>　</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">学年
                                  <GuideIcon
                                    icon="fa-regular fa-circle-question"
                                    boxSize="230"
                                    hoverText="テキスト相談受付時の学年" />
                                </div>
                                <spacer :y="2" />
                                <div :class="$style.graph_contents_figure">
                                  <div :class="$style.graph_size_max">
                                    <pie-graph
                                      :labelsData="backendData.schoolYear.text.labels"
                                      :datasetsData="backendData.schoolYear.text.datasets" />
                                  </div>
                                </div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th>学年</th><th>件数</th><th>割合</th></tr>
                                    <tr v-for="data, index in backendData.schoolYear.text.labels" :key="data">
                                      <td :class="$style.alg_center">
                                        <span :style="`color: ${backendData.schoolYear.text.datasets.backgroundColor[index]}`">●</span>{{ data }}
                                      </td>
                                      <td :class="$style.alg_right">{{ backendData.schoolYear.text.datasets.data[index] }}件</td>
                                      <td :class="$style.alg_right">{{ calcPercentage(backendData.schoolYear.text.datasets.data[index], calcSum(backendData.schoolYear.text.datasets.data)) }}%</td>
                                    </tr>
                                    <tr>
                                      <td :class="$style.alg_center">合計</td>
                                      <td :class="$style.alg_right">{{ calcSum(backendData.schoolYear.text.datasets.data) }}件</td>
                                      <td>　</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                        </div>
                        <spacer :y="2" />
                        <p :class="$style.graph_contents_title">WHO-5</p>
                        <div :class="$style.card_container">
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">全体</div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th>相談件数</th><td :class="$style.alg_right">{{ backendData.counselingType.table[3].value }}件</td></tr>
                                    <tr><th>利用者数</th><td :class="$style.alg_right">{{ backendData.month.table.userCount.text }}人</td></tr>
                                    <tr><th>平均利用回数</th><td :class="$style.alg_right">{{ usedTextAve }}回/人</td></tr>
                                    <tr><th>WHO-5</th><td :class="$style.alg_right">{{ calcWeightAve(backendData.month.counts.text, backendData.month.weight.text) }}点</td></tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">月</div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th>年-月</th><th>平均点</th></tr>
                                    <tr v-for="data, index in backendData.month.graph.text.labels" :key="data">
                                      <th>{{ data }}</th>
                                      <td :class="$style.alg_right">{{ backendData.month.graph.text.datasets[0].data[index] }}点</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">曜日</div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th>曜日</th><th>平均点</th></tr>
                                    <tr v-for="data in backendData.month.table.weekDays.text"
                                      :key="data"
                                      v-show="data.value > 0">
                                      <th>{{ data.label }}</th>
                                      <td :class="$style.alg_right">{{ data.value }}点</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">性別</div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr>
                                      <th>性別</th><th>平均点</th>
                                    </tr>
                                    <tr v-for="data in backendData.month.table.gender.text" :key="data">
                                      <th>{{ data.label }}</th>
                                      <td :class="$style.alg_right">{{ data.value }}点</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">時間</div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th>受付時間帯</th><th>平均点</th></tr>
                                    <tr v-for="data, index in backendData.scheduleTime.text.labels"
                                      :key="data">
                                      <th :class="$style.alg_center">
                                        {{ data }}
                                      </th>
                                      <td :class="$style.alg_right">
                                        {{ backendData.month.table.receptionTime.text[index] }}点
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                        </div>
                        <spacer :y="2" />
                        <div :class="$style.wide_contents">
                          <contents-box
                            type="inner">
                            <div v-if="backendData" :class="$style.graph_contents">
                              <div :class="$style.graph_contents_title">相談内容</div>
                              <spacer :y="2" />
                              <div :class="$style.wide_contents_block">
                                <div :class="$style.graph_contents_figure">
                                  <div :class="$style.graph_size_max">
                                    <bar-graph
                                      Axis="y"
                                      :labelsData="backendData.worries.text.labels"
                                      :datasetsData="backendData.worries.text.datasets" />
                                  </div>
                                </div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th>お悩み</th><th>回数</th></tr>
                                    <!-- <tr v-for="data in onayami.text" :key="data">
                                      <td :class="$style.alg_center">{{ data.label }}</td>
                                      <td>{{ data.data }}回</td>
                                    </tr> -->
                                    <tr v-for="data, index in backendData.worries.text.labels"
                                      :key="data">
                                      <td>
                                        <span :style="`color:${backendData.worries.text.datasets.backgroundColor[index]}`">●</span>
                                        {{ data }}
                                      </td>
                                      <td :class="$style.alg_right">{{ backendData.worries.text.datasets.data[index] }}回</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </contents-box>
                        </div>
                      </div>
                    </div>

                    <!-- オンライン -->
                    <div v-if="['online', 'onlineOnly', 'virtualOnly'].includes(tab.value) && backendData?.month?.graph?.[tab.value]?.datasets?.[0]?.data?.length">
                      <div>
                        <div :class="$style.wide_contents">
                          <contents-box type="inner">
                            <div v-if="backendData" :class="$style.graph_contents">
                              <div :class="$style.graph_contents_title">ステータス</div>
                              <spacer :y="2" />
                              <mix-graph
                                :labels-data="backendData.month.graph[tab.value].labels"
                                :datasets-data="backendData.month.graph[tab.value].datasets" />
                            </div>
                          </contents-box>
                        </div>
                        <div :class="$style.card_container">
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">性別</div>
                                <spacer :y="2" />
                                <div :class="$style.graph_contents_figure">
                                  <div :class="$style.graph_size_max">
                                    <pie-graph
                                      :labelsData="backendData.gender[tab.value].labels"
                                      :datasetsData="backendData.gender[tab.value].datasets" />
                                  </div>
                                </div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th>性別</th><th>人数</th><th>ユニーク数</th><th>割合</th></tr>
                                    <tr>
                                      <td :class="$style.alg_center"><span :style="`color: ${backendData.gender[tab.value].datasets.backgroundColor[0]}`">● </span>男性　</td>
                                      <td :class="$style.alg_right">{{ backendData.gender[tab.value].datasets.data[0] }}件</td>
                                      <td :class="$style.alg_right">{{ backendData.gender[tab.value].unique.male.length }}人</td>
                                      <td :class="$style.alg_right">{{ calcPercentage(backendData.gender[tab.value].datasets.data[0], backendData.gender[tab.value].datasets.data[0] + backendData.gender[tab.value].datasets.data[1]) }}%</td>
                                    </tr>
                                    <tr>
                                      <td :class="$style.alg_center"><span :style="`color: ${backendData.gender[tab.value].datasets.backgroundColor[1]}`">● </span>女性　</td>
                                      <td :class="$style.alg_right">{{ backendData.gender[tab.value].datasets.data[1] }}件</td>
                                      <td :class="$style.alg_right">{{ backendData.gender[tab.value].unique.female.length }}人</td>
                                      <td :class="$style.alg_right">{{ calcPercentage(backendData.gender[tab.value].datasets.data[1], backendData.gender[tab.value].datasets.data[0] + backendData.gender[tab.value].datasets.data[1]) }}%</td>
                                    </tr>
                                    <tr>
                                      <td :class="$style.alg_center">合計　</td>
                                      <td :class="$style.alg_right">{{ backendData.gender[tab.value].datasets.data[0] + backendData.gender[tab.value].datasets.data[1] }}件</td>
                                      <td :class="$style.alg_right">{{ backendData.gender[tab.value].unique.male.length + backendData.gender[tab.value].unique.female.length }}人</td>
                                      <td>　</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">年齢</div>
                                <spacer :y="2" />
                                <div :class="$style.graph_contents_figure">
                                  <div :class="$style.graph_size_max">
                                    <pie-graph
                                      :labelsData="backendData.ageData[tab.value].labels"
                                      :datasetsData="backendData.ageData[tab.value].datasets" />
                                  </div>
                                </div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th>年齢</th><th>人数</th><th>割合</th></tr>
                                    <tr v-for="data, index in backendData.ageData[tab.value].labels" :key="data">
                                      <td :class="$style.alg_center">
                                        <span :style="`color:${backendData.ageData[tab.value].datasets.backgroundColor[index]}`">●</span>{{ data }}歳
                                      </td>
                                      <td :class="$style.alg_right">{{ backendData.ageData[tab.value].datasets.data[index] }}件</td>
                                      <td :class="$style.alg_right">{{ calcPercentage(backendData.ageData[tab.value].datasets.data[index], calcSum(backendData.ageData[tab.value].datasets.data)) }}%</td>
                                    </tr>
                                    <tr>
                                      <td :class="$style.alg_center">合計</td>
                                      <td :class="$style.alg_right">{{ calcSum(backendData.ageData[tab.value].datasets.data) }}件</td>
                                      <td>　</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">時間</div>
                                <spacer :y="2" />
                                <div :class="$style.graph_contents_figure">
                                  <div :class="$style.graph_size_max">
                                    <pie-graph
                                      :labelsData="backendData.scheduleTime[tab.value].labels"
                                      :datasetsData="backendData.scheduleTime[tab.value].datasets" />
                                  </div>
                                </div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th>時間帯</th><th>件数</th><th>割合</th></tr>
                                    <tr v-for="data, index in backendData.scheduleTime[tab.value].labels" :key="data">
                                      <td :class="$style.alg_center">
                                        <span :style="`color:${backendData.scheduleTime[tab.value].datasets.backgroundColor[index]}`">●</span>{{ data }}
                                      </td>
                                      <td :class="$style.alg_right">{{ backendData.scheduleTime[tab.value].datasets.data[index] }}件</td>
                                      <td :class="$style.alg_right">{{ calcPercentage(backendData.scheduleTime[tab.value].datasets.data[index], calcSum(backendData.scheduleTime[tab.value].datasets.data)) }}%</td>
                                    </tr>
                                    <tr>
                                      <td :class="$style.alg_center">合計　</td>
                                      <td :class="$style.alg_right">{{ calcSum(backendData.scheduleTime[tab.value].datasets.data) }}件</td>
                                      <td>　</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">学年
                                  <GuideIcon
                                    icon="fa-regular fa-circle-question"
                                    boxSize="230"
                                    hoverText="予約申し込み時の学年" />
                                </div>
                                <spacer :y="2" />
                                <div :class="$style.graph_contents_figure">
                                  <div :class="$style.graph_size_max">
                                    <pie-graph
                                      :labelsData="backendData.schoolYear[tab.value].labels"
                                      :datasetsData="backendData.schoolYear[tab.value].datasets" />
                                  </div>
                                </div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th>学年</th><th>件数</th><th>割合</th></tr>
                                    <tr v-for="data, index in backendData.schoolYear[tab.value].labels" :key="data">
                                      <td :class="$style.alg_center">
                                        <span :style="`color:${backendData.schoolYear[tab.value].datasets.backgroundColor[index]}`">●</span>{{ data }}
                                      </td>
                                      <td :class="$style.alg_right">{{ backendData.schoolYear[tab.value].datasets.data[index] }}件</td>
                                      <td :class="$style.alg_right">{{ calcPercentage(backendData.schoolYear[tab.value].datasets.data[index], calcSum(backendData.schoolYear[tab.value].datasets.data)) }}%</td>
                                    </tr>
                                    <tr>
                                      <td :class="$style.alg_center">合計</td>
                                      <td :class="$style.alg_right">{{ calcSum(backendData.schoolYear[tab.value].datasets.data) }}件</td>
                                      <td>　</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">専門家</div>
                                <spacer :y="2" />
                                <div :class="$style.graph_contents_figure">
                                  <div :class="$style.graph_size_max">
                                    <donut-graph
                                      :labelsData="backendData.experts[tab.value].labels"
                                      :datasetsData="backendData.experts[tab.value].datasets"/>
                                  </div>
                                </div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr>
                                      <th>専門家</th><th>件数</th><th>割合</th>
                                    </tr>
                                    <tr
                                      v-for="expert, index in backendData.experts[tab.value].labels"
                                      :key="expert">
                                      <td :class="$style.alg_center">
                                        <span :style="`color: ${backendData.experts[tab.value].datasets.backgroundColor[index]}`">●</span>
                                        {{ expert }}
                                      </td>
                                      <td :class="$style.alg_right">{{ backendData.experts[tab.value].datasets.data[index] }}件</td>
                                      <td :class="$style.alg_right">{{ calcPercentage(backendData.experts[tab.value].datasets.data[index], calcSum(backendData.experts[tab.value].datasets.data)) }}%</td>
                                    </tr>
                                    <tr>
                                      <td :class="$style.alg_center">合計</td>
                                      <td :class="$style.alg_right">{{ calcSum(backendData.experts[tab.value].datasets.data) }}件</td>
                                      <td></td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">指導区分</div>
                                <spacer :y="2" />
                                <div :class="$style.graph_contents_figure">
                                  <div :class="$style.graph_size_max">
                                    <donut-graph
                                      :labelsData="backendData.guidance[tab.value].labels"
                                      :datasetsData="backendData.guidance[tab.value].datasets"/>
                                  </div>
                                </div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr>
                                      <th>区分</th><th>件数</th><th>割合</th>
                                    </tr>
                                    <tr
                                      v-for="type, index in backendData.guidance[tab.value].labels"
                                      :key="type">
                                      <td :class="$style.alg_center">
                                        <span :style="`color: ${backendData.guidance[tab.value].datasets.backgroundColor[index]}`">●</span>
                                        {{ type }}
                                      </td>
                                      <td :class="$style.alg_right">{{ backendData.guidance[tab.value].datasets.data[index] }}件</td>
                                      <td :class="$style.alg_right">{{ calcPercentage(backendData.guidance[tab.value].datasets.data[index], calcSum(backendData.guidance[tab.value].datasets.data)) || 0 }}%</td>
                                    </tr>
                                    <tr>
                                      <td :class="$style.alg_center">合計</td>
                                      <td :class="$style.alg_right">{{ calcSum(backendData.guidance[tab.value].datasets.data) }}件</td>
                                      <td></td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                        </div>
                        <spacer :y="3" />
                        <p :class="$style.graph_contents_title">WHO-5</p>
                        <div :class="$style.card_container">
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">全体</div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th rowspan="3">予約件数</th><th>合計</th><td :class="$style.alg_right">{{ backendData.counselingType.table[tab.value === 'onlineOnly' ? 5 : 4 ].value }}件</td></tr>
                                    <tr><th>医師</th><td :class="$style.alg_right">{{ backendData.experts[tab.value].datasets.data[0] }}件</td></tr>
                                    <tr><th>心理士</th><td :class="$style.alg_right">{{ backendData.experts[tab.value].datasets.data[1] }}件</td></tr>
                                    <tr><th rowspan="3">実施件数</th><th>合計</th><td :class="$style.alg_right">{{ backendData.counselingType.table[tab.value === 'onlineOnly' ? 8 : 7].value }}件</td></tr>
                                    <tr><th>医師</th><td :class="$style.alg_right">{{ backendData.experts[tab.value].online_dones[0] }}件</td></tr>
                                    <tr><th>心理士</th><td :class="$style.alg_right">{{ backendData.experts[tab.value].online_dones[1] }}件</td></tr>
                                    <tr><th>利用者数</th><td :class="$style.alg_right" colspan="2">{{ backendData.month.table.userCount[tab.value] }}人</td></tr>
                                    <tr v-if="tab.value === 'online'"><th>平均利用回数</th><td :class="$style.alg_right" colspan="2">{{ usedOnlineAve }}回/人</td></tr>
                                    <tr v-if="tab.value === 'onlineOnly'"><th>平均利用回数</th><td  :class="$style.alg_right" colspan="2">{{ usedOnlineOnlyAve }}回/人</td></tr>
                                    <tr v-if="tab.value === 'virtualOnly'"><th>平均利用回数</th><td :class="$style.alg_right" colspan="2">{{ usedVirtualOnlyAve }}回/人</td></tr>
                                    <tr><th>WHO-5</th><td :class="$style.alg_right" colspan="2">{{ calcWeightAve(backendData.month.counts[tab.value], backendData.month.weight[tab.value]) }}点</td></tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">月</div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th>年-月</th><th>平均点</th></tr>
                                    <tr v-for="data, index in backendData.month.graph[tab.value].labels" :key="data">
                                      <th>{{ data }}</th>
                                      <td :class="$style.alg_right">{{ backendData.month.graph[tab.value].datasets[0].data[index] }}点</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">曜日</div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th>曜日</th><th>平均点</th></tr>
                                    <tr v-for="data in backendData.month.table.weekDays[tab.value]"
                                      :key="data"
                                      v-show="data.value > 0">
                                      <th>{{ data.label }}</th>
                                      <td :class="$style.alg_right">{{ data.value }}点</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">性別</div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr>
                                      <th>性別</th><th>平均点</th>
                                    </tr>
                                    <tr v-for="data in backendData.month.table.gender[tab.value]" :key="data">
                                      <th>{{ data.label }}</th>
                                      <td :class="$style.alg_right">{{ data.value || '- ' }}点</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                          <div :class="$style.card">
                            <contents-box
                              type="inner"
                              style="height: 100%;">
                              <div v-if="backendData" :class="$style.graph_contents">
                                <div :class="$style.graph_contents_title">時間</div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th>相談時間帯</th><th>平均点</th></tr>
                                    <tr v-for="data, index in backendData.scheduleTime[tab.value].labels"
                                      :key="data">
                                      <th v-if="index < 3" :class="$style.alg_center">
                                        {{ data }}
                                      </th>
                                      <td v-if="index < 3" :class="$style.alg_right">
                                        {{ backendData.month.table.receptionTime?.[tab.value]?.[index] || '- ' }}点
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </contents-box>
                          </div>
                        </div>
                        <spacer :y="2" />
                        <div :class="$style.wide_contents">
                          <contents-box
                            type="inner">
                            <div v-if="backendData" :class="$style.graph_contents">
                              <div :class="$style.graph_contents_title">相談内容</div>
                              <spacer :y="2" />
                              <div :class="$style.wide_contents_block">
                                <div :class="$style.graph_contents_figure">
                                  <div :class="$style.graph_size_max">
                                    <bar-graph
                                      Axis="y"
                                      :labelsData="backendData.worries[tab.value].labels"
                                      :datasetsData="backendData.worries[tab.value].datasets" />
                                  </div>
                                </div>
                                <div :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th>お悩み</th><th>回数</th></tr>
                                    <!-- <tr v-for="data in onayami.online" :key="data">
                                      <td :class="$style.alg_center">{{ data.label }}</td>
                                      <td>{{ data.data }}回</td>
                                    </tr> -->
                                    <tr v-for="data, index in backendData.worries[tab.value].labels"
                                      :key="data">
                                      <td>
                                        <span :style="`color:${backendData.worries[tab.value].datasets.backgroundColor[index]}`">●</span>
                                        {{ data }}
                                      </td>
                                      <td :class="$style.alg_right">{{ backendData.worries[tab.value].datasets.data[index] }}回</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </contents-box>
                        </div>
                        <spacer :y="2" />
                        <div :class="$style.wide_contents">
                          <contents-box
                            type="inner">
                            <div v-if="backendData" :class="$style.graph_contents">
                              <div :class="$style.graph_contents_title">オンライントスアップ</div>
                              <div :class="$style.graph_contents_block">
                                <div v-if="backendData.tossup[tab.value].users.length" :class="$style.graph_contents_table">
                                  <table>
                                    <tr><th>人数</th><td :class="$style.alg_right">{{ backendData.tossup[tab.value].transferOnline }}人</td></tr>
                                    <tr><th colspan="2">ID</th></tr>
                                    <tr v-for="data, index in backendData.tossup[tab.value].users" :key="data">
                                      <td colspan="2" :class="$style.alg_center"><span style="color: gray;">{{ index + 1 }}.　</span>{{ data }}</td>
                                    </tr>
                                  </table>
                                </div>
                                <div v-else>選択の期間内にトスアップはありませんでした</div>
                              </div>
                            </div>
                          </contents-box>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="['online', 'onlineOnly', 'virtualOnly'].includes(tab.value)">
                      <p>選択の期間内に該当するデータがありません</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </contents-box>
          <!-- <spacer :y="2" />
          <contents-box>
            <div :class="$style.wide_contents">
              <div v-if="symptoms">
                <div v-for="tab in analytics_tabs" :key="tab">
                  <div v-if="tab.check">
                    <contents-box
                      type="inner">
                      <div v-if="symptoms.length" :class="$style.graph_contents_symptoms">
                        <div :class="$style.graph_contents_title">暫定診断</div>
                        <div :class="$style.graph_contents_symptomsTable">
                          <table>
                            <thead>
                              <tr>
                                <th>大分類</th>
                                <th :class="$style.symptoms_number">人数</th>
                                <th>中分類（内訳）</th>
                                <th :class="$style.symptoms_number">人数</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="data in symptoms">
                                <tr v-for="(detail, index) in data.detail" :key="detail.label">
                                  <template v-if="index === 0">
                                    <td :rowspan="data.detail.length" :class="$style.alg_left_plus_space">{{ data.label }}</td>
                                    <td :rowspan="data.detail.length" :class="$style.alg_right">{{ data.count }}人</td>
                                  </template>
                                  <td :class="$style.alg_left_plus_space">{{ detail.label }}</td>
                                  <td :class="$style.alg_right">{{ detail.count }}人</td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div v-else>暫定診断のデータはありません</div>
                    </contents-box>
                  </div>
                </div>
              </div>
            </div>
          </contents-box> -->
        </loader-simple>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ContentsBox from '@/views/components/ContentsBox.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';
import DateSmallPicker from '@/views/components/DateSmallPicker.vue';
import DonutGraph from '@/views/components/DonutGraph.vue';
import PieGraph from '@/views/components/PieGraph.vue';
import BarGraph from '@/views/components/BarGraph.vue';
import MixGraph from '@/views/components/MixGraph.vue';
import AnalyticsCommon from '@/views/components/AnalyticsCommon.vue';
import GuideIcon from '@/views/components/GuideIcon.vue';
import moment from 'moment';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'analyticsSelect',
  mixins: [cf],
  components: {
    ContentsBox,
    BasicBtn,
    LoaderSimple,
    Spacer,
    DateSmallPicker,
    DonutGraph,
    PieGraph,
    BarGraph,
    MixGraph,
    AnalyticsCommon,
    GuideIcon,
  },
  data() {
    return {
      flag: {
        loader: true,
        doneTextAnalytics: false,
        doneReserveAnalytics: false,
      },
      startDate: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
      // startDate: moment('2023-02-1').format('YYYY-MM-DD'), // 検証用
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      // endDate: moment('2023-02-28').format('YYYY-MM-DD'), // 検証用
      account_types: [],
      school_types: [],
      targetSchool: 0,
      targetArea: 0,
      backendData: null,
      usedTextAve: null,
      usedOnlineAve: null,
      usedOnlineOnlyAve: null,
      usedVirtualOnlyAve: null,
      countAnalytics: null,
      symptoms: null,
      schools: [],
      // addQuery: [],
      accountsData: [],
      queryData: {
        startDate: [],
        endDate: [],
        school_types: [],
        account_types: [],
        targetArea: [],
      },
      analytics_tabs: [
        {
          label: 'テキスト',
          value: 'text',
          check: true,
        },
        {
          label: 'オンライン・バーチャル',
          value: 'online',
          check: false,
        },
        {
          label: 'オンライン',
          value: 'onlineOnly',
          check: false,
        },
        {
          label: 'バーチャル',
          value: 'virtualOnly',
          check: false,
        },
      ],
      tabBackgroundColor: 'rgb(116, 176, 191)',
      areas: [
        '北海道',
        '東北',
        '関東',
        '中部',
        '近畿',
        '中国',
        '四国',
        '九州沖縄',
      ],
      accountTypeItems: [
        {
          name: 'analyticsUser',
          label: '在学生',
          value: 1,
          checked: false,
        },
        {
          name: 'analyticsParent',
          label: '保護者',
          value: 2,
          checked: false,
        },
        {
          name: 'analyticsTeacher',
          label: '教職員',
          value: 3,
          checked: false,
        },
      ],
      schoolTypeItems: [
        {
          name: 'analyticsElementary',
          label: '小学校',
          value: 1,
          checked: false,
        },
        {
          name: 'analyticsJuniorHigh',
          label: '中学校',
          value: 2,
          checked: false,
        },
        {
          name: 'analyticsHigh',
          label: '高校',
          value: 3,
          checked: false,
        },
        {
          name: 'analyticsUniv',
          label: '専門・短大・大学',
          value: 4,
          checked: false,
        },
        {
          name: 'analyticsFree',
          label: 'フリースクール',
          value: 5,
          checked: false,
        },
        {
          name: 'personal',
          label: '個人向け',
          value: 0,
          checked: false,
        },
      ],
      exclusionCancel: false,
      analyzedTextData: [],
      analyzedReserveData: [],
      targetCommonData: null,
      targetTextData: null,
      targetReserveData: null,
      onayami: {
        text: [],
        online: [],
      },
    };
  },
  watch: {
    startDate: {
      handler() {
        if (!this.startDate) {
          const { startDate, ...queryWithoutStartDate } = this.$route.query;
          this.$router.replace({ query: queryWithoutStartDate }, () => {});
        }
      },
      deep: true,
    },
    endDate: {
      handler() {
        if (!this.endDate) {
          const { endDate, ...queryWithoutEndDate } = this.$route.query;
          this.$router.replace({ query: queryWithoutEndDate }, () => {});
        }
      },
      deep: true,
    },
    account_types: {
      handler(newVal) {
        // 配列に新しい要素が追加された場合、クエリパラメータを更新する
        if (this.account_types && newVal) {
          const accountTypes = this.account_types.join(',');
          const addQuery = { ...this.$route.query, accountTypes };
          this.$router.push({ query: addQuery }, () => {});
        }
        // 絞り込みのデータがない時パラメータを削除する
        if (!this.account_types.length && !newVal.length) {
          const { accountTypes, ...queryWithoutAccountTypes } = this.$route.query;
          this.$router.replace({ query: queryWithoutAccountTypes }, () => {});
        }
      },
      deep: true,
    },
    school_types: {
      handler(newVal) {
        // 配列に新しい要素が追加された場合、クエリパラメータを更新する
        if (this.school_types && newVal) {
          const schoolTypes = this.school_types.join(',');
          const addQuery = { ...this.$route.query, schoolTypes };
          this.$router.push({ query: addQuery }, () => {});
        }
        // 絞り込みのデータがない時パラメータを削除する
        if (!this.school_types.length && !newVal.length) {
          const { schoolTypes, ...queryWithoutSchoolTypes } = this.$route.query;
          this.$router.replace({ query: queryWithoutSchoolTypes }, () => {});
        }
      },
      deep: true,
    },
    targetArea: {
      handler(newVal) {
        if (newVal !== 0) {
          const targetArea = newVal;
          const query = { ...this.$route.query };
          if (query.targetArea !== targetArea) {
            query.targetArea = targetArea;
            this.$router.push({ query });
          }
        } else {
          const query = { ...this.$route.query };
          if (query.targetArea) {
            delete query.targetArea;
            this.$router.push({ query });
          }
        }
      },
      immediate: true,
    },
    targetSchool: {
      handler(newVal) {
        if (newVal !== null) {
          const targetSchool = newVal;
          const query = { ...this.$route.query };
          if (query.targetSchool !== targetSchool) {
            query.targetSchool = targetSchool;
            this.$router.push({ query });
          }
        } else {
          const query = { ...this.$route.query };
          if (query.targetSchool) {
            delete query.targetSchool;
            this.$router.push({ query });
          }
        }
      },
      immediate: true,
    },
  },
  async created() {
    if (window.performance) {
      /**
       * ページのロードのタイプによって処理を分ける
       * 意図的にリロードされたときは絞り込み条件を全てクリアして
       * デフォルトの期間のみが設定された状態のデータを表示する
       * console.log(window.performance.navigation.type);
       * 0 : ブラウザで検索orリンクのクリック
       * 1 : リロード
       * 2 : 履歴から
       */
      if (window.performance.navigation.type === 1) {
        await this.reloadClearFilter();
      }
    }

    this.updateType();
    this.receiveDate();
    this.updateArea();
    this.updateSchool();
    this.getAccounts();
    this.getSchools();
  },
  computed: {
    ...mapState(['helper', 'user']),
    targetAccountType() {
      let array = [];
      let all = true;
      this.accountTypeItems.forEach((row) => {
        if (row.checked) {
          all = false;
          array.push(row.value);
        }
      });
      if (all) array = [1, 2, 3];
      return array;
    },
    targetSchoolType() {
      let array = [];
      let all = true;
      this.schoolTypeItems.forEach((row) => {
        if (row.checked) {
          all = false;
          array.push(row.value);
        }
      });
      if (all) array = [0, 1, 2, 3, 4, 5];
      return array;
    },
    allFilter() {
      const result = {
        start: this.startDate,
        end: this.endDate,
        account_types: this.account_types,
        school_types: this.school_types,
        targetSchool: this.targetSchool,
        targetArea: this.targetArea,
      };
      return result;
    },
  },
  methods: {
    // 配列の合計を計算
    calcSum(arr) {
      let result = 0;
      arr.forEach((data) => {
        result += data;
      });
      return result;
    },

    // 配列の平均値を計算、第二引数は小数点以下何桁まで出すかを指定
    calcAve(arr, digit = 1) {
      let result;
      if (arr.length <= 1) result = arr[0];
      else {
        const rowAve = arr.reduce((a, b) => a + b) / arr.length;
        result = Math.round(rowAve * 10 ** digit) / 10 ** digit;
      }

      return result;
    },

    // 加重平均を返す
    calcWeightAve(arr1, arr2) {
      if (!arr1?.length || !arr2?.length) return 0;
      const result = Math.round((arr2.reduce((sum, elem) => sum + elem) / arr1.reduce((sum, elem) => sum + elem)) * 10 ** 2) / 10 ** 2;
      return result;
    },

    calcObjectSum(obj) {
      let result = 0;
      Object.keys(obj).forEach((data) => {
        result += obj[data];
      });
      return result;
    },

    // ページがリロードで描画された際に検索条件を全てクリア
    reloadClearFilter() {
      const conditions = [
        'startDate',
        'endDate',
        'accountTypes',
        'schoolTypes',
        'targetSchool',
        'targetArea',
        'targetType',
        'targetCount',
        'targetRange',
        'sortedOrder',
        'displayRange',
      ];

      const result = this.$route.query;
      conditions.forEach((condition) => {
        delete result[condition];
      });
      this.$router.push({ tab: 'analyticsselect' });
    },

    // ユーザ数の取得
    getAccounts(data) {
      if (!data) {
        this.accountsData = null;
        return true;
      }
      const params = {
        graphRange: data.graphRange,
        sortedCriteria: data.sortedCriteria,
        includeInactive: data.includeInactive,
      };
      this.axios({
        method: 'GET',
        url: '/v1/analytics/get/accounts',
        params,
      })
        .then((response) => {
          this.accountsData = response.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    startAnalytics() {
      this.$refs.analyticsCommon.sendTrend();
    },
    /** アナリティクスデータの取得 */
    getAnalytics(data) {
      // 初期化
      // this.flag.loader = true;
      this.backendData = null;

      const params = {
        start: this.startDate,
        end: this.endDate,
        account_types: this.account_types,
        school_types: this.school_types,
        targetSchool: this.targetSchool,
        targetArea: this.targetArea,
        displayTrend: data.displayTrend,
        sortedTrend: data.sortedTrend,
        exclusionCancel: this.exclusionCancel,
      };
      this.axios({
        method: 'GET',
        url: '/v1/analytics/get/data',
        params,
      })
        .then((response) => {
          this.backendData = response.data;
          // 平均利用回数を小数第１位まで表示
          this.usedTextAve = (this.backendData.counselingType.table[3].value / this.backendData.month.table.userCount.text).toFixed(1);
          this.usedOnlineAve = (this.backendData.counselingType.table[4].value / this.backendData.month.table.userCount.online).toFixed(1);
          this.usedOnlineOnlyAve = (this.backendData.counselingType.table[5].value / this.backendData.month.table.userCount.onlineOnly).toFixed(1);
          this.usedVirtualOnlyAve = (this.backendData.counselingType.table[6].value / this.backendData.month.table.userCount.virtualOnly).toFixed(1);
          // バックエンドからは全ての暫定診断をとってきているが、値が０のラベルを除いて配列を再形成
          const symptomsData = response.data.diagnoses;
          const filteredData = symptomsData
            .map((item) => ({
              ...item,
              detail: item.detail.filter((detail) => detail.count >= 1).sort((a, b) => b.count - a.count),
            }))
            .filter((item) => item.detail.length > 0)
            .sort((a, b) => b.count - a.count);
          this.symptoms = filteredData;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
      // 最終的には不要になる
    },
    getSchools() {
      this.axios({
        method: 'GET',
        url: '/v1/school/get/list',
        params: {
          schoolTypes: this.targetSchoolType,
          isUsers: false,
        },
      })
        .then((response) => {
          const res = response.data.data;
          res.data.forEach((data, index) => {
            if (data.id === 13 || data.id === 15) {
              res.data.splice(index, 1);
            }
          });
          this.schools = res.data;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.loader = false;
        });
    },

    // グラフタブ切り替え
    toggleTab(args) {
      if (args === 'text') this.tabBackgroundColor = 'rgb(116, 176, 191)';
      else if (['online', 'onlineOnly'].includes(args)) this.tabBackgroundColor = '#e7a223';
      else if (args === 'virtualOnly') this.tabBackgroundColor = '#60c192';

      this.analytics_tabs.forEach((tab) => {
        if (tab.value === args) {
          if (tab.check === false) {
            tab.check = !tab.check;
          }
        } else {
          tab.check = false;
        }
      });
    },

    updateSchool(e) {
      if (e) {
        this.targetSchool = Number(e.target.value);
        if (!Number(e.target.value)) this.targetSchool = null;
      }
      const query = this.$route.query;
      // アナリティクスを開いている時のみパラメータ付与
      if (query.tab === 'analyticsselect' && !e) {
        if (query.targetSchool) {
          this.targetSchool = Number(query.targetSchool);
        }
      }
    },

    /** タイプによる絞り込み(アカウント・学校) */
    updateType(e, name) {
      const allQuery = this.$route.query;
      if (allQuery.accountTypes && !name) {
        const accountQuery = allQuery.accountTypes.split(',');
        const queryNumber = accountQuery.map((str) => parseInt(str, 10));
        if (queryNumber && !name) {
          for (let i = 0; i < queryNumber.length; i += 1) {
            this.accountTypeItems.forEach((row) => {
              if (queryNumber[i] === row.value) {
                row.checked = true;
                this.account_types.push(row.value);
              }
            });
          }
        }
      }
      if (allQuery.schoolTypes && !name) {
        const schoolQuery = allQuery.schoolTypes.split(',');
        const queryNumber = schoolQuery.map((str) => parseInt(str, 10));
        if (queryNumber && !name) {
          for (let i = 0; i < queryNumber.length; i += 1) {
            this.schoolTypeItems.forEach((row) => {
              if (queryNumber[i] === row.value) {
                row.checked = true;
                this.school_types.push(row.value);
              }
            });
          }
        }
      }
      if (name) {
        this[`${name}TypeItems`].forEach((row) => {
          if (e.target.id === row.name) row.checked = e.target.checked;
        });

        if (name === 'school') {
          this.targetSchool = 0;
          this.getSchools();
        }

        // this.account_typesおよびthis.school_types更新
        const targetArray = this[`${name}_types`];
        const tgt = e.target;
        const value = Number(tgt.value);
        if (tgt.checked) { // 追加
          targetArray.push(value);
        } else { // 削除
          const index = targetArray.indexOf(value);
          targetArray.splice(index, 1);
        }
      }
    },
    /** 期間の始点と終点取得 */
    receiveDate(args) {
      const query = this.$route.query;
      // アナリティクスを開いている時のみパラメータ付与
      if (query.tab === 'analyticsselect') {
        // 期間の初期値の始点と終点を付与
        if (!query.startDate && !query.endDate && !args) {
          const addQuery = { ...this.$route.query, startDate: this.startDate, endDate: this.endDate };
          this.$router.push({ query: addQuery }, () => {});
          // this.$router.push('/operator/');
        }
        // 始点のクエリデータを絞り込みに反映
        if (query.startDate && !args) {
          this.startDate = query.startDate;
        }
        // 終点ののクエリデータを絞り込みに反映
        if (query.endDate && !args) {
          this.endDate = query.endDate;
        }
        if (args) {
          if (args.date === 'Invalid date') {
            this[args.name] = null;
          } else {
            this[args.name] = args.date;
            const addQuery = { ...this.$route.query, [args.name]: args.date };
            this.$router.push({ query: addQuery }, () => {});
          }
        }
      }
    },
    updateArea() {
      const query = this.$route.query;
      // アナリティクスを開いている時のみパラメータ付与
      if (query.tab === 'analyticsselect') {
        if (query.targetArea) {
          this.targetArea = query.targetArea;
          const targetArea = this.targetArea;
          const addQuery = { ...this.$route.query, targetArea };
          this.$router.push({ query: addQuery }, () => {});
        }
      }
    },

    searchSchool() {
      const array = [];
      this.schools.forEach((row) => {
        if (this.targetSchoolType.includes(row.school_type)) {
          array.push(row);
        }
      });
      this.schools = array;
    },

    calcCustomerAge(target, birth) {
      const birthDate = moment(birth);
      const targetDate = moment(target);
      const age = targetDate.year() - birthDate.year();
      if (!age) return 'エラー';
      const thisYearsBirthDay = `${targetDate.year()}-${birthDate.month() + 1}-${birthDate.date()}`;
      const thisYearsBirthDate = moment(thisYearsBirthDay);
      const calcAge = age + ((thisYearsBirthDate.isAfter(targetDate)) ? -1 : 0);
      return calcAge;
    },
  },
};
</script>

<style lang="scss" module>
.spacious {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  @media screen and (max-width: 1080px) {
    max-width: 100%;
    width: 100%;
  }
}
.outer_flex {
  display: flex;
  &_left {
    flex-grow: .5;
    height: 100%;
    max-width: 340px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 10;
    top: 87px; // ヘッダーの高さが77pxなので余白として+10
    width: 29%;
    @media screen and (max-width: 1080px) {
      max-width: 100%;
      width: 100%;
      position: relative;
      top: 0;
    }
  }
  &_right {
    flex-grow: 9.5;
    width: 71%;
    @media screen and (max-width: 1080px) {
      width: 100%;
  }
  }
  @media screen and (max-width: 1080px) {
    display: block;
  }
}
.search_period {
  display: flex;
  &_box {
    width: 150px;
    flex-grow: 1;
    &_bar {
      padding: 8px;
    }
  }
}
.search_frame {
  &_title {
    margin-bottom: 8px;
  }
  &_contents {
    max-width: 300px;
    @media screen and (max-width:1080px) {
      max-width: 100%;
    }
  }
}
.select {
  margin-left: -10px;
  display: flex;
  flex-wrap: wrap;
  &_elem {
    margin-left: 10px;
  }
  &_box {
    padding: 13px;
    width: 100%;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
    border-radius: 8px;
    appearance: none;

    &_wrap {
      display: flex;

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
.search_tab {
  margin-left: 1em;
}
.tab_list {
  display: flex;
  li {
    &:not(:first-child) {
      margin-left: 8px;
    }
  }
  li:hover {
    transition: .3s all;
    color: white;
    background-color: var(--primary-color);
    &.tab_text {
      background-color: rgb(116, 176, 191);
    }
    &.tab_onlineOnly {
      background-color: #e7a223;
    }
    &.tab_virtualOnly {
      background-color: #60c192;
    }
  }
}
.not_check {
  padding: 3px 10px;
  background-color: white;
  border-radius: 5px 5px 0 0;
  box-shadow: 0px 1px 4.45px 0.55px rgb(208 208 208 / 48%);
}
.check_tab {
  padding: 3px 10px;
  color: #333333;
  background-color: v-bind(tabBackgroundColor);
  border-radius: 5px 5px 0 0;
}
.cursor_poiter {
  cursor: pointer;
}
.who5_analytics {
  text-align: center;
  &_pie {
    &_common {
      margin: 0 auto;
      background-color: rgb(129, 213, 82);
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }
    &_text {
      margin: 0 auto;
      background-color: rgb(116, 176, 191);
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }
    &_online {
      margin: 0 auto;
      background-color: rgb(221, 165, 66);
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }
  }
}
.average {
  &_label {
    background: white;
    display: inline-block;
    margin-top: 50px;
    font-weight: bold;
    padding: 0 5px;
  }
  &_score {
    color: white;
    font-size: 2em;
    font-weight: bold;
  }
}
.card {
  margin-top: 10px;
  width: 32.5%;
  &_container {
    margin-top: -10px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    @media screen and (max-width:927px) {
      margin-left: -13px;
    }
    @media screen and (max-width:764px) {
      margin-left: 0;
    }
  }
  @media screen and (max-width:927px) {
    margin-left: 13px;
    width: 47%;
  }
  @media screen and (max-width:764px) {
    width: 100%;
    margin-left: 0;
  }
}
.card_container:after {
  content: '　';
  width: 31%;
  margin: 8px;
}
.graph_contents {
  &_title {
    padding: 3px;
    font-size: 1.1em;
    font-weight: 500;
    small {
      display: flex;
      font-size: 12px;
      align-items: center;
      span {
        display: block;
        height: 1em;
      }
    }
  }
  &_figure {
    &_equality {
      flex-grow: 1;
    }
  }
  &_table {
    max-width: 400px;
    flex-grow: 1;
    display: flex;
    margin: 15px auto;
    font-size: .8em;
    table {
      table-layout: fixed;
      width: 100%;
    }
    tr {
      background-color: #f8f8f8;;
      max-height: 20px;
    }
    tr:nth-child(odd) {
      background-color: var(--gray-sub);
    }
  }
  &_symptoms {
    width: 100%;
  }
  &_symptomsTable {
    max-width: 800px;
    flex-grow: 1;
    display: flex;
    margin: 15px auto;
    font-size: .8em;
    table {
      table-layout: fixed;
      width: 100%;
    }
    tr {
      background-color: #f8f8f8;;
      max-height: 20px;
    }
    tr:nth-child(odd) {
      background-color: var(--gray-sub);
    }
    .td {
      max-width: 10%;
    }
  }
}

.symptoms_number {
  width: 10%;
}

.alg {
  &_left {
    text-align: left;
    &_plus_space {
      padding-left: 1em;
    }
  }
  &_right {
    text-align: right;
    padding-right: .5em;
  }
  &_center {
    text-align: center;
  }
}
.graph_size {
  &_l, &_m, &_s {
    margin: 0 auto;
  }
  &_max {
    width: 100%;
  }
  &_l {
    width: 70%;
  }
  &_m {
    width: 50%;
  }
  &_s {
    width: 30%;
  }
}
.wide_contents {
  margin-bottom: 10px;
  &_block {
    display: flex;
    @include md-view {
      display: block;
    }
  }
}
</style>

<template>
  <div>
    <spacer :y="6"/>
    <loader-simple :flag="flag.loader">
      <div v-show="!flag.loader">

        <ul>
          <contents-box>
            <loader-simple :flag="flag.isOnline">
              <div v-if="reserves.length">
                <stack-item
                  space="2"
                  v-for="row in reserves" :key="row.id">
                  <div :class="$style.time">
                    <i class="fa-regular fa-clock"/>
                    <p>{{ formatTimestamp(row.schedule.date, `YYYY年MM月DD日（${getWeekDayLabel(row.schedule.date, 'short')}）HH:00`) }}</p>
                    <div :class="$style.counseling_wrap">
                      <i
                        v-if="reserveCounselingTypes[row.id] === 1"
                        class="fa-regular fa-laptop"
                        :class="[
                          $style.counseling_icon,
                          $style.online,
                        ]"
                      ><i :class="$style.counseling_icon_inner" class="fa-regular fa-user-headset"/></i>
                      <i
                        v-if="isVirtual(row.id)"
                        class="fa-solid fa-vr-cardboard"
                        :class="[
                          $style.counseling_icon,
                          $style.virtual,
                        ]"
                      ></i>
                    </div>
                  </div>
                  <loader-simple :flag="loadingIds.includes(row.id) && !openflags.includes(row.id)">
                    <user-box
                      :user="row.fromUser"
                      :showCocomoniBtn="true"
                      :ownUser="user"
                      :bg="userBoxColor(row.fromUser)"
                      :showId="true"
                      :link="`/teacher/history/${row.fromUser.id}`"
                      :targetBlank="true"
                      type="inner"
                      saveTab="reserve">
                      <template v-slot:btn>
                        <div :class="$style.icon_wrap">
                          <i
                            class="fa-solid"
                            :class="[$style.icon, {
                              'fa-plus': !openIds.includes(row.id),
                              'fa-minus': openIds.includes(row.id),
                            }]"
                            v-on:click="changeOpenIds(row.id)" />
                        </div>
                      </template>
                      <template v-slot:main>
                        <div v-if="openIds.includes(row.id) && openflags.includes(row.id)">
                          <spacer :y="3"/>
                          <ul :class="$style.detail">
                            <li>
                              <label-box label="担当" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <p :class="$style.content">{{ row.toUser.username }}（{{ row.toUser.account_type === 21 ? '医師' : '心理士' }}）</p>
                            </li>
                            <li v-if="row.meeting">
                              <label-box label="URL" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <p :class="$style.content"><a :href="row.meeting.join_url" target="_blank">{{ row.meeting.join_url }}</a></p>
                            </li>
                            <li v-if="isVirtual(row.id)">
                              <label-box label="URL" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <p :class="$style.content"><a :href="getVirtualCastMedicalRooomURL(row.toUser.virtualCastProp.virtual_cast_room_key)" target="_blank">{{ getVirtualCastMedicalRooomURL(row.toUser.virtualCastProp.virtual_cast_room_key) }}</a></p>
                            </li>
                            <li v-if="isVirtual(row.id) && row.fromUser.student">
                              <label-box label="VC ID" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <div>
                                <p :class="$style.content">{{ row.fromUser.virtualCastProp.virtual_cast_id }}</p>
                                <p :class="$style.virtualcast_url"><a :href="getVirtualCastUserURL(row.fromUser.virtualCastProp.virtual_cast_id)" target="_branc">{{ getVirtualCastUserURL(row.fromUser.virtualCastProp.virtual_cast_id) }}</a></p>
                              </div>
                            </li>
                            <li>
                              <label-box label="お悩み" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <div :class="[$style.value, $style.type]">
                                <p
                                  v-for="typeNum in row.type"
                                  :key="typeNum">{{ helper.master.labels.reserve.type[typeNum] }}<span v-if="row.other_text && typeNum === 6">（{{ row.other_text }}）</span></p>
                              </div>
                            </li>
                            <li v-if="row.memos.customer.length">
                              <label-box label="自由欄" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <p :class="$style.content">{{ row.memos.customer[0].memo }}</p>
                            </li>
                            <li>
                              <label-box label="ココモニ" color="gray" size="sm" :class="$style.label" smAlign="left"/>
                              <div :class="$style.content">
                                <ul :class="$style.who5">
                                  <li v-for="(item, i) in row.who5Answer.answers" :key="item">
                                    <p>{{ helper.master.who5.items[i].label }}</p>
                                    <p :class="$style.who5_answer">{{ helper.master.who5.labels[item] }}</p>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="n2br">
                              <label-box label="コメント" color="gray" size="sm" :class="$style.label"/>
                              <p
                                :class="$style.content" v-if="row.memos.teacher && row.memos.teacher.length"
                                v-html="row.memos.teacher[0].memo"></p>
                              <p :class="$style.content" v-else>登録なし</p>
                            </li>
                          </ul>
                          <spacer :y="2"/>
                          <btn-container v-if="row.flag === 1">
                            <basic-btn
                              size="sm"
                              :to="getTeacherReserveURL(row.id)">
                              先生に伝える</basic-btn>
                            <spacer :x="2"/>
                            <basic-btn
                              size="sm"
                              tag="button"
                              addClass="danger"
                              @click="cancelReserve(row)">キャンセルする</basic-btn>
                          </btn-container>
                        </div>
                      </template>
                    </user-box>
                  </loader-simple>
                </stack-item>
              </div>
              <p v-if="!flag.isOnline && !reserves.length">現在、オンライン健康相談の予約はありません。</p>
            </loader-simple>
          </contents-box>
        </ul>
      </div>
    </loader-simple>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import UserBox from '@/views/components/UserBox.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'teacher-reserve',
  mixins: [cf],
  components: {
    StackItem,
    ContentsBox,
    UserBox,
    Spacer,
    LabelBox,
    LoaderSimple,
    BtnContainer,
    BasicBtn,
  },
  data() {
    return {
      flag: {
        loader: true,
        isOnline: true,
      },
      openflags: [],
      openIds: [],
      loadingIds: [],
      dateSortedReserves: {},
      reserves: [],
      reserveCounselingTypes: {}, // key: reserve_id, value: counseling_type
    };
  },
  created() {
    this.getReserves();
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  methods: {
    // 予約を取得(軽量メソッド)
    async getReserves() {
      const params = {
        school_id: this.user.school[0].id,
        flags: [1],
        limit: 100,
        page: 1,
      };
      this.axios({
        method: 'GET',
        url: '/v2/reserve/get/minimumList',
        params,
      })
        .then((response) => {
          const res = response.data.result;
          this.reserves = cf.upSortReserves(res.reserve);
          // 取得直後のデータで、それぞれの予約がオンライン/バーチャルどちらの健康相談なのかを控えておく（+アイコンクリック時にgetDetailによってreestve,.typeが上書きされてしまうことへのケア）
          this.setReserveCounselingType();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.loader = false;
          this.flag.isOnline = false;
        });
    },
    setReserveCounselingType() {
      this.reserves.forEach((reserve) => {
        this.reserveCounselingTypes[reserve.id] = reserve.type.counseling_type;
      });
    },
    isVirtual(reserveId) {
      return this.reserveCounselingTypes[reserveId] === 3;
    },
    // 予約の詳細情報を取得
    async getReserveDetail(id) {
      const params = {
        id,
        isRisk: 1,
        counseling_type: this.reserveCounselingTypes[id],
      };
      await this.axios({
        method: 'GET',
        url: '/v1/reserve/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data.reserve;
          this.openflags.push(id);
          this.reserves.forEach((elem, i) => {
            if (elem.id === res.id) this.reserves[i] = res;
          });
        })
        .catch((error) => {
          if (error.message) console.log(error);
          else console.log(error);
        });
    },
    dateSortReserves() {
      if (this.user.counseling.reserves.length) {
        const reserves = cf.upSortReserves(this.user.counseling.reserves);
        const len = reserves.length - 1;
        reserves.forEach((row, i) => {
          if (row.flag === 1) {
            const date = this.formatTimestamp(row.schedule.date, 'YYYY/MM/DD');
            if (this.dateSortedReserves[date]) {
              this.dateSortedReserves[date].push(row);
            } else {
              this.dateSortedReserves[date] = [];
              this.dateSortedReserves[date].push(row);
            }
          }
          if (len === i) this.flag.loader = false;
        });
      } else {
        this.flag.loader = false;
      }
    },
    async changeOpenIds(id) {
      if (this.openIds.includes(id)) {
        const idx = this.openIds.indexOf(id);
        this.openIds.splice(idx, 1);
      } else {
        this.loadingIds.push(id);
        this.openIds.push(id);
        this.getReserveDetail(id);
      }
    },

    /** 学校管理者のキャンセル処理 */
    cancelReserve(reserve) {
      const args = {
        modalName: 'reserveCancel',
        data: {
          reserve_id: reserve.id,
          counseling_type: this.reserveCounselingTypes[reserve.id],
          schedule: reserve.schedule,
        },
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: null });
    },

    /** userBox背景色指定用 */
    userBoxColor(user) {
      let color;
      if (user && user.risk && user.risk.length && user.risk[user.risk.length - 1].type !== 11) {
        color = 'red';
      } else {
        color = 'white';
      }
      return color;
    },

    getTeacherReserveURL(reserveId) {
      return this.isVirtual(reserveId) ? `/teacher/reserve/virtual/${reserveId}` : `/teacher/reserve/online/${reserveId}`;
    },
  },
};
</script>

<style lang="scss" module>
.label {
  width: 80px;
  margin-right: 20px;
  @include sm-view {
    width: 100%;
  }
}
.user {
  display: flex;
  align-items: center;
}
.img {
  margin-right: 10px;
}
.icon {
  cursor: pointer;
  font-size: 20px;
  color: var(--gray-side);
  &_wrap {
    @include sm-view {
      text-align: right;
    }
  }
}
.detail {
  li {
    display: flex;
    align-items: flex-start;
    .content {
      // font-size: 14px;
      margin-top: 3px;
      flex: 1;
    }
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    @include sm-view {
      display: block;
      font-size: 13px;
    }
  }
}
.cancel {
  margin-left: 5px;
  font-size: 10px;
  color: var(--pink);
}
.types {
  margin-right: 20px;
}
.who5 {
  li {
    justify-content: space-between;
  }
  &_answer {
    font-weight: bold;
  }
}
.time {
  display: flex;
  align-items: center;
  font-weight: bold;
  i {
    font-size: 20px;
    margin-right: 6px;
  }
  div {
    margin-left: 12px;
  }
}
.counseling {
  display: flex;
  align-items: center;
  &_wrap {
    display: flex;
    align-items: center;
  }
  &_icon {
    font-size: 25px !important;
    position: relative;
    &_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
      font-size: 10px !important;
    }
  }
}
.value {
  flex: 1;
  margin-top: 3px;
  &.type {
    display: flex;
    flex-wrap: wrap;
    p {
      white-space: nowrap;
      &:not(:last-child) {
        &::after {
          margin: 0 5px;
          content: '/';
        }
      }
    }
  }
}
.virtualcast_url {
  font-size: 10px;
}
.counseling_icon {
  &.online {
    color: var(--orange-main);
  }
  &.virtual {
    color: var(--green-main);
  }
}
</style>

<template>
  <div>
    <spacer :y="6"/>
    <ul>
      <stack-item>
        <contents-box
          :title="user.school[0].billing_type.billing_type === 1 ? '登録QRコード' : '学校情報'"
          :icon="user.school[0].billing_type.billing_type === 1 ? '<i class=\'fa-regular fa-qrcode\'></i>' : '<i class=\'fa-solid fa-school\'></i>'">
          <loader-simple :flag="flag.loader">
            <ul v-if="school">
              <stack-item space="2">
                <contents-box type="inner">
                  <div :class="$style.school">
                    <div>
                      <div :class="$style.kana">{{ school.kana }}</div>
                      <div :class="$style.name">{{ school.name }}</div>
                    </div>
                  </div>

                  <ul :class="$style.detail">
                    <spacer :y="3"/>
                    <li v-if="user.school[0].billing_type.billing_type === 1">
                      <i :class="$style.icon" class="fa-solid fa-qrcode"></i>
                      <p :class="$style.content"><a :href="`${baseURL}/signup/?school=${school.id}`" target="_blank">{{ baseURL }}/signup/?school={{ school.id }}</a></p>
                    </li>
                    <li v-if="user.school[0].billing_type.billing_type === 1">
                      <p :class="$style.content">
                        <img :src="`https://api.qrserver.com/v1/create-qr-code/?data=${baseURL}/signup?school=${school.id}&size=150x150`">
                      </p>
                    </li>
                    <li v-if="school.school_type && !school.is_company">
                      <i :class="$style.icon" class="fa-solid fa-graduation-cap"></i>
                      <p :class="$style.content">{{ helper.master.labels.schools.school_type[school.school_type] }}</p>
                    </li>
                    <li>
                      <i :class="$style.icon" class="fa-solid fa-file-user"></i>
                      <p :class="$style.content">
                        {{ helper.master.labels.schools.billing_type[school.billing_type.billing_type] }}モデル
                        <span :class="$style.bold" v-if="school.billing_type.billing_type === 1 && school.contract && school.contract.cycle">{{ school.contract.cycle === 1 ? '月次' : '年次' }}</span>
                      </p>
                    </li>
                    <li v-if="school.email">
                      <i :class="$style.icon" class="fa-regular fa-envelope"></i>
                      <p :class="$style.content">{{ school.email }}</p>
                    </li>
                    <li v-if="school.tel">
                      <i :class="$style.icon" class="fa-solid fa-phone"></i>
                      <p :class="$style.content">{{ school.tel }}</p>
                    </li>
                    <li v-if="school.fax">
                      <i :class="$style.icon" class="fa-solid fa-fax"></i>
                      <p :class="$style.content">{{ school.fax }}</p>
                    </li>
                    <li v-if="school.addresses">
                      <i :class="$style.icon" class="fa-regular fa-house"></i>
                      <p :class="$style.content"></p>
                    </li>
                    <li :class="$style.flexstart">
                      <label-box
                        :class="$style.label"
                        label="管理者"
                        size="xs" color="orange" />
                      <ul
                        :class="$style.content_list"
                        v-if="school.mainUser">
                        <li>
                          <span>{{ school.mainUser[0].username }}</span>
                          <span>（<a :href="`mailto:${school.mainUser[0].email}`">{{ school.mainUser[0].email }}</a>）</span>
                        </li>
                      </ul>
                    </li>
                    <li :class="$style.flexstart">
                      <label-box
                        :class="$style.label"
                        label="副管理者"
                        size="xs" color="orange" />
                      <ul :class="$style.content_list">
                        <li v-for="subuser in school.subUser" :key="subuser.id">
                          <span>{{ subuser.username }}</span>
                          <span v-if="subuser.email">（<a :href="`mailto:${subuser.email}`">{{ subuser.email }}</a>）</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </contents-box>
              </stack-item>
            </ul>
          </loader-simple>
        </contents-box>

        <spacer
          :y="3"
          v-if="school.billing_type.billing_type === 2" />
        <contents-box
          title="利用者登録状況"
          icon="<i class='fa-solid fa-user-plus'></i>"
          v-if="school.billing_type.billing_type === 2">
          <loader-simple :flag="flag.countTemporaries">
            <p :class="$style.temporary_info">
              <span :class="$style.temporary_info_label">本登録</span>
              <span :class="$style.temporary_info_num">
                {{ registCount }}<span :class="$style.temporary_info_num_mini"> / {{ registCount + temporaryCount }}</span>
              </span>名
            </p>
            <p :class="$style.temporary_info">
              <span :class="$style.temporary_info_label">未登録</span>
              <span :class="$style.temporary_info_num">
                {{ temporaryCount }}<span :class="$style.temporary_info_num_mini"> / {{ registCount + temporaryCount }}</span>
              </span>名
            </p>
          </loader-simple>
        </contents-box>
        <spacer :y="5" />

        <contents-box title="オンライン・バーチャル健康相談" icon="<i class='fa-solid fa-coins'></i>">
          <div :class="$style.label_wrap">
            <p :class="$style.label">保有クレジット: <span>{{ schoolCredit || 0 }}</span></p>
            <p :class="$style.detailed_explanation" v-if="school.billing_type.billing_type === 1">※医師相談=２クレジット使用</p>
            <div :class="$style.flex_history" v-if="this.school.billing_type.billing_type === 2">
              <p>クレジット編集履歴</p>
              <div>
                <loader-simple :flag="flag.school_count.includes(this.school.id)" :mini="true" :isDisableMinHight="true">
                  <div v-if="!(flag.school_count.includes(this.school.id))">
                    <i
                      v-if="!opensusCreditIds"
                      :class="$style.count"
                      class="fa-solid fa-plus"
                      v-on:click="changeOpensusCreditIds()"/>
                    <i
                      v-if="opensusCreditIds"
                      :class="$style.count"
                      class="fa-solid fa-minus"
                      v-on:click="changeOpensusCreditIds()"/>
                  </div>
                </loader-simple>
              </div>
            </div>
            <spacer :x="2" />

            <div :class="$style.flex_history" v-if="this.school.billing_type.billing_type === 2 && opensusCreditIds">
              <div :class="$style.max_flex" v-if="!susCreditHistorys.length">変更履歴はありません</div>
              <div v-else :class="$style.categories">
                <dl :class="$style.category">
                  <dt v-for="count in susCreditHistorysLabals" :key="count.label">
                    {{ count.label }}
                  </dt>
                </dl>
                <dl :class="$style.category"
                  v-for="history in susCreditHistorys" :key="history">
                  <dd
                    v-for="count in susCreditHistorysLabals"
                    :key="count.label">
                    <p v-if="count.name === 'date'">{{ history.date }}</p>
                    <p v-if="count.name === 'editor'">{{ history.editor }}</p>
                    <p v-if="count.name === 'target'">{{ history.target }}</p>
                    <p v-if="count.name === 'content'">{{ history.content }}</p>
                    <p v-if="count.name === 'counselor_type'">{{ history.counselor_type }}</p>
                    <p v-if="count.name === 'credit'">{{ history.credit }}</p>
                  </dd>
                </dl>
              </div>
            </div>
          </div>

          <div
            v-if="school.billing_type.billing_type === 2
              && schoolCredit > 0">
            <spacer :y="4" />

            <div :class="$style.label_wrap">
              <p :class="$style.label">利用者別にオンライン健康相談の回数を付与</p>
              <p :class="$style.assistance">※医師相談の回数付与は<b>2クレジット</b>、心理士相談は<b>1クレジット</b>を消費します。</p>
              <p :class="$style.assistance">チェックした利用者にまとめてクレジットを付与します。</p>
            </div>
            <spacer :y="3" />
            <limit-counter
              :flag="flag"
              :school="school"
              :sustainableAddCreditHistory="true"
              :creditTarget="creditTargets"
              :isSchoolAdmin="true"
              @updateTarget="regetTargets"
              @updateSchool="updateSchool" />
            <spacer :y="3" />

            <div :class="$style.sortBox">
              <div :class="$style.top">
                <div :class="$style.search_wrap">
                  <word-search-form
                    :class="$style.search_word"
                    v-on:sendValue="receiveValue"
                    :placeholder="isCompany ? '利用者を検索' : '生徒を検索'" />
                  <div :class="$style.search_button">
                    <basic-btn
                      tag="button"
                      @click="getUsers(), flag.exeSearch=true">検索</basic-btn>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!isCompany" :class="$style.birth">
                <spacer :y="2" />
                <dl>
                  <dt><i class="fa-solid fa-graduation-cap"></i>学年</dt>
                  <dd>
                    <div>
                      <select
                        :class="$style.select_box"
                        v-model="schoolYear"
                        name="schoolYear"
                        id="schoolYear"
                        @change="updateSchoolYear">
                        <option :value="null">選択なし</option>
                        <option
                          v-for="n of setSchoolYear()"
                          :key="n"
                          :value="n">{{ n }}</option>
                      </select>
                    </div>
                  </dd>
                </dl>
              </div>
              <spacer :y="2" />
              <div v-if="flag.exeSearch">
                <div>
                  <spacer :y="2"/>
                  <input type="checkbox" id="bulkChek" v-model="bulkChek">
                  <label for="bulkChek">一括チェック</label>
                  <spacer :y="2"/>
                </div>
                <stack-item v-for="row in creditTargets" :key="row.id">
                  <div :class="$style.check_user">
                    <input
                      :class="$style.user_check_box"
                      type="checkbox"
                      :id="`checked_${row.id}`"
                      :value="row.id"
                      v-model="creditTargetIds"
                      @change="updateTargets" />
                    <label
                      :for="`checked_${row.id}`"
                      :class="$style.check_user_box">
                      <user-box
                        :showId="true"
                        :showCocomoniBtn="true"
                        :ownUser="user"
                        v-if="users.length"
                        :user="row"
                        saveTab="students"
                        :bg="userBoxColor(row)">
                      </user-box>
                    </label>
                  </div>
                </stack-item>
                <stack-item v-for="row in filteredUsers" :key="row.id">
                  <div :class="$style.check_user">
                    <input
                      :class="$style.user_check_box"
                      type="checkbox"
                      :id="`stillCheck_${row.id}`"
                      :value="row.id"
                      v-model="creditTargetIds"
                      @change="updateTargets" />
                    <label
                      :for="`stillCheck_${row.id}`"
                      :class="$style.check_user_box">
                      <user-box
                        :showId="true"
                        :showCocomoniBtn="true"
                        :ownUser="user"
                        v-if="users.length"
                        :user="row"
                        saveTab="students"
                        :bg="userBoxColor(row)">
                      </user-box>
                    </label>
                  </div>
                </stack-item>
              </div>
          </div>
        </contents-box>

        <spacer :y="3" v-if="school.billing_type.billing_type === 1" />
        <contents-box
          v-if="school.billing_type.billing_type === 1"
          title="テキスト健康相談"
          icon="<i class='fa-sharp fa-light fa-abacus'></i>">
          <div :class="$style.label_wrap">
            <p :class="$style.label">利用可能回数: <span>{{ schoolTextCount || 0 }}</span></p>
          </div>
        </contents-box>
<!--
        <spacer :y="5" />
        <unregist-school-year
          :school="user.school[0]"/>
-->
      </stack-item>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LabelBox from '@/views/components/LabelBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import UserBox from '@/views/components/UserBox.vue';
import WordSearchForm from '@/views/components/WordSearchForm.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import LimitCounter from '@/views/components/LimitCounter4BillingAccount.vue';
// import UnregistSchoolYear from '@/views/components/UnregistSchoolYear.vue';
import moment from 'moment';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'qr',
  mixins: [cf],
  props: ['school'],
  components: {
    LabelBox,
    Spacer,
    ContentsBox,
    StackItem,
    UserBox,
    WordSearchForm,
    BasicBtn,
    LoaderSimple,
    LimitCounter,
    // UnregistSchoolYear,
  },
  data() {
    return {
      flag: {
        loader: true,
        disabled: true,
        countTemporaries: true,
        initial: true,
        exeSearch: false,
        school_count: [],
      },
      users: [],
      allUsers: [],
      temporaryCount: 0,
      registCount: 0,
      creditTargetId: 0,
      creditTargetIds: [],
      schoolYear: null, // 学年検索機能追加のため
      creditTargets: [],
      opensusCreditIds: false,
      susCreditHistorys: [],
      bulkChek: false,
      registSituation: {
        completion: [],
        temporary: [],
      },
      susCreditHistorysLabals: [ // アカウント課金、クレジット付与の履歴、初期にやる利用可能回数一括調整とは別
        {
          label: '編集日',
          name: 'date',
        },
        {
          label: '編集者',
          name: 'editor',
        },
        {
          label: '対象',
          name: 'target',
        },
        {
          label: '種別',
          name: 'counselor_type',
        },
        {
          label: '内容',
          name: 'content',
        },
        {
          label: 'クレジット数',
          name: 'credit',
        },
      ],
    };
  },
  created() {
    if (this.school) {
      if (this.school.billing_type.billing_type === 1) this.flag.loader = false;
      else if (this.school.billing_type.billing_type === 2) {
        this.getTempcount(this.school);
        this.getUsers();
        this.getSustainableAddCredit();
      }
    }
  },
  watch: {
    school() {
      this.flag.loader = false;
    },
    bulkChek() {
      if (this.bulkChek) {
        this.filteredUsers.forEach((elem) => {
          this.creditTargets.push(elem);
          this.creditTargetIds.push(elem.id);
        });
      } else {
        this.creditTargets = [];
        this.creditTargetIds = [];
      }
      this.updateTargets();
    },
  },
  computed: {
    ...mapState(['user', 'helper']),
    baseURL() {
      return `${location.protocol}//${location.host}`;
    },
    /**
     * 学校の保持クレジット
     *   該当校の課金タイプが
     *     1 => 従量課金: school.count
     *     2 => アカウント課金: school.mainUser[0].count.credit
     */
    schoolCredit() {
      const billingType = this.school.billing_type.billing_type;
      let credit = 0;
      if (billingType === 1) credit = this.school.count;
      else if (billingType === 2) credit = this.school.mainUser[0].count.credit;
      return credit;
    },
    /**
     * 従量課金、テキスト健康相談利用可能回数
     */
    schoolTextCount() {
      const billingType = this.school.billing_type.billing_type;
      let count = 0;
      if (billingType === 1) count = this.school.textCount;
      return count;
    },
    /**
     * アカウント課金
     * 学校に所属する利用者
     */
    schoolCustomers() {
      const result = [];
      this.school.users.forEach((user) => {
        if (user.account_type < 11) result.push(user);
      });
      return result;
    },
    filteredUsers() {
      const targetIds = this.creditTargets.map((item) => item.id);
      return this.users.filter((user) => !targetIds.includes(user.id));
    },
  },
  methods: {
    // masterのデータから該当する学校の学年を取得
    setSchoolYear() {
      if (this.user.school) {
        const school = this.user.school[0];
        const yearIndex = Object.keys(this.helper.master.schoolYear).findIndex((elem) => this.helper.master.schoolYear[elem].label === school.school_type);
        const schoolYear = this.helper.master.schoolYear[yearIndex].value;
        return schoolYear + 2;
      }
    },
    /**
     * アカウント課金
     * 回数追加・削除の有効無効
     * 対象ユーザオブジェクトの仕込み
     */
    updateTargets() {
      // const disabled = !this.creditTargetIds || !Number(this.creditTargetIds);
      // 配列を想定して書き換え
      const disabled = !this.creditTargetIds.length || !this.creditTargetIds.every((id) => typeof id === 'number');
      this.flag.disabled = disabled;

      if (!disabled) {
        this.creditTargets = this.users.filter((user) => this.creditTargetIds.includes(user.id));
      } else {
        this.creditTargets = [];
      }
    },

    // 子コンポーネントから受け取ったデータを格納
    receiveValue(data) {
      const userIdRegex = new RegExp(/^[0-9０-９]{4}(-|ー|−|―|‐|-|ｰ)[0-9０-９]{1,}(-|ー|−|―|‐|-|ｰ)[0-9０-９]{4}$/);
      if (userIdRegex.test(data.value)) {
        const userId = data.value.split(/[-|ー|−|―|‐|-|ｰ]/);
        this.keyword = String(Number(this.toHalfNumber(userId[2]))); // user.id指定
        this.targetColumn = ['id', 'old_id', 'tel']; // 0120-12-3456のtelも引っかかる
      } else {
        this.keyword = data.value;
        this.targetColumn = ['username', 'kana', 'id'];
      }
    },

    /** アカウント課金モデルの場合にはユーザーのリストを取得 */
    async getUsers(payload) {
      let newUsers = [];
      let params = null;
      if (payload && payload.fromChild === true) {
        params = {
          id: this.school.id,
          ids: this.creditTargetIds,
        };
      } else {
        params = {
          id: this.school.id,
          targetColumn: this.keyword && this.keyword !== '' ? this.targetColumn : null,
          keyword: this.keyword && this.keyword !== '' ? this.keyword : null,
          school_year: this.schoolYear,
          noTemporary: true,
        };
      }
      await this.axios({
        method: 'GET',
        url: '/v1/school/get/subject',
        params,
      })
        .then((response) => {
          const res = response.data.subject;
          newUsers = res.users.filter((user) => user.account_type < 11);
          if (this.flag.initial && res.billing_type.billing_type === 2) {
            this.allUsers = res.users.filter((user) => user.account_type < 11);
            this.countTemporaryUser(); // 組織の契約が従量課金であれば仮登録の利用者をカウント
            this.flag.initial = false;
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          // 検索をかけた時に、this.usersから値がなくなってしまうと、this.creditTargetsにも保持できないために追加
          const creditTargetIds = this.creditTargets.map((item) => item.id);
          const newUserIds = newUsers.map((user) => user.id);
          const idsToAdd = creditTargetIds.filter((id) => !newUserIds.includes(id));
          const usersToAdd = this.creditTargets.filter((user) => idsToAdd.includes(user.id));
          newUsers = [...newUsers, ...usersToAdd];
          this.users = newUsers;
          if (newUsers.some((user) => user.billing_type && user.billing_type.billing_type === 2)) {
            this.countTemporaryUser(); // 組織の契約が従量課金であれば仮登録の利用者をカウント
          }
          if (payload && payload.fromChild === true) {
            this.updateTargets();
          }
          this.flag.loader = false;
        });
    },

    // アカウント課金、利用可能回数更新後の回数取得用
    async regetTargets() {
      const params = { // チェックのついている利用者の情報を取得
        ids: [],
      };

      this.creditTargetIds.forEach((id) => {
        params.ids.push(id);
      });

      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params,
      })
        .then((response) => {
          const res = response.data.users.data;
          this.creditTargets = [];
          res.forEach((user) => {
            this.creditTargets.push(user);
          });
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    // アカウント課金の仮登録、本登録の人数を返却
    async getTempcount(school) {
      // 従量課金タイプだったら即返却
      if (school.billing_type.billing_type === 1) return true;

      const params = { school_id: school.id };
      this.axios({
        method: 'GET',
        url: '/v1/user/get/tempCount',
        params,
      })
        .then((response) => {
          const res = response.data.result;
          this.temporaryCount = res.temporary;
          this.registCount = res.regist;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    // 学年で絞り込み
    updateSchoolYear(e) {
      this.schoolYear = Number(e.target.value);
      if (!Number(e.target.value)) this.schoolYear = null;
    },

    /** アカウント課金のみ */
    countTemporaryUser() {
      this.allUsers.forEach((user) => {
        if (user.temporary) this.registSituation.temporary.push(user);
        else this.registSituation.completion.push(user);
      });
      this.flag.countTemporaries = false;
    },

    /** userBox背景色指定用 */
    userBoxColor(user) {
      let color;
      if (user.flag !== 1) {
        color = 'gray';
      } else if (user.temporary) {
        color = 'silver';
      } else {
        color = 'white';
      }
      return color;
    },

    /** limitCounterでカウント付与後、学校情報更新 */
    updateSchool() {
      this.$emit('updateSchool');
    },

    // クレジット変動履歴の表表示フラグ切り替え
    async changeOpensusCreditIds() {
      this.opensusCreditIds = !this.opensusCreditIds;
    },

    /**
     * アカウント課金タイプのオンラインクレジット付与履歴を取得
     * 表示するのは
     * 事務局→学校クレジット
     * 事務局→利用者クレジット
     * 学校→利用者クレジット
    */
    async getSustainableAddCredit() {
      if (this.school.billing_type.billing_type === 1) return true; // 学校タイプが従量課金だったら即返却

      const params = {
        school_id: this.school.id,
        isUsers: 1,
        isSchool: 1,
        isTargetUser: 1,
      };

      this.axios({
        methods: 'GET',
        url: '/v1/sustainableAddCredit/get/list',
        params,
      })
        .then((response) => {
          const res = response.data.data.data;
          res.forEach((data) => {
            const insertData = {
              date: moment(data.created_at).format('YYYY年MM月DD日'),
              editor: data.regist_user.account_type === 31 ? '事務局' : data.regist_user.username,
              target: data.target_user_id ? data.target_user.username : data.school.name,
              content: `${data.credit}クレジット${data.is_add ? '追加' : '削除'}`,
              credit: `${data.credit}クレジット`,
              is_add: data.is_add,
              counselor_type: 'なし',
            };
            if (data.counselor_type) { // カウンセラータイプNULLになるのは事務局→学校のパターンのみ
              insertData.counselor_type = data.counselor_type === 22 ? '心理士' : '医師';
              const count = data.counselor_type === 22 ? data.credit : data.credit / 2; // 回数表記用
              insertData.content = `${count}回${data.is_add ? '追加' : '削除'}`;
            }
            if (data.target_user // 事務局から利用者に直接クレジットを付与したものは表示しない
              && data.regist_user.account_type === 31
              && data.target_user.account_type <= 3) {
              return true;
            }
            this.susCreditHistorys.push(insertData);
          });
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error.message);
        });
    },
  },
};
</script>

<style lang="scss" module>
.flex_history {
  display: flex;
  align-items: center;
  .categories {
    $mgn: 40px;
    $gry: #ccc;
    $bdr: 1px solid $gry;
    $pd: .5em;
    width: 100%;
    .category {
      font-size: .8em;
      padding: $mgn/5 0;
      border-bottom: $bdr;
      display: flex;
      margin-block-start: 0;
      margin-block-end: 0;
      // width: 90%;
      &:first-child {
        border-top: $bdr;
      }
      > dt, dd {
        padding: 0 $pd;
        margin-inline-start: 0;
      }
    > dt {
        width: 45%;
      }
      > dd {
        flex: 1;
      }
      .link {
        margin-left: auto;
        padding-left: 1em;
      }
    }
  }
}
.count {
  margin-left: 1em;
  cursor: pointer;
}

.kana {
  font-size: 12px;
}
.name {
  font-size: 20px;
  font-weight: bold;
}
.school {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icons {
  display: flex;
  align-items: center;
}
.plus {
  cursor: pointer;
  font-size: 20px;
  color: var(--gray-side);
  &:not(:first-child) {
    margin-left: 20px;
  }
}
.detail {
  li {
    display: flex;
    align-items: center;
    @include sm-view {
      display: block;
    }
    &.flexstart {
      align-items: flex-start;
    }
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    .icon {
      font-size: 20px;
      color: var(--orange-main);
      margin-right: 10px;
    }
    .label {
      width: 60px;
      white-space: nowrap;
      margin-right: 10px;
    }
    .content {
      flex: 1;
      &_list {
        flex: 1;
        display: flex;
        li {
          &:not(:last-child) {
            margin-bottom: 0;
            &::after {
              content: '/';
              padding: 0 10px;
            }
          }
        }
      }
    }
  }
  a {
    color: var(--black);
    text-decoration: underline;
    transition: all .4s;
    &:hover {
      opacity: .6;
    }
  }
}
.bold {
  font-weight: bold;
}
.center {
  text-align: center;
}

.label {
  border-radius: 4px;
  margin-right: 4px;
  font-weight: bold;
  span {
    font-size: 28px;
    margin-left: 5px;
  }
  &_flex {
    display: flex;
    align-items: center;
    dt {
      font-weight: bold;
    }
    dd {
      margin-left: .8em;
    }
  }
}

.assistance {
  font-size: 14px;
}

.select {
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;
  &:disabled {
    color: black;
    background-color: transparent;
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }
  }

  &_box {
    width: 100px;
    padding: 13px;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
    border-radius: 8px;
    appearance: none;

    &_wrap {
      display: flex;

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
.check_user {
  display: flex;
  &_box {
    flex: 1;
  }
}

.detailed_explanation{
  font-size: .7em;
}

.temporary_info {
  font-weight: bold;

  &_label {
    margin-left: 2em;
    display: inline-block;
    width: 95px;
  }

  &_num {
    font-size: 1.5em;
    &_mini {
      font-size: 15px;
    }
  }
}
.search_wrap {
  display: flex;
  .search_word {
    flex: 8.5;
  }
  .search_button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1.5;
  }
}
.sortBox {
  > * {
    align-items: center;
  }
  @include sm-view {
    > * {
      display: block;
      .search_wrap {
        width: 100%;
        .search_word {
          margin: 0;
        }
      }
    }
  }
}
.user_check {
  display: flex;
  &_box {
    margin-right: 1em;
  }
  &_user_box {
    flex-grow: 1;
  }
}

.birth {
  flex: 1;
  dl {
    display: flex;
    align-items: center;
    margin: 0;
  }
  dt {
    width: 7em;
    i {
      margin-right: .5em;
    }
  }
  dd {
    flex: 1;
    display: flex;
    margin-left: 1em;
    > div {
      display: flex;
      align-items: center;
      &:nth-child(2) {
        margin-left: 1em;
        &:before {
          content: "~";
          margin-right: 1em;
        }
      }
    }
  }
  @include sm-view {
    dl {
      display: block;
      margin: 1em 0 .4em;
    }
    dt {
      font-size: 14px;
    }
    dd {
      margin: 0;
    }
  }
}
</style>

<template>
  <div v-if="reserve && helper">
    <spacer :y="5"/>
    <ul>
      <stack-item>
        <contents-box :title="title">
          <user-box :showId="true" type="inner" :user="reserve.fromUser">
            <template v-slot:main>
              <div>
                <spacer :y="3"/>
                <ul :class="$style.detail">
                  <li>
                    <label-box label="担当" color="gray" size="sm" :class="$style.userlabel"/>
                    <p :class="$style.content">{{ reserve.toUser.username }}（{{ reserve.toUser.account_type === 21 ? '医師' : '心理士' }}）</p>
                  </li>
                  <li>
                    <label-box label="実施日" color="gray" size="sm" :class="$style.userlabel"/>
                    <p :class="$style.content">{{ formatTimestamp(reserve.schedule.date, 'YYYY/MM/DD HH:00') }}</p>
                  </li>
                  <li>
                    <label-box label="お悩み" color="gray" size="sm" :class="$style.userlabel"/>
                    <p :class="$style.content">
                      <span
                        v-for="type in reserve.type"
                        :key="type"
                        :class="$style.types">{{ helper.master.labels.reserve.type[type] }}</span>
                    </p>
                  </li>
                  <li v-if="reserve.memos.customer.length">
                    <label-box label="自由欄" color="gray" size="sm" :class="$style.userlabel"/>
                    <p :class="$style.content">{{ reserve.memos.customer[0].memo }}</p>
                  </li>
                  <li>
                    <label-box label="ココモニ" color="gray" size="sm" :class="$style.userlabel"/>
                    <div :class="$style.content">
                      <ul :class="$style.who5">
                        <li v-for="(item, i) in helper.master.who5.items" :key="item">
                          <p>{{ item.label }}</p>
                          <p>{{ helper.master.who5.labels[reserve.who5Answer.answers[i]] }}</p>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li class="n2br">
                    <label-box :label="isCompany ? '企業コメント' : '学校コメント'" color="gray" size="sm" :class="$style.userlabel" />
                    <p
                      v-if="reserve.memos.teacher.length"
                      :class="$style.content"
                      v-html="reserve.memos.teacher[0].memo"></p>
                    <p v-else :class="$style.content">記入なし</p>
                  </li>
                  <li
                    class="n2br"
                    v-if="user.account_type > 20">
                    <label-box label="カルテ" color="gray" size="sm" :class="$style.userlabel" />
                    <p
                      v-if="reserve.memos.counselor.length"
                      :class="$style.content"
                      v-html="reserve.memos.counselor[0].memo"></p>
                    <p v-else :class="$style.content">記入なし</p>
                  </li>
                </ul>
              </div>
            </template>
          </user-box>
        </contents-box>
      </stack-item>
      <stack-item>
        <contents-box title="報告書兼意見書">
          <div v-if="reserve && reserve.report">
            <div :class="$style.wrapper">
              <div v-for="row in items" :key="row">
                <div v-if="row.type === 1">
                  <div :class="$style.item">
                    <div :class="$style.label">{{ isCompany ? row.label.replace('学校', '企業') : row.label }}</div>
                    <p :class="$style.value">{{ reserve.report[row.name] }}</p>
                  </div>
                </div>
                <div v-if="row.type === 2 && (reserve.report[row.name] !== 0 || row.name === 'finding')">
                  <div :class="$style.item">
                    <div :class="$style.label">{{ row.label }}</div>
                    <p
                      v-for="item in row.items" :key="item"
                      v-show="reserve.report[row.name] === item.value"
                      :class="$style.value">{{ item.label }}<span v-if="(reserve.report[row.name] === row.other) || row.name === 'finding' && reserve.report[row.name] === 1">（{{ reserve.report[`${row.name}_memo`] }}）</span></p>
                  </div>
                </div>
                <div v-if="row.type === 3 && reserve.report[row.name] === 1">
                  <div :class="$style.item">
                    <div :class="$style.label">{{ row.label }}</div>
                    <p :class="$style.value">{{ row.value }}</p>
                  </div>
                </div>
                <div v-if="row.type === 4 && !schoolhidden(row.name)">
                  <div :class="$style.item">
                    <div :class="$style.label">{{ row.label }}</div>
                    <p v-if="this.reserve.toUser.account_type === 21" :class="$style.value">{{ row.category }}</p>
                    <p :class="$style.value">{{ row.diagnosis }}</p>
                  </div>
                </div>
              </div>
              <div>
                <div :class="$style.item">
                  <div :class="$style.label">作成日</div>
                  <p :class="$style.value">{{ formatTimestamp(reserve.report.updated_at, 'YYYY年MM月DD日') }}</p>
                </div>
              </div>
              <div>
                <div :class="$style.item">
                  <div :class="$style.label">作成者</div>
                  <spacer :y="2"/>
                  <user-box
                    type="inner"
                    :user="reserve.toUser"
                    :showId="false"></user-box>
                </div>
              </div>
            </div>
          </div>
          <p v-if="!reserve.report">報告書兼意見書は未登録です。</p>
        </contents-box>
      </stack-item>
    </ul>
    <spacer :y="5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UserBox from '@/views/components/UserBox.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'operator-user-list',
  mixins: [cf],
  components: {
    LabelBox,
    UserBox,
    ContentsBox,
    StackItem,
    Spacer,
  },
  data() {
    return {
      reserveId: null,
      reserve: null,
      symptomsList: null,
      items: [
        {
          name: 'summary',
          label: '相談概要',
          type: 1,
        },
        {
          name: 'notice',
          label: '医学的所見に関する特記事項',
          type: 1,
        },
        {
          name: 'finding',
          label: 'その他の心身の状況',
          type: 2,
          items: [
            {
              label: '所見なし',
              name: 'notFound',
              value: 0,
            },
            {
              label: '所見あり',
              name: 'found',
              value: 1,
            },
          ],
        },
        {
          name: 'cause',
          label: '要因',
          type: 2,
          items: [
            {
              label: '医学的要因（病気・障害等の疑い）',
              name: 'medical',
              value: 1,
            },
            {
              label: '心理社会・環境的要因（友人・家族など）',
              name: 'environmental',
              value: 2,
            },
          ],
        },
        {
          name: 'observe',
          label: '要健康観察',
          type: 2,
          other: 3,
          items: [
            {
              label: '必要なし',
              value: 0,
            },
            {
              label: '１ヶ月程度',
              value: 1,
            },
            {
              label: '３ヶ月程度',
              value: 2,
            },
            {
              label: 'その他',
              value: 3,
            },
          ],
        },
        {
          name: 're_counseling',
          label: '再カウンセリング',
          type: 2,
          other: 3,
          items: [
            {
              label: '必要なし',
              value: 0,
            },
            {
              label: '２週間後',
              value: 1,
            },
            {
              label: '１ヶ月後',
              value: 2,
            },
            {
              label: 'その他',
              value: 3,
            },
          ],
        },
        {
          name: 're_online',
          label: '再健康相談',
          type: 2,
          other: 3,
          items: [
            {
              label: '必要なし',
              value: 0,
            },
            {
              label: '２週間後',
              value: 1,
            },
            {
              label: '１ヶ月後',
              value: 2,
            },
            {
              label: 'その他',
              value: 3,
            },
          ],
        },
        {
          name: 'medical_cooperation',
          label: '医療連携',
          type: 3,
          value: '必要',
        },
        {
          name: 'diagnosis',
          label: '暫定診断',
          category: null,
          diagnosis: null,
          type: 4,
        },
        {
          name: 'report_memo',
          label: '学校関係者の対応・次回予約に関して',
          type: 1,
        },
      ],
    };
  },
  created() {
    this.reserveId = Number(this.$route.params.slug);
    this.getReserve();
    if (this.user.email) {
      this.registReadFlag();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.registReadFlag();
        }
        if (mutation.type === 'helper/putMaster') {
          this.getReserve();
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
    isCompany() {
      return Boolean(this.reserve && this.reserve.fromUser && this.reserve.fromUser.school.length && this.reserve.fromUser.school[0].is_company);
    },
    isVirtual() {
      if (!this.reserve || !this.reserve._counselingType) {
        return false;
      }
      return this.reserve._counselingType.counseling_type === 3;
    },
    title() {
      return this.isVirtual ? 'バーチャル健康相談予約内容' : 'オンライン健康相談予約内容';
    },
  },
  methods: {
    setSymptomslist() {
      this.symptomsList = this.helper.master.symptoms;
    },
    schoolhidden(name) {
      let schoolhidden = false;
      if ((this.user.account_type >= 11 && this.user.account_type <= 20) && name === 'diagnosis') {
        schoolhidden = true;
      }
      return schoolhidden;
    },
    async getReserve() {
      await this.setSymptomslist();
      const params = {
        id: this.reserveId,
      };
      this.axios({
        method: 'GET',
        url: '/v1/reserve/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.reserve = res.reserve;
          if (res.reserve.toUser.account_type === 21 && res.reserve.diagnosis.category !== 0) {
            this.items[8].category = this.symptomsList[this.reserve.diagnosis.category - 1].label;
            this.items[8].diagnosis = this.symptomsList[this.reserve.diagnosis.category - 1].detail[this.reserve.diagnosis.diagnosis - 1].label;
          } else {
            this.items[8].diagnosis = '暫定診断なし';
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    registReadFlag() {
      // 確認済であればreturn
      if (this.user.readflags && this.user.readflags.reserveReports.includes(this.reserveId)) return;
      // 下書き状態でアクセスされてもreadFlagは更新しない
      if (this.reserve && this.reserve.report && this.reserve.report.flag === 2) return;
      const data = {
        model_type: 4, // オンライン健康相談報告書
        user_id: this.user.id,
        foreign_id: this.reserveId,
      };
      this.axios({
        method: 'POST',
        url: '/v1/readflag/set/register',
        data,
      })
        .then(() => {
          this.$store.dispatch('user/update');
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module>
.labellist {
  li {
    &:not(:last-child) {
      padding-bottom: 32px;
    }
  }
}
.userlabel {
  width: 100px;
  margin-right: 20px;
}
.detail {
  li {
    display: flex;
    align-items: flex-start;
    @include sm-view {
      font-size: 14px;
      display: block;
    }
    .content {
      // font-size: 14px;
      margin-top: 3px;
      flex: 1;
    }
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
.types {
  margin-right: 20px;
}
.who5 {
  li {
    justify-content: space-between;
  }
}
.sign {
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
}

.item {
  padding: 20px 0;
  font-size: 14px;
  border-top: 1px solid var(--gray-main);
}
.label {
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  // color: var(--orange-main);
  background-color: var(--gray-sub);
  // color: #fff;
  padding: 4px 16px;
  border-radius: 4px;
  // border-bottom: 1px solid var(--orange-main);
}
.value {
  padding-top: 8px;
}
</style>

<template>
  <div id="scroll-top">
    <loader-simple
      :flag="flag.loader">
      <div v-show="!flag.loader">
        <div v-if="histories.length">
          <pagination
            :page="page"
            :pageMax="pageMax"
            :path="'/?tab=history'"
            v-if="pageMax !== 1" />
          <spacer :y="5"/>
          <histories :histories="histories" />

          <spacer :y="4"/>
          <pagination
            :page="page"
            :pageMax="pageMax"
            :path="'/?tab=history'"
            v-if="pageMax !== 1" />
        </div>
        <div v-else>
          <p>履歴はありません。<br>お気軽に<router-link to="/?tab=text">テキスト健康相談</router-link>・<router-link to="/?tab=online">オンライン健康相談</router-link>をご利用ください。</p>
        </div>
      </div>
    </loader-simple>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';
import moment from 'moment';
import Histories from '@/views/components/Histories.vue';
import Pagination from '@/views/components/Pagination.vue';

export default {
  name: 'history',
  mixins: [cf],
  components: {
    LoaderSimple,
    Spacer,
    Histories,
    Pagination,
  },
  data() {
    return {
      flag: {
        loader: true,
        pageQuery: false,
      },
      openIds: [],
      histories: [],
      page: 1,
      pageMax: 1,
      limit: 20,
    };
  },
  created() {
    this.getQueryPage();
  },
  watch: {
    $route() {
      this.getQueryPage();
      this.flag.pageQuery = true;
    },
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  methods: {
    changeOpenIds(id) {
      if (this.openIds.includes(id)) {
        const idx = this.openIds.indexOf(id);
        this.openIds.splice(idx, 1);
      } else {
        this.openIds.push(id);
      }
    },
    isNew(date) {
      const today = moment(new Date());
      // 3日以内（暫定的）
      return today.diff(moment(date), 'days') < 3;
    },

    /**
     * クエリのページ番号を取得
     */
    getQueryPage() {
      const query = this.$route.query;
      if (query.page) {
        this.page = Number(query.page);
      } else if (query.tab === 'history') {
        // queryにpageがなければ追加してreplace
        this.$router.replace('/?tab=history&page=1');
      }
      this.getHistory();
    },

    /**
     * 利用者の履歴を取得
     */
    getHistory() {
      this.flag.loader = true;
      const params = {
        userId: this.user.id,
        page: this.page,
        limit: this.limit,
      };
      this.axios({
        method: 'GET',
        url: '/v1/history/get/list',
        params,
      })
        .then((response) => {
          const res = response.data.data;
          this.pageMax = res.lastPage;
          this.histories = res.data;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(async () => {
          await this.$nextTick();
          if (this.flag.pageQuery) this.scroll2Top();
          this.flag.loader = false;
          this.flag.pageQuery = false;
        });
    },

    /**
     * ページャー選択時にトップへスクロース
     */
    scroll2Top() {
      const elem = document.getElementById('scroll-top');
      const position = elem.getBoundingClientRect().top;
      scrollBy(0, position - 77);
    },
  },
};
</script>

<style lang="scss" module>
.slider {
  width: 100%;
  height: 510px;
  border-radius: 10px;
  background-color: var(--primary-color);
}
.create {
  font-weight: bold;
}
.flex {
  display: flex;

  @include sm-view {
    display: block;
  }
}
.link {
  color: black;
}
.user_wrap {
  width: 180px;
}
.user {
  display: flex;
}
.md {
  @include sm-view {
    display: none;
  }
}
.only_sm {
  display: none;
  @include sm-view {
    display: flex;
    margin-top: 16px;
  }
}
.date {
  font-size: 10px;
}

.check_wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @include sm-view {
     flex-direction: column;
     align-items: flex-start;
  }
}

.check {
  font-size: 14px;
  padding: 4px 12px;
  background-color: var(--gray-sub);
  display: inline-block;
  border-radius: 4px;
  margin-right: 10px;

  @include sm-view {
    font-size: 12px;
    padding: 2px 7px;
    margin-right: 8px;
  }
}
.value {
  font-weight: bold;

  @include sm-view {
    font-size: 12px;
  }
}

.waitingCommentBox {
  display: flex;
  font-size: 14px;
  white-space: pre-wrap;

  @include sm-view {
    font-size: 13px;
  }
}

.open {
  cursor: pointer;
  display: flex;
}
.icon {
  margin-left: 15px;
  color: var(--gray-main);
  font-size: 20px;
  margin-top: 3px;
}
.answer {
  font-size: 14px;
  padding: 12px;
  background-color: rgba(var(--orange-main-rgb), .15);
  border-radius: 4px;
}

.new {
  position: absolute;
  top: -10px;
  left: -10px;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 12px;
  background-color: var(--pink);
  color: #fff;
  border-radius: 8px;

  @include md-view {
    left: auto;
    right: -8px;
    padding: 3px 7px 2px;
    font-size: 11px;
    border-radius: 6px;
  }
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include sm-view {
    display: block;
    .cancel {
      margin-top: 10px;
    }
  }
}
.counselor {
  display: flex;
  margin-right: 10px;
  @include sm-view {
    margin-bottom: 10px;
  }
  .img {
    margin-right: 10px;
  }
}

.position {
  font-size: 12px;
}

.labels {
  display: flex;
  align-items: center;
  @include sm-view {
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .md {
    width: 250px;
  }
  .sm {
    width: 130px;
  }

}

.url {
  a {
    color: var(--black);
    text-decoration: underline;
  }
}
.slider {
  width: 100%;
  height: 510px;
  border-radius: 10px;
  background-color: var(--primary-color);
}
.status {
  font-size: 12px;
  font-weight: normal;
}
.memo {
  font-size: 14px;
}
</style>

<style lang="scss" scoped>
.cancel {
  color: red;
}
</style>

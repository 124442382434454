<template>
  <div :class="$style.container" :style="style">
    <img :src="src" v-bind="$attrs" :class="$style.img" />
  </div>
</template>

<script>
export default {
  name: 'RoundImg',
  inheritAttrs: false,
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: false,
    },
    width: {
      // TODO: defaultを'100%'にしてレスポンシブ対応しやすくする
      type: String,
      required: true,
      validator: (value) => ['px', 'rem', 'em', '%', 'vw', 'vh'].some((unit) => value.includes(unit)),
    },
  },
  computed: {
    style() {
      return {
        '--width': this.width,
      };
    },
  },
};

</script>

<style lang="scss" module>
$size: 4.5em;
.container {
  display: block;
  width: var(--width);
  border-radius: 50%;
  overflow: hidden;
  @include sm-view {
    width: $size;
    height: $size;
  }
}

.img {
  display: block;
  width: var(--width);
  height: var(--width);
  object-fit: cover;
  @include sm-view {
    width: $size;
    height: $size;
  }
}
</style>

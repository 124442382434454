<template>
  <div>
    <spacer :y="5"/>
    <ul>
      <stack-item id="scroll-top">
        <contents-box title="送信済みメッセージ">
          <p :class="$style.label">＊最新の状態を反映するにはリロードしてください</p>
          <spacer :y="3"/>
          <loader-simple :flag="flag.messageLoader">
            <p v-if="!messagegroups && !flag.messageLoader">送信履歴はありませんでした。</p>
            <ul v-if="messagegroups && !flag.messageLoader">
              <stack-item space="2" :class="$style.new_wrap"
                v-for="(group, i) of messagegroups"
                :key="i">
                <user-box
                  v-if="group.fromUser"
                  :showId="true"
                  :showAge="group.fromUser.account_type > 10 ? false : true"
                  :user="group.fromUser"
                  :link="`/operator/text/${group.id}`"
                  :targetBlank="true"
                  @clickedUser="receiveUserClick(group.id)">
                  <template v-slot:main>
                    <div :class="$style.message" v-if="messages[group.target_user_id]">
                      <spacer :y="1"/>
                      <p>{{ messages[group.target_user_id] }}</p>
                    </div>
                  </template>
                </user-box>
                <div
                  :class="$style.new"
                  v-if="user.readflags && newFlags.includes(group.id)">NEW</div>
              </stack-item>
            </ul>
          </loader-simple>

          <div v-show="showMore">
            <spacer :y="4" />
            <loader-simple
              :flag="flag.loader"
              :mini="true"
            >
              <btn-container>
                <basic-btn
                  tag="button"
                  :disabled="flag.loader"
                  v-on:click="loadMore">
                  <sapn>もっと見る</sapn>
                </basic-btn>
              </btn-container>
            </loader-simple>
          </div>

        </contents-box>
      </stack-item>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import Spacer from '@/views/components/Spacer.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import UserBox from '@/views/components/UserBox.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'Message',
  mixins: [cf],
  components: {
    ContentsBox,
    StackItem,
    Spacer,
    UserBox,
    LoaderSimple,
    BtnContainer,
    BasicBtn,
  },
  data() {
    return {
      initialMessage: null,
      messagegroups: [],
      messages: {},
      rawMessages: {},
      newFlags: [],
      flag: {
        loader: false,
        messageLoader: true,
        noUsers: false,
      },
      perPage: 20,
      page: 0,
      lastPage: 0,
    };
  },
  created() {
    if (this.user.counseling.operator) {
      this.getOperatorMessage();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/counseling/putOperatorMessage') {
          this.getOperatorMessage();
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
    showMore() {
      return this.page < this.lastPage;
    },
    messageGroupIds() {
      return this.messagegroups.map((mg) => mg.id);
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** メッセージ取得 */
    getOperatorMessage() {
      // 多重実行防止
      if (this.flag.loader) {
        return;
      }
      this.flag.loader = true;

      const params = {
        user_id: this.user.id,
        target_type: 2,
        isMessages: 1,
        limit: this.perPage,
        page: this.page + 1,
      };
      this.axios({
        method: 'GET',
        url: '/v1/messagegroup/get/listOrderByNewest', // 新着順で取得する
        params,
      })
        .then(async (response) => {
          const res = response.data;

          // ページ関連
          this.page = res.groups.page;
          this.lastPage = res.groups.lastPage;

          // 取得したデータを表示用の配列に格納する（重複は除く）
          res.groups.data.forEach((data) => {
            if (!this.messageGroupIds.includes(data.id)) {
              this.messagegroups.push(data);
            }
          });

          await this.setMessage(res);
          await this.newFlagsChecker(res);
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.messageLoader = false;
          this.flag.loader = false;
        });
    },

    setMessage(res) {
      if (res && res.groups && res.groups.data) {
        const groups = res.groups;
        groups.data.forEach((message) => {
          if (message.messages.length) {
            this.rawMessages[message.id] = message.messages;
          } else {
            this.rawMessages[message.id] = [];
          }

          if (message.messages && message.messages.length) {
            const targetMessage = message.messages[message.messages.length - 1];
            this.messages[message.target_user_id] = targetMessage.message;
          } else {
            this.messages[message.target_user_id] = 'メッセージ履歴がありません';
          }
        });
      }
    },

    newFlagsChecker(res) {
      if (res && res.groups && res.groups.data) {
        const groups = res.groups;
        groups.data.forEach((message) => {
          if (message.messages && message.messages.length) {
            const targetMessage = message.messages[message.messages.length - 1];
            if ((this.user.readflags && !this.user.readflags.operatorMessages.includes(targetMessage.id)) && targetMessage.user_id !== this.user.id) {
              this.newFlags.push(message.id);
            }
            this.messages[message.target_user_id] = targetMessage.message;
          }
        });
      }
    },

    receiveUserClick(id) {
      if (id && this.newFlags.includes(id)) {
        this.registerReadFlag(id);
      }
    },

    registerReadFlag(id) {
      const messages = this.rawMessages[id];
      if (messages.length && messages[messages.length - 1].user_id !== this.user.id && this.user.readflags && !this.user.readflags.operatorMessages.includes(messages[messages.length - 1].id)) {
        const data = {
          model_type: 6, // 事務局個別メッセージ
          user_id: this.user.id,
          foreign_id: messages[messages.length - 1].id,
        };
        this.axios({
          method: 'POST',
          url: '/v1/readflag/set/register',
          data,
        })
          .then(async () => {
            await this.$store.dispatch('user/update');
            const idx = this.newFlags.indexOf(id);
            this.newFlags.splice(idx, 1);
          })
          .catch((error) => {
            if (error.message) console.log(error.message);
            else console.log(error);
          });

      }
    },

    loadMore() {
      this.getOperatorMessage();
    },
  },
};
</script>

<style lang="scss" module>

.message p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.new {
  position: absolute;
  background-color: var(--pink);
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 4px;
  top: -3px;
  right: -3px;
  &_wrap {
    position: relative;
  }
}
.label {
  border-radius: 4px;
  margin-right: 4px;
  font-weight: bold;
  display: inline-block;
  &_wrap {
    display: flex;
    align-items: baseline;
  }
}
</style>

<template>
  <div>
    <div :class="$style.top">
      <h2>{{ formatTimestamp(data.date, 'YYYY/MM/DD') }}</h2>
      <i
        :class="$style.plus"
        class="fa-regular fa-circle-plus"
        v-on:click="editSchedule(null)"/>
    </div>
    <spacer :y="2"/>
    <ul :class="$style.select">
      <li
        :class="showBy ==='counselor' ? `${$style.selected}` : ''"
        v-on:click="changeShowBy('counselor')">先生ごとに表示</li>
      <li
        :class="showBy ==='all' ? `${$style.selected}` : ''"
        v-on:click="changeShowBy('all')">登録シフト一覧</li>
    </ul>
    <spacer :y="3"/>
    <ul v-if="showBy === 'all'">
      <stack-item v-for="row in selectHours" :key="row" space="2">
        <contents-box type="inner">
          <div :class="$style.wrap">
            <div :class="$style.left">
              <label-box :label="`${row.date}:00`" size="sm" :class="$style.timeLabel"/>
              <div :class="$style.img">
                <round-img width="45px" :src="getMyIconUrl(row.counselor)" />
              </div>
              <div :class="$style.text">
                <p :class="$style.name">{{ row.counselor.username }}
                  <span :class="$style.counselingicons">
                    <i class="fa-regular fa-laptop" :class="$style.icon_online">
                      <i :class="$style.icon_online_inner" class="fa-regular fa-user-headset" />
                    </i>
                    <i v-if="canReserveVirtual(row.raw)" class="fa-solid fa-vr-cardboard" :class="$style.icon_virtual"></i>
                  </span>
                </p>
                <p :class="$style.position">{{ row.counselor.account_type === 21 ? '医師' : '心理士' }}</p>
              </div>
            </div>
            <div>
              <p :class="$style.reserved"
                v-if="row.raw.reserve && row.raw.reserve.flag === 1">
                <i v-if="isVirtualReserve(row.raw.reserve)" class="fa-solid fa-vr-cardboard" :class="$style.icon_virtual"></i>
                <i v-else class="fa-regular fa-laptop" :class="$style.icon_online">
                  <i :class="$style.icon_online_inner" class="fa-regular fa-user-headset" />
                </i>
                予約あり
              </p>
              <i
                v-else
                :class="$style.trash"
                class="fa-solid fa-trash-can"
                v-on:click="deleteSchedule(row.raw)"/>
            </div>
          </div>
        </contents-box>
      </stack-item>
    </ul>
    <ul v-if="showBy === 'counselor'">
      <stack-item v-for="user in users" :key="user">
        <user-box :showId="true" type="inner" :user="user" :bg="userBoxColor(user)">
          <template v-slot:btn>
            <i :class="$style.plus" class="fa-solid fa-plus" v-on:click="changeOpenIds(user.id)"></i>
          </template>
          <template v-slot:main v-if="openIds.includes(user.id)">
            <spacer :y="4"/>
            <button
              type="button"
              :class="$style.btn"
              v-on:click="editSchedule(user.id)">シフトを追加</button>
            <spacer :y="2"/>
            <ul>
              <stack-item
                v-for="row in user.schedules"
                :key="row"
                space="2">
                <contents-box type="inner">
                  <div :class="$style.inner">
                    <p>
                      {{ row.time }}:00 〜 {{ row.time }}:45
                      <span :class="$style.counselingicons">
                        <i class="fa-regular fa-laptop" :class="$style.icon_online">
                          <i :class="$style.icon_online_inner" class="fa-regular fa-user-headset" />
                        </i>
                        <i v-if="canReserveVirtual(row.raw)" class="fa-solid fa-vr-cardboard" :class="$style.icon_virtual"></i>
                      </span>
                    </p>
                    <div>
                      <p :class="$style.reserved"
                        v-if="row.raw.reserve && row.raw.reserve.flag === 1">
                        <i v-if="isVirtualReserve(row.raw.reserve)" class="fa-solid fa-vr-cardboard" :class="$style.icon_virtual"></i>
                        <i v-else class="fa-regular fa-laptop" :class="$style.icon_online">
                          <i :class="$style.icon_online_inner" class="fa-regular fa-user-headset" />
                        </i>
                        予約あり
                      </p>
                      <i
                        v-else
                        :class="$style.trash"
                        class="fa-solid fa-trash-can"
                        v-on:click="deleteSchedule(row.raw)"/>
                    </div>
                  </div>
                </contents-box>
              </stack-item>
            </ul>
          </template>
        </user-box>
      </stack-item>
    </ul>
  </div>
</template>

<script>
import cf from '@/mixins/commonFunctions.js';
import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import UserBox from '@/views/components/UserBox.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import RoundImg from '@/views/components/RoundImg.vue';
import Spacer from '@/views/components/Spacer.vue';

export default {
  name: 'daily-schedule',
  mixins: [cf],
  props: ['data'],
  components: {
    StackItem,
    ContentsBox,
    UserBox,
    Spacer,
    LabelBox,
    RoundImg,
  },
  data() {
    return {
      users: [],
      selectHours: [],
      rawHours: [], // flagに関わらずgetしたデータそのまま、objectの配列
      adjustRawHours: [], // flagに関わらずgetしたデータを数字に変換して格納
      invalidHours: [], // flag=999で登録済の時間
      openIds: [],
      showBy: 'counselor',
    };
  },
  created() {
    this.getSchedules();
  },
  methods: {
    getSchedules() {
      this.rawHours = [];
      this.selectHours = [];
      this.adjustRawHours = [];
      this.invalidHours = [];

      this.axios({
        method: 'GET',
        url: '/v1/schedule/get/list',
      })
        .then((response) => {
          const res = response.data;
          this.rawHours = res.schedules.data;
          this.adjustSchedule();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    adjustSchedule() {
      const array = [];
      this.adjustRawHours = [];
      this.selectHours = [];
      this.rawHours.forEach((time) => {
        let date = this.formatTimestamp(time.date, 'YYYY-MM-DD/HH');
        date = date.split('/');
        if (date[0] === this.data.date) {
          // 有効なものはselectHoursに
          if (time.flag === 1) {
            this.selectHours.push({
              date: Number(date[1]),
              counselor: time.counselor,
              raw: time,
            });
            array.push(time);
          }
        }
      });
      this.rawHours = array;
      this.orderByUsers();
    },
    orderByUsers() {
      const userIds = [];
      this.users = [];
      this.rawHours.forEach((time) => {
        let date = this.formatTimestamp(time.date, 'YYYY-MM-DD/HH');
        date = date.split('/');
        const adjustTime = date[1];
        if (!userIds.includes(time.target_user_id)) {
          userIds.push(time.target_user_id);
          this.users.push(time.counselor);
          this.users.forEach((user) => {
            if (user.id === time.target_user_id) {
              if (!user.schedules) {
                user.schedules = [];
                user.schedules.push({
                  time: adjustTime,
                  raw: time,
                });
              }
            }
          });
        } else {
          this.users.forEach((user) => {
            if (user.id === time.target_user_id) {
              user.schedules.push({
                time: adjustTime,
                raw: time,
              });
            }
          });
        }
      });
    },
    changeOpenIds(id) {
      if (this.openIds.includes(id)) {
        const idx = this.openIds.indexOf(id);
        this.openIds.splice(idx, 1);
      } else {
        this.openIds.push(id);
      }
    },
    changeShowBy(name) {
      this.showBy = name;
    },
    editSchedule(cid) {
      const data = {
        isOperator: true,
        id: cid,
        date: this.data.date,
      };
      const args = {
        modalName: 'edit-schedule',
        data,
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
    confirmDelete(date, time) {
      const deleteDate = window.confirm(`下記 登録シフトを削除しますか？\n${date}　${time.date}:00 - ${time.date}:45`);
      if (deleteDate) {
        this.deleteSchedule(time.raw);
      }
    },
    deleteSchedule(time) {
      if (!confirm(`こちらのシフトを削除してよろしいですか？\n${this.formatTimestamp(time.date, 'YYYY年MM月DD日 HH:00')}：${time.counselor.username}`)) return;
      const data = {
        schedules: [
          {
            id: time.id,
            date: this.formatTimestamp(time.date, 'YYYY-MM-DD HH:mm:ss'),
            flag: 999,
          },
        ],
      };
      this.axios({
        method: 'POST',
        url: '/v1/schedule/set/updater',
        data,
      })
        .then(() => {
          this.getSchedules();
          this.$store.dispatch('schedule/setSchedule');
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    /**
     * 非アクティブの医師・心理士のシフトが登録されていたら背景色を変更
     * 操作はアクティブな医師心理士同様に可能にしておく
     */
    userBoxColor(user) {
      let color;
      if (user.flag !== 1) color = 'silver';
      else color = 'white';
      return color;
    },

    canReserveVirtual(schedule) {
      return schedule.counseling_reception_type === 2;
    },
    isVirtualReserve(reserve) {
      return reserve.type && reserve.type.counseling_type === 3;
    },
  },
};
</script>

<style lang="scss" module>
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .plus {
    font-size: 24px;
  }
}
.select {
  display: flex;
  align-items: center;
  li {
    font-size: 14px;
    border: 1px solid var(--black);
    padding: 8px 24px;
    border-radius: 24px;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 10px;
    }
    &.selected {
      background-color: var(--orange-main);
      color: #fff;
      border-color: var(--orange-main);
      font-weight: bold;
    }
  }
}
.plus {
  font-size: 20px;
  cursor: pointer;
  color: var(--gray-side);
}
.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include sm-view {
    display: block;
  }
}
.left {
  display: flex;
  align-items: center;
}
.timeLabel {
  width: 80px;
  margin-right: 20px;
}
.img {
  margin-right: 20px;
}
.name {
  font-size: 14px;
}
.position {
  font-size: 10px;
}
.trash {
  font-size: 20px;
  color: var(--gray-main);
  cursor: pointer;
}
.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 12px;
  color: var(--black);
  border: 1px solid var(--black);
  border-radius: 20px;
  padding: 4px 12px;
  cursor: pointer;
}
.reserved {
  display: inline-block;
  font-size: 14px;
  color: var(--pink);
  border: 1px solid var(--pink);
  padding: 2px 12px;
  border-radius: 4px;
}
.counselingicons {
  > i {
    &:not(:first-child) {
      margin-left: .3em;
    }
  }
}
.icon {
  &_online {
    color: var(--orange-main);
    position: relative;
    &_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
      font-size: .4em;
    }
  }
  &_virtual {
    color: var(--green-main);
  }
}
</style>

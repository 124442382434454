<template>
  <div class="sitemap">
    <div :class="$style.types">
      <div
        :class="[$style.type, type.name]"
        v-for="type in sitemap"
        :key="type.label">
        <h2 v-html="type.label"></h2>
        <table :class="$style.table">
          <tr>
            <th>ページ名</th>
            <th>パス</th>
            <th>備考</th>
          </tr>
          <tr
            v-for="page in type.pages"
            :key="page.label">
            <td v-if="page.exists"><router-link :to="getPath(type.route, page.path, page.query)">{{ page.label }}</router-link></td>
            <td v-else>{{ page.label }}</td>
            <td>{{ getPath(type.route, page.path, page.query) }}</td>
            <td>{{ page.description }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import cf from '@/mixins/commonFunctions';
import sitemap from '@/data/sitemap.yaml';

export default {
  name: 'Sitemap',
  mixins: [cf],
  data() {
    return {
      sitemap,
    };
  },
  methods: {
    getPath(route, path, query) {
      const trailingSlash = path ? '/' : '';
      let querystr = '';
      if (query) querystr = `?${query}`;
      return `${route}${path}${trailingSlash}${querystr}`;
    },
  },
};
</script>

<style module lang="scss">
.types {
}

.type {
  &:not(:first-child) {
    margin-top: 50px;
  }
}

.table {
  margin-top: 10px;
  th {
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  td, th {
    padding: 10px;
  }
}
</style>

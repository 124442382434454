<template>
  <div class="bar-consul">
    <Bar
      :chartData="ChartData"
      :chart-options="chartOptions"
      :height="200"
    />
  </div>
</template>

<script>
import {
  Chart,
  registerables,
} from 'chart.js';
import { Bar } from 'vue-chartjs';

Chart.register(...registerables);

export default {
  name: 'BarChart',
  components: {
    Bar,
  },
  props: {
    Axis: {
      type: String,
      default: 'x',
    },
    labelsData: {
      type: Array,
      default: null,
    },
    datasetsData: {
      type: Object,
      default: null,
    },
    sort: { // 入力により昇順、降順でソートする
      type: String,
      default: null,
    },
    options: { // 個別にグラフスタイルをいじりたい時は呼び出し元でオプションを設定
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      ChartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        indexAxis: null,
        plugins: {
          legend: {
            display: false, // 凡例の非表示
          },
        },
        scales: {
          x: {
            ticks: {
              stepSize: 1, // メモリのステップサイズを1.0に設定（標準は自動なので少ないと0.25になったりする）
            },
          },
        },
        // barThickness: 20, // バーの太さをpxで指定
        barPercentage: 0.7, // バーの太さを余白と相対的に指定
        responsive: true,
      },
    };
  },
  created() {
    this.chartOptions.indexAxis = this.Axis;
  },
  mounted() {
    this.drawGraph();
  },
  watch: {
    labelsData() { // 期日や絞り込み条件の変動があったら再描画
      this.updateGraph();
    },
  },
  methods: {
    updateGraph() {
      this.ChartData.labels = [];
      this.ChartData.datasets = [];
      this.drawGraph();
    },
    drawGraph() {
      this.labelsData.forEach((elem) => {
        this.ChartData.labels.push(elem);
      });

      // 複数軸の場合はChartData.datasetsを配列にする
      if (this.datasetsData.length) {
        this.datasetsData.forEach((elem) => {
          this.ChartData.datasets.push(elem);
        });
      } else {
        this.ChartData.datasets.push(this.datasetsData);
      }

      if (this.options) {
        Object.keys(this.options).forEach((elem) => {
          this.chartOptions[elem] = this.options[elem];
        });
      }

      if (this.sort) { // ソート
        const sortContainer = [];
        this.ChartData.labels.forEach((elem, index) => {
          const data = {
            label: elem,
            data: this.ChartData.datasets[0].data[index],
            color: this.ChartData.datasets[0].backgroundColor[index],
          };
          sortContainer.push(data);
        });
        const sortedData = sortContainer.sort((a, b) => a.data - b.data);
        if (this.sort === 'desc') { // 降順
          sortedData.reverse();
        }
        this.ChartData.labels = [];
        this.ChartData.datasets = [{
          data: [],
          backgroundColor: [],
          label: '相談内容',
        }];

        sortedData.forEach((elem) => {
          this.ChartData.labels.push(elem.label);
          this.ChartData.datasets[0].data.push(elem.data);
          this.ChartData.datasets[0].backgroundColor.push(elem.color);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .bar-consul {
    @media screen and (min-width: 1080px) {
      max-width: 500px;
    }
  }

</style>>


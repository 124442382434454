<template>
  <div id="edit-top" class="editSchool edit">
    <div class="container">
      <h2 class="title-page lg">所属組織情報更新</h2>

      <form
        class="form horizontal"
        @submit.prevent="updateFlagConfirm"
        autocomplete="off">

        <div
          class="form-contents"
          v-if="formItems.length">
          <dl
            class="form-group"
            v-for="(item, i) in formItems"
            v-bind:key="i">
            <dt class="label">
              <label
                class="form-label"
                v-bind:for="item.name">{{ item.label }}</label>
            </dt>
            <dd
              class="detail"
              v-bind:class="item.type">
              <div class="form-parts">
                <input
                  :id="item.name"
                  :type="item.type"
                  :name="item.name"
                  :disabled="flag.confirm"
                  v-model="item.value"
                  v-if="item.type === 'text'"
                  @change="updateValue($event, i)">

                <textarea
                  :id="item.name"
                  :name="item.name"
                  :disabled="flag.confirm"
                  v-model="item.value"
                  v-if="item.type === 'textarea'"
                  @change="updateValue($event, i)"></textarea>

                <select
                  :id="item.name"
                  :name="item.name"
                  :disabled="flag.confirm"
                  v-model="item.value"
                  v-if="item.type === 'select'">
                  <option value="0">選択してください</option>
                  <option
                    v-for="option in item.options"
                    :key="option.value"
                    :value="option.value"
                    :selected="option.value === user[item.name]">{{ option.label }}</option>
                </select>
              </div>
              <p
                class="form-assistance"
                v-if="item.comment"
                v-html="item.comment"></p>
            </dd>
          </dl>
        </div>

        <div class="form-contents mt-sm">
          <ul class="btn-lists horizontal">
            <li v-if="!flag.confirm">
              <button
                type="submit"
                id="confirm"
                class="btn btn__primary sm"
                >確認</button>
            </li>
            <li v-if="!flag.confirm">
              <router-link
                to="/account/"
                class="btn btn__tertiary sm"
                >戻る</router-link>
            </li>
            <li v-if="flag.confirm">
              <div
                id="submit"
                class="btn btn__primary sm"
                @click="submit">登録</div>
            </li>
            <li v-if="flag.confirm">
              <div
                class="btn btn__tertiary sm"
                id="back"
                @click="updateFlagConfirm">編集画面に戻る</div>
            </li>
          </ul>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'edit-school',
  mixins: [cf],
  data() {
    return {
      flag: {
        confirm: false,
        showFileInput: true,
        isSaved: true,
      },
      values: {},
      formItems: [
        {
          name: 'name',
          type: 'text',
          label: '学校名',
          value: null,
          comment: '',
        },
        {
          name: 'kana',
          type: 'text',
          label: '学校名カナ',
          value: null,
          comment: '',
        },
        {
          name: 'tel',
          type: 'text',
          label: '電話番号',
          value: null,
          comment: '',
        },
        {
          name: 'fax',
          type: 'text',
          label: 'FAX',
          value: null,
          comment: '',
        },
        {
          name: 'email',
          type: 'text',
          label: 'メールアドレス',
          value: null,
          comment: '',
        },
      ],
    };
  },
  created() {
    if (this.user.email) {
      this.getUserValues();
    } else {
      this.$store.subscribe((mutation) => {
        // ユーザ情報を取得してvaluesを定義
        if (mutation.type === 'user/setUserData') this.getUserValues();
      });
    }
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
    submitFlag() {
      return false;
    },
  },
  methods: {
    getUserValues() {
      if (this.user.school) {
        const clone = cloneDeep(this.user.school);
        const keys = Object.keys(clone);
        // item.nameに応じたvalueを取得
        this.formItems.forEach((item, i) => {
          if (keys.includes(item.name)) {
            this.formItems[i].value = clone[item.name];
          }
        });
      }
      this.flag.loader = false;
    },
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 確認フラグ更新 */
    updateFlagConfirm(e) {
      if (!this.formItems[0].value) {
        return alert('学校名を入力してください');
      }
      const id = e.currentTarget.id;
      const next = id && id === 'back';
      this.flag.confirm = !next;
      // if (next) {
      //   this.$scrollTo('#edit-top');
      // }
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();
      this.showLoading();

      const data = {
        id: this.user.school[0].id,
      };

      this.formItems.forEach((item) => {
        data[item.name] = item.value;
      });


      this.axios({
        method: 'POST',
        url: '/v1/school/set/updater',
        data,
      })
        .then(() => {
          this.flag.confirm = false;
          // stateアップデート
          this.$store.dispatch('user/update', null, { root: true });
          alert('所属組織情報を更新しました');
        })
        .catch((error) => {
          alert('所属組織情報の更新に失敗しました');
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>

<template>
  <div>
    <spacer :y="5"/>
    <contents-box title="生徒情報更新">
      <form v-on:submit.prevent="updateFlagConfirm">
        <div :class="$style.wrapper">
         <div :class="$style.row"
            class="form-group"
            v-for="(item, i) in formItems"
            v-bind:key="i">
            <div :class="$style.label_wrap">
              <p :class="$style.label">{{ item.label }}</p>
            </div>
            <div :class="$style.detail">
              <div class="form-parts">
                <input
                  :class="$style.text"
                  :id="item.name"
                  :type="item.type"
                  :name="item.name"
                  :disabled="flag.confirm"
                  v-model="item.value"
                  v-if="item.type === 'text'"
                  @change="updateValue($event, i)">

                <textarea
                  :class="$style.textarea"
                  :id="item.name"
                  :name="item.name"
                  :disabled="flag.confirm"
                  v-model="item.value"
                  rows="5"
                  v-if="item.type === 'textarea'"
                  @change="updateValue($event, i)"></textarea>

                <select
                  :id="item.name"
                  :name="item.name"
                  :disabled="flag.confirm"
                  v-model="item.value"
                  v-if="item.type === 'select'">
                  <option value="0">選択してください</option>
                  <option
                    v-for="option in item.options"
                    :key="option.value"
                    :value="option.value"
                    :selected="option.value === user[item.name]">{{ option.label }}</option>
                </select>
              </div>
              <p
                class="form-assistance"
                v-if="item.comment"
                v-html="item.comment"></p>
            </div>
          </div>
        </div>

      <!-- <form
        class="form horizontal"
        @submit.prevent="updateFlagConfirm"
        autocomplete="off"> -->

        <div :class="$style.flex">
          <button
            v-if="!flag.confirm"
            type="submit"
            :class="$style.btn"
            id="confirm"
            >確認</button>
          <spacer v-if="!flag.confirm" :x="2"/>
          <router-link
            v-if="!flag.confirm"
            to="/account/"
            :class="`${$style.btn} ${$style.bdr}`">戻る</router-link>
          <div
            v-if="flag.confirm"
            :class="$style.btn"
            id="submit"
            v-on:click="submit">登録</div>
          <spacer v-if="flag.confirm" :x="2"/>
          <div
            v-if="flag.confirm"
            :class="`${$style.btn} ${$style.bdr}`"
            id="back"
            v-on:click="updateFlagConfirm">編集画面に戻る</div>
        </div>
      </form>

    </contents-box>
    <spacer :y="5"/>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';
// import BasicBtn from '@/views/components/BasicBtn.vue';
// import BtnContainer from '@/views/components/BtnContainer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Spacer from '@/views/components/Spacer.vue';

export default {
  name: 'edit-student',
  mixins: [cf],
  components: {
    // BasicBtn,
    // BtnContainer,
    ContentsBox,
    Spacer,
  },
  data() {
    return {
      flag: {
        confirm: false,
        showFileInput: true,
        isSaved: true,
      },
      values: {},
      formItems: [
        {
          name: 'student_id',
          type: 'text',
          label: '学籍番号',
          value: null,
          comment: '',
        },
        {
          name: 'school_year',
          type: 'text',
          label: '学年',
          value: null,
          comment: '',
        },
      ],
    };
  },
  created() {
    if (this.user.email) {
      this.getUserValues();
    } else {
      this.$store.subscribe((mutation) => {
        // ユーザ情報を取得してvaluesを定義
        if (mutation.type === 'user/setUserData') this.getUserValues();
      });
    }
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
    submitFlag() {
      return false;
    },
  },
  methods: {
    getUserValues() {
      if (this.user.student) {
        const clone = cloneDeep(this.user.student);
        const keys = Object.keys(clone);
        // item.nameに応じたvalueを取得
        this.formItems.forEach((item, i) => {
          if (keys.includes(item.name)) {
            this.formItems[i].value = clone[item.name];
          }
        });
      }
      this.flag.loader = false;
    },
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 確認フラグ更新 */
    updateFlagConfirm(e) {
      if (!this.formItems[0].value) {
        return alert('学籍番号を入力してください');
      }
      const id = e.currentTarget.id;
      const next = id && id === 'back';
      this.flag.confirm = !next;
      // if (next) {
      //   this.$scrollTo('#edit-top');
      // }
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();

      this.showLoading();
      const endpoint = this.user.student ? 'update' : 'register';
      const type = this.user.student ? '更新' : '登録';

      const data = {
        id: this.user.id,
        username: this.user.username,
        isOnlyAdditional: 1,
        account_type: this.user.account_type,
        additionalData: {
          id: this.user.student ? this.user.student.id : null,
          user_id: this.user.id,
          created_at: this.user.student ? this.user.student.created_at : null,
        },
      };

      this.formItems.forEach((item) => {
        data.additionalData[item.name] = item.value;
      });


      this.axios({
        method: 'POST',
        url: `/v1/user/set/${endpoint}`,
        data,
      })
        .then(() => {
          this.flag.confirm = false;
          // stateアップデート
          this.$store.dispatch('user/update', null, { root: true });
          alert(`生徒情報を${type}しました`);
        })
        .catch((error) => {
          alert(`生徒情報の${type}に失敗しました`);
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module>
.row {
  padding: 24px 0;
  border-top: 1px solid var(--gray-main);
}
.label {
  border-radius: 4px;
  margin-right: 4px;
  font-weight: bold;
  display: inline-block;
  &_wrap {
    display: flex;
    align-items: baseline;
  }
}
.flex {
  display: flex;
  align-items: center;
}
.fileinput {
  display: none;
}
.filelabel {
  font-size: 12px;
  padding: 8px 20px;
  border-radius: 20px;
  background-color: var(--gray-main);
  // background-color: #666;
  // color: #fff;
  display: inline-block;
  border: none;
}
.icon {
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-position: center;
  &_wrap {
    overflow: hidden;
    border-radius: 50%;
    width: 100px;
  }
}
.text, .select, .textarea {
  &:disabled {
    color: black;
    background-color: transparent;
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }
  }
}
.assistant {
  font-size: 12px;
}
.space {
  padding-top: 6px;
}
.text {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
}
.textarea {
  padding: 13px;
  background-color: var(--gray-sub);
  resize: vertical;
  width: 100%;
  border-radius: 8px;
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
}
.radio {
  padding-top: 8px;
  display: flex;

  li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
.select {
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;

  &_wrap {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.btn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
  &.bdr {
    background-color: transparent;
    color: var(--black);
    border: 1px solid var(--black);
  }
}
</style>

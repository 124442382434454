<template>
  <div>
    <spacer :y="5"/>
    <stack-item>
      <contents-box title="基本情報">
        <loader-simple :flag="flag.loader">
          <data-grid v-if="!flag.loader">
            <data-row>
              <data-term width="quater">
                <label-box :label="isCompany ? '企業名' : '学校名'"/>
              </data-term>
              <data-detail>
                <p :class="$style.value">{{ school.name }}</p>
              </data-detail>
            </data-row>
            <data-row>
              <data-term width="quater">
                <label-box :label="isCompany ? '企業名カナ' : '学校名カナ'"/>
              </data-term>
              <data-detail>
                <p :class="$style.value">{{ school.kana }}</p>
              </data-detail>
            </data-row>
          </data-grid>
        </loader-simple>
      </contents-box>
    </stack-item>
    <stack-item>
      <contents-box title="詳細情報">
        <div :class="$style.wrapper">
          <div
            :class="$style.row"
            v-for="row in schoolItems"
            :key="row.label">
            <div :class="$style.label_wrap">
              <p :class="$style.label">{{ row.label }}</p>
            </div>
            <spacer :y="1" />
            <div class="n2br" :class="$style.detail">
              <input
                v-if="row.type === 'text'"
                v-model="row.value"
                type="text"
                :class="$style.text">
            </div>
          </div>
          <div :class="$style.row">
            <div :class="$style.label_wrap">
              <p :class="$style.label">住所</p>
            </div>
            <spacer :y="1" />
            <div class="n2br" :class="$style.detail">
              <Address
                :items="items"
                :isConfirm="false"
                v-on:sendValue="receiveValue"
                ref="address"/>
            </div>
          </div>

          <btn-container>
            <basic-btn
              size="sm"
              tag="button"
              @click="updateBase">更新</basic-btn>
          </btn-container>
        </div>
      </contents-box>
    </stack-item>
    <stack-item>
      <contents-box title="管理者情報">
        <loader-simple :flag="flag.loader">
          <data-grid v-if="!flag.loader">
            <data-row>
              <data-term width="quater">
                <label-box label="主管理者"/>
              </data-term>
              <data-detail>
                <ul :class="$style.users">
                  <li
                    v-for="row in school.mainUser"
                    :key="row.id">
                    <div :class="$style.user_wrap">
                      <contents-box type="inner">
                        <div v-if="mainValues.id !== row.id">
                          <ul :class="$style.user">
                            <li
                              v-for="item in userItems"
                              :key="item"
                              v-show="item.name !== 'kana'">
                              <p :class="$style.user_value"><i :class="item.icon"/>{{ row[item.name] }}<span v-if="item.name === 'username' && row.kana">（{{ row.kana }}）</span></p>
                            </li>
                          </ul>
                          <spacer :y="2"/>
                          <btn-container align="right">
                            <basic-btn
                              tag="button"
                              size="sm"
                              v-on:click="editStart(row, 'main')">編集</basic-btn>
                          </btn-container>
                        </div>
                        <div v-else>
                          <ul>
                            <li
                              v-for="item in userItems"
                              :key="item">
                              <div :class="$style.label_wrap">
                                <p :class="$style.label">{{ item.label }}</p>
                              </div>
                              <div :class="$style.detail">
                                <input
                                  v-if="item.name === 'email'"
                                  id="mainUser"
                                  type="email"
                                  name="mainUser"
                                  v-model.trim="$v.mainUser.$model"
                                  v-model="mainValues[item.name]"
                                  :class="$style.text">
                                <input
                                  v-else
                                  type="text"
                                  v-model="mainValues[item.name]"
                                  :class="$style.text">
                                <p v-if="item.name === 'email'
                                  && $v.mainUser.$dirty
                                  && $v.mainUser.email.$invalid"
                                  :class="$style.validation">正しいメールアドレスの形式で入力してください</p>
                              </div>
                            </li>
                          </ul>
                          <spacer :y="2"/>
                          <btn-container>
                            <basic-btn
                              tag="button"
                              size="sm"
                              v-on:click="updateUser('main')">更新</basic-btn>
                            <spacer :x="2"/>
                            <basic-btn
                              tag="button"
                              size="sm"
                              type="bdr"
                              v-on:click="editCancel('main')">戻る</basic-btn>
                          </btn-container>
                          <spacer :y="2"/>
                          <div :class="$style.editpassword" v-if="row.id === user.id">
                            <router-link to="/edit/password/">パスワードの変更はこちら</router-link>
                          </div>
                        </div>
                      </contents-box>
                    </div>
                  </li>
                </ul>
              </data-detail>
            </data-row>
            <data-row>
              <data-term width="quater">
                <label-box label="副管理者"/>
              </data-term>
              <data-detail>
                <ul :class="$style.users">
                  <li
                    v-for="user in school.subUser"
                    :key="user.id">
                    <div :class="$style.user_wrap">
                      <contents-box type="inner">
                        <div v-if="subValues.id !== user.id">
                          <ul :class="$style.user">
                            <li
                              v-for="item in userItems"
                              :key="item"
                              v-show="item.name !== 'kana'">
                              <p :class="$style.user_value" v-if="user[item.name]"><i :class="item.icon"/>{{ user[item.name] }}<span v-if="item.name === 'username' && user.kana">（{{ user.kana }}）</span></p>
                              <p :class="$style.user_value" v-else><i :class="item.icon"/>登録なし</p>
                            </li>
                          </ul>
                          <spacer :y="2"/>
                          <btn-container align="right">
                            <basic-btn
                              tag="button"
                              size="sm"
                              v-on:click="editStart(user, 'sub')">編集</basic-btn>
                          </btn-container>
                        </div>
                        <div v-else>
                          <ul>
                            <li
                              v-for="item in userItems"
                              :key="item">
                              <div :class="$style.label_wrap">
                                <p :class="$style.label">{{ item.label }}</p>
                              </div>
                              <div :class="$style.detail">
                                <input
                                  v-if="item.name === 'email'"
                                  id="subUser"
                                  type="email"
                                  name="subUser"
                                  v-model.trim="$v.subUser.$model"
                                  v-model="subValues[item.name]"
                                  :class="$style.text">
                                <input
                                  v-else
                                  type="text"
                                  v-model="subValues[item.name]"
                                  :class="$style.text">
                                <p v-if="item.name === 'email'
                                  && $v.subUser.$dirty
                                  && $v.subUser.email.$invalid"
                                  :class="$style.validation">正しいメールアドレスの形式で入力してください</p>
                              </div>
                            </li>
                          </ul>
                          <spacer :y="2"/>
                          <btn-container>
                            <basic-btn
                              tag="button"
                              size="sm"
                              v-on:click="updateUser('sub')">更新</basic-btn>
                            <spacer :x="2"/>
                            <basic-btn
                              tag="button"
                              size="sm"
                              type="bdr"
                              v-on:click="editCancel('sub')">戻る</basic-btn>
                            <spacer :x="2"/>
                            <basic-btn
                              tag="button"
                              size="sm"
                              addClass="danger"
                              v-on:click="deleteSubUser('sub')">削除</basic-btn>
                          </btn-container>
                        </div>
                      </contents-box>
                    </div>
                  </li>
                  <li v-if="!school.subUser.length">
                    <div :class="$style.user_wrap">
                      <contents-box type="inner">
                        <div v-if="!flag.registSubUser">
                          <basic-btn
                            tag="button"
                            size="sm"
                            v-on:click="editStart(null, 'sub')">登録する</basic-btn>
                        </div>
                        <div v-else>
                          <ul>
                            <li
                              v-for="item in userItems"
                              :key="item">
                              <div :class="$style.label_wrap">
                                <p :class="$style.label">{{ item.label }}</p>
                              </div>
                              <div :class="$style.detail">
                                <input
                                  type="text"
                                  v-model="subValues[item.name]"
                                  :class="$style.text">
                              </div>
                            </li>
                          </ul>
                          <spacer :y="2"/>
                          <btn-container>
                            <basic-btn
                              tag="button"
                              size="sm"
                              v-on:click="updateUser('sub')">更新</basic-btn>
                            <spacer :x="2"/>
                            <basic-btn
                              tag="button"
                              size="sm"
                              type="bdr"
                              v-on:click="editCancel('sub')">戻る</basic-btn>
                          </btn-container>
                        </div>
                      </contents-box>
                    </div>
                  </li>
                </ul>
              </data-detail>
            </data-row>
          </data-grid>
        </loader-simple>
      </contents-box>
    </stack-item>
    <spacer :y="5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';
import { Address } from '@/views/components/parts/Form';
import { useVuelidate } from '@vuelidate/core';
import { email } from '@vuelidate/validators';
import { ref } from 'vue';
import {
  DataGrid, DataRow, DataTerm, DataDetail,
} from '@/views/components/DataGrid';

export default {
  name: 'account',
  components: {
    BasicBtn,
    BtnContainer,
    StackItem,
    ContentsBox,
    LabelBox,
    LoaderSimple,
    Spacer,
    Address,
    DataGrid,
    DataRow,
    DataTerm,
    DataDetail,
  },
  mixins: [cf],
  computed: {
    ...mapState(['user', 'helper']),
    isCompany() {
      return Boolean(this.user && this.user.school && this.user.school[0].is_company);
    },
  },
  data() {
    return {
      flag: {
        loader: true,
        readySubmit: false,
        registSubUser: false,
      },
      validationFlags: {
        mainUser: false,
        subUser: false,
      },
      invalids: {
        mainUser: true,
        subUser: true,
      },
      school: null,
      schoolItems: [
        {
          label: '電話番号',
          name: 'tel',
          type: 'text',
          value: null,
        },
        {
          label: 'メールアドレス',
          name: 'email',
          type: 'text',
          value: null,
        },
      ],
      userItems: [
        {
          label: '名前',
          name: 'username',
          icon: 'fa-solid fa-user',
        },
        {
          label: 'カナ',
          name: 'kana',
          icon: '',
        },
        {
          label: 'メールアドレス',
          name: 'email',
          icon: 'fa-regular fa-envelope',
        },
      ],
      items: [
        {
          name: 'zip',
          type: 'text',
          label: '郵便番号',
          value: null,
          required: true,
          placeholder: '1020074',
          comment: '半角・ハイフンなしで入力してください',
        },
        {
          name: 'pref',
          type: 'select',
          label: '都道府県',
          value: null,
          options: [],
          required: true,
          comment: '',
        },
        {
          name: 'city',
          type: 'text',
          label: '市区町村',
          value: null,
          required: true,
          placeholder: '千代田区九段南',
        },
        {
          name: 'address',
          type: 'text',
          label: '番地',
          value: null,
          required: false,
          placeholder: '1-5-6',
        },
        {
          name: 'building',
          type: 'text',
          label: '建物名・部屋番号',
          placeholder: 'りそな九段ビル5階 KSフロア',
          value: null,
        },
      ],
      address: null,
      mainValues: {
        id: null,
        username: null,
        kana: null,
        email: null,
      },
      subValues: {
        id: null,
        username: null,
        kana: null,
        email: null,
      },
    };
  },
  setup() {
    const mainUser = ref('');
    const subUser = ref('');

    const rules = {
      mainUser: { email },
      subUser: { email },
    };

    const $v = useVuelidate(rules, {
      mainUser,
      subUser,
    });

    return { mainUser, subUser, $v };
  },
  created() {
    if (this.user.email) {
      this.getSchool();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.getSchool();
        }
      });
    }
    if (this.helper.master.prefs) {
      this.items[1].options = this.helper.master.prefs;
    } else {
      this.$store.subscribe((mutations) => {
        if (mutations.type === 'helper/putMaster') {
          this.items[1].options = mutations.payload.prefs;
        }
      });
    }
  },
  watch: {
    user: {
      handler() {
        this.getSchool();
      },
      deep: true,
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
    /** 子から値を受け取る */
    receiveValue(values) {
      this.flag.readySubmit = values.readySubmit;
      this.address = values.address;
    },
    getSchool() {
      this.school = this.user.school[0];
      // 電話番号、メールアドレスをセット
      this.schoolItems.forEach((elem) => {
        if (elem.name === 'tel') elem.value = this.school.tel;
        if (elem.name === 'email') elem.value = this.school.email;
      });
      this.flag.loader = false;
      if (this.school.address) {
        setTimeout(() => {
          this.$refs.address.setCustomerData(this.school.address);
        }, 1000);
      }
    },
    editStart(user, name) {
      if (!user) {
        this.editCancel('sub');
        this.flag.registSubUser = true;
        return;
      }
      this.flag.registSubUser = false;
      Object.keys(this[`${name}Values`]).forEach((key) => {
        this[`${name}Values`][key] = user[key];
      });
    },
    editCancel(name) {
      this.flag.registSubUser = false;
      Object.keys(this[`${name}Values`]).forEach((key) => {
        this[`${name}Values`][key] = null;
      });
    },
    updateBase() {
      if (!this.flag.readySubmit) {
        alert('住所を正しく入力してください。');
        return;
      }
      this.showLoading();
      const data = {
        id: this.school.id,
      };
      this.schoolItems.forEach((row) => {
        data[row.name] = row.value;
      });

      this.axios({
        method: 'POST',
        url: '/v1/school/set/updater',
        data,
      })
        .then(() => {
          this.updateAddress();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          this.hideLoading();
        });
    },
    updateAddress() {
      const data = this.address;
      data.id = this.school.address ? this.school.address.id : null;

      const endpoint = data.id ? 'updater' : 'register';

      if (!data.id) {
        data.model_type = 2;
        data.foreign_id = this.school.id;
      }

      this.axios({
        method: 'POST',
        url: `/v1/address/set/${endpoint}`,
        data,
      })
        .then(() => {
          alert('更新が完了しました。');
          this.getSchool();
          this.hideLoading();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          this.hideLoading();
        });
    },
    updateUser(name) {
      this.showLoading();
      const data = this[`${name}Values`];
      const url = name === 'main' ? 'user' : 'user/sub';
      const endpoint = data.id ? 'update' : 'register';
      const label = data.id ? '更新' : '登録';
      if (!data.username || data.username === '') {
        alert('名前を入力してください');
        this.hideLoading();
        return;
      }
      if (name === 'main' && (!data.kana || data.kana === '')) {
        alert('管理者カナを入力してください。');
        this.hideLoading();
        return;
      }
      if (name === 'sub' && (!data.kana || data.kana === '')) {
        alert('副管理者カナを入力してください。');
        this.hideLoading();
        return;
      }
      if (name === 'main' && (data.email === '' || !data.email)) {
        alert('メールアドレスを入力してください。');
        this.hideLoading();
        return;
      }
      if (name === 'sub' && (data.email === '' || !data.email)) {
        alert('副管理者メールアドレスを入力してください。');
        this.hideLoading();
        return;
      }
      if (!data.id) data.school_id = this.user.school[0].id;

      console.log(`/v1/${url}/set/${endpoint}`);
      this.axios({
        method: 'POST',
        url: `/v1/${url}/set/${endpoint}`,
        data,
      })
        .then((response) => {
          const res = response.data;
          if (res.duplicate) {
            alert('送信いただいた更新メールアドレスはすでに別アカウントで登録済みです。\nメールアドレスの重複登録はできません。\n別のメールアドレスでお試しください。');
            this.hideLoading();
            return;
          }
          alert(`${label}が完了しました。`);
          this.$store.dispatch('user/update');
          this.getSchool();
          this.editCancel(name);
          this.hideLoading();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          this.hideLoading();
        });
    },

    deleteSubUser(name) {
      this.showLoading();
      const userData = this[`${name}Values`];
      const data = {
        id: userData.id,
        flag: 999,
      };
      this.axios({
        method: 'POST',
        url: '/v1/user/sub/set/update',
        data,
      })
        .then(() => {
          alert('副管理者の削除が完了しました');
          this.school.subUser = [];
          this.getSchool();
          this.hideLoading();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          alert('副管理者の削除に失敗しました。お手数ですが管理者までお知らせください');
          this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module>
.value {
  margin-top: 8px;
  @include sm-view {
    margin-top: 0;
  }
}
.users {
  margin-top: -20px;
}
.user {
  &_wrap {
    margin-top: 20px;
  }
  li {
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
  &_value {
    i {
      margin-right: 12px;
      color: var(--orange-main);
    }
  }
}
.row {
  padding: 24px 0;
  border-top: 1px solid var(--gray-main);
  &.child {
    border-top: none;
    &:not(:first-child) {
      padding-top: 0;
    }
  }
}
.label {
  border-radius: 4px;
  margin-right: 4px;
  font-weight: bold;
  display: inline-block;
  &_wrap {
    display: flex;
    align-items: baseline;
  }
}
.text,
.select {
  &:disabled {
    color: black;
    background-color: transparent;
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }
  }
}
.text,
.plane {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
  &.plane {
    font-size: 14px;
    background-color: transparent;
  }
}
.textarea {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
}
.editpassword {
  display: flex;
  justify-content: center;
  a {
    font-size: 14px;
    color: var(--black);

    &:hover {
      text-decoration: underline;
    }
  }
}
.validation {
  font-size: .8em;
  color: red;
}
</style>

<template>
  <div>
    <div v-if="flag.valid">
      <spacer :y="10"/>
      <h3>URLが誤っています。ホームから再度アクセスし直してください。</h3>
    </div>

    <loader-simple :flag="flag.loader">
      <spacer :y="4"/>
      <stack-item v-if="graphData && !flag.valid && !flag.loader && slugUserData">
        <contents-box :title="`ココモニ （${slugUserData.school.length ? slugUserData.school[0].name : '取得エラー'}：${slugUserData.username}さん）`" icon="<i class='fa-solid fa-monitor-waveform'></i>">
          <Meter
          :graphData="graphData" />

          <contents-box
            title="トレンド"
            icon="<i class='fa-solid fa-chart-line-up'></i>">
            <line-graph
              :power="graphData.graph.stenScores"
              :datetime="graphData.graph.dateTimes"
              :who5="graphData.graph.who5Scores"
              :labels="graphData.graph.dataLabels"
              :height="graphHeight"
              :who5DateTimes="graphData.graph.who5DateTimes"/>
          </contents-box>

          <spacer :y="3" />

          <GraphPeriod
            :start="start"
            :end="end"
            v-on:sendPeriod="receivePeriod" />
        </contents-box>
      </stack-item>
      <spacer :y="4"/>
    </loader-simple>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';
import ContentsBox from '@/views/components/ContentsBox.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Meter from '@/views/components/Meter.vue';
import LineGraph from '@/views/components/LineGraph.vue';
import GraphPeriod from '@/views/components/GraphPeriod.vue';

export default {
  name: 'cocomoni-detail',
  mixins: [cf],
  components: {
    Spacer,
    ContentsBox,
    LoaderSimple,
    Meter,
    LineGraph,
    GraphPeriod,
  },
  data() {
    return {
      id: null,
      slugUserData: null,
      graphData: null,
      graphHeight: 200,
      flag: {
        valid: false,
        loader: true,
        count: 0,
        effectSize: true,
        isDisplay: false,
      },
      start: null,
      end: null,
    };
  },
  created() {
    this.id = cf.convert2Num(this.$route.params.id);
    if (this.user.email) {
      this.getGraphData();
      this.getUserData();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.getGraphData();
          this.getUserData();
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
    showVoice() {
      return this.graphData && !this.graphData.meter.energy;
    },
  },
  methods: {
    /** slugのユーザーデータの取得 */
    async getUserData() {
      this.axios({
        method: 'GET',
        url: '/v1/user/get/detail',
        params: {
          id: this.id,
        },
      })
        .then((response) => {
          const res = response.data.data;
          this.slugUserData = res;
          if ([1, 2].includes(this.user.account_type)
          || ([3].includes(this.user.account_type) && this.checkShowFlag(res)) // 教職員（利用者）は自分の属する学校の生徒のみ閲覧可能
          || ([11, 12].includes(this.user.account_type) && !this.user.school.length) // ↓学校管理者は自分の属する学校の利用者のみ閲覧可能（利用者、保護者、教師）
          || ([11, 12].includes(this.user.account_type) && this.user.school.length && res.school.length && this.user.school[0].id !== res.school[0].id)) {
            this.flag.valid = true;
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.loader = false;
        });
    },

    checkShowFlag(data) {
      let result = true;
      console.log(data);
      if (data.school[0].id === this.user.school[0].id && data.account_type === 1) {
        result = false;
      }
      return result;
    },

    /** グラフ描画データの取得 */
    async getGraphData() {
      if (window.innerWidth <= 440) this.graphHeight = 400;

      this.axios({
        method: 'GET',
        url: '/v1/graph/get/charts',
        params: {
          user_id: this.id,
          start: this.start,
          end: this.end,
        },
      })
        .then((response) => {
          this.graphData = response.data.result;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /** 期間を受け取ってグラフ再取得 */
    receivePeriod(data) {
      this.start = data.start;
      this.end = data.end;
      this.getGraphData();
    },
  },
};
</script>

<style lang="scss" module>
.danger {
  color: red;
  text-align: center;
}
.flex_item {
  display: flex;
  justify-content: space-around;
}
.animationBox {
  background: -moz-linear-gradient(top, #333333, black 20%, black);
  background: -webkit-linear-gradient(top, #333333, black 20%, black);
  background: linear-gradient(to bottom, #333333, black 20%, black);
  border-radius: 10px;
  margin: 0 auto;
  height: 90vmin;
  $size: 150px;
  &_rec {
    position: relative;
    top: 20%;
    margin: 0 auto;
    width: 50%;
    transition: 2s;
    transform: scale(0.7);
    .particle_animation {
      width: 100%;
    }
  }
  &_rec_effect {
    position: relative;
    top: 25%;
    margin: 0 auto;
    width: 50%;
    transition: 2s;
    transform: scale(1.2);
    .particle_animation {
      width: 100%;
    }
  }
}
.v-leave {
    opacity: .2s;
}
.v-leave-to {
    opacity: 0;
}
.recoding_explanation {
  color: white;
  text-shadow: 1px 2px 2px #222222;
  font-size: 1.5em;
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: bold;
  display: table;
  width: 100%;
  .bigger_text {
    font-size: 1.5em;
    @include sm-view {
      font-size: 1.3em;
    }
  }
  @include sm-view {
    font-size: 1.2em;
  }
}
</style>

<style lang="scss" scoped>
.v {
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
  &-enter-active {
    transition: opacity .5s;
  }
  &-enter-to,
  &-leave {
    opacity: 1;
  }
  &-leave-active {
    transition: opacity .5s;
  }
}


/* ボイスと占い結果の切り替わり */
.fade-voice {
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
  &-enter-active {
    transition: opacity .5s;
  }
  &-enter-to,
  &-leave {
    opacity: 1;
  }
  &-leave-active {
    transition: opacity 1s;
  }
}


.fade-fortune {
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
  &-enter-active {
    transition: opacity 5s;
  }
  &-enter-to,
  &-leave {
    opacity: 1;
  }
  &-leave-active {
    transition: opacity .5s;
  }
}
</style>

<template>
  <div :class="className">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BtnContainer',
  props: {
    align: {
      type: String,
      default: 'center',
      validator: (value) => ['center', 'left', 'right'].includes(value),
    },
  },
  computed: {
    className() {
      return `${this.$style.container} ${this.$style[this.align]}`;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
  @include sm-view {
    display: block;
  }

  &.center {
    justify-content: center;
  }

  &.left {
    justify-content: start;
  }

  &.right {
    justify-content: end;
  }
}
</style>
